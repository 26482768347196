import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/allow-group';
import { createRequest } from '../../../helpers/api/createRequest';

export const CHANGE_ALLOW_GROUP = 'allow/changeSectionGroup';

export const changeAllowGroup = createAsyncThunk(
  CHANGE_ALLOW_GROUP,
  createRequest({
    type: CHANGE_ALLOW_GROUP,
    loader: data => authApi.changeAllowGroup(data),
  }),
);
