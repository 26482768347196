import React, { useContext, useEffect } from 'react';

import { SearchMenuFilterBlockItem } from './search-menu-filter-block-item';
import { StateContext } from './state-provider';
import { log } from '@craco/craco/dist/lib/logger';

export const SearchMenuFilterBlockFiltersRest = ({
  filter,
  selectedOptionsTitle,
  setSelectedOptionsTitle,
  scrollIndex,
  setScrollIndex,
  selectedObjectFiltersRest,
  setSelectedObjectFiltersRest,
  save,
  setSave,
  oneSelectedOptionRest,
  setOneSelectedOptionRest,
  show,
  setShow,
  date,
  setDataPickerId,
}) => {
  const { selectedOptions, setSelectedOptions } = useContext(StateContext);
  const { scrollRef } = useContext(StateContext);

  const toggleOption = (filter, option, title) => {
    setSave(false);
    setSelectedOptionsTitle(prev => [...prev, title]);
    if (filter !== 1 && filter !== 5 && filter !== 8) {
      const newOptions = selectedOptions.includes(option)
        ? selectedOptions.filter(item => item !== option)
        : [...selectedOptions, option];
      setSelectedOptions(newOptions);
    }

    if (filter === 5 || filter === 8) {
      const newOptions = oneSelectedOptionRest[filter]?.includes(option)
        ? oneSelectedOptionRest[filter].filter(item => item !== option)
        : [option];
      setOneSelectedOptionRest({ ...oneSelectedOptionRest, [filter]: newOptions });
    }

    if (filter === 1) {
      if (option === 'bath') {
        const newOptions = oneSelectedOptionRest[filter]?.includes(option)
          ? oneSelectedOptionRest[filter].filter(item => item !== option)
          : ['bath'];
        setOneSelectedOptionRest({ ...oneSelectedOptionRest, [filter]: newOptions });
      }
      if (option !== 'bath') {
        if (oneSelectedOptionRest[filter].includes('bath')) {
          const t = oneSelectedOptionRest[filter].filter(item => item !== 'bath');
          const newOptions = t?.includes(option)
            ? t.filter(item => item !== option)
            : [...t, option];
          setOneSelectedOptionRest({ ...oneSelectedOptionRest, [filter]: newOptions });
        } else if (oneSelectedOptionRest[filter].length === 0) {
          setOneSelectedOptionRest({ ...oneSelectedOptionRest, [filter]: [option] });
        } else {
          const newOptions = oneSelectedOptionRest[filter]?.includes(option)
            ? oneSelectedOptionRest[filter].filter(item => item !== option)
            : [...oneSelectedOptionRest[filter], option];
          setOneSelectedOptionRest({ ...oneSelectedOptionRest, [filter]: newOptions });
        }
      }
    }

    if (filter === 5 || filter === 8) {
      const newOptions = selectedObjectFiltersRest[filter]?.includes(option)
        ? selectedObjectFiltersRest[filter]?.filter(item => item !== option)
        : [option];
      setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: newOptions });
    }

    if (filter === 1) {
      if (option === 'bath') {
        if (selectedObjectFiltersRest[filter]?.includes(option)) {
          const a = oneSelectedOptionRest[filter].filter(item => item !== option);
          setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: a });
        } else {
          setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: [option] });
        }
      }
      if (option !== 'bath') {
        const b = selectedObjectFiltersRest[filter].filter(item => item !== 'bath');

        if (b.includes(option)) {
          const newOpt = b.filter(item => item !== option);
          setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: newOpt });
        } else {
          setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: [...b, option] });
        }
      }
    }
    if (filter !== 5 && filter !== 8 && filter !== 1) {
      if (selectedObjectFiltersRest[filter].includes(option)) {
        const newOpt = selectedObjectFiltersRest[filter].filter(item => item !== option);
        setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: newOpt });
      } else
        setSelectedObjectFiltersRest({
          ...selectedObjectFiltersRest,
          [filter]: [...selectedObjectFiltersRest[filter], option],
        });
    }
  };

  const cleanOptions = (e, filterTitle, value) => {
    const newArr = [];
    const newArrCommon = [];
    const arr = [];
    e?.map(el => {
      arr.push(el.value);
    });
    const commonArray = [].concat(
      selectedObjectFiltersRest[1],
      selectedObjectFiltersRest[4],
      selectedObjectFiltersRest[7],
      selectedObjectFiltersRest[9],
      selectedObjectFiltersRest[10],
      selectedObjectFiltersRest[11],
    );
    selectedObjectFiltersRest[value]?.map(elem => {
      !arr.includes(elem) && newArr.push(elem);
    });

    setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [value]: newArr });

    commonArray.map(elem => {
      !arr.includes(elem) && newArrCommon.push(elem);
    });
    setSelectedOptions(newArrCommon);

    setSelectedOptionsTitle(selectedOptionsTitle.filter(item => item !== filterTitle));
  };

  useEffect(() => {
    if (save && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [save, scrollRef.current]);

  useEffect(() => {
    const emptyArrays = Object.keys(selectedObjectFiltersRest).filter(
      key =>
        (selectedObjectFiltersRest[key].length === 0 && key !== '3') ||
        (!selectedObjectFiltersRest[key][0] && key !== '3'),
    );
    const numberArray = emptyArrays.map(str => Number.parseInt(str, 10));

    const scrollArray = selectedObjectFiltersRest[1].includes('bath')
      ? numberArray.filter(item => (item > 6 && item < 12) || item === 2)
      : numberArray.filter(item => (item >= 1 && item < 7) || item === 11);

    setScrollIndex(scrollArray);
  }, [selectedObjectFiltersRest]);

  return (
    <div className="search-menu-filter-block">
      <div className="search-menu-filter-block-top">
        <h4
          style={{
            color: save && filter.id !== 3 && scrollIndex.includes(filter.value) && 'red',
          }}
          ref={filter.value === Math.min(...scrollIndex) ? scrollRef : null}
        >
          {filter.title}
        </h4>
        {selectedOptionsTitle?.includes(filter.title) && (
          <div
            className="search-menu-filter-block-reset"
            onClick={() => cleanOptions(filter.options, filter.title, filter.value)}
          >
            сбросить
          </div>
        )}
      </div>
      <div className="search-menu-filter-block-bottom">
        {filter.options.map((option, key) => (
          <SearchMenuFilterBlockItem
            key={`${option.label}-${key}`}
            option={option}
            isSelected={
              selectedOptions?.includes(option?.value) ||
              oneSelectedOptionRest[filter.value]?.includes(option?.value)
            }
            toggleOption={() => toggleOption(filter?.value, option?.value, filter?.title)}
            save={save}
            setSave={setSave}
            show={show}
            setShow={setShow}
            date={date}
            setDataPickerId={setDataPickerId}
          />
        ))}
      </div>
    </div>
  );
};
