import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { authSelector } from '../selectors/auth';

export function RootRouter({ children }) {
  const auth = useSelector(authSelector);
  let location = useLocation();

  if (!auth) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  } else {
    return children;
  }
}
