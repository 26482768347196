import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section-discount';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_DISCOUNT_GROUP = 'discount/createDiscountGroup';

export const createDiscountGroup = createAsyncThunk(
  CREATE_DISCOUNT_GROUP,
  createRequest({
    type: CREATE_DISCOUNT_GROUP,
    loader: data => authApi.createDiscountGroup(data),
  }),
);
