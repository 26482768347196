import React from 'react';

export const AwningIcon = props => (
  <svg
    viewBox="0 0 33 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M5 15.499C5 15.2229 5.22386 14.999 5.5 14.999C5.77614 14.999 6 15.2229 6 15.499V25.499C6 25.7752 5.77614 25.999 5.5 25.999C5.22386 25.999 5 25.7752 5 25.499V15.499Z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M27.5996 15.499C27.5996 15.2229 27.8235 14.999 28.0996 14.999C28.3758 14.999 28.5996 15.2229 28.5996 15.499V25.499C28.5996 25.7752 28.3758 25.999 28.0996 25.999C27.8235 25.999 27.5996 25.7752 27.5996 25.499V15.499Z"
        fill="currentColor"
      />
      <path
        d="M27.8032 14.999C27.9157 14.999 28.0068 15.3273 28.0068 15.7324V25.2657C28.0068 25.6707 27.9157 25.999 27.8032 25.999C27.6908 25.999 27.5996 25.6707 27.5996 25.2657V15.7324C27.5996 15.3273 27.6908 14.999 27.8032 14.999Z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M10.3047 9.41895C10.3047 9.1428 10.5285 8.91895 10.8047 8.91895C11.0808 8.91895 11.3047 9.1428 11.3047 9.41895V25.2764C11.3047 25.5525 11.0808 25.7764 10.8047 25.7764C10.5285 25.7764 10.3047 25.5525 10.3047 25.2764V9.41895Z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M1.02574 26.5664C0.988634 26.4988 0.991961 26.4167 1.03368 26.3522C2.46509 24.1478 2.56158 21.3515 1.285 19.0541C1.08768 18.6988 1.13375 18.2538 1.3994 17.9464L15.765 1.33603C15.95 1.12259 16.218 1 16.5003 1C16.7826 1 17.0503 1.12259 17.2353 1.33603L27.5957 13.3153L31.6009 17.9464C31.8668 18.2538 31.9129 18.6988 31.7153 19.0541C30.4393 21.3515 30.5355 24.1478 31.9667 26.3522C32.0084 26.4167 32.0117 26.4988 31.9748 26.5664C31.9382 26.6342 31.8676 26.6762 31.7903 26.6762H1.21001C1.13298 26.6762 1.06234 26.6339 1.02574 26.5664ZM31.3491 18.8501C31.4612 18.6484 31.4351 18.3956 31.2838 18.221L16.9182 1.61065C16.813 1.48908 16.661 1.41947 16.5006 1.41947C16.3401 1.41947 16.1878 1.48883 16.0829 1.61065L1.71727 18.221C1.56627 18.3956 1.54042 18.6484 1.652 18.8501C2.94215 21.1724 2.90837 23.9759 1.58521 26.2562H31.4159C30.0927 23.9759 30.059 21.1724 31.3491 18.8501Z"
        fill="currentColor"
      />
      <path
        d="M1.46722 18.9522H1.46876M1.02574 26.5664C0.988634 26.4988 0.991961 26.4167 1.03368 26.3522C2.46509 24.1478 2.56158 21.3515 1.285 19.0541C1.08768 18.6988 1.13375 18.2538 1.3994 17.9464L15.765 1.33603C15.95 1.12259 16.218 1 16.5003 1C16.7826 1 17.0503 1.12259 17.2353 1.33603L27.5957 13.3153L31.6009 17.9464C31.8668 18.2538 31.9129 18.6988 31.7153 19.0541C30.4393 21.3515 30.5355 24.1478 31.9667 26.3522C32.0084 26.4167 32.0117 26.4988 31.9748 26.5664C31.9382 26.6342 31.8676 26.6762 31.7903 26.6762H1.21001C1.13298 26.6762 1.06234 26.6339 1.02574 26.5664ZM31.3491 18.8501C31.4612 18.6484 31.4351 18.3956 31.2838 18.221L16.9182 1.61065C16.813 1.48908 16.661 1.41947 16.5006 1.41947C16.3401 1.41947 16.1878 1.48883 16.0829 1.61065L1.71727 18.221C1.56627 18.3956 1.54042 18.6484 1.652 18.8501C2.94215 21.1724 2.90837 23.9759 1.58521 26.2562H31.4159C30.0927 23.9759 30.059 21.1724 31.3491 18.8501Z"
        stroke="currentColor"
      />
      <path
        d="M17.1895 1.78418C19.6653 18.3429 22.2679 24.1624 23.2663 25.8911C23.2125 24.1792 23.7799 21.8982 26.1488 19.4391C21.7094 15.1846 18.6887 6.80579 17.1895 1.78418Z"
        stroke="currentColor"
      />
    </g>
  </svg>
);
