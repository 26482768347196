import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section-group';
import { createRequest } from '../../../helpers/api/createRequest';

export const CHANGE_GROUP = 'section/changeSectionGroup';

export const changeSectionGroup = createAsyncThunk(
  CHANGE_GROUP,
  createRequest({
    type: CHANGE_GROUP,
    loader: data => authApi.changeSectionGroup(data),
  }),
);
