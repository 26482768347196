import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/document';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_DOCUMENT = 'document/deleteDocument';

export const deleteDocument = createAsyncThunk(
  DELETE_DOCUMENT,
  createRequest({
    type: DELETE_DOCUMENT,
    loader: data => authApi.deleteDocument(data),
  }),
);
