/* import { addCompetitionAttachment } from '../../../../../../actions/competition'; */
/* import { getGuideCabinet } from '../../../../../../actions/guide/thunks'; */
import {
  addGuideAttachment,
  deleteGuideAttachment,
  getGuideCabinet,
  updateGuideCabinet,
  updateGuidesContacts,
} from '../../../../../../api/guide';
import { urlToBlob } from '../../../../../utils/blob';
// TODO
export const removeAttachments = async (eventId, type) => {
  const {
    data: { attachments },
  } = await getGuideCabinet({ id: eventId });

  const filtered = attachments?.filter?.(({ type: t }) => t === type);

  if (filtered?.length) {
    await Promise.all(filtered?.map(({ id }) => deleteGuideAttachment({ id })));
  }
};

export const updateGuide = async (dispatch, guide, fields) => {
  try {
    await Promise.all(
      ['LOGO', 'POSTCARD', 'BANNER', 'GALLERY'].map(type => removeAttachments(guide?.id, type)),
    );

    if (fields?.gallery?.length) {
      await Promise.all(
        fields?.gallery?.map(async image => {
          if (image?.src) {
            const formData = new FormData();
            formData.append('file', await urlToBlob(image?.src));
            formData.append('cabinetId', guide?.id);
            formData.append('type', 'GALLERY');
            await addGuideAttachment(formData);
          }
        }),
      );
    }

    if (fields?.attachments?.length) {
      await Promise.all(
        fields?.attachments.map(async image => {
          if (image?.src) {
            const formData = new FormData();
            formData.append('file', await urlToBlob(image?.src));
            formData.append('cabinetId', guide?.id);
            formData.append('type', image?.key?.toUpperCase());
            await addGuideAttachment(formData);
          }
        }),
      );
    }

    await Promise.all([
      updateGuideCabinet({
        data: {
          name: fields?.name,
          description: fields?.description,
        },
        id: guide?.id,
      }),
      updateGuidesContacts({
        id: guide?.contact?.id,
        contactsData: {
          country: fields?.contact?.country,
          region: fields?.contact?.region,
          district: fields?.contact?.district,
          street: fields?.contact?.street,
          town: fields?.contact?.town,
          mainPhone: fields?.contact?.mainPhone,
          mainPhoneStartTime: fields?.contact?.mainPhoneStartTime,
          mainPhoneEndTime: fields?.contact?.mainPhoneEndTime,
          secondaryPhone: fields?.contact?.secondaryPhone,
          secondaryPhoneStartTime: fields?.contact?.secondaryPhoneStartTime,
          secondaryPhoneEndTime: fields?.contact?.secondaryPhoneEndTime,
          email: fields?.contact?.email,
          whatsAppLink: fields?.contact?.whatsAppLink,
          telegramLink: fields?.contact?.telegramLink,
          viberLink: fields?.contact?.viberLink,
          socialPageLink: fields?.contact?.socialPageLink,
          websiteLink: fields?.contact?.websiteLink,
          secondaryWebsiteLink: fields?.contact?.secondaryWebsiteLink,
          coordinates: fields?.contact?.coordinates?.split(',')?.map(i => i?.trim?.()),
        },
      }),
    ]);

    await dispatch(getGuideCabinet({ id: guide?.id }));
  } catch {
    console.log('error');
  }
};
