import { request } from '../../helpers/api/request';

export const cardHolderAccept = async ({ profileId, data }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `card-holder/accept/${profileId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    data,
  });

  return response;
};
