/**
 * Потенциально опасный момент: при наследовании от нативных классов (таких, как Error),
 * транспайлинг бабелем работает не так, как нужно и может получиться, что
 * класс-наследник class MyError extends Error при проверке (new MyError()) instanceof Error возвращает false
 * Подробно описано здесь:
 * https://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node/33877501#33877501
 * Для правильного транспайлинга следует либо использовать @babel/plugin-transform-classes (для babel 7) либо вообще не
 * транспайлить наследование классов (сейчас именно так и происходит)
 */
import { notifyError } from '@api/helpers/alertNotification';
// Ошибка при запросе
export class RequestError extends Error {
    constructor(message) {
        super(message || 'Request error');
    }
}

// Ошибка, которую отдал сервер, с соответствующим кодом
export class ServerRequestError extends RequestError {
    statusCode;
    data;

    constructor(
        message,
        statusCode,
        data,
    ) {
        super(message);
        this.statusCode = statusCode;
        this.data = data;
    }
}

// Ошибка с сетью, не достучались до сервера, нет кода ответа
export class NetworkError extends RequestError {
    constructor(message) {
        super(message || 'Network error');
    }
}

// Получает на вход ошибку от axios и в случае, если это сетевая ошибка,
// бросает NetworkError
export const throwNetworkError = (error) => {

  const status = error?.statusCode;
  const isNotNetworkError = error?.response?.status;

  if (error?.response?.data?.message  ==="Profile with given number doesn`t exist") {
    
    notifyError('Профиль еще не зарегистрирован');
  }
  if (error?.response?.data?.message === "phone must be a valid phone number") {
    notifyError('Проверьте правильность введенного номера телефона');
  }
  if (error?.response?.data?.message === "Verification Codes doesn`t match") {
    notifyError('Проверьте правильность введенного  проверочного кода');
  } 
  if (error?.response?.data?.message  ==="Profile with given number already exist") {
    
    notifyError('Профиль c этим телефонным номером уже зарегистрирован');
  }




  if (!isNotNetworkError && !status) {
    throw new NetworkError();
  }
};
