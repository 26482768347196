/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/no-useless-undefined */
import { createReducer } from '@reduxjs/toolkit';

import {
  getCompetitionCabinet,
  getCompetitionCabinetByProfile,
  getCompetitionEvent,
  getCompetitions,
} from '../../actions/competition';
/* import { objectImageTypeSlider } from '../../constants/Component'; */

export const competition = createReducer({}, builder => {
  builder.addCase(getCompetitionCabinetByProfile.fulfilled, (state, { payload }) => ({
    ...state,
    competitions: payload?.data,
  }));
  builder.addCase(getCompetitionCabinet.fulfilled, (state, { payload }) => ({
    ...state,
    competition: payload?.data,
    logo: payload?.data?.attachments?.find(att => att.type === 'LOGO')?.url,
    schema:
      payload?.data?.attachments?.filter(index => index?.type === 'SCHEMA').length > 0
        ? payload?.data?.attachments
            ?.filter(index => index?.type === 'SCHEMA')
            .slice()
            .sort((a, b) => a.priority - b.priority)
            .map(item => ({
              id: item?.id,
              src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
              type: item?.type,
              title: item?.title,
              textFileName: decodeURIComponent(item?.textFileName),
              textFileUrl: item?.textFileUrl,
            }))
        : [],
    protocol:
      payload?.data?.attachments?.filter(index => index?.type === 'PROTOCOL').length > 0
        ? payload?.data?.attachments
            ?.filter(index => index?.type === 'PROTOCOL')
            .slice()
            .sort((a, b) => a.priority - b.priority)
            .map(item => ({
              id: item?.id,
              src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
              type: item?.type,
              title: item?.title,
              textFileName: decodeURIComponent(item?.textFileName),
              textFileUrl: item?.textFileUrl,
            }))
        : [],
    news: payload?.data?.news?.slice().sort((a, b) => a.id - b.id),
    gallery:
      payload?.data?.attachments?.filter(index => index?.type === 'GALLERY').length > 0
        ? payload?.data?.attachments
            ?.filter(index => index?.type === 'GALLERY')
            .map(item => ({
              id: item?.id,
              src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
              type: item?.type,
            }))
        : [{ id: undefined, src: '', type: 'blueBg' }],
  }));
  builder.addCase(getCompetitions.fulfilled, (state, { payload }) => ({
    ...state,
    listOfCompetitions: payload?.data,
  }));
  builder.addCase(getCompetitionEvent.fulfilled, (state, { payload }) => ({
    ...state,
    competitionEvents: payload?.data,
  }));
});
