/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/attachment';
import { createRequest } from '@/helpers/api/createRequest';

export const UPDATE_ATTACHMENT = 'attachment/updateAttachment';

export const updateAttachment = createAsyncThunk(
  UPDATE_ATTACHMENT,
  createRequest({
    type: UPDATE_ATTACHMENT,
    loader: data => authApi.updateAttachment(data),
  }),
);
