/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const GET_COMPETITION_EVENT = 'competition/getCompetitionEvent';

export const getCompetitionEvent = createAsyncThunk(
  GET_COMPETITION_EVENT,
  createRequest({
    type: GET_COMPETITION_EVENT,
    loader: data => authApi.getCompetitionEvent(data),
  }),
);
