import { addLoader, removeLoader, updateLoader } from "@/actions/loaders"
import { createLoader } from "@/helpers/loaders/createLoader"
import { createReducer } from "@reduxjs/toolkit"

const add = (state, loader) => ({
    ...state,
    [loader]: { ...createLoader(loader) },
})

const update = (state, { loaderName, loaderState }) => ({
    ...state,
    [loaderName]: {
        ...state[loaderName],
        state: loaderState,
    },
})

const remove = (state, loaderName) => {
    const { [loaderName]: RemoveLoader, ...otherLoaderName } = state
    return {
        ...otherLoaderName,
    }
}

export const loaders = createReducer({}, (builder) => {
    builder.addCase(addLoader, (state, { payload }) =>
        add(state, payload.loader)
    )
    builder.addCase(updateLoader, (state, { payload }) =>
        update(state, payload)
    )
    builder.addCase(removeLoader, (state, { payload }) =>
        remove(state, payload.loader)
    )
})
