import { INITIAL_STATE } from '../../constants';

export const arrayToObject = arr =>
  arr === undefined || arr?.length === 0
    ? undefined
    : Array?.isArray(arr)
      ? Object.assign(...arr.map(k => ({ [k]: true })))
      : { [arr]: true };

export const objectToArray = obj => Object?.keys(obj)?.filter(key => key !== 'id');

export const mapSectorToFields = (sector, locations, selectedLocationId) => {
  const def = INITIAL_STATE?.sectorFields;

  return {
    ...def,
    id: sector?.id,
    facilities: sector?.conveniences?.map?.(({ name }) => name) ?? def?.facilities,
    fishing: sector?.fishingType ?? def?.fishing,
    discipline: sector?.discipline ?? def?.discipline,
    direction: sector?.direction ?? def?.direction,
    addConditions: sector?.additionalConditions ?? def?.addConditions,
    fish: sector?.fishType ?? def?.fish,
    number: sector?.number ?? def?.number,
    conditions: sector?.conditions ?? def?.conditions,
    landscape: sector?.landscape ?? def?.landscape,
    aquazone: sector?.aquazone ?? def?.aquazone,
    services: sector?.services ?? def?.services,
    forRest: sector?.forRest ?? def?.forRest,
    objectDescription: sector?.description ?? def?.objectDescription,
    name: sector?.name ?? def?.name,
    watersideObject: sector?.objects ?? def?.watersideObject,
    place: sector?.place?.map?.(({ name }) => name) ?? def?.place,
    objectType: sector?.objectType ? objectToArray(sector?.objectType)?.[0] : def?.objectType,
    sleepPlaces:
      sector?.conveniences?.find?.(({ name }) => name === 'bed')?.number ?? def?.sleepPlaces,
    capacityNumber: sector?.objectType?.number ?? def?.capacityNumber,
    freeZoneNumber:
      sector?.place?.find?.(({ name }) => name === 'free')?.number ?? def?.freeZoneNumber,
    winterFromIceNumber:
      sector?.place?.find?.(({ name }) => name === 'winterFromIce')?.number ??
      def?.winterFromIceNumber,
    photos:
      sector?.images
        ?.sort?.((a, b) => a.priority - b.priority)
        ?.map?.(({ url }, index) => ({
          src: `${process?.env?.REACT_APP_STATIC_URL}${url}`,
          first: index === 0,
        })) ?? def?.photos,
    schemas:
      sector?.schemas
        ?.sort?.((a, b) => a.priority - b.priority)
        ?.map?.(({ url }) => ({
          src: `${process?.env?.REACT_APP_STATIC_URL}${url}`,
        })) ?? def?.schemas,
    price:
      locations
        ?.find(loc => loc?.id === selectedLocationId)
        ?.prices?.find(price => price?.sections?.map(section => section?.id)?.includes(sector?.id))
        ?.rates?.filter(
          ({ cost, caughtValue, helpValue, withNormValue, withoutNormValue }) =>
            cost || caughtValue || helpValue || withNormValue || withoutNormValue,
        )?.length > 0
        ? Math?.min?.apply?.(
            null,
            locations
              ?.find(loc => loc?.id === selectedLocationId)
              ?.prices?.find(price =>
                price?.sections?.map(section => section?.id)?.includes(sector?.id),
              )
              ?.rates?.filter(
                ({ cost, caughtValue, helpValue, withNormValue, withoutNormValue }) =>
                  cost || caughtValue || helpValue || withNormValue || withoutNormValue,
              )
              ?.map(
                ({
                  cost,
                  isCaught,
                  caughtValue,
                  isHelp,
                  helpValue,
                  isWithNorm,
                  withNormValue,
                  isWithoutNorm,
                  withoutNormValue,
                }) =>
                  Math?.min(
                    ...[
                      Number(cost),
                      Number(isCaught && caughtValue),
                      Number(isHelp && helpValue),
                      Number(isWithNorm && withNormValue),
                      Number(isWithoutNorm && withoutNormValue),
                    ].filter(item => item !== 0),
                  ) === Number.POSITIVE_INFINITY
                    ? 0
                    : Math?.min(
                        ...[
                          Number(cost),
                          Number(isCaught && caughtValue),
                          Number(isHelp && helpValue),
                          Number(isWithNorm && withNormValue),
                          Number(isWithoutNorm && withoutNormValue),
                        ].filter(item => item !== 0),
                      ),
              ),
          )
        : undefined,
    discount:
      locations
        ?.find(loc => loc?.id === selectedLocationId)
        ?.discounts?.find(discount =>
          discount?.sections?.map(section => section?.id)?.includes(sector?.id),
        )
        ?.rates?.filter(
          ({ cost, caughtValue, helpValue, withNormValue, withoutNormValue }) =>
            cost || caughtValue || helpValue || withNormValue || withoutNormValue,
        )?.length > 0
        ? Math?.min?.apply?.(
            null,
            locations
              ?.find(loc => loc?.id === selectedLocationId)
              ?.discount?.find(discount =>
                discount?.sections?.map(section => section?.id)?.includes(sector?.id),
              )
              ?.rates?.filter(
                ({ cost, caughtValue, helpValue, withNormValue, withoutNormValue }) =>
                  cost || caughtValue || helpValue || withNormValue || withoutNormValue,
              )
              ?.map(({ cost, caughtValue, helpValue, withNormValue, withoutNormValue }) =>
                Math?.min(
                  ...[
                    Number(cost),
                    Number(caughtValue),
                    Number(helpValue),
                    Number(withNormValue),
                    Number(withoutNormValue),
                  ].filter(item => item !== 0),
                ),
              ),
          )
        : undefined,
  };
};

export const mapFieldsToPayload = async (fields, location) => {
  const {
    number,
    landscape,
    conditions,
    objectDescription: description,
    watersideObject: objects,
    objectType,
    place,
    facilities: conveniences,
    fishing: fishingType,
    discipline,
    direction,
    addConditions: additionalConditions,
    fish: fishType,
    capacityNumber,
    freeZoneNumber,
    winterFromIceNumber,
    sleepPlaces,
    forRest,
    aquazone,
    services,
    name,
  } = fields;

  return {
    locationId: location?.id,
    type: location?.type,
    number,
    landscape,
    conditions,
    direction,
    fishType,
    aquazone,
    discipline,
    forRest,
    conveniences: conveniences?.map?.(convenience => ({
      name: convenience,
      number: convenience === 'bed' ? sleepPlaces : undefined,
    })),
    fishingType,
    additionalConditions,
    place: place?.map?.(p => ({
      name: p,
      number:
        p === 'free' ? freeZoneNumber : p === 'winterFromIce' ? winterFromIceNumber : undefined,
    })),
    description,
    name,
    services,
    objects,
    objectType: {
      ...arrayToObject(objectType),
      number: capacityNumber,
    },
  };
};

export const numberStringToArray = number => {
  let numberString = number.toString();
  const numberArray = [];

  if (numberString?.[0]?.match(/[^\d$,.]/)) {
    numberArray?.push?.(numberString?.[0]);
    numberString = numberString.slice(1);
  } else numberArray?.push?.('');

  if (numberString?.[numberString.length - 1]?.match(/[^\d$,.]/)) {
    numberArray?.push?.(numberString?.[numberString.length - 1]);
    numberString = numberString.slice(0, -1);
  } else numberArray?.push?.('');

  if (numberString?.length !== 0) {
    numberArray.splice(1, 0, numberString);
  }

  return numberArray;
};
