import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/profile-admin';
import { createRequest } from '../../../helpers/api/createRequest';
import { getAdmins } from './get-admins';

export const BLOCK_ADMIN = 'profile-admin/blockAdmin';

const onSuccess = () => async (dispatch) => {
  dispatch(
    getAdmins()
  );
};

export const blockAdmin = createAsyncThunk(
  BLOCK_ADMIN,
    createRequest({
        type: BLOCK_ADMIN,
        loader: (data) => profileAdminApi.blockAdmin(data),
        onSuccess,
    }),
);