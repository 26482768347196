import { useState, useEffect } from "react"

const usePosition = () => {
    const [position, setPosition] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const handleSuccess = (userPosition) => {
            setPosition(userPosition.coords)
        }

        const handleError = (err) => {
            setError(err)
        }

        if (!navigator.geolocation) {
            setError(new Error("Геолокация не поддерживается вашим браузером"))
            return
        }

        navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
    }, [])

    return { position, error }
}

export default usePosition
