import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getProfile, getProfileById, profileAvatar } from '../../actions/profile';
import { Footer, Header, ScrollUpToTopArrow, StyledAvatar } from '../../components';
import { Banner } from '../../components/banner';
import { Icon } from '../../components/Icon';
import { StateContext } from '../../components/state-provider';
import { addLeadingZeros } from '../../constants/Function';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { profileByIdSelector, profileSelector } from '../../selectors/profile';
import { ImageLoaderModal, Modal } from '../shared';
import { urlToBlob } from '../utils/blob';

const OPTIONS = [
  {
    id: 1,
    name: 'Личная информация',
    iconName: 'PERSONALINFO',
    width: 22.47,
    height: 25.56,
    viewBox: '0 0 22.466 25.561',
    link: 'profile/edit',
    bottom: px(25),
  },
  {
    id: 2,
    name: 'Вход и Надежность',
    iconName: 'SECURITY',
    width: 26.13,
    height: 30.07,
    viewBox: '0 0 26.128 30.074',
    link: 'verify-face-id',
    bottom: px(20.9),
    personal: true,
  },
  {
    id: 3,
    name: 'Уведомления. Копии',
    iconName: 'NOTIFICATION',
    width: 22.97,
    height: 27.56,
    viewBox: '0 0 22.968 27.562',
    // link: 'notification',
    link: 'profile',
    bottom: px(23.2),
  },
  {
    id: 4,
    name: 'Управление кабинетом',
    iconName: 'CONTROL',
    width: 28.47,
    height: 28.47,
    viewBox: '0 0 28.474 28.474',
    link: 'account-control',
    bottom: px(22.3),
    personal: true,
  },
  {
    id: 5,
    name: 'Банковские карты',
    iconName: 'CARDS',
    width: 29.12,
    height: 23.08,
    viewBox: '0 0 29.117 23.077',
    // link: 'add-card',
    link: 'profile',
    bottom: px(23.9),
  },
  {
    id: 6,
    name: 'Финансы. Организации',
    iconName: 'FINANCE',
    width: 26.58,
    height: 28.74,
    viewBox: '0 0 27.58 29.737',
    link: 'finance',
    bottom: px(25),
  },
  {
    id: 7,
    name: 'Аналитика. Финансы',
    iconName: 'ANALYTICS',
    width: 27.15,
    height: 27.15,
    viewBox: '0 0 27.145 27.145',
    link: 'profile',
    bottom: px(22.9),
  },
  {
    id: 8,
    name: 'Подписки',
    iconName: 'SUBSCRIPTIONS',
    width: 25.83,
    height: 25.83,
    viewBox: '0 0 25.827 25.827',
    link: 'profile',
    bottom: px(23.6),
  },
  {
    id: 9,
    name: 'Клубные карты',
    iconName: 'CLUBCARTS',
    width: 27.41,
    height: 19.17,
    viewBox: '0 0 27.41 19.172',
    /* link: 'profile/club-cards', */
    link: 'profile',
    bottom: px(25),
    personal: true,
  },
  {
    id: 10,
    name: 'Сертификаты',
    iconName: 'SERTIFICATE',
    width: 23.19,
    height: 23.19,
    viewBox: '0 0 23.188 23.188',
    link: 'profile',
    bottom: px(25),
  },
  {
    id: 11,
    name: 'Чат с техподдержкой',
    iconName: 'SUPPORT',
    width: 24.96,
    height: 25.42,
    viewBox: '0 0 24.964 25.423',
    link: 'chat-with-support',
    bottom: px(23.8),
  },
  {
    id: 12,
    name: 'Обращения',
    iconName: 'APPEALS',
    width: 24.96,
    height: 25.42,
    viewBox: '0 0 24.964 25.423',
    link: 'appeals',
    bottom: px(23.8),
  },
  {
    id: 13,
    name: 'Позвонить нам',
    iconName: 'CALL',
    width: 27.27,
    height: 27.37,
    viewBox: '0 0 27.269 27.372',
    link: 'tel:+74951479784',
    bottom: px(22.8),
    phoneNumber: true,
  },
  {
    id: 14,
    name: 'Мобильная версия',
    iconName: 'MOBILE',
    width: 25.68,
    height: 23.53,
    viewBox: '0 0 25.683 23.53',
    link: 'mobile',
    bottom: px(25),
  },
  {
    id: 15,
    name: 'Заблокировать кабинет',
    iconName: 'BLOCK',
    width: 21,
    height: 28,
    viewBox: '0 0 21 28',
    link: 'block-account',
    bottom: px(23),
    personal: true,
  },
  {
    id: 16,
    name: 'Перевод кабинета',
    iconName: 'TRANSFER',
    width: 28.98,
    height: 26.08,
    viewBox: '0 0 30.48 27.582',
    link: 'transfer',
    bottom: px(25),
    personal: true,
  },
  {
    id: 17,
    name: 'Удалить кабинет',
    iconName: 'DELETE',
    width: 20.5,
    height: 25.23,
    viewBox: '0 0 20.5 25.231',
    link: 'delete-account',
    bottom: px(25),
    personal: true,
  },
];

const PAGE_OPTIONS = [
  { id: 1, name: 'Рыбалка. Отдых. Услуги' },
  { id: 2, name: 'Гиды. Обучение. Туры' },
  { id: 3, name: 'Турниры. Соревнования' },
];

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const prevScrollTop = useRef(0);
  const activeType = useSelector(authActiveType);
  const activeId = useSelector(authActiveIdSelector);

  const profile = useSelector(
    activeType === 'administeredProfile' ? profileByIdSelector : profileSelector,
  );

  const [scrollActiveBottom, setScrollActiveBottom] = useState(false);
  const { showSearch, setShowSearch, show, handleClose } = useContext(StateContext);
  const [showAvatarLoaderModal, setShowAvatarLoaderModal] = useState(false);

  const navigeRoute = link => {
    navigate(`/${link}`, { state: { prevPath: pathname } });
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight } = document.documentElement;

      prevScrollTop.current = scrollTop;

      const scrollableHeight = scrollHeight - window.innerHeight;
      setScrollActiveBottom(window.scrollY >= scrollableHeight - 800);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (hash.includes('#')) window.location.href = '#registration';
    else window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (activeType === 'administeredProfile') {
      dispatch(getProfileById({ id: activeId }));
    } else {
      dispatch(getProfile());
    }
  }, [profile?.id, activeId, activeType]);

  useEffect(() => {
    if (showSearch) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
    return () => document.documentElement.classList.remove('lock');
  }, [showSearch]);

  const zeroId = addLeadingZeros(profile?.id);
  const number = (profile?.name?.length ?? 0) + (profile?.surname?.length ?? 0);
  return (
    <div>
      {!show && <Header profile={profile} setShowSearch={setShowSearch} showSearch={showSearch} />}
      <Modal isOpen={show} onClose={handleClose}>
        <StyledAvatar index={0} />
      </Modal>
      <main>
        <div
          className="  flex cursor-pointer flex-col items-center"
          style={{ paddingTop: `${px(29)}` }}
        >
          <div aria-hidden onClick={() => setShowAvatarLoaderModal(true)}>
            {profile?.profileImage?.url ? (
              <img
                alt="avatar"
                style={{
                  width: px(40),
                  height: px(40),
                  borderRadius: px(40),
                }}
                src={`${process.env.REACT_APP_STATIC_URL}${profile?.profileImage?.url}`}
              />
            ) : (
              <Icon
                className="cursor-pointer"
                width={px(40)}
                height={px(40)}
                viewBox="0 0 40 40"
                type="USERPHOTO"
              />
            )}
          </div>
          <ImageLoaderModal
            show={showAvatarLoaderModal}
            noDelete
            onClose={() => setShowAvatarLoaderModal(false)}
            onChange={async ({ avatar }) => {
              try {
                if (avatar?.src) {
                  const formData = new FormData();
                  formData.append('files', await urlToBlob(avatar?.src));
                  formData.append('files', await urlToBlob(avatar?.src));
                  await dispatch(profileAvatar({ data: formData, id: profile?.id }));
                  await dispatch(
                    activeType === 'administeredProfile'
                      ? getProfileById({ id: activeId })
                      : getProfile(),
                  );
                }
              } catch (error) {
                console.log(error);
              }
            }}
            items={[
              {
                label: 'Аватар',
                key: 'avatar',
                image: profile?.profileImage?.url
                  ? {
                      src: `${process.env.REACT_APP_STATIC_URL}${profile?.profileImage?.url}`,
                    }
                  : undefined,
                crop: {
                  width: 204,
                  height: 204,
                  style: {
                    borderRadius: px(204),
                  },
                },
              },
            ]}
          />

          <div
            className="w-full "
            style={{
              padding: `${0} ${px(15)}`,
            }}
          >
            <h1
              className="overflow-x-hidden"
              style={{
                fontSize: `${px(32)}`,
                lineHeight: `${px(43)}`,
              }}
            >
              {number <= 15
                ? `${profile?.name} ${profile?.surname || ''}`
                : profile?.name?.slice(0, 15)?.length === 15
                  ? `${profile?.name?.slice(0, 14)}...`
                  : profile?.name?.slice(0, 15)}
            </h1>
          </div>

          <h1 style={{ fontSize: `${px(20)}`, lineHeight: `${px(26)}` }}>{`ID: ${zeroId}`}</h1>
        </div>

        <div
          style={{
            paddingTop: `${px(10)}`,
            paddingLeft: `${px(15)}`,
            paddingRight: `${px(15)}`,
            paddingBottom: px(10),
          }}
        >
          {OPTIONS?.filter(item =>
            activeType === 'administeredProfile' ? !item.personal : item,
          )?.map(item =>
            item?.phoneNumber ? (
              <a key={item.id} className=" text-[black]" href={item?.link}>
                <div
                  aria-hidden
                  className={`border-b-solid flex cursor-pointer flex-row  
                    items-center border-b-[1px] border-b-black
                  `}
                  style={{
                    paddingTop: `${px(19)}`,
                    paddingBottom: item.bottom,
                    height: px(70),
                  }}
                >
                  {item?.iconName && (
                    <div
                      style={{
                        width: `${px(48)}`,
                      }}
                    >
                      <Icon
                        width={px(item.width)}
                        height={px(item.height)}
                        viewBox={item.viewBox}
                        type={item.iconName}
                      />
                    </div>
                  )}
                  <h1
                    className="font-normal "
                    style={{
                      fontSize: `${px(20)}`,
                      lineHeight: `${px(26)}`,
                    }}
                  >
                    {item.name}
                  </h1>
                </div>
              </a>
            ) : (
              <div
                aria-hidden
                className={`flex cursor-pointer flex-row items-center  ${
                  item.id === OPTIONS.length ? '' : 'border-b-solid border-b-[1px] border-b-black'
                }`}
                style={{
                  paddingTop: `${px(19)}`,
                  paddingBottom: item.bottom,
                  height: px(70),
                }}
                key={item.id}
                onClick={() => navigeRoute(item?.link)}
              >
                {item?.iconName && (
                  <div
                    style={{
                      width: `${px(48)}`,
                    }}
                  >
                    <Icon
                      width={px(item.width)}
                      height={px(item.height)}
                      viewBox={item.viewBox}
                      type={item.iconName}
                    />
                  </div>
                )}
                <h1
                  className="font-normal "
                  style={{
                    fontSize: `${px(20)}`,
                    lineHeight: `${px(26)}`,
                  }}
                >
                  {item.name}
                </h1>
              </div>
            ),
          )}
        </div>
        <Banner />
        {activeType !== 'administeredProfile' && (
          <div
            className="relative flex flex-col items-start"
            style={{ paddingLeft: `${px(15)}`, paddingRight: `${px(15)}` }}
          >
            <div id="registration" className="absolute" style={{ top: px(-60) }} />
            <div
              className="w-full"
              style={{
                fontSize: `${px(32)}`,
                lineHeight: `${px(43)}`,
                paddingTop: `${px(20)}`,
              }}
            >
              Добавить кабинет
            </div>
            <p className="font-normal" style={{ fontSize: `${px(15)}`, lineHeight: `${px(20)}` }}>
              Создавайте и управляйте. Делитесь своим опытом и зарабатывайте.
            </p>
          </div>
        )}
        {activeType !== 'administeredProfile' && (
          <div
            style={{
              paddingBottom: px(16),
            }}
          >
            <div
              style={{
                paddingTop: ` ${px(10)}`,
                marginBottom: `${px(20)}`,
                paddingRight: `${px(15)}`,
                paddingLeft: `${px(15)}`,
              }}
            >
              <div className="border-[2px] border-solid border-black border-opacity-30">
                {PAGE_OPTIONS?.map(item => (
                  <div
                    key={item.id}
                    className={`flex  
                  flex-row items-center border-opacity-30 ${
                    item.id === 3 ? 'border-b-0' : 'border-b-solid border-b-[2px] border-black'
                  }`}
                    /* onClick={
                      item.id === 1
                        ? () => navigate(`/registration-object`, { state: { type: 'object' } })
                        : item.id === 2
                          ? () => navigate(`/registration-object`, { state: { type: 'guide' } })
                          : () =>
                              navigate(`/registration-object`, { state: { type: 'competition' } })
                    }
                    onKeyDown={
                      item.id === 1
                        ? () => navigate(`/registration-object`, { state: { type: 'object' } })
                        : item.id === 2
                          ? () => navigate(`/registration-object`, { state: { type: 'guide' } })
                          : () =>
                              navigate(`/registration-object`, { state: { type: 'competition' } })
                    } */
                  >
                    <h1
                      className="
                      font-normal "
                      style={{
                        fontSize: `${px(20)}`,
                        lineHeight: `${px(26)}`,
                        paddingTop: px(22),
                        paddingBottom: px(22),
                        paddingLeft: `${px(18)}`,
                      }}
                    >
                      {item.name}
                    </h1>
                  </div>
                ))}
              </div>
            </div>

            <Banner />
          </div>
        )}
        <Footer />
        <ScrollUpToTopArrow
          scrollActive={scrollActiveBottom}
          handleScrollToTop={handleScrollToTop}
        />
      </main>
    </div>
  );
};

export default ProfilePage;
