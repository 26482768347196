/* eslint-disable no-shadow */
/* eslint-disable unicorn/no-null */
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { addAttachment } from '../actions/attachment';
import { updateContacts } from '../actions/contacts';
import { getEvent as getEventAction, updateEvent } from '../actions/event';
import { deleteAttachment } from '../api/attachment';
import { getEvent } from '../api/event';
import {
  objectDescriptionPageData,
  objectDescriptionPageTextareaSizes,
  timeDate,
} from '../constants/Component';
import { px } from '../constants/Px';
import {
  BackButton,
  CircleButton,
  DynamicImageSlider,
  ScrollButtonsContainer,
} from '../new-architecture/shared';
import { urlToBlob } from '../new-architecture/utils/blob';
import { authActiveIdSelector } from '../selectors/auth';
import { galleryObjectSelector, imagesObjectSelector } from '../selectors/get-event';
import { TypesIcon } from '../types/TypesIcon';
import { ContactsObjectDescriptionPage } from './contacts-object-description-page';
import { DatePicker } from './date-picker';
import { Icon } from './Icon';
import { MapsObjectDescription } from './maps-object-description';
import { ObjectNameObjectDescriptionPage } from './object-name-object-description-page';
import RulesObjectDescriptionPage from './rules-object-description-page';

const removeAttachments = async (eventId, type) => {
  const {
    data: { EventAttachment: attachments },
  } = await getEvent({ id: eventId });

  const filtered = attachments?.filter?.(({ type: t }) => t === type);

  if (filtered?.length) {
    await Promise.all(filtered?.map(({ id }) => deleteAttachment({ id })));
  }
};

export const ObjectDescriptionComponent = ({
  data,
  contacts,
  isVisible,
  styledComponent,
  setStyledComponent,
  setAddSlide,
  schemaIndex,
  setSchemaIndex,
}) => {
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [isPeriod, setIsPeriod] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const images = useSelector(imagesObjectSelector);
  const gallery = useSelector(galleryObjectSelector);

  console.log('data', images, gallery);

  const eventId = useSelector(authActiveIdSelector);

  const [timePhone1, setTimePhone1] = useState({
    hour: contacts?.mainPhoneStartTime || 'Время.',
  });
  const [endTimePhone1, setEndTimePhone1] = useState({
    hour: contacts?.mainPhoneEndTime || 'Период',
  });
  const [timePhone2, setTimePhone2] = useState({
    hour: contacts?.secondaryPhoneStartTime || 'Время.',
  });
  const [endTimePhone2, setEndTimePhone2] = useState({
    hour: contacts?.secondaryPhoneEndTime || 'Период',
  });

  const [inputsData, setInputsData] = useState({
    objectName: data?.name,
    objectDescription: data?.description,
    objectRules: contacts?.termsAndRules,
    country: contacts?.country,
    region: contacts?.region,
    area: contacts?.district,
    city: contacts?.town,
    street: contacts?.street,
    phone1: contacts?.mainPhone,
    phone2: contacts?.secondaryPhone,
    email: contacts?.email,
    whatsApp: contacts?.whatsAppLink,
    telegram: contacts?.telegramLink,
    viber: contacts?.viberLink,
    geo: contacts?.coordinates,
    network: contacts?.socialPageLink,
    webPage1: contacts?.websiteLink,
    webPage2: '',
    accountId: contacts?.eventId,
  });

  const updatedData = () => {
    setInputsData(inputsData => {
      dispatch(
        updateContacts({
          id: data?.id,
          contactsData: {
            country: inputsData?.country,
            region: inputsData?.region,
            district: inputsData?.area,
            street: inputsData?.street,
            town: inputsData?.city,
            termsAndRules: inputsData?.objectRules,
            mainPhone: inputsData?.phone1,
            mainPhoneStartTime: timePhone1?.hour,
            mainPhoneEndTime: endTimePhone1?.hour,
            secondaryPhone: inputsData?.phone2,
            secondaryPhoneStartTime: timePhone2?.hour,
            secondaryPhoneEndTime: endTimePhone2?.hour,
            email: inputsData?.email,
            whatsAppLink: inputsData?.whatsApp,
            telegramLink: inputsData?.telegram,
            viberLink: inputsData?.viber,
            socialPageLink: inputsData?.network,
            websiteLink: inputsData?.webPage1,
            coordinates: inputsData?.geo,
          },
        }),
      );
      return inputsData;
    });
  };
  const updatedNameAndDescription = () => {
    setInputsData(async inputsData => {
      await dispatch(
        updateEvent({
          dataObj: {
            name: inputsData?.objectName,
            eventType: data?.eventType,
            discipline: data?.discipline,
            discount: data?.discount,
            direction: data?.direction,
            ball: data?.ball,
            participant: data?.participant,
            price: data?.price,
            service: data?.service,
            standing: data?.standing,
            tournament: data?.tournament,
            coordinates: data?.coordinates,
            country: data?.country,
            region: data?.region,
            blockMessage: data?.blockMessage,
            description: inputsData.objectDescription,
          },
          id: data?.id,
        }),
      );

      await dispatch(getEventAction({ id: data?.id }));
      return inputsData;
    });
  };

  const update = useCallback(
    debounce(() => updatedData(), 2000),
    [],
  );

  const updateNameAndDescription = useCallback(
    debounce(() => updatedNameAndDescription(), 2000),
    [],
  );
  const cleanAll = () => {
    dispatch(
      updateContacts({
        id: data?.id,
        contactsData: {
          country: '',
          region: '',
          district: '',
          street: '',
          town: '',
          termsAndRules: '',
          mainPhone: '',
          mainPhoneStartTime: '',
          mainPhoneEndTime: '',
          secondaryPhone: '',
          secondaryPhoneStartTime: '',
          secondaryPhoneEndTime: '',
          email: '',
          whatsAppLink: '',
          telegramLink: '',
          viberLink: '',
          socialPageLink: '',
          websiteLink: '',
          coordinates: [],
        },
      }),
    );
    dispatch(
      updateEvent({
        dataObj: {
          name: '',
          eventType: data?.eventType,
          discipline: data?.discipline,
          discount: data?.discount,
          direction: data?.direction,
          ball: data?.ball,
          participant: data?.participant,
          price: data?.price,
          service: data?.service,
          standing: data?.standing,
          tournament: data?.tournament,
          coordinates: data?.coordinates,
          country: data?.country,
          region: data?.region,
          blockMessage: data?.blockMessage,
          description: '',
        },
        id: data?.id,
      }),
    );
  };

  const handleInputChange = (key, value) => {
    if (key === 'country') {
      setInputsData(previousInputs => ({
        ...previousInputs,
        region: '',
        area: '',
      }));
    }
    if (key === 'geo') {
      setInputsData(previousInputs => ({
        ...previousInputs,
        [key]: [value],
      }));
    } else {
      setInputsData(previousInputs => ({
        ...previousInputs,
        [key]: value,
      }));
    }

    update();
  };

  const openPicker = (id_, label) => {
    setId(id_);
    setIsPeriod(label);
    setShow(true);
  };

  const handleClear = () => {
    setInputsData(objectDescriptionPageData);
    cleanAll();
    updatedNameAndDescription();

    setTimePhone1({
      hour: 'Время.',
    });
    setTimePhone2({
      hour: 'Время.',
    });
    setEndTimePhone2({
      hour: 'Период',
    });
    setEndTimePhone1({
      hour: 'Период',
    });
  };
  useEffect(() => {
    if (!show) {
      updatedData();
    }
  }, [show]);

  return (
    <div>
      <DatePicker
        singleItem
        title="Время. Период"
        setShow={setShow}
        show={show}
        data={timeDate}
        setPickerValue={
          id === 6 && isPeriod === 'start'
            ? setTimePhone1
            : id === 6 && isPeriod === 'end'
              ? setEndTimePhone1
              : id === 7 && isPeriod === 'start'
                ? setTimePhone2
                : id === 7 && isPeriod === 'end'
                  ? setEndTimePhone2
                  : () => {}
        }
        pickerValue={
          id === 6 && isPeriod === 'start'
            ? timePhone1
            : id === 6 && isPeriod === 'end'
              ? endTimePhone1
              : id === 7 && isPeriod === 'start'
                ? timePhone2
                : id === 7 && isPeriod === 'end'
                  ? endTimePhone2
                  : ''
        }
        buttonTitle="Применить"
        isLine
      />
      <BackButton
        onClick={async () => {
          await updatedNameAndDescription();
          await updatedData();
          navigate(`/user-object`);
        }}
      />

      <div
        style={{
          paddingBottom: px(80),
          paddingTop: px(20),
        }}
      >
        <ObjectNameObjectDescriptionPage
          title="Название. Фото. Описание объекта"
          subTitle="Работа с сервисом будет возможна, если Вы заполните все формы "
          setInputsData={setInputsData}
          inputsData={inputsData}
          data={objectDescriptionPageTextareaSizes?.slice(0, 2)}
          update={updateNameAndDescription}
        />

        {/* <GalleryObjectDescription
          data={galleryObjectDescription}
          title=" Логотип. Открытка, … "
          subTitle="Редактируйте по вашему усмотрению"
          index={index}
          setIndex={setIndex}
          galleryIndex={galleryIndex}
          setGalleryIndex={setGalleryIndex}
          styledComponent={styledComponent}
          setStyledComponent={setStyledComponent}
          setAddSlide={setAddSlide}
        /> */}

        <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
          <div
            className="flex flex-row items-center"
            style={{ gap: px(16.86), marginTop: px(33), marginBottom: px(26.26) }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 24"
              width={px(31.14)}
              height={px(27.74)}
            />

            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Логотип. Открытка, …
            </p>
          </div>
        </div>
        <DynamicImageSlider
          items={[
            {
              label: 'Логотип',
              key: 'logo',
              src: images?.find(i => i?.type === 'LOGO')?.src,
              crop: {
                width: 130,
                height: 130,
                style: {
                  borderRadius: px(15),
                },
              },
            },
            {
              label: 'Открытка',
              key: 'postcard',
              src: images?.find(i => i?.type === 'POSTCARD')?.src,
              crop: {
                width: 141,
                height: 110,
              },
            },
            {
              label: 'Обложка',
              key: 'banner',
              src: images?.find(i => i?.type === 'BANNER')?.src,
              crop: {
                width: 320,
                height: 183,
              },
            },
            {
              label: 'Предложения. Игра',
              key: 'game',
              src: images?.find(i => i?.type === 'GAME')?.src,
              crop: {
                width: 141,
                height: 110,
                style: {
                  borderRadius: `${px(15)} ${px(15)} 0 0`,
                },
              },
            },
            {
              label: 'Предложения. Отдых',
              key: 'recreation',
              src: images?.find(i => i?.type === 'RECREATION')?.src,
              crop: {
                width: 141,
                height: 110,
                style: {
                  borderRadius: `${px(15)} ${px(15)} 0 0`,
                },
              },
            },
            {
              label: 'Предложения. Услуги',
              key: 'service',
              src: images?.find(i => i?.type === 'SERVICE')?.src,
              crop: {
                width: 141,
                height: 110,
                style: {
                  borderRadius: `${px(15)} ${px(15)} 0 0`,
                },
              },
            },
          ]}
          onChange={async files => {
            await Promise.all(
              ['LOGO', 'POSTCARD', 'BANNER', 'GAME', 'RECREATION', 'SERVICE'].map(type =>
                removeAttachments(data?.id, type),
              ),
            );

            if (files?.length) {
              await Promise.all(
                files.map(async image => {
                  if (image?.src) {
                    const formData = new FormData();

                    formData.append('file', await urlToBlob(image?.src));
                    formData.append('eventId', data?.id);
                    formData.append('type', image?.key?.toUpperCase());
                    dispatch(addAttachment(formData));
                  }
                }),
              );
            }
          }}
        />

        <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
          <div
            className="flex flex-row items-center"
            style={{ gap: px(16.86), marginTop: px(26), marginBottom: px(23.26) }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 24"
              width={px(31.14)}
              height={px(27.74)}
            />

            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Галерея
            </p>
          </div>
        </div>
        <DynamicImageSlider
          items={gallery?.filter?.(({ src }) => src !== '')?.map(image => ({ src: image?.src }))}
          onChange={async files => {
            await removeAttachments(data?.id, 'GALLERY');

            if (files?.length) {
              await Promise.all(
                files.map(async image => {
                  const formData = new FormData();

                  formData.append('file', await urlToBlob(image?.src));
                  formData.append('eventId', data?.id);
                  formData.append('type', 'GALLERY');
                  dispatch(addAttachment(formData));
                }),
              );
            }
          }}
        />

        <RulesObjectDescriptionPage
          setInputsData={setInputsData}
          inputsData={inputsData}
          update={update}
        />

        <MapsObjectDescription
          schemaIndex={schemaIndex}
          setSchemaIndex={setSchemaIndex}
          styledComponent={styledComponent}
          setStyledComponent={setStyledComponent}
          setAddSlide={setAddSlide}
        />

        <ContactsObjectDescriptionPage
          inputsData={inputsData}
          endTimePhone1={endTimePhone1}
          endTimePhone2={endTimePhone2}
          timePhone1={timePhone1}
          handleInputChange={handleInputChange}
          timePhone2={timePhone2}
          openPicker={openPicker}
        />

        <ScrollButtonsContainer>
          <CircleButton
            onClick={handleClear}
            icon={{
              type: TypesIcon.ROUNDEDRETURN,
              viewBox: '0 0 28.451 26.164',
              width: px(28.451),
              height: px(26.164),
              className: 'text-[#0a84fe]',
              fill: '#0a84fe',
            }}
          />
        </ScrollButtonsContainer>
      </div>
    </div>
  );
};

export default ObjectDescriptionComponent;
