// eslint-disable-next-line import/no-unresolved
import { request } from '@/helpers/api/request';

export const addCompetitionRegistration = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `competition-registration`,
    /* headers: {
      'Content-Type': 'multipart/form-data;',
    }, */
    method: 'POST',
    data,
  });

  return response;
};
