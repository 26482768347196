import * as authApi from '@/api/feedback';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addModal } from '../../modal/addModal';

export const ADD_FEEDBACK = 'appeal/addObjectFeedback';

const onSuccess = () => async (dispatch) => {
    dispatch(
        addModal({
          id: 16,
          data: {
            title: 'Спасибо за оценку и отзыв',
         buttonLeftText: 'Ok',
            single: true,
        
          },
          path: 'feedback'
        }),
      );
 
  };

export const addFeedback = createAsyncThunk(
    ADD_FEEDBACK,
  createRequest({
    type: ADD_FEEDBACK,
    loader: (data) => authApi.addFeedback(data),
    onSuccess,
  }),
);
