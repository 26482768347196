import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { locationEvent } from '../actions/event';
import { px } from '../constants/Px';
import usePosition from '../hooks/usePosition';
import { BackButton, CircleButton } from '../new-architecture/shared';
import { cardsSelector } from '../selectors/event';
import { TypesIcon } from '../types/TypesIcon';
import { Button } from './button';
import { RangeSlider } from './range-slider';
import { SearchMenuFilterBlock } from './search-menu-filter-block';
import { StateContext } from './state-provider';

export const SearchMenuItemFilter = ({
  selectedItem,
  setResultsListItems,
  resultsListItems,
  setResultsListOpen,
  setSelectedItem,
}) => {
  const { selectedOptions, setSelectedOptions } = useContext(StateContext);
  const { setSelectedOptionsTitle, selectedOptionsTitle } = useContext(StateContext);
  const { position } = usePosition();

  const navigate = useNavigate();
  const [isFiltersSelected, setIsFiltersSelected] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const fetchedData = useSelector(cardsSelector);

  const getDefaultStateMapping = () => ({
    discount: [],
    discipline: [],
    tournament: [],
    participant: [],
    standing: [],
    direction: [],
    ball: [],
    price: [],
    service: [],
    gameType: 'sport',
    location: {
      preferredDistance: 10_000,
      currentLat: position ? position?.latitude : 0,
      currentLon: position ? position?.longitude : 0,
    },
  });

  const [filterTotalOptions, setFilterTotalOptions] = useState(getDefaultStateMapping);
  const [scrollValue, setScrollValue] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setFilterTotalOptions({
      ...filterTotalOptions,
      location: {
        ...filterTotalOptions?.location,
        currentLat: position && position?.latitude,
        currentLon: position && position?.longitude,
      },
      eventType: selectedItem?.eventType,
    });
  }, [position]);

  const setFilerData = useCallback(
    debounce(
      () =>
        setFilterTotalOptions(prevState => {
          dispatch(locationEvent({ skip: 0, take: 100, where: prevState }));
          return prevState;
        }),
      100,
    ),
    [],
  );

  useEffect(() => {
    async function effect() {
      const result = await dispatch(
        locationEvent({ skip: 0, take: 100, where: filterTotalOptions }),
      );
      setIsLoaded(true);
    }
    effect();
  }, []);

  useEffect(() => {
    setFilerData();
    setResultsListItems(fetchedData);
  }, [filterTotalOptions]);
  const filtersCount = useMemo(() => (isLoaded ? fetchedData?.length : 0), [isLoaded, fetchedData]);

  useEffect(() => {
    const hasSelectedFilters = Object.values(filterTotalOptions).some(value => {
      if (Array.isArray(value)) {
        return value?.length > 0;
      }
      return false;
    });
    setIsFiltersSelected(hasSelectedFilters);
  }, [filterTotalOptions]);

  const handleNavigate = () => {
    navigate(-1);
    setSelectedItem();
  };

  const handleClear = () => {
    setSelectedOptions([]);
    setSelectedOptionsTitle([]);
  };

  function scrollHandler() {
    if (window.scrollY > scrollValue && scrollValue > 50) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setScrollValue(window.scrollY);
  }

  window.addEventListener('scroll', scrollHandler);

  return (
    <div className="global-container">
      <BackButton onClick={handleNavigate} />
      <div
        style={{
          paddingTop: px(20),
          paddingBottom: px(22),
        }}
      >
        <div className="search-menu-item-filter-content">
          <p
            style={{
              width: px(210),
              fontSize: px(32),
              lineHeight: px(32),
            }}
            className="font-light"
          >
            {selectedItem?.title}
          </p>
          <div className="search-menu-item-filter-horiz-slider">
            <div className="search-menu-item-filter-horiz-slider-data">
              <div style={{ display: 'block', width: px(78), textAlign: 'right' }}>
                <p style={{ display: 'inline' }}>
                  {filterTotalOptions?.location?.preferredDistance === 10_000
                    ? '\u221E'
                    : filterTotalOptions?.location?.preferredDistance}
                </p>
                <span>км</span>
              </div>
              <div style={{ display: 'block', width: px(78), textAlign: 'right' }}>
                <p style={{ display: 'inline' }}>{filtersCount}</p>
                <span>шт.</span>
              </div>
            </div>
            <RangeSlider
              min={0}
              marks={[8535]}
              max={10_000}
              value={filterTotalOptions?.location?.preferredDistance}
              onChange={preferredDistance =>
                setFilterTotalOptions({
                  ...filterTotalOptions,
                  location: {
                    ...filterTotalOptions?.location,
                    preferredDistance,
                  },
                })
              }
            />
          </div>

          <div className="search-menu-item-filter-items">
            {selectedItem?.filters?.map(filter => (
              <SearchMenuFilterBlock
                selectedOptionsTitle={selectedOptionsTitle}
                setSelectedOptionsTitle={setSelectedOptionsTitle}
                key={filter?.value}
                filter={filter}
                onChange={selected => {
                  setFilterTotalOptions({
                    ...filterTotalOptions,
                    [filter?.value]: selected,
                  });
                }}
                position={position}
              />
            ))}
            <Button
              marginTop={0}
              borderRadius={0}
              height={60}
              onClick={() => {
                setResultsListOpen(true);
                handleNavigate();
              }}
              disabled={!isFiltersSelected}
            >
              Применить
            </Button>
            <div
              id="scroll"
              style={{
                gap: px(10),
                bottom: px(-8),
              }}
              className={` row  fixed  left-1/2 z-[1000] flex  -translate-x-1/2 -translate-y-1/2 transform items-center justify-center transition-all duration-200
             ${isVisible ? 'opacity-100' : 'opacity-0'} `}
            >
              {!!selectedOptions?.length && (
                <CircleButton
                  onClick={handleClear}
                  icon={{
                    type: TypesIcon.ROUNDEDRETURN,
                    viewBox: '0 0 28.451 26.164',
                    width: px(28.451),
                    height: px(26.164),
                    className: 'text-[#0a84fe]',
                    fill: '#0a84fe',
                  }}
                />
              )}
              <CircleButton
                onClick={handleNavigate}
                icon={{
                  type: TypesIcon.SEARCH,
                  viewBox: '0 0 22.357 22.357',
                  width: px(22.357),
                  height: px(22.357),
                  className: 'text-[#0a84fe]',
                  fill: '#0a84fe',
                }}
              />
            </div>
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};
