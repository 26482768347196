import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/notification';
import { createRequest } from '../../../helpers/api/createRequest';

export const ADD_NOTIFICATION_EVENT = 'notification/addNotificationEvent';

export const addNotificationEvent = createAsyncThunk(
  ADD_NOTIFICATION_EVENT,
  createRequest({
    type: ADD_NOTIFICATION_EVENT,
    loader: data => authApi.addNotificationEvent(data),
  }),
);
