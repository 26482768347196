import React from 'react';
import { useNavigate } from 'react-router';

import { ImgLogoHeader } from './header-component/img-logo-header';

export const SearchInputResultsItem = ({ title, location, object, setShowSearch }) => {
  const navigate = useNavigate();

  return (
    <div
      className="search-input-results-item-container"
      onClick={() => {
        setShowSearch(false);
        navigate(`/object/${object?.id}`);
      }}
      aria-hidden
    >
      <div className="search-input-results-item-svg shrink-0">
        <ImgLogoHeader
          activeType="object"
          eventSelectorData={object}
          logo={object?.EventAttachment?.find(item => item?.type === 'LOGO')?.imageUrl}
        />
      </div>
      <div className="search-input-results-item-content">
        <h3 className="line-clamp-1">{title}</h3>
        <p className="line-clamp-1">{`${location?.district ? `${location?.district}, ` : ''}${
          location?.town ? `${location?.town}, ` : ''
        }${location?.street || ''}`}</p>
      </div>
    </div>
  );
};

export default SearchInputResultsItem;
