import { createReducer } from '@reduxjs/toolkit';

import { findCard, getCards } from '../../actions/card';
import { addYearsToDate } from '../../constants/Function';

export const card = createReducer({}, builder => {
  builder.addCase(findCard.fulfilled, (state, { payload }) => ({
    ...state,
    card: payload?.data,
  }));
  builder.addCase(getCards.fulfilled, (state, { payload }) => ({
    ...state,
    cards: payload?.data?.filter(item => !item?.deletedAt?.length),
    deletedCards: '',
    cardsSlider: payload?.data
      ?.filter(item => !item?.deletedAt?.length)
      ?.map(item => ({
        id: item?.id,
        percent: item?.cardDiscount,
        cardDate: addYearsToDate(item?.activeDate),
        name: item?.name,
        imageUrl: item?.image?.url,
        showLogo: item?.displayLogo,
        openUntill: item?.openUntill,
      })),
  }));
});
