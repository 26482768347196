/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// TODO: move to new arch
import { createLocation } from '../../../../../actions/location/thunks';
import { StyledInput } from '../../../../../components/styled-input';
import { px } from '../../../../../constants/Px';
import { authSelector } from '../../../../../selectors/auth';
import { useProfile } from '../../../hooks';

const LocationButton = ({ item, onClick }) => (
  <button
    className="location-button"
    key={item.id}
    type="button"
    onClick={onClick}
    style={{
      width: px(290),
      height: px(60),
      paddingLeft: px(18),
      marginBottom: px(10),
      fontSize: px(20),
      fontWeight: 400,
      textAlign: 'left',
    }}
  >
    {item.name}
  </button>
);

export const AddLocation = ({ onClose }) => {
  const navigate = useNavigate();
  const auth = useSelector(authSelector);

  const [locationTitle, setLocationTitle] = useState('');
  const dispatch = useDispatch();
  const {
    data: { locations, object },
    updateFunction: updateProfile,
    setData: setProfile,
  } = useProfile();

  const changeLocationTitle = e => {
    setLocationTitle(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      const create = async () => {
        const creation = await dispatch(
          createLocation({ name: locationTitle, eventId: object?.id }),
        );
        console.log('creation', creation);
        await updateProfile();
      };
      create();
      setLocationTitle('');
    }
  };

  useEffect(() => {
    if (!auth) navigate('/login');
  }, []);

  const handleButtonClick = item => {
    setProfile({ selectedLocationId: item?.id });
    if (onClose) onClose();
  };

  return (
    <div style={{ overflowY: 'auto', height: '100%', paddingBottom: px(30) }}>
      <p
        className="mb-[20px] text-center"
        style={{
          paddingTop: px(5),
          marginBottom: px(30),
        }}
      >
        Предложения
      </p>
      <div className="container">
        <p
          style={{
            fontSize: px(32),
            lineHeight: px(43),
          }}
        >
          Создать локацию
        </p>
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
            marginBottom: px(48),
          }}
        >
          Добавляйте водоемы, объекты, места отдыха и структуру для бронирования
        </p>
        <div style={{ marginBottom: px(10) }}>
          <StyledInput
            value={locationTitle}
            title="Название места"
            placeholder="ХХХХХХХХ"
            onChange={e => changeLocationTitle(e)}
            onKeyDown={e => handleKeyPress(e)}
          />
        </div>
        {locations?.map(item => (
          <LocationButton key={item.id} item={item} onClick={() => handleButtonClick(item)} />
        ))}
      </div>
    </div>
  );
};

export default AddLocation;
