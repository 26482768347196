/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { AddressLineFormat, FixTruncate } from './line-component';
import { useWindowSize } from './line-component/useWndiwSize';

export const CardGuide = ({
  className,
  image,
  item,
  fullSize,
  title,
  addressTitle,
  address,
  outlined,
  link,
  id,
  competitionImage,
  competitions,
  blocked,
  setShowPopup,
  ...props
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const fixTruncateKey = `guide ${width} ${id}`;

  const handleClick = id =>
    blocked === 'BLOCKED'
      ? navigate('/holiday', {
          state: {
            blockMessage: item?.blockMessage || '',
            endDate: item?.blockEndsAt,
            link: pathname,
          },
        })
      : navigate(`/guide/${id}`);

  return (
    <>
      {link ? (
        <Link
          target="_blank"
          to={link}
          {...props}
          className={`card ${outlined ? 'card--outlined' : ''} ${className || ''} home-events-card`}
        >
          {image || competitionImage ? (
            <img
              style={{ height: px(304), width: px(141) }}
              src={`${competitions ? competitionImage : image}`}
              alt="card-pic"
            />
          ) : (
            <div style={{ height: px(304), width: px(141), backgroundColor: 'white' }} />
          )}
        </Link>
      ) : (
        <div
          {...props}
          style={{
            height: px(304),
            width: px(141),
            borderRadius: 0,
            position: 'relative',
            marginBottom: px(16),
          }}
          onClick={() => handleClick(id)}
          aria-hidden
        >
          <div className="relative mx-auto">
            {blocked === 'ACTIVE' ? (
              <></>
            ) : (
              <Icon
                className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform"
                viewBox="0 0 68 68"
                width={px(68)}
                height={px(68)}
                type={TypesIcon.BIGLOCK}
              />
            )}
            {image || competitionImage ? (
              <img
                style={{ height: px(304), width: px(141) }}
                src={`${competitions ? competitionImage : image}`}
                className={`card__image ${fullSize ? 'card__image--full' : ''}`}
                alt="card-pic"
              />
            ) : (
              <div style={{ height: px(304), width: px(141), backgroundColor: 'white' }} />
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              top: px(195),
              color: image || competitionImage ? 'white' : 'black',
              padding: `${px(3)} ${px(8)} ${px(8)} ${px(8)}`,
              height: px(102),
              lineHeight: px(20),
              textAlign: 'left',
            }}
            className="w-full text-left "
          >
            {title && (
              <>
                <p
                  style={{
                    fontSize: px(20),
                    height: px(40),
                    lineHeight: px(20),
                  }}
                  className="line-clamp-2 font-bold opacity-100"
                >
                  <FixTruncate
                    fixTruncateKey={fixTruncateKey}
                    title={title}
                    linesQuantity={2}
                    spanClass="break-all"
                  />
                </p>
              </>
            )}
            <div style={{ marginTop: px(2) }}>
              <div style={{ height: px(35) }}>
                <AddressLineFormat line={addressTitle} isTitle />
              </div>
              <div style={{ height: px(16) }}>
                <AddressLineFormat line={address} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardGuide;
