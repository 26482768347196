import { request } from '../../helpers/api/request';

export const codeRestore = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  await request({
    url: `auth/code/restore`,
    method: 'POST',
    data,
  });
};
