/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { createGroup, getGroup } from '../../actions/allow-group/thunks';
import { getLocations } from '../../actions/location/thunks';
import {
  AccessSettings,
  Button,
  Checkbox,
  DropdownList,
  FishingMenu,
  LocationCreator,
  SectorModal,
  SortModal,
} from '../../components';
import { DraggableModal } from '../../components/draggable-modal';
import { Icon } from '../../components/Icon';
import { Modal, useModal } from '../../components/portals';
import { sortValues } from '../../constants/Component/sector';
import { px } from '../../constants/Px';
import { CircleButton, ScrollButtonsContainer } from '../../new-architecture/shared';
import { authSelector } from '../../selectors/auth';
import { locationsSelector } from '../../selectors/location';
import { TypesIcon } from '../../types/TypesIcon';

const toFormData = object => {
  const formData = new FormData();
  for (const key of Object.keys(object)) {
    if (key === 'images') object[key].forEach(item => formData.append('images', item));
    else if (key === 'schemas') object[key].forEach(item => formData.append('schemas', item));
    else if (Array.isArray(object[key])) {
      if (typeof object[key][0] === 'object') {
        for (const [index, item] of object[key].entries()) {
          for (const [k, value] of Object.entries(item)) {
            formData.append(`${key}[${index}][${k}]`, value);
          }
        }
      } else {
        for (const [index, item] of object[key].entries()) {
          formData.append(`${key}[${index}]`, item);
        }
      }
    } else if (typeof object[key] === 'object') {
      for (const [k, value] of Object.entries(object[key])) {
        formData.append(`${key}[${k}]`, value);
      }
    } else formData.append(key, object[key]);
  }
  return formData;
};

const mapPlace = (data, dataPickerFilters) => ({
  freeZone: { active: data.includes('free'), count: dataPickerFilters?.freeZone },
  bridge: data.includes('bridge'),
  pontoon: data.includes('pontoon'),
  english: data.includes('english'),
  wild: data.includes('wild'),
});

export const OfferMenuPage = () => {
  const { isOpen: location, openModal: openLocation, closeModal: closeLocation } = useModal();
  const { isOpen: access, openModal: openAccess, closeModal: closeAccess } = useModal();
  const navigate = useNavigate();
  const [isLocked, setIsLocked] = useState(false);
  const auth = useSelector(authSelector);
  const dispatch = useDispatch();
  const locations = useSelector(locationsSelector);
  const [type, setType] = useState();
  const { id } = useParams();
  const [hideIfInAGroup, setHideIfInAGroup] = useState(false);
  const [modalType, setModalType] = useState(1);
  const [locationsNameId, setLocationsNameId] = useState({ title: '', id: '' });
  const [imgUrl, setImgUrl] = useState(); // current image we chose
  const [imgArr, setImgArr] = useState([]); // array for post request
  const [tempImgArr, setTempImgArr] = useState([]); // array for addied but not sent images
  const [schemaUrl, setSchemaUrl] = useState(); // current schema we chose
  const [schemaArr, setSchemaArr] = useState([]); // array for post request
  const [tempSchemaArr, setTempSchemaArr] = useState([]); // array for addied but not sent schemas
  const [currentSector, setCurrentSector] = useState();
  const [selectedOptionsObjectFilters, setSelectedOptionsObjectFilters] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: '',
    9: [],
    10: [],
    11: [],
    12: [],
  });
  const [selectedObjectFiltersRest, setSelectedObjectFiltersRest] = useState({
    1: [],
    2: [''],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [''],
  });
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedObjectFiltersService, setSelectedObjectFiltersService] = useState({
    1: [],
    2: [''],
    3: [''],
  });
  // барабаны
  const [dataPickerFilters, setDataPickerFilters] = useState({
    freeZone: '2',
    capacity: '0',
    sleepingPlace: '0',
    hotel: '0',
    capacityObject: '0',
    bedObject: '0',
    sectorShoreSide: '',
    sectorShoreNumber: '',
    sectorShoreLetter: '',
    intervalStartingFromNumber: '1',
    intervalEndsFromNumber: '100',
    similarSector: '0',
  });

  const [postData, setPostData] = useState({
    number:
      dataPickerFilters.sectorShoreSide +
      dataPickerFilters.sectorShoreNumber +
      dataPickerFilters.sectorShoreLetter,
    name: '',
    description: '',
    place: {
      freeZone: selectedOptionsObjectFilters[1].includes('free') || false,
      brige: selectedOptionsObjectFilters[1].includes('bridge'),
      pontoon: selectedOptionsObjectFilters[1].includes('pontoon'),
      english: selectedOptionsObjectFilters[1].includes('english'),
      wild: selectedOptionsObjectFilters[1].includes('wild') || false,
    },
    landscape: [],
    conditions: [],
    objects: [],
    objectType: {},
    conveniences: [{}],
    fishingType: '',
    discipline: [],
    direction: [],
    additionalConditions: [],
    fishType: [],
    aquazone: [],
    forRest: [],
    services: [],
    locationId: '',
    images: [],
    schemas: [],
  });
  // похожие сектора
  const similarSector = ['12', 'Л12M', 'П1S'];
  const [saveDescription, setSaveDescription] = useState({
    saveFishingDescription: false,
    saveRestDescription: false,
    saveServiceDescription: false,
  });

  const { isOpen: choice, openModal: openChoice, closeModal: closeChoice } = useModal();
  const {
    isOpen: fishingMenu,
    openModal: openFishingMenu,
    closeModal: closeFishingMenu,
  } = useModal();
  const [activeIndex, setActiveIndex] = useState();

  const [selectedItems, setSelectedItems] = useState([]);

  const handleComponentClick = id => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const toggleLockClass = () => {
    setIsLocked(!isLocked);
  };

  useEffect(() => {
    dispatch(getLocations({ id }));
  }, []);

  useEffect(() => {
    if (!auth) navigate('/login');
  }, []);
  useEffect(() => {
    if (locations) {
      const index = locations?.findIndex(item => item?.sections?.length > 0);
      setActiveIndex(index === -1 ? 0 : index);
    }
  }, [locations]); // change

  /*   useEffect(() => {
    if (locations) dispatch(getGroup({ id: locations[activeIndex]?.id }));
  }, [activeIndex]); */

  const handleSelectedAll = () => {
    if (selectedAll) {
      const allId = locations[activeIndex]?.sections?.map(item => item.id);
      setSelectedItems([]);
      setSelectedAll(false);
    } else {
      const allId = locations[activeIndex]?.sections?.map(item => item.id);
      setSelectedItems(allId);
      setSelectedAll(true);
    }
  };
  const mapObjectType = data => ({
    opened:
      modalType === 1 ? data.includes('opened') : modalType === 2 && data?.includes('openedObject'),
    closed:
      modalType === 1 ? data.includes('closed') : modalType === 2 && data?.includes('closedObject'),
    spaType: modalType === 2 && data[8][0],
  });
  const [showFilters, setShowFilters] = useState(false);
  const mapData = (data, locationRest) => {
    const key =
      modalType === 1
        ? 'saveFishingDescription'
        : modalType === 2
          ? 'saveRestDescription'
          : 'saveServiceDescription';

    return {
      number:
        dataPickerFilters.sectorShoreSide +
        dataPickerFilters.sectorShoreNumber +
        dataPickerFilters.sectorShoreLetter,
      name: modalType === 1 ? locationsNameId?.title : data[2],
      description: saveDescription[key]
        ? data[modalType === 1 ? 7 : modalType === 2 ? 11 : 3][0]
        : '',
      place: mapPlace(data[1], dataPickerFilters),
      landscape: modalType === 1 ? data[2] : modalType === 2 ? locationRest : [],
      conditions: modalType === 3 ? [] : data[3],
      objects: modalType === 1 ? data[4] : modalType === 2 ? data[1] : [],
      conveniences: [{ geu: '1' }, { geu: '2' }],
      objectType: mapObjectType(data[5]),
      fishingType: modalType === 1 ? data[8] : '',
      discipline: modalType === 1 ? data[9] : [],
      direction: modalType === 1 ? data[10] : [],
      additionalConditions: modalType === 1 ? data[11] : [],
      fishType: modalType === 1 ? data[12] : [],
      aquazone: data[9] || [],
      forRest: data[10] || [],
      services: modalType === 1 || modalType === 2 ? [] : data[1],
      locationId: locations && locations[activeIndex]?.id,
      images: imgArr,
      schemas: schemaArr,
    };
  };
  const useEffectFunction = () => {
    const data =
      modalType === 1
        ? selectedOptionsObjectFilters
        : modalType === 2
          ? selectedObjectFiltersRest
          : selectedObjectFiltersService;

    const locationRest = [].concat(selectedObjectFiltersRest[4], selectedObjectFiltersRest[7]);
    setPostData(mapData(data, locationRest));
  };

  useEffect(useEffectFunction, [
    selectedOptionsObjectFilters,
    selectedObjectFiltersService,
    selectedObjectFiltersRest,
    imgUrl,
    imgArr,
    schemaArr,
    schemaUrl,
    saveDescription,
    activeIndex,
  ]);

  const payload = {
    locationId: postData.locationId,
    number: postData.number,
    fdhjyetagsrhdghsrfdg: 'ugytaedsfgnhrsfdg',
  };

  const formData = toFormData(postData);

  const [showSort, setShowSort] = useState(false);
  const [selectedOption, setSelectedOption] = useState('default');
  const handleOptionChange = event => setSelectedOption(event.target.value);
  // useEffects for add images and schemas to array for request and array for temporary view
  useEffect(() => {
    if (imgUrl) setImgArr([...imgArr, imgUrl]);
    const blobToBase64 = blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
    if (imgUrl)
      blobToBase64(imgUrl).then(res => {
        setTempImgArr([...tempImgArr, res]);
      });
  }, [imgUrl]);

  useEffect(() => {
    if (schemaUrl) setSchemaArr([...schemaArr, schemaUrl]);
    const blobToBase64 = blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
    if (schemaUrl)
      blobToBase64(schemaUrl).then(res => {
        setTempSchemaArr([...tempSchemaArr, res]);
      });
  }, [schemaUrl]);

  return (
    <>
      <div className="home-add-popup-container" style={{ height: px(60) }}>
        <div className="home-add-popup-container-overflow" style={{ height: px(60) }}>
          <div className="home-add-popup-content">
            <p className="absolute w-full text-center leading-[27px]" style={{ top: px(21) }}>
              Предложения
            </p>
            <div
              className="search-menu-item-preferences-close"
              onClick={handleNavigateBack}
              aria-hidden
            >
              <Icon type={TypesIcon.CLOSE} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
            </div>
          </div>
        </div>
      </div>
      <section
        style={{
          position: 'relative',
          zIndex: 5,
          minWidth: px(320),
          height: px(488),
        }}
      >
        <div>
          <img
            src="/offer-menu.png"
            alt="cover"
            style={{
              position: 'absolute',
              top: px(-80),
              width: px(320),
              height: px(568),
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            top: px(86),
            left: px(147),
            zIndex: 6,
          }}
        >
          <Icon type={TypesIcon.OFFERMENU} width={px(27)} height={px(30)} viewBox="0 0 27 30" />
        </div>
        <div
          style={{
            position: 'absolute',
            width: px(270),
            height: px(270),
            left: px(25),
            top: px(154),
            zIndex: 6,
          }}
        >
          <img
            src="/offer-menu-border.png"
            alt="border"
            style={{
              width: px(270),
              height: px(270),
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            width: px(146),
            top: px(193),
            left: px(88),
            zIndex: 6,
          }}
        >
          <p
            className="font-normal text-white"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            Москоу Кантр…
          </p>
          <p
            className="text-center text-white"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            ID: 7579086
          </p>
        </div>
        <div
          style={{
            position: 'absolute',
            top: px(249),
            left: px(120),
            zIndex: 6,
          }}
        >
          <img
            src="/offer-menu-image.png"
            alt="map"
            style={{
              width: px(80),
              height: px(80),
            }}
          />
        </div>
        <CircleButton
          onClick={() => {
            openLocation();
          }}
          icon={{
            type: TypesIcon.ADDPLUS,
            viewBox: '0 0 26.95 26.95',
            width: px(26.95),
            height: px(26.95),
          }}
        />
        <CircleButton
          disabled={!locations || locations.length === 0}
          icon={{
            type: TypesIcon.PENSIL,
            viewBox: '0 0 18.51 23.873',
            width: px(18.51),
            height: px(23.873),
          }}
        />
        <CircleButton
          disabled={!locations || locations.length === 0}
          icon={{
            type: TypesIcon.PENSIL,
            viewBox: '0 0 24.703 26.238',
            width: px(24.703),
            height: px(26.238),
            fill: '#0A84FE',
          }}
        />
        <CircleButton
          disabled={!locations || locations.length === 0}
          icon={{
            type: TypesIcon.PRICE,
            viewBox: '0 0 23.355 26.228',
            width: px(23.355),
            height: px(26.228),
            fill: '#0A84FE',
          }}
        />
      </section>

      <Modal isOpen={location} onClose={closeLocation}>
        <LocationCreator
          openFishingMenu={openFishingMenu}
          setActiveIndex={setActiveIndex}
          closeLocation={closeLocation}
          setModalType={setModalType}
          id={id}
        />
      </Modal>

      <Modal isOpen={fishingMenu} onClose={closeFishingMenu}>
        <FishingMenu
          id={locations && locations[activeIndex]?.id}
          objectDropdownList={locations?.map(item => ({ id: item.id, title: item.name }))}
          index={activeIndex}
          selectedObjectFiltersService={selectedObjectFiltersService}
          setSelectedObjectFiltersService={setSelectedObjectFiltersService}
          selectedObjectFiltersRest={selectedObjectFiltersRest}
          setSelectedObjectFiltersRest={setSelectedObjectFiltersRest}
          selectedOptionsObjectFilters={selectedOptionsObjectFilters}
          setSelectedOptionsObjectFilters={setSelectedOptionsObjectFilters}
          modalType={modalType}
          setLocationsNameId={setLocationsNameId}
          setImgUrl={setImgUrl}
          setSchemaUrl={setSchemaUrl}
          schemaUrl={schemaUrl}
          formData={formData}
          imgUrl={imgUrl}
          saveDescription={saveDescription}
          setSaveDescription={setSaveDescription}
          setActiveIndex={setActiveIndex}
          date={dataPickerFilters}
          setDate={setDataPickerFilters}
          similarSector={similarSector}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          setShowSort={setShowSort}
          sectors={locations && locations[activeIndex]?.sections}
          handleComponentClick={handleComponentClick}
          setCurrentSector={setCurrentSector}
          imgArr={imgArr}
          tempImgArr={tempImgArr}
          schemaArr={schemaArr}
          tempSchemaArr={tempSchemaArr}
        />
      </Modal>

      <Modal isOpen={access} onClose={closeAccess}>
        <div style={{ overflowY: 'auto', height: '100%', paddingBottom: px(65) }}>
          <p
            className="mb-[20px] text-center"
            style={{
              paddingTop: px(5),
              marginBottom: px(22),
            }}
          >
            Допуск. Условия
          </p>
          <div className="container flex justify-between" style={{ marginBottom: px(20) }}>
            <div
              style={{
                width: px(90),
                height: px(222),
                border: '1px solid black',
                borderRadius: px(15),
                backgroundColor: '#082341',
                paddingTop: px(20),
                paddingBottom: px(10),
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  paddingRight: px(19.9),
                  paddingLeft: px(19.9),
                  height: px(43),
                  marginBottom: px(12),
                }}
              >
                <Icon
                  type={TypesIcon.BIGFISH}
                  viewBox="0 0 50.226 22.966"
                  width={px(50.226)}
                  height={px(22.966)}
                />
              </div>
              <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>МОСТИК</p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                НАВЕС
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                ДОМИК
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                FREE ZONE
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                ...
              </p>
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: 'white',
                  marginTop: px(10),
                  marginBottom: px(10),
                }}
              >
                РЫБАЛКА
              </p>
              <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
                <Checkbox checked={type === 'FISHING'} onChange={() => setType('FISHING')} />
              </div>
            </div>

            <div
              style={{
                width: px(90),
                height: px(222),
                border: '1px solid black',
                borderRadius: px(15),
                backgroundColor: '#082341',
                paddingTop: px(20),
                paddingBottom: px(10),
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  paddingRight: px(36),
                  paddingLeft: px(36),
                  height: px(43),
                  marginBottom: px(12),
                }}
              >
                <Icon
                  type={TypesIcon.RELAX}
                  viewBox="0 0 17.987 42.916"
                  width={px(17.987)}
                  height={px(42.916)}
                />
              </div>
              <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ОТЕЛЬ</p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                КОТТЕДЖ
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                БЕСЕДКА
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                БАНЯ, САУНА
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                ...
              </p>
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: 'white',
                  marginTop: px(10),
                  marginBottom: px(10),
                }}
              >
                ОТДЫХ
              </p>
              <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
                <Checkbox checked={type === 'RELAX'} onChange={() => setType('RELAX')} />
              </div>
            </div>

            <div
              style={{
                width: px(90),
                height: px(222),
                border: '1px solid black',
                borderRadius: px(15),
                backgroundColor: '#082341',
                paddingTop: px(20),
                paddingBottom: px(10),
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  paddingRight: px(16.5),
                  paddingLeft: px(21.1),
                  height: px(43),
                  marginBottom: px(12),
                }}
              >
                <Icon
                  type={TypesIcon.BOAT}
                  viewBox="0 0 52.429 42.517"
                  width={px(52.429)}
                  height={px(42.517)}
                />
              </div>
              <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ЛОДКА</p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                СНАСТИ
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                ПРИКОРМКА
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                ОБОРУДОВАНИЕ
              </p>
              <p
                style={{
                  fontSize: px(10),
                  lineHeight: px(14),
                  color: 'white',
                  marginTop: px(-1),
                }}
              >
                ...
              </p>
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: 'white',
                  marginTop: px(10),
                  marginBottom: px(10),
                }}
              >
                УСЛУГИ
              </p>
              <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
                <Checkbox checked={type === 'SERVICE'} onChange={() => setType('SERVICE')} />
              </div>
            </div>
          </div>

          <div className="container" style={{ marginBottom: px(20) }}>
            <div
              style={{
                width: px(90),
                height: px(90),
                border: '1px solid black',
                borderRadius: px(15),
                paddingTop: px(15),
                paddingBottom: px(15),
                paddingLeft: px(12),
                paddingRight: px(12),
              }}
            >
              <div className="flex justify-center" style={{ opacity: 0.5, marginBottom: px(4.4) }}>
                <Icon
                  type={TypesIcon.ACCESS}
                  viewBox="0 0 24.703 26.238"
                  width={px(24.703)}
                  height={px(26.238)}
                  fill="#000000"
                />
              </div>
              <p
                style={{
                  opacity: 0.5,
                  textAlign: 'center',
                  fontSize: px(20),
                  lineHeight: px(27),
                }}
              >
                Допуск
              </p>
            </div>
          </div>

          <div className="container">
            <DropdownList
              objectDropdownList={locations?.map(item => ({ id: item.id, title: item.name }))}
              index={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </div>

          {locations &&
            locations[activeIndex]?.allowanceSectionGroups?.map(item => (
              <AccessSettings
                key={item.id}
                data={item}
                sectors={locations[activeIndex]?.sections?.filter(
                  it => it.sectionAllowanceGroupId === item.id,
                )}
              />
            ))}
          <div className="container">
            <Button
              marginTop={0}
              borderRadius={0}
              height={60}
              className="w-full"
              onClick={() => {
                openChoice();
              }}
            >
              Создать новую группу
            </Button>
          </div>
        </div>
      </Modal>

      <Modal noDraggable isOpen={choice} onClose={closeChoice}>
        <div className="absolute left-0 top-0 z-50 w-full bg-white">
          <SectorModal
            subTitle="Выбор"
            alternativeTitle="Рыбалка"
            dropdownTitle="Выберите сектор"
            selectedItems={selectedItems}
            handleNavigateBack={closeChoice}
            data={
              locations &&
              (hideIfInAGroup
                ? locations[activeIndex]?.sections?.filter(item => !item?.sectionAllowanceGroupId)
                : locations[activeIndex]?.sections)
            }
            handleComponentClick={handleComponentClick}
            buttonTitle="Применить"
            onClick={() => {
              /* closeOtherModals(0); */
              /* openChoice(); */
              dispatch(
                createGroup({ locationId: locations[activeIndex].id, sectionIds: selectedItems }),
              );
              closeChoice();
            }}
          />

          <ScrollButtonsContainer>
            <CircleButton
              onClick={handleSelectedAll}
              icon={{
                type: TypesIcon.GROUP,
                viewBox: '0 0 30.783 30.783',
                width: px(30.783),
                height: px(30.783),
              }}
            />
            {hideIfInAGroup ? (
              <CircleButton
                onClick={() => {
                  setHideIfInAGroup(false);
                  setShowSort(true);
                }}
                icon={{
                  type: TypesIcon.REMOVEALPHABET,
                  viewBox: '0 0 26 41',
                  width: px(26),
                  height: px(41),
                }}
              />
            ) : (
              <CircleButton
                onClick={() => {
                  setHideIfInAGroup(true);
                  setShowSort(true);
                }}
                icon={{
                  type: TypesIcon.ALPHABET,
                  viewBox: '0 0 8.939 40.957',
                  width: px(8.939),
                  height: px(40.957),
                }}
              />
            )}
          </ScrollButtonsContainer>
        </div>
      </Modal>
      <Modal isOpen={showSort} onClose={() => setShowSort(false)}>
        <SortModal
          title="Сортировка"
          data={sortValues}
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
        />
      </Modal>
    </>
  );
};
