import StatusCode from '@types/Errors';
import axios from 'axios';

import { config } from '../../config';
import { ServerRequestError } from './errors';

export const axiosInstance = axios.create({
  withCredentials: true,
});

export const getApiPath = () => {
  const { baseUrl } = config;
  return `${baseUrl}`;
};

axiosInstance.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === StatusCode.UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;
      await fetch(`${getApiPath()}/auth/refresh`, { method: 'GET', credentials: 'include' });
      return axiosInstance(originalRequest);
    }
    throw error;
  },
);

export const request = async ({
  headers = {},
  method = 'GET',
  url,
  data,
  params,
  responseType,
  onUploadProgress,
}) => {
  const options = {
    headers,
    method,
    params,
    responseType,
    data,
    onUploadProgress,
    url: `${getApiPath()}/${url}`,
  };

  try {
    return await axiosInstance(options);
  } catch (error) {
    const typedError = error;

    const { message, response: { status = StatusCode.INTERNAL_SERVER_ERROR } = {} } =
      typedError || {};

    throw new ServerRequestError(message, status, typedError?.response?.data);
  }
};
