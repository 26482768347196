import React from 'react';

export const FishMatIcon = props => (
  <svg
    viewBox="0 0 41 27"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.295871"
      d="M38.4489 13.1724L21.7776 15.9978L2.08008 13.1724V2.6875H38.4489L38.4489 13.1724Z"
      fill="#FAF7F7"
      stroke="#707070"
    />
    <path
      opacity="0.5"
      d="M24.1978 4.51172L38.888 26.822H35.5015L20.582 4.51172H24.1978Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M39.1998 0H1.33951C0.599688 0 0 0.599686 0 1.33951C0 2.07933 0.599688 2.67902 1.33951 2.67902H10.6216C12.1811 2.67902 13.6024 3.57341 14.2771 4.97948C14.9517 6.38547 16.3731 7.27995 17.9326 7.27995H22.6068C24.1663 7.27995 25.5875 6.38555 26.2623 4.97948C26.9369 3.57349 28.3582 2.67902 29.9177 2.67902H39.1999C39.9397 2.67902 40.5394 2.07933 40.5394 1.33951C40.5393 0.59977 39.9396 0 39.1998 0Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M22.0433 4.72677H18.4152C18.0036 4.72677 17.6699 4.39307 17.6699 3.98148V3.26287C17.6699 2.85128 18.0036 2.51758 18.4152 2.51758H22.0433C22.4549 2.51758 22.7886 2.85128 22.7886 3.26287V3.98148C22.7885 4.39307 22.4549 4.72677 22.0433 4.72677Z"
      fill="currentColor"
    />
    <path
      d="M22.0423 2.51758H20.6953C21.1069 2.51758 21.4406 2.85127 21.4406 3.26279V3.9814C21.4406 4.39299 21.1069 4.7266 20.6953 4.7266H22.0423C22.4539 4.7266 22.7875 4.39291 22.7875 3.9814V3.26279C22.7875 2.85127 22.4539 2.51758 22.0423 2.51758Z"
      fill="black"
    />
    <path
      opacity="0.5"
      d="M16.2605 4.51172L1.57031 26.822H4.95685L19.8763 4.51172H16.2605Z"
      fill="currentColor"
    />
  </svg>
);
