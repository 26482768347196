/* eslint-disable no-shadow */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeDiscountGroup } from '../../../../../actions/section-discount/thunks';
import { changeSectionGroup } from '../../../../../actions/section-group/thunks';
import { changePromotionGroup } from '../../../../../actions/section-promotion';
// TODO: move to new arch (everything) and alias
import { Checkbox, CustomAlert, ObjectPriceDiscountAction } from '../../../../../components';
import { Icon } from '../../../../../components/Icon';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { Button, CircleButton, Dropdown, Picker, ScrollButtonsContainer } from '../../../../shared';
import { px } from '../../../../utils';
import { useProfile } from '../../../hooks';
import { GroupModal } from '../access/components';
import { PriceGroup } from './components';

export const Price = ({ locationCategory }) => {
  const dispatch = useDispatch();
  const [showGroupModal, setShowGroupModal] = useState(false);

  const [type, setType] = useState(locationCategory || 'REST');
  const [priceDiscountAction, setPriceDiscountAction] = useState('price');
  const [showGroupPicker, setShowGroupPicker] = useState(false);
  const [groups, setGroups] = useState([]);
  const [id, setId] = useState();
  const [alert, setAlert] = useState(false);

  const {
    data: { locations, selectedLocationId, selectedSector },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const selectedLocation = useMemo(
    () => locations?.find(location => location.id === selectedLocationId) || locations[0],
    [locations, selectedLocationId],
  );

  const selectedSectorItem = useMemo(
    () => selectedLocation?.sections?.find(item => item?.id === selectedSector),
    [selectedLocation, selectedSector],
  );

  const handlePriceDiscountActionChange = value => setPriceDiscountAction(value);

  const handleMoveSector = async (data, sectionId) => {
    if (priceDiscountAction === 'price') await dispatch(changeSectionGroup(data, sectionId));
    if (priceDiscountAction === 'action') await dispatch(changePromotionGroup(data, sectionId));
    if (priceDiscountAction === 'discount') await dispatch(changeDiscountGroup(data, sectionId));
    updateProfile();
  };

  const isSectorFree = useMemo(
    () =>
      selectedSectorItem?.place[0]?.name === 'free' ||
      selectedSectorItem?.place[1]?.name === 'free' ||
      selectedSectorItem?.place[1]?.name === 'winterFromIce' ||
      selectedSectorItem?.place[0]?.name === 'winterFromIce',
    [locations, selectedLocationId, selectedSector],
  );

  const disabledButton = useMemo(() => {
    if (priceDiscountAction === 'price')
      return selectedLocation?.sections?.every(section => section.priceId);
    if (priceDiscountAction === 'action')
      return selectedLocation?.sections?.every(section => section.promotionId);
    if (priceDiscountAction === 'discount')
      return selectedLocation?.sections?.every(section => section.discountId);
    return null;
  }, [selectedLocation, priceDiscountAction]);

  useEffect(
    () =>
      setProfile({
        selectedLocationId: locations?.find(l => l?.type === type)?.id,
      }),
    [type],
  );

  return (
    <div style={{ overflowY: 'auto', height: '100%', paddingBottom: px(30) }}>
      <p
        className="mb-[20px] text-center"
        style={{
          paddingTop: px(5),
          marginBottom: px(22),
        }}
      >
        Цена. Скидка. Акции
      </p>
      <div className="container flex justify-between" style={{ marginBottom: px(20) }}>
        <div
          style={{
            width: px(90),
            height: px(222),
            border: '1px solid black',
            borderRadius: px(15),
            backgroundColor: '#082341',
            paddingTop: px(20),
            paddingBottom: px(10),
            textAlign: 'center',
          }}
        >
          <div
            style={{
              paddingRight: px(19.9),
              paddingLeft: px(19.9),
              height: px(43),
              marginBottom: px(12),
            }}
          >
            <Icon
              type={TypesIcon.BIGFISH}
              viewBox="0 0 50.226 22.966"
              width={px(50.226)}
              height={px(22.966)}
            />
          </div>
          <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>МОСТИК</p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            НАВЕС
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ДОМИК
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            FREE ZONE
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ...
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              color: 'white',
              marginTop: px(10),
              marginBottom: px(10),
            }}
          >
            РЫБАЛКА
          </p>
          <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
            <Checkbox
              checked={type === 'FISHING'}
              onChange={() => {
                if (locations?.filter(l => l?.type === 'FISHING')?.length > 0) {
                  setType('FISHING');
                  setProfile({
                    selectedLocationId: locations?.find(l => l?.type === 'FISHING')?.id,
                  });
                }
              }}
            />
          </div>
        </div>

        <div
          style={{
            width: px(90),
            height: px(222),
            border: '1px solid black',
            borderRadius: px(15),
            backgroundColor: '#082341',
            paddingTop: px(20),
            paddingBottom: px(10),
            textAlign: 'center',
          }}
        >
          <div
            style={{
              paddingRight: px(36),
              paddingLeft: px(36),
              height: px(43),
              marginBottom: px(12),
            }}
          >
            <Icon
              type={TypesIcon.RELAX}
              viewBox="0 0 17.987 42.916"
              width={px(17.987)}
              height={px(42.916)}
            />
          </div>
          <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ОТЕЛЬ</p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            КОТТЕДЖ
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            БЕСЕДКА
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            БАНЯ, САУНА
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ...
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              color: 'white',
              marginTop: px(10),
              marginBottom: px(10),
            }}
          >
            ОТДЫХ
          </p>
          <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
            <Checkbox
              checked={type === 'REST'}
              onChange={() => {
                setType('REST');
                setProfile({
                  selectedLocationId: locations?.find(l => l?.type === 'REST')?.id,
                });
              }}
            />
          </div>
        </div>

        <div
          style={{
            width: px(90),
            height: px(222),
            border: '1px solid black',
            borderRadius: px(15),
            backgroundColor: '#082341',
            paddingTop: px(20),
            paddingBottom: px(10),
            textAlign: 'center',
          }}
        >
          <div
            style={{
              paddingRight: px(16.5),
              paddingLeft: px(21.1),
              height: px(43),
              marginBottom: px(12),
            }}
          >
            <Icon
              type={TypesIcon.BOAT}
              viewBox="0 0 52.429 42.517"
              width={px(52.429)}
              height={px(42.517)}
            />
          </div>
          <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ЛОДКА</p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            СНАСТИ
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ПРИКОРМКА
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ОБОРУДОВАНИЕ
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ...
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              color: 'white',
              marginTop: px(10),
              marginBottom: px(10),
            }}
          >
            УСЛУГИ
          </p>
          <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
            <Checkbox
              checked={type === 'SERVICE'}
              onChange={() => {
                setType('SERVICE');
                setProfile({
                  selectedLocationId: locations?.find(l => l?.type === 'SERVICE')?.id,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="container" style={{ marginBottom: px(20) }}>
        <ObjectPriceDiscountAction
          priceDiscountAction={priceDiscountAction}
          handlePriceDiscountActionChange={handlePriceDiscountActionChange}
        />
      </div>

      <div className="container" style={{ marginBottom: px(20) }}>
        <Dropdown
          key={selectedLocationId}
          items={locations?.map(({ name: label, id: value }) => ({ label, value }))}
          // eslint-disable-next-line unicorn/consistent-destructuring
          value={selectedLocation?.id}
          onChange={id => {
            setProfile({ selectedLocationId: locations?.find(i => i?.id === id)?.id });
            setType(locations?.find(i => i?.id === id)?.type);
          }}
        />
      </div>

      {locations &&
        priceDiscountAction === 'price' &&
        selectedLocation?.prices
          ?.slice()
          .sort((a, b) => a.id - b.id)
          .map(item => (
            <PriceGroup
              priceDiscountAction={priceDiscountAction}
              type={type}
              key={item.id}
              data={item}
              sectors={selectedLocation.sections?.filter(it => it.priceId === item.id)}
            />
          ))}

      {locations &&
        priceDiscountAction === 'discount' &&
        selectedLocation?.discounts
          ?.slice()
          .sort((a, b) => a.id - b.id)
          .map(item => (
            <PriceGroup
              priceDiscountAction={priceDiscountAction}
              type={type}
              key={item.id}
              data={item}
              sectors={selectedLocation.sections?.filter(it => it.discountId === item.id)} // sectors={item.sections}
            />
          ))}

      {locations &&
        priceDiscountAction === 'action' &&
        selectedLocation?.promotions
          ?.slice()
          .sort((a, b) => a.id - b.id)
          .map(item => (
            <PriceGroup
              priceDiscountAction={priceDiscountAction}
              type={type}
              key={item.id}
              data={item}
              sectors={selectedLocation.sections?.filter(it => it.promotionId === item.id)}
            />
          ))}
      <div className="container" style={{ marginBottom: px(60) }}>
        <Button
          marginTop={0}
          borderRadius={0}
          height={60}
          border={disabledButton}
          fontSize={19}
          bgColor={disabledButton ? 'white' : '#0A84FE'}
          color={disabledButton ? 'black' : 'white'}
          bgOpacityDisabled={1}
          className="w-full"
          onClick={() => setShowGroupModal(true)}
          disabled={disabledButton}
        >
          {disabledButton ? 'Нет свободных для выбора' : 'Создать новую группу'}
        </Button>
      </div>

      {showGroupModal && (
        <GroupModal
          show={showGroupModal}
          onClose={() => setShowGroupModal(false)}
          price={priceDiscountAction}
        />
      )}

      {selectedSector && (
        <ScrollButtonsContainer>
          <CircleButton
            // onClick={() => setShowGroupPicker(true)}
            onClick={() => {
              if (isSectorFree) {
                setAlert(true);
              } else setShowGroupPicker(true);
            }}
            icon={{
              type: TypesIcon.MOVETOANOTHERGROUP,
              viewBox: '0 0 26.215 30.616',
              width: px(26.215),
              height: px(30.616),
            }}
          />
          {/* TODO */}
          <CircleButton
            onClick={async () => {
              await handleMoveSector({
                data: {
                  connect: {
                    sectionIds: [],
                  },
                  disconnect: {
                    sectionIds: [selectedSector],
                  },
                },
                id:
                  priceDiscountAction === 'price'
                    ? locations
                        ?.find(item => item.id === selectedLocationId)
                        ?.sections?.find(item => item.id === selectedSector)?.priceId
                    : priceDiscountAction === 'action'
                      ? locations
                          ?.find(item => item.id === selectedLocationId)
                          ?.sections?.find(item => item.id === selectedSector)?.promotionId
                      : locations
                          ?.find(item => item.id === selectedLocationId)
                          ?.sections?.find(item => item.id === selectedSector)?.discountId,
              });
              setProfile({ selectedSector: undefined });
            }}
            icon={{
              type: TypesIcon.SMALLBASKET,
              viewBox: '0 0 20.5 25.231',
              width: px(20.5),
              height: px(25.231),
            }}
          />
        </ScrollButtonsContainer>
      )}
      <Picker
        group
        singleItem
        title="Группа"
        show={showGroupPicker}
        onClick={async () => {
          if (
            (priceDiscountAction === 'price' &&
              (selectedLocation?.sections?.find(item => item?.priceId === id)?.place[0]?.name ===
                'free' ||
                selectedLocation?.sections?.find(item => item?.priceId === id)?.place[0]?.name ===
                  'winterFromIce' ||
                selectedLocation?.sections?.find(item => item?.priceId === id)?.place[1]?.name ===
                  'free' ||
                selectedLocation?.sections?.find(item => item?.priceId === id)?.place[1]?.name ===
                  'winterFromIce')) ||
            (priceDiscountAction === 'action' &&
              (selectedLocation?.sections?.find(item => item?.promotionId === id)?.place[0]
                ?.name === 'free' ||
                selectedLocation?.sections?.find(item => item?.promotionId === id)?.place[0]
                  ?.name === 'winterFromIce' ||
                selectedLocation?.sections?.find(item => item?.promotionId === id)?.place[1]
                  ?.name === 'free' ||
                selectedLocation?.sections?.find(item => item?.promotionId === id)?.place[1]
                  ?.name === 'winterFromIce')) ||
            (priceDiscountAction === 'discount' &&
              (selectedLocation?.sections?.find(item => item?.discountId === id)?.place[0]?.name ===
                'free' ||
                selectedLocation?.sections?.find(item => item?.discountId === id)?.place[0]
                  ?.name === 'winterFromIce' ||
                selectedLocation?.sections?.find(item => item?.discountId === id)?.place[1]
                  ?.name === 'free' ||
                selectedLocation?.sections?.find(item => item?.discountId === id)?.place[1]
                  ?.name === 'winterFromIce'))
          )
            setAlert(true);
          else {
            const from =
              priceDiscountAction === 'price'
                ? locations
                    ?.find(item => item.id === selectedLocationId)
                    ?.sections?.find(item => item.id === selectedSector)?.priceId
                : priceDiscountAction === 'action'
                  ? locations
                      ?.find(item => item.id === selectedLocationId)
                      ?.sections?.find(item => item.id === selectedSector)?.promotionId
                  : locations
                      ?.find(item => item.id === selectedLocationId)
                      ?.sections?.find(item => item.id === selectedSector)?.discountId;
            if (from === id) return;
            await handleMoveSector({
              data: {
                connect: {
                  sectionIds: [],
                },
                disconnect: {
                  sectionIds: [selectedSector],
                },
              },
              id: from,
            });
            await handleMoveSector({
              data: {
                connect: {
                  sectionIds: [selectedSector],
                },
                disconnect: {
                  sectionIds: [],
                },
              },
              id,
            });
            setProfile({ selectedSector: undefined });
          }
        }}
        onClose={() => setShowGroupPicker(false)}
        data={{
          groups:
            priceDiscountAction === 'price'
              ? selectedLocation?.prices
                  .map(item => item.groupNumber)
                  // eslint-disable-next-line unicorn/prefer-spread
                  .slice()
                  .sort()
              : priceDiscountAction === 'action'
                ? selectedLocation?.promotions
                    .map(item => item.groupNumber)
                    // eslint-disable-next-line unicorn/prefer-spread
                    .slice()
                    .sort()
                : selectedLocation?.discounts
                    .map(item => item.groupNumber)
                    // eslint-disable-next-line unicorn/prefer-spread
                    .slice()
                    .sort(),
        }}
        onChange={({ groups }) => {
          if (priceDiscountAction === 'price')
            setId(selectedLocation?.prices?.find(item => item.groupNumber === groups)?.id);
          if (priceDiscountAction === 'action')
            setId(selectedLocation?.promotions?.find(item => item.groupNumber === groups)?.id);
          if (priceDiscountAction === 'discount')
            setId(selectedLocation?.discounts?.find(item => item.groupNumber === groups)?.id);
          setGroups(groups);
        }}
        value={{
          groups,
        }}
        button
        isLine
      />
      {alert && (
        <CustomAlert
          onClickButtonSubmit={() => setAlert(false)}
          title="FREE ZONE и ЛЕД не могут состоять в группах. Только индивидуально"
          single
          buttonRightText="Ок"
        />
      )}
    </div>
  );
};

export default Price;
