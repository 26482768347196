import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { authActiveIdSelector, authSelector } from '../../selectors/auth';
import { CompetitionPage } from './competition.page';
import { getCompetition } from './competition.service';
import { CompetitionProvider } from './context';
import { useCompetition } from './hooks';
import { ProtocolPage } from './protocol';
import { CompetitionRegistrationPage } from './registration';
import { CompetitionRegistrationEditPage } from './registration/edit';
import { TeamRegistrationPage } from './registration/team';

const ROUTES = [
  {
    path: '/competition/:id',
    element: CompetitionPage,
  },
  {
    path: '/competition/registration/edit',
    element: CompetitionRegistrationEditPage,
  },
  {
    path: '/competition/registration/:id',
    element: CompetitionRegistrationPage,
  },
  {
    path: '/competition/registration/team',
    element: TeamRegistrationPage,
  },
  {
    path: '/protocols',
    element: ProtocolPage,
  },
];

const Stack = () => {
  const auth = useSelector(authSelector);
  /* const activeId = window.location.pathname.split('/')[2]; */
  const activeId = useSelector(authActiveIdSelector);
  const location = useLocation();
  const onStack = !!matchRoutes(ROUTES, location);

  const {
    data: competitionData,
    setData: setCompetition,
    setUpdateFunction: setUpdateCompetitionFunction,
  } = useCompetition();
  const getData = () =>
    getCompetition({
      competitionId: isNaN(window.location.pathname.split('/')[2])
        ? activeId
        : window.location.pathname.split('/')[2],
    }).then(setCompetition);

  useEffect(() => {
    if (onStack) {
      setUpdateCompetitionFunction(() => getData);
      getData();
    }
  }, [onStack]);

  return (
    <Routes>
      {ROUTES?.map(({ private_, element: Element, ...route }) => (
        <Route
          key={route.path}
          element={
            private_ ? (
              auth ? (
                <Element />
              ) : (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            ) : (
              <Element />
            )
          }
          {...route}
        />
      ))}
    </Routes>
  );
};

export const CompetitionStack = () => (
  <CompetitionProvider>
    <Stack />
  </CompetitionProvider>
);

export default CompetitionStack;
