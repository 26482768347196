import { some } from 'lodash';
import React from 'react';

import { colors } from '../../constants/Colors';
import { TypesIcon } from '../../types/TypesIcon';
import { ReactComponent as access } from './icons/access.svg';
import { ReactComponent as activeNight } from './icons/active-night.svg';
import { ReactComponent as activeSun } from './icons/active-sun.svg';
import { ReactComponent as ad } from './icons/ad.svg';
import { ReactComponent as addPlus } from './icons/add-plus.svg';
import { ReactComponent as addProfile } from './icons/add-profile.svg';
import { ReactComponent as adminControl } from './icons/admin-control.svg';
import { ReactComponent as adminList } from './icons/admin-list.svg';
import { ReactComponent as alcoveAvatar } from './icons/alcove_avatar.svg';
import { ReactComponent as alphabet } from './icons/alphabet.svg';
import { ReactComponent as analytics } from './icons/analytics.svg';
import { ReactComponent as appeals } from './icons/appeals.svg';
import { ReactComponent as arrowDown } from './icons/arrow-down.svg';
import { ReactComponent as arrowLeft } from './icons/arrow-left.svg';
import { ReactComponent as whiteArrowLeft } from './icons/arrow-left-white.svg';
import { ReactComponent as arrowRight } from './icons/arrow-right.svg';
import { ReactComponent as whiteArrowRight } from './icons/arrow-right-white.svg';
import { ReactComponent as arrowTop } from './icons/arrow-top.svg';
import { ReactComponent as avatarCamera } from './icons/avatar-camera.svg';
import { ReactComponent as Back } from './icons/back.svg';
import { ReactComponent as backet } from './icons/backet.svg';
import { ReactComponent as bathhouseAvatar } from './icons/bathhouse_avatar.svg';
import { ReactComponent as bigBasket } from './icons/big-basket.svg';
import { ReactComponent as bigFish } from './icons/big-fish.svg';
import { ReactComponent as bigLock } from './icons/big-lock.svg';
import { ReactComponent as blackSearch } from './icons/black-seach.svg';
import { ReactComponent as block } from './icons/block.svg';
import { ReactComponent as bluePrice } from './icons/blue-price.svg';
import { ReactComponent as boat } from './icons/boat.svg';
import { ReactComponent as booking } from './icons/booking.svg';
import { ReactComponent as building } from './icons/building.svg';
import { ReactComponent as burgerMenu } from './icons/burger-menu.svg';
import { ReactComponent as call } from './icons/call.svg';
import { ReactComponent as camera } from './icons/camera.svg';
import { ReactComponent as canopyAvatar } from './icons/canopy_avatar.svg';
import { ReactComponent as cardBack } from './icons/card-back.svg';
import { ReactComponent as card } from './icons/card-icon.svg';
import { ReactComponent as cards } from './icons/cards.svg';
import { ReactComponent as cash } from './icons/cash.svg';
import { ReactComponent as chair } from './icons/chair.svg';
import { ReactComponent as changeKey } from './icons/change-key.svg';
import { ReactComponent as chat } from './icons/chat.svg';
import { ReactComponent as check } from './icons/check.svg';
import { ReactComponent as circles } from './icons/circles.svg';
import { ReactComponent as classHand } from './icons/class-hand.svg';
import { ReactComponent as clock } from './icons/clock.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as closeRed } from './icons/close-red.svg';
import { ReactComponent as clubCarts } from './icons/clubCarts.svg';
import { ReactComponent as colorBack } from './icons/color-back.svg';
import { ReactComponent as confirm } from './icons/confirm.svg';
import { ReactComponent as control } from './icons/control.svg';
import { ReactComponent as cross } from './icons/cross.svg';
import { ReactComponent as deleteCode } from './icons/delete-code.svg';
import { ReactComponent as deleteCodeSmall } from './icons/delete-code-small.svg';
import { ReactComponent as deleted } from './icons/deleted.svg';
import { ReactComponent as diagrams } from './icons/diagrams.svg';
import { ReactComponent as direction } from './icons/direction.svg';
import { ReactComponent as document } from './icons/document.svg';
import { ReactComponent as documentFinance } from './icons/document-finance.svg';
import { ReactComponent as dotsMore } from './icons/dots-more.svg';
import { ReactComponent as dropdown } from './icons/dropdown.svg';
import { ReactComponent as dropDownTriangle } from './icons/dropDownTriangle.svg';
import { ReactComponent as education } from './icons/education.svg';
import { ReactComponent as envelop } from './icons/envelop.svg';
import { ReactComponent as eraser } from './icons/eraser.svg';
import { ReactComponent as eyeClose } from './icons/eye-close.svg';
import { ReactComponent as eyeOpen } from './icons/eye-open.svg';
import { ReactComponent as faceId } from './icons/face-id.svg';
import { ReactComponent as feedbackStarBorder } from './icons/feedback-star-border.svg';
import { ReactComponent as feedbackStarFill } from './icons/feedback-star-fill.svg';
import { ReactComponent as filter } from './icons/filter.svg';
import { ReactComponent as finance } from './icons/finance.svg';
import { ReactComponent as first } from './icons/first.svg';
import { ReactComponent as fish } from './icons/fish.svg';
import { ReactComponent as flagBelarus } from './icons/flag-belarus.svg';
import { ReactComponent as flagRussia } from './icons/flag-russia.svg';
import { ReactComponent as frame } from './icons/frame.svg';
import { ReactComponent as freeZone } from './icons/free-zone.svg';
import { ReactComponent as freeZoneAvatar } from './icons/free-zone-avatar.svg';
import { ReactComponent as gallery } from './icons/gallery.svg';
import { ReactComponent as galleryObject } from './icons/gallery-object.svg';
import { ReactComponent as games } from './icons/games.svg';
import { ReactComponent as gamesWhite } from './icons/games-white.svg';
import { ReactComponent as golf } from './icons/golf.svg';
import { ReactComponent as group } from './icons/group.svg';
import { ReactComponent as hallOfGlory } from './icons/hall-of-glory.svg';
import { ReactComponent as hand } from './icons/hand.svg';
import { ReactComponent as headerBell } from './icons/header-bell.svg';
import { ReactComponent as home } from './icons/home.svg';
import { ReactComponent as hotelKey } from './icons/hotel-key.svg';
import { ReactComponent as house } from './icons/house.svg';
import { ReactComponent as houseAvatar } from './icons/house_avatar.svg';
import { ReactComponent as Human } from './icons/human.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as infoCursive } from './icons/info-cursive.svg';
import { ReactComponent as jcb } from './icons/jcb.svg';
import { ReactComponent as kazakhstan } from './icons/kazakhstan-flag.svg';
import { ReactComponent as key } from './icons/key.svg';
import { ReactComponent as lamp } from './icons/lamp.svg';
import { ReactComponent as lampBlue } from './icons/lamp-blue.svg';
import { ReactComponent as line } from './icons/line.svg';
import { ReactComponent as list } from './icons/list.svg';
import { ReactComponent as littleFeedbackStarBorder } from './icons/little-feedback-star-border.svg';
import { ReactComponent as littleFeedbackStarFill } from './icons/little-feedback-star-fill.svg';
import { ReactComponent as lock } from './icons/lock.svg';
import { ReactComponent as locker } from './icons/locker.svg';
import { ReactComponent as logHouse } from './icons/log-house.svg';
import { ReactComponent as mail } from './icons/mail.svg';
import { ReactComponent as mailSubscribe } from './icons/mail-subscripe.svg';
import { ReactComponent as marker } from './icons/marker.svg';
import { ReactComponent as mastercard } from './icons/mastercard.svg';
import { ReactComponent as minusForButton } from './icons/minus-for-button.svg';
import { ReactComponent as mir } from './icons/mir.svg';
import { ReactComponent as mirFooter } from './icons/mir-footer.svg';
import { ReactComponent as mobile } from './icons/mobile.svg';
import { ReactComponent as news } from './icons/news.svg';
import { ReactComponent as night } from './icons/night.svg';
import { ReactComponent as note } from './icons/note.svg';
import { ReactComponent as notebook } from './icons/notebook.svg';
import { ReactComponent as notification } from './icons/notification.svg';
import { ReactComponent as numberOne } from './icons/number-one.svg';
import { ReactComponent as offerMenu } from './icons/offer-menu.svg';
import { ReactComponent as openLock } from './icons/open-lock.svg';
import { ReactComponent as openMail } from './icons/open-mail.svg';
import { ReactComponent as passport } from './icons/passport.svg';
import { ReactComponent as paypal } from './icons/paypal.svg';
import { ReactComponent as pensil } from './icons/pensil.svg';
import { ReactComponent as peoples } from './icons/peoples.svg';
import { ReactComponent as person } from './icons/person.svg';
import { ReactComponent as personInCircle } from './icons/person-in-circle.svg';
import { ReactComponent as headerProfile } from './icons/person-in-circle-header.svg';
import { ReactComponent as personalInfo } from './icons/personalInfo.svg';
import { ReactComponent as phone } from './icons/phone.svg';
import { ReactComponent as plus } from './icons/plus.svg';
import { ReactComponent as plusBig } from './icons/plus-big.svg';
import { ReactComponent as plusForButton } from './icons/plus-for-button.svg';
import { ReactComponent as price } from './icons/price.svg';
import { ReactComponent as profile } from './icons/profile.svg';
import { ReactComponent as records } from './icons/records.svg';
import { ReactComponent as redClose } from './icons/red-close.svg';
import { ReactComponent as registration } from './icons/registration.svg';
import { ReactComponent as relax } from './icons/relax.svg';
import { ReactComponent as removeAlphabet } from './icons/remove-alphabet.svg';
import { ReactComponent as resize } from './icons/resize.svg';
import { ReactComponent as reward } from './icons/reward.svg';
import { ReactComponent as roundedReturn } from './icons/rounded-return.svg';
import { ReactComponent as rub } from './icons/rub.svg';
import { ReactComponent as rules } from './icons/rules.svg';
import { ReactComponent as screen } from './icons/screen.svg';
import { ReactComponent as search } from './icons/search.svg';
import { ReactComponent as sector } from './icons/sector.svg';
import { ReactComponent as security } from './icons/security.svg';
import { ReactComponent as sertificate } from './icons/sertificate.svg';
import { ReactComponent as settings } from './icons/settings.svg';
import { ReactComponent as settingsFilter } from './icons/settings_filter.svg';
import { ReactComponent as seven } from './icons/seven.svg';
import { ReactComponent as shoreLandscapeAvatar } from './icons/shore_landscape_avatar.svg';
import { ReactComponent as smallBasket } from './icons/small-basket.svg';
import { ReactComponent as something } from './icons/something.svg';
import { ReactComponent as sort } from './icons/sort.svg';
import { ReactComponent as speed } from './icons/speed.svg';
import { ReactComponent as star } from './icons/star.svg';
import { ReactComponent as statistics } from './icons/statistics.svg';
import { ReactComponent as stats } from './icons/stats.svg';
import { ReactComponent as subscriptions } from './icons/subscriptions.svg';
import { ReactComponent as sun } from './icons/sun.svg';
import { ReactComponent as support } from './icons/support.svg';
import { ReactComponent as surprise } from './icons/surprise.svg';
import { ReactComponent as telegram } from './icons/telegram.svg';
import { ReactComponent as telegramWithoutBg } from './icons/telegram-without-bg.svg';
import { ReactComponent as top } from './icons/top.svg';
import { ReactComponent as touchId } from './icons/touch-id.svg';
import { ReactComponent as tournament } from './icons/tournament.svg';
import { ReactComponent as transfer } from './icons/transfer.svg';
import { ReactComponent as trash } from './icons/trash.svg';
import { ReactComponent as unlock } from './icons/unlock.svg';
import { ReactComponent as userPhoto } from './icons/user-photo.svg';
import { ReactComponent as viber } from './icons/viber.svg';
import { ReactComponent as video } from './icons/video.svg';
import { ReactComponent as visa } from './icons/visa.svg';
import { ReactComponent as vk } from './icons/vkontakte.svg';
import { ReactComponent as waterside } from './icons/waterside.svg';
import { ReactComponent as whatsup } from './icons/whatsUp.svg';
import { ReactComponent as whiteLock } from './icons/white-lock.svg';
import { ReactComponent as whiteSearch } from './icons/white-seach.svg';
import { ReactComponent as youtube } from './icons/youtube.svg';

const sprite = {
  [TypesIcon.HAND]: hand,
  [TypesIcon.HUMAN]: Human,
  [TypesIcon.CLOSE]: close,
  [TypesIcon.BACK]: Back,
  [TypesIcon.REDCLOSE]: redClose,
  [TypesIcon.LOCK]: lock,
  [TypesIcon.BASKET]: backet,
  [TypesIcon.BIGLOCK]: bigLock,
  [TypesIcon.COLORBACK]: colorBack,
  [TypesIcon.OPENLOCK]: openLock,
  [TypesIcon.FLAGRUSSIAN]: flagRussia,
  [TypesIcon.FLAGBELARUS]: flagBelarus,
  [TypesIcon.TOUCHID]: touchId,
  [TypesIcon.FACEID]: faceId,
  [TypesIcon.PERSONALINFO]: personalInfo,
  [TypesIcon.SECURITY]: security,
  [TypesIcon.NOTIFICATION]: notification,
  [TypesIcon.CONTROL]: control,
  [TypesIcon.CARDS]: cards,
  [TypesIcon.FINANCE]: finance,
  [TypesIcon.ANALYTICS]: analytics,
  [TypesIcon.SUBSCRIPTIONS]: subscriptions,
  [TypesIcon.CLUBCARTS]: clubCarts,
  [TypesIcon.SERTIFICATE]: sertificate,
  [TypesIcon.SUPPORT]: support,
  [TypesIcon.APPEALS]: appeals,
  [TypesIcon.CALL]: call,
  [TypesIcon.MOBILE]: mobile,
  [TypesIcon.BLOCK]: block,
  [TypesIcon.TRANSFER]: transfer,
  [TypesIcon.DELETE]: deleted,
  [TypesIcon.USERPHOTO]: userPhoto,
  [TypesIcon.MARKER]: marker,
  [TypesIcon.DELETECODE]: deleteCode,
  [TypesIcon.DELETECODESMALL]: deleteCodeSmall,
  [TypesIcon.VIDEO]: video,
  [TypesIcon.SEARCH]: search,
  [TypesIcon.ROUNDEDRETURN]: roundedReturn,
  [TypesIcon.CHANGEKEY]: changeKey,
  [TypesIcon.KEY]: key,
  [TypesIcon.CLOCK]: clock,
  [TypesIcon.BIGBASKET]: bigBasket,
  [TypesIcon.PASSPORT]: passport,
  [TypesIcon.DOCUMENT]: document,
  [TypesIcon.HOME]: home,
  [TypesIcon.STATS]: stats,
  [TypesIcon.GAMES]: games,
  [TypesIcon.GALLERY]: gallery,
  [TypesIcon.CAMERA]: camera,
  [TypesIcon.ARROWLEFT]: arrowLeft,
  [TypesIcon.ARROWRIGHT]: arrowRight,
  [TypesIcon.MIR]: mir,
  [TypesIcon.KAZAKHSTAN]: kazakhstan,
  [TypesIcon.CHAT]: chat,
  [TypesIcon.CASH]: cash,
  [TypesIcon.PERSON]: person,
  [TypesIcon.PLUS]: plus,
  [TypesIcon.PEOPLES]: peoples,
  [TypesIcon.CHECK]: check,
  [TypesIcon.NUMBERONE]: numberOne,
  [TypesIcon.BLACKSEARCH]: blackSearch,
  [TypesIcon.PROFILE]: profile,
  [TypesIcon.SMALLBASKET]: smallBasket,
  [TypesIcon.MAIL]: mail,
  [TypesIcon.REWARD]: reward,
  [TypesIcon.WHITESEARCH]: whiteSearch,
  [TypesIcon.ARROWLEFTWHITE]: whiteArrowLeft,
  [TypesIcon.ARROWRIGHTWHITE]: whiteArrowRight,
  [TypesIcon.TELEGRAM]: telegram,
  [TypesIcon.VIBER]: viber,
  [TypesIcon.PERSONINCIRCLE]: personInCircle,
  [TypesIcon.WHATSUP]: whatsup,
  [TypesIcon.ARROWDOWN]: arrowDown,
  [TypesIcon.CROSS]: cross,
  [TypesIcon.MAILSUBSCRIBE]: mailSubscribe,
  [TypesIcon.STAR]: star,
  [TypesIcon.SEVEN]: seven,
  [TypesIcon.SETTINGS]: settings,
  [TypesIcon.PENSIL]: pensil,
  [TypesIcon.LAMP]: lamp,
  [TypesIcon.LAMPBLUE]: lampBlue,
  [TypesIcon.DOTSMORE]: dotsMore,
  [TypesIcon.BOOKING]: booking,
  [TypesIcon.VKONTAKTE]: vk,
  [TypesIcon.YOUTUBE]: youtube,
  [TypesIcon.TELEGRAMWITHOUTBG]: telegramWithoutBg,
  [TypesIcon.DROPDOWN]: dropdown,
  [TypesIcon.WHITELOCK]: whiteLock,
  [TypesIcon.LINE]: line,
  [TypesIcon.ARROWTOP]: arrowTop,
  [TypesIcon.JCB]: jcb,
  [TypesIcon.VISA]: visa,
  [TypesIcon.MASTERCARD]: mastercard,
  [TypesIcon.MIRFOOTER]: mirFooter,
  [TypesIcon.PAYPAL]: paypal,
  [TypesIcon.EDUCATION]: education,
  [TypesIcon.GOLF]: golf,
  [TypesIcon.TOURNAMENT]: tournament,
  [TypesIcon.BURGERMENU]: burgerMenu,
  [TypesIcon.HEADERBELL]: headerBell,
  [TypesIcon.HEADERPROFILE]: headerProfile,
  [TypesIcon.SORT]: sort,
  [TypesIcon.CARD]: card,
  [TypesIcon.AVATARCAMERA]: avatarCamera,
  [TypesIcon.ENVELOP]: envelop,
  [TypesIcon.CLASS]: classHand,
  [TypesIcon.FEEDBACKSTARBORDER]: feedbackStarBorder,
  [TypesIcon.FEEDBACKSTARFILL]: feedbackStarFill,
  [TypesIcon.CLOSERED]: closeRed,
  [TypesIcon.DOCUMENTFINANCE]: documentFinance,
  [TypesIcon.CHAIR]: chair,
  [TypesIcon.DIRECTION]: direction,
  [TypesIcon.ADMINLIST]: adminList,
  [TypesIcon.ADMINCONTROL]: adminControl,
  [TypesIcon.UNLOCK]: unlock,
  [TypesIcon.TRASH]: trash,
  [TypesIcon.LOCKER]: locker,
  [TypesIcon.PLUSFORBUTTON]: plusForButton,
  [TypesIcon.MINUSFORBUTTON]: minusForButton,
  [TypesIcon.LITTLEFEEDBACKSTARBORDER]: littleFeedbackStarBorder,
  [TypesIcon.LITTLEFEEDBACKSTARFILL]: littleFeedbackStarFill,
  [TypesIcon.INFO]: info,
  [TypesIcon.REGISTRATION]: registration,
  [TypesIcon.CONFIRM]: confirm,
  [TypesIcon.FIRST]: first,
  [TypesIcon.PHONE]: phone,
  [TypesIcon.RESIZE]: resize,
  [TypesIcon.ADDPLUS]: addPlus,
  [TypesIcon.WATERSIDE]: waterside,
  [TypesIcon.FISH]: fish,
  [TypesIcon.HOUSE]: house,
  [TypesIcon.BIGFISH]: bigFish,
  [TypesIcon.HOTELKEY]: hotelKey,
  [TypesIcon.BOAT]: boat,
  [TypesIcon.BUILDING]: building,
  [TypesIcon.SECTOR]: sector,
  [TypesIcon.LOGHOUSE]: logHouse,
  [TypesIcon.FREEZONE]: freeZone,
  [TypesIcon.FILTER]: filter,
  [TypesIcon.OFFERMENU]: offerMenu,
  [TypesIcon.ACCESS]: access,
  [TypesIcon.PRICE]: price,
  [TypesIcon.BLUEPRICE]: bluePrice,
  [TypesIcon.FREEZONEAVATAR]: freeZoneAvatar,
  [TypesIcon.SHORELANDSCAPEAVATAR]: shoreLandscapeAvatar,
  [TypesIcon.ALCOVEAVATAR]: alcoveAvatar,
  [TypesIcon.BATHHOUSEAVATAR]: bathhouseAvatar,
  [TypesIcon.CANOPYAVATAR]: canopyAvatar,
  [TypesIcon.HOUSEAVATAR]: houseAvatar,
  [TypesIcon.FISH]: fish,
  [TypesIcon.RELAX]: relax,
  [TypesIcon.BOAT]: boat,
  [TypesIcon.ALPHABET]: alphabet,
  [TypesIcon.REMOVEALPHABET]: removeAlphabet,
  [TypesIcon.GROUP]: group,
  [TypesIcon.SUN]: sun,
  [TypesIcon.ACTIVESUN]: activeSun,
  [TypesIcon.NIGHT]: night,
  [TypesIcon.ACTIVENIGHT]: activeNight,
  [TypesIcon.FREEZONEAVATAR]: freeZoneAvatar,
  [TypesIcon.SHORELANDSCAPEAVATAR]: shoreLandscapeAvatar,
  [TypesIcon.ALCOVEAVATAR]: alcoveAvatar,
  [TypesIcon.BATHHOUSEAVATAR]: bathhouseAvatar,
  [TypesIcon.CANOPYAVATAR]: canopyAvatar,
  [TypesIcon.HOUSEAVATAR]: houseAvatar,
  [TypesIcon.EYEOPEN]: eyeOpen,
  [TypesIcon.EYECLOSE]: eyeClose,
  [TypesIcon.GAMESWHITE]: gamesWhite,
  [TypesIcon.GALLERYOBJECT]: galleryObject,
  [TypesIcon.NEWS]: news,
  [TypesIcon.RULES]: rules,
  [TypesIcon.STATISTICS]: statistics,
  [TypesIcon.HALLOFGLORY]: hallOfGlory,
  [TypesIcon.AD]: ad,
  [TypesIcon.SPEED]: speed,
  [TypesIcon.SCREEN]: screen,
  [TypesIcon.SURPRISE]: surprise,
  [TypesIcon.LIST]: list,
  [TypesIcon.PLUSBIG]: plusBig,
  [TypesIcon.ERASER]: eraser,
  [TypesIcon.CARDBACK]: cardBack,
  [TypesIcon.DROPDOWNTRIANGLE]: dropDownTriangle,
  [TypesIcon.RUB]: rub,
  [TypesIcon.INFOCURSIVE]: infoCursive,
  [TypesIcon.SETTINGSFILTER]: settingsFilter,
  [TypesIcon.DIAGRAMS]: diagrams,
  [TypesIcon.OPENMAIL]: openMail,
  [TypesIcon.CIRCLES]: circles,
  [TypesIcon.TOP]: top,
  [TypesIcon.NOTE]: note,
  [TypesIcon.SOMETHING]: something,
  [TypesIcon.NOTEBOOK]: notebook,
  [TypesIcon.FRAME]: frame,
  [TypesIcon.RECORDS]: records,
  [TypesIcon.ADDPROFILE]: addProfile,
};

export const Icon = props => {
  const {
    type,
    xmlns = 'http://www.w3.org/2000/svg',
    xmlnsXlink = 'http://www.w3.org/1999/xlink',
    viewBox = '0 0 24 24',
    width = '24px',
    height = '24px',
    fill = colors.black,
    color = '',
    onClick,
    onMouseDown,
    opacity,
    strokeOpacity,
    fillOpacity,
    className,
    onMouseOver,
  } = props;
  const IconComponent = sprite[type];

  return (
    <IconComponent
      {...props}
      onClick={onClick}
      xmlns={xmlns}
      xmlnsXlink={xmlnsXlink}
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      stroke={color}
      className={className}
      onMouseDown={onMouseDown}
      opacity={opacity}
      strokeOpacity={strokeOpacity}
      fillOpacity={fillOpacity}
      onMouseOver={onMouseOver}
    />
  );
};

export default Icon;
