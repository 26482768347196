import React, { useEffect, useRef, useState } from 'react';

import { userCardMonthDate } from '../constants/Component';
import { getFormatDate } from '../constants/Function';
import { px } from '../constants/Px';

export const DateContainer = ({ itemDate }) => {
  const ref = useRef();
  const [scrolling, setScrolling] = useState(false);
  const currentDate = new Date();
  const formatDate = getFormatDate(currentDate, true);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(ref.current.offsetTop > 0);
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={ref}
      className="z-50 flex justify-center duration-300"
      style={{
        position: 'sticky',
        top: px(69),
        color: scrolling ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.3)',
      }}
    >
      <p
        className={`${
          scrolling ? 'shadow-[0px_3px_6px_0_rgba(0,0,0,0.3)]' : ''
        } z-50 bg-white font-bold duration-300`}
        style={{
          marginBottom: px(10),
          borderRadius: px(50),
          padding: `${px(5)} ${px(15)} ${px(5)} ${px(15)} `,
          fontSize: px(15),
          lineHeight: px(20),
        }}
      >{`${
        formatDate === itemDate
          ? `Сегодня, ${itemDate.split('.')[0]} ${userCardMonthDate[
              itemDate.split('.')[1] - 1
            ].toLowerCase()}`
          : `${itemDate.split('.')[0]} ${userCardMonthDate[
              itemDate.split('.')[1] - 1
            ].toLowerCase()} ${itemDate.split('.')[2]}`
      }`}</p>
    </div>
  );
};

export default DateContainer;
