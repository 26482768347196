import { request } from '@/helpers/api/request';

export const verify = async ({ phone, code }) => {
    const response =   await request({
        url: `auth/verify`,
        method: 'POST',
        data: {
          phone,
          code
        }
      });
    
      return response;
    }


  