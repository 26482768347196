/* eslint-disable no-unsafe-optional-chaining */
// TODO: implement sort by prices

export const sortOptions = [
  {
    id: 1,
    title: 'По умолчанию',
    cb: (a, b) => b.id - a.id,
  },
  {
    id: 2,
    title: 'Последовательно',
    cb: (a, b) => a.id - b.id,
  },
  {
    id: 3,
    title: 'По группам ( А - Z )',
    cb: (a, b) => a.sectionGroupId - b.sectionGroupId,
  },
  {
    id: 4,
    title: 'По цене',
    cb: (a, b) =>
      Math?.min?.apply(
        null,
        a?.priceGroup?.rates?.map(props =>
          Math.min(
            ...[
              Number(props?.cost),
              Number(props?.isCaught && props?.caughtValue),
              Number(props?.isHelp && props?.helpValue),
              Number(props?.isWithNorm && props?.withNormValue),
              Number(props?.isWithoutNorm && props?.withoutNormValue),
            ]?.filter(item => item !== 0),
          ),
        ),
      ) -
      Math?.min?.apply(
        null,
        b?.priceGroup?.rates?.map(props =>
          Math.min(
            ...[
              Number(props?.cost),
              Number(props?.isCaught && props?.caughtValue),
              Number(props?.isHelp && props?.helpValue),
              Number(props?.isWithNorm && props?.withNormValue),
              Number(props?.isWithoutNorm && props?.withoutNormValue),
            ]?.filter(item => item !== 0),
          ),
        ),
      ),
  },
  {
    id: 5,
    title: 'Сначала Скидки',
    cb: (a, b) =>
      Math?.max?.apply?.(
        null,
        b?.discountGroup?.rates?.map(item =>
          Number(
            Boolean(
              (item?.cost && item?.cost !== '0 %') ||
                (item?.isCaught && item?.caughtValue && item?.caughtValue !== '0 %') ||
                (item?.isHelp && item?.helpValue && item?.helpValue !== '0 %') ||
                (item?.isWithNorm && item?.withNormValue && item?.withNormValue !== '0 %') ||
                (item?.isWithoutNorm && item?.withoutNormValue && item?.withoutNormValue !== '0 %'),
            ),
          ),
        ),
      ) -
      Math?.max?.apply?.(
        null,
        a?.discountGroup?.rates?.map(item =>
          Number(
            Boolean(
              (item?.cost && item?.cost !== '0 %') ||
                (item?.isCaught && item?.caughtValue && item?.caughtValue !== '0 %') ||
                (item?.isHelp && item?.helpValue && item?.helpValue !== '0 %') ||
                (item?.isWithNorm && item?.withNormValue && item?.withNormValue !== '0 %') ||
                (item?.isWithoutNorm && item?.withoutNormValue && item?.withoutNormValue !== '0 %'),
            ),
          ),
        ),
      ),
  },
  {
    id: 6,
    title: 'Сначала Акции',
    value: 'blocked',
    cb: (a, b) =>
      Math?.max?.apply?.(
        null,
        b?.promotionGroup?.rates?.map(item =>
          Number(
            Boolean(
              (item?.cost && item?.cost !== '0 %') ||
                (item?.isCaught && item?.caughtValue && item?.caughtValue !== '0 %') ||
                (item?.isHelp && item?.helpValue && item?.helpValue !== '0 %') ||
                (item?.isWithNorm && item?.withNormValue && item?.withNormValue !== '0 %') ||
                (item?.isWithoutNorm && item?.withoutNormValue && item?.withoutNormValue !== '0 %'),
            ),
          ),
        ),
      ) -
      Math?.max?.apply?.(
        null,
        a?.promotionGroup?.rates?.map(item =>
          Number(
            Boolean(
              (item?.cost && item?.cost !== '0 %') ||
                (item?.isCaught && item?.caughtValue && item?.caughtValue !== '0 %') ||
                (item?.isHelp && item?.helpValue && item?.helpValue !== '0 %') ||
                (item?.isWithNorm && item?.withNormValue && item?.withNormValue !== '0 %') ||
                (item?.isWithoutNorm && item?.withoutNormValue && item?.withoutNormValue !== '0 %'),
            ),
          ),
        ),
      ),
  },
];
