import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/react';

import { deleteCard, getCards } from '../../actions/card';
import { getEvent } from '../../api/event';
import {
  Button,
  CustomAlert,
  FooterSlider,
  SearchCardModal,
  TruncatedText,
} from '../../components';
import { LoyalCard } from '../../components/loyal-card';
import { px } from '../../constants/Px';
import { BackButton, CardInformation, CircleButton } from '../../new-architecture/shared';
import { ScrollButtonsContainer } from '../../new-architecture/shared/scroll-buttons-container/scroll-buttons-container';
import { authActiveIdSelector } from '../../selectors/auth';
import { cardsSelector, cardsSliderSelector } from '../../selectors/card';
import { TypesIcon } from '../../types/TypesIcon';

export const CardsTypesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const activeId = useSelector(authActiveIdSelector);
  const cards = useSelector(cardsSelector);
  const slider = useSelector(cardsSliderSelector);
  const [showSearchCardModal, setShowSearchCardModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [openDropdownIds, setOpenDropdownIds] = useState([]);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(false);
  const [showAlertDeleteCard, setShowAlertDeleteCard] = useState(false);
  const handleComponentClick = id => {
    if (openDropdownIds.includes(id)) {
      setOpenDropdownIds(openDropdownIds.filter(item => item !== id));
    } else {
      setOpenDropdownIds([...openDropdownIds, id]);
    }
  };
  const onClickButtonDelete = async () => {
    setDisabledButtonDelete(true);
    try {
      setShowAlertDeleteCard(true);
    } catch (error) {
      console.error('Error deleting card:', error);
    } finally {
      setDisabledButtonDelete(false);
    }
  };

  useEffect(() => {
    if (activeId) {
      dispatch(getCards({ eventId: activeId }));
    }
  }, [activeId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [event, setEvent] = useState([]);
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEvent({ id: activeId });
        if (eventData?.data) {
          setEvent(eventData.data);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    fetchEvent();
  }, [activeId]);

  return (
    <div className="global-container">
      <BackButton onClick={() => navigate('/user-object')} />
      <main>
        <div style={{ paddingBottom: px(80) }}>
          <p
            style={{
              fontSize: px(32),
              lineHeight: px(32),
              paddingTop: px(26),
              paddingBottom: px(5),
            }}
            className="text-center font-light"
          >
            Виды карт
            <br /> и привилегии
          </p>
          <TruncatedText
            data={{
              textBefore: 'Виртуальные карты лояльности для постоянных гостей. Является...',
              textAfter:
                'Виртуальные карты лояльности для постоянных гостей. Является Виртуальные карты лояльности для постоянных гостей. Является Виртуальные карты лояльности для постоянных гостей. Является',
              lines: 2,
            }}
            style={{
              paddingLeft: px(27),
              paddingRight: px(27),
            }}
          />
          {cards?.length ? (
            <>
              <div
                className="w-full"
                style={{
                  padding: `${px(16)} ${px(15)} ${px(0)} ${px(15)}`,
                }}
              >
                <FooterSlider
                  slidesPerView={2}
                  gap={20}
                  cards
                  pagination
                  onRealIndexChange={elem => setActiveIndex(elem)}
                  centeredSlides
                >
                  {[...slider]
                    .sort((a, b) => a.id - b.id)
                    .map(item => (
                      <SwiperSlide key={item?.id} className="swiper-slide">
                        <div className="flex w-full justify-center" style={{ width: px(142) }}>
                          <LoyalCard
                            color="#fff"
                            data={{
                              openUntill: item?.cardDate,
                              ownerName: 'Имя владельца',
                              cardType: item?.name,
                              percent: item?.percent,
                              bgImg: item?.imageUrl,
                              logo: {
                                logoSrc: event?.EventAttachment?.filter(
                                  attachment => attachment.type === 'LOGO',
                                )[0]?.imageUrl,
                                showLogo: item?.showLogo,
                              },
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                </FooterSlider>
              </div>
              <div
                className=" footer__dropdowns "
                style={{
                  background: 'white',
                }}
              >
                <CardInformation
                  key={`cardInfoBlocks-${activeIndex}`}
                  data={[
                    {
                      id: 1,
                      title: { label: 'Имя карты' },
                      value: cards?.[activeIndex]?.name,
                    },
                    {
                      id: 2,
                      title: { label: 'Статус карты' },
                      value: `${cards?.[activeIndex]?.cardDiscount} %`,
                    },
                    {
                      id: 3,
                      title: { label: 'Срок действия' },
                      value: cards?.[activeIndex]?.activeDate,
                    },
                    {
                      id: 4,
                      title: { label: 'Условия получения' },
                      value: cards?.[activeIndex]?.receiptConditions,
                    },
                  ]}
                />
                <CardInformation
                  key={`cardInfoDropDowns-${activeIndex}`}
                  handleComponentClick={handleComponentClick}
                  data={[
                    {
                      id: 1,
                      title: { label: 'Общая информация' },
                      text: cards?.[activeIndex]?.generalInformation,
                    },
                    {
                      id: 2,
                      title: { label: 'Где применяется' },
                      text: cards?.[activeIndex]?.applicationScope,
                    },
                    {
                      id: 3,
                      title: { label: 'Соглашение' },
                      text: cards?.[activeIndex]?.agreement,
                    },
                    {
                      id: 4,
                      title: { label: 'Регламент использования' },
                      text: cards?.[activeIndex]?.useTerms,
                    },
                  ]}
                  dropDown
                />
              </div>
              <div style={{ paddingLeft: px(15), paddingRight: px(15) }}>
                <Button
                  onClick={onClickButtonDelete}
                  className="w-full"
                  height={60}
                  borderRadius={0}
                  marginTop={20}
                  marginBottom={20}
                  bgColor="#FA0303"
                  color="#ffffff"
                  disabled={disabledButtonDelete}
                >
                  Удалить карту
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                paddingTop: px(20),
              }}
            >
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className="text-center font-normal opacity-50"
              >
                Карты не найдены
              </p>
            </div>
          )}
          <SearchCardModal
            title="Клубные карты"
            isOpen={showSearchCardModal}
            onClose={() => setShowSearchCardModal(false)}
            buttonTitle="Найти"
          />
          <ScrollButtonsContainer>
            <CircleButton
              onClick={() => navigate(`/add-loyal-card`)}
              icon={{
                type: TypesIcon.PLUSBIG,
                viewBox: '0 0 24.846 24.847',
                width: px(24.846),
                height: px(24.847),
              }}
              className="text-[#0A84FE]"
            />
            {cards?.length > 0 && (
              <CircleButton
                onClick={() => setShowSearchCardModal(true)}
                icon={{
                  type: TypesIcon.SEARCH,
                  viewBox: '0 0 22.357 22.357',
                  width: px(22.357),
                  height: px(22.357),
                }}
                className="text-[#0A84FE]"
              />
            )}
            {cards?.length > 0 && (
              <CircleButton
                onClick={() =>
                  navigate(`/present-card`, { state: { cardId: cards?.[activeIndex]?.id } })
                }
                icon={{
                  type: TypesIcon.SURPRISE,
                  viewBox: '0 0 23.072 22.972',
                  width: px(23.072),
                  height: px(22.972),
                }}
                className="text-[#0A84FE]"
              />
            )}
            {cards?.[activeIndex]?.ClubCardHolders?.length > 0 && (
              <CircleButton
                onClick={() => navigate(`/club-cards`)}
                icon={{
                  type: TypesIcon.LIST,
                  viewBox: '0 0 23.113 16.403',
                  width: px(23.113),
                  height: px(16.403),
                }}
                className="text-[#0A84FE]"
              />
            )}
          </ScrollButtonsContainer>
          {showAlertDeleteCard && (
            <CustomAlert
              onClickButtonSubmit={async () => {
                await dispatch(deleteCard({ cardId: cards?.[activeIndex]?.id }));
                await dispatch(getCards({ eventId: activeId }));
                setShowAlertDeleteCard(false);
              }}
              onClickButtonClose={() => setShowAlertDeleteCard(false)}
              title="Подтвердить удаление клубной карты?"
              buttonLeftText="Отмена"
              buttonRightText="Да"
            />
          )}
        </div>
      </main>
    </div>
  );
};
