import { request } from '../../helpers/api/request';

export const cardHolderFind = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `card-holder/admin/find`,
    method: 'GET',
    params: data,
  });

  return response;
};
