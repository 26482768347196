import { Icon } from '../../components/Icon';
import { HomeAddPopup } from '../../components/home-add-popup';
import { px } from '../../constants/Px';
import { TypesIcon } from '../../types/TypesIcon';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


export const OpenSoonPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="global-container">
      <main>
      <HomeAddPopup>
            <p>Скоро открытие</p>
            <div className={`card__popup`}>
              <Icon
                viewBox="0 0 21 28"
                width={px(21)}
                height={px(28)}
                type={TypesIcon.OPENLOCK}
              />
              <p className="card__time">06:00 26.07.22 </p>
            </div>
          </HomeAddPopup>
      </main>
    </div>
  );
};
