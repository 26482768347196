import React, { useState } from 'react';

import { px } from '../constants/Px';

export const TruncatedText = ({ data: { textBefore, textAfter, lines }, style }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div style={style}>
      {expand ? (
        <p
          className="font-normal"
          style={{
            fontSize: px(15),
            lineHeight: px(20),
          }}
        >
          {textAfter}
          {expand && textBefore && (
            <u className="cursor-pointer" onClick={() => setExpand(!expand)} aria-hidden>
              скрыть
            </u>
          )}
        </p>
      ) : (
        <p
          className={`font-normal line-clamp-${lines}`}
          style={{
            fontSize: px(15),
            lineHeight: px(20),
          }}
        >
          {textBefore}
          {!expand && textAfter && (
            <u className="cursor-pointer" onClick={() => setExpand(!expand)} aria-hidden>
              далее
            </u>
          )}
        </p>
      )}
    </div>
  );
};

export default TruncatedText;
