import { request } from '@/helpers/api/request';

export const getEvent = async ({ id }) => {
  const response = await request({
    url: `event/${id}`,
    method: 'GET',
  });

  return response;
};
