/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_GUIDE_ADMIN = 'guide/deleteGuideAdmin';

/* const onSuccess = () => async (dispatch) => {
  dispatch(
    getEventAdmins()
  );
}; */

export const deleteGuideAdmin = createAsyncThunk(
  DELETE_GUIDE_ADMIN,
  createRequest({
    type: DELETE_GUIDE_ADMIN,
    loader: data => profileAdminApi.deleteGuideAdmin(data),
    /* onSuccess, */
  }),
);
