import { createReducer } from '@reduxjs/toolkit';
import { getVisit } from '../../actions/visit/thunks/get-visit';


export const visit = createReducer({}, (builder) => {
  builder.addCase(getVisit.fulfilled, (state, { payload }) => ({
    ...state,
    visit: [ ...payload?.data ],
  }));
});
