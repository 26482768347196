import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Main } from '../../components';
import { Icon } from '../../components/Icon';
import { appealsPage } from '../../constants/Component';
import { px } from '../../constants/Px';
import { TypesIcon } from '../../types/TypesIcon';

export const ChatWithSupport = () => {
  const [selectedMessager, setSelectedMessager] = useState();

  const handleMessagerSelect = messager => {
    setSelectedMessager(messager);
  };

  const handleLinkClick = () => {
    if (selectedMessager) {
      let messagerUrl = '';
      switch (selectedMessager) {
        case 'whatsapp': {
          messagerUrl = 'https://wa.me/message/CTQ7CHGNK4PFJ1';
          break;
        }
        case 'telegram': {
          messagerUrl = 'https://t.me/BookingFishing';
          break;
        }
        case 'viber': {
          messagerUrl = 'viber://chat?number=+1234567890';
          break;
        }
        default: {
          messagerUrl = '/';
        }
      }
      if (messagerUrl) {
        window.open(messagerUrl, '_blank');
      }
    }
  };
  return (
    <Main title="Служба техподдержки" light lineHeight={32} paddingTop={25}>
      <p
        className=" whitespace-pre-wrap font-normal"
        style={{ fontSize: px(15), lineHeight: px(20), paddingTop: px(5) }}
      >
        {`Надежная и эффективная помощь\nв разрешении технических проблем`}
      </p>
      <div className="flex flex-col" style={{ paddingTop: px(18), gap: px(10) }}>
        <div style={{ marginBottom: px(1), gap: px(16.4) }} className="flex">
          <div>
            <Icon
              width={px(31.6)}
              height={px(22.2)}
              viewBox="0 0 31.6 22.2"
              type={TypesIcon.ENVELOP}
            />
          </div>
          <p className="font-normal">Ваша соц. сеть</p>
        </div>
        <div className="flex justify-center" style={{ gap: px(30) }}>
          <div className="flex flex-col items-center" style={{ gap: px(13) }}>
            <div>
              <Icon width={px(50)} height={px(50)} viewBox="0 0 50 50" type={TypesIcon.WHATSUP} />
            </div>
            <div style={{ paddingLeft: px(9) }}>
              <Checkbox
                checked={selectedMessager === 'whatsapp'}
                onChange={() => handleMessagerSelect('whatsapp')}
              />
            </div>
          </div>

          <div className="flex flex-col items-center" style={{ gap: px(12) }}>
            <div>
              <Icon width={px(51)} height={px(51)} viewBox="0 0 51 51" type={TypesIcon.TELEGRAM} />
            </div>
            <div style={{ paddingLeft: px(9) }}>
              <Checkbox
                checked={selectedMessager === 'telegram'}
                onChange={() => handleMessagerSelect('telegram')}
              />
            </div>
          </div>

          <div className="flex flex-col items-center" style={{ gap: px(11) }}>
            <div className=" opacity-50" style={{ marginTop: px(-2) }}>
              <Icon width={px(50)} height={px(54)} viewBox="0 0 50 54" type={TypesIcon.VIBER} />
            </div>
            <div style={{ paddingLeft: px(9) }}>
              {/* <Checkbox
                checked={selectedMessager === 'viber'}
                onChange={() => handleMessagerSelect('viber')}
              /> */}
            </div>
          </div>
        </div>
        <Button
          onClick={handleLinkClick}
          borderRadius={0}
          height={60}
          disabled={!selectedMessager}
          className={`w-fullC ${!selectedMessager && 'opacity-50'} `}
        >
          Перейти
        </Button>
      </div>
    </Main>
  );
};
