/* eslint-disable consistent-return */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable import/no-unresolved */
import map1 from '@assets/images/apples-maps.png';
import map4 from '@assets/images/google-maps.png';
import map3 from '@assets/images/yandex-maps.png';
import map2 from '@assets/images/yandex-nav.png';
import { Header, Slider, SliderMaps, SliderNews, Tabs } from '@components';
import { Map, YMaps } from '@pbe/react-yandex-maps';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getCompetitionCabinet } from '../../actions/competition/thunks';
import { addFavourites, deleteFavourites, getFavourites } from '../../actions/favourites';
import { StateContext } from '../../components/state-provider';
import { px } from '../../constants/Px';
import { authSelector } from '../../selectors/auth';
import { competitionGallerySelector, competitionNewsSelector } from '../../selectors/competition';
import { favouritesSelector } from '../../selectors/favourites';
import { TypesIcon } from '../../types/TypesIcon';
import { GetDirectionsModal } from '../object/components/get-directions-modal';
import { CircleButton, Icon } from '../shared';
import { useCompetition } from './hooks';

export const CompetitionPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { showSearch, setShowSearch } = useContext(StateContext);

  const [showAdPopup, setShowAdPopup] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [menuToggleOpen, setMenuToggleOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const auth = useSelector(authSelector);
  const favouritesCards = useSelector(favouritesSelector);
  const dispatch = useDispatch();

  const {
    data: { cabinet },
  } = useCompetition();

  const gallery = useSelector(competitionGallerySelector);
  const news = useSelector(competitionNewsSelector);
  const toggleLockClass = () => {
    setIsLocked(!isLocked);
  };

  const handleFavourites = () => {
    if (auth) {
      if (favouritesCards?.some(item => item?.competitionId === cabinet?.id))
        dispatch(
          deleteFavourites({
            id: favouritesCards.find(item => item.competitionId === cabinet?.id)?.id,
          }),
        );
      else dispatch(addFavourites({ ...cabinet, competitionCabinetId: cabinet?.id }));
    } else {
      navigate('/login');
    }
  };

  const openReg = () => {
    if (
      cabinet?.competitionEvents?.length > 0 &&
      cabinet?.competitionEvents?.some(item => item.isRegistrationOpen)
    )
      navigate(`/competition/registration/${id}`);
  };
  useEffect(() => {
    if (window.location.hash === '#ad') {
      toggleLockClass();
      setShowAdPopup(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getFavourites());
  }, []);

  useEffect(() => {
    dispatch(getCompetitionCabinet({ id }));
  }, [id]);

  const [offersOpen, setOffersOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');

  const directions = [
    { id: 1, imageSrc: map1, label: 'Apple Maps', link: '...' },
    { id: 2, imageSrc: map2, label: 'Яндекс Навигатор', link: '...' },
    { id: 3, imageSrc: map3, label: 'Яндекс Карты', link: '...' },
    { id: 4, imageSrc: map4, label: 'Google Maps', link: '...' },
  ];

  if (cabinet && cabinet?.status === 'BLOCKED')
    navigate('/holiday', {
      state: { blockMessage: cabinet?.blockMessage || '', endDate: cabinet?.blockEndsAt },
    });

  if (cabinet && cabinet?.status === 'ACTIVE')
    return (
      <main>
        <div className="global-container">
          {!(showAdPopup || offersOpen) && (
            <Header
              menuToggleOpen={menuToggleOpen}
              setMenuToggleOpen={setMenuToggleOpen}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              toggleLockClass={toggleLockClass}
              showAdd={showAdd}
              setShowAdd={setShowAdd}
            />
          )}

          <main>
            <div className="relative" style={{ marginBottom: px(75) }}>
              <div>
                {cabinet && cabinet?.attachments?.find(image => image.type === 'BANNER') ? (
                  <img
                    src={`${process.env.REACT_APP_STATIC_URL}${
                      cabinet?.attachments?.find(image => image.type === 'BANNER')?.url
                    }`}
                    alt="heroImage"
                    className="w-full"
                  />
                ) : (
                  <div style={{ height: px(183), width: '100%' }} />
                )}
              </div>
              <div
                className="absolute z-10 overflow-hidden bg-white shadow-[0_4px_3px_0px_rgba(0,0,0,0.16)]"
                style={{
                  left: `calc(50% - ${px(65)})`,
                  bottom: `${px(-65)}`,
                  height: px(130),
                  width: px(130),
                  borderRadius: px(15),
                  border: `1px solid rgba(0, 0, 0, 0.16)`,
                }}
              >
                {cabinet && cabinet?.attachments?.find(image => image.type === 'LOGO') && (
                  <img
                    src={`${process.env.REACT_APP_STATIC_URL}${
                      cabinet?.attachments?.find(image => image.type === 'LOGO')?.url
                    }`}
                    alt="logo"
                    className="w-full"
                  />
                )}
              </div>
            </div>
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                paddingLeft: px(15),
                paddingRight: px(15),
              }}
              className="... overflow-hidden text-ellipsis whitespace-nowrap text-center font-normal"
            >
              {cabinet?.name || ''}
            </p>
            {cabinet?.contacts?.country && (
              <p
                style={{
                  marginBottom: px(10),
                  fontSize: px(15),
                  lineHeight: px(20),
                  paddingLeft: px(15),
                  paddingRight: px(15),
                  textAlign: 'center',
                }}
              >
                <span>{`${cabinet.contacts.country}`}</span>
                {cabinet.contacts?.district && <span>{` / ${cabinet?.contacts?.district}`}</span>}
              </p>
            )}

            <div
              className="flex items-center justify-center"
              style={{ gap: px(10), marginBottom: px(20) }}
            >
              <button
                type="button"
                className="flex cursor-pointer items-center overflow-hidden border-[1px] border-[#0A84FE] bg-white font-normal text-[#0A84FE]"
                style={{
                  height: px(50),
                  fontSize: px(20),
                  borderRadius: px(100),
                  opacity:
                    cabinet?.competitionEvents?.length > 0 &&
                    cabinet?.competitionEvents?.some(item => item.isRegistrationOpen)
                      ? 1
                      : 0.5,
                }}
                onClick={openReg}
              >
                <span
                  className="flex h-full items-center bg-[#0A84FE]"
                  style={{ paddingRight: px(9), paddingLeft: px(13) }}
                >
                  <Icon
                    type={TypesIcon.CHAIR}
                    width={px(24.1)}
                    height={px(26.8)}
                    viewBox="0 0 24.1 26.8"
                  />
                </span>
                <span style={{ paddingRight: px(5), paddingLeft: px(10) }}>Участвовать</span>
              </button>
              <div>
                <CircleButton
                  onClick={handleFavourites}
                  icon={{
                    type: TypesIcon.STAR,
                    viewBox: '0 0 27.5 26.233',
                    width: px(27.5),
                    height: px(26.233),
                    fill: favouritesCards?.some(item => item?.competitionId === cabinet?.id)
                      ? 'white'
                      : '#0a84fe',
                  }}
                  className={`${
                    favouritesCards?.some(item => item?.competitionId === cabinet?.id)
                      ? '!bg-[#39CA1C]'
                      : ''
                  }`}
                />
              </div>
            </div>

            <Tabs
              setActiveTab={setActiveTab}
              tabsArray={[
                {
                  title: 'Описание',
                  body: (
                    <div className="container">
                      {cabinet?.description && (
                        <p
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Описание
                        </p>
                      )}
                      {cabinet?.description && (
                        <p
                          className="break-words"
                          style={{
                            marginBottom: px(27),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: cabinet?.description.replace('\n', '&#10;'),
                          }}
                        />
                      )}
                    </div>
                  ),
                },
                gallery &&
                  gallery.some(image => image.src) && {
                    title: 'Галерея',
                    body: (
                      <div
                        className="cursor-pointer"
                        style={{
                          marginBottom: px(20),
                          minHeight: activeTab === 'Галерея' ? `calc(100dvh - ${px(60)})` : '0',
                        }}
                      >
                        <p
                          className="container"
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Галерея
                        </p>
                        <Slider
                          images={gallery?.map(image => ({ type: 'image', src: image.src }))}
                          setShowSearch={setShowSearch}
                          sliderInRegistrationCard
                        />
                      </div>
                    ),
                  },
                news?.filter?.(item => item.status === 'ACTIVE')?.length > 0 && {
                  title: 'Новости',
                  body: (
                    <div
                      className="cursor-pointer"
                      style={{
                        marginBottom: px(20),
                        minHeight: activeTab === 'Новости' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      {news?.filter?.(item => item.status === 'ACTIVE')?.length > 0 && (
                        <p
                          className="container"
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Новости
                        </p>
                      )}
                      <SliderNews
                        images={news
                          ?.filter?.(item => item.status === 'ACTIVE')
                          ?.map(image => ({
                            type: 'image',
                            title: image.title,
                            subtitle: image.subtitle,
                            body: image.body,
                            src: `${process.env.REACT_APP_STATIC_URL}${image.images[0].url}`,
                          }))}
                      />
                    </div>
                  ),
                },
                cabinet?.attachments &&
                  cabinet?.attachments?.some(image => image.type === 'SCHEMA') && {
                    title: 'Карты, Схемы',
                    body: (
                      <div
                        className="container cursor-pointer"
                        style={{
                          marginBottom: px(40),
                          minHeight:
                            activeTab === 'Карты, Схемы' ? `calc(100dvh - ${px(60)})` : '0',
                        }}
                      >
                        <p
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Карты, Схемы
                        </p>
                        {cabinet?.attachments &&
                          cabinet?.attachments?.some(image => image.type === 'SCHEMA') && (
                            <SliderMaps
                              images={cabinet?.attachments
                                ?.filter(item => item.type === 'SCHEMA')
                                ?.map(item => ({
                                  title: item.title,
                                  src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
                                  file: `${process.env.REACT_APP_STATIC_URL}${item?.textFileUrl}`,
                                  name: item?.textFileName,
                                }))}
                            />
                          )}
                      </div>
                    ),
                  },
                cabinet?.attachments &&
                  cabinet?.attachments?.some(image => image.type === 'PROTOCOL') && {
                    title: 'Протоколы',
                    body: (
                      <div
                        className="container cursor-pointer"
                        style={{
                          marginBottom: px(40),
                          minHeight: activeTab === 'Протоколы' ? `calc(100dvh - ${px(60)})` : '0',
                        }}
                      >
                        <p
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Протоколы
                        </p>
                        {cabinet?.attachments &&
                          cabinet?.attachments?.some(image => image.type === 'PROTOCOL') && (
                            <SliderMaps
                              images={cabinet?.attachments
                                ?.filter(item => item.type === 'PROTOCOL')
                                ?.map(item => ({
                                  title: item.title,
                                  src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
                                  file: `${process.env.REACT_APP_STATIC_URL}${item?.textFileUrl}`,
                                  name: item?.textFileName,
                                }))}
                            />
                          )}
                      </div>
                    ),
                  },
                {
                  title: 'Контакты',
                  body: (
                    <div style={{ marginBottom: px(20) }} className="relative w-full">
                      <p
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          paddingLeft: px(15),
                          paddingRight: px(15),
                          fontWeight: 400,
                          marginBottom: px(19),
                        }}
                        className="line-clamp-3"
                      >
                        {`${cabinet?.contacts?.country ? `${cabinet?.contacts?.country}, ` : ''}${
                          cabinet?.contacts?.region ? `${cabinet?.contacts?.region} регион, ` : ''
                        }
                        ${cabinet?.contacts?.district ? `${cabinet?.contacts?.district}, ` : ''}
                        ${cabinet?.contacts?.town ? `${cabinet?.contacts?.town}, ` : ''}
                        ${cabinet?.contacts?.street || ''}
                        `}
                      </p>
                      <p
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          paddingLeft: px(19),
                          fontWeight: 400,
                          marginBottom: px(10),
                        }}
                      >
                        Карта местности
                      </p>
                      <div className="relative">
                        <div
                          className="absolute flex"
                          style={{ left: px(105), gap: px(10), top: px(116), zIndex: 5 }}
                        >
                          <CircleButton
                            onClick={() => setShowAdPopup(true)}
                            icon={{
                              type: TypesIcon.DIRECTION,
                              viewBox: '0 0 23.4 23.4',
                              width: px(23.4),
                              height: px(23.4),
                              fill: '#0A84FE',
                            }}
                          />
                          <CircleButton
                            onClick={handleFavourites}
                            icon={{
                              type: TypesIcon.STAR,
                              viewBox: '0 0 27.5 26.233',
                              width: px(27.5),
                              height: px(26.233),
                              fill: favouritesCards?.some(
                                item => item?.competitionId === cabinet?.id,
                              )
                                ? 'white'
                                : '#0a84fe',
                            }}
                            className={`${
                              favouritesCards?.some(item => item?.competitionId === cabinet?.id)
                                ? '!bg-[#39CA1C]'
                                : ''
                            }`}
                          />
                        </div>
                      </div>
                      <YMaps className="w-full" style={{ height: px(290) }}>
                        <div style={{ height: px(290) }}>
                          <Map
                            style={{ height: px(290) }}
                            defaultState={{
                              center: [
                                cabinet?.contacts?.coordinates?.[0],
                                cabinet?.contacts?.coordinates?.[1],
                              ],
                              zoom: 8,
                            }}
                            behaviors={{ disable: ['drag'] }}
                          />
                        </div>
                      </YMaps>
                      <div
                        className="flex flex-col"
                        style={{
                          paddingBottom: px(80),
                          paddingTop: px(19),
                          paddingLeft: px(15),
                          paddingRight: px(15),
                          gap: px(18),
                        }}
                      >
                        <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                          ID кабинета: {id}
                        </p>
                        {cabinet?.contacts?.mainPhone && (
                          <div>
                            <p
                              className="opacity-50"
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                              }}
                            >
                              <span className="inline-block" style={{ width: px(118) }}>
                                Телефон 1
                              </span>
                              {cabinet?.contacts?.mainPhoneStartTime &&
                                cabinet?.contacts?.mainPhoneEndTime && (
                                  <span className="inline-block">{`${cabinet?.contacts?.mainPhoneStartTime} - ${cabinet?.contacts?.mainPhoneEndTime}`}</span>
                                )}
                            </p>
                            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                              {cabinet?.contacts?.mainPhone}
                            </p>
                          </div>
                        )}

                        {cabinet?.contacts?.secondaryPhone && (
                          <div>
                            <p
                              className="opacity-50"
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                              }}
                            >
                              <span className="inline-block" style={{ width: px(118) }}>
                                Телефон 2
                              </span>
                              {cabinet?.contacts?.secondaryPhoneStartTime &&
                                cabinet?.contacts?.secondaryPhoneEndTime && (
                                  <span className="inline-block">{`${cabinet?.contacts?.secondaryPhoneStartTime} - ${cabinet?.contacts?.secondaryPhoneEndTime}`}</span>
                                )}
                            </p>
                            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                              {cabinet?.contacts?.secondaryPhone}
                            </p>
                          </div>
                        )}

                        {cabinet?.contacts?.email && (
                          <div>
                            <p
                              className="opacity-50"
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                              }}
                            >
                              Почта электронная
                            </p>
                            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                              {cabinet?.contacts?.email}
                            </p>
                          </div>
                        )}

                        {cabinet?.contacts?.websiteLink && (
                          <div className="w-full">
                            <p
                              className="opacity-50"
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                              }}
                            >
                              Web-страница сайта
                            </p>
                            <a
                              className="line-clamp-1 text-ellipsis break-words"
                              href={cabinet?.contacts?.websiteLink}
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                                color: '#0A84FE',
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {cabinet?.contacts?.websiteLink}
                            </a>
                          </div>
                        )}

                        {cabinet?.contacts?.socialPageLink && (
                          <div>
                            <p
                              className="opacity-50"
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                              }}
                            >
                              Страница в соц. сети
                            </p>
                            <a
                              href={cabinet?.contacts?.socialPageLink}
                              className="line-clamp-1 text-ellipsis break-words"
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                                color: '#0A84FE',
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {cabinet?.contacts?.socialPageLink}
                            </a>
                          </div>
                        )}

                        <div className="flex justify-center" style={{ gap: px(46) }}>
                          {cabinet?.contacts?.whatsAppLink && (
                            <a
                              href={cabinet?.contacts?.whatsAppLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon
                                width={px(30)}
                                height={px(30)}
                                viewBox="0 0 50 50"
                                type={TypesIcon.WHATSUP}
                              />
                            </a>
                          )}
                          {cabinet?.contacts?.telegramLink && (
                            <a
                              href={cabinet?.contacts?.telegramLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon
                                width={px(29)}
                                height={px(29)}
                                viewBox="0 0 51 51"
                                type={TypesIcon.TELEGRAM}
                              />
                            </a>
                          )}
                          {cabinet?.contacts?.viberLink && (
                            <a href={cabinet?.contacts?.viberLink} target="_blank" rel="noreferrer">
                              <Icon
                                width={px(28.814)}
                                height={px(31.106)}
                                viewBox="0 0 28.814 31.106"
                                type={TypesIcon.VIBERSMALL}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </main>
        </div>
        <GetDirectionsModal
          show={showAdPopup}
          onClose={() => setShowAdPopup(false)}
          title="Проложить маршрут"
          directions={directions}
          contacts={cabinet?.contacts}
        />
      </main>
    );
};

export default CompetitionPage;
