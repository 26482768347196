import React, { useLayoutEffect, useMemo, useState } from 'react';

import { Checkbox } from '../../../components/checkbox';
import { px } from '../../utils';
import { Picker } from '..';

export const CheckboxField = ({
  label,
  checked: defaultChecked = false,
  onChange,
  picker,
  noCheckbox,
  redIfZero,
  allowance,
}) => {
  const [checked, setChecked] = useState();
  const [showPicker, setShowPicker] = useState(false);

  const formattedPickerValue = useMemo(
    () => picker?.formatValue(picker?.value),
    [picker?.value, picker?.formatValue],
  );

  useLayoutEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = c => {
    setChecked(c);
    if (onChange) onChange(c);
  };

  return (
    <div
      aria-hidden
      className={`search-menu-filter-block-item ${checked ? 'selected' : ''}`}
      onClick={e => !noCheckbox && !showPicker && handleChange(!checked)}
    >
      <h5
        style={{
          color: (noCheckbox || allowance) && 'black',
          fontSize: allowance && px(15),
          lineHeight: allowance && px(20),
        }}
      >
        {label}
      </h5>
      {!noCheckbox && <Checkbox className="search-menu-filter-block-checkbox" checked={checked} />}
      {picker && (
        <>
          <p
            aria-hidden
            onClick={() => setShowPicker(true)}
            className={`pointer-events-auto absolute z-[2] font-normal ${
              redIfZero && formattedPickerValue === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
            }`}
            style={{ right: px(66), fontSize: px(20), lineHeight: px(26) }}
          >
            {formattedPickerValue}
          </p>
          <Picker
            title={picker?.title}
            data={picker?.data}
            value={picker?.value}
            singleItem
            onChange={value => picker?.onChange(value)}
            show={showPicker}
            onClose={() => setShowPicker(false)}
            button
            isLine
          />
        </>
      )}
    </div>
  );
};

export default CheckboxField;
