import { addLoader, updateLoader } from '../../actions/loaders';
import { selectLoadersByName } from '../../selectors/loaders';
import { LoaderState } from '../../types/Loaders/LoaderState';

export const createRequest =
  ({ loader, onSuccess, onFail, type, shouldUpdateLoader = true }) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async (argument, thunkAPI) => {
    try {
      const { state: loaderState } = selectLoadersByName(type)(thunkAPI.getState()) || {};
      const isChangeLoaderState = loaderState === LoaderState.FULFILLED && shouldUpdateLoader;

      if (!loaderState || isChangeLoaderState) {
        thunkAPI.dispatch(addLoader(type));
      }

      const result = await loader(argument, thunkAPI);

      if (onSuccess) {
        await thunkAPI.dispatch(onSuccess(result));
      }

      return Promise.resolve(thunkAPI.fulfillWithValue(result)).then(
        thunkAPI.dispatch(updateLoader(type, LoaderState.FULFILLED)),
      );
    } catch (error) {
      if (onFail) {
        thunkAPI.dispatch(onFail(error));
      }

      return Promise.reject(thunkAPI.rejectWithValue(error)).then(
        thunkAPI.dispatch(updateLoader(type, LoaderState.REJECT)),
      );
    }
  };
