import { request } from '@/helpers/api/request';

export const deleteFavourites = async ({ id }) => {
  const response = await request({
    url: `favourites/${id}`,
    method: 'DELETE',
  });

  return response;
};
