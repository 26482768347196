import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from '../../components/Icon';
import { px } from '../../constants/Px';
import { TypesIcon } from '../../types/TypesIcon';

export const SMSPage = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="global-container">
      <main>
        <div
          className="flex-column flex h-auto min-h-screen bg-[white]"
          style={{
            gap: px(36),
            padding: `${px(79)} ${px(8)} ${px(20)} ${px(15)}`,
          }}
        >
          <div
            className="search-menu-item-preferences-close"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon type={TypesIcon.CLOSE} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
          </div>
          <div>
            <h1
              className="font-light"
              style={{
                marginBottom: px(22),
              }}
            >
              SMS Код
            </h1>
            <p
              className="font-normal opacity-50"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              Важно
            </p>
            <p
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                width: px(237),
              }}
            >
              - SMS приходит только в странах: Российская Федерация, Республика Армения, Республика
              Беларусь, Республика Казахстан, Кыргызская Республика
            </p>
          </div>
          <div>
            <p
              className="font-normal opacity-50"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              Не приходит SMS с кодом
            </p>
            <div
              className="flex-column flex"
              style={{
                gap: px(22),
              }}
            >
              <p
                className="font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                Среди наиболее распространенных причин:
                <br />
                - режим полета, активированный на мобильном устройстве;
                <br />
                - на некоторых смартфонах установлено программное обеспечение, предназначенное для
                блокировки спама, за который могут быть приняты входящие SMS. В подобной ситуации
                стоит выполнить отключение приложения в настройках и повторить запрос кода
                подтверждения;
                <br />
                - некорректно указанный номер
                <br />
                телефона, код страны или оператора - неполадки или сбой в работе сайта;
                <br />- …;
              </p>
              <p
                className="font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                Что можно сделать:
                <br />
                1. Проверить наличие свободного места и убедиться в стабильности сигнала сотовой
                сети.
                <br />
                2. Перезагрузить мобильный телефон или отключить передачу мобильных данных.
                <br />
                3. Активировать пониженный режим работы GSM-сетей (перейти в настройках сети с 4G на
                ЗG, 2G).
                <br />
                4. Если раньше СМС-сообщения доставлялись без каких-либо проблем, стоит немного
                подождать, после чего повторить попытку запроса. Рекомендуется отключить на 5-10
                минут мобильный интернет или Wi-Fi и снова запросить код подтверждения.
                <br />
                5. Если перечисленные выше рекомендации не помогли решить проблему, необходимо
                обратиться в
                <Link
                  className="font-normal text-[#0a84fe]"
                  to="#"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                >
                  {' '}
                  службу поддержки.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
