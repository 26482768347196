/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getCompetitionCabinet } from '../actions/competition/thunks';
import { getEvent } from '../actions/event';
import { getGuideCabinet } from '../actions/guide';
import { getProfile, getProfileById } from '../actions/profile';
import { px } from '../constants/Px';
import useNotificationCount from '../hooks/useNotificationCount';
import { LogoIcon } from '../new-architecture/icons';
import { Modal } from '../new-architecture/shared';
import {
  authActiveIdSelector,
  authActiveType,
  authSelector,
  isBiometricSelector,
  isCodeSelector,
} from '../selectors/auth';
import { competitionLogoSelector, competitionSelector } from '../selectors/competition';
import { eventSelector } from '../selectors/event';
import { logoObjectSelector } from '../selectors/get-event';
import { guideLogoSelector, guideSelector } from '../selectors/guide';
import { profileByIdSelector, profileSelector } from '../selectors/profile';
import { TypesIcon } from '../types/TypesIcon';
import { ImgLogoHeader } from './header-component/img-logo-header';
import { Icon } from './Icon';
import { Search } from './search';

export const Header = ({ menuToggleOpen, showFilter, showSearch, setShowSearch, fixed }) => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const auth = useSelector(authSelector);
  const isBiometric = useSelector(isBiometricSelector);
  const isCode = useSelector(isCodeSelector);
  const id = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const logo = useSelector(
    activeType === 'object' || activeType === 'administeredObject'
      ? logoObjectSelector
      : activeType === 'competition' || activeType === 'administeredCompetition'
        ? competitionLogoSelector
        : guideLogoSelector,
  );

  const eventSelectorData = useSelector(eventSelector);
  const profile = useSelector(
    activeType === 'administeredProfile' ? profileByIdSelector : profileSelector,
  );
  const competition = useSelector(
    activeType === 'competition' || activeType === 'administeredCompetition'
      ? competitionSelector
      : guideSelector,
  );

  const [searchMenuOpen, setSearchMenuOpen] = useState(false);

  const golfButtonNavigate =
    id === 100_000_000 || id === undefined || activeType === 'administeredProfile'
      ? '/'
      : activeType === 'object' || activeType === 'administeredObject'
        ? `/object/${id}`
        : activeType === 'competition' || activeType === 'administeredCompetition'
          ? `/competition/${id}`
          : `/guide/${id}`;

  const handleOpen = () => {
    if (
      activeType !== 'object' &&
      activeType !== 'administeredObject' &&
      activeType !== 'competition' &&
      activeType !== 'administeredCompetition' &&
      activeType !== 'guide' &&
      activeType !== 'administeredGuide'
    )
      setShowSearch(true);
  };

  const handleNavigate = () => {
    const nextPath = state?.prevPath || '/user-account';
    navigate(nextPath, {
      state: { prevPath: pathname },
    });
  };

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  useEffect(() => {
    if (activeType === 'object' || activeType === 'administeredObject') {
      dispatch(getEvent({ id }));
    }
    if (activeType === 'competition' || activeType === 'administeredCompetition') {
      dispatch(getCompetitionCabinet({ id }));
    }
    if (activeType === 'guide' || activeType === 'administeredGuide') {
      dispatch(getGuideCabinet({ id }));
    }
    if (activeType === 'administeredProfile') {
      dispatch(getProfileById({ id }));
    }
  }, [id, activeType]);

  const unreadNotificationsCount = useNotificationCount();

  return (
    <header className={`header ${fixed ? 'fixed' : 'sticky'}`}>
      <nav className="header__navbar navbar">
        <ul className={`navbar__items ${menuToggleOpen && 'center'}`}>
          <li
            className="navbar__item navbar-item "
            style={{ marginRight: px(6), marginLeft: px(-6) }}
          >
            <div className="navbar-item__link  " onClick={handleOpen} onKeyDown={handleOpen}>
              <div>
                <Icon
                  type={TypesIcon.BLACKSEARCH}
                  width={px(21.357)}
                  height={px(21.358)}
                  viewBox="0 0 21.357 21.358"
                />
              </div>
            </div>
          </li>

          {!auth && (
            <Link className="text-black" to="/">
              <p className="navbar__item-text whitespace-nowrap">booking.fishing</p>
            </Link>
          )}
          {auth && (
            <li className="navbar__item navbar-item">
              <Link className="navbar-item__link" to={golfButtonNavigate}>
                <LogoIcon
                  style={{
                    width: px(35.7),
                    height: px(40),
                  }}
                />
              </Link>
            </li>
          )}
          {auth && (
            <div
              className="navbar__item navbar-item"
              onClick={handleNavigate}
              onKeyDown={handleNavigate}
            >
              <div className="navbar-item__link">
                {!menuToggleOpen && (
                  <Icon
                    type={TypesIcon.BURGERMENU}
                    width={px(22)}
                    height={px(18)}
                    viewBox="0 0 22 18"
                  />
                )}
                {menuToggleOpen && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    className="navbar-item__icon--close"
                  >
                    <path
                      id="Контур_531"
                      data-name="Контур 531"
                      d="M9.439,8.5l7.366-7.366A.664.664,0,1,0,15.866.2L8.5,7.562,1.133.2a.664.664,0,0,0-.939.939L7.561,8.5.194,15.867a.664.664,0,0,0,.939.939L8.5,9.44l7.366,7.366a.664.664,0,1,0,.939-.939Z"
                      transform="translate(0 -0.001)"
                      fill="#3f3f3f"
                    />
                  </svg>
                )}
              </div>
            </div>
          )}
          {auth && (
            <li className="navbar__item navbar-item">
              <Link className="navbar-item__link notifications" to="/notifications">
                {unreadNotificationsCount >= 10 ? (
                  <span className="">{unreadNotificationsCount}</span>
                ) : unreadNotificationsCount > 0 ? (
                  <span className="circle flex items-center justify-center">
                    {unreadNotificationsCount}
                  </span>
                ) : (
                  ''
                )}
                <Icon
                  type={TypesIcon.HEADERBELL}
                  width={px(19.901)}
                  height={px(22.643)}
                  viewBox="0 0 19.901 22.643"
                />
              </Link>
            </li>
          )}

          <li className="navbar__item navbar-item">
            <Link
              className="navbar-item__link"
              to={`${auth ? '/select-profile' : isCode || isBiometric ? '/face-id' : '/login'}`}
            >
              {auth &&
              (id && id !== 100_000_000 && activeType !== 'administeredProfile'
                ? logo
                : profile?.id) ? (
                <div
                  style={{ height: px(40), width: px(40) }}
                  className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
                >
                  {(
                    id && id !== 100_000_000 && activeType !== 'administeredProfile'
                      ? logo
                      : profile?.profileImage?.url
                  ) ? (
                    <img
                      alt="avatar"
                      style={{
                        width: px(40),
                        height: px(40),
                        borderRadius: px(40),
                      }}
                      src={`${process.env.REACT_APP_STATIC_URL}${
                        id && id !== 100_000_000 && activeType !== 'administeredProfile'
                          ? logo
                          : profile?.profileImage?.url
                      }`}
                    />
                  ) : (
                    <p
                      className="font-light text-black"
                      style={{ fontSize: px(32), lineHeight: px(43) }}
                    >
                      {profile?.name?.charAt(0)?.toUpperCase()}
                    </p>
                  )}
                </div>
              ) : (
                <>
                  {auth ? (
                    <ImgLogoHeader {...{ activeType, logo, eventSelectorData, competition }} />
                  ) : (
                    <Icon
                      type={TypesIcon.HEADERPROFILE}
                      width={px(40)}
                      height={px(40)}
                      viewBox="0 0 40 40"
                    />
                  )}
                </>
              )}
            </Link>
          </li>
        </ul>
      </nav>
      <Modal
        show={showSearch}
        onClose={() => {
          setShowSearch(false);
        }}
      >
        <Search
          searchMenuOpen={searchMenuOpen}
          setSearchMenuOpen={setSearchMenuOpen}
          setShowSearch={setShowSearch}
        />
      </Modal>
      {showFilter}
    </header>
  );
};

export default Header;
