import { request } from '../../helpers/api/request';

export const blockEvent = async (data) => {
  const response = await request({
    url: `event/block`,
    method: 'POST',
    data,
  });

  return response;
};
