/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const GET_GUIDE_OFFER = 'guide/getGuideOffer';

export const getGuideOffer = createAsyncThunk(
  GET_GUIDE_OFFER,
  createRequest({
    type: GET_GUIDE_OFFER,
    loader: data => authApi.getGuideOffer(data),
  }),
);
