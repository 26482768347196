import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';

export const CANCEL_DELETE = 'event/cancelDelete';

export const cancelDelete = createAsyncThunk(
  CANCEL_DELETE,
  createRequest({
    type: CANCEL_DELETE,
    loader: data => authApi.cancelDelete(data),
  }),
);
