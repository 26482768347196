import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Button, Main } from '../../components';
import { SearchMenuItemPreferencesSelect } from '../../components/search-menu-item-preferences-select';
import { StateContext } from '../../components/state-provider';
import { countriesDataArray } from '../../data/countries';

export const PreferencesPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { setSelectedPreferences } = useContext(StateContext);
  const [selectedCountry, setSelectedCountry] = useState(countriesDataArray[0].countryName);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [showSelect, setShowSelect] = useState(false);

  const getCountryData = selectedCountry =>
    countriesDataArray.find(country => country.countryName === selectedCountry);

  const selectedCountryData = getCountryData(selectedCountry);

  useEffect(() => {
    setSelectedRegion(selectedCountryData.regions[0].name);
    setSelectedLanguage(selectedCountryData.languages[0].name);
    setSelectedCurrency(selectedCountryData.currencies[0].name);
  }, [selectedCountry, selectedCountryData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="">
      <Main light title="Предпочтения" registration isIconRegistretion>
        <div className="search-menu-item-preferences-inputs ">
          <SearchMenuItemPreferencesSelect
            title="Страна"
            options={countriesDataArray.map(country => country.countryName)}
            arrowDown
            selectedCountry={selectedCountry}
            onSelect={value => setSelectedCountry(value)}
            showSelect={showSelect}
            setShowSelect={setShowSelect}
          />
          {selectedCountry && (
            <SearchMenuItemPreferencesSelect
              title="Регион"
              options={selectedCountryData.regions.map(region => region.name)}
              selectedRegion={selectedRegion}
              arrowDown
              onSelect={value => setSelectedRegion(value)}
              showSelect={showSelect}
              setShowSelect={setShowSelect}
            />
          )}

          <SearchMenuItemPreferencesSelect
            title="Язык"
            options={selectedCountryData.languages.map(language => language.name)}
            selectedLanguage={selectedLanguage}
            arrowDown
            onSelect={value => setSelectedLanguage(value)}
            showSelect={showSelect}
            setShowSelect={setShowSelect}
          />
          <SearchMenuItemPreferencesSelect
            title="Валюта"
            options={selectedCountryData.currencies.map(currency => currency.name)}
            selectedCurrency={selectedCurrency}
            arrowDown
            onSelect={value => setSelectedCurrency(value)}
            showSelect={showSelect}
            setShowSelect={setShowSelect}
          />
        </div>

        <Button
          className="w-full"
          borderRadius={0}
          marginTop={10}
          height={60}
          onClick={() => {
            setSelectedPreferences({
              country: selectedCountry,
              currency: selectedCurrency,
              iconUrl: selectedCountryData.iconUrl,
              svgUrl: selectedCountryData?.svgUrl,
            });
            navigate(-1);
          }}
        >
          Применить
        </Button>
      </Main>
    </div>
  );
};

export default PreferencesPage;
