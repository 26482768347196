import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Banner } from './banner';
import { Icon } from './Icon';

export const Main = ({
  registration,
  isIconRegistretion,
  title,
  children,
  navigatePatch,
  light,
  center,
  lineHeight,
  fontSize,
  backSubmit,
  feedback,
  paddingBottom,
  withUserId,
  titleWidth,
  marginTop,
  paddingTop,
  fixed
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const navigete = useNavigate();
  const { pathname } = useLocation();

  const handleNavigateBack = () => {
    navigete(navigatePatch || -1);
    if (backSubmit) {
      backSubmit();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="global-container">
      <main>
        <div style={{ paddingBottom: paddingBottom && px(80) }}>
          <div className={`w-full ${fixed && 'fixed bg-[white]'}`} style={{ height: px(60) }}>
            {withUserId && (
              <div
                style={{ top: px(22) }}
                className="absolute left-0 right-0 flex flex-row items-center justify-center"
              >
                <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
                  ID 484949
                </p>
              </div>
            )}
            <div
              className=" absolute top-0"
              style={{
                height: px(60),
                width: px(60),
                right: `${(registration || feedback) && px(0)}`,
                left: `${!registration && !feedback && px(0)}`,
              }}
              onClick={handleNavigateBack}
              onKeyDown={handleNavigateBack}
            >
              <div
                style={{
                  marginTop: px(19),

                  marginLeft: `${!registration && !feedback && px(15)}`,
                  marginRight: `${(registration || feedback) && px(28)}`,
                  justifyContent: !registration && !feedback ? 'start' : 'end',
                }}
                className=" flex cursor-pointer  justify-end bg-[#fff] "
              >
                {registration || feedback ? (
                  <Icon type={TypesIcon.CLOSE} viewBox="0 0 17 17" width={px(17)} height={px(17)} />
                ) : (
                  <Icon
                    type={TypesIcon.COLORBACK}
                    viewBox="0 0 11.6 19.76"
                    width={px(11.6)}
                    height={px(19.76)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: `${marginTop || 0}`,
              padding: ` ${px(paddingTop || 19)} ${px(15)} ${px(18)} ${px(15)}`,
            }}
          >
            {title &&
              (registration ? (
                <div
                  style={{
                    paddingBottom: px(18),
                    gap: px(10),
                  }}
                  className="flex items-center justify-center"
                >
                  {!isIconRegistretion && (
                    <Icon
                      type={TypesIcon.HUMAN}
                      viewBox="0 0 24.98 24.98"
                      width={px(24.98)}
                      height={px(24.98)}
                    />
                  )}
                  <p
                    style={{
                      fontSize: px(fontSize || 32),
                      lineHeight: px(lineHeight || 43),
                    }}
                    className={`${light ? 'font-light' : 'font-normal'} text-[#000000] ${
                      center && 'text-center'
                    } `}
                  >
                    {title}
                  </p>
                </div>
              ) : (
                <p
                  style={{
                    fontSize: `${px(fontSize || 32)}`,
                    lineHeight: ` ${px(lineHeight || 43)}`,
                    width: titleWidth && px(289),
                  }}
                  className={`${light ? 'font-light' : 'font-normal'} text-[#000000] ${
                    center && 'text-center'
                  } whitespace-pre-wrap `}
                >
                  {title}
                </p>
              ))}
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};
