import {
  default as sliderImage1,
  default as sliderImage2,
  default as sliderImage3,
  default as sliderImage4,
} from '@assets/images/slider/1.png';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getRegistrationCard } from '../actions/profile';
import bannerPlaceholder from '../assets/images/banner-placeholder.png';
import { addLeadingZeros } from '../constants/Function';
import { px } from '../constants/Px/index';
import { ClubCardViewModal } from '../new-architecture/shared/modal';
import { TypesIcon } from '../types/TypesIcon';
import { Banner } from './banner';
import { Icon } from './Icon';
import { Modal } from './portals';
import { Slider } from './slider';

export const RegistrationCardModal = ({ isOpen, onClose, data: { profileId, eventId } }) => {
  const dispatch = useDispatch();
  const [registrationCardData, setRegistrationCardData] = useState();
  const [activeClubCard, setActiveClubCard] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getRegistrationCard({ profileId, eventId }));
        if (result?.payload?.data) {
          setRegistrationCardData(result?.payload?.data);
        }
      } catch (error) {
        console.error('Error fetching registration card data:', error);
      }
    };
    fetchData();
  }, [profileId, eventId]);
  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }

    return () => document.documentElement.classList.remove('lock');
  }, [isOpen]);
  // mapped received information
  const registrationCardInformation = useMemo(
    () => ({
      id: `ID: ${addLeadingZeros(registrationCardData?.id) || '0000'}`,
      name: `${registrationCardData?.name || ''} ${registrationCardData?.surname || ''}`,
      recommends:
        registrationCardData?.recommends?.map(r => `ID: ${addLeadingZeros(r.recommendedId)}`) || [],
      sportAchievementsTeam: registrationCardData?.sportAchivments?.team || '',
      sportAchievementsClub: registrationCardData?.sportAchivments?.club || '',
      sportAchievementsAthletesBook: registrationCardData?.sportAchivments?.image?.url || '',
      clubCards: registrationCardData?.ClubCardHolder || [],
      images: registrationCardData?.departure?.images || [],
    }),
    [registrationCardData],
  );
  const [showCardTypeView, setShowCardTypeView] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Регистрационная карта">
      <div className="cursor-pointer" style={{ height: px(290) }}>
        <Slider
          images={
            registrationCardInformation?.images.length > 0
              ? registrationCardInformation?.images?.map(item => ({
                  type: 'image',
                  src: `${process.env.REACT_APP_STATIC_URL}${item?.url}` || '',
                }))
              : [
                  // {
                  //   type: 'image',
                  //   src: `${bannerPlaceholder}` || '',
                  // },
                ]
          }
          isOpen={isOpen}
        />
      </div>
      <div className="container">
        <div
          className="flex-column flex outline-white"
          style={{
            paddingBottom: px(20),
          }}
        >
          <div
            className="flex flex-col items-center justify-center"
            style={{ paddingBottom: px(25) }}
          >
            <p
              className="... w-full truncate text-center font-light"
              style={{
                fontSize: px(32),
                lineHeight: px(43),
              }}
            >
              {registrationCardInformation?.name}
            </p>
            <p
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              {registrationCardInformation?.id}
            </p>
          </div>
          <div className="flex flex-col items-start justify-start">
            <h3
              className="font-light"
              style={{
                fontSize: px(32),
                lineHeight: px(43),
              }}
            >
              Оценка
            </h3>
            <p style={{ fontSize: px(15), lineHeight: px(20) }}>От владельцев объектов</p>
            <div
              className="flex items-center justify-center font-normal text-[#39CA1C]"
              style={{
                marginTop: px(25),
                marginBottom: px(25),
                fontSize: px(20),
                lineHeight: px(26),
                gap: px(10),
              }}
              aria-hidden
            >
              <div className="flex flex-row" style={{ gap: px(4.7) }}>
                {[1, 2, 3, 4, 5].map(e => (
                  <Icon
                    key={e}
                    type={TypesIcon.LITTLEFEEDBACKSTARFILL}
                    width={px(15)}
                    height={px(16)}
                    viewBox="0 0 15 16"
                  />
                ))}
              </div>
              <p>10</p>
              <p>Отзывов</p>
            </div>
            <div className="flex w-full flex-col items-start justify-start">
              <h3
                className="font-light"
                style={{
                  paddingBottom: px(25),
                  fontSize: px(32),
                  lineHeight: px(43),
                }}
              >
                Гарантии
              </h3>
              <div className="flex w-full flex-col " style={{ gap: px(24) }}>
                <div className="flex flex-col">
                  <h4 className="font-[400]">Рекомендации</h4>
                  {registrationCardInformation?.recommends.length > 0 ? (
                    <div className="flex flex-col items-start justify-start">
                      {registrationCardInformation?.recommends?.map(recommendedId => (
                        <p key={recommendedId} style={{ fontSize: px(15), lineHeight: px(20) }}>
                          {recommendedId}
                        </p>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex flex-col">
                  <h4 className="font-[400]">Спортивная команда</h4>
                  {registrationCardInformation?.sportAchievementsTeam && (
                    <p
                      style={{ fontSize: px(15), lineHeight: px(20) }}
                      className="w-full break-words"
                    >
                      {registrationCardInformation?.sportAchievementsTeam}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <h4 className="font-[400]">Спортивный клуб</h4>
                  {registrationCardInformation?.sportAchievementsClub && (
                    <p
                      style={{ fontSize: px(15), lineHeight: px(20) }}
                      className="w-full break-words"
                    >
                      {registrationCardInformation?.sportAchievementsClub}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <h4 className="font-[400]">Книжка спортсмена</h4>
                  {registrationCardInformation?.sportAchievementsAthletesBook ? (
                    <a
                      href={`${process.env.REACT_APP_STATIC_URL}${registrationCardInformation?.sportAchievementsAthletesBook}`}
                      className="... truncate text-[#0A84FE]"
                      target="_blank"
                      style={{ fontSize: px(15), lineHeight: px(20) }}
                      rel="noreferrer"
                    >
                      Просмотреть изображение
                    </a>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex flex-col">
                  <h4 className="font-[400]">Клубные карты</h4>
                  {registrationCardInformation?.clubCards?.length > 0
                    ? registrationCardInformation?.clubCards?.map(item => (
                        <div
                          aria-hidden
                          key={item?.id}
                          className="flex items-center justify-start"
                          style={{ gap: px(40) }}
                          onClick={() => {
                            setShowCardTypeView(true);
                            setActiveClubCard(item);
                          }}
                        >
                          <p className="text-[#0A84FE]">{item?.id}</p>
                          <p className="text-[#0A84FE]">{item?.card?.name}</p>
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
            {/* <div
              className="flex items-center justify-center font-normal text-[#39CA1C]"
              style={{
                marginBottom: px(20),
                fontSize: px(20),
                lineHeight: px(26),
                gap: px(11),
              }}
              // onClick={() => navigate('/object-feedback', { state: event?.id })}
              aria-hidden
            >
              <div className="flex flex-row" style={{ gap: px(4.7) }}>
                {[1, 2, 3, 4, 5].map(e => (
                  <Icon
                    key={e}
                    type={
                      rating >= e - 0.5
                        ? TypesIcon.LITTLEFEEDBACKSTARFILL
                        : TypesIcon.LITTLEFEEDBACKSTARBORDER
                    }
                    width={px(15)}
                    height={px(16)}
                    viewBox="0 0 15 16"
                  />
                ))}
              </div>
              <p>{auth ? feedback?.length : feedbackNoAuth?.length}</p>
              <p>Отзывов</p>
            </div> */}
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: px(20) }}>
        <Banner />
      </div>
      {showCardTypeView && (
        <ClubCardViewModal
          showCardTypeView={showCardTypeView}
          setShowCardTypeView={setShowCardTypeView}
          data={activeClubCard}
          removeDeleteButton
        />
      )}
    </Modal>
  );
};

export default RegistrationCardModal;
