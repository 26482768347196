import React, { useEffect, useState } from 'react';

import { Checkbox, Main } from '../../components';
import { Icon } from '../../components/Icon';
import { appealsPage } from '../../constants/Component';
import { px } from '../../constants/Px';
import { TypesIcon } from '../../types/TypesIcon';

export const AppealsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedMessager, setSelectedMessager] = useState('');

  const messagerLinks = appealsPage?.find(obj => obj?.title === selectedOption);

  function select(e) {
    appealsPage.map(elem => {
      if (e === elem?.id) {
        setSelectedOption(elem?.title);
      }
    });
    setIsOpen(false);
  }

  function mainSelect() {
    if (isOpen) {
      setSelectedOption(appealsPage[0]?.title);
    }
    setIsOpen(!isOpen);
  }

  return (
    <Main title="Обращения" light>
      <div
        style={{
          paddingTop: px(19),
        }}
      >
        <div
          className="flex flex-row justify-between  border-[1px] border-solid border-black border-opacity-30"
          onClick={mainSelect}
          style={{
            height: isOpen ? px(70) : px(60),
            paddingLeft: px(18),
            paddingRight: px(18),
            paddingTop: px(16),
          }}
        >
          <p
            className="font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            {isOpen ? appealsPage[0].title : selectedOption || 'Создать обращение'}
          </p>
          {!isOpen && (
            <div
              style={{
                paddingTop: px(10),
              }}
            >
              {' '}
              <Icon width={px(8)} height={px(6)} viewBox="0 0 8 6" type={TypesIcon.ARROWDOWN} />
            </div>
          )}
        </div>
      </div>
      {isOpen &&
        appealsPage?.map(
          item =>
            item?.id !== 1 && (
              <div>
                <div
                  className=" flex flex-row justify-start  border-[1px] border-t-[0px] border-solid border-black border-opacity-30"
                  onClick={() => select(item?.id)}
                  style={{
                    height: item.id === 4 ? px(60) : px(70),
                    paddingLeft: px(18),
                    paddingRight: px(18),
                    paddingTop: px(16),
                  }}
                >
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="font-normal"
                  >
                    {item.title}
                  </p>
                </div>
              </div>
            ),
        )}
      {selectedOption && !isOpen && (
        <div
          className="flex flex-col gap-[10px]"
          style={{
            paddingTop: px(10),
          }}
        >
          <div className="flex gap-[16.4px]">
            <div>
              <Icon
                width={px(31.6)}
                height={px(22.2)}
                viewBox="0 0 31.6 22.2"
                type={TypesIcon.ENVELOP}
              />
            </div>
            <p className="font-normal">Ваша соц. сеть</p>
          </div>
          <div className="flex justify-center gap-[30px]">
            <div className="flex flex-col items-center gap-[13px]">
              <div>
                <Icon width={px(50)} height={px(50)} viewBox="0 0 50 50" type={TypesIcon.WHATSUP} />
              </div>
              <div className="pl-[9px]">
                <Checkbox
                  checked={selectedMessager === 'whatsapp'}
                  onChange={() => setSelectedMessager('whatsapp')}
                />
              </div>
            </div>

            <div className="flex flex-col items-center gap-[12px]">
              <div>
                <Icon
                  width={px(51)}
                  height={px(51)}
                  viewBox="0 0 51 51"
                  type={TypesIcon.TELEGRAM}
                />
              </div>
              <div className="pl-[9px]">
                <Checkbox
                  checked={selectedMessager === 'telegram'}
                  onChange={() => setSelectedMessager('telegram')}
                />
              </div>
            </div>

            <div className="flex flex-col items-center gap-[11px]">
              <div className="mt-[-2px]">
                <Icon width={px(50)} height={px(54)} viewBox="0 0 50 54" type={TypesIcon.VIBER} />
              </div>
              <div className="pl-[9px]">
                <Checkbox
                  checked={false}
                  // onChange={() => setSelectedMessager('viber')}
                />
              </div>
            </div>
          </div>
          <a
            href={messagerLinks[selectedMessager]}
            target="_blank"
            rel="noreferrer"
            style={{
              marginTop: '-2px',
              display: 'block',
              fontSize: px(24),
              lineHeight: px(32),
              height: px(60),
              paddingTop: px(13),
              textAlign: 'center',
              borderRadius: 0,
              color: '#fff',
              fontWeight: '400',
              background: '#0a84fe',
            }}
            disabled={!selectedMessager}
            className={`w-fullC ${!selectedMessager && 'opacity-50'} `}
          >
            Перейти для отправки
          </a>
        </div>
      )}
    </Main>
  );
};
