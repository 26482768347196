export const TypesIcon = {
  HUMAN: 'HUMAN',
  BOATAVATAR: 'BOATAVATAR',
  CLOSE: 'CLOSE',
  BACK: 'BACK',
  REDCLOSE: 'REDCLOSE',
  LOCK: 'LOCK',
  BASKET: 'BASKET',
  BIGLOCK: 'BIGLOCK',
  COLORBACK: 'COLORBACK',
  OPENLOCK: 'OPENLOCK',
  FLAGRUSSIAN: 'FLAGRUSSIAN',
  FLAGBELARUS: 'FLAGBELARUS',
  TOUCHID: 'TOUCHID',
  FACEID: 'FACEID',
  PERSONALINFO: 'PERSONALINFO',
  SECURITY: 'SECURITY',
  NOTIFICATION: 'NOTIFICATION',
  CONTROL: 'CONTROL',
  CARDS: 'CARDS',
  FINANCE: 'FINANCE',
  ANALYTICS: 'ANALYTICS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  CLUBCARTS: 'CLUBCARTS',
  SERTIFICATE: 'SERTIFICATE',
  SUPPORT: 'SUPPORT',
  APPEALS: 'APPEALS',
  CALL: 'CALL',
  MOBILE: 'MOBILE',
  BLOCK: 'BLOCK',
  TRANSFER: 'TRANSFER',
  DELETE: 'DELETE',
  USERPHOTO: 'USERPHOTO',
  MARKER: 'MARKER',
  DELETECODE: 'DELETECODE',
  DELETECODESMALL: 'DELETECODESMALL',
  VIDEO: 'VIDEO',
  SEARCH: 'SEARCH',
  KEY: 'KEY',
  CHANGEKEY: 'CHANGEKEY',
  CLOCK: 'CLOCK',
  ROUNDEDRETURN: 'ROUNDEDRETURN',
  BIGBASKET: 'BIGBASKET',
  PASSPORT: 'PASSPORT',
  DOCUMENT: 'DOCUMENT',
  HOME: 'HOME',
  STATS: 'STATS',
  GAMES: 'GAMES',
  CAMERA: 'CAMERA',
  GALLERY: 'GALLERY',
  ARROWLEFT: 'ARROWLEFT',
  ARROWLEFTWHITE: 'ARROWLEFTWHITE',
  ARROWRIGHT: 'ARROWRIGHT',
  ARROWRIGHTWHITE: 'ARROWRIGHTWHITE',
  MIR: 'MIR',
  KAZAKHSTAN: 'KAZAKHSTAN',
  CHAT: 'CHAT',
  CASH: 'CASH',
  PERSON: 'PERSON',
  PLUS: 'PLUS',
  PEOPLES: 'PEOPLES',
  CHECK: 'CHECK',
  NUMBERONE: 'NUMBERONE',
  BLACKSEARCH: 'BLACKSEARCH',
  WHITESEARCH: 'WHITESEARCH',
  PROFILE: 'PROFILE',
  SMALLBASKET: 'SMALLBASKET',
  MAIL: 'MAIL',
  REWARD: 'REWARD',
  PERSONINCIRCLE: 'PERSONINCIRCLE',
  TELEGRAM: 'TELEGRAM',
  VIBER: 'VIBER',
  WHATSUP: 'WHATSUP',
  ARROWDOWN: 'ARROWDOWN',
  CROSS: 'CROSS',
  MAILSUBSCRIBE: 'MAILSUBSCRIBE',
  BOOKING: 'BOOKING',
  DOTSMORE: 'DOTSMORE',
  LAMP: 'LAMP',
  PENSIL: 'PENSIL',
  SETTINGS: 'SETTINGS',
  SEVEN: 'SEVEN',
  STAR: 'STAR',
  VKONTAKTE: 'VKONTAKTE',
  YOUTUBE: 'YOUTUBE',
  TELEGRAMWITHOUTBG: 'TELEGRAMWITHOUTBG',
  DROPDOWN: 'DROPDOWN',
  WHITELOCK: 'WHITELOCK',
  LINE: 'LINE',
  ARROWTOP: 'ARROWTOP',
  JCB: 'JCB',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  MIRFOOTER: 'MIRFOOTER',
  PAYPAL: 'PAYPAL',
  EDUCATION: 'EDUCATION',
  GOLF: 'GOLF',
  TOURNAMENT: 'TOURNAMENT',
  BURGERMENU: 'BURGERMENU',
  HEADERBELL: 'HEADERBELL',
  HEADERPROFILE: 'HEADERPROFILE',
  CARD: 'CARD',
  AVATARCAMERA: 'AVATARCAMERA',
  ENVELOP: 'ENVELOP',
  CLASS: 'CLASS',
  FEEDBACKSTARBORDER: 'FEEDBACKSTARBORDER',
  FEEDBACKSTARFILL: 'FEEDBACKSTARFILL',
  CLOSERED: 'CLOSERED',
  DOCUMENTFINANCE: 'DOCUMENTFINANCE',
  CHAIR: 'CHAIR',
  DIRECTION: 'DIRECTION',
  ADMINLIST: 'ADMINLIST',
  ADMINCONTROL: 'ADMINCONTROL',
  UNLOCK: 'UNLOCK',
  TRASH: 'TRASH',
  LOCKER: 'LOCKER',
  PLUSFORBUTTON: 'PLUSFORBUTTON',
  MINUSFORBUTTON: 'MINUSFORBUTTON',
  LITTLEFEEDBACKSTARBORDER: 'LITTLEFEEDBACKSTARBORDER',
  LITTLEFEEDBACKSTARFILL: 'LITTLEFEEDBACKSTARFILL',
  INFO: 'INFO',
  REGISTRATION: 'REGISTRATION',
  LAMPBLUE: 'LAMPBLUE',
  CONFIRM: 'CONFIRM',
  FIRST: 'FIRST',
  PHONE: 'PHONE',
  RESIZE: 'RESIZE',
  ADDPLUS: 'ADDPLUS',
  WATERSIDE: 'WATERSIDE',
  FISH: 'FISH',
  HOUSE: 'HOUSE',
  BIGFISH: 'BIGFISH',
  BOAT: 'BOAT',
  HOTELKEY: 'HOTELKEY',
  BUILDING: 'BUILDING',
  LOGHOUSE: 'LOGHOUSE',
  SECTOR: 'SECTOR',
  FREEZONE: 'FREEZONE',
  OFFERMENU: 'OFFERMENU',
  ACCESS: 'ACCESS',
  PRICE: 'PRICE',
  BLUEPRICE: 'BLUEPRICE',
  FREE_ZONE: 'FREE_ZONE',
  FREEZONEAVATAR: 'FREEZONEAVATAR',
  SHORELANDSCAPEAVATAR: 'SHORELANDSCAPEAVATAR',
  ALCOVEAVATAR: 'ALCOVEAVATAR',
  BATHHOUSEAVATAR: 'BATHHOUSEAVATAR',
  CANOPYAVATAR: 'CANOPYAVATAR',
  HOUSEAVATAR: 'HOUSEAVATAR',
  RELAX: 'RELAX',
  ALPHABET: 'ALPHABET',
  REMOVEALPHABET: 'REMOVEALPHABET',
  GROUP: 'GROUP',
  SUN: 'SUN',
  ACTIVESUN: 'ACTIVESUN',
  NIGHT: 'NIGHT',
  ACTIVENIGHT: 'ACTIVENIGHT',
  GALLERYOBJECT: 'GALLERYOBJECT',
  NEWS: 'NEWS',
  RULES: 'RULES',
  STATISTICS: 'STATISTICS',
  HALLOFGLORY: 'HALLOFGLORY',
  AD: 'AD',
  EYEOPEN: 'EYEOPEN',
  EYECLOSE: 'EYECLOSE',
  FILTER: 'FILTER',
  ICEAVATAR: 'ICEAVATAR',
  SPEED: 'SPEED',
  SCREEN: 'SCREEN',
  SURPRISE: 'SURPRISE',
  LIST: 'LIST',
  PLUSBIG: 'PLUSBIG',
  ERASER: 'ERASER',
  CARDBACK: 'CARDBACK',
  MOVETOANOTHERGROUP: 'MOVETOANOTHERGROUP',
  BIGSORT: 'BIGSORT',
  SORT: 'SORT',
  HAND: 'HAND',
  DROPDOWNTRIANGLE: 'DROPDOWNTRIANGLE',
  RUB: 'RUB',
  RUBLE: 'RUBLE',
  INFOCURSIVE: 'INFOCURSIVE',
  BLUEMINUS: 'BLUEMINUS',
  SETTINGSFILTER: 'SETTINGSFILTER',
  DELETEDPROFILE: 'DELETEDPROFILE',
  DIAGRAMS: 'DIAGRAMS',
  OPENMAIL: 'OPENMAIL',
  CIRCLES: 'CIRCLES',
  TOP: 'TOP',
  NOTE: 'NOTE',
  SOMETHING: 'SOMETHING',
  NOTEBOOK: 'NOTEBOOK',
  FRAME: 'FRAME',
  RECORDS: 'RECORDS',
  ADDPROFILE: 'ADDPROFILE',
  VIBERSMALL: 'VIBERSMALL',
  ARROWDOWNLOAD: 'ARROWDOWNLOAD',
  WHATSAPP: 'WHATSAPP',
};

export default TypesIcon;
