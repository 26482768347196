import React from 'react';
import { px } from '../constants/Px';

export const FaceIdDot = ({ error, confrirm, code, index }) => {
  return (
    <div
      className={` ${
        error
          ? 'bg-[#FF0000]'
          : confrirm
          ? 'bg-[#00FF11]'
          : code?.length === 4
          ? 'bg-[#030303] opacity-30'
          : code?.length > index
          ? 'bg-[#030303]'
          : ''
      } ${code?.length !== 4 && 'border-[1px] border-solid border-[#030303] opacity-30'}`}
      style={{
        width: `${px(12.5)}`,
        height: `${px(12.5)}`,
        borderRadius: `${px(12.5)}`,
      }}
    ></div>
  );
};

export default FaceIdDot;
