import { request } from '../../helpers/api/request';
//
export const cardHolderBlock = async ({ holderId, data }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `card-holder/${holderId}`,
    method: 'PUT',
    data,
  });
  return response;
};
