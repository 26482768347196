import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/news';
import { createRequest } from '../../../helpers/api/createRequest';

export const DELETE_NEWS = 'news/deleteNews';

export const deleteNews = createAsyncThunk(
  DELETE_NEWS,
  createRequest({
    type: DELETE_NEWS,
    loader: data => authApi.deleteNews(data),
  }),
);
