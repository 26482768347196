import { request } from '../../helpers/api/request';

export const logout = async () => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `auth/logout`,
    method: 'POST',
  });
  return response;
};
