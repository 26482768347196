import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SearchMenuItemFilter } from '../../components/search-menu-item-filter';
import { StateContext } from '../../components/state-provider';

export const ItemFilterPage = () => {
  const { setSelectedItem, setResultsListItems, resultsListItems, setResultsListOpen } =
    useContext(StateContext);
  const { pathname, state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.documentElement.classList.remove('lock');
  }, []);

  return (
    <div className="global-container">
      <main>
        <SearchMenuItemFilter
          selectedItem={state?.selectedItem}
          setSelectedItem={setSelectedItem}
          setResultsListItems={setResultsListItems}
          resultsListItems={resultsListItems}
          setResultsListOpen={setResultsListOpen}
        />
      </main>
    </div>
  );
};
