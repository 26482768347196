/* eslint-disable unicorn/no-useless-undefined */
import { createReducer } from '@reduxjs/toolkit';

import { getEvent, getEvents, getProfileEvent, locationEvent } from '../../actions/event';
import { objectImageTypeSlider } from '../../constants/Component';

export const event = createReducer({}, builder => {
  builder.addCase(locationEvent.fulfilled, (state, { payload }) => ({
    ...state,
    cards: payload?.data?.data,
  }));
  builder.addCase(getEvent.fulfilled, (state, { payload }) => ({
    ...state,
    event: payload?.data,
    logoImageUrl: payload?.data?.EventAttachment?.find(index => index.type === 'LOGO')?.imageUrl,
    name: payload?.data?.name,
  }));
  builder.addCase(getProfileEvent.fulfilled, (state, { payload }) => ({
    ...state,
    profileEvent: payload?.data,
  }));
  builder.addCase(getEvents.fulfilled, (state, { payload }) => ({
    ...state,
    events: payload?.data,
  }));
});

export const eventObject = createReducer({}, builder => {
  builder.addCase(getEvent.fulfilled, (state, { payload }) => ({
    ...state,
    object: payload?.data,
    images: objectImageTypeSlider.map((type, index_) =>
      payload?.data?.EventAttachment?.filter(index => index.type === type)[0]
        ? {
            id: payload?.data?.EventAttachment?.filter(index => index.type === type)[0].id,
            src: `${process.env.REACT_APP_STATIC_URL}${
              payload?.data?.EventAttachment?.filter(index => index.type === type)[0].imageUrl
            }`,
            type: payload?.data?.EventAttachment?.filter(index => index.type === type)[0].type,
          }
        : { id: index_, src: '', type: 'blueBg' },
    ),
    gallery:
      payload?.data?.EventAttachment?.filter(index => index?.type === 'GALLERY').length > 0
        ? payload?.data?.EventAttachment?.filter(index => index?.type === 'GALLERY').map(item => ({
            id: item?.id,
            src: `${process.env.REACT_APP_STATIC_URL}${item?.imageUrl}`,
            type: item?.type,
          }))
        : [{ id: undefined, src: '', type: 'blueBg' }],

    schema:
      payload?.data?.EventAttachment?.filter(index => index?.type === 'SCHEMA').length > 0
        ? payload?.data?.EventAttachment?.filter(index => index?.type === 'SCHEMA')
            .slice()
            .sort((a, b) => a.priority - b.priority)
            .map(item => ({
              id: item?.id,
              src: `${process.env.REACT_APP_STATIC_URL}${item?.imageUrl}`,
              type: item?.type,
              title: item?.title,
              textFileName: decodeURIComponent(item?.textFileName),
              textFileUrl: item?.textFileUrl,
            }))
        : [],

    news: payload?.data?.EventNews?.slice().sort((a, b) => a.id - b.id),

    emptyIndex: objectImageTypeSlider
      .map((type, index_) =>
        payload?.data?.EventAttachment?.filter(index => index.type === type)[0]
          ? {
              id: payload?.data?.EventAttachment?.filter(index => index.type === type)[0].id,
              src: `${process.env.REACT_APP_STATIC_URL}${
                payload?.data?.EventAttachment?.filter(index => index.type === type)[0].imageUrl
              }`,
              type: payload?.data?.EventAttachment?.filter(index => index.type === type)[0].type,
            }
          : undefined,
      )
      .indexOf(undefined),
  }));
});
