import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/sector';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_SECTORS = 'sector/addNews';

export const getSectors = createAsyncThunk(
  GET_SECTORS,
  createRequest({
    type: GET_SECTORS,
    loader: data => authApi.getSectors(data),
  }),
);
