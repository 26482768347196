import React from 'react';
import { px } from '../constants/Px';
import { Icon } from './Icon';
import user from '../assets/images/jonnyDeph.jpg';
import { TypesIcon } from '../types/TypesIcon';
import {
  feedbackComponentFeedbackTitles,
} from '../constants/Component';
import { getFormatDate } from '../constants/Function';


export const FeedbackComponent = ({ data }) => {
  const arr = [1, 2, 3, 4, 5];
  const time = getFormatDate(data.createdAt, false, true).split(' ')[2];

  const getGate = getFormatDate(data.createdAt, false, true).split(' ');

  const date =
    getGate[0][0] === '0' ? getGate[0][1] + `\n` + getGate[1] : getGate[0] + `\n` + getGate[1];

    

  return (
    <>
      <div className='flex flex-column ' style={{ marginBottom: px(10) }}>
        <div
          className='flex flex-row'
          style={{
            gap: px(10),
          }}
        >
          <div style={{ paddingTop: px(7) }}>
            <img
              className='rounded-[50%]'
              src={`${process.env.REACT_APP_STATIC_URL}${data?.profile?.avatar?.url}`}
              alt='user'
              style={{
                height: px(60),
                width: px(60),
              }}
            />
          </div>

          <div>
            <p
              className='font-normal'
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                marginBottom: px(1),
              }}
            >
              {data?.profile?.name}
            </p>
            <div
              className='flex flex-row items-center'
              style={{ gap: px(10), marginBottom: px(6) }}
            >
              <p
                className='font-normal'
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {time}
              </p>
              <p
                className='font-normal'
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {date}
              </p>
            </div>
            <div className='flex flex-row' style={{ gap: px(4.7) }}>
              {arr.map((e) => (
                <Icon
                  type={
                    data?.totalRating >= e
                      ? TypesIcon.LITTLEFEEDBACKSTARFILL
                      : TypesIcon.LITTLEFEEDBACKSTARBORDER
                  }
                  width={px(15)}
                  height={px(16)}
                  viewBox={'0 0 15 16'}
                />
              ))}
            </div>
          </div>
        </div>
          {feedbackComponentFeedbackTitles.map((item) => (
            < >
              <div
                className='flex flex-row items-center'
                style={{ gap: px(10), marginBottom: px(8), marginTop: px(10) }}
              >
                <div
                  className='  flex justify-center items-center rounded-[50%] border-solid border-[1px] border-black '
                  style={{
                    height: px(31),
                    width: px(31),
                  }}
                >
                  <Icon
                    type={item.iconType}
                    viewBox={item.viewBox}
                    width={item.iconWidth}
                    height={item.iconHeight}
                  />
                </div>
                <p className='font-normal' style={{ fontSize: px(20), lineHeight: px(26) }}>
                  {item.title}
                </p>
              </div>

              {data && (
                <p className='font-light' style={{ fontSize: px(20), lineHeight: px(26) }}>
                  {data[item.objKey]}
                </p>
              )}
            </ >
          ))}
        </div>
    </>
  );
};

export default FeedbackComponent;
