import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';
import { mapSectorToFields } from '../../../../helpers/sector.helpers';
import { Sector } from '../../../fishing-menu/components';
import { PriceSettings } from '../price-settings';

export const PriceGroup = ({ data, sectors, type, priceDiscountAction }) => {
  const {
    data: {
      priceGroup,
      promotionGroup,
      discountGroup,
      selectedSector,
      locations,
      selectedLocationId,
    },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const setPriceGroup = fields =>
    setProfile({
      priceGroup: {
        ...priceGroup,
        ...fields,
      },
    });

  const setPromotionGroup = fields =>
    setProfile({
      promotionGroup: {
        ...promotionGroup,
        ...fields,
      },
    });

  const setDiscountGroup = fields =>
    setProfile({
      discountGroup: {
        ...discountGroup,
        ...fields,
      },
    });

  const setSelectedSector = item =>
    item === selectedSector
      ? setProfile({ selectedSector: undefined })
      : setProfile({ selectedSector: item });

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (priceGroup.id !== data.id) setHide(false);
  }, [priceGroup]);

  useEffect(() => {
    if (promotionGroup.id !== data.id) setHide(false);
  }, [promotionGroup]);

  useEffect(() => {
    if (discountGroup.id !== data.id) setHide(false);
  }, [discountGroup]);

  return (
    <section className="container" style={{ marginBottom: px(20) }}>
      <div>
        <p style={{ fontSize: px(32), lineHeight: px(32), marginBottom: px(10) }}>
          {data?.groupNumber}
        </p>
      </div>
      <div className="flex flex-wrap" style={{ gap: px(10), marginBottom: px(19) }}>
        {sectors?.map(item => (
          <Sector
            sector={mapSectorToFields(item, locations, selectedLocationId)}
            onClick={() => setSelectedSector(item.id)}
            key={item?.id}
            id={item?.id}
            sectorNumber={item?.number}
            discount={item?.discount}
            type={item}
            price={item?.price}
            active={selectedSector === item?.id}
          />
        ))}
      </div>

      <div>
        <div className="flex items-center justify-between">
          <p style={{ fontSize: px(32), lineHeight: px(32) }}>
            {priceDiscountAction === 'price'
              ? 'Тарифы'
              : priceDiscountAction === 'discount'
                ? 'Скидка'
                : 'Акция'}
          </p>
          <div>
            {priceDiscountAction === 'price' && (
              <button
                type="button"
                className="font-normal"
                onClick={async () => {
                  setHide(!hide);
                  setPriceGroup(data);
                  updateProfile();
                }}
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: '#0A84FE',
                  cursor: 'pointer',
                }}
              >
                {priceGroup.id === data.id && hide ? 'скрыть' : 'показать'}
              </button>
            )}
            {priceDiscountAction === 'action' && (
              <button
                type="button"
                className="font-normal"
                onClick={async () => {
                  setHide(!hide);
                  setPromotionGroup(data);
                  updateProfile();
                }}
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: '#0A84FE',
                  cursor: 'pointer',
                }}
              >
                {promotionGroup.id === data.id && hide ? 'скрыть' : 'показать'}
              </button>
            )}
            {priceDiscountAction === 'discount' && (
              <button
                type="button"
                className="font-normal"
                onClick={async () => {
                  setHide(!hide);
                  setDiscountGroup(data);
                  updateProfile();
                }}
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: '#0A84FE',
                  cursor: 'pointer',
                }}
              >
                {discountGroup.id === data.id && hide ? 'скрыть' : 'показать'}
              </button>
            )}
          </div>
        </div>
        <p
          style={{ width: px(260), fontSize: px(15), lineHeight: px(20), marginBottom: px(19) }}
          className="font-normal"
        >
          Выберите периоды. Установите цены и условия:
        </p>

        {priceDiscountAction === 'price' && priceGroup.id === data.id && hide && (
          <PriceSettings data={data} type={type} priceDiscountAction={priceDiscountAction} />
        )}
        {priceDiscountAction === 'action' && promotionGroup.id === data.id && hide && (
          <PriceSettings
            data={data}
            type={type}
            priceDiscountAction={priceDiscountAction}
            firstSector={sectors[0]}
          />
        )}
        {priceDiscountAction === 'discount' && discountGroup.id === data.id && hide && (
          <PriceSettings
            data={data}
            type={type}
            priceDiscountAction={priceDiscountAction}
            firstSector={sectors[0]}
          />
        )}
      </div>
    </section>
  );
};

export default PriceGroup;
