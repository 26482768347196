import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';

export const CARD_HOLDER_BLOCK = 'card-holder/cardHolderBlock';

export const cardHolderBlock = createAsyncThunk(
  CARD_HOLDER_BLOCK,
  createRequest({
    type: CARD_HOLDER_BLOCK,
    loader: data => authApi.cardHolderBlock(data),
  }),
);
