import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

import { getNotification } from '../../actions/notification';
import { Banner, DateContainer, HeaderWithSearch } from '../../components';
import {
  NotificationTypeInvite,
  NotificationTypeInviteRequest,
  NotificationTypeInviteResponse,
} from '../../components/notification-type';
import { NotificationTypeInviteResponseDirect } from '../../components/notification-type/notification-type-invite-response-direct';
import { RegistrationCardModal } from '../../components/registration-card-modal';
import { StateContext } from '../../components/state-provider';
import { getFormatDate, getFormatDateHours } from '../../constants/Function';
import { px } from '../../constants/Px/index';
import {
  eventDateSelector,
  eventNameSelector,
  notificationEventsSelector,
  notificationSelector,
  offersSelector,
} from '../../selectors/notification';

export const NotificationsPage = () => {
  const { showSearch, setShowSearch } = useContext(StateContext);
  // states for RegistrationCardModal
  const [registrationCardProfileId, setRegistrationCardProfileId] = useState();
  const [registrationEventId, setRegistrationEventId] = useState();

  const events = useSelector(notificationEventsSelector);
  const notification = useSelector(notificationSelector);
  const offers = useSelector(offersSelector);
  const eventName = useSelector(eventNameSelector);
  const eventDate = useSelector(eventDateSelector);
  const dispatch = useDispatch();

  const [id, setId] = useState(1);
  const [eventId, setEventId] = useState(0);
  const [data, setData] = useState(notification);
  const [searchReq, setSearchReq] = useState('');
  const [registrationCardModalOpen, setRegistrationCardModalOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [menuToggleOpen, setMenuToggleOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (id === 1) {
      if (eventId === 0) {
        setData(notification);
      } else {
        setData(events?.filter(ev => ev?.eventName === eventId)[0]?.notifications);
      }
    } else {
      setData(offers);
    }
  }, [eventId, events, id, notification, offers]);
  const handleChangeId = id => {
    setId(id);
    setEventId(0);
  };

  const handleChangeEventId = eventId => {
    setEventId(eventId);
  };

  useEffect(() => {
    dispatch(getNotification());
  }, []);

  return (
    <div className="global-container">
      {!menuToggleOpen && <Banner />}
      <HeaderWithSearch
        menuToggleOpen={menuToggleOpen}
        setMenuToggleOpen={setMenuToggleOpen}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        setSearchReq={setSearchReq}
      />
      <main
        style={{
          padding: `${px(11)} ${px(0)} ${px(60)} ${px(0)}`,
        }}
      >
        <div
          style={{
            gap: px(10),
            paddingLeft: px(15),
          }}
          className="flex "
        >
          <button
            aria-hidden
            type="button"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
            className={`${id === 2 && 'opacity-50'} font-normal`}
            onClick={() => {
              handleChangeId(1);
            }}
          >
            Уведомления
          </button>
          <button
            aria-hidden
            type="button"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
            className={`${id === 1 && 'opacity-50'} font-normal`}
            onClick={() => {
              handleChangeId(2);
            }}
          >
            Предложения
          </button>
        </div>
        <div
          style={{
            paddingTop: px(20),
            paddingBottom: px(22),
            paddingLeft: px(15),
          }}
        >
          <Swiper slidesPerView="auto" spaceBetween={10}>
            <SwiperSlide
              key={0}
              style={{
                width: px(64),
                height: px(38),
              }}
              onClick={() => handleChangeEventId(0)}
            >
              <div
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  color: eventId === 0 && '#FFFFFF',
                  background: eventId === 0 && '#000000',
                  borderRadius: px(50),
                  height: px(38),
                  paddingLeft: px(15),
                }}
                className="flex cursor-pointer  items-center border-[1px] border-solid border-[#000000] border-opacity-30 font-normal"
              >
                Все
              </div>
            </SwiperSlide>
            {eventName?.map(item => (
              <SwiperSlide
                key={item?.id}
                style={{
                  maxWidth: px(153),
                  width: 'auto',
                  height: px(38),
                  color: eventId === item ? '#FFFFFF' : '#000000',
                  background: eventId === item ? '#000000' : '#FFFFFF',
                  borderRadius: px(50),
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleChangeEventId(item);
                }}
              >
                <div
                  style={{
                    borderRadius: px(50),
                    height: px(38),
                    paddingLeft: px(15),
                    paddingRight: px(15),
                  }}
                  className="cursor-pointers flex items-center border-[1px] border-solid border-[#000000] border-opacity-30 "
                >
                  <p
                    className="... w-full truncate font-normal"
                    style={{ fontSize: px(20), lineHeight: px(26) }}
                  >
                    {item}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          style={{
            position: 'relative',
          }}
        >
          <div className="relative flex flex-col" style={{ gap: px(10) }}>
            {data?.length
              ? eventDate.map(itemDate => (
                  <div key={data?.id} style={{ position: 'relative' }}>
                    {data
                      ?.filter(
                        item =>
                          item?.body.message?.toLowerCase().includes(searchReq.toLowerCase()) ||
                          (item?.body.name &&
                            item?.body.name.toLowerCase().includes(searchReq.toLowerCase())) ||
                          (item?.body.cardName &&
                            item?.body.cardName.toLowerCase().includes(searchReq.toLowerCase())) ||
                          (item?.body.cardStatus &&
                            `${item?.body.cardStatus}%`.includes(searchReq)) ||
                          (item?.body.openUntill &&
                            new Date(item?.body.openUntill)
                              .toLocaleDateString()
                              .includes(searchReq)) ||
                          (item?.body.holderName &&
                            item?.body.holderName
                              .toLowerCase()
                              .includes(searchReq.toLowerCase())) ||
                          (item?.body.holderProfileId &&
                            `${item?.body.holderProfileId}`.includes(searchReq)) ||
                          (item?.body.eventName &&
                            item?.body.eventName.toLowerCase().includes(searchReq.toLowerCase())) ||
                          (item?.body.cardActiveDate &&
                            item?.body.cardActiveDate.includes(searchReq)) ||
                          (item?.body.cardType &&
                            item?.body.cardType === 'DISCOUNT' &&
                            searchReq.toLowerCase().includes('скидочная')) ||
                          (item?.body.cardType === 'CLUB' &&
                            searchReq.toLowerCase().includes('клубная')) ||
                          (item?.body.cardType &&
                            (searchReq.toLowerCase().includes('запрос карты'.toLowerCase()) ||
                              (item?.body.cardType === 'DISCOUNT' &&
                                searchReq.toLowerCase().includes('скидочная')) ||
                              (item?.body.cardType === 'CLUB' &&
                                searchReq.toLowerCase().includes('клубная')) ||
                              (searchReq.toLowerCase().includes('запрос'.toLowerCase()) &&
                                (item?.body.cardType === 'DISCOUNT' ||
                                  item?.body.cardType === 'CLUB')))) ||
                          (item?.body.holderPlastic &&
                            item?.body.holderPlastic === searchReq.replace('№', '')),
                      )
                      ?.filter(item => getFormatDate(item?.createdAt, true).trim() === itemDate)
                      .length > 0 ? (
                      <>
                        <DateContainer itemDate={itemDate} />
                        <div className="relative flex flex-col " style={{ gap: px(10) }}>
                          {data
                            ?.filter(
                              item =>
                                item?.body.message
                                  ?.toLowerCase()
                                  .includes(searchReq.toLowerCase()) ||
                                (item?.body.name &&
                                  item?.body.name
                                    .toLowerCase()
                                    .includes(searchReq.toLowerCase())) ||
                                (item?.body.cardName &&
                                  item?.body.cardName
                                    .toLowerCase()
                                    .includes(searchReq.toLowerCase())) ||
                                (item?.body.cardStatus &&
                                  `${item?.body.cardStatus}%`.includes(searchReq)) ||
                                (item?.body.openUntill &&
                                  new Date(item?.body.openUntill)
                                    .toLocaleDateString()
                                    .includes(searchReq)) ||
                                (item?.body.holderName &&
                                  item?.body.holderName
                                    .toLowerCase()
                                    .includes(searchReq.toLowerCase())) ||
                                (item?.body.holderProfileId &&
                                  `${item?.body.holderProfileId}`.includes(searchReq)) ||
                                (item?.body.eventName &&
                                  item?.body.eventName
                                    .toLowerCase()
                                    .includes(searchReq.toLowerCase())) ||
                                (item?.body.cardActiveDate &&
                                  item?.body.cardActiveDate.includes(searchReq)) ||
                                (item?.body.cardType &&
                                  item?.body.cardType === 'DISCOUNT' &&
                                  searchReq.toLowerCase().includes('скидочная')) ||
                                (item?.body.cardType === 'CLUB' &&
                                  searchReq.toLowerCase().includes('клубная')) ||
                                (item?.body.cardType &&
                                  (searchReq.toLowerCase().includes('запрос карты'.toLowerCase()) ||
                                    (item?.body.cardType === 'DISCOUNT' &&
                                      searchReq.toLowerCase().includes('скидочная')) ||
                                    (item?.body.cardType === 'CLUB' &&
                                      searchReq.toLowerCase().includes('клубная')) ||
                                    (searchReq.toLowerCase().includes('запрос'.toLowerCase()) &&
                                      (item?.body.cardType === 'DISCOUNT' ||
                                        item?.body.cardType === 'CLUB')))) ||
                                (item?.body.holderPlastic &&
                                  item?.body.holderPlastic === searchReq.replace('№', '')),
                            )
                            ?.filter(
                              item => getFormatDate(item?.createdAt, true).trim() === itemDate,
                            )
                            .map(item => (
                              <div
                                key={item?.id}
                                style={{
                                  paddingRight: px(15),
                                  paddingLeft: px(15),
                                  gap: px(10),
                                }}
                                className="flex "
                              >
                                <div
                                  style={{
                                    width: px(51),
                                    paddingTop: px(5),
                                    paddingBottom: px(14),
                                  }}
                                  className="flex flex-col justify-between "
                                >
                                  <div>
                                    <p
                                      style={{
                                        fontSize: px(15),
                                        lineHeight: px(20),
                                        color: item?.isRead ? '#000000' : '#FF0A0A',
                                      }}
                                    >
                                      {getFormatDateHours(item?.createdAt)}
                                    </p>
                                  </div>
                                  <div
                                    style={{ width: px(40), height: px(40), borderRadius: px(100) }}
                                  >
                                    {item?.notificationAvatar ? (
                                      <img
                                        alt="avatar"
                                        style={{
                                          width: px(40),
                                          height: px(40),
                                          borderRadius: px(40),
                                        }}
                                        src={`${process.env.REACT_APP_STATIC_URL}${item?.notificationAvatar}`}
                                      />
                                    ) : (
                                      <div
                                        style={{ height: px(40), width: px(40) }}
                                        className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
                                      >
                                        <p
                                          className="font-light text-black"
                                          style={{ fontSize: px(32), lineHeight: px(43) }}
                                        >
                                          {item?.body?.name
                                            ? item?.body?.name?.charAt(0)?.toUpperCase()
                                            : item?.body?.eventName?.charAt(0)?.toUpperCase()}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    paddingLeft: px(10),
                                    paddingRight: px(10),
                                    minWidth: px(240),
                                    borderRadius: px(10),
                                  }}
                                  className="flex  flex-1 flex-col bg-[#F7F9FC]"
                                >
                                  {item?.notificationType === 'INVITE_REQUEST' ? (
                                    <NotificationTypeInviteRequest
                                      item={item}
                                      states={{
                                        setRegistrationCardModalOpen,
                                        setRegistrationCardProfileId,
                                        setRegistrationEventId,
                                      }}
                                    />
                                  ) : item?.notificationType === 'INVITE' ? (
                                    <NotificationTypeInvite item={item} />
                                  ) : item?.notificationType === 'INVITE_RESPONSE' ? (
                                    <NotificationTypeInviteResponse item={item} />
                                  ) : item?.notificationType === 'INVITE_RESPONSE_DIRECT' ? (
                                    <NotificationTypeInviteResponseDirect item={item} />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    ) : (
                      <div>
                        <p
                          style={{
                            fontSize: px(20),
                            lineHeight: px(26),
                          }}
                          className="whitespace-pre-wrap text-center font-normal opacity-50"
                        >
                          {`По вашему запросу ничего\nне найдено`}
                        </p>
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
        <RegistrationCardModal
          isOpen={registrationCardModalOpen}
          onClose={() => setRegistrationCardModalOpen(false)}
          data={{ profileId: registrationCardProfileId, eventId: registrationEventId }}
        />
      </main>
    </div>
  );
};
