/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createGuideOffer, updateGuideOffer } from '../../../../../actions/guide';
import { Button } from '../../../../../components';
import { StyledInput } from '../../../../../components/styled-input';
import { AreaAndDistrictOptions } from '../../../../../constants/Component';
import { px } from '../../../../../constants/Px';
import { authActiveIdSelector } from '../../../../../selectors/auth';
import { TypesIcon } from '../../../../../types/TypesIcon';
import {
  CircleButton,
  PeriodSetter,
  PickerInput,
  ScrollButtonsContainer,
  Textarea,
} from '../../../../shared';
import { CheckboxFieldGroup } from '../../../../shared/checkbox-field-group';
import { capitalizeFirstLetter } from '../../../../utils';
import { useGuide } from '../../../hooks';

export const InformationForm = ({ information: defaultInformation, onClose }) => {
  const dispatch = useDispatch();
  const activeId = useSelector(authActiveIdSelector);
  const [geo, setGeo] = useState(defaultInformation?.geolocation || [{}]);
  const [cost, showCost] = useState(false);

  const addGeo = () => {
    const newGeo = [...geo, {}];
    setGeo(newGeo);
  };

  const { updateFunction: updateGuide } = useGuide();

  const {
    country,
    region,
    district,
    location,
    sportsAchivments,
    prices,
    teaching,
    equipment,
    program,
    tournamentType,
    participantsType,
    periodStart,
    periodEnd,
    payment,
    standing,
    discipline,
    direction,
    fishType,
    rules,
    boat,
    accomodation,
    service,
    participantsNumber,
    pricePerHour,
    terms,
  } = defaultInformation || {};

  const [start, setStart] = useState(periodStart);
  const [end, setEnd] = useState(periodEnd);
  const [enabled, setEnabled] = useState(false);

  const [dataForRequest, setDataForRequest] = useState({
    location: '',
    organizers: '',
    participantsNumber: '',
    languageKnowledge: '',
    sportsAchivments: '',
    prices: '',
    teaching: '',
    boat: '',
    equipment: '',
    program: '',
    periodStart: '',
    periodEnd: '',
    payment: '',
    pricePerHour: 0,
    accomodation: '',
    terms: '',
    tournamentType: [],
    participantsType: [],
    standing: [],
    discipline: [],
    direction: [],
    fishType: [],
    service: [],
    isBookingOpen: false,
  });

  const setFields = field => {
    setDataForRequest(prevDataForRequest => ({
      ...prevDataForRequest,
      ...field,
    }));
  };

  const optionAreaDistrictFilter = (id, country) => {
    if (country === '') {
      return [];
    }
    return id === 2 && country === 'Россия'
      ? AreaAndDistrictOptions.russiaDistricts
      : id === 3 && country === 'Россия'
        ? AreaAndDistrictOptions.regionsAndAreas[dataForRequest.region] || []
        : id === 3 && country === 'Беларусь'
          ? AreaAndDistrictOptions.belarusAreas
          : AreaAndDistrictOptions.kazachstanAreas;
  };

  useEffect(() => {
    setFields({ periodEnd: end });
  }, [end]);

  useEffect(() => {
    setFields({ periodStart: start });
  }, [start]);

  useEffect(() => {
    if (defaultInformation) {
      setDataForRequest({ ...defaultInformation });
    }
  }, [defaultInformation]);

  useEffect(() => {
    if (
      dataForRequest.location === '' &&
      dataForRequest.organizers === '' &&
      dataForRequest.participantsNumber === '' &&
      dataForRequest.languageKnowledge === '' &&
      dataForRequest.accomodation === '' &&
      dataForRequest.prices === '' &&
      dataForRequest.sportsAchivments === '' &&
      dataForRequest.payment === '' &&
      dataForRequest.terms === '' &&
      dataForRequest.boat === '' &&
      dataForRequest.equipment === '' &&
      dataForRequest.teaching === '' &&
      dataForRequest.program === ''
    )
      setEnabled(false);
    else setEnabled(true);
  }, [dataForRequest]);

  return (
    <div
      className="container w-full"
      style={{
        paddingBottom: px(80),
        paddingTop: px(80),
      }}
    >
      <div style={{ marginBottom: px(16) }}>
        <p style={{ fontSize: px(32), lineHeight: px(43) }}>Информация</p>
        <p style={{ fontSize: px(15), lineHeight: px(20) }}>
          Работа с сервисом будет еще удобнее, если Вы заполните все формы
        </p>
      </div>
      <div style={{ marginBottom: px(10) }}>
        <StyledInput
          isBorder
          id={1}
          country={dataForRequest.country || 'Россия'}
          value={dataForRequest.country}
          onChange={e => setFields({ country: e.target.value })}
          options={['Россия', 'Беларусь', 'Казахстан']}
          title="Страна"
          textOpacity={50}
          placeholder="Выбор из списка"
          placeholderOpacity
        />
      </div>
      <div style={{ marginBottom: px(10) }}>
        <StyledInput
          isBorder
          id={2}
          country={dataForRequest.country || 'Россия'}
          value={dataForRequest.region}
          onChange={e => setFields({ region: e.target.value })}
          options={optionAreaDistrictFilter(2, dataForRequest.country)}
          title="Регион"
          textOpacity={50}
          placeholder="Выбор из списка"
          placeholderOpacity
          disabled={
            dataForRequest.country === 'Беларусь' ||
            dataForRequest.country === 'Казахстан' ||
            !dataForRequest.country
          }
        />
      </div>
      <div style={{ marginBottom: px(10) }}>
        <StyledInput
          isBorder
          id={3}
          country={dataForRequest.country || 'Россия'}
          value={dataForRequest.district}
          onChange={e => setFields({ district: e.target.value })}
          options={optionAreaDistrictFilter(3, dataForRequest.country)}
          title="Область"
          textOpacity={50}
          placeholder="Выбор из списка"
          placeholderOpacity
          disabled={
            !dataForRequest.country ||
            (dataForRequest.country === 'Россия' && !dataForRequest.region)
          }
        />
      </div>
      <Textarea
        textareaHight={px(90)}
        placeholder="Введите текст"
        title="Место проведения"
        style={{
          height: px(130),
          marginBottom: px(14),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.location}
        onChange={location => setFields({ location: capitalizeFirstLetter(location) })}
      />
      <div className="flex flex-col" style={{ gap: px(10) }}>
        {geo?.map((item, index) => (
          <StyledInput
            // eslint-disable-next-line react/no-array-index-key
            key={`geo-${index}`}
            isBorder
            id={12}
            marginBottom={px(10)}
            value={geo[index].coordinates}
            onChange={e => {
              const text = e.target.value;
              setGeo(prevGeo => [
                ...prevGeo.slice(0, index),
                { ...prevGeo[index], coordinates: text },
                ...prevGeo.slice(index + 1),
              ]);
            }}
            title="Геолокация. Места игр"
            textOpacity={50}
            placeholder="Прикрепить"
            placeholderOpacity
            bluePlaceholder
          />
        ))}
      </div>
      <p
        aria-hidden
        onClick={addGeo}
        style={{
          marginTop: px(40),
          marginBottom: px(18),
          fontSize: px(20),
          lineHeight: px(26),
          fontWeight: 400,
          paddingLeft: px(18),
          color: '#0A84FE',
        }}
      >
        Добавить Геолокацию
      </p>
      <div>
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            opacity: 0.5,
            fontWeight: 400,
            paddingLeft: px(18),
          }}
        >
          Период проведения
        </p>
        <PeriodSetter start={start} end={end} onChangeStart={setStart} onChangeEnd={setEnd} />
      </div>

      <Textarea
        placeholder="Введите текст"
        title="Организаторы"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.organizers}
        onChange={organizers => setFields({ organizers: capitalizeFirstLetter(organizers) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Количество участников"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.participantsNumber}
        onChange={participantsNumber =>
          setFields({ participantsNumber: capitalizeFirstLetter(participantsNumber) })
        }
      />
      <Textarea
        placeholder="Введите текст"
        title="Знания языков"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.languageKnowledge}
        onChange={languageKnowledge =>
          setFields({ languageKnowledge: capitalizeFirstLetter(languageKnowledge) })
        }
      />
      <Textarea
        placeholder="Введите текст"
        title="Спортивные достижения"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.sportsAchivments}
        onChange={sportsAchivments =>
          setFields({ sportsAchivments: capitalizeFirstLetter(sportsAchivments) })
        }
      />
      <div>
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            opacity: 0.5,
            fontWeight: 400,
            paddingLeft: px(18),
            marginBottom: px(17),
          }}
        >
          Стоимость. Расценки
        </p>
        <div className="flex items-center justify-between" style={{ marginBottom: px(17) }}>
          <span style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>1 час</span>
          <span
            aria-hidden
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              fontWeight: 400,
              color: dataForRequest.pricePerHour === 0 ? 'black' : '#0A84FE',
            }}
            onClick={() => showCost(true)}
          >
            {dataForRequest.pricePerHour}
          </span>
          <PickerInput
            singleItem
            title="Стоимость. Цена"
            show={cost}
            onClose={() => showCost(false)}
            onChange={pricePerHour => setFields({ pricePerHour: +pricePerHour })}
            value={dataForRequest.pricePerHour}
            button
            isLine
          />
        </div>
      </div>
      <Textarea
        placeholder="Введите текст"
        title="Расценки"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.prices}
        onChange={prices => setFields({ prices: capitalizeFirstLetter(prices) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Обучение"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.teaching}
        onChange={teaching => setFields({ teaching: capitalizeFirstLetter(teaching) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Лодка"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.boat}
        onChange={boat => setFields({ boat: capitalizeFirstLetter(boat) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Оборудование"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.equipment}
        onChange={equipment => setFields({ equipment: capitalizeFirstLetter(equipment) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Размещение"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.accomodation}
        onChange={accomodation => setFields({ accomodation: capitalizeFirstLetter(accomodation) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Оплата участия"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.payment}
        onChange={payment => setFields({ payment: capitalizeFirstLetter(payment) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Программа"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.program}
        onChange={program => setFields({ program: capitalizeFirstLetter(program) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Условия и правила"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(40),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.terms}
        onChange={terms => setFields({ terms: capitalizeFirstLetter(terms) })}
      />
      <div style={{ marginBottom: px(16) }}>
        <p style={{ fontSize: px(32), lineHeight: px(43) }}>Фильтры</p>
        <p style={{ fontSize: px(15), lineHeight: px(20) }}>Характеристики</p>
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Вид турнира"
          items={[
            { label: 'Коммерческий', value: 'Коммерческий' },
            { label: 'Рейтинговый', value: 'Рейтинговый' },
            { label: 'Любительский', value: 'Любительский' },
            { label: 'Профессиональный', value: 'Профессиональный' },
          ]}
          value={tournamentType}
          onChange={tournamentType => setFields({ tournamentType })}
        />
      </div>
      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Тип участников"
          items={[
            { label: 'Все желающие', value: 'Все желающие' },
            { label: 'Молодежный', value: 'Молодежный' },
            { label: 'Детский', value: 'Детский' },
            { label: 'Женский', value: 'Женский' },
            { label: 'Мужской', value: 'Мужской' },
          ]}
          value={participantsType}
          onChange={participantsType => setFields({ participantsType })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Зачет"
          items={[
            { label: 'Личный', value: 'Личный' },
            { label: 'Командный', value: 'Командный' },
            { label: 'Лично-командный', value: 'Лично-командный' },
          ]}
          value={standing}
          onChange={standing => setFields({ standing })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Дисциплина"
          items={[
            { label: 'Карпфишинг', value: 'Карпфишинг' },
            { label: 'Ловля форели', value: 'Ловля форели' },
            { label: 'Фидер. Донка', value: 'Фидер. Донка' },
          ]}
          value={discipline}
          onChange={discipline => setFields({ discipline })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Направление"
          items={[
            { label: 'Платная', value: 'Платная' },
            { label: 'Трофейная (от 20 кг )', value: 'Трофейная (от 20 кг )' },
            { label: 'Зимняя со льда', value: 'Зимняя со льда' },
          ]}
          value={direction}
          onChange={direction => setFields({ direction })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Виды рыб"
          items={[
            { label: 'Карп', value: 'Карп' },
            { label: 'Белый амур', value: 'Белый амур' },
            { label: 'Черный амур', value: 'Черный амур' },
          ]}
          value={fishType}
          onChange={fishType => setFields({ fishType })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Услуги"
          items={[
            { label: 'Проживание', value: 'Проживание' },
            { label: 'Обучение', value: 'Обучение' },
            { label: 'Машина', value: 'Машина' },
            { label: 'Оборудование', value: 'Оборудование' },
            { label: 'Аренда снастей', value: 'Аренда снастей' },
            { label: 'Что-то еще', value: 'Что-то еще' },
          ]}
          value={service}
          onChange={service => setFields({ service })}
        />
      </div>

      <Button
        style={{ width: '100%' }}
        fontSize={20}
        lineHeight={26}
        height={60}
        borderRadius={0}
        /* disabled={loading} */
        onClick={async () => {
          // eslint-disable-next-line unicorn/prefer-ternary
          if (defaultInformation) {
            await dispatch(
              updateGuideOffer({
                data: {
                  ...dataForRequest,
                  geolocation: geo,
                },
                id: defaultInformation.id,
              }),
            );
          } else {
            await dispatch(
              createGuideOffer({
                // eslint-disable-next-line unicorn/no-useless-spread
                ...{
                  ...dataForRequest,
                  geolocation: geo,
                },
                guideId: activeId,
              }),
            );
          }
          updateGuide();
          onClose();
        }}
      >
        Сохранить
      </Button>
      <ScrollButtonsContainer>
        <CircleButton
          onClick={() => {
            setFields({
              location: '',
              participantsNumber: '',
              languageKnowledge: '',
              boat: '',
              equipment: '',
              program: '',
              teaching: '',
              prices: '',
              organizers: '',
              sportsAchivments: '',
              payment: '',
              terms: '',
              accomodation: '',
            });
          }}
          icon={{
            type: TypesIcon.ROUNDEDRETURN,
            viewBox: '0 0 28.451 26.164',
            width: px(28.451),
            height: px(26.164),
          }}
        />
      </ScrollButtonsContainer>
    </div>
  );
};

export default InformationForm;
