import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addModal } from '../../modal/addModal';

export const ADD_EVENT = 'event/addEvent';
const onSuccess = () => async (dispatch) => {
  dispatch(
    addModal({
      id: 13,
      data: {
        title: 'Кабинет создан',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'profile',
    }),
  );
};

export const addEvent = createAsyncThunk(
  ADD_EVENT,
  createRequest({
    type: ADD_EVENT,
    loader: (data) => authApi.addEvent(data),
    onSuccess,
  }),
);
