export const data = [
  {
    id: 0,
    title: 'Цена',
    type: 'price',
  },
  {
    id: 1,
    title: 'Скидка',
    type: 'discount',
  },
  {
    id: 2,
    title: 'Акция',
    type: 'action',
  },
];
