import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';

export const PROFILE_AVATAR = 'profile/profileAvatar';

export const profileAvatar = createAsyncThunk(
    PROFILE_AVATAR,
    createRequest({
        type: PROFILE_AVATAR,
        loader: (data) => authApi.profileAvatar(data),
    }),
);