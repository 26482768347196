import { request } from '@/helpers/api/request';

export const updateContacts = async (data) => {
  const response = await request({
    url: `event-contact/${data.id}`,
    method: 'PUT',
    data:data.contactsData
  });
  return response;
};
