import React from 'react';

export const Input = ({ onChange, className, style, ...properties }) => (
  <input
    {...properties}
    className={`input ${className}`}
    readOnly={!!properties.readOnly}
    onChange={onChange}
    style={{
      ...style,
    }}
  />
);

export default Input;
