import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/location';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_LOCATIONS = 'location/getLocations';

export const getLocations = createAsyncThunk(
  GET_LOCATIONS,
  createRequest({
    type: GET_LOCATIONS,
    loader: data => authApi.getLocations(data),
  }),
);
