/* eslint-disable import/no-named-as-default */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { addAttachment, updateAttachment } from '../../actions/attachment';
import { getContacts } from '../../actions/contacts';
import { getEvent } from '../../actions/event';
import { StyledAttachment, StyledGallery, StyledSchema } from '../../components';
import DraggableModal from '../../components/draggable-modal';
import ObjectDescriptionComponent from '../../components/object-description-component';
import { StateContext } from '../../components/state-provider';
import { authActiveIdSelector } from '../../selectors/auth';
import { contactsSelector } from '../../selectors/contacts';
import { eventObjectSelector } from '../../selectors/get-event';
import { Modal } from '../../new-architecture/shared';

export const ObjectDescriptionPage = () => {
  const dispatch = useDispatch();
  const data = useSelector(eventObjectSelector);
  const contacts = useSelector(contactsSelector);
  const [index, setIndex] = useState(0);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [schemaIndex, setSchemaIndex] = useState(0);
  const { show, handleClose } = useContext(StateContext);
  const [styledComponent, setStyledComponent] = useState('Attachment');
  const [addSlide, setAddSlide] = useState(false);

  const { pathname } = useLocation();
  const id = useSelector(authActiveIdSelector);
  useEffect(() => {
    dispatch(getEvent({ id }));
  }, [id]);

  useEffect(() => {
    dispatch(getContacts({ id }));
  }, [id]);

  const [scrollValue, setScrollValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  function scrollHandler() {
    if (window.scrollY > scrollValue && scrollValue > 500) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setScrollValue(window.scrollY);
  }

  const hadleSave = (imageId, croppedImg, type) => {
    if (imageId) {
      const formDateFile = new FormData();
      formDateFile.append('file', croppedImg);
      dispatch(updateAttachment({ data: formDateFile, id: imageId }));
      handleClose();
    } else {
      const formDateFile = new FormData();
      formDateFile.append('file', croppedImg);
      formDateFile.append('eventId', data?.id);
      formDateFile.append('type', type);
      dispatch(addAttachment(formDateFile));
      handleClose();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  window?.addEventListener('scroll', scrollHandler);

  return (
    <div className="global-container ">
      <main>
        <Modal isOpen={show} onClose={handleClose}>
          {styledComponent === 'Attachment' && (
            <StyledAttachment index={index} data={data} setIndex={setIndex} />
          )}
          {styledComponent === 'Gallery' && (
            <StyledGallery
              index={galleryIndex}
              data={data}
              setIndex={setGalleryIndex}
              setAddSlide={setAddSlide}
              addSlide={addSlide}
            />
          )}
        </Modal>
        {data && contacts && (
          <ObjectDescriptionComponent
            index={index}
            data={data}
            setIndex={setIndex}
            galleryIndex={galleryIndex}
            setGalleryIndex={setGalleryIndex}
            schemaIndex={schemaIndex}
            setSchemaIndex={setSchemaIndex}
            contacts={contacts}
            isVisible={isVisible}
            styledComponent={styledComponent}
            setStyledComponent={setStyledComponent}
            setAddSlide={setAddSlide}
          />
        )}
      </main>
    </div>
  );
};
