import { request } from '@/helpers/api/request';

export const addAttachment = async (data) => {
  const response = await request({
    url: `event-attachment`,
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    method: 'POST',
    data,
  });

  return response;
};
