import * as authApi from '@/api/attachment';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const ADD_ATTACHMENT = 'attachment/addAttachment';


export const addAttachment = createAsyncThunk(
  ADD_ATTACHMENT,
  createRequest({
    type: ADD_ATTACHMENT,
    loader: (data) => authApi.addAttachment(data),
 
  }),
);
