import { request } from '../../helpers/api/request';

export const getNotification = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `notification`,
    method: 'GET',
    data,
  });
  return response;
};
