import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/auth';

export const LOGIN_CHALLENGE = 'auth/loginChallenge';

export const loginChallenge = createAsyncThunk(
    LOGIN_CHALLENGE,
    createRequest({
        type: LOGIN_CHALLENGE,
        loader: (data) => authApi.loginChallenge(data),
    }),
);