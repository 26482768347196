import React from 'react';

export default function SearchSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.357"
      height="21.358"
      viewBox="0 0 21.357 21.358"
      className="search-svg"
    >
      <g id="search-interface-symbol_2_" transform="translate(-4 2)">
        <g id="_x34__4_">
          <g id="Сгруппировать_36" data-name="Сгруппировать 36">
            <path
              id="Контур_27"
              d="M21.161,20.2,15.647,14.77a8.722,8.722,0,0,0,2.331-5.927A8.916,8.916,0,0,0,8.992,0,8.915,8.915,0,0,0,.006,8.843a8.915,8.915,0,0,0,8.986,8.843,9.037,9.037,0,0,0,5.656-1.975l5.536,5.447a.7.7,0,0,0,.977,0A.673.673,0,0,0,21.161,20.2ZM8.992,16.325a7.543,7.543,0,0,1-7.6-7.482,7.543,7.543,0,0,1,7.6-7.482,7.543,7.543,0,0,1,7.6,7.482A7.543,7.543,0,0,1,8.992,16.325Z"
              transform="translate(3.994 -2)"
              fill="#ffffff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
