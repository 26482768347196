import React from 'react';

export const SpinningIcon = props => (
  <svg
    viewBox="0 0 30 31"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M4.07471 23.0596L0.79618 26.3381C-0.265393 27.4003 -0.265393 29.1276 0.79618 30.1898C1.85836 31.252 3.58629 31.2513 4.64787 30.1898L6.61971 28.2179C6.37882 28.0382 6.14884 27.8384 5.93217 27.6218C4.67577 26.3653 4.05656 24.71 4.07471 23.0596Z"
        fill="currentColor"
      />
      <path
        d="M7.21467 19.9191C5.45467 21.6791 5.43349 24.5576 7.21467 26.3387C8.98437 28.1084 11.8641 28.1084 13.6338 26.3387C15.4034 24.569 15.4034 21.6893 13.6338 19.9191C11.8598 18.1452 8.98014 18.1536 7.21467 19.9191ZM10.4243 24.4129L9.14059 23.1292L10.4243 21.8449L11.708 23.1292L10.4243 24.4129Z"
        fill="currentColor"
      />
      <path
        d="M29.0507 21.0015C29.0507 19.8141 28.2886 18.8086 27.235 18.4344V2.46813L28.4194 1.28369L27.1358 0L23.2654 3.87039L21.9815 2.58651L20.6976 3.87039L21.9815 5.15426L18.1298 9.00595L16.8459 7.72207L15.5621 9.00595L16.8459 10.2898L12.9942 14.1415L11.7104 12.8576L10.4265 14.1415L11.7104 15.4254L10.3594 16.7764C10.3824 16.7758 10.4048 16.7758 10.4278 16.7758C11.1662 16.7758 11.8858 16.9011 12.5618 17.1413L25.4193 4.28382V18.4344C24.3656 18.8087 23.6036 19.8146 23.6036 21.0015C23.6036 22.1888 24.3657 23.1944 25.4193 23.5686V27.3564C25.4193 28.3576 24.6048 29.1721 23.6036 29.1721C22.6024 29.1721 21.7879 28.3576 21.7879 27.3564V26.3292L23.1001 27.204L24.1072 25.6932L19.9723 22.9365V27.3564C19.9723 29.3588 21.6013 30.9878 23.6037 30.9878C25.606 30.9878 27.2351 29.3588 27.2351 27.3564V23.5686C28.2888 23.1943 29.0507 22.1884 29.0507 21.0015Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
