import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/mail';
import { createRequest } from '@/helpers/api/createRequest';

import { addModal } from '../../modal/addModal';

export const ADD_LEGAL_INFORMATION = 'mail/addLegalInformation';
const onSuccess = () => async dispatch => {
  dispatch(
    addModal({
      id: 20,
      data: {
        title: 'Ваши данные успешно отправлены',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'profile',
    }),
  );
};

export const addLegalInformation = createAsyncThunk(
  ADD_LEGAL_INFORMATION,
  createRequest({
    type: ADD_LEGAL_INFORMATION,
    loader: data => authApi.addLegalInformation(data),
    onSuccess,
  }),
);
