import { addLeadingZeros } from '../../../../../../constants/Function';
import { INITIAL_PROFILE_FIELDS } from '../constants/profile.constants';

export const mapProfileToFields = profile => ({
  name: profile?.name ?? INITIAL_PROFILE_FIELDS?.name,
  surname: profile?.surname ?? INITIAL_PROFILE_FIELDS?.surname,
  phone: profile?.phone ?? INITIAL_PROFILE_FIELDS?.phone,
  email: profile?.email ?? INITIAL_PROFILE_FIELDS?.email,
  country: profile?.country ?? INITIAL_PROFILE_FIELDS?.country,
  town: profile?.town ?? INITIAL_PROFILE_FIELDS?.town,
  passportDetails: {
    passportNumber:
      profile?.passportDetails?.passportNumber ??
      INITIAL_PROFILE_FIELDS?.passportDetails?.passportNumber,
    issuedBy:
      profile?.passportDetails?.issuedBy ?? INITIAL_PROFILE_FIELDS?.passportDetails?.issuedBy,
    issueDate:
      profile?.passportDetails?.issueDate ?? INITIAL_PROFILE_FIELDS?.passportDetails?.issueDate,
    divisionCode:
      profile?.passportDetails?.divisionCode ??
      INITIAL_PROFILE_FIELDS?.passportDetails?.divisionCode,
    image:
      (profile?.passportDetails?.image?.url
        ? `${process.env.REACT_APP_STATIC_URL}${profile?.passportDetails?.image?.url}`
        : undefined) ?? INITIAL_PROFILE_FIELDS?.passportDetails?.image,
  },
  documents:
    profile?.documents?.images
      ?.slice?.()
      ?.sort?.((a, b) => a.priority - b.priority)
      ?.map(({ url }, index) => ({
        src: url ? `${process.env.REACT_APP_STATIC_URL}${url}` : undefined,
        first: index === 0,
      })) ?? INITIAL_PROFILE_FIELDS?.documents,
  departures:
    profile?.departure?.images
      ?.slice?.()
      ?.sort?.((a, b) => a.priority - b.priority)
      ?.map(({ url }, index) => ({
        src: url ? `${process.env.REACT_APP_STATIC_URL}${url}` : undefined,
        first: index === 0,
      })) ?? INITIAL_PROFILE_FIELDS?.departures,
  recommends:
    profile?.recommends?.map(rec => addLeadingZeros(rec?.recommendedId)) ??
    INITIAL_PROFILE_FIELDS?.recommends,
  sportAchivments: {
    team: profile?.sportAchivments?.team ?? INITIAL_PROFILE_FIELDS?.sportAchivments?.team,
    club: profile?.sportAchivments?.club ?? INITIAL_PROFILE_FIELDS?.sportAchivments?.club,
    image:
      (profile?.sportAchivments?.image?.url
        ? `${process.env.REACT_APP_STATIC_URL}${profile?.sportAchivments?.image?.url}`
        : undefined) ?? INITIAL_PROFILE_FIELDS?.sportAchivments?.image,
  },
  socials: {
    telegramLink: profile?.socials?.telegramLink ?? INITIAL_PROFILE_FIELDS?.socials?.telegramLink,
    telegramLinkVisible:
      profile?.socials?.telegramLinkVisible ?? INITIAL_PROFILE_FIELDS?.socials?.telegramLinkVisible,
    viberLink: profile?.socials?.viberLink ?? INITIAL_PROFILE_FIELDS?.socials?.viberLink,
    viberLinkVisible:
      profile?.socials?.viberLinkVisible ?? INITIAL_PROFILE_FIELDS?.socials?.viberLinkVisible,
    whatsupLink: profile?.socials?.whatsupLink ?? INITIAL_PROFILE_FIELDS?.socials?.whatsupLink,
    whatsupLinkVisible:
      profile?.socials?.whatsupLinkVisible ?? INITIAL_PROFILE_FIELDS?.socials?.whatsupLinkVisible,
  },
});
