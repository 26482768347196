/* eslint-disable sonarjs/no-nested-template-literals */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HomeAddPopup } from '../../components/home-add-popup';
import { Icon } from '../../components/Icon';
import { formatDate } from '../../constants/Function';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { TypesIcon } from '../../types/TypesIcon';
import { months } from '../BlockTheAccountPage';

export const HolidayPage = () => {
  const { pathname, state } = useLocation();

  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const linkToMenu =
    state.link ||
    (activeId === 100_000_000 || activeId === undefined
      ? '/profile'
      : activeType === 'object' || activeType === 'administeredObject'
        ? '/user-object'
        : activeType === 'competition' || activeType === 'administeredCompetition'
          ? '/user-competition'
          : '/user-guide');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const renderTextContent = () => {
    if (!state?.blockMessage) return '';

    return (
      <div className="flex items-center justify-center">
        <div style={{ width: px(172) }}>
          <p
            style={{ fontSize: px(20), lineHeight: px(26), wordBreak: 'break-word' }}
            className="line-clamp-3 text-center font-normal opacity-50"
          >
            {state?.blockMessage || ''}
          </p>
        </div>
      </div>
    );
  };

  const renderEndTimeContent = () => {
    if (!state?.endDate?.slice(11, 16)) return '';

    const formattedTime = `${state?.endDate?.slice(11, 16)}`;

    const formattedData = `${formatDate({
      day: `${new Date(state?.endDate)}`.slice(16, 21),
      month: state?.endDate?.slice(5, 7),
      year: state?.endDate?.slice(0, 4),
    })}`;

    return (
      <div className="card__popup">
        <Icon viewBox="0 0 21 28" width={px(21)} height={px(28)} type={TypesIcon.OPENLOCK} />
        <p style={{ fontSize: px(20), lineHeight: px(26) }} className="font-normal opacity-50">
          {state.prevPage
            ? `${formattedTime} ${state?.endDate?.slice(8, 10)}.${state?.endDate?.slice(
                5,
                7,
              )}.${state?.endDate?.slice(0, 4)}`
            : `${`${new Date(state?.endDate)}`.slice(16, 21)} ${`${new Date(state?.endDate)}`.slice(
                8,
                10,
              )}.${months[`${new Date(state?.endDate)}`.slice(4, 7)]}.${`${new Date(
                state?.endDate,
              )}`.slice(11, 15)}`}
        </p>
      </div>
    );
  };

  return (
    <div className="global-container">
      <main>
        <HomeAddPopup path={linkToMenu}>
          {renderTextContent()}
          {renderEndTimeContent()}
        </HomeAddPopup>
      </main>
    </div>
  );
};

export default HolidayPage;
