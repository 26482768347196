import { addModal, removeModal } from '@/actions/modal';
import { createReducer } from '@reduxjs/toolkit';



export const modals = createReducer({}, builder => {
    builder.addCase(addModal, (state, { payload }) => ({
        ...state,
        [payload.id]: { ...payload },
    }));
    builder.addCase(removeModal, () => ({ }));
});
