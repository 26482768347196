import { request } from '../../helpers/api/request';

export const loginChallenge = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `auth/login/challenge`,
    method: 'POST',
    headers: {
      'content-type': 'application/cbor',
      Accept: 'application/cbor',
    },
    responseType: 'arraybuffer',
    data,
  });
  return response;
};
