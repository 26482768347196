import React, { useEffect, useMemo, useState } from 'react';
import Picker from 'react-mobile-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { findCard } from '../../actions/card';
import { cardHolderAccept, cardHolderInvite } from '../../actions/card-holder/thunks';
import { getEvent } from '../../api/event';
import {
  AddLoyalCard,
  CustomAlert,
  DatePicker,
  Main,
  ObjectDescriptionTextarea,
  Switch,
} from '../../components';
import { PeriodBlock } from '../../components/period-block';
import { selectionsDate } from '../../constants/Component';
import { periodData } from '../../constants/Component/date-picker';
import {
  addYearsToDate,
  createObjectDram,
  formatMonth,
  formatYear,
  toISO8601,
} from '../../constants/Function';
import { px } from '../../constants/Px';
import { Button, CardInformation } from '../../new-architecture/shared';
import { capitalizeFirstLetter } from '../../new-architecture/utils';
import { authActiveIdSelector } from '../../selectors/auth';
import { cardSelector } from '../../selectors/card';

export const PresentCardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const card = useSelector(cardSelector);
  const date = new Date();
  const currentMonth = date.getMonth();
  const [showPeriodEndPicker, setShowPeriodEndPicker] = useState(false);
  const [toggle, setToggle] = useState(true);
  const currentYear = useMemo(
    () =>
      card?.activeDate === '1 год'
        ? date.getFullYear() + 1
        : card?.activeDate === '2 года'
          ? date.getFullYear() + 2
          : date.getFullYear() + 5,
    [card],
  );
  const [values, setValues] = useState({
    profileId: `ID ${state?.profileId || ''}`,
    comment: '',
    endTime: `${date?.getHours()}:00` || '1:00',
    day: `${date.getDate()}` || '1',
    month: selectionsDate?.month[currentMonth] || 'Январь',
    year: '',
  });
  useEffect(() => {
    setValues(prevValues => ({ ...prevValues, year: currentYear }));
  }, [card, currentYear]);

  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(false);
  const [invalidId, setInvalidId] = useState(false);
  const [showAlertInvalidId, setShowAlertInvalidId] = useState(false);
  const onChangeValue = value => {
    setValues({
      ...values,
      day: value?.day,
      month: value?.month,
      year: value?.year,
      endTime: value?.endTime,
    });
  };
  const onCLickButtonSubmit = async () => {
    try {
      setDisabledButtonSubmit(true);
      const jsonData = {
        status: state?.presentCardFrom === 'notifications' ? 'ACTIVE' : 'INVITED',
        message: values?.comment,
        cardId: Number(state?.cardId),
        openUntill: toISO8601(
          { month: values?.month, day: values?.day, year: values?.year },
          { hour: values?.endTime },
        ),
      };
      const profileId =
        state?.presentCardFrom === 'notifications'
          ? state?.profileId
          : Number.parseInt(values?.profileId.replaceAll(/\D/g, ''), 10);
      const { payload } = await dispatch(cardHolderAccept({ profileId, data: jsonData }));
      if (
        payload?.statusCode === 200 ||
        payload?.status === 200 ||
        payload?.statusCode === 409 ||
        payload?.status === 409
      ) {
        setDisabledButtonSubmit(false);
        if (state?.presentCardFrom === 'notifications') {
          navigate('/notifications');
        } else {
          navigate('/card-types');
        }
      } else if (
        payload?.statusCode === 404 ||
        payload?.status === 404 ||
        payload?.statusCode === 400 ||
        payload?.status === 400
      ) {
        setInvalidId(true);
        setShowAlertInvalidId(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setDisabledButtonSubmit(false);
    }
  };
  useEffect(() => {
    if (state?.cardId) {
      dispatch(findCard({ cardId: state?.cardId }));
    }
  }, [state?.cardId]);
  const activeId = useSelector(authActiveIdSelector);
  const [event, setEvent] = useState([]);
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEvent({ id: activeId });
        if (eventData?.data) {
          setEvent(eventData.data);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    fetchEvent();
  }, [activeId]);
  return (
    <Main title="Вручить карту" light center>
      <p
        style={{
          fontSize: px(15),
          lineHeight: px(20),
        }}
        className="whitespace-pre-wrap text-center font-normal"
      >
        {`Номер карты и QR код\nсгенерируются автоматически`}
      </p>
      <DatePicker
        isLine
        singleItem
        title="Конец периода"
        show={showPeriodEndPicker}
        setShow={setShowPeriodEndPicker}
        data={createObjectDram(
          ['month', 'day', 'year'],
          periodData?.month,
          periodData?.day,
          periodData?.year,
        )}
        setPickerValue={onChangeValue}
        pickerValue={values}
        buttonTitle="Применить"
      />
      <AddLoyalCard
        cardData={{
          cardId: card?.id,
          cardName: card?.name,
          cardDiscount: `${card?.cardDiscount}%`,
          cardLogo: {
            imageSrc: event?.EventAttachment?.filter(attachment => attachment.type === 'LOGO')[0]
              ?.imageUrl,
            showLogo: card?.displayLogo,
          },
          cardBackground: card?.image?.url,
          cardActiveDate: addYearsToDate(card?.activeDate),
          cardProfileName: state?.profileName || 'Имя владельца',
        }}
      />
      <CardInformation
        data={[
          {
            id: 1,
            title: { label: 'Имя Владельца' },
            value: (
              <div>
                <input
                  type="text"
                  name="my-input"
                  className={`font-normal ${invalidId ? 'text-[#fa0202]' : ''}`}
                  style={{
                    height: px(36),
                    width: px(120),
                    fontSize: px(20),
                    lineHeight: px(26),
                    textAlign: 'right',
                    border: 'none',
                    appearance: 'none',
                    outline: 'none',
                  }}
                  maxLength="10"
                  value={values.profileId}
                  onChange={e => {
                    setValues(prevValues => ({
                      ...prevValues,
                      profileId: `ID ${e.target.value.replaceAll(/\D/g, '')}`,
                    }));
                    setInvalidId(false);
                    setDisabledButtonSubmit(false);
                  }}
                />
              </div>
            ),
            disabled: state?.profileId,
          },
          {
            id: 2,
            title: { label: 'Вид карты. Название' },
            value: card?.name,
          },
          {
            id: 3,
            title: { label: 'Статус карты' },
            value: `${card?.cardDiscount}%`,
          },
        ]}
        clickable
      />
      <CardInformation
        data={[
          {
            id: 4,
            title: { label: 'Допуск' },
            text: (
              <div>
                <p
                  style={{
                    fontSize: px(32),
                    lineHeight: px(32),
                    paddingBottom: px(10),
                  }}
                  className="whitespace-pre-wrap font-light"
                >
                  {`Открыть\nдля бронирования`}
                </p>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                  className="font-normal"
                >
                  Меняйте период, в зависимости от задач, условий, погоды и сезона:
                </p>
                <div
                  style={{
                    paddingTop: px(20),
                    paddingBottom: px(15),
                  }}
                  className="flex w-full items-center justify-between"
                >
                  <div>
                    <p
                      className="font-normal"
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                      }}
                    >
                      Открыть
                    </p>
                    <p
                      className="font-normal"
                      style={{
                        fontSize: px(15),
                        lineHeight: px(20),
                      }}
                    >
                      Указать период
                    </p>
                  </div>
                  <Switch toggle={toggle} setToggle={setToggle} />
                </div>
                {toggle && (
                  <PeriodBlock
                    date={{
                      day: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
                      time: `${date?.getHours()}:00`,
                      month: formatMonth(selectionsDate?.month[currentMonth]),
                      year: formatYear(date.getFullYear().toString()),
                    }}
                    activeDate={card?.activeDate}
                    show={() => {
                      if (card?.activeDate === 'Точная дата') {
                        setShowPeriodEndPicker(true);
                      }
                    }}
                    pickerDate={values}
                  />
                )}
              </div>
            ),
          },
          {
            id: 5,
            title: { label: 'Комментарий' },
            text: (
              <ObjectDescriptionTextarea
                objectStyles={{
                  height: 140,
                  placeholder: 'Вставьте текст или напишите…',
                  paddingTop: 4,
                  paddingLeft: 18,
                  paddingRight: 18,
                  textareaHeight: 132,
                  textareaFontSize: 18,
                  textareaLineHeight: 24,
                }}
                objectKey="comment"
                value={values?.comment}
                setValue={(_, comment) => {
                  setValues(prevValues => ({
                    ...prevValues,
                    comment: capitalizeFirstLetter(comment),
                  }));
                }}
              />
            ),
          },
        ]}
        dropDown
      />
      <Button
        className="w-full border-[1px] border-solid border-[#dddddd]"
        marginTop={20}
        height={60}
        borderRadius={0}
        onClick={onCLickButtonSubmit}
        disabled={disabledButtonSubmit}
      >
        Вручить карту
      </Button>
      {showAlertInvalidId && (
        <CustomAlert
          onClickButtonSubmit={() => {
            setShowAlertInvalidId(false);
          }}
          title="Введен некорректный ID Кабинета"
          single
          buttonRightText="Ок"
        />
      )}
    </Main>
  );
};
