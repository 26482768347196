import { request } from '../../helpers/api/request';

export const addNotification = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `notification/create-test`,
    method: 'POST',
    data,
  });

  return response;
};
