import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { DraggableModal } from './components/draggable-modal';

export const Modal = ({
  show,
  onClose,
  noDraggable,
  children,
  title,
  isLine,
  customHeader,
  customHeaderProps,
  additionalModal,
}) => {
  const modalRoot = document.querySelector('#modal');

  return modalRoot
    ? noDraggable
      ? ReactDOM.createPortal(
          <AnimatePresence>{show && <div>{children}</div>}</AnimatePresence>,
          modalRoot,
        )
      : ReactDOM.createPortal(
          <AnimatePresence>
            {show && (
              <DraggableModal
                additionalModal={additionalModal}
                onClose={onClose}
                title={title}
                isLine={isLine}
                customHeader={customHeader}
                customHeaderProps={customHeaderProps}
              >
                {children}
              </DraggableModal>
            )}
          </AnimatePresence>,
          modalRoot,
        )
    : undefined;
};
