import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProfile } from '../../actions/profile';
import { Header, Menu } from '../../components';
import { StateContext } from '../../components/state-provider';
import {
  menuCompetitionItems,
  menuGuideItems,
  menuObjectItems,
  menuProfileItems,
} from '../../constants/Component/menu';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { profileSelector } from '../../selectors/profile';

export const UserAccount = () => {
  const { showSearch, setShowSearch } = useContext(StateContext);
  const dispatch = useDispatch();

  const profile = useSelector(profileSelector);
  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);

  const data =
    activeId === 100_000_000 || activeId === undefined
      ? menuProfileItems
      : activeType === 'object' || activeType === 'administeredObject'
        ? menuObjectItems
        : activeType === 'competition' || activeType === 'administeredCompetition'
          ? menuCompetitionItems
          : menuGuideItems;

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="global-container">
      <main>
        <div className="bg-[white]">
          <Header fixed profile={profile} setShowSearch={setShowSearch} showSearch={showSearch} />
          <div
            style={{
              marginTop: px(60),
            }}
          >
            <Menu activeId={activeId === 100_000_000 || activeId === undefined} data={data} />
          </div>
        </div>
      </main>
    </div>
  );
};
