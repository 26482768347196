import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { signUp, signUpCodeRefresh, verify } from '../../actions/auth';
import { Button, Input, Main } from '../../components';
import { Checkbox } from '../../components/checkbox';
import { PhoneInput } from '../../components/phoneInput';
import { RulesAndConditionsModal } from '../../components/rules-and-conditions-modal';
import { regexPhone } from '../../constants/Component';
import { px } from '../../constants/Px';

export const RegistrationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [phone, setPhone] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isCode, setIsCode] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [time, setTime] = useState(0);
  const [rulesAndConditionsOpen, setRulesAndConditionsOpen] = useState(false);
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const handleChangePhone = event => {
    const input = event.target.value.replaceAll(/\D/g, '');
    setIsValidPhone(regexPhone.test(input));

    setPhone(event.target.value);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit = async event => {
    event.preventDefault();
    try {
      if (isCode) {
        const { payload } = await dispatch(
          verify({ phone: `+${phone.replaceAll(/\D/g, '')}`, code }),
        );
      } else {
        const { payload } = await dispatch(
          signUp({ phone: `+${phone.replaceAll(/\D/g, '')}`, name }),
        );
        if (payload?.data?.verificationCode) {
          alert(`Ваш код ${payload?.data?.verificationCode}`);
        }
        if (payload?.statusCode === 403) {
          const response = await dispatch(
            signUpCodeRefresh({ phone: `+${phone.replaceAll(/\D/g, '')}` }),
          );
          if (response?.payload?.status === 201) {
            setIsCode(true);
          }
          if (payload?.data?.verificationCode) {
            alert(`Ваш код ${response?.payload?.data?.verificationCode}`);
          }
        } else if (payload?.status === 201) {
          setIsCode(true);
        }
      }
    } catch {
      console.log('error signUp');
    }
  };

  const handleReSend = async event => {
    event.preventDefault();
    try {
      const { payload } = await dispatch(
        signUp({ phone: `+${phone.replaceAll(/\D/g, '')}`, name }),
      );
      if (payload?.data?.verificationCode) {
        alert(`Ваш код ${payload?.data?.verificationCode}`);
      }
      if (payload?.statusCode === 403) {
        const response = await dispatch(
          signUpCodeRefresh({ phone: `+${phone.replaceAll(/\D/g, '')}` }),
        );
        if (response?.payload?.data?.verificationCode) {
          alert(`Ваш код ${response?.payload?.data?.verificationCode}`);
        }
      }
    } catch {
      console.log('error signUp');
    }
  };

  const formattedSeconds = seconds > 10 ? seconds : `0${seconds}`;

  const handleChangeCode = event => {
    const newValue = event.target.value.replaceAll(/\D/g, ''); // удаляем все, кроме цифр
    if (newValue.length <= 4) {
      setCode(newValue);
    }
  };

  const navigateSms = () => {
    navigate('/sms');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(previousTime => previousTime - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (search === '?rulesAndConditionsOpen=true') {
      setRulesAndConditionsOpen(true);
    }
  }, [pathname, search]);

  useEffect(() => {
    if (showAlert) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }

    return () => {
      document.documentElement.classList.remove('lock');
    };
  }, [showAlert]);

  return (
    <>
      <Main light registration title="Регистрация" navigatePatch="/">
        <form onSubmit={onSubmit}>
          {!isCode && (
            <div className="reg">
              <Input
                placeholder="Введите имя"
                onChange={event => setName(event.target.value)}
                value={name}
              />
            </div>
          )}
          <PhoneInput
            error={!isValidPhone}
            setPhone={setPhone}
            handleChangePhone={event => handleChangePhone(event)}
            phone={phone}
            disabled={isCode}
          />
          {isCode && (
            <div className="number">
              <Input
                style={{
                  borderRadius: px(0),
                  margin: `${px(0)} ${px(0)} ${px(10)} ${px(0)}`,
                }}
                placeholder="Ввести код…"
                onChange={handleChangeCode}
                value={code}
                type="text"
                name="token"
                inputmode="numeric"
                pattern="\d{4}"
                autocomplete="one-time-code"
                required
              />
            </div>
          )}
          {!isCode && (
            <div
              style={{
                marginBottom: px(24),
                marginTop: px(25),
              }}
            >
              <Checkbox
                onChange={event => setIsChecked(event.target.checked)}
                checked={isChecked}
                isChecked={isChecked}
                label="Согласие  на обработку данных"
                onClick={() => {
                  setRulesAndConditionsOpen(true);
                }}
              />
            </div>
          )}
          <Button
            type="submit"
            disabled={
              isCode
                ? code?.length < 4
                : !phone || !name || !isValidPhone || !isChecked || !!time || phone.length < 5
            }
            className="w-full border-[1px] border-solid border-[#dddddd]"
            height={60}
            borderRadius={0}
            marginBottom={89}
          >
            Зарегистрироваться
          </Button>
          {isCode &&
            (time > 0 ? (
              <div className="item-center flex justify-between">
                <p
                  style={{
                    fontSize: `${px(15)}`,
                    lineHeight: `${px(20)}`,
                  }}
                >{` ${minutes}:${formattedSeconds}`}</p>
                <div
                  className="font-normal text-[#ff0a0a]"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                  onClick={navigateSms}
                  onKeyDown={navigateSms}
                >
                  Не приходит SMS
                </div>
              </div>
            ) : (
              <div className="flex justify-between">
                <div
                  className="cursor-pointer font-normal text-[#0a84fe]"
                  style={{
                    fontSize: `${px(15)}`,
                    lineHeight: `${px(20)}`,
                  }}
                  onClick={event => {
                    handleReSend(event);
                    setTime(120);
                  }}
                  onKeyDown={event => {
                    handleReSend(event);
                    setTime(120);
                  }}
                >
                  Отправить повторно
                </div>
                <div
                  className="font-normal  text-[#ff0a0a]"
                  style={{
                    fontSize: `${px(15)}`,
                    lineHeight: `${px(20)}`,
                  }}
                  onClick={navigateSms}
                  onKeyDown={navigateSms}
                >
                  Не приходит SMS
                </div>
              </div>
            ))}
        </form>
      </Main>
      {rulesAndConditionsOpen && (
        <RulesAndConditionsModal
          isOpen={rulesAndConditionsOpen}
          onClose={() => {
            setRulesAndConditionsOpen(false);
          }}
        />
      )}
    </>
  );
};
