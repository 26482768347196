import { createSelector } from '@reduxjs/toolkit';

export const selectRoot = ({ eventObject }) => eventObject;
export const selectMainRoot = ({ event }) => event;
export const eventObjectSelector = createSelector(selectRoot, eventObject => eventObject?.object);

export const imagesObjectSelector = createSelector(selectRoot, eventObject => eventObject?.images);
export const logoObjectSelector = createSelector(selectMainRoot, event => event?.logoImageUrl);
export const nameObjectSelector = createSelector(selectMainRoot, event => event?.name);
export const galleryObjectSelector = createSelector(
  selectRoot,
  eventObject => eventObject?.gallery,
);
export const schemaObjectSelector = createSelector(selectRoot, eventObject =>
  eventObject?.schema?.slice()?.sort((a, b) => a.priority - b.priority),
);
export const emptyIndexObjectSelector = createSelector(
  selectRoot,
  eventObject => eventObject?.emptyIndex,
);
export const newsObjectSelector = createSelector(selectRoot, eventObject => eventObject?.news);
