import { request } from '@/helpers/api/request';

export const acceptAdmins = async ({ link }) => {
  const response = await request({
    url: `${link}`,
    method: 'GET',
  });

  return response;
};
