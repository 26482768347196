import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Main, Switch } from '../../components';
import { px } from '../../constants/Px';

export const CashPage = () => {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Main title='Кэширование данных' light fontSize={32} lineHeight={32}>
      <div style={{
        marginTop:px(12)
      }}>
        <p
          className='font-normal'
          style={{ fontSize: px(15), lineHeight: px(20)}}
        >
          Кэширование ускоряет работу приложения, оно позволяет быстрее отображать ранее загруженные данные, не дожидаясь их повторного получения с сервера
        </p>

        <div className='flex flex-row justify-between items-center' style={{ marginTop:px(52)}}>
            <p className='font-normal' style={{ fontSize: px(20), lineHeight: px(26) }}>
              Кэширование данных
            </p>
          <Switch toggle={toggle} setToggle={setToggle} />
        </div>
      </div>
    </Main>
  );
};
