/* eslint-disable import/no-unresolved */
import 'swiper/scss';

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const SingleSlider = ({
  tempImg,
  index,
  images,
  handleShow,
  setIndex,
  setInputsData,
  news,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const sliderReference = useRef(null);

  const [ind, setInd] = useState(index);

  const handleNavigateShema = () => {
    navigate(`/schemas`);
  };

  useEffect(() => {
    setInd(index);
  }, [index]);

  useEffect(() => {
    setInd(index);
  }, [tempImg]);

  useEffect(() => {
    setInd(index);
  }, [images]);

  if (ind === null) return <div />;

  return (
    <Swiper
      spaceBetween={0}
      modules={[Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation={{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }}
      initialSlide={ind}
      className="home_page-hero"
      ref={sliderReference}
      onRealIndexChange={element => {
        if (setIndex) setIndex(element.activeIndex);
      }}
    >
      {images?.map((img, index) => (
        <SwiperSlide key={img?.id}>
          {handleShow ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0A84FE',
                }}
              />
              <CircleButton
                onClick={() => {
                  if (setInputsData) {
                    if (news)
                      setInputsData({
                        title: '',
                        subtitle: '',
                        body: '',
                        startsAt: '',
                        endsAt: '',
                      });
                    else setInputsData({ title: '' });
                  }
                }}
                icon={{
                  type: TypesIcon.ROUNDEDRETURN,
                  viewBox: '0 0 28.451 26.164',
                  width: px(28.451),
                  height: px(26.164),
                }}
              />
              {img.type !== 'blueBg' && (
                <img
                  src={img.src}
                  className="slider__image"
                  style={{
                    width: news ? px(320) : px(141),
                    height: news ? px(304) : px(216),
                    borderTopLeftRadius: px(0),
                    borderTopRightRadius: px(0),
                  }}
                  alt={`slider-${index}`}
                />
              )}
            </div>
          ) : (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290) }}
            >
              <CircleButton
                onClick={handleNavigateShema}
                icon={{
                  type: TypesIcon.ADDPLUS,
                  viewBox: '0 0 26.95 26.95',
                  width: px(26.95),
                  height: px(26.95),
                  fill: '#0A84FE',
                }}
              />
              {img.type !== 'blueBg' && (
                <img
                  src={img.src}
                  className="slider__image"
                  style={{
                    width: news ? px(320) : px(141),
                    height: news ? px(304) : px(216),
                    borderTopLeftRadius: px(0),
                    borderTopRightRadius: px(0),
                  }}
                  alt={`slider-${index}`}
                />
              )}
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SingleSlider;
