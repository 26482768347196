/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_GUIDE_OFFER = 'guide/deleteGuideOffer';

export const deleteGuideOffer = createAsyncThunk(
  DELETE_GUIDE_OFFER,
  createRequest({
    type: DELETE_GUIDE_OFFER,
    loader: data => authApi.deleteGuideOffer(data),
  }),
);
