import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { TypesIcon } from '@/types/TypesIcon';

import { Button } from '../../components';
import { Icon } from '../../components/Icon';
import { getMinutesString } from '../../constants/Function';
import { BackButton } from '../../new-architecture/shared';

export const BlockedPage = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const handleNavigate = () => {};

  const incomingDate = new Date(state?.time);

  const now = new Date();

  const diffInMinutes = Math.floor((incomingDate - now) / (1000 * 60));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const px = size =>
    size < 0
      ? `calc(min(calc(${size} * -1 * 0.3125vw), calc(${size} * -1 * 2.4px)) * -1)`
      : `min(calc(${size} * 0.3125vw), calc(${size} * 2.4px))`;

  return (
    <main>
      <div className="global-container">
        <div className="flex-column flex h-auto min-h-screen">
          <BackButton onClick={() => navigate('/login')} />
          <div
            className="flex justify-center"
            style={{
              padding: `${px(10)} 0 ${px(11.7)} 0`,
            }}
          >
            <Icon
              viewBox="0 0 59.3 59.3"
              width={px(59.3)}
              height={px(59.3)}
              type={TypesIcon.REDCLOSE}
            />
          </div>
          <div style={{ paddingLeft: px(15), paddingRight: px(15) }}>
            <p
              style={{
                fontSize: px(32),
                lineHeight: px(32),
                marginBottom: px(10),
              }}
              className="whitespace-pre-wrap text-center  font-light text-[#000000]"
            >
              {`Вход временно\nзаблокирован`}
            </p>
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
              className="whitespace-pre-wrap text-center font-normal text-[#000000]"
            >
              {`Вы 3 раза подряд неверно ввели код доступа. Попробуйте еще раз\nчерез ${
                diffInMinutes > 0 ? diffInMinutes : 0
              } ${getMinutesString(diffInMinutes)}`}
            </p>

            <Button
              style={{
                padding: `${px(17)} ${px(12)} ${px(17)} ${px(13)}`,
              }}
              type="submit"
              className="w-full border-[1px] border-solid border-[#dddddd]"
              disabled={false}
              onClick={handleNavigate}
              borderRadius={0}
              marginTop={20}
              height={60}
            >
              Ок
            </Button>
          </div>
        </div>
      </div>
    </main>
  );
};
