/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../../../components/Icon';
import { addLeadingZeros } from '../../../constants/Function';
// TODO: this page needs a complete refactor
import { TypesIcon } from '../../../types/TypesIcon'; // TODO: To new arch
import { CircleButton, Modal } from '../../shared';
import { px } from '../../utils';
import { useProfile } from '../hooks';
import { Access, AddLocation, FishingMenu, Price } from './components';

export const OffersPage = () => {
  const navigate = useNavigate();
  const [showAddLocaionModal, setShowAddLocationModal] = useState(false);
  const [showFishingMenuModal, setShowFishingMenuModal] = useState(false);
  const [showAccessMenuModal, setShowAccessMenuModal] = useState(false);
  const [showPriceMenuModal, setShowPriceMenuModal] = useState(false);

  const {
    data: { object },
    updateFunction: updateProfile,
  } = useProfile();

  return (
    <>
      <img
        src="/offer-menu.png"
        alt="cover"
        style={{
          position: 'absolute',
          top: px(60),
          minHeight: `calc(100% - ${px(60)})`,
          width: px(320),
          bottom: 0,
          objectFit: 'cover',
        }}
      />
      <div className="home-add-popup-container" style={{ height: px(60) }}>
        <div className="home-add-popup-container-overflow" style={{ height: px(60) }}>
          <div className="home-add-popup-content">
            <p className="absolute w-full text-center" style={{ top: px(21), lineHeight: px(27) }}>
              Предложения
            </p>
            <div
              className="search-menu-item-preferences-close"
              onClick={() => navigate('/user-object')}
              aria-hidden
            >
              <Icon type={TypesIcon.CLOSE} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
            </div>
          </div>
        </div>
      </div>
      <section
        style={{
          position: 'relative',
          zIndex: 5,
          minWidth: px(320),
          height: px(488),
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: px(86),
            left: px(147),
            zIndex: 6,
          }}
        >
          <Icon type={TypesIcon.OFFERMENU} width={px(27)} height={px(30)} viewBox="0 0 27 30" />
        </div>
        <div
          style={{
            position: 'absolute',
            width: px(270),
            height: px(270),
            left: px(25),
            top: px(154),
            zIndex: 6,
          }}
        >
          <img
            src="/offer-menu-border.png"
            alt="border"
            style={{
              width: px(270),
              height: px(270),
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            width: px(146),
            top: px(193),
            left: px(88),
            zIndex: 6,
          }}
        >
          <p
            className="line-clamp-1 text-ellipsis break-words text-center align-middle font-normal text-white"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            {object?.name}
          </p>
          <p
            className="text-center text-white"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            ID: {addLeadingZeros(object?.id)}
          </p>
        </div>
        <div
          className="isolate overflow-hidden"
          style={{
            position: 'absolute',
            top: px(249),
            left: px(120),
            zIndex: 6,
            borderRadius: px(9999),
          }}
        >
          {object?.EventAttachment?.find?.(e => e?.type === 'LOGO')?.imageUrl ? (
            <img
              src={`${process.env.REACT_APP_STATIC_URL}${
                object?.EventAttachment?.find?.(e => e?.type === 'LOGO')?.imageUrl
              }`}
              alt="map"
              style={{ width: px(80), height: px(80) }}
            />
          ) : (
            <div
              style={{ height: px(80), width: px(80) }}
              className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50 bg-white"
            >
              <p className="font-light text-black" style={{ fontSize: px(60), lineHeight: px(43) }}>
                {object?.name?.charAt(0)?.toUpperCase()}
              </p>
            </div>
          )}
        </div>
        <CircleButton
          className="!absolute"
          style={{ top: px(297), left: px(55), zIndex: 6 }}
          icon={{
            type: TypesIcon.ADDPLUS,
            viewBox: '0 0 26.95 26.95',
            width: px(26.95),
            height: px(26.95),
          }}
          onClick={() => setShowAddLocationModal(true)}
        />

        <CircleButton
          className="!absolute"
          style={{ top: px(346), left: px(102), zIndex: 6 }}
          onClick={() => navigate('/offers/edit')}
          icon={{
            type: TypesIcon.PENSIL,
            viewBox: '0 0 18.51 23.873',
            width: px(18.51),
            height: px(23.873),
            fill: '#0A84FE',
          }}
        />
        <CircleButton
          className="!absolute"
          style={{ top: px(346), left: px(169), zIndex: 6 }}
          onClick={() => setShowAccessMenuModal(true)}
          icon={{
            type: TypesIcon.ACCESS,
            viewBox: '0 0 24.703 26.238',
            width: px(24.703),
            height: px(26.238),
            fill: '#0A84FE',
          }}
        />
        <CircleButton
          className="!absolute"
          style={{ top: px(297), left: px(216), zIndex: 6 }}
          noFill
          icon={{
            type: TypesIcon.PRICE,
            viewBox: '0 0 24 27',
            width: px(24),
            height: px(27),
            fill: 'white',
            stroke: '#0A84FE',
          }}
          onClick={() => setShowPriceMenuModal(true)}
        />
      </section>

      <Modal show={showAddLocaionModal} onClose={() => setShowAddLocationModal(false)}>
        <AddLocation
          onClose={() => {
            setShowFishingMenuModal(true);
            setShowAddLocationModal(false);
          }}
        />
      </Modal>

      <Modal
        show={showFishingMenuModal}
        onClose={() => setShowFishingMenuModal(false)}
        additionalModal
      >
        <FishingMenu />
      </Modal>

      <Modal
        show={showAccessMenuModal}
        onClose={() => {
          updateProfile();
          setShowAccessMenuModal(false);
        }}
      >
        <Access />
      </Modal>

      <Modal
        show={showPriceMenuModal}
        onClose={() => {
          updateProfile();
          setShowPriceMenuModal(false);
        }}
      >
        <Price />
      </Modal>
    </>
  );
};

export default OffersPage;
