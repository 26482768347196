import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/subscription';

export const GET_SINGLE_SUBSCRIPTIONS = 'subscription/getSingleSubscription';


export const getSingleSubscription = createAsyncThunk(
  GET_SINGLE_SUBSCRIPTIONS,
    createRequest({
        type: GET_SINGLE_SUBSCRIPTIONS,
        loader: (data) => authApi.getSingleSubscription(data),
    }),
);
