import { removeModal } from '@/actions/modal';
import { useAppDispatch } from '@/store';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectModals } from '@/selectors/modals';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { useOutSideClick } from '@/hooks/useOutSideClick';
import { px } from '../../constants/Px';
import Button from '../button';
import { useNavigate } from 'react-router-dom';

export const Modal = ({ id, onClose, data, path, pathSubmit }) => {
  const modals = useSelector(selectModals);
  const navigate = useNavigate()


  const ref = useRef(null);

  const dispatch = useAppDispatch();

  useLockBodyScroll();

  const handleOutSideClick = useCallback(
    (event) => {
      if (!ref.current?.contains(event.target)) {
        if (onClose) {
          onClose();
        }
        dispatch(removeModal(id));
      }
    },
    [dispatch, modals, onClose],
  );

  useOutSideClick(ref, handleOutSideClick);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    dispatch(removeModal(id));
    if(path){
      navigate(`/${path}`)
    }
 
  }, [dispatch, id, onClose]);


  const onSubmit = useCallback(() => {
    if (onClose) {
      onClose();
    }

    dispatch(removeModal(id));
    if(pathSubmit){
      navigate(`/${pathSubmit}`)
    }
 
  }, [dispatch, id, onClose]);

  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-[100] `}
      onClick={data?.onClickButtonClose}
      style={{
        background: 'rgba(0, 0, 0, 0.7)',
      }}
      ref={ref}
    >
      <div
        className='bg-[white] grid grid-rows-[3fr_1fr] '
        style={{
          width: px(290),
          height: px(184),
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className=' flex justify-center items-stretch flex-col ' style={{
          height:px(134),
          padding: `${px(0)} ${px(10)} `,
          width: px(290),
        }}>
        {data?.title && (
          <p
            className=' text-center font-[400] break-words  overflow-auto'
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            {data?.title}
          </p>
        )}
        </div>
        

        <div className='flex flex-row '>
          {data?.buttonLeftText && (
            <Button
              bgColor={'#fff'}
              className={`w-full border-solid border-[#dddddd] border-[1px]  flex items-center justify-center cursor-pointer`}
              color={data?.single ? '#0A84FE' : '#000'}
              style={{ padding: ` 0 ${px(15)}` }}
              borderRadius={0}
              onClick={handleClose}
            >
              {data?.buttonLeftText}
            </Button>
          )}
          {data?.buttonRightText && (
            <Button
              bgColor={'#fff'}
              color={data?.single ? '#0A84FE' : '#000'}
              className={`w-full border-solid border-[#dddddd] border-[1px]  flex items-center justify-center cursor-pointer`}
              style={{ padding: ` 0 ${px(15)}` }}
              borderRadius={0}
              onClick={onSubmit}
            >
              {data?.buttonRightText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
