import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../actions/profile';

import { profileSelector } from '../../selectors/profile';
import SettingProfileComponent from '../../components/setting-profile-component';

export const SettingsProfilePage = () => {
  const dispatch = useDispatch();

  const profile = useSelector(profileSelector);

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  return <>{profile && <SettingProfileComponent profile={profile} />}</>;
};
