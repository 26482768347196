import React, { useState } from 'react';
import { distributionPageShortTitle, timeDate } from '../constants/Component';
import { px } from '../constants/Px';
import Switch from './switch';
import { Main } from './main';
import DatePicker from './date-picker';
import DistributionPageDatePickers from './distribution-page-datePickers';

export const DistributionPageComponent = ({
  title,
  dataPickerTitle,
  dataPickerData,
  setPickerValue,
  setTime,
  pickerValue,
  time,
  subtitle,
  toggle,
  setToggle,
  toggleNo,
  setToggleNo,
  period,
}) => {
  const [dataPickerId, setDataPickerId] = useState(0);
  const [show, setShow] = useState(false);
  const distributionPageDatePickersData = [
    { id: 0, title: 'Стартовое время', data: time.hour },
    { id: 1, title: 'Период', data: distributionPageShortTitle[period] || period },
  ];
  const distributionPageToggleData = [
    { id: 1, label: 'Отправлять', state: toggle, setState: setToggle },
    { id: 2, label: 'Не отправлять если нет', state: toggleNo, setState: setToggleNo },
  ];
  return (
    <Main title={title} fontSize={32} lineHeight={32} light navigatePatch={'/notification'}>
      <DatePicker
        singleItem
        title={dataPickerId === 0 ? 'Время' : dataPickerTitle}
        setShow={setShow}
        show={show}
        data={dataPickerId === 0 ? timeDate : dataPickerData}
        setPickerValue={dataPickerId === 0 ? setTime : setPickerValue}
        pickerValue={dataPickerId === 0 ? time : pickerValue}
        buttonTitle="Применить"
        isLine
      />

      <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
        {subtitle}
      </p>
      <div className="flex-column flex" style={{ marginTop: px(36), gap: px(39) }}>
        {distributionPageToggleData?.map(elem => (
          <div className="flex flex-row items-center justify-between" key={elem.id}>
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              {elem.label}
            </p>
            <Switch toggle={elem.state} setToggle={elem.setState} />
          </div>
        ))}
      </div>

      <div className="flex flex-row items-center justify-between" style={{ marginTop: px(22) }}>
        {distributionPageDatePickersData?.map(item => (
          <DistributionPageDatePickers
            key={item.id}
            id={item.id}
            data={item.data}
            setDataPickerId={setDataPickerId}
            setShow={setShow}
            title={item.title}
          />
        ))}
      </div>
    </Main>
  );
};

export default DistributionPageComponent;
