import { request } from '../../helpers/api/request';

export const deleteSection = async sectionId => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `section/${sectionId}`,
    method: 'DELETE',
  });

  return response;
};
