import React, { useState } from 'react';

import { assistantData } from '../../../../../../../../../constants/Component/date-picker';
import { Button, Picker } from '../../../../../../../../shared';
import { px } from '../../../../../../../../utils';

export const Propagate = ({ onSubmit }) => {
  const [from, setFrom] = useState('1');
  const [to, setTo] = useState('1');
  const [showFromPicker, setShowFromPicker] = useState(false);
  const [showToPicker, setShowToPicker] = useState(false);

  return (
    <>
      <p className="font-light" style={{ fontSize: px(32), lineHeight: px(43) }}>
        Размножить
      </p>
      <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
        Создайте сразу несколько похожих объектов для экономии времени если у вас таких большинство.
        Затем выберите непохожие и внесите индивидуальные изменения в фильтре
      </p>

      <div style={{ marginTop: px(20) }}>
        <p
          className="font-normal opacity-50"
          style={{ fontSize: px(20), lineHeight: px(26), marginBottom: px(15) }}
        >
          Указать интервал
        </p>
        <div className="flex flex-row" style={{ gap: px(77) }}>
          <div>
            <p
              className="font-normal opacity-50"
              style={{ fontSize: px(15), lineHeight: px(20), paddingBottom: px(13) }}
            >
              Начало
              <br />
              От номера
            </p>
            <p
              aria-hidden
              onClick={() => setShowFromPicker(true)}
              className="font-normal"
              style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
            >
              {from}
            </p>
            <Picker
              singleItem
              title="Начало от номера"
              show={showFromPicker}
              onClose={() => setShowFromPicker(false)}
              data={{
                intervalStartingFromNumber: assistantData?.assistant
                  .filter(item => Number(item) <= Number(to))
                  .map(mappedItem => mappedItem.toString()),
              }}
              onChange={({ intervalStartingFromNumber }) => setFrom(intervalStartingFromNumber)}
              value={{
                intervalStartingFromNumber: from,
              }}
              button
              isLine
            />
            <hr className="opacity-30" style={{ width: px(34) }} />
          </div>
          <div>
            <p
              className="font-normal opacity-50"
              style={{ fontSize: px(15), lineHeight: px(20), paddingBottom: px(13) }}
            >
              {' '}
              Конец
              <br />
              От номера
            </p>
            <p
              aria-hidden
              onClick={() => setShowToPicker(true)}
              className="font-normal"
              style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
            >
              {to}
            </p>
            <Picker
              singleItem
              title="Начало от номера"
              show={showToPicker}
              onClose={() => setShowToPicker(false)}
              data={{
                intervalEndsFromNumber: assistantData?.assistant
                  .filter(item => Number(item) >= Number(from))
                  .map(mappedItem => mappedItem.toString()),
              }}
              onChange={({ intervalEndsFromNumber }) => setTo(intervalEndsFromNumber)}
              value={{
                intervalEndsFromNumber: to,
              }}
              button
              isLine
            />
            <hr className="opacity-30" style={{ width: px(34) }} />
          </div>
        </div>
      </div>
      <div style={{ marginTop: px(20), marginBottom: px(20) }}>
        <div
          style={{ marginBottom: px(20) }}
          className="flex flex-row items-center justify-between"
        >
          <Button
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#000"
            bgColor="#fff"
            disabled={from === '1' && to === '1'}
            onClick={() => {
              setFrom('1');
              setTo('1');
            }}
          >
            Очистить
          </Button>
          <Button
            style={{ width: px(141) }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#fff"
            onClick={() => onSubmit(from, to)}
          >
            Применить
          </Button>
        </div>
      </div>
    </>
  );
};

export default Propagate;
