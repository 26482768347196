import React, { useEffect, useMemo, useState } from 'react';

import { deleteSection } from '../../../../../../../api/section';
import { Button } from '../../../../../../../components/button';
import {
  alphabet,
  assistantData,
  sectorData,
} from '../../../../../../../constants/Component/date-picker';
import { useDidMountEffect } from '../../../../../../hooks';
import { Picker, Popup } from '../../../../../../shared';
import { px } from '../../../../../../utils';
import { INITIAL_STATE } from '../../../../../constants';
import { useProfile } from '../../../../../hooks';
import {
  mapFieldsToPayload,
  mapSectorToFields,
  numberStringToArray,
} from '../../../../helpers/sector.helpers';
import { propagateSector, sectorRequest } from '../../../../services/sector.service';
import { Sector } from '../sector';
import { CreateSimilar, FishingFields, Propagate, RestFields, ServiceFields } from './components';

export const SectorForm = ({ sector: defaultSector, onClose }) => {
  const {
    data: { sectorFields, selectedLocationId, locations },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const setSectorFields = fields =>
    setProfile({
      sectorFields: {
        ...sectorFields,
        ...fields,
      },
    });

  const [showNumberPicker, setShowNumberPicker] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [creation, setCreation] = useState(!defaultSector);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const selectedLocation = useMemo(
    () =>
      locations?.find(location => location.id === selectedLocationId) ||
      locations?.find(location => location.type === 'REST'),
    [JSON.stringify(locations), selectedLocationId],
  );

  const { number, place, services, watersideObject } = sectorFields || {};
  const { type: locationCategory } = selectedLocation;

  useEffect(() => {
    setSectorFields(mapSectorToFields(defaultSector));
  }, [defaultSector]);

  useDidMountEffect(() => {
    setCreation(true);
    setSectorFields(INITIAL_STATE?.sectorFields);
  }, [selectedLocationId]);

  const numberArray = useMemo(() => numberStringToArray(number), [number]);

  const validate = () => {
    if (!number || number === '0') {
      setError('number');
      document.querySelector?.('#number')?.scrollIntoView?.({
        behavior: 'smooth',
      });
      return false;
    }

    if (locationCategory === 'FISHING' && !place?.length) {
      setError('place');
      document.querySelector?.('#place')?.scrollIntoView?.({
        behavior: 'smooth',
      });
      return false;
    }

    if (locationCategory === 'REST' && !watersideObject?.length) {
      setError('watersideObject');
      document.querySelector?.('#watersideObject')?.scrollIntoView?.({
        behavior: 'smooth',
      });
      return false;
    }

    if (locationCategory === 'SERVICE' && !services?.length) {
      setError('services');
      document.querySelector?.('#services')?.scrollIntoView?.({
        behavior: 'smooth',
      });
      return false;
    }

    return true;
  };

  return (
    <>
      <div
        className="flex justify-center"
        style={{
          marginTop: locationCategory === 'FISHING' ? px(11) : px(20),
          marginBottom: locationCategory === 'FISHING' ? px(20) : px(20),
        }}
      >
        <Sector sector={sectorFields} />
      </div>
      <p className="font-light" style={{ fontSize: px(32), lineHeight: px(43) }}>
        Фильтр
      </p>
      <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
        Создайте Условия и Описание
      </p>
      {locationCategory === 'FISHING' && (
        <div
          className="flex-column flex"
          style={{ marginTop: px(25), gap: px(15), marginBottom: px(20) }}
        >
          <div className="flex flex-row items-center justify-between">
            <p
              id="number"
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                color: error === 'number' ? 'red' : 'inherit',
                opacity: error === 'number' ? 1 : 0.5,
              }}
            >
              Сектор
            </p>
            {number !== INITIAL_STATE?.sectorFields?.number && (
              <p
                aria-hidden
                onClick={() => setSectorFields({ number: INITIAL_STATE?.sectorFields?.number })}
                className="cursor-pointer font-normal text-[#0A84FE]"
                style={{ fontSize: px(15), lineHeight: px(20) }}
              >
                Сбросить
              </p>
            )}
          </div>
          <div className="flex flex-row items-center">
            <p
              className="font-normal"
              style={{ fontSize: px(20), lineHeight: px(26), marginRight: px(64) }}
            >
              Номер
            </p>
            <p
              aria-hidden
              onClick={() => {
                setShowNumberPicker(true);
              }}
              className="flex cursor-pointer justify-center font-light text-[#0A84FE]"
              style={{ minWidth: px(44), fontSize: px(20), lineHeight: px(26) }}
            >
              {number || 0}
            </p>
            <Picker
              singleItem
              title="Номер сектора"
              show={showNumberPicker}
              onClose={() => setShowNumberPicker(false)}
              data={{
                sectorShoreSide: sectorData?.sectorSide,
                sectorShoreNumber: assistantData?.assistant,
                sectorShoreLetter: alphabet?.letters,
              }}
              onChange={v =>
                setSectorFields({
                  number: [v.sectorShoreSide, v.sectorShoreNumber, v.sectorShoreLetter]
                    ?.filter(item => item !== '-')
                    ?.join(''),
                })
              }
              value={{
                sectorShoreSide: numberArray?.[0],
                sectorShoreNumber: numberArray?.[1],
                sectorShoreLetter: numberArray?.[2],
              }}
              button
              isLine
            />
          </div>
        </div>
      )}
      <div style={{ paddingBottom: locationCategory !== 'SERVICE' && px(20) }}>
        {locationCategory === 'FISHING' && <FishingFields type={locationCategory} error={error} />}
        {locationCategory === 'REST' && <RestFields type={locationCategory} error={error} />}
        {locationCategory === 'SERVICE' && <ServiceFields type={locationCategory} error={error} />}
      </div>
      {locationCategory === 'FISHING' && (
        <>
          <CreateSimilar onSubmit={similar => setSectorFields(mapSectorToFields(similar))} />
          <Propagate
            onSubmit={(from, to) =>
              mapFieldsToPayload(sectorFields).then(sector =>
                propagateSector(sector, selectedLocation, from, to)?.then(() =>
                  updateProfile()?.then(onClose),
                ),
              )
            }
          />
        </>
      )}

      <div style={{ marginTop: px(20), marginBottom: px(20) }}>
        <Button
          style={{ width: '100%' }}
          fontSize={20}
          lineHeight={26}
          height={60}
          borderRadius={0}
          disabled={loading}
          // onClick={sectorId ? updateData : sendData}
          onClick={() => {
            if (!validate()) return;
            setLoading(true);
            sectorRequest(
              sectorFields,
              creation ? selectedLocation : undefined,
              creation ? undefined : defaultSector?.id,
            )
              ?.then(() => updateProfile()?.then(onClose))
              .finally(() => setLoading(false));
          }}
        >
          Сохранить
        </Button>
        <p
          aria-hidden
          className="w-full text-center font-normal text-[#FA0303]"
          style={{ fontSize: px(15), lineHeight: px(20), marginTop: px(20) }}
          onClick={() => setShowDeleteModal(true)}
        >
          Удалить
        </p>
      </div>
      {showDeleteModal && (
        <Popup
          onClose={() => setShowDeleteModal(false)}
          onSubmit={() =>
            creation
              ? onClose()
              : deleteSection(defaultSector?.id)?.then(() => updateProfile().then(onClose))
          }
          data={{
            title: 'Вы действительно хотите удалить',
            buttonLeftText: 'Нет',
            buttonRightText: 'Да',
          }}
        />
      )}
    </>
  );
};

export default SectorForm;
