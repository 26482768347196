import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/react';

import { getCards, sendCards } from '../../actions/card';
import { getEvent } from '../../api/event';
import { FooterSlider, SearchCardModal } from '../../components';
import { LoyalCard } from '../../components/loyal-card';
import { LoyaltyCard } from '../../components/loyalty-card';
import { standartDate } from '../../constants/Function';
import { px } from '../../constants/Px';
import { sortOptionsCards } from '../../new-architecture/constants/sort-options';
import { BackButton, CircleButton, ScrollButtonsContainer } from '../../new-architecture/shared';
import { SortModal } from '../../new-architecture/shared/modal/components';
import { ClubCardViewModal } from '../../new-architecture/shared/modal/components/club-card-view-modal/club-card-view-modal';
import { format2DigitDate } from '../../new-architecture/utils';
import { authActiveIdSelector } from '../../selectors/auth';
import { cardsSelector } from '../../selectors/card';
import { TypesIcon } from '../../types/TypesIcon';

export const ClubCardsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeId = useSelector(authActiveIdSelector);
  const cards = useSelector(cardsSelector);
  const [showAlert, setShowAlert] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [sortOption, setSortOption] = useState(sortOptionsCards[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showSearchCardModal, setShowSearchCardModal] = useState(false);
  const [event, setEvent] = useState([]);
  const [showCardTypeView, setShowCardTypeView] = useState(false);
  const [activeCardView, setActiveCardView] = useState();
  const [cardHolderId, setCardHolderId] = useState();
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEvent({ id: activeId });
        if (eventData?.data) {
          setEvent(eventData.data);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    fetchEvent();
  }, [activeId, cardHolderId]);

  const sortedCards = useMemo(
    () => (cards ? cards.filter(card => card.deletedAt === null)?.sort(sortOption.cb) : []),
    [cards, sortOption, cardHolderId],
  );
  const sortedClubCardHolders = useMemo(() => {
    if (sortOption.id === 5) {
      return sortedCards[activeIndex]?.ClubCardHolders.filter(c => c?.blockedUntill !== null).sort(
        sortOption.cb,
      );
    }
    if (sortOption.id === 6) {
      return sortedCards[activeIndex]?.ClubCardHolders.filter(c => c?.deletedAt !== null).sort(
        sortOption.cb,
      );
    }
    if (sortOption.id === 7) {
      return sortedCards[activeIndex]?.ClubCardHolders.filter(c => c?.deletedAt !== null)
        .filter(c => new Date(c?.openUntill) < new Date())
        .sort(sortOption.cb);
    }
    return sortedCards[activeIndex]?.ClubCardHolders.filter(c => c.deletedAt === null) ?? [];
  }, [cards, activeIndex, sortOption, cardHolderId]);

  const slider = useMemo(
    () =>
      sortedCards?.map(card => ({
        data: {
          bgImg: card?.image?.url,
          cardType: card?.name,
          cardDiscount: card?.cardDiscount,
          displayLogo: card?.displayLogo,
          logoSrc: card?.event?.EventAttachment?.find(attachment => attachment.type === 'LOGO')
            ?.imageUrl,
          openUntill: format2DigitDate(card?.openUntill),
        },
      })),
    [sortedCards, cardHolderId],
  );
  useEffect(() => {
    if (activeId) {
      dispatch(getCards({ eventId: activeId }));
    }
  }, [activeId, cardHolderId]);

  return (
    <>
      <div className="global-container">
        <BackButton onClick={() => navigate(-1)} />
        <main>
          <div style={{ paddingBottom: px(80) }}>
            <p
              style={{
                fontSize: px(32),
                lineHeight: px(32),
                paddingTop: px(26),
                paddingBottom: px(5),
              }}
              className="text-center font-light"
            >
              Карты <br /> клубные
            </p>

            <p
              className="text-center font-normal"
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
            >
              Виртуальные карты лояльности для постоянных гостей.
            </p>
            {slider?.length > 0 ? (
              <div
                className="w-full"
                style={{
                  padding: `${px(16)} ${px(15)} ${px(0)} ${px(15)}`,
                }}
              >
                <FooterSlider
                  slidesPerView={2}
                  gap={20}
                  cards
                  pagination
                  onRealIndexChange={elem => setActiveIndex(elem)}
                  centeredSlides
                >
                  {slider?.map(item => (
                    <SwiperSlide key={item?.id} className="swiper-slide">
                      <div className="flex w-full justify-center ">
                        <LoyalCard
                          color="#fff"
                          data={{
                            bgImg: item?.data?.bgImg,
                            ownerName: 'Имя владельца',
                            cardType: item?.data?.cardType,
                            percent: item?.data?.cardDiscount,
                            openUntill: item?.data?.openUntill,
                            logo: {
                              logoSrc: item?.data?.logoSrc,
                              showLogo: item?.data?.displayLogo,
                            },
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </FooterSlider>
              </div>
            ) : (
              <div
                style={{
                  paddingTop: px(20),
                }}
              >
                <p
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                  className="text-center font-normal opacity-50"
                >
                  Карты не найдены
                </p>
              </div>
            )}

            <div
              style={{
                gap: px(10),
                marginTop: px(20),
                paddingRight: px(15),
                paddingLeft: px(15),
                paddingBottom: px(19),
              }}
              className="flex flex-col"
            >
              {sortedClubCardHolders.length > 0 ? (
                sortedClubCardHolders?.map(item => (
                  <LoyaltyCard
                    key={item?.id}
                    data={{
                      location:
                        item?.profile?.town || ('' && item?.profile?.country) || ''
                          ? `${item?.profile?.town || ''}, ${item?.profile?.country || ''}`
                          : `${item?.profile?.town || ''}${item?.profile?.country || ''}`,
                      profileId: item?.profileId?.toString(),
                      profileName: item?.profile?.name,
                      profileLogo: item?.profile?.avatar?.url,
                      createdAt: standartDate(item?.createdAt),
                      openUntill: standartDate(item?.openUntill),
                      cardNumber: item?.id?.toString(),
                      cardName: sortedCards?.[activeIndex]?.name,
                    }}
                    type="Classic"
                    onChangeCardId={() => {
                      setActiveCardView(sortedCards?.[activeIndex]);
                      setCardHolderId(item);
                      setShowCardTypeView(true);
                    }}
                  />
                ))
              ) : (
                <div>
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="text-center font-normal opacity-50"
                  >
                    Клиентов данной карты не найдено
                  </p>
                </div>
              )}
            </div>
            {/* {showAlert && (
            <CustomAlert
              onClickButtonSubmit={onClickButtonSubmit}
              onClickButtonClose={() => {
                setShowAlert(false);
              }}
              title="Отправить на почту список Клубных Карт"
              buttonLeftText="Нет"
              buttonRightText="Да"
            />
          )} */}
            <SearchCardModal
              title="Клубные карты"
              isOpen={showSearchCardModal}
              onClose={() => setShowSearchCardModal(false)}
              buttonTitle="Найти"
            />
            <ScrollButtonsContainer>
              <CircleButton
                onClick={() => setShowSortModal(true)}
                icon={{
                  type: TypesIcon.SORT,
                  viewBox: '0 0 25 23.607',
                  width: px(25),
                  height: px(23.607),
                }}
                className="text-[#0A84FE]"
              />
              {/* <CircleButton
                onClick={() => setShowAlert(true)}
                icon={{
                  type: TypesIcon.CARDBACK,
                  viewBox: '0 0 30.477 26.632',
                  width: px(30.477),
                  height: px(26.632),
                }}
                className="text-[#0A84FE]"
              /> */}
              <CircleButton
                onClick={() => setShowSearchCardModal(true)}
                icon={{
                  type: TypesIcon.SEARCH,
                  viewBox: '0 0 22.357 22.357',
                  width: px(22.357),
                  height: px(22.357),
                }}
                className="text-[#0A84FE]"
              />
            </ScrollButtonsContainer>
          </div>
        </main>
      </div>
      <SortModal
        closeButton
        title="Клубные карты"
        show={showSortModal}
        onClose={() => setShowSortModal(false)}
        options={sortOptionsCards}
        onChange={option => {
          setSortOption(option);
          setShowSortModal(false);
        }}
      />
      {showCardTypeView && (
        <ClubCardViewModal
          showCardTypeView={showCardTypeView}
          setShowCardTypeView={setShowCardTypeView}
          data={activeCardView}
          cardHolderId={cardHolderId}
          setCardHolderId={setCardHolderId}
        />
      )}
    </>
  );
};
