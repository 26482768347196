import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';

export const CARD_HOLDER_ACCEPT = 'card-holder/cardHolderAccept';

export const cardHolderAccept = createAsyncThunk(
  CARD_HOLDER_ACCEPT,
  createRequest({
    type: CARD_HOLDER_ACCEPT,
    loader: data => authApi.cardHolderAccept(data),
  }),
);
