import { request } from '@/helpers/api/request';

export const deleteEventAdmin = async ({ profileAdminId }) => {
  const response = await request({
    url: `event-admin/${profileAdminId}`,
    method: 'DELETE',
  });

  return response;
};
