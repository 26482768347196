import React from 'react';

import { TariffCard } from '../../../../../../components';
import { px } from '../../../../../utils';

const fillEmptyTariffCards = tariffCards => {
  const maxTariffCards = 3;
  const disabledCount = tariffCards.reduce(
    (count, tariff) => count + (tariff?.disabled ? 1 : 0),
    0,
  );
  if (tariffCards.length > 3 && disabledCount > 1) {
    const indexToRemove = tariffCards.findIndex(tariff => tariff?.disabled);
    tariffCards.splice(indexToRemove, 1);
  }
  if (tariffCards.length > 3 && disabledCount === 1) {
    return tariffCards.filter(tariff => tariff.disabled === false);
  }

  if (tariffCards.length === 0) {
    tariffCards.push(
      {
        cost: '0000',
        periodStart: '00',
        periodEnd: '00',
        priceType: 'ДЕНЬ',
        disabled: true,
      },
      {
        cost: '0000',
        periodStart: '00',
        periodEnd: '00',
        priceType: 'СУТКИ',
        disabled: true,
      },
      {
        cost: '0000',
        periodStart: '00',
        periodEnd: '00',
        priceType: 'НОЧЬ',
        disabled: true,
      },
    );
  }
  return tariffCards.slice(0, maxTariffCards);
};

export const ObjectOffersTariffCards = ({ tariffCards }) => {
  const filledTariffCards = fillEmptyTariffCards(tariffCards);

  return (
    <div className="flex items-center justify-center" style={{ gap: px(10), height: px(150) }}>
      {filledTariffCards.map(tariff => (
        <TariffCard
          key={tariff?.id}
          price={tariff.cost}
          time={tariff.periodStart}
          endTime={tariff.periodEnd}
          typeTime={tariff.priceType}
          disabled={tariff.disabled}
          bgClassName="transparent rounded-[15%] border-[1px] border-solid border-[#444444]"
          priceColor="#0A84FE"
        />
      ))}
    </div>
  );
};

export default ObjectOffersTariffCards;
