import React, { useEffect, useMemo, useState } from 'react';

import { TypesIcon } from '../../../types/TypesIcon';
import { px } from '../../utils';
import { Icon } from '../icon';

export const Dropdown = ({ items, value: defaultValue, light, onChange, style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const selectedItem = useMemo(
    () => items?.find(i => i?.value === selectedValue),
    [selectedValue, items],
  );

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue, items]);

  return (
    <div
      style={{
        paddingTop: style?.paddingTop || px(19),
      }}
    >
      <div
        className={`${
          light ? 'border-[#ffffff80]' : 'border-black'
        } flex flex-row items-center justify-between border-[1px] border-solid border-opacity-30`}
        onClick={() => setIsOpen(!isOpen)}
        aria-hidden
        style={{
          height: isOpen ? px(70) : px(60),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
      >
        <p
          // eslint-disable-next-line sonarjs/no-duplicate-string
          className={`font-normal ${light ? 'text-[#ffffff80]' : 'text-black'}`}
          style={{
            fontSize: px(20),
            lineHeight: px(26),
          }}
        >
          {selectedItem?.label}
        </p>
        {!isOpen && (
          <div className={`${light ? 'text-[#ffffff80]' : 'text-black text-opacity-50'}`}>
            <Icon width={px(8)} height={px(6)} viewBox="0 0 8 6" type={TypesIcon.ARROWDOWN} />
          </div>
        )}
      </div>

      {isOpen &&
        items
          ?.filter(i => i?.label !== selectedItem?.label)
          ?.map(item => (
            <div
              key={item?.label}
              aria-hidden
              className={`${
                light ? 'border-[#ffffff80]' : 'border-black'
              } flex flex-row items-center justify-start border-[1px] border-t-[0px] border-solid border-opacity-30`}
              onClick={() => {
                setSelectedValue(item?.value);
                if (onChange) onChange(item?.value);
                setIsOpen(false);
              }}
              style={{
                height: px(70),
                paddingLeft: px(18),
                paddingRight: px(18),
              }}
            >
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className={`font-normal ${light ? 'text-[#ffffff80]' : 'text-black'}`}
              >
                {item?.label}
              </p>
            </div>
          ))}
    </div>
  );
};

export default Dropdown;
