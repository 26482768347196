/* eslint-disable sonarjs/no-nested-template-literals */
import React, { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import PickerComponent from 'react-mobile-picker';

import { getData } from '../../../constants/Component';
import { saveEmptyFile } from '../../../constants/Function';
import { numberPx, px } from '../../utils';
import { Button } from '../button';
import { Modal } from '../modal';

export const Picker = ({
  value: defaultValue,
  onChange,
  data,
  button,
  singleItem,
  isDocumentType,
  period,
  id,
  onClick,
  changeDocumentType,
  onClose,
  type,
  group,
  dontSetDefaultValue,
  ...rest
}) => {
  const modifierData = type === 'date' ? getData(defaultValue) : data;
  const [fileType, setFileType] = useState('');

  const [value, setValue] = useState('');

  // TODO: value doesnt go into columns

  useLayoutEffect(() => {
    if (!dontSetDefaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange && group) onChange(value);
  }, [value]);

  const handleClick = async () => {
    if (onChange) await onChange(value);
    if (type === 'datePeriod') {
      if (id === 1) saveEmptyFile(fileType);
      onClick(value, true);

      if (id === 1) onClose();
    } else if (isDocumentType && value?.statistic === 'Выбрать период') {
      setFileType(value?.documentType);
    } else if (isDocumentType) {
      saveEmptyFile(value?.documentType);
      onClose();
    } else {
      if (onClick) onClick();
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} {...rest}>
      <div
        style={{
          padding: `${px(35)} ${px(15)} ${px(49)} ${px(15)}`,
        }}
      >
        <PickerComponent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          itemHeight={numberPx(43)}
          height={numberPx(216)}
          value={value}
          onChange={v => {
            setValue(v);
          }}
        >
          {Object?.keys(modifierData)?.map((name, index) => (
            <PickerComponent.Column
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:
                  singleItem || index === 1 ? 'center' : index === 0 ? 'start' : index === 2,
              }}
              key={name}
              name={name}
            >
              {modifierData?.[name]?.map(option => (
                <PickerComponent.Item
                  key={option}
                  value={option}
                  style={{
                    justifyContent: type === 'date' && index === 0 ? 'flex-start' : 'flex-end',
                    fontSize: px(32),
                    lineHeight: px(20),
                    height: px(43),
                    width: type === 'date' && index === 0 && px(140.08),
                  }}
                >
                  {({ selected }) => (
                    <div
                      style={{
                        color: '#000000',
                        fontSize: px(32),
                        lineHeight: px(20),
                        height: px(43),
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: type === 'date' && index === 0 && 'left',
                        opacity: selected ? '1' : '0.2',
                      }}
                    >
                      {option}
                    </div>
                  )}
                </PickerComponent.Item>
              ))}
            </PickerComponent.Column>
          ))}
        </PickerComponent>
        {isDocumentType && (
          <div
            style={{
              gap: px(10),
              marginTop: px(13),
            }}
            className="flex"
          >
            {['TXT (1C)', 'EXCEL', 'PDF'].map(item => (
              <div
                aria-hidden
                style={{
                  height: px(40),
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className={`flex border-[1px] border-solid ${
                  item === value?.documentType ? 'border-[#0A84FE]' : 'border-black'
                } w-full items-center justify-center border-opacity-30 font-normal`}
                onClick={() => {
                  changeDocumentType(item);
                }}
                key={item}
              >
                {item}
              </div>
            ))}
          </div>
        )}

        {type === 'datePeriod' && (
          <div
            style={{
              gap: px(10),
              marginTop: px(13),
            }}
            className="flex"
          >
            {Array?.from({ length: 2 }, (_, index) => (
              <div
                aria-hidden
                style={{
                  height: px(40),
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className={`flex border-[1px] border-solid ${
                  id === index ? 'border-[#0A84FE]' : 'border-black'
                } w-full items-center justify-center border-opacity-30 font-normal`}
                onClick={() => {
                  onClick(value);
                }}
                key={index}
              >
                {index === id
                  ? `${+value.day < 10 ? `0${value?.day}` : value?.day} ${value?.month
                      ?.slice(0, 3)
                      ?.toLowerCase()} ${value?.year}`
                  : index === 0
                    ? `${
                        +period.start.day < 10 ? `0${period?.start?.day}` : period?.start?.day
                      } ${period?.start?.month?.slice(0, 3)?.toLowerCase()} ${period?.start?.year}`
                    : `${
                        +period.end.day < 10
                          ? period?.end?.day && `0${period?.end?.day}`
                          : period?.end?.day
                      } ${period?.end?.month?.slice(0, 3)?.toLowerCase()} ${period?.end?.year}`}
              </div>
            ))}
          </div>
        )}

        {button && (
          <Button
            className="w-full border-[1px] border-solid border-[#dddddd]"
            marginTop={type === 'datePeriod' || isDocumentType ? 10 : 63}
            height={60}
            borderRadius={0}
            onClick={handleClick}
          >
            Применить
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Picker;
