import React from 'react';

import { px } from '../constants/Px';
import Button from './button';

export const PropagateFilter = ({ setDataPickerId, setShow, date, setDate }) => (
  <>
    <p className="font-light" style={{ fontSize: px(32), lineHeight: px(43) }}>
      Размножить
    </p>
    <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
      Создайте сразу несколько похожих объектов для экономии времени если у вас таких большинство.
      Затем выберите непохожие и внесите индивидуальные изменения в фильтре
    </p>

    <div style={{ marginTop: px(20) }}>
      <p
        className="font-normal opacity-50"
        style={{ fontSize: px(20), lineHeight: px(26), marginBottom: px(15) }}
      >
        Указать интервал
      </p>
      <div className="flex flex-row" style={{ gap: px(77) }}>
        <div>
          <p
            className="font-normal opacity-50"
            style={{ fontSize: px(15), lineHeight: px(20), paddingBottom: px(13) }}
          >
            Начало
            <br />
            От номера
          </p>
          <p
            onClick={() => {
              setDataPickerId(6);
              setShow(true);
            }}
            className="font-normal"
            style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
          >
            {date?.intervalStartingFromNumber}
          </p>
          <hr className="opacity-30" style={{ width: px(34) }} />
        </div>
        <div>
          <p
            className="font-normal opacity-50"
            style={{ fontSize: px(15), lineHeight: px(20), paddingBottom: px(13) }}
          >
            {' '}
            Конец
            <br />
            От номера
          </p>
          <p
            onClick={() => {
              setDataPickerId(7);
              setShow(true);
            }}
            className="font-normal"
            style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
          >
            {date?.intervalEndsFromNumber}
          </p>
          <hr className="opacity-30" style={{ width: px(34) }} />
        </div>
      </div>
    </div>
    <div style={{ marginTop: px(20), marginBottom: px(20) }}>
      <div style={{ marginBottom: px(20) }} className="flex flex-row items-center justify-between">
        <Button
          fontSize={20}
          lineHeight={26}
          height={60}
          borderRadius={0}
          color="#000"
          bgColor="#fff"
          disabled={
            date.intervalStartingFromNumber === '1' && date.intervalEndsFromNumber === '100'
          }
          onClick={() => {
            setDate({
              ...date,
              intervalStartingFromNumber: '1',
              intervalEndsFromNumber: '100',
            });
          }}
        >
          Очистить
        </Button>
        <Button
          style={{ width: px(141) }}
          fontSize={20}
          lineHeight={26}
          height={60}
          borderRadius={0}
          color="#fff"
          onClick={() => {
            setDate({
              ...date,
              intervalStartingFromNumber: date?.intervalStartingFromNumber,
              intervalEndsFromNumber: date?.intervalEndsFromNumber,
            });
          }}
        >
          Применить
        </Button>
      </div>
    </div>
  </>
);

export default PropagateFilter;
