import React from 'react';
import { Icon } from './Icon';
import { px } from '../constants/Px';
import { useNavigate } from 'react-router';

export const SearchMenuItem = ({
  svg,
  title,
  border,
  strong,
  isCountry,
  handleMenuItemClick,
  index,
  selectedPreferences,
  selectedItem,
}) => {
  const navigate = useNavigate();
  const handleSearchMenuItemClick = () => {
    if (isCountry) {
      navigate('/preferences');
    } else if (handleMenuItemClick) {
      handleMenuItemClick(index);
      // navigate('/item-filter', { state: { selectedItem: selectedItem } });
    }
  };

  return (
    <div
      className={`search-menu-item${border ? ' border-bottom' : ''}`}
      onClick={handleSearchMenuItemClick}
    >
      {title ? (
        <img src={`${process.env.REACT_APP_SITE_URL}${svg}`} />
      ) : (
        <>
          {selectedPreferences.svgUrl ? (
            <Icon
              viewBox='0 0 22 14.66'
              width={px(22)}
              height={px(14.66)}
              type={selectedPreferences.svgUrl}
            />
          ) : (
            <img src={`${selectedPreferences.iconUrl}`} />
          )}
        </>
      )}
      <h3 className={`search-menu-item-title ${strong ? 'strong' : ''}`}>
        {title ? `${title}` : `${selectedPreferences.country} / ${selectedPreferences.currency}`}
      </h3>
    </div>
  );
};

export default SearchMenuItem;
