import { TypesIcon } from '../../../types/TypesIcon';
import { px } from '../../Px';

export const menuProfileItems = [
  {},
  {
    icon: TypesIcon.SETTINGS,
    width: px(28.214),
    height: px(28.214),
    viewBox: '0 0 28.214 28.214',
    link: '/profile',
  },
  {
    icon: TypesIcon.STAR,
    width: px(27.5),
    height: px(26.233),
    viewBox: '0 0 27.5 26.233',
    link: '/favourites',
  },
  {
    icon: TypesIcon.PENSIL,
    title: 'дневник',
    link: '',
    width: px(18.51),
    height: px(23.873),
    viewBox: '0 0 18.51 23.873',
  },
  { title: 'зал<br>славы' },
  { title: 'Новости' },
  {},
  { title: 'Отчеты' },
  { icon: TypesIcon.BOOKING, width: px(51.919), height: px(61.411), viewBox: '0 0 51.919 61.411' },
  { icon: TypesIcon.SEVEN, width: px(24.5), height: px(31), viewBox: '0 0 24.5 31' },
  { icon: TypesIcon.LAMP, width: px(24.715), height: px(29.646), viewBox: '0 0 24.715 29.646' },
  { icon: TypesIcon.DOTSMORE, width: px(28), height: px(6), viewBox: '0 0 28 6' },
];

export const menuObjectItems = [
  {
    icon: TypesIcon.SPEED,
    width: px(43.356),
    height: px(29.338),
    viewBox: '0 0 43.356 29.338',
  },
  {
    icon: TypesIcon.SETTINGS,
    width: px(28.214),
    height: px(28.214),
    viewBox: '0 0 28.214 28.214',
    link: '/user-object',
  },
  {
    icon: TypesIcon.SCREEN,
    width: px(33.039),
    height: px(33.039),
    viewBox: '0 0 33.039 33.039',
  },
  {
    icon: TypesIcon.PENSIL,
    title: 'дневник',
    link: '',
    width: px(18.51),
    height: px(23.873),
    viewBox: '0 0 18.51 23.873',
  },
  { title: '' },
  { title: 'Аналитика' },
  { title: '' },
  { title: '' },
  { icon: TypesIcon.BOOKING, width: px(51.919), height: px(61.411), viewBox: '0 0 51.919 61.411' },
  { icon: TypesIcon.SEVEN, width: px(24.5), height: px(31), viewBox: '0 0 24.5 31' },
  { icon: TypesIcon.LAMP, width: px(24.715), height: px(29.646), viewBox: '0 0 24.715 29.646' },
  { icon: TypesIcon.DOTSMORE, width: px(28), height: px(6), viewBox: '0 0 28 6' },
];

export const menuCompetitionItems = [
  {
    icon: TypesIcon.SPEED,
    width: px(43.356),
    height: px(29.338),
    viewBox: '0 0 43.356 29.338',
  },
  {
    icon: TypesIcon.SETTINGS,
    width: px(28.214),
    height: px(28.214),
    viewBox: '0 0 28.214 28.214',
    link: '/user-competition',
  },
  {
    icon: TypesIcon.SCREEN,
    width: px(33.039),
    height: px(33.039),
    viewBox: '0 0 33.039 33.039',
  },
  {
    icon: TypesIcon.PENSIL,
    title: 'дневник',
    link: '',
    width: px(18.51),
    height: px(23.873),
    viewBox: '0 0 18.51 23.873',
  },
  { title: '' },
  { title: 'Аналитика' },
  { title: '' },
  { title: '' },
  { icon: TypesIcon.BOOKING, width: px(51.919), height: px(61.411), viewBox: '0 0 51.919 61.411' },
  { icon: TypesIcon.SEVEN, width: px(24.5), height: px(31), viewBox: '0 0 24.5 31' },
  { icon: TypesIcon.LAMP, width: px(24.715), height: px(29.646), viewBox: '0 0 24.715 29.646' },
  { icon: TypesIcon.DOTSMORE, width: px(28), height: px(6), viewBox: '0 0 28 6' },
];

export const menuGuideItems = [
  {
    icon: TypesIcon.SPEED,
    width: px(43.356),
    height: px(29.338),
    viewBox: '0 0 43.356 29.338',
  },
  {
    icon: TypesIcon.SETTINGS,
    width: px(28.214),
    height: px(28.214),
    viewBox: '0 0 28.214 28.214',
    link: '/user-guide',
  },
  {
    icon: TypesIcon.SCREEN,
    width: px(33.039),
    height: px(33.039),
    viewBox: '0 0 33.039 33.039',
  },
  {
    icon: TypesIcon.PENSIL,
    title: 'дневник',
    link: '',
    width: px(18.51),
    height: px(23.873),
    viewBox: '0 0 18.51 23.873',
  },
  { title: '' },
  { title: 'Аналитика' },
  { title: '' },
  { title: '' },
  { icon: TypesIcon.BOOKING, width: px(51.919), height: px(61.411), viewBox: '0 0 51.919 61.411' },
  { icon: TypesIcon.SEVEN, width: px(24.5), height: px(31), viewBox: '0 0 24.5 31' },
  { icon: TypesIcon.LAMP, width: px(24.715), height: px(29.646), viewBox: '0 0 24.715 29.646' },
  { icon: TypesIcon.DOTSMORE, width: px(28), height: px(6), viewBox: '0 0 28 6' },
];
