import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { StateProvider } from '../components';
import { routes } from '../constants/Routes';
import { CompetitionStack } from '../new-architecture/competition';
import { GuideStack } from '../new-architecture/guide/guide.stack';
import { ObjectStack } from '../new-architecture/object';
import { ProfileStack } from '../new-architecture/profile';
import { AboutPage } from './AboutPage';
import { AccountControl } from './AccountControlPage';
import { AddAdministratorPage } from './AddAdministratorPage';
import { AddCardPage } from './AddCardPage';
import { AddCodePage } from './AddCodePage';
import { AddEmailPage } from './AddEmailPage';
import { AddLoyalCardPage } from './AddLoyalCardPage';
import { AppealsPage } from './AppealsPage';
import { BlockedPage } from './BlockedPage';
import { BlockTheAccountPage } from './BlockTheAccountPage';
import { BookingPage } from './BookingPage';
import { CardsTypesProfilePage } from './CardsTypesProfilePage';
import { CardsTypesPage } from './CardTypesPage';
import { CashPage } from './CashPage';
import { ChangePhonePage } from './ChangePhonePage';
import { ChatWithSupport } from './ChatWithSupport';
import { ClubCardsPage } from './ClubCardsPage';
import { ConfidentialInfoPage } from './ConfigentialInfoPage';
import { CookiesPage } from './CookiesPage';
import { DeleteAccountPage } from './DeleteAccountPage';
import { DemandPage } from './DemandPage';
import { Distribution } from './DistributionPage';
import { ExtractBookingPage } from './ExtractBookingPage';
import { ExtractOfferPage } from './ExtractOfferPage';
import { ExtractStatisticsPage } from './ExtractStatisticsPage';
import { FaceIdPage } from './FaceIdPage';
import { FavouritesPage } from './FavouritesPage';
import { FeedbackPage } from './FeedbackPage';
import { FilterPage } from './FilterPage';
import { FinanceMainPage } from './FinanceMainPage';
import { History } from './History';
import { HolidayPage } from './HolidayPage';
import { HomePage } from './HomePage';
import { ItemFilterPage } from './ItemFilterPage';
import { LoginPage } from './LoginPage';
import { MapPage } from './MapPage';
import { MobilePage } from './MobilePage';
import { Notification } from './Notification';
import { NotificationDevices } from './Notification-devices';
import { NotificationDevicesList } from './NotificationDevicesList';
import { NotificationPushCodes } from './NotificationPushCodes';
import { NotificationSendingTime } from './NotificationSendingTime';
import { NotificationsPage } from './NotificationsPage';
import { ObjectCreateFeedbackPage } from './ObjectCreateFeedbackPage';
import { ObjectDescriptionPage } from './ObjectDescriptionPage';
import { ObjectFeedbackPage } from './ObjectFeedbackPage';
import { ObjectNewsPage } from './ObjectNewsPage';
import { OfferMenuPage } from './OfferMenuPage';
import { OpenSoonPage } from './OpenSoonPage';
import { PreferencesPage } from './PreferencesPage';
import { PresentCardPage } from './PresentCardPage';
import { PrivacyPage } from './PrivacyPage';
import { RegistrationObjectPage } from './RegistrationObjectPage';
import { RegistrationPage } from './RegistrationPage';
import { RootRouter } from './RootRouter';
import { SchemaPage } from './SchemaPage';
import { SectorPage } from './SectorPage';
import { SelectionPage } from './SelectionPage';
import { SelectProfile } from './SelectProfilePage';
import { SettingsProfilePage } from './SettingsProfilePage';
import { SliderPage } from './SliderPage';
import { SMSPage } from './SMSPage';
import { SortPage } from './SortPage';
import { StatisticPage } from './StatisticPage';
import { Test } from './Test';
import { TransferPage } from './TransferPage';
import { UserAccount } from './UserAccount';
import { UserCompetitionPage } from './UserCompetition';
import { UserGuidePage } from './UserGuide';
import { UserObjectPage } from './UserObject';
import { VerifyFaceIdPage } from './VerifyFaceIdPage';

export const Router = () => (
  <StateProvider>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/old-offers"
        element={
          <RootRouter>
            <OfferMenuPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.selectProfile}
        element={
          <RootRouter>
            <SelectProfile />
          </RootRouter>
        }
      />
      <Route path={routes.blocked} element={<BlockedPage />} />
      <Route
        path={routes.registrationObject}
        element={
          <RootRouter>
            <RegistrationObjectPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.addLoyalCard}
        element={
          <RootRouter>
            <AddLoyalCardPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.objectDescription}
        element={
          <RootRouter>
            <ObjectDescriptionPage />
          </RootRouter>
        }
      />
      {/* <Route
        path={routes.userObject}
        element={
          <RootRouter>
            <UserObjectPage />
          </RootRouter>
        }
      /> */}
      {/* <Route
        path={routes.userCompetition}
        element={
          <RootRouter>
            <UserCompetitionPage />
          </RootRouter>
        }
      /> */}
      {/* <Route
        path={routes.userGuide}
        element={
          <RootRouter>
            <UserGuidePage />
          </RootRouter>
        }
      /> */}
      <Route
        path={routes.userAccount}
        element={
          <RootRouter>
            <UserAccount />
          </RootRouter>
        }
      />

      <Route path={routes.openSoon} element={<OpenSoonPage />} />

      <Route path={routes.sms} element={<SMSPage />} />

      <Route
        path={routes.verifyFaceId}
        element={
          <RootRouter>
            <VerifyFaceIdPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.deleteAccountPage}
        element={
          <RootRouter>
            <DeleteAccountPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.history}
        element={
          <RootRouter>
            <History />
          </RootRouter>
        }
      />

      <Route
        path={routes.notification}
        element={
          <RootRouter>
            <Notification />
          </RootRouter>
        }
      />
      <Route
        path={routes.confidentialInfo}
        element={
          <RootRouter>
            <ConfidentialInfoPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.settingsProfile}
        element={
          <RootRouter>
            <SettingsProfilePage />
          </RootRouter>
        }
      />
      <Route
        path={routes.changePhone}
        element={
          <RootRouter>
            <ChangePhonePage />
          </RootRouter>
        }
      />
      <Route
        path={routes.addCode}
        element={
          <RootRouter>
            <AddCodePage />
          </RootRouter>
        }
      />
      <Route
        path={routes.notificationDevices}
        element={
          <RootRouter>
            <NotificationDevices />
          </RootRouter>
        }
      />
      <Route
        path={routes.notificationDevicesList}
        element={
          <RootRouter>
            <NotificationDevicesList />
          </RootRouter>
        }
      />
      <Route
        path={routes.notificationPushCodes}
        element={
          <RootRouter>
            <NotificationPushCodes />
          </RootRouter>
        }
      />
      <Route
        path={routes.notificationSendingTime}
        element={
          <RootRouter>
            <NotificationSendingTime />
          </RootRouter>
        }
      />
      <Route
        path={routes.addEmail}
        element={
          <RootRouter>
            <AddEmailPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.appeals}
        element={
          <RootRouter>
            <AppealsPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.chatWithSupport}
        element={
          <RootRouter>
            <ChatWithSupport />
          </RootRouter>
        }
      />
      <Route
        path={routes.addCard}
        element={
          <RootRouter>
            <AddCardPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.finance}
        element={
          <RootRouter>
            <FinanceMainPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.mobile}
        element={
          <RootRouter>
            <MobilePage />
          </RootRouter>
        }
      />
      {/* <Route
        path={routes.accountControl}
        element={
          <RootRouter>
            <AccountControl />
          </RootRouter>
        }
      /> */}
      <Route
        path={routes.blockTheAccount}
        element={
          <RootRouter>
            <BlockTheAccountPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.holiday}
        element={
          <RootRouter>
            <HolidayPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.clubCards}
        element={
          <RootRouter>
            <ClubCardsPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.sort}
        element={
          <RootRouter>
            <SortPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.presentCard}
        element={
          <RootRouter>
            <PresentCardPage />
          </RootRouter>
        }
      />
      <Route path={routes.test} element={<Test />} />
      <Route
        path={routes.cardTypes}
        element={
          <RootRouter>
            <CardsTypesPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.cardsTypesProfile}
        element={
          <RootRouter>
            <CardsTypesProfilePage />
          </RootRouter>
        }
      />
      <Route
        path={routes.favourites}
        element={
          <RootRouter>
            <FavouritesPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.notifications}
        element={
          <RootRouter>
            <NotificationsPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.statistic}
        element={
          <RootRouter>
            <StatisticPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.cash}
        element={
          <RootRouter>
            <CashPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.about}
        element={
          <RootRouter>
            <AboutPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.feedback}
        element={
          <RootRouter>
            <FeedbackPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.objectCreateFeedback}
        element={
          <RootRouter>
            <ObjectCreateFeedbackPage />
          </RootRouter>
        }
      />
      <Route path={routes.objectFeedback} element={<ObjectFeedbackPage />} />
      <Route
        path={routes.demand}
        element={
          <RootRouter>
            <DemandPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.transfer}
        element={
          <RootRouter>
            <TransferPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.distribution}
        element={
          <RootRouter>
            <Distribution />
          </RootRouter>
        }
      />

      <Route
        path={routes.notificationExtractBooking}
        element={
          <RootRouter>
            <ExtractBookingPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.notificationExtractStatistics}
        element={
          <RootRouter>
            <ExtractStatisticsPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.notificationExtractOffer}
        element={
          <RootRouter>
            <ExtractOfferPage />
          </RootRouter>
        }
      />

      <Route
        path={`${routes.objectNews}`}
        element={
          <RootRouter>
            <ObjectNewsPage />
          </RootRouter>
        }
      />

      <Route
        path={`${routes.shema}`}
        element={
          <RootRouter>
            <SchemaPage />
          </RootRouter>
        }
      />
      <Route
        path={routes.sector}
        element={
          <RootRouter>
            <SectorPage />
          </RootRouter>
        }
      />

      <Route
        path={routes.selection}
        element={
          <RootRouter>
            <SelectionPage />
          </RootRouter>
        }
      />

      <Route path={routes.filter} element={<FilterPage />} />
      <Route path={routes.login} element={<LoginPage />} />
      <Route path={routes.registration} element={<RegistrationPage />} />
      <Route path={routes.faceId} element={<FaceIdPage />} />
      <Route path={routes.map} element={<MapPage />} />
      <Route path={routes.itemFilter} element={<ItemFilterPage />} />
      <Route path={routes.booking} element={<BookingPage />} />
      <Route path={routes.addAdministrator} element={<AddAdministratorPage />} />
      <Route path={routes.preferences} element={<PreferencesPage />} />
      <Route path={routes.slider} element={<SliderPage />} />
      <Route path={routes.privacy} element={<PrivacyPage />} />
      <Route path={routes.cookies} element={<CookiesPage />} />
    </Routes>
    <ProfileStack />
    <ObjectStack />
    <CompetitionStack />
    <GuideStack />
  </StateProvider>
);
