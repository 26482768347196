import { request } from '@/helpers/api/request';

export const getAttachmentEvent = async ({ eventId }) => {
  const response = await request({
    url: `event-attachment/${eventId}`,
    method: 'GET',
  });

  return response;
};
