/* eslint-disable security/detect-object-injection */
/* eslint-disable no-restricted-syntax */
import { getEvent } from '../../api/event';
import { getLocations } from '../../api/location';

export const getObject = async ({ objectId }) => {
  const [locations, object] = (
    await Promise.allSettled([getLocations({ id: objectId }), getEvent({ id: objectId })])
  )
    // eslint-disable-next-line unicorn/no-await-expression-member
    .map(item => item?.value?.data); // TODO: current object

  // Inject sectors for each location with their group
  const injectedLocations = await Promise.all(
    locations?.map?.(location => ({
      ...location,
      sections: location?.sections?.map?.(sector => ({
        ...sector,
        priceGroup: location?.sectionGroups?.find?.(({ id }) => id === sector?.sectionGroupId),
        accessGroup: location?.allowanceSectionGroups?.find?.(
          ({ id }) => id === sector?.sectionAllowanceGroupId,
        ),
      })),
    })),
  );

  return {
    locations: injectedLocations,
    selectedLocation: injectedLocations[0],
    object,
  };
};
