import * as authApi from '@/api/contacts';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_CONTACTS = 'contacts/get-contacts';

export const getContacts = createAsyncThunk(
  GET_CONTACTS,
  createRequest({
    type: GET_CONTACTS,
    loader: (data) => authApi.getContacts(data),
  }),
);
