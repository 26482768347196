import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/event';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_EVENT = 'event/getEvent';

export const getEvent = createAsyncThunk(
  GET_EVENT,
  createRequest({
    type: GET_EVENT,
    loader: data => authApi.getEvent(data),
  }),
);
