import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/recommendation';

export const UPDATE_RECOMMENDATION = 'recommendation/updateRecommendation';

export const updateRecommendation = createAsyncThunk(
  UPDATE_RECOMMENDATION,
    createRequest({
        type: UPDATE_RECOMMENDATION,
        loader: (data) => authApi.updateRecommendation(data),
    }),
);
