import { request } from '@/helpers/api/request';
// TODO CHECK
export const deleteProfile = async (id) => {

  const response = await request({
    url: `profile/${id}`,
    method: 'DELETE',
  });

  return response;
};
