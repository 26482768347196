import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';
import { clearActiveId } from '../../auth';
import { addModal } from '../../modal/addModal';

export const DELETE_GUIDE_CABINET = 'guide/deleteGuideCabinet';

const onFail = () => async dispatch => {
  await dispatch(
    addModal({
      id: 31,
      data: {
        title: 'Данный кабинет не может быть удален',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

const onSuccess = () => async dispatch => {
  await dispatch(clearActiveId());
};

export const deleteGuideCabinet = createAsyncThunk(
  DELETE_GUIDE_CABINET,
  createRequest({
    type: DELETE_GUIDE_CABINET,
    loader: data => authApi.deleteGuideCabinet(data),
    onFail,
    onSuccess,
  }),
);
