import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/auth';
import { addModal } from '../../modal/addModal';

export const AUTH_CODE_DELETE = 'auth/authCodeDelete';

const onSuccess = () => async (dispatch) => {
  dispatch(
    addModal({
      id: 10,
      data: {
        title: 'Код доступа удален',
        buttonLeftText: 'Ok',
        single: true,
      },

    }),
  );
};

export const authCodeDelete = createAsyncThunk(
  AUTH_CODE_DELETE,
  createRequest({
    type: AUTH_CODE_DELETE,
    loader: (data) => authApi.authCodeDelete(data),
    onSuccess,
  }),
);
