import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLockBodyScroll = () => {
  useEffect(() => {
    document.documentElement.classList.add('hidden-scroll');
    return () => {
      document.documentElement.classList.remove('hidden-scroll');
    };
  }, []);
};

export const useLockBodyScrollChangePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (document.documentElement.classList.includes === 'hidden-scroll') {
      document.documentElement.classList.remove('hidden-scroll');
    }
  }, [location.pathname]);
};
