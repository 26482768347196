/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-shadow */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Checkbox, TariffCard } from '../../../../../../../components';
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';
import { DiscountInfo } from '../discount-info';
import { PriceInfo } from '../price-info';
import { PromotionInfo } from '../promotion-info';

export const PriceRate = ({
  promotionGroup,
  discountGroup,
  priceInfo,
  discountInfo,
  promotionInfo,
  checked: defaultChecked = false,
  onChange,
  locationType,
  priceDiscountAction,
  type,
}) => {
  const [checked, setChecked] = useState();
  const refDiv = useRef();
  const [promotion, setPromotion] = useState(0);
  const [discount, setDiscount] = useState(0);

  const { updateFunction: updateProfile } = useProfile();

  useEffect(() => {
    updateProfile();
  }, [promotion]);

  useLayoutEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = c => {
    setChecked(c);
    if (onChange) onChange(c);
    setTimeout(() => {
      refDiv.current.scrollIntoView();
    }, 0);
  };

  const [priceValue, setPriceValue] = useState(0);
  const [priceStart, setPriceStart] = useState();
  const [priceEnd, setPriceEnd] = useState();

  useLayoutEffect(() => {
    if (locationType === 'FISHING') {
      const priceArray = [];
      if (priceInfo?.isCaught && priceInfo?.caughtValue !== '0' && priceInfo?.caughtValue)
        priceArray.push(+priceInfo.caughtValue);
      if (priceInfo?.isHelp && priceInfo?.helpValue !== '0' && priceInfo?.helpValue)
        priceArray.push(+priceInfo.helpValue);
      if (priceInfo?.isWithNorm && priceInfo?.withNormValue !== '0' && priceInfo?.withNormValue)
        priceArray.push(+priceInfo.withNormValue);
      if (
        priceInfo?.isWithoutNorm &&
        priceInfo?.withoutNormValue !== '0' &&
        priceInfo?.withoutNormValue
      )
        priceArray.push(+priceInfo.withoutNormValue);
      if (priceInfo?.cost !== '0' && priceInfo?.cost) priceArray.push(+priceInfo.cost);
      if (priceArray?.length > 0) setPriceValue(Math.min(...priceArray));
      else setPriceValue(0);
    } else {
      setPriceValue(priceInfo?.cost);
    }
  }, [priceInfo, checked]);

  useEffect(() => {
    if (setDiscount) {
      let promo = 0;
      if (
        discountInfo?.isCaught &&
        discountInfo?.caughtValue &&
        discountInfo?.caughtValue !== '0 %'
      ) {
        if (+discountInfo?.caughtValue.slice(0, -1) > promo) {
          promo = discountInfo?.caughtValue.slice(0, -1);
        }
      }
      if (discountInfo?.helpValue && discountInfo?.isHelp && discountInfo?.helpValue !== '0 %') {
        if (+discountInfo?.helpValue.slice(0, -1) > promo) {
          promo = discountInfo.helpValue?.slice(0, -1);
        }
      }
      if (
        discountInfo?.withoutNormValue &&
        discountInfo?.isWithoutNorm &&
        discountInfo?.withoutNormValue !== '0 %'
      ) {
        if (+discountInfo?.withoutNormValue.slice(0, -1) > promo) {
          promo = discountInfo?.withoutNormValue.slice(0, -1);
        }
      }
      if (
        discountInfo?.withNormValue &&
        discountInfo?.isWithNorm &&
        discountInfo?.withNormValue !== '0 %'
      ) {
        if (+discountInfo?.withNormValue.slice(0, -1) > promo) {
          promo = discountInfo?.withNormValue.slice(0, -1);
        }
      }
      if (discountInfo?.cost && discountInfo?.cost !== '0 %') {
        if (+discountInfo?.cost.slice(0, -1) > promo) {
          promo = discountInfo?.cost.slice(0, -1);
        }
      }

      setDiscount(promo);
    }

    if (setPromotion) {
      let promo = 0;
      if (
        promotionInfo?.isCaught &&
        promotionInfo?.caughtValue &&
        promotionInfo?.caughtValue !== '0 %'
      ) {
        if (+promotionInfo?.caughtValue.slice(0, -1) > promo) {
          promo = promotionInfo?.caughtValue.slice(0, -1);
        }
      }
      if (promotionInfo?.isHelp && promotionInfo?.helpValue && promotionInfo?.helpValue !== '0 %') {
        if (+promotionInfo?.helpValue.slice(0, -1) > promo) {
          promo = promotionInfo?.helpValue.slice(0, -1);
        }
      }
      if (
        promotionInfo?.isWithoutNorm &&
        promotionInfo?.withoutNormValue &&
        promotionInfo?.withoutNormValue !== '0 %'
      ) {
        if (+promotionInfo?.withoutNormValue.slice(0, -1) > promo) {
          promo = promotionInfo?.withoutNormValue.slice(0, -1);
        }
      }
      if (
        promotionInfo?.isWithNorm &&
        promotionInfo?.withNormValue &&
        promotionInfo?.withNormValue !== '0 %'
      ) {
        if (+promotionInfo?.withNormValue.slice(0, -1) > promo) {
          promo = promotionInfo?.withNormValue.slice(0, -1);
        }
      }
      if (promotionInfo?.cost && promotionInfo?.cost !== '0 %') {
        if (+promotionInfo?.cost.slice(0, -1) > promo) {
          promo = promotionInfo?.cost.slice(0, -1);
        }
      }

      setPromotion(promo);
    }
    setTimeout(() => updateProfile(), 100);
  }, []);

  useEffect(() => {
    setPriceStart(priceStart || priceInfo?.periodStart);
    setPriceEnd(priceEnd || priceInfo?.periodEnd);
  }, [checked]);

  return (
    <div>
      <div
        style={{
          paddingBottom: px(20),
          paddingTop: px(20),
        }}
        className="flex items-center justify-between"
      >
        <div />
        <div
          ref={refDiv}
          style={{
            paddingLeft: px(100),
            paddingRight: px(69),
          }}
        >
          <TariffCard
            price={priceValue || '0'}
            isSunActive
            time={priceStart || '00:00'}
            endTime={priceEnd || '00:00'}
            typeTime={type}
            promotion={promotion}
            discount={discount}
            priceDiscountAction={priceDiscountAction}
          />
        </div>
        <Checkbox
          margin
          checked={checked}
          onChange={handleChange}
          style={{
            margin: '0px',
          }}
        />
      </div>

      {checked && priceDiscountAction === 'price' && (
        <PriceInfo
          locationType={locationType}
          priceInfo={priceInfo}
          setPriceValue={setPriceValue}
          setPriceStart={setPriceStart}
          setPriceEnd={setPriceEnd}
        />
      )}
      {checked && priceDiscountAction === 'action' && (
        <PromotionInfo locationType={locationType} setPromotion={setPromotion} />
      )}
      {checked && priceDiscountAction === 'discount' && (
        <DiscountInfo locationType={locationType} setPromotion={setDiscount} />
      )}
    </div>
  );
};

export default PriceRate;
