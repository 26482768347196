import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/search';

export const SEARCH = 'subscription/addSubscription';


export const search = createAsyncThunk(
  SEARCH,
    createRequest({
        type: SEARCH,
        loader: (data) => authApi.search(data),
    }),
);