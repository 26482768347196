import { request } from '@/helpers/api/request';

export const emailStart = async ({ email }) => {
  const response = await request({
    url: `profile/email/start`,
    method: 'POST',
    data: {
      email,
    },
  });

  return response;
};
