/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCompetitionCabinet } from '../../actions/competition/thunks';
import {
  addCompetitionAttachment,
  deleteCompetitionAttachment,
  getCompetitionCabinet as getCompetitionCabinetApi,
} from '../../api/competition';
import { Footer, Header, ScrollUpToTopArrow, StyledAvatar } from '../../components';
import { Banner } from '../../components/banner';
import { DraggableModal } from '../../components/draggable-modal';
import { Icon } from '../../components/Icon';
import { StateContext } from '../../components/state-provider';
import { userCompetitionData } from '../../constants/Component/user-competition';
import { addLeadingZeros } from '../../constants/Function';
import { px } from '../../constants/Px';
import { mapCompetitionToFields } from '../../new-architecture/profile/edit/components/competition-form/helpers/competition.helpers';
import { useProfile } from '../../new-architecture/profile/hooks';
import { ImageLoaderModal } from '../../new-architecture/shared';
import { urlToBlob } from '../../new-architecture/utils/blob';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';

const removeAttachments = async (eventId, type) => {
  const {
    data: { attachments },
  } = await getCompetitionCabinetApi({ id: eventId });

  const filtered = attachments?.filter?.(({ type: t }) => t === type);

  if (filtered?.length) {
    await Promise.all(filtered?.map(({ id }) => deleteCompetitionAttachment({ id })));
  }
};

export const UserCompetitionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = useSelector(authActiveIdSelector);
  const prevScrollTop = useRef(0);
  const [scrollActiveBottom, setScrollActiveBottom] = useState(false);
  const { showSearch, setShowSearch, show, handleClose } = useContext(StateContext);
  const [showAvatarLoaderModal, setShowAvatarLoaderModal] = useState(false);
  const [showGalleryLoader, setShowGalleryLoader] = useState(false);
  const activeType = useSelector(authActiveType);

  const {
    updateFunction: updateProfileFunction,
    data: { competition },
  } = useProfile();

  const fields = useMemo(() => mapCompetitionToFields(competition), [JSON.stringify(competition)]);

  const logo = useMemo(
    () => fields?.attachments?.find?.(att => att?.key === 'logo')?.src,
    [JSON.stringify(fields?.attachments)],
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const navigeRoute = link => {
    navigate(`/${link}`, {
      state: { prevPath: pathname },
    });
  };

  const zeroId = addLeadingZeros(id);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight } = document.documentElement;

      prevScrollTop.current = scrollTop;

      const scrollableHeight = scrollHeight - window.innerHeight;
      setScrollActiveBottom(window.scrollY >= scrollableHeight - 800);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (show || showSearch) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
  }, [show, showSearch]);

  useEffect(() => {
    dispatch(getCompetitionCabinet({ id }));
  }, [id]);

  return (
    <div>
      {!show && <Header setShowSearch={setShowSearch} showSearch={showSearch} />}
      <div>
        <DraggableModal show={show} onClose={handleClose}>
          <StyledAvatar index={1} data={competition} />
        </DraggableModal>
      </div>
      <main>
        <div
          onClick={() => setShowAvatarLoaderModal(true)}
          onKeyDown={() => setShowAvatarLoaderModal(true)}
          className="  flex cursor-pointer flex-col items-center "
          style={{ paddingTop: `${px(29)}` }}
        >
          {logo ? (
            <img
              alt="avatar"
              style={{
                width: px(40),
                height: px(40),
                borderRadius: px(40),
              }}
              src={logo}
            />
          ) : (
            <Icon
              className="cursor-pointer"
              width={px(40)}
              height={px(40)}
              viewBox="0 0 40 40"
              type="USERPHOTO"
            />
          )}

          <ImageLoaderModal
            show={showAvatarLoaderModal}
            label="Логотип"
            crop={{
              width: 130,
              height: 130,
              style: {
                borderRadius: px(15),
              },
            }}
            onClose={() => setShowAvatarLoaderModal(false)}
            onChange={async ({ avatar }) => {
              const id = competition?.attachments?.filter(item => item.type === 'LOGO')[0]?.id;

              if (id) await deleteCompetitionAttachment({ id });

              if (avatar?.src) {
                const formData = new FormData();
                formData.append('file', await urlToBlob(avatar?.src));
                formData.append('cabinetId', competition?.id);
                formData.append('type', 'LOGO');
                await addCompetitionAttachment(formData);
              }

              await updateProfileFunction();
              await dispatch(getCompetitionCabinet({ id: competition?.id }));

              handleClose();
            }}
            items={[
              {
                key: 'avatar',
                image: {
                  src: logo,
                },
              },
            ]}
          />

          <div
            className="w-full "
            style={{
              padding: `${0} ${px(15)}`,
            }}
          >
            <h1
              className="line-clamp-1 overflow-x-hidden"
              style={{ fontSize: `${px(32)}`, lineHeight: `${px(43)}` }}
            >
              {competition?.name?.length <= 15
                ? competition?.name || ''
                : competition?.name?.slice(0, 15)?.length === 15
                  ? `${competition?.name?.slice(0, 14)}...`
                  : competition?.name?.slice(0, 15)}
            </h1>
          </div>
          <h1 style={{ fontSize: `${px(20)}`, lineHeight: `${px(26)}` }}>{`ID: ${zeroId}`}</h1>
        </div>

        <div
          style={{
            paddingTop: `${px(10)}`,
            paddingLeft: `${px(15)}`,
            paddingRight: `${px(15)}`,
            paddingBottom: px(10),
          }}
        >
          {userCompetitionData?.({ setShowGalleryLoader })
            ?.filter(item => (activeType === 'administeredCompetition' ? !item.personal : item))
            ?.map(item =>
              item?.phoneNumber ? (
                <a className="text-[black]" key={item.id} href={item?.link}>
                  <div
                    className={`'border-b-solid border-b-black' flex cursor-pointer  
                     flex-row items-center border-b-[1px]
                `}
                    style={{
                      paddingTop: `${px(19)}`,
                      paddingBottom: item.bottom,
                      height: px(70),
                    }}
                  >
                    {item?.iconName && (
                      <div
                        style={{
                          width: `${px(48)}`,
                        }}
                      >
                        <Icon
                          width={px(item.width)}
                          height={px(item.height)}
                          viewBox={item.viewBox}
                          type={item.iconName}
                        />
                      </div>
                    )}
                    <h1
                      className="font-normal "
                      style={{
                        fontSize: `${px(20)}`,
                        lineHeight: `${px(26)}`,
                      }}
                    >
                      {item.name}
                    </h1>
                  </div>
                </a>
              ) : (
                <div
                  className={`flex cursor-pointer flex-row items-center  ${
                    item.id === userCompetitionData?.().length
                      ? ''
                      : 'border-b-solid border-b-[1px] border-b-black'
                  }`}
                  style={{
                    paddingTop: `${px(19)}`,
                    paddingBottom: item.bottom,
                    height: px(70),
                  }}
                  key={item.id}
                  onClick={() => (item?.onClick ? item?.onClick?.() : navigeRoute(item?.link))}
                  onKeyDown={() => (item?.onClick ? item?.onClick?.() : navigeRoute(item?.link))}
                >
                  {item?.iconName && (
                    <div
                      style={{
                        width: `${px(48)}`,
                      }}
                    >
                      <Icon
                        width={px(item.width)}
                        height={px(item.height)}
                        viewBox={item.viewBox}
                        type={item.iconName}
                      />
                    </div>
                  )}
                  <h1
                    className="font-normal "
                    style={{
                      fontSize: `${px(20)}`,
                      lineHeight: `${px(26)}`,
                    }}
                  >
                    {item.name}
                  </h1>
                </div>
              ),
            )}
        </div>

        <ImageLoaderModal
          show={showGalleryLoader}
          onClose={() => setShowGalleryLoader(false)}
          label="Галерея"
          crop={{
            width: 320,
            height: 290,
          }}
          items={[
            ...fields?.gallery?.map?.(({ src, first }) => ({
              image: {
                src,
                first,
              },
            })),
            undefined,
          ]}
          onChange={async filesObject => {
            const files = Object?.values?.(filesObject)
              ?.filter(i => i && i?.src !== undefined)
              ?.map(item => ({
                ...item,
                src: item?.src,
              }))
              ?.sort?.((a, b) => Number(b.first) - Number(a.first));
            await removeAttachments(competition?.id, 'GALLERY');

            if (files?.length) {
              await Promise.all(
                files.map(async (image, index) => {
                  const formData = new FormData();

                  formData.append('file', await urlToBlob(image?.src, index));
                  formData.append('cabinetId', competition?.id);
                  formData.append('type', 'GALLERY');
                  await addCompetitionAttachment(formData);
                }),
              );
            }
            await updateProfileFunction();
          }}
          canBeFirst
        />

        <div
          style={{
            paddingBottom: px(10),
          }}
        >
          <Banner />
        </div>
        <Footer />
        <ScrollUpToTopArrow
          scrollActive={scrollActiveBottom}
          handleScrollToTop={handleScrollToTop}
        />
      </main>
    </div>
  );
};
