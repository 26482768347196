import * as authApi from '@/api/auth';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addModal } from '../../modal/addModal';
import { getProfile } from '../../profile';

export const LOGIN_BIOMETRIC = 'auth/loginBiometric';

const onFail = () => async (dispatch) => {
  await dispatch(
    addModal({
      id: 15,
      data: {
        title: 'Установите вход по Touch Id',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

const onSuccess = () => async (dispatch) => {
  await dispatch(getProfile());
};

export const loginBiometric = createAsyncThunk(
  LOGIN_BIOMETRIC,
  createRequest({
    type: LOGIN_BIOMETRIC,
    loader: (data) => authApi.loginBiometric(data),
    onSuccess,
    onFail,
  }),
);
