/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unknown-property */
import ad1Image from '@assets/gifs/female_with_glasses.gif';
import ad2Image from '@assets/images/ads/2.png';
import ad3Image from '@assets/images/ads/3.png';
import ad4Image from '@assets/images/ads/4.png';
import ad5Image from '@assets/images/ads/5.png';
import ad6Image from '@assets/images/ads/6.png';
import ad7Image from '@assets/images/ads/7.png';
import ad8Image from '@assets/images/ads/8.png';
// import ad2Image from '@assets/images/ad-2.png';
// import ad3Image from '@assets/images/ad-3.png';
// import ad5Image from '@assets/images/ad-5.png';
import mapBtnImg from '@assets/images/map-btn-img.png';
import sliderImage1 from '@assets/images/slider/new1.jpeg';
import sliderImage2 from '@assets/images/slider/new2.png';
import sliderImage3 from '@assets/images/slider/new3.jpeg';
import sliderImage4 from '@assets/images/slider/new4.jpeg';
import { Button, Checkbox, Footer, Header, ImageBlock, Input, Slider } from '@components';
import DraggableModal from '@components/draggable-modal';
import { ScrollUpToTopArrow } from '@components/scroll-up-to-top';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getProfile } from '../../actions/profile';
import { addSubscription } from '../../actions/subscription';
import { GuideCards } from '../../components';
import { Banner } from '../../components/banner';
import { CompetitionCards } from '../../components/competition-cards';
import { EventsCards } from '../../components/events-cards';
import { Icon } from '../../components/Icon';
import { StateContext } from '../../components/state-provider';
import { regexEmail } from '../../constants/Component/index';
import { px } from '../../constants/Px';
import { LogoIcon } from '../../new-architecture/icons/logo';
import { CircleButton, Popup, ScrollButtonsContainer } from '../../new-architecture/shared';
import { Modal } from '../../new-architecture/shared/modal';
import { authActiveIdSelector, authActiveType, authSelector } from '../../selectors/auth';
import { listOfCompetitionsSelector } from '../../selectors/competition';
import { cardsSelector } from '../../selectors/event';
import { listOfGuidesSelector } from '../../selectors/guide';
import { profileSelector } from '../../selectors/profile';
import { subscribeSelector } from '../../selectors/subscription';
import { TypesIcon } from '../../types/TypesIcon';

export const HomePage = () => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  const { showSearch, setShowSearch } = useContext(StateContext);
  const prevScrollTop = useRef(0);
  const guideCabinets = useSelector(listOfGuidesSelector);
  const competitionCabinets = useSelector(listOfCompetitionsSelector);
  const profile = useSelector(profileSelector);
  const cards = useSelector(cardsSelector);
  const activeType = useSelector(authActiveType);
  const activeID = useSelector(authActiveIdSelector);
  const auth = useSelector(authSelector);
  const [showAdPopup, setShowAdPopup] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);
  const [scrollActiveBottom, setScrollActiveBottom] = useState(false);
  const [menuToggleOpen, setMenuToggleOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [subscription, setSubscription] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [showAllEventsCards, setShowAllEventsCards] = useState(false);
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  const [showAllCompetitionsCards, setShowAllCompetitionsCards] = useState(false);
  const [showAllGuidesCards, setShowAllGuidesCards] = useState(false);

  const toggleLockClass = () => {
    setIsLocked(!isLocked);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const dispatch = useDispatch();
  const isSubscribed = useSelector(subscribeSelector);

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(addSubscription({ email: subscription }));
    setIsChecked(isSubscribed);
    setShowSubscriptionPopup(true);
  };

  const handleChangeEmail = e => {
    setSubscription(e);

    setIsValidEmail(regexEmail.test(e));
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = document.documentElement;
      if (scrollTop > prevScrollTop.current + 3) {
        setScrollActive(false);
      } else if (scrollTop < prevScrollTop.current - 1) {
        setScrollActive(true);
      }
      prevScrollTop.current = scrollTop;

      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
      setScrollActiveBottom(window.scrollY >= scrollableHeight - 800);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (window.location.hash === '#ad') {
      toggleLockClass();
      setShowAdPopup(true);
    }

    if (window.location.hash === '#video-modal') {
      setIsVideoModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (subscription.trim() === '') {
      setIsChecked(false);
    }
  }, [subscription]);

  useEffect(() => {
    if (isLocked) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
    return () => {
      document.documentElement.classList.remove('lock');
    };
  }, [isLocked]);

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (hash.includes('#privacy')) {
      window.scrollTo(0, document.body.scrollHeight);
      // eslint-disable-next-line no-restricted-globals
      history.pushState('', document.title, window.location.pathname + window.location.search);
    }
  }, [JSON.stringify(cards)]);

  useEffect(() => {
    if (showSearch) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
  }, [showSearch]);

  if (auth && (activeType === 'object' || activeType === 'administeredObject'))
    navigate(`/object/${activeID}`);

  if (auth && (activeType === 'competition' || activeType === 'administeredCompetition'))
    navigate(`/competition/${activeID}`);

  if (auth && (activeType === 'guide' || activeType === 'administeredGuide'))
    navigate(`/guide/${activeID}`);

  if (!auth || activeType === undefined || activeType === 'administeredProfile')
    return (
      <div className="global-container">
        {!menuToggleOpen && <Banner />}
        <Header
          menuToggleOpen={menuToggleOpen}
          setMenuToggleOpen={setMenuToggleOpen}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          toggleLockClass={toggleLockClass}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          profile={profile}
        />
        <main>
          <div className="cursor-pointer">
            <Slider
              images={[
                { type: 'image', src: sliderImage1 },
                { type: 'image', src: sliderImage2 },
                { type: 'image', src: sliderImage3 },
                { type: 'image', src: sliderImage4 },
              ]}
              setShowSearch={setShowSearch}
            />
          </div>
          <section>
            <div className="container">
              <h1
                style={{
                  marginTop: px(20),
                }}
                className="home_page_main-title"
              >
                Найди идеи для
                <br />
                вдохновения на
                <br />
                БРОНИРУЙ
                <br />
                РЫБАЛКУ. РФ
              </h1>
              <h2
                style={{
                  marginTop: px(5),
                  marginLeft: px(-15),
                  marginRight: px(-15),
                }}
                className="home_page_main-title"
              >
                Создай профиль и
                <br />
                получи доступ к лучшим
                <br /> услугам и источникам
              </h2>
            </div>
          </section>
          <section>
            <div className="container">
              <ImageBlock
                className="cursor-pointer"
                style={{
                  marginTop: px(20),
                }}
                src={ad1Image}
              />
              <ImageBlock
                className="cursor-pointer"
                style={{
                  marginTop: px(10),
                  marginBottom: px(10),
                }}
                src={ad2Image}
              />
              <div
                style={{
                  marginBottom: px(10),
                  marginRight: px(0),
                  marginLeft: px(-5),
                }}
                className="row "
              >
                <div
                  aria-hidden
                  onClick={() => setShowVideo(true)}
                  className="col cursor-pointer  "
                >
                  <ImageBlock
                    src={ad3Image}
                    video
                    // adLink="https://www.youtube.com/watch?v=9VBDUmS5I0k"
                  />
                </div>

                <div className="col">
                  <ImageBlock src={ad4Image} />
                </div>
              </div>
              <ImageBlock
                className="cursor-pointer"
                style={{
                  marginTop: px(10),
                  marginBottom: px(10),
                }}
                src={ad5Image}
              />
              <ImageBlock
                className="cursor-pointer"
                style={{
                  marginTop: px(10),
                  marginBottom: px(10),
                }}
                src={ad6Image}
              />
            </div>
          </section>
          <section
            style={{
              paddingTop: px(10),
              paddingBottom: px(20),
              backgroundColor: 'rgba(11, 131, 254, 0.3)',
            }}
          >
            <div className="container">
              <h2
                style={{
                  marginBottom: px(60),
                  opacity: 0,
                }}
                className="h1"
              >
                Заголовок
              </h2>
              <div
                className={`row ${cards?.length >= 7 && 'events-cards-container'} ${
                  showAllEventsCards && 'show-all'
                }`}
              >
                <EventsCards
                  cards={cards}
                  setShowAllEventsCards={setShowAllEventsCards}
                  showAllEventsCards={showAllEventsCards}
                />
              </div>
              {!showAllEventsCards && cards?.length > 7 && (
                <div
                  className="m-auto cursor-pointer whitespace-nowrap"
                  style={{
                    marginTop: px(2),
                    width: px(156),
                  }}
                >
                  <Button onClick={() => setShowAllEventsCards(true)}>Показать все</Button>
                </div>
              )}
            </div>
          </section>
          <section className="home__ad home__ad--2">
            <div className="container ">
              <Link to="/" target="_blank" className="pointer-events-none">
                <img src={ad7Image} className="home__ad-image" alt="home-ad-2" />
              </Link>
            </div>
          </section>
          {/* <section
            style={{
              paddingTop: px(10),
              paddingBottom: px(20),
              backgroundColor: 'rgba(11, 131, 254, 0.3)',
            }}
          >
            <div className="container">
              <h2
                style={{
                  marginBottom: px(20),
                }}
                className="h1"
              >
                Соревнования
              </h2>
              <div
                className={`row ${cards?.length >= 7 && 'events-cards-container'} ${
                  showAllCompetitionsCards && 'show-all'
                }`}
              >
                <CompetitionCards
                  setShowAllEventsCards={setShowAllCompetitionsCards}
                  showAllEventsCards={showAllCompetitionsCards}
                  competitions
                />
              </div>
              {!showAllCompetitionsCards && competitionCabinets?.length > 7 && (
                <div
                  className="m-auto cursor-pointer whitespace-nowrap"
                  style={{
                    marginTop: px(2),
                    width: px(156),
                  }}
                >
                  <Button onClick={() => setShowAllCompetitionsCards(true)}>Показать все</Button>
                </div>
              )}
            </div>
          </section>

          <section
            style={{
              paddingTop: px(10),
              paddingBottom: px(20),
              backgroundColor: 'rgba(11, 131, 254, 0.3)',
            }}
          >
            <div className="container">
              <h2
                style={{
                  marginBottom: px(20),
                }}
                className="h1"
              >
                Туры. Гиды
              </h2>
              <div
                className={`row ${cards?.length >= 7 && 'events-cards-container'} ${
                  showAllGuidesCards && 'show-all'
                }`}
              >
                <GuideCards
                  setShowAllEventsCards={setShowAllGuidesCards}
                  showAllEventsCards={showAllGuidesCards}
                />
              </div>
              {!showAllGuidesCards && guideCabinets?.length > 7 && (
                <div
                  className="m-auto cursor-pointer whitespace-nowrap"
                  style={{
                    marginTop: px(2),
                    width: px(156),
                  }}
                >
                  <Button onClick={() => setShowAllGuidesCards(true)}>Показать все</Button>
                </div>
              )}
            </div>
          </section> */}

          <section
            style={{
              paddingTop: px(10),
              paddingBottom: px(20),
              backgroundColor: 'rgba(11, 131, 254, 0.3)',
            }}
          >
            <div className="container ">
              <p
                style={{
                  marginBottom: px(10),
                  fontSize: px(20),
                  lineHeight: px(25),
                }}
                className="text-center font-normal"
              >
                <strong>
                  Подпишитесь на нашу
                  <br />
                  рассылку
                </strong>
              </p>
              <p
                className="text-center font-light"
                style={{
                  fontSize: px(20),
                  lineHeight: px(25),
                }}
              >
                Следите за последними новостями о компаниях и продуктах из мира рыбалки
                <br />и получайте вознаграждения
              </p>
              <form onSubmit={handleSubmit}>
                <Input
                  style={{
                    marginTop: px(10),
                    marginBottom: px(25),
                  }}
                  className={`${!isValidEmail && 'error'}`}
                  placeholder="E-mail"
                  onChange={e => handleChangeEmail(e.target.value)}
                  value={subscription}
                />
                <div className="flex flex-row">
                  <Checkbox
                    onChange={e => setIsChecked(e.target.checked)}
                    checked={isChecked}
                    isChecked={isChecked}
                    style={{
                      marginBottom: px(20),
                    }}
                  />
                  <p
                    className={`${isChecked && 'text-[#0A84FE]'}`}
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                    }}
                  >
                    Принимаю условия
                    <br />
                    <a
                      href="/privacy"
                      style={{
                        fontSize: px(15),
                        lineHeight: px(20),
                      }}
                    >
                      <u
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                        }}
                        className={` ${isChecked ? 'text-[#0A84FE]' : 'text-[#000]'}`}
                      >{`политики конфиденциальности\n`}</u>
                    </a>
                    и<br />
                    даю согласие на обработку персональных данных
                  </p>
                </div>

                <div
                  className="m-auto"
                  style={{
                    width: px(183),
                  }}
                >
                  <Button
                    aria-hidden
                    marginTop={20}
                    bgColorHover="#0a84fe"
                    type="submit"
                    disabled={!isChecked || !isValidEmail || !subscription}
                    className="flex flex-row "
                    style={{
                      gap: px(9.6),
                    }}
                  >
                    <Icon
                      type={TypesIcon.MAILSUBSCRIBE}
                      width={px(19.393)}
                      height={px(12.929)}
                      viewBox="0 0 19.393 12.929"
                    />
                    Подписаться
                  </Button>
                </div>
              </form>
              {showSubscriptionPopup && (
                <Popup
                  data={{
                    single: true,
                    title: 'Спасибо за подписку на рассылку',
                    buttonLeftText: 'Ок',
                  }}
                  onClose={() => setShowSubscriptionPopup(false)}
                />
              )}
            </div>
          </section>
          <section className="home__ad home__ad--3 ip-0 cursor-pointer">
            <img src={ad8Image} className="home__ad-image" alt="home-ad-3" />
          </section>
        </main>
        <Footer />
        <ScrollUpToTopArrow
          scrollActive={scrollActiveBottom}
          handleScrollToTop={handleScrollToTop}
        />
        <ScrollButtonsContainer>
          <CircleButton
            onClick={() => {
              navigate('/filter');
            }}
            icon={{
              type: TypesIcon.SETTINGSFILTER,
              viewBox: '0 0 20 20',
              width: px(20),
              height: px(20),
              fill: '#ffffff',
              stroke: '#0a84fe',
            }}
          />
          <CircleButton
            onClick={() => {
              navigate('/map');
            }}
          >
            <img src={mapBtnImg} alt="action-map-button" className="map-btn-img " />
          </CircleButton>
        </ScrollButtonsContainer>
        <Modal show={isVideoModalOpen} onClose={() => setIsVideoModalOpen(false)}>
          <div className="flex h-full flex-col items-center justify-center">
            <button
              type="button"
              className="play-button flex items-center justify-center"
              onClick={() => setShowVideo(true)}
              style={{
                fontSize: '4rem',
                width: px(50),
                height: px(50),
                borderRadius: '50%',
                border: '1px solid #00000029',
                boxShadow: '2px 4px 6px 0 #00000029',
              }}
            >
              <div className="flex h-full w-full items-center justify-center">
                <LogoIcon
                  style={{
                    width: px(23),
                    height: px(26),
                    margin: 0,
                    padding: 0,
                  }}
                />
              </div>
            </button>
          </div>
        </Modal>
        {showAdPopup && (
          <DraggableModal setShowAdPopup={setShowAdPopup} toggleLockClass={toggleLockClass}>
            <div className="home__ad-container-overflow">
              <div className="home__ad home__ad--5">
                <div className="container">
                  <Link to="/" target="_blank">
                    <img src={ad5Image} className="home__ad-image" alt="home-ad-1" />
                  </Link>
                </div>
              </div>
            </div>
          </DraggableModal>
        )}
        {showVideo && (
          <video
            ref={videoRef}
            onEnded={() => {
              videoRef?.current?.webkitExitFullScreen();
              setShowVideo(false);
            }}
            autoPlay
            playsInline
            onLoadedData={e => {
              e.target.webkitEnterFullscreen();
              if (!e.target.playing) e.target.play();
              setIsVideoModalOpen(false);

              const handleFullscreen = () => {
                if (
                  !document.fullscreen &&
                  !document.mozFullScreen &&
                  !document.webkitIsFullScreen
                ) {
                  e.target.pause();
                  e.target.currentTime = 0;
                  setShowVideo(false);
                }
              };

              e.target?.addEventListener?.('fullscreenchange', handleFullscreen);
              e.target?.addEventListener?.('mozfullscreenchange', handleFullscreen);
              e.target?.addEventListener?.('webkitfullscreenchange', handleFullscreen);
              e.target?.addEventListener?.('webkitendfullscreen', handleFullscreen);
            }}
            controls
          >
            <source src="/home-video.mp4" type="video/mp4" />
          </video>
        )}
      </div>
    );
};
