import * as profileAdminApi from '@/api/event-admin';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventAdmins } from './get-admins';


export const DELETE_EVENT_ADMIN = 'profile-admin/deleteEventAdmin';

const onSuccess = () => async (dispatch) => {
  dispatch(
    getEventAdmins()
  );
};

export const deleteEventAdmin = createAsyncThunk(
  DELETE_EVENT_ADMIN,
  createRequest({
    type: DELETE_EVENT_ADMIN,
    loader: (data) => profileAdminApi.deleteEventAdmin(data),
    onSuccess,
  }),
);
