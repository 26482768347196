import { request } from '../../helpers/api/request';

export const updatePrice = async ({ data, id }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `price-rate/${id}`,
    method: 'PUT',
    data,
  });

  return response;
};
