import React from 'react';

export const IceText = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 14" {...props}>
    <g id="Сгруппировать_4172" data-name="Сгруппировать 4172" transform="translate(-109 -679)">
      <g
        id="Сгруппировать_4170"
        data-name="Сгруппировать 4170"
        transform="translate(-129.309 -44.068)"
      >
        <rect
          id="Прямоугольник_991"
          data-name="Прямоугольник 991"
          width="24"
          height="14"
          transform="translate(238.309 723.068)"
          fill="#0a84fe"
        />
        <g
          id="Личная_информация"
          data-name="Личная информация"
          transform="translate(85.309 482.068)"
        >
          <g id="Компонент_4_1" data-name="Компонент 4 – 1" transform="translate(155.195 243.187)">
            <path
              id="Контур_13534"
              data-name="Контур 13534"
              d="M6.416-7.109V0H4.946V-5.923H3.052l-.117,2.51a7.817,7.817,0,0,1-.322,2A2.083,2.083,0,0,1,1.885-.344,2.25,2.25,0,0,1,.576,0H.2V-1.177L.439-1.2a.958.958,0,0,0,.789-.637,6.585,6.585,0,0,0,.3-2.009L1.65-7.109Zm5.557,4.028H9.16v1.9h3.3V0H7.7V-7.109h4.756v1.187H9.16v1.694h2.813ZM9.648-8.193a.575.575,0,0,1-.19.437.686.686,0,0,1-.488.178.67.67,0,0,1-.488-.181A.585.585,0,0,1,8.3-8.193a.593.593,0,0,1,.186-.437.665.665,0,0,1,.488-.183.673.673,0,0,1,.491.183A.589.589,0,0,1,9.648-8.193Zm1.025,0a.571.571,0,0,1,.2-.442.693.693,0,0,1,.481-.178.676.676,0,0,1,.481.181.58.58,0,0,1,.193.439.593.593,0,0,1-.186.437.665.665,0,0,1-.488.183.673.673,0,0,1-.491-.183A.589.589,0,0,1,10.674-8.193Zm9.238,9.941H18.56V0H14.272V1.743H12.886l-.078-2.92h.537a2.3,2.3,0,0,0,.671-1.062,7.326,7.326,0,0,0,.32-1.736l.205-3.135H19.2v5.933h.83ZM15.024-1.177h2.71V-5.923h-1.8l-.122,1.87A6.27,6.27,0,0,1,15.024-1.177Z"
              transform="translate(-0.195 8.813)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IceText;
