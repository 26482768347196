import { DatePicker, Main } from '../../components';
import Switch from '../../components/switch';
import { px } from '../../constants/Px';
import { NotificationSendingTimePageDataDays, timeDateSendingNotification } from '../../constants/Component';
import { useState } from 'react';

export const NotificationSendingTime = () => {
  const [everyDayToggle, setEveryDayToggle] = useState(false);
  const [workingDaysToggle, setWorkingDaysToggle] = useState(false);
  const [weekendDayToggle, setWeekendDaysToggle] = useState(false);

  const dataTime=[
    {id:1,title:'Начало'},
    {id:2,title:'Окончание'}
  ]

  const date = new Date();
  const hour = date?.getHours();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [time, setTime] = useState({
    hour: `${hour}:00` || '1',
  });
  const [endTime, setEndTime] = useState({
    hour: `${hour}:00` || '1',
  });
  const openPicker = (value) => {
    setId(value);
    // setShow(true);
  };
  return (
    <Main title='Время рассылки' >
      <DatePicker
        singleItem={true}
        title='Время рассылки'
        setShow={setShow}
        show={show}
        data={timeDateSendingNotification }
        setPickerValue={
             id===1?setTime:setEndTime
        }
        pickerValue={
         id===1?time:endTime
        }
        buttonTitle='Применить'
        isLine
      />
      <div className='flex flex-column ' style={{
        paddingTop:px(33)
      }}>

        {NotificationSendingTimePageDataDays.map((item)=>{
          return (<div className='flex flex-row justify-between items-center' style={{
            paddingBottom:item?.id===3?px(22):px(39)
          }}><p
            className='font-normal'
            style={{
            fontSize:px(20),
            lineHeight:px(26)
          }}>{item.day}</p><div style={{
            width:px(51),
            height:px(31)
          }}><Switch toggle={item?.id===1?everyDayToggle:(item?.id===2?workingDaysToggle:weekendDayToggle)}
                     setToggle={item?.id===1?setEveryDayToggle:(item?.id===2?setWorkingDaysToggle:setWeekendDaysToggle)}  /></div></div>)
        })}
        <div className='flex flex-row justify-between items-center'>
          {dataTime.map((item)=>{
            return(
              <div className='flex flex-column'>
                <p className='opacity-50 font-normal' style={{
                  fontSize:px(15),
                  lineHeight:px(20),
                  paddingBottom:px(13)
                }}>{item.title}</p>
                <p
                  onClick={() => {
                    openPicker(item.id === 1 ? 1 : 2);
                  }}
                  className='font-normal'
                  style={{
                  fontSize:px(20),
                  lineHeight:px(26),
                  paddingBottom:px(5)
                }}>{item.id===1?time.hour:endTime.hour}</p>
                <hr style={{
                  width:px(125)
                }}/>
              </div>)
          })}
        </div>
      </div>
    </Main>
  );
};
