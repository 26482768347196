import React, { useEffect, useState } from 'react';

import { Checkbox } from '../../../../../../../components/checkbox';
import { Modal } from '../../../../../../shared';
import { px } from '../../../../../../utils';

export const SortModal = ({ options, onChange, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (onChange) onChange(selectedOption);
  }, [selectedOption]);

  return (
    <Modal {...props}>
      <div
        style={{
          marginTop: px(15),
          gap: px(39),
          paddingLeft: px(15),
          paddingRight: px(15),
        }}
        className="flex flex-col"
      >
        {options?.map(item => (
          <div key={item?.id} className="flex items-center justify-between">
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
              className="font-normal"
            >
              {item?.title}
            </p>
            <Checkbox
              margin
              checked={selectedOption?.id === item?.id}
              value={item?.value}
              onChange={() => setSelectedOption(item)}
              style={{
                margin: '0px',
              }}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default SortModal;
