import React, { useContext } from 'react';

import { px } from '../constants/Px';
import { StateContext } from './state-provider';

export const ModalHeader = ({ title, isLine, onPointerDown }) => {
  const { dragControls } = useContext(StateContext);
  return (
    <div
      style={{
        paddingTop: px(2),
      }}
      className="flex flex-col justify-center"
      onPointerDown={onPointerDown}
    >
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          paddingBottom: px(12),
        }}
        className="text-center font-light"
        onPointerDown={event => {
          dragControls.start(event);
        }}
      >
        {title || ''}
      </p>
      {isLine && <hr className="w-full text-[#000] opacity-30" />}
    </div>
  );
};

export default ModalHeader;
