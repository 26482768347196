import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useEffect } from 'react';
import { px } from '../../../../constants/Px';
import { TypesIcon } from '../../../../types/TypesIcon';
import { Banner } from '../../../../components/banner';
import { Icon } from '../../../../components/Icon';

const ObjectFeedbackHeader = ({
    
  title,
  children,
  navigatePatch,
  light,
  center,
  lineHeight,
  fontSize,
  paddingBottom,
  withId,
  withBanner,
  marginTop,
  objectId
}) => {

const navigete = useNavigate();
  const { pathname } = useLocation();

  const handleNavigateBack = () => {
    navigete(navigatePatch || -1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <div className="global-container">
      <main>
        <div style={{ paddingBottom: paddingBottom && px(80) }}>
          <div className="w-full " style={{ height: px(60) }}>
            {withId && (
              <div
                style={{ top: px(22) }}
                className="absolute left-0 right-0 flex flex-row items-center justify-center"
              >
                <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
               ID: { objectId}
                </p>
              </div>
            )}
            {withBanner && <div style={{marginTop:px(59)}}> <Banner/></div>}
            
            <div
              className=" absolute top-0"
              style={{
                height: px(60),
                width: px(60),
                right: px(0),
              }}
              onClick={handleNavigateBack}
              onKeyDown={handleNavigateBack}
            >
              <div
                style={{
                  marginTop: px(19),
                  marginRight:px(28),
                 
                }}
                className=" flex cursor-pointer  justify-end bg-[#fff] justify-end"
              >
               
                  <Icon type={TypesIcon.CLOSE} viewBox="0 0 17 17" width={px(17)} height={px(17)} />
            
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: `${marginTop || 0}`,
              padding: `${ px(10)} ${px(15)} ${px(18)} ${px(15)}`,
            }}
          >
           
                <p
                  style={{
                    fontSize: `${px(fontSize || 32)}`,
                    lineHeight: ` ${px(lineHeight || 43)}`,
                   
                  }}
                  className={`${light ? 'font-light' : 'font-normal'} text-[#000000] ${
                    center && 'text-center'
                  } whitespace-pre-wrap `}
                >
                  {title}
                </p>
            
            {children}
          </div>
        </div>
      </main>
    </div>
    );
};

export default ObjectFeedbackHeader;