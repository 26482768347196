/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_COMPETITION_ADMIN = 'competition/deleteCompetitionAdmin';

/* const onSuccess = () => async (dispatch) => {
  dispatch(
    getEventAdmins()
  );
}; */

export const deleteCompetitionAdmin = createAsyncThunk(
  DELETE_COMPETITION_ADMIN,
  createRequest({
    type: DELETE_COMPETITION_ADMIN,
    loader: data => profileAdminApi.deleteCompetitionAdmin(data),
    /* onSuccess, */
  }),
);
