import React, { useLayoutEffect, useState } from 'react';

import { capitalizeFirstLetter, px } from '../../utils';

export const Textarea = ({
  title,
  placeholder,
  style,
  light,
  value: defaultValue,
  onChange,
  textareaHight,
  noEnter,
  maxLength,
}) => {
  const [value, setValue] = useState();

  useLayoutEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="flex flex-col">
      <div className="w-full border-[1px] border-solid border-[rgb(0,0,0,0.3)]" style={style}>
        {title && (
          <p className="font-normal opacity-50" style={{ fontSize: px(20), lineHeight: px(26) }}>
            {title}
          </p>
        )}
        <textarea
          maxLength={maxLength}
          value={value?.toString()?.charAt(0)?.toUpperCase() +
            value?.toString()?.slice(1)}
          onChange={event => {
            setValue(capitalizeFirstLetter(event?.target?.value));
            if (onChange) onChange(event?.target?.value);
          }}
          onKeyDown={evt => {
            const { key } = evt;
            if (noEnter && key === 'Enter') evt.preventDefault();
          }}
          placeholder={placeholder}
          className={`w-full flex-grow outline-none focus:outline-none ${
            light ? 'font-light' : 'font-normal'
          }  resize-none placeholder:text-[rgb(0,0,0,0.5)]`}
          style={{ fontSize: px(20), lineHeight: px(26), height: textareaHight }}
        />
      </div>
    </div>
  );
};

export default Textarea;
