import { request } from '@/helpers/api/request';

export const getObjectFeedback = async (data) => {
  const response = await request({
    url: `event-review`,
    method: 'GET',
    params: { eventId: data.id },
  });
  return response;
};
