import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import ad9Image from '../assets/images/ads/9.png';
import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { StateContext } from './state-provider';
import { ad8Image } from '@assets/images/ads/8.png';

export const HomeAddPopup = ({ children, search, path, book }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setShowSearch } = useContext(StateContext);

  const handleNavigate = () => {
    navigate(path || -1);
  };

  const handleNavigateSearch = () => {
    navigate(-1);
    setShowSearch(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="home-add-popup-container">
      <div className="home-add-popup-container-overflow">
        <div className="home-add-popup-content">
          <div className="search-menu-item-preferences-close" onClick={handleNavigate}>
            <Icon type={TypesIcon.CLOSE} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
          </div>

          <div className="home-add-popup-content-photo-text">
            <div className="home__ad home__ad--4">
              <Link to="/" target="_blank" className="pointer-events-none">
                <img src={ad9Image} className="home__ad-image" alt="home-ad-4" />
              </Link>
            </div>
            <div
              className={`${
                book ? 'home-add-popup-content-book' : 'home-add-popup-content-desc line-clamp-3'
              }`}
            >
              {children}
            </div>

            <div className="home-add-popup-search-btn-container">
              {search && (
                <CircleButton
                  onClick={handleNavigateSearch}
                  icon={{
                    type: TypesIcon.SEARCH,
                    viewBox: '0 0 22.357 22.357',
                    width: px(22.357),
                    height: px(22.357),
                    fill: '#0a84fe',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
