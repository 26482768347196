import React, { useMemo, useState } from 'react';

import { px } from '../../../../../constants/Px';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { CircleButton, Dropdown } from '../../../../shared';
import { useProfile } from '../../../hooks';
import { sortOptions } from '../../constants/sort-options';
import { mapSectorToFields } from '../../helpers/sector.helpers';
import { Sector, SectorForm, SortModal } from './components';

export const FishingMenu = () => {
  const {
    data: { locations, selectedLocationId },
    setData: setProfile,
  } = useProfile();

  const [showSectorForm, setShowSectorForm] = useState(false);
  const [currentSector, setCurrentSector] = useState();
  const [showSortModal, setShowSortModal] = useState(false);
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  const selectedLocation = useMemo(
    () =>
      locations?.find(location => location.id === selectedLocationId) ||
      locations?.find(location => location.type === 'REST'),
    [JSON.stringify(locations), selectedLocationId],
  );

  const { sections: sectors, type: locationCategory } = selectedLocation;

  const sortedSectors = useMemo(
    () => sectors?.sort(sortOption?.cb),
    [sortOption, selectedLocation],
  );

  return (
    <div className="relative h-full w-full overflow-y-scroll">
      <div
        className="flex-column absolute  top-0 flex items-center justify-center"
        style={{ width: '100%' }}
      >
        <p
          className="font-light"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingTop: px(2),
            paddingBottom: px(12),
          }}
        >
          {locationCategory === 'FISHING'
            ? 'Рыбалка'
            : locationCategory === 'REST'
              ? 'Отдых'
              : 'Услуги'}
        </p>
        <div
          style={{
            paddingRight: px(15),
            paddingLeft: px(15),
            paddingBottom: px(80),
            width: '100%',
          }}
        >
          <div
            style={{
              width: px(290),
              paddingBottom: locationCategory === 'FISHING' && px(7),
            }}
          >
            <Dropdown
              items={locations?.map(({ name: label, id: value }) => ({ label, value }))}
              // eslint-disable-next-line unicorn/consistent-destructuring
              value={selectedLocation?.id}
              onChange={id =>
                setProfile({ selectedLocationId: locations?.find(i => i?.id === id)?.id })
              }
              style={{
                paddingTop: px(12),
              }}
            />
          </div>
          {!showSectorForm && (
            <div
              style={{
                paddingTop: px(20),
                gap: px(10),
                paddingBottom: px(80),
              }}
              className="flex flex-wrap"
            >
              {sortedSectors?.map(item => (
                <Sector
                  id={item?.id}
                  key={item?.id}
                  sector={mapSectorToFields(item, locations, selectedLocationId)}
                  onClick={() => {
                    setCurrentSector(item);
                    setShowSectorForm(true);
                  }}
                />
              ))}
            </div>
          )}

          {showSectorForm && (
            <SectorForm sector={currentSector} onClose={() => setShowSectorForm(false)} />
          )}
        </div>
      </div>
      {!showSectorForm && (
        <div
          className="fixed flex w-full max-w-[768px] flex-row items-center justify-center"
          style={{
            bottom: px(54),
            gap: px(10),
          }}
        >
          <CircleButton
            onClick={async () => {
              await setCurrentSector();
              setShowSectorForm(true);
            }}
            icon={{
              type: TypesIcon.PLUS,
              viewBox: '0 0 19.785 19.785',
              width: px(19.785),
              height: px(19.785),
              fill: '#0A84FE',
            }}
          />
          {sortedSectors.length > 0 && (
            <CircleButton
              onClick={() => setShowSortModal(true)}
              icon={{
                type: TypesIcon.FILTER,
                viewBox: '0 0 25 24',
                width: px(25),
                height: px(24),
                fill: '#0A84FE',
              }}
              className="text-[#0A84FE]"
            />
          )}
        </div>
      )}

      <SortModal
        show={showSortModal}
        onClose={() => setShowSortModal(false)}
        options={sortOptions}
        onChange={option => {
          setSortOption(option);
          setShowSortModal(false);
        }}
      />
    </div>
  );
};
export default FishingMenu;
