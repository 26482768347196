import { px } from '../../Px';

export const iconSectors = [
  {
    id: 1,
    type: 'FREEZONE',
    viewBox: '0 0 41 41.519',
    width: px(41),
    height: px(41.519),
  },
  {
    id: 2,
    type: 'SECTOR',
    viewBox: '0 0 30.305 28.63',
    width: px(30.305),
    height: px(28.63),
  },
  {
    id: 3,
    type: 'BUILDING',
    viewBox: '0 0 34.013 26.71',
    width: px(34.013),
    height: px(26.71),
  },
  {
    id: 4,
    type: 'LOGHOUSE',
    viewBox: '0 0 26.596 29.892',
    width: px(26.596),
    height: px(29.892),
  },
];

export const sortValues = [
  {
    id: 1,
    title: 'По умолчанию',
    value: 'default',
    cb: () => {},
  },
  {
    id: 2,
    title: 'Последовательно',
    value: 'byId',
    cb: (a, b) => b.id - a.id,
  },
  {
    id: 3,
    title: 'По группам ( А - Z )',
    value: 'byGroup',
    cb: (a, b) => b.sectionGroupId - a.sectionGroupId,
  },
  {
    id: 4,
    title: 'По цене',
    value: 'sequentially',
    cb: () => {},
  },
  {
    id: 5,
    title: 'Сначала Скидки',
    value: 'new',
    cb: () => {},
  },
  {
    id: 6,
    title: 'Сначала Акции',
    value: 'blocked',
    cb: () => {},
  },
];
