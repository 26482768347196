import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';

export const GET_PROFILE_BY_ID = 'profile/getProfileById';

export const getProfileById = createAsyncThunk(
    GET_PROFILE_BY_ID,
    createRequest({
        type: GET_PROFILE_BY_ID,
        loader: (data) => authApi.getProfileById(data),
    }),
);
