import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';

export const FIND_CARD = 'card/findCard';

export const findCard = createAsyncThunk(
  FIND_CARD,
  createRequest({
    type: FIND_CARD,
    loader: data => authApi.findCard(data),
  }),
);
