import React from 'react';

export const LureIcon = props => (
  <svg
   viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.5" cx="6.5" cy="8.5" r="3.5" fill="currentColor" />
    <circle opacity="0.5" cx="11.5" cy="2.5" r="2.5" fill="currentColor" />
    <circle opacity="0.5" cx="11" cy="15" r="2" fill="currentColor" />
    <circle opacity="0.5" cx="25.5" cy="10.5" r="1.5" fill="currentColor" />
    <circle opacity="0.5" cx="4" cy="20" r="3.5" stroke="currentColor" />
    <circle opacity="0.5" cx="20" cy="19" r="5" fill="currentColor" />
    <circle opacity="0.5" cx="16.5" cy="8.5" r="3" stroke="currentColor" />
  </svg>
);
