import React from 'react';

export const FreeZoneIcon = props => (
  <svg
    viewBox="0 0 39 47"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4031_1815)">
      <path
        d="M0.894886 30.2939V29.3991L6.38352 21.2059H0.835227V20.1121H7.85511V21.007L2.36648 29.2002H7.91477V30.2939H0.894886ZM18.0953 25.203C18.0953 26.2769 17.9015 27.2049 17.5137 27.9871C17.1259 28.7693 16.5939 29.3725 15.9178 29.7968C15.2417 30.221 14.4694 30.4331 13.601 30.4331C12.7327 30.4331 11.9604 30.221 11.2843 29.7968C10.6081 29.3725 10.0762 28.7693 9.68839 27.9871C9.3006 27.2049 9.10671 26.2769 9.10671 25.203C9.10671 24.1292 9.3006 23.2011 9.68839 22.4189C10.0762 21.6367 10.6081 21.0335 11.2843 20.6093C11.9604 20.185 12.7327 19.9729 13.601 19.9729C14.4694 19.9729 15.2417 20.185 15.9178 20.6093C16.5939 21.0335 17.1259 21.6367 17.5137 22.4189C17.9015 23.2011 18.0953 24.1292 18.0953 25.203ZM16.9022 25.203C16.9022 24.3214 16.7547 23.5773 16.4597 22.9708C16.168 22.3643 15.772 21.9052 15.2715 21.5937C14.7743 21.2821 14.2175 21.1263 13.601 21.1263C12.9846 21.1263 12.4261 21.2821 11.9256 21.5937C11.4284 21.9052 11.0324 22.3643 10.7374 22.9708C10.4457 23.5773 10.2999 24.3214 10.2999 25.203C10.2999 26.0847 10.4457 26.8287 10.7374 27.4353C11.0324 28.0418 11.4284 28.5009 11.9256 28.8124C12.4261 29.124 12.9846 29.2797 13.601 29.2797C14.2175 29.2797 14.7743 29.124 15.2715 28.8124C15.772 28.5009 16.168 28.0418 16.4597 27.4353C16.7547 26.8287 16.9022 26.0847 16.9022 25.203ZM28.2424 20.1121V30.2939H27.0492L21.5009 22.2996H21.4015V30.2939H20.1685V20.1121H21.3617L26.9299 28.1263H27.0293V20.1121H28.2424ZM30.7095 30.2939V20.1121H36.8544V21.2059H31.9425V24.6462H36.5362V25.74H31.9425V29.2002H36.9339V30.2939H30.7095Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
      <path
        d="M1.23295 15.5V5.31818H7.33807V6.41193H2.46591V9.85227H6.88068V10.946H2.46591V15.5H1.23295ZM9.44975 15.5V5.31818H12.8901C13.6855 5.31818 14.3385 5.45407 14.8489 5.72585C15.3593 5.99432 15.7372 6.36387 15.9824 6.83452C16.2277 7.30516 16.3503 7.84044 16.3503 8.44034C16.3503 9.04025 16.2277 9.57221 15.9824 10.0362C15.7372 10.5002 15.361 10.8648 14.8539 11.13C14.3468 11.3918 13.6988 11.5227 12.91 11.5227H10.1259V10.4091H12.8702C13.4138 10.4091 13.8513 10.3295 14.1827 10.1705C14.5175 10.0114 14.7594 9.78598 14.9086 9.49432C15.061 9.19934 15.1373 8.84801 15.1373 8.44034C15.1373 8.03267 15.061 7.67637 14.9086 7.37145C14.7561 7.06652 14.5125 6.8312 14.1777 6.66548C13.843 6.49645 13.4005 6.41193 12.8503 6.41193H10.6827V15.5H9.44975ZM14.2424 10.9261L16.748 15.5H15.3162L12.8503 10.9261H14.2424ZM18.4048 15.5V5.31818H24.5497V6.41193H19.6378V9.85227H24.2315V10.946H19.6378V14.4062H24.6293V15.5H18.4048ZM26.772 15.5V5.31818H32.9169V6.41193H28.005V9.85227H32.5987V10.946H28.005V14.4062H32.9964V15.5H26.772Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
      <g opacity="0.5">
        <path d="M38.2364 41.467H0.607422V46.019H38.2364V41.467Z" fill="white" />
        <path d="M37.7364 41.967H1.10742V45.519H37.7364V41.967Z" stroke="white" />
      </g>
      <g opacity="0.5">
        <path
          d="M32.6691 38.717H27.7371C27.3969 38.717 27.1211 38.9928 27.1211 39.333V39.334C27.1211 39.6742 27.3969 39.95 27.7371 39.95H32.6691C33.0093 39.95 33.2851 39.6742 33.2851 39.334V39.333C33.2851 38.9928 33.0093 38.717 32.6691 38.717Z"
          fill="currentColor"
        />
        <path
          d="M32.6691 39.217H27.7371C27.673 39.217 27.6211 39.269 27.6211 39.333V39.334C27.6211 39.3981 27.673 39.45 27.7371 39.45H32.6691C32.7332 39.45 32.7851 39.3981 32.7851 39.334V39.333C32.7851 39.269 32.7332 39.217 32.6691 39.217Z"
          stroke="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M37.0067 34.2518L33.2456 38.0129C33.0992 38.1594 33.099 38.3966 33.2453 38.5429C33.3915 38.6891 33.6288 38.689 33.7753 38.5425L37.5364 34.7814C37.6828 34.635 37.683 34.3977 37.5367 34.2515C37.3905 34.1052 37.1532 34.1054 37.0067 34.2518Z"
          fill="currentColor"
        />
        <mask
          id="mask0_4031_1815"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="33"
          y="34"
          width="5"
          height="5"
        >
          <path
            d="M37.0067 34.2518L33.2456 38.0129C33.0992 38.1594 33.099 38.3966 33.2453 38.5429C33.3915 38.6891 33.6288 38.689 33.7753 38.5425L37.5364 34.7814C37.6828 34.635 37.683 34.3977 37.5367 34.2515C37.3905 34.1052 37.1532 34.1054 37.0067 34.2518Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask0_4031_1815)">
          <path
            d="M33.1567 38.4543L37.4482 34.1628M36.9184 34.3401L37.4482 34.8699M37.6256 34.3401L33.334 38.6316M33.8638 38.4543L33.334 37.9245"
            stroke="currentColor"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          d="M27.7371 41.181C28.0773 41.181 28.3531 40.9052 28.3531 40.565C28.3531 40.2248 28.0773 39.949 27.7371 39.949C27.3969 39.949 27.1211 40.2248 27.1211 40.565C27.1211 40.9052 27.3969 41.181 27.7371 41.181Z"
          fill="currentColor"
        />
        <path
          d="M27.7371 40.681C27.8012 40.681 27.8531 40.629 27.8531 40.565C27.8531 40.5009 27.8012 40.449 27.7371 40.449C27.673 40.449 27.6211 40.5009 27.6211 40.565C27.6211 40.629 27.673 40.681 27.7371 40.681Z"
          stroke="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M32.6687 41.181C33.0089 41.181 33.2847 40.9052 33.2847 40.565C33.2847 40.2248 33.0089 39.949 32.6687 39.949C32.3285 39.949 32.0527 40.2248 32.0527 40.565C32.0527 40.9052 32.3285 41.181 32.6687 41.181Z"
          fill="currentColor"
        />
        <path
          d="M32.6687 40.681C32.7328 40.681 32.7847 40.629 32.7847 40.565C32.7847 40.5009 32.7328 40.449 32.6687 40.449C32.6047 40.449 32.5527 40.5009 32.5527 40.565C32.5527 40.629 32.6047 40.681 32.6687 40.681Z"
          stroke="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M19.6656 40.6749L0.856584 34.5635C0.758887 34.5317 0.653954 34.5852 0.62221 34.6829C0.590467 34.7806 0.643932 34.8855 0.74163 34.9173L19.5507 41.0287C19.6484 41.0604 19.7533 41.007 19.785 40.9093C19.8168 40.8116 19.7633 40.7066 19.6656 40.6749Z"
          fill="currentColor"
        />
        <mask
          id="mask1_4031_1815"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="34"
          width="20"
          height="8"
        >
          <path
            d="M19.6656 40.6749L0.856584 34.5635C0.758887 34.5317 0.653954 34.5852 0.62221 34.6829C0.590467 34.7806 0.643932 34.8855 0.74163 34.9173L19.5507 41.0287C19.6484 41.0604 19.7533 41.007 19.785 40.9093C19.8168 40.8116 19.7633 40.7066 19.6656 40.6749Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask1_4031_1815)">
          <path
            d="M0.71875 34.3844L19.8814 40.6107M19.3664 40.5777L19.2514 40.9317M19.6874 41.2078L0.524754 34.9815M1.03977 35.0144L1.15479 34.6604"
            stroke="currentColor"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          d="M16.6433 40.4489C16.7118 40.6378 16.7025 40.8462 16.6176 41.0283C16.5327 41.2104 16.379 41.3514 16.1903 41.4204C16.1569 41.4325 16.1229 41.4424 16.0883 41.4501C16.2139 41.3824 16.309 41.2694 16.3542 41.1341C16.3995 40.9988 16.3915 40.8513 16.3318 40.7217C16.2722 40.592 16.1653 40.49 16.0331 40.4364C15.9009 40.3827 15.7532 40.3815 15.6201 40.4329C15.4947 40.478 15.3895 40.5665 15.3235 40.6825C15.2576 40.7984 15.2353 40.934 15.2606 41.0649L15.2538 41.0461C15.2376 41.0222 15.2277 40.995 15.2178 40.9677C15.1794 40.8732 15.1605 40.7719 15.1622 40.67C15.1639 40.568 15.1861 40.4674 15.2275 40.3742C15.2689 40.281 15.3287 40.1971 15.4033 40.1275C15.4779 40.0579 15.5657 40.0041 15.6615 39.9692C15.7574 39.9343 15.8593 39.9191 15.9611 39.9245C16.063 39.9298 16.1627 39.9557 16.2543 40.0005C16.346 40.0452 16.4276 40.108 16.4945 40.1851C16.5613 40.2621 16.6119 40.3518 16.6433 40.4489Z"
          fill="currentColor"
        />
        <path
          d="M16.643 40.449C16.786 40.8419 16.5827 41.2775 16.19 41.4204C16.1578 41.4321 16.1233 41.4421 16.0876 41.4499C16.3287 41.3145 16.4357 41.0293 16.3407 40.7685C16.2348 40.4775 15.9115 40.3271 15.62 40.4332C15.3601 40.5277 15.2089 40.7932 15.2604 41.0647L15.2495 41.0468C15.2384 41.0211 15.2271 40.9948 15.2174 40.9679C15.0743 40.5748 15.2777 40.1378 15.6711 39.9947C16.0638 39.8517 16.4999 40.0559 16.643 40.449Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M12.0241 40.6774L8.73065 38.196C8.64857 38.1341 8.5319 38.1505 8.47006 38.2326C8.40822 38.3147 8.42462 38.4313 8.5067 38.4932L11.8002 40.9746C11.8823 41.0365 11.9989 41.0201 12.0608 40.938C12.1226 40.8559 12.1062 40.7392 12.0241 40.6774Z"
          fill="currentColor"
        />
        <mask
          id="mask2_4031_1815"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="38"
          width="5"
          height="4"
        >
          <path
            d="M12.0241 40.6774L8.73065 38.196C8.64857 38.1341 8.5319 38.1505 8.47006 38.2326C8.40822 38.3147 8.42462 38.4313 8.5067 38.4932L11.8002 40.9746C11.8823 41.0365 11.9989 41.0201 12.0608 40.938C12.1226 40.8559 12.1062 40.7392 12.0241 40.6774Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask2_4031_1815)">
          <path
            d="M8.6582 37.9819L12.2485 40.687M11.7721 40.4881L11.548 40.7855M11.8706 41.1886L8.28028 38.4835M8.7567 38.6824L8.98078 38.385"
            stroke="currentColor"
            strokeWidth="1.0004"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          d="M6.5833 40.9954L9.87678 38.5139C9.95885 38.4521 9.97526 38.3354 9.91342 38.2534C9.85158 38.1713 9.73491 38.1549 9.65283 38.2167L6.35935 40.6982C6.27728 40.76 6.26087 40.8767 6.32271 40.9588C6.38455 41.0408 6.50122 41.0572 6.5833 40.9954Z"
          fill="currentColor"
        />
        <mask
          id="mask3_4031_1815"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="38"
          width="4"
          height="4"
        >
          <path
            d="M6.5833 40.9954L9.87678 38.5139C9.95885 38.4521 9.97526 38.3354 9.91342 38.2534C9.85158 38.1713 9.73491 38.1549 9.65283 38.2167L6.35935 40.6982C6.27728 40.76 6.26087 40.8767 6.32271 40.9588C6.38455 41.0408 6.50122 41.0572 6.5833 40.9954Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask3_4031_1815)">
          <path
            d="M10.1016 38.5042L6.51116 41.2093M6.83368 40.8061L6.60966 40.5088M6.13318 40.7076L9.72357 38.0025M9.40106 38.4057L9.62507 38.703"
            stroke="currentColor"
            strokeWidth="1.0004"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4031_1815">
        <rect width="38.235" height="45.519" fill="currentColor" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
