import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Main } from '../../components';
import { Icon } from '../../components/Icon';
import { mobilePageLinks as mobile } from '../../constants/Component';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';

export const MobilePage = () => {
  const navigate = useNavigate();
  const activeId = useSelector(authActiveIdSelector);

  const activeType = useSelector(authActiveType);

  return (
    <Main
      title="Мобильная версия"
      light
      navigatePatch={`${
        activeId && activeId !== 100_000_000 && activeType !== 'administeredProfile'
          ? activeType === 'object' || activeType === 'administeredObject'
            ? '/user-object'
            : activeType === 'competition' || activeType === 'administeredCompetition'
              ? '/user-competition'
              : '/user-guide'
          : '/profile'
      }`}
    >
      <div
        style={{
          paddingTop: `${px(22)}`,
        }}
      >
        {mobile?.map(item => (
          <div
            className="flex flex-row items-center"
            style={{
              paddingBottom: item.paddingBottom,
            }}
            key={item.id}
            onClick={() => navigate(`/${item?.link}`)}
            onKeyDown={() => navigate(`/${item?.link}`)}
          >
            {item?.iconName && (
              <div
                style={{
                  width: `${px(58)}`,
                }}
              >
                <Icon
                  width={px(item.width)}
                  height={px(item.height)}
                  viewBox={item.viewBox}
                  type={item.iconName}
                />
              </div>
            )}
            <p
              className="w-full font-normal"
              style={{
                fontSize: `${px(20)}`,
                lineHeight: `${px(26)}`,
              }}
            >
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </Main>
  );
};
