import { motion } from 'framer-motion';
import React from 'react';

import { px } from '../constants/Px';

export const Switch = ({ className, toggle, setToggle, disabled, setShowAlert }) => (
  <motion.div
    layout
    layoutRoot
    style={{
      width: px(51),
      height: px(31),
      padding: px(2),
      paddingRight: px(2.5),
    }}
    className={`flex  cursor-pointer items-center rounded-full   transition-colors ${
      !disabled && toggle ? 'justify-end bg-[#39CA1C]' : 'justify-start bg-[lightgray] '
    } ${className || ''}`}
    onClick={() => {
      !disabled && setToggle(!toggle);
    }}
    aria-hidden
  >
    <motion.div
      layout="position"
      transition={{
        duration: 0.2,
      }}
      style={{
        width: px(27),
        height: px(27),
      }}
      className="rounded-full bg-white"
    />
  </motion.div>
);

export default Switch;
