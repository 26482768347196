import * as favouritesApi from '@/api/favourites';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFavourites } from './get-favourites';


export const DELETE_FAVOURITES = 'favourites/deleteFavourites';

const onSuccess = () => async (dispatch) => {
  dispatch(
    getFavourites()
  );
};

export const deleteFavourites = createAsyncThunk(
  DELETE_FAVOURITES,
  createRequest({
    type: DELETE_FAVOURITES,
    loader: (data) => favouritesApi.deleteFavourites(data),
    onSuccess,
  }),
);
