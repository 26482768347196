import { Main } from '../../components';
import { px } from '../../constants/Px';
import Switch from '../../components/switch';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export const NotificationPushCodes = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <Main title='Push-коды '>
      <div className='flex flex-column '>
        <p
          className='font-normal'
          style={{
            fontSize: px(15),
            lineHeight: px(20),
            paddingBottom: px(65),
          }}
        >
          Уведомления о бронировании, платежах и других операций
        </p>
        <div>
          <div className='flex flex-column '>
            <div
              className='flex flex-row items-center justify-between'
              style={{
                paddingBottom: px(36),
              }}
            >
              <h1
                className='font-normal'
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  paddingRight: px(111),
                }}
              >
                Устройства
              </h1>
              <Link to={'#'}>
                <p
                  className='text-[#0A84FE] font-normal'
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                >
                  Изменить
                </p>
              </Link>
            </div>
            <div className='flex flex-row  justify-between items-center'>
              <div className='flex flex-column'>
                <p
                  className='font-normal'
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                >
                  Iphone
                </p>
                <p
                  className='font-normal'
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                >
                  Текущее
                </p>
              </div>
              <div style={{
                width:px(51),
                height:px(31)
              }}>
                <Switch toggle={toggle} setToggle={setToggle}  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};
