/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React, { useEffect, useState } from 'react';

import competition from '../../assets/images/competition.png';
import {
  Button,
  CardInformation,
  Checkbox,
  DatePicker,
  DropdownList,
  FooterSlider,
  ObjectDescriptionTextarea,
} from '../../components';
import { DraggableModal } from '../../components/draggable-modal';
import { FishingMenu } from '../../components/fishing-menu';

export const Test = () => {
  const [showInformationForm, setShowInformationForm] = useState(false);
  const [hide, setHide] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);

  const competitionData = {
    place: '',
    organizator: '',
    CountOfMembers: '',
    prize: '',
    sumOfDepozit: '',
    ListOfMembers: '',
    registrationOrder: '',
    payment: '',
    reglament: '',
    program: '',
    tournamentType: [],
    membersType: [],
    rank: [],
    discipline: [],
    direction: [],
    fish: [],
  };

  const [object, setObject] = useState(competitionData);

  const {
    place,
    organizator,
    CountOfMembers,
    prize,
    sumOfDepozit,
    ListOfMembers,
    registrationOrder,
    payment,
    reglament,
    program,
    tournamentType,
    membersType,
    rank,
    discipline,
    direction,
    fish,
  } = competitionData;

  const setFields = field => {
    setObject({
      ...object,
      ...field,
    });
  };

  return (
    <>
      {!showInformationForm && (
        <div
          className="w-full"
          style={{
            paddingBottom: px(40),
            paddingTop: px(80),
            width: px(320),
          }}
        >
          <div style={{ marginBottom: px(17) }}>
            <p style={{ fontSize: px(32), lineHeight: px(43), textAlign: 'center' }}>Регистрация</p>
            <p style={{ fontSize: px(15), lineHeight: px(20), textAlign: 'center' }}>
              Добавьте соревнование
            </p>
          </div>

          {/* <div>
            <div className="relative" style={{ width: px(270), height: px(218) }}>
              <img
                src={competition}
                alt="card"
                style={{ width: px(270), height: px(218), objectFit: 'cover' }}
              />
              <span
                style={{
                  position: 'absolute',
                  top: px(105),
                  left: px(30),
                  color: 'white',
                  fontSize: px(32),
                  lineHeight: px(43),
                }}
              >
                0000
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: px(167),
                  left: px(12),
                  color: 'white',
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                000
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: px(167),
                  left: px(53),
                  color: 'white',
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                00
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: px(167),
                  left: px(90),
                  color: 'white',
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                00
              </span>
            </div>
          </div> */}
          <Swiper
            slidesPerView={1.1852}
            spaceBetween={10}
            cards
            onRealIndexChange={element => setActiveIndex(element.activeIndex)}
            centeredSlides
            modules={[Pagination]}
            pagination={{ clickable: true }}
            style={{ height: px(218) }}
          >
            {(data?.length === 0 ? [{}] : data)?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={index}>
                <div className="relative" style={{ width: px(270), height: px(218) }}>
                  <img
                    src={competition}
                    alt="card"
                    style={{ width: px(270), height: px(218), objectFit: 'cover' }}
                  />
                  <span
                    style={{
                      position: 'absolute',
                      top: px(105),
                      left: px(30),
                      color: 'white',
                      fontSize: px(32),
                      lineHeight: px(43),
                    }}
                  >
                    0000
                  </span>
                  <span
                    style={{
                      position: 'absolute',
                      top: px(167),
                      left: px(12),
                      color: 'white',
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                  >
                    000
                  </span>
                  <span
                    style={{
                      position: 'absolute',
                      top: px(167),
                      left: px(53),
                      color: 'white',
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                  >
                    00
                  </span>
                  <span
                    style={{
                      position: 'absolute',
                      top: px(167),
                      left: px(90),
                      color: 'white',
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                  >
                    00
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      {/* <Swiper
        slidesPerView={1.1852}
        spaceBetween={10}
        cards
        onRealIndexChange={element => setActiveIndex(element.activeIndex)}
        centeredSlides
        modules={[Pagination]}
        pagination={{ clickable: true }}
        style={{ height: px(218) }}
      >
        {(data?.length === 0 ? [{}] : data)?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={index}>
            <div className="relative" style={{ width: px(270), height: px(218) }}>
              <img
                src={competition}
                alt="card"
                style={{ width: px(270), height: px(218), objectFit: 'cover' }}
              />
              <span
                style={{
                  position: 'absolute',
                  top: px(105),
                  left: px(30),
                  color: 'white',
                  fontSize: px(32),
                  lineHeight: px(43),
                }}
              >
                0000
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: px(167),
                  left: px(12),
                  color: 'white',
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                000
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: px(167),
                  left: px(53),
                  color: 'white',
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                00
              </span>
              <span
                style={{
                  position: 'absolute',
                  top: px(167),
                  left: px(90),
                  color: 'white',
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                00
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper> */}

      {!showInformationForm && (
        <div
          className="fixed flex w-full flex-row items-center justify-center"
          style={{
            bottom: px(54),
            left: 0,
            gap: px(10),
            zIndex: 10,
          }}
        >
          {/* <RoundedButton bgShadow onClick={() => setShowInformationForm(true)}>
            <Icon
              type={TypesIcon.PLUS}
              width={px(19.785)}
              height={px(19.785)}
              viewBox="0 0 19.785 19.785"
              fill="#0A84FE"
            />
          </RoundedButton> */}
          {/* {sortedSectors.length > 0 && (
              <RoundedButton bgShadow>
                <Icon
                  onClick={() => setShowSortModal(true)}
                  type={TypesIcon.FILTER}
                  viewBox="0 0 25 24"
                  width={px(25)}
                  height={px(24)}
                />
              </RoundedButton>
            )} */}
        </div>
      )}

      {showInformationForm && (
        <div
          className="container w-full"
          style={{
            paddingBottom: px(80),
            paddingTop: px(80),
          }}
        >
          <div style={{ marginBottom: px(16) }}>
            <p style={{ fontSize: px(32), lineHeight: px(43) }}>Информация</p>
            <p style={{ fontSize: px(15), lineHeight: px(20) }}>
              Работа с сервисом будет еще удобнее, если Вы заполните все формы
            </p>
          </div>
          <Textarea
            placeholder="Введите текст"
            title="Место проведения"
            style={{
              height: px(130),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            value={place}
            onChange={place => setFields({ place })}
          />
          <Textarea
            placeholder="Введите текст"
            title="Организаторы"
            style={{
              height: px(130),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            value={organizator}
            onChange={(_, organizator) => setFields({ organizator })}
          />
          <Textarea
            placeholder="Введите текст"
            title="Количество участников"
            style={{
              height: px(130),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Призовой фонд"
            style={{
              height: px(330),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Сумма взносов"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Список участников"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Порядок регистрации"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Оплата участия"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Регламент"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="Программа"
            style={{
              height: px(130),
              marginBottom: px(40),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <div style={{ marginBottom: px(16) }}>
            <p style={{ fontSize: px(32), lineHeight: px(43) }}>Фильтры</p>
            <p style={{ fontSize: px(15), lineHeight: px(20) }}>Характеристики</p>
          </div>

          <div style={{ marginBottom: px(40) }}>
            <CheckboxFieldGroup
              multiple
              title="Вид турнира"
              items={[
                { label: 'Коммерческий', value: 'commercial' },
                { label: 'Рейтинговый', value: 'rank' },
                { label: 'Любительский', value: 'amateur' },
                { label: 'Профессиональный', value: 'professional' },
              ]}
              /* value={landscape}
              onChange={landscape => setSectorFields({ landscape })} */
            />
          </div>
          <div style={{ marginBottom: px(40) }}>
            <CheckboxFieldGroup
              multiple
              title="Тип участников"
              items={[
                { label: 'Все желающие', value: 'all' },
                { label: 'Молодежный', value: 'young' },
                { label: 'Детский', value: 'kids' },
                { label: 'Женский', value: 'female' },
                { label: 'Мужской', value: 'male' },
              ]}
              /* value={landscape}
              onChange={landscape => setSectorFields({ landscape })} */
            />
          </div>

          <div style={{ marginBottom: px(40) }}>
            <CheckboxFieldGroup
              multiple
              title="Зачет"
              items={[
                { label: 'Личный', value: 'personal' },
                { label: 'Командный', value: 'team' },
                { label: 'Лично-командный', value: 'mix' },
              ]}
              /* value={landscape}
              onChange={landscape => setSectorFields({ landscape })} */
            />
          </div>

          <div style={{ marginBottom: px(40) }}>
            <CheckboxFieldGroup
              multiple
              title="Дисциплина"
              items={[
                { label: 'Карпфишинг', value: 'discipline1' },
                { label: 'Ловля форели', value: 'discipline2' },
                { label: 'Фидер. Донка', value: 'discipline3' },
              ]}
              /* value={landscape}
              onChange={landscape => setSectorFields({ landscape })} */
            />
          </div>

          <div style={{ marginBottom: px(40) }}>
            <CheckboxFieldGroup
              multiple
              title="Направление"
              items={[
                { label: 'Платная', value: 'payment' },
                { label: 'Трофейная (от 20 кг ) ', value: 'trophy' },
                { label: 'Зимняя со льда', value: 'winter' },
              ]}
              /* value={landscape}
              onChange={landscape => setSectorFields({ landscape })} */
            />
          </div>

          <div style={{ marginBottom: px(40) }}>
            <CheckboxFieldGroup
              multiple
              title="Виды рыб"
              items={[
                { label: 'Карп', value: 'fish1' },
                { label: 'Белый амур', value: 'fish2' },
                { label: 'Черный амур', value: 'fish3' },
              ]}
              /* value={landscape}
              onChange={landscape => setSectorFields({ landscape })} */
            />
          </div>

          <div style={{ marginBottom: px(16) }}>
            <p style={{ fontSize: px(32), lineHeight: px(32) }}>Условия</p>
            <p style={{ fontSize: px(32), lineHeight: px(32) }}>и правила</p>
            <p style={{ fontSize: px(15), lineHeight: px(20) }}>
              Работа с сервисом будет еще удобнее, если Вы заполните все формы
            </p>
          </div>
          <Textarea
            placeholder="Введите текст"
            title="1. Календарь"
            style={{
              height: px(130),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="2. Основные положения"
            style={{
              height: px(130),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="3. Регистрация. Взносы …"
            style={{
              height: px(130),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="4. Результаты"
            style={{
              height: px(330),
              marginBottom: px(14),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="5. Рейтинг команд"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="6. Основные правила"
            style={{
              height: px(130),
              marginBottom: px(10),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />
          <Textarea
            placeholder="Введите текст"
            title="7. Нарушения правил"
            style={{
              height: px(130),
              marginBottom: px(40),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            /* value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })} */
          />

          <Button
            style={{ width: '100%' }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            /* disabled={loading} */
            onClick={() => {
              setData([...data, object]);
              setShowInformationForm(false);
            }}
            /* onClick={() => {
              setLoading(true);
              sectorRequest(
                sectorFields,
                creation ? selectedLocation : undefined,
                creation ? undefined : defaultSector?.id,
              )
                ?.then(() => updateProfile()?.then(onClose))
                .finally(() => setLoading(false));
            }} */
          >
            Сохранить
          </Button>
        </div>
      )}

      {!showInformationForm && data.length > 0 && (
        <div className="container w-full">
          <div style={{ marginBottom: px(14) }}>
            <p style={{ fontSize: px(32), lineHeight: px(43) }}>Информация</p>
            <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
              Дорогие друзья, с большой радостью приглашаем вас принять участие
            </p>
          </div>

          <div style={{ marginBottom: px(10) }}>
            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
              Место проведения
            </p>
            <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
              Корты МПИ «Дмитровское», Московская область, Рузский район, деревня Фермерская
              {data[activeIndex]?.place}
            </p>
          </div>

          <div style={{ marginBottom: px(10) }}>
            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
              Период проведения
            </p>
            <div
              style={{
                gap: px(14),
              }}
              className="flex"
            >
              {blockTheAccountPageTimeData.map(item => (
                <div key={item?.id} className="flex flex-col">
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      fontWeight: 400,
                    }}
                    className="font-normal opacity-50"
                  >
                    {item?.title}
                  </p>

                  <div
                    style={{
                      gap: px(10),
                    }}
                    className="flex flex-row"
                  >
                    {item?.data?.map(el => (
                      <div key={el?.id}>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            paddingBottom: px(20),
                            fontWeight: 400,
                          }}
                          className="font-normal  opacity-50"
                        >
                          {el?.subTitle}
                        </p>
                        <p
                          style={{
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                          className="font-normal text-black"
                        >
                          {el?.id === 1
                            ? `09:00`
                            : el?.id === 3
                              ? `19:00`
                              : el?.id === 2
                                ? `23.02.24`
                                : `24.02.24`}
                        </p>
                        <hr
                          className="text-black opacity-30"
                          style={{
                            marginTop: px(5),
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ marginBottom: px(10) }}>
            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Организаторы</p>
            <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
              Администрация Московской области
              {data[activeIndex]?.organizator}
            </p>
          </div>

          <div className="flex items-center justify-between" style={{ marginBottom: px(10) }}>
            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Характеристики</p>
            <div>
              <button
                type="button"
                className="font-normal"
                onClick={() => setHide(!hide)}
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  color: '#0A84FE',
                  cursor: 'pointer',
                }}
              >
                {hide ? 'скрыть' : 'показать'}
              </button>
            </div>
          </div>

          {hide && (
            <>
              <div style={{ marginBottom: px(40) }}>
                <p
                  style={{
                    marginBottom: px(13),
                    fontSize: px(20),
                    lineHeight: px(26),
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  Вид турнира
                </p>
                <div className="flex justify-between" style={{ paddingRight: px(8) }}>
                  <p
                    style={{
                      paddingTop: px(2),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                    }}
                  >
                    Коммерческий
                  </p>
                  <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                </div>
              </div>

              <div style={{ marginBottom: px(40) }}>
                <p
                  style={{
                    marginBottom: px(13),
                    fontSize: px(20),
                    lineHeight: px(26),
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  Тип участников
                </p>
                <div className="flex justify-between" style={{ paddingRight: px(8) }}>
                  <p
                    style={{
                      paddingTop: px(2),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                    }}
                  >
                    Все желающие
                  </p>
                  <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                </div>
              </div>

              <div style={{ marginBottom: px(40) }}>
                <p
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  Зачет
                </p>
                <div
                  className="flex justify-between"
                  style={{ paddingRight: px(8), marginTop: px(13) }}
                >
                  <p
                    style={{
                      paddingTop: px(2),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                    }}
                  >
                    Личный
                  </p>
                  <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                </div>
                <div
                  className="flex justify-between"
                  style={{ paddingRight: px(8), marginTop: px(13) }}
                >
                  <p
                    style={{
                      paddingTop: px(2),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                    }}
                  >
                    Командный
                  </p>
                  <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                </div>
                <div
                  className="flex justify-between"
                  style={{ paddingRight: px(8), marginTop: px(13) }}
                >
                  <p
                    style={{
                      paddingTop: px(2),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                    }}
                  >
                    Лично-командный
                  </p>
                  <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                </div>
              </div>

              <div style={{ marginBottom: px(40) }}>
                <p
                  style={{
                    marginBottom: px(13),
                    fontSize: px(20),
                    lineHeight: px(26),
                    fontWeight: 400,
                    color: 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  Дисциплина
                </p>
                <div className="flex justify-between" style={{ paddingRight: px(8) }}>
                  <p
                    style={{
                      paddingTop: px(2),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                    }}
                  >
                    Карпфишинг
                  </p>
                  <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                </div>
              </div>
            </>
          )}

          <div style={{ marginBottom: px(10) }}>
            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
              Количество участников
            </p>
            <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
              Максимально 30 команд по 2 или 3 участника. Максимально 30 папапппа
              укнукщцукцукгуцгкуцгкугкгукук … далее
            </p>
          </div>

          <div style={{ marginBottom: px(10) }}>
            <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
              Старт регистрации
            </p>
            <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
              Максимально 30 команд по 2 или 3 учас
            </p>
          </div>
          <CardInformation
            dropDown
            cardHolder
            data={[
              {
                id: 6,
                title: { label: 'Список участников и резерв' },
                text: (
                  <div>
                    <p>1. Календарь</p>
                    <p>
                      Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                      любезны. Долго происходил за… далее
                    </p>
                  </div>
                ),
              },
            ]}
          />
          <CardInformation
            dropDown
            cardHolder
            data={[
              {
                id: 6,
                title: { label: 'Порядок регистрации' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                    }}
                    objectKey="generalInformation"
                    value="ASDAS"
                    /* setValue={(_, generalInformation) =>
                      setValues({
                        generalInformation: capitalizeFirstLetter(generalInformation),
                      })
                    } */
                  />
                ),
              },
            ]}
          />
          <CardInformation
            dropDown
            cardHolder
            data={[
              {
                id: 6,
                title: { label: 'Оплата участия' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                    }}
                    objectKey="generalInformation"
                    value="ASDAS"
                    /* setValue={(_, generalInformation) =>
                      setValues({
                        generalInformation: capitalizeFirstLetter(generalInformation),
                      })
                    } */
                  />
                ),
              },
            ]}
          />
          <CardInformation
            dropDown
            cardHolder
            data={[
              {
                id: 6,
                title: { label: 'Регламент' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                    }}
                    objectKey="generalInformation"
                    value="ASDAS"
                    /* setValue={(_, generalInformation) =>
                      setValues({
                        generalInformation: capitalizeFirstLetter(generalInformation),
                      })
                    } */
                  />
                ),
              },
            ]}
          />
          <CardInformation
            dropDown
            cardHolder
            data={[
              {
                id: 6,
                title: { label: 'Программа' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                    }}
                    objectKey="generalInformation"
                    value="ASDAS"
                    /* setValue={(_, generalInformation) =>
                      setValues({
                        generalInformation: capitalizeFirstLetter(generalInformation),
                      })
                    } */
                  />
                ),
              },
            ]}
          />
          <CardInformation
            dropDown
            cardHolder
            data={[
              {
                id: 6,
                title: { label: 'Условия и Правила' },
                text: (
                  <div className="flex flex-col" style={{ gap: px(10) }}>
                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        1. Календарь
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        2. Основные положения
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        3. Регистрация. Взносы
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        4. Результаты
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        5. Рейтинг команд
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        6. Основные правила
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>

                    <div>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          color: 'rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        7. Нарушения правил
                      </p>
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        Оплата за участие Не так вкусно этот раз При заселении были не достаточно
                        любезны. Долго происходил за… далее
                      </p>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </>
  );
};
