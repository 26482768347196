import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '../selectors/auth';

const useNotificationCount = trigger => {
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const auth = useSelector(authSelector);

  const onMessage = useCallback(event => {
    try {
      const data = JSON.parse(event.data).unreadCount ?? 0;
      setUnreadNotificationsCount(prevCount => prevCount + data);
    } catch (error) {
      console.error('Error parsing event data:', error);
    }
  }, []);

  const onError = useCallback(error => {
    console.error('EventSource error:', error);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (auth) {
      const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/notification/count`, {
        withCredentials: true,
      });

      eventSource.addEventListener('message', onMessage);
      eventSource.addEventListener('error', onError);

      return () => {
        eventSource.close();
        eventSource.removeEventListener('message', onMessage);
        eventSource.removeEventListener('error', onError);
      };
    }
  }, [onMessage, onError]);

  useEffect(() => setUnreadNotificationsCount(0), [trigger]);

  return unreadNotificationsCount;
};

export default useNotificationCount;
