import React, { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Router } from '@pages';
import { Provider } from 'react-redux';
import { store } from "@store"
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Modals from './components/modals';

export const App = () => {
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Router />    
        <Modals/>  
      </BrowserRouter>
      <ToastContainer />

      </PersistGate>
    </Provider>
  );
};

export default App;