import { Icon } from '@/components/Icon';
import { useNavigate } from 'react-router-dom';
import { colors } from '../constants/Colors';
import { TypesIcon } from '../types/TypesIcon';
import { px } from './../constants/Px';

export const Registration = ({ title, children, navigatePatch }) => {
  const navigete = useNavigate();

  const handleNavigateBack = () => {
    navigete(navigatePatch || -1);
  };

  return (
    <div className='home-add-popup-container'>
      <div className='home-add-popup-container-overflow'>
        <div className='home-add-popup-content'>
          <div className='search-menu-item-preferences-close'>
            <Icon
              type={TypesIcon.CLOSE}
              viewBox='0 0 17 17'
              width={px(17)}
              height={px(17)}
              onClick={handleNavigateBack}
            />
          </div>
          <div
            style={{
              padding: `${px(0)}  ${px(15)}`,
            }}
          >
            {title && (
              <div
                style={{
                  margin: `${px(19)} ${px(0)} ${px(18)} ${px(0)}`,
                  gap: px(10),
                }}
                className='flex justify-center items-center'
              >
                <Icon
                  type={TypesIcon.HUMAN}
                  viewBox='0 0 24.98 24.98'
                  width={px(24.98)}
                  height={px(24.98)}
                  fill={colors.black}
                />
                <p
                  style={{
                    fontSize: px(32),
                    lineHeight: px(43),
                  }}
                  className='text-center text-[#000000]  font-light'
                >
                  {title}
                </p>
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
