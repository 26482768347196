import * as authApi from '@/api/auth';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addModal } from '../../modal/addModal';

export const LOGIN_CODE = 'auth/loginCode';

const onFail = () => async (dispatch) => {
  // await dispatch(
  //   addModal({
  //     id: 8,
  //     data: {
  //       title:
  //         'Забыли код доступа?Нажмите кнопку выйти, чтобы сбросить код доступа  и создать новый',
  //       buttonLeftText: 'Отмена',
  //       buttonRightText: 'Выйти',
  //     },
  //     pathSubmit: 'login',
  //   }),
  // );
};

export const loginCode = createAsyncThunk(
  LOGIN_CODE,
  createRequest({
    type: LOGIN_CODE,
    loader: (data) => authApi.loginCode(data),

    onFail,
  }),
);
