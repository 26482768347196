import React from 'react';
import { px } from '../constants/Px';
import { objectDescriptionPageInputs } from '../constants/Component';
import StyledInput from './styled-input';
import ObjectDescriptionTitles from './object-description-titles';
import { AreaAndDistrictOptions } from '../constants/Component';

export const ContactsObjectDescriptionPage = ({
  inputsData,
  handleInputChange,
  openPicker,
  timePhone1,
  timePhone2,
  endTimePhone1,
  endTimePhone2,
}) => {
  const optionAreaDistrictFilter = (id, country) => {
    if (country === '') {
      return [];
    }
    return id === 2 && country === 'Россия'
      ? AreaAndDistrictOptions.russiaDistricts
      : id === 3 && country === 'Россия'
        ? AreaAndDistrictOptions.regionsAndAreas[inputsData['region']] || []
        : id === 3 && country === 'Беларусь'
          ? AreaAndDistrictOptions.belarusAreas
          : AreaAndDistrictOptions.kazachstanAreas;
  };

  return (
    <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
      <ObjectDescriptionTitles
        titleLineHeight={43}
        text={'Необходимые нормативы\nповедения на вашем объекте'}
        title={'Контакты'}
      />
      <form
        style={{
          paddingBottom: px(65),
          gap: px(10),
          marginTop: px(20),
        }}
        className="flex flex-col"
      >
        {objectDescriptionPageInputs.map(elem => (
          <StyledInput
            withDatePicker={elem.id === 7 || elem.id === 6 || false}
            isBorder
            id={elem.id}
            country={inputsData?.country || 'Россия'}
            phoneInput={elem.phoneInput || false}
            value={inputsData[elem?.objKey]}
            onChange={e => {
              handleInputChange(elem.objKey, e.target.value);
            }}
            options={
              (elem?.id === 1 && elem?.options) ||
              ((elem?.id === 2 || elem?.id === 3) &&
                optionAreaDistrictFilter(elem.id, inputsData?.country))
            }
            bluePlaceholder={elem.bluePlaceholder || false}
            bigTitle={elem.bigTitle || false}
            title={elem.title}
            textOpacity={50}
            placeholder={
              elem.id === 7 || elem.id === 6
                ? inputsData.country === 'Беларусь'
                  ? '+375 (XX) XXX XX XX'
                  : '+7 (XXX) XXX XX XX'
                : elem.placeholder || ''
            }
            placeholderOpacity
            openPicker={openPicker}
            timePhone={elem.id === 6 ? timePhone1 : elem.id === 7 && timePhone2}
            endTimePhone={elem.id === 6 ? endTimePhone1 : elem.id === 7 && endTimePhone2}
            letEmailValidation={elem.id === 8}
            disabled={
              inputsData.country === 'Беларусь' || inputsData.country === 'Казахстан'
                ? elem.id === 2
                : !inputsData.country
                  ? elem.id === 3 || elem.id === 2
                  : !inputsData.region && elem.id === 3
            }
          />
        ))}
      </form>
    </div>
  );
};

export default ContactsObjectDescriptionPage;
