/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox } from '../../../components';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { CircleButton, Dropdown, Modal, ScrollButtonsContainer } from '../../shared';
import { px } from '../../utils';
import { useProfile } from '../hooks';
import { Access, AddLocation, FishingMenu, Price } from './components';

export const OffersEditPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [showAddLocaionModal, setShowAddLocationModal] = useState(false);
  const [showAccessMenuModal, setShowAccessMenuModal] = useState(false);
  const [showFishingMenuModal, setShowFishingMenuModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showPriceMenuModal, setShowPriceMenuModal] = useState(false);

  const {
    data: { locations, selectedLocationId },
    setData: setProfile,
  } = useProfile();

  const selectedLocation = useMemo(
    () => locations?.find?.(location => location.id === selectedLocationId) || locations[0],
    [JSON.stringify(locations), selectedLocationId],
  );

  useLayoutEffect(() => {
    if (!selectedLocationId) {
      setProfile({
        selectedLocationId: locations?.[0]?.id,
      });
    }
  }, [JSON.stringify(locations), selectedLocationId]);

  const { type: locationCategory } = selectedLocation || {};

  return (
    <div className="global-container">
      <main>
        <div
          className="relative bg-[#fff]"
          style={{
            padding: ` ${px(20)} ${px(15)}`,
          }}
          aria-hidden
          onClick={handleClose}
        >
          <div className="cursor-pointer">
            <Icon
              type={TypesIcon.COLORBACK}
              viewBox="0 0 11.61 19.762"
              width={px(11.61)}
              height={px(19.762)}
            />
          </div>
        </div>
        {selectedLocation && (
          <>
            <div className="container">
              <p
                style={{
                  fontSize: px(32),
                  marginTop: px(29),
                }}
              >
                Редактировать
              </p>
              <div
                style={{
                  width: px(290),
                  marginBottom: px(10),
                  paddingTop: px(9),
                }}
              >
                <Dropdown
                  key={selectedLocationId}
                  items={locations?.map(({ name: label, id: value }) => ({ label, value }))}
                  // eslint-disable-next-line unicorn/consistent-destructuring
                  value={selectedLocation.id}
                  onChange={id =>
                    setProfile({ selectedLocationId: locations?.find(i => i?.id === id)?.id })
                  }
                />
              </div>
            </div>

            <div className="container flex justify-between" style={{ marginBottom: px(100) }}>
              <div
                style={{
                  width: px(90),
                  height: px(222),
                  border: '1px solid black',
                  borderRadius: px(15),
                  backgroundColor: '#082341',
                  paddingTop: px(20),
                  paddingBottom: px(10),
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    paddingRight: px(19.9),
                    paddingLeft: px(19.9),
                    height: px(43),
                    marginBottom: px(12),
                  }}
                >
                  <Icon
                    type={TypesIcon.BIGFISH}
                    viewBox="0 0 50.226 22.966"
                    width={px(50.226)}
                    height={px(22.966)}
                  />
                </div>
                <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>МОСТИК</p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  НАВЕС
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  ДОМИК
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  FREE ZONE
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  ...
                </p>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    color: 'white',
                    marginTop: px(10),
                    marginBottom: px(10),
                  }}
                >
                  РЫБАЛКА
                </p>
                <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
                  <Checkbox
                    checked={locationCategory === 'FISHING'}
                    onChange={() => {
                      if (locations?.filter(l => l?.type === 'FISHING')?.length > 0) {
                        setProfile({
                          selectedLocationId: locations?.find(l => l?.type === 'FISHING')?.id,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  width: px(90),
                  height: px(222),
                  border: '1px solid black',
                  borderRadius: px(15),
                  backgroundColor: '#082341',
                  paddingTop: px(20),
                  paddingBottom: px(10),
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    paddingRight: px(36),
                    paddingLeft: px(36),
                    height: px(43),
                    marginBottom: px(12),
                  }}
                >
                  <Icon
                    type={TypesIcon.RELAX}
                    viewBox="0 0 17.987 42.916"
                    width={px(17.987)}
                    height={px(42.916)}
                  />
                </div>
                <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ОТЕЛЬ</p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  КОТТЕДЖ
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  БЕСЕДКА
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  БАНЯ, САУНА
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  ...
                </p>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    color: 'white',
                    marginTop: px(10),
                    marginBottom: px(10),
                  }}
                >
                  ОТДЫХ
                </p>
                <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
                  <Checkbox
                    checked={locationCategory === 'REST'}
                    onChange={() =>
                      setProfile({
                        selectedLocationId: locations?.find(l => l?.type === 'REST')?.id,
                      })
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  width: px(90),
                  height: px(222),
                  border: '1px solid black',
                  borderRadius: px(15),
                  backgroundColor: '#082341',
                  paddingTop: px(20),
                  paddingBottom: px(10),
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    paddingRight: px(16.5),
                    paddingLeft: px(21.1),
                    height: px(43),
                    marginBottom: px(12),
                  }}
                >
                  <Icon
                    type={TypesIcon.BOAT}
                    viewBox="0 0 52.429 42.517"
                    width={px(52.429)}
                    height={px(42.517)}
                  />
                </div>
                <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ЛОДКА</p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  СНАСТИ
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  ПРИКОРМКА
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  ОБОРУДОВАНИЕ
                </p>
                <p
                  style={{
                    fontSize: px(10),
                    lineHeight: px(14),
                    color: 'white',
                    marginTop: px(-1),
                  }}
                >
                  ...
                </p>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    color: 'white',
                    marginTop: px(10),
                    marginBottom: px(10),
                  }}
                >
                  УСЛУГИ
                </p>
                <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
                  <Checkbox
                    checked={locationCategory === 'SERVICE'}
                    onChange={() =>
                      setProfile({
                        selectedLocationId: locations?.find(l => l?.type === 'SERVICE')?.id,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <ScrollButtonsContainer>
          <CircleButton
            icon={{
              type: TypesIcon.ADDPLUS,
              viewBox: '0 0 26.95 26.95',
              width: px(26.95),
              height: px(26.95),
            }}
            onClick={() => setShowAddLocationModal(true)}
          />

          <CircleButton
            onClick={() => setShowFishingMenuModal(true)}
            icon={{
              type: TypesIcon.PENSIL,
              viewBox: '0 0 18.51 23.873',
              width: px(18.51),
              height: px(23.873),
              fill: '#0A84FE',
            }}
          />
          <CircleButton
            onClick={() => setShowAccessMenuModal(true)}
            icon={{
              type: TypesIcon.ACCESS,
              viewBox: '0 0 24.703 26.238',
              width: px(24.703),
              height: px(26.238),
              fill: '#0A84FE',
            }}
          />
          <CircleButton
            noFill
            icon={{
              type: TypesIcon.PRICE,
              viewBox: '0 0 24 27',
              width: px(23.355),
              height: px(26.228),
              // className: '[&_*]:!stroke-[#0A84FE]',
              fill: 'white',
              stroke: '#0A84FE',
            }}
            onClick={() => setShowPriceMenuModal(true)}
          />
        </ScrollButtonsContainer>
        {/* <div
          className="fixed flex w-full max-w-[768px] flex-row items-center justify-center"
          style={{
            bottom: px(54),
            gap: px(10),
          }}
        >
          <CircleButton
            icon={{
              type: TypesIcon.ADDPLUS,
              viewBox: '0 0 26.95 26.95',
              width: px(26.95),
              height: px(26.95),
            }}
            onClick={() => setShowAddLocationModal(true)}
          />

          <CircleButton
            onClick={() => setShowFishingMenuModal(true)}
            icon={{
              type: TypesIcon.PENSIL,
              viewBox: '0 0 18.51 23.873',
              width: px(18.51),
              height: px(23.873),
              fill: '#0A84FE',
            }}
          />
          <CircleButton
            onClick={() => setShowAccessMenuModal(true)}
            icon={{
              type: TypesIcon.ACCESS,
              viewBox: '0 0 24.703 26.238',
              width: px(24.703),
              height: px(26.238),
              fill: '#0A84FE',
            }}
          />
          <CircleButton
            icon={{
              type: TypesIcon.PRICE,
              viewBox: '0 0 23.355 26.228',
              width: px(23.355),
              height: px(26.228),
              className: '[&_*]:!stroke-[#0A84FE]',
            }}
            onClick={() => setShowPriceMenuModal(true)}
          />
        </div> */}

        <Modal show={showAddLocaionModal} onClose={() => setShowAddLocationModal(false)}>
          <AddLocation
            onClose={() => {
              setShowFishingMenuModal(true);
              setShowAddLocationModal(false);
            }}
          />
        </Modal>

        <Modal show={showFishingMenuModal} onClose={() => setShowFishingMenuModal(false)}>
          <FishingMenu />
        </Modal>

        <Modal show={showAccessMenuModal} onClose={() => setShowAccessMenuModal(false)}>
          <Access
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            openChoice={() => {}}
            locationCategory={locationCategory}
          />
        </Modal>

        <Modal show={showPriceMenuModal} onClose={() => setShowPriceMenuModal(false)}>
          <Price locationCategory={locationCategory} />
        </Modal>
      </main>
    </div>
  );
};

export default OffersEditPage;
