import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { px } from '../../../../../utils';

export const ObjectOffersIcons = ({ icons }) => (
  <div
    className="w-full"
    style={{
      gap: px(8),
      marginTop: px(10),
      marginBottom: px(20),
      height: px(43),
      paddingLeft: px(15),
      paddingRight: px(15),
    }}
  >
    <Swiper spaceBetween={8} slidesPerView={6}>
      {icons
        ?.filter(icon => icon.visible === true)
        .map(icon => (
          <SwiperSlide key={icon?.id}>
            <div
              className={`flex items-center justify-center rounded-[15%] border-[1px] border-solid border-[#444444] ${
                icon?.active ? '' : 'opacity-30'
              }`}
              style={{ width: px(42), height: px(43), paddingLeft: px(6), paddingRight: px(6) }}
            >
              <div className="active">{icon?.element}</div>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  </div>
);

export default ObjectOffersIcons;
