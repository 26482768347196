import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { getProfile, getProfileById } from '../../../actions/profile';
import { authActiveIdSelector, authActiveType } from '../../../selectors/auth';
import { profileByIdSelector, profileSelector } from '../../../selectors/profile';
import { useProfile } from '../hooks';
import { CompetitionForm, ObjectForm } from './components';
import { GuideForm } from './components/guide-form';
import { ProfileForm } from './components/profile-form';

export const EditProfilePage = () => {
  const activeType = useSelector(authActiveType);
  const activeId = useSelector(authActiveIdSelector);
  const dispatch = useDispatch();
  const { pathname, hash } = useLocation();
  const profile = useSelector(
    activeType === 'administeredProfile' ? profileByIdSelector : profileSelector,
  );

  const {
    data: { object, competition, guide },
  } = useProfile();

  useEffect(() => {
    if (hash.includes('#')) window.location.href = '#contacts';
    else window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getProfileById({ id: activeId }));
    } else {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  return (
    <div className="global-container ">
      <main>
        {object &&
        Object?.values?.(object)?.length &&
        (activeType === 'object' || activeType === 'administeredObject') ? (
          <ObjectForm object={object} />
        ) : competition &&
          (activeType === 'competition' || activeType === 'administeredCompetition') &&
          Object?.values?.(competition)?.length ? (
          <CompetitionForm competition={competition} />
        ) : guide &&
          (activeType === 'guide' || activeType === 'administeredGuide') &&
          Object?.values?.(guide)?.length ? (
          <GuideForm guide={guide} />
        ) : (
          <ProfileForm profile={profile} />
        )}
      </main>
    </div>
  );
};
