import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/subscription';

export const ADD_SUBSCRIPTIONS = 'subscription/addSubscription';


export const addSubscription = createAsyncThunk(
  ADD_SUBSCRIPTIONS,
    createRequest({
        type: ADD_SUBSCRIPTIONS,
        loader: (data) => authApi.addSubscription(data),
    }),
);