import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_COMPETITION_CABINET_BY_PROFILE = 'competition/getCompetitionCabinetByProfile';

export const getCompetitionCabinetByProfile = createAsyncThunk(
  GET_COMPETITION_CABINET_BY_PROFILE,
  createRequest({
    type: GET_COMPETITION_CABINET_BY_PROFILE,
    loader: data => authApi.getCompetitionCabinetByProfile(data),
  }),
);
