/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { authActiveIdSelector, authSelector } from '../../selectors/auth';
import { GuideProvider } from './context';
import { GuidePage } from './guide.page';
import { getGuide } from './guide.service';
import { useGuide } from './hooks';
import { GuideOffersPage } from './offers';
import { GuideOffersEditPage } from './offers/edit';

const ROUTES = [
  {
    path: '/guide/:id',
    element: GuidePage,
  },
  {
    path: '/guide-offers/edit',
    element: GuideOffersEditPage,
  },
  {
    path: '/guide/offers/:id',
    element: GuideOffersPage,
  },
];

const Stack = () => {
  const auth = useSelector(authSelector);
  const activeId = useSelector(authActiveIdSelector);
  const location = useLocation();
  const onStack = !!matchRoutes(ROUTES, location);

  const {
    data: guideData,
    setData: setGuide,
    setUpdateFunction: setUpdateGuideFunction,
  } = useGuide();
  const getData = () =>
    getGuide({
      // eslint-disable-next-line unicorn/prefer-number-properties
      competitionId: isNaN(window.location.pathname.split('/')[2])
        ? activeId
        : window.location.pathname.split('/')[2],
    }).then(setGuide);

  useEffect(() => {
    if (onStack) {
      setUpdateGuideFunction(() => getData);
      getData();
    }
  }, [onStack]);

  return (
    <Routes>
      {ROUTES?.map(({ private_, element: Element, ...route }) => (
        <Route
          key={route.path}
          element={
            private_ ? (
              auth ? (
                <Element />
              ) : (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            ) : (
              <Element />
            )
          }
          {...route}
        />
      ))}
    </Routes>
  );
};

export const GuideStack = () => (
  <GuideProvider>
    <Stack />
  </GuideProvider>
);

export default GuideStack;
