/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/react';

import { getProfile } from '../../../actions/profile';
import { getCardHolderCards } from '../../../api/card-holder';
import { restoreDeleteCardHolder } from '../../../api/card-holder/delete-card-holder';
import { Button, CustomAlert, FooterSlider, SearchCardModal } from '../../../components';
import { LoyalCard } from '../../../components/loyal-card';
import { TruncatedText } from '../../../components/trancated-text';
import { formattedExpirationDate } from '../../../constants/Function';
import { profileSelector } from '../../../selectors/profile';
import { TypesIcon } from '../../../types/TypesIcon';
import { sortOptionsCards } from '../../constants/sort-options';
import { CardInformation, CircleButton, ScrollButtonsContainer } from '../../shared';
import { BackButton } from '../../shared/back-button';
import { ClubCardViewModal, SortModal } from '../../shared/modal/components';
import { px } from '../../utils/px';

export const ClubCardsPage = () => {
  const navigate = useNavigate();
  const [sortOption, setSortOption] = useState(sortOptionsCards[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [openDropdownIds, setOpenDropdownIds] = useState([]);
  const [cards, setCards] = useState([]);
  const [showSortModal, setShowSortModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showCardTypeView, setShowCardTypeView] = useState(false);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(false);
  const [showAlertDeleteCard, setShowAlertDeleteCard] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCardHolderCards();
        if (response?.data) {
          setCards(response.data || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const onClickButtonDelete = async cardId => {
    try {
      setDisabledButtonDelete(true);
      await restoreDeleteCardHolder({ cardId });
      const response = await getCardHolderCards();
      if (response?.data.length > 0) {
        setCards(response.data || []);
        setDisabledButtonDelete(false);
      }
    } catch (error) {
      console.error('Error deleting card:', error);
    } finally {
      setDisabledButtonDelete(false);
      setShowAlertDeleteCard(false);
    }
  };
  const sortedCards = useMemo(() => {
    if (sortOption.id === 5) {
      return cards && cards.length > 0 ? cards.filter(c => c.status === 'BLOCKED') : [];
    }
    if (sortOption.id === 6) {
      return cards && cards.length > 0
        ? cards.filter(c => c.deletedAt !== null).sort(sortOption.cb)
        : [];
    }
    if (sortOption.id === 7) {
      return cards && cards.length > 0
        ? cards.filter(card => card.openUntill !== null && new Date(card.openUntill) <= new Date())
        : [];
    }
    return cards
      ?.slice()
      .filter(card => card.deletedAt === null)
      .sort(sortOption.cb);
  }, [cards, sortOption]);

  const onRealIndexChange = element => {
    setActiveIndex(element);
  };

  const handleComponentClick = id => {
    if (openDropdownIds.includes(id)) {
      setOpenDropdownIds(openDropdownIds.filter(item => item !== id));
    } else {
      setOpenDropdownIds([...openDropdownIds, id]);
    }
  };
  const slider = useMemo(
    () =>
      sortedCards?.map(card => ({
        data: {
          bgImg: card?.card?.image?.url,
          ownerName: card?.profile?.name,
          cardType: card?.card?.name,
          cardDiscount: card?.card?.cardDiscount,
          displayLogo: card?.card?.displayLogo,
          logoSrc: card?.card?.event?.image,
          openUntill: formattedExpirationDate(card?.openUntill),
        },
      })),
    [sortedCards],
  );

  return (
    <div className="global-container">
      <BackButton onClick={() => navigate('/profile')} />
      <main>
        <div style={{ paddingBottom: px(80) }}>
          <p
            style={{
              fontSize: px(32),
              lineHeight: px(32),
              paddingTop: px(26),
              paddingBottom: px(5),
            }}
            className="text-center font-light"
          >
            Виды карт
            <br /> и привилегии
          </p>
          <TruncatedText
            data={{
              textBefore: 'Виртуальные карты лояльности для постоянных гостей. Является...',
              textAfter:
                'Виртуальные карты лояльности для постоянных гостей. Является Виртуальные карты лояльности для постоянных гостей. Является Виртуальные карты лояльности для постоянных гостей. Является',
              lines: 2,
            }}
            style={{
              paddingLeft: px(27),
              paddingRight: px(27),
            }}
          />
          {sortedCards?.length > 0 ? (
            <>
              <div
                className="w-full"
                style={{
                  padding: `${px(16)} ${px(15)} ${px(0)} ${px(15)}`,
                }}
              >
                <FooterSlider
                  slidesPerView={2}
                  gap={20}
                  cards
                  pagination
                  onRealIndexChange={onRealIndexChange}
                  centeredSlides
                >
                  {slider?.map(item => (
                    <SwiperSlide key={item?.data?.cardNumber} className="swiper-slide">
                      <div className="flex w-full justify-center">
                        <LoyalCard
                          color="#fff"
                          data={{
                            bgImg: item?.data?.bgImg,
                            ownerName: item?.data?.ownerName,
                            cardType: item?.data?.cardType,
                            percent: item?.data?.cardDiscount,
                            openUntill: item?.data?.openUntill,
                            logo: {
                              logoSrc: item?.data?.logoSrc,
                              showLogo: item?.data?.displayLogo,
                            },
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </FooterSlider>
              </div>
              <div
                className=" footer__dropdowns "
                style={{
                  background: 'white',
                }}
              >
                <CardInformation
                  key={`cardInfoBlocks-${activeIndex}`}
                  data={[
                    {
                      id: 1,
                      title: { label: 'Имя карты' },
                      value: sortedCards?.[activeIndex]?.card?.name,
                    },
                    {
                      id: 2,
                      title: { label: 'Статус карты' },
                      value: `${sortedCards?.[activeIndex]?.card?.cardDiscount || 0}%`,
                    },
                    {
                      id: 3,
                      title: { label: 'Срок действия' },
                      value: formattedExpirationDate(sortedCards?.[activeIndex]?.openUntill),
                    },
                    {
                      id: 4,
                      title: { label: 'Условия получения' },
                      value: sortedCards?.[activeIndex]?.card?.receiptConditions,
                    },
                  ]}
                />
                <CardInformation
                  key={`cardInfoDropDowns-${activeIndex}`}
                  handleComponentClick={handleComponentClick}
                  data={[
                    {
                      id: 1,
                      title: { label: 'Общая информация' },
                      text: sortedCards?.[activeIndex]?.card?.generalInformation,
                    },
                    {
                      id: 2,
                      title: { label: 'Где применяется' },
                      text: sortedCards?.[activeIndex]?.card?.applicationScope,
                    },
                    {
                      id: 3,
                      title: { label: 'Соглашение' },
                      text: sortedCards?.[activeIndex]?.card?.agreement,
                    },
                    {
                      id: 4,
                      title: { label: 'Регламент использования' },
                      text: sortedCards?.[activeIndex]?.card?.useTerms,
                    },
                    {
                      id: 5,
                      title: { label: 'Обслуживание клиентов' },
                      text: [
                        {
                          label: 'Email:',
                          value: sortedCards?.[activeIndex]?.card?.event?.EventContact?.email,
                        },
                        {
                          value: sortedCards?.[activeIndex]?.card?.event?.EventContact?.mainPhone,
                        },
                        {
                          value:
                            sortedCards?.[activeIndex]?.card?.event?.EventContact?.secondaryPhone,
                        },
                      ],
                      disabled:
                        sortedCards?.[activeIndex]?.card?.event?.EventContact?.EventContact
                          ?.email === '' &&
                        sortedCards?.[activeIndex]?.card?.event?.EventContact?.EventContact
                          ?.mainPhone === '' &&
                        sortedCards?.[activeIndex]?.card?.event?.EventContact?.EventContact
                          ?.secondaryPhone === '',
                    },
                  ]}
                  dropDown
                />
                {sortOption?.id !== 6 && (
                  <Button
                    onClick={() => setShowAlertDeleteCard(true)}
                    className="w-full"
                    height={60}
                    borderRadius={0}
                    marginTop={20}
                    marginBottom={20}
                    bgColor="#FA0303"
                    color="#ffffff"
                    disabled={disabledButtonDelete}
                  >
                    Удалить карту
                  </Button>
                )}
              </div>
              <SearchCardModal
                title="Клубные карты"
                isOpen={showSearchModal}
                onClose={() => {
                  setShowSearchModal(false);
                }}
                buttonTitle="Найти"
                profileId={profile?.id}
              />
            </>
          ) : (
            <div
              style={{
                paddingTop: px(20),
              }}
            >
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className="text-center font-normal opacity-50"
              >
                Карты не найдены
              </p>
            </div>
          )}
          <SortModal
            closeButton
            title="Клубные карты"
            show={showSortModal}
            onClose={() => setShowSortModal(false)}
            options={sortOptionsCards}
            onChange={option => {
              setSortOption(option);
              setShowSortModal(false);
            }}
          />
          {showCardTypeView && (
            <ClubCardViewModal
              showCardTypeView={showCardTypeView}
              setShowCardTypeView={setShowCardTypeView}
              data={sortedCards[activeIndex]}
              setCards={setCards}
            />
          )}
          <ScrollButtonsContainer>
            {sortedCards.length > 1 && (
              <CircleButton
                onClick={() => setShowSortModal(true)}
                icon={{
                  type: TypesIcon.SORT,
                  viewBox: '0 0 25 24',
                  width: px(25),
                  height: px(23.61),
                }}
                className="text-[#0A84FE]"
              />
            )}
            {sortedCards.length > 0 && (
              <CircleButton
                onClick={() => {
                  setShowSearchModal(true);
                }}
                icon={{
                  type: TypesIcon.SEARCH,
                  viewBox: '0 0 22 22',
                  width: px(22.36),
                  height: px(22.36),
                }}
                className="text-[#0A84FE]"
              />
            )}
            {sortedCards.length > 0 && (
              <CircleButton
                onClick={() => {
                  setShowCardTypeView(true);
                }}
                icon={{
                  type: TypesIcon.EYEOPEN,
                  viewBox: '0 0 26 18',
                  width: px(26.47),
                  height: px(16.87),
                }}
                className="text-[#0A84FE]"
              />
            )}
          </ScrollButtonsContainer>
          {showAlertDeleteCard && (
            <CustomAlert
              onClickButtonSubmit={async () => {
                onClickButtonDelete(sortedCards[activeIndex]?.id);
              }}
              onClickButtonClose={() => setShowAlertDeleteCard(false)}
              title="Подтвердить удаление клубной карты?"
              buttonLeftText="Отмена"
              buttonRightText="Да"
            />
          )}
        </div>
      </main>
    </div>
  );
};
