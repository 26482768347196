import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const UPDATE_COMPETITION_NEWS = 'competition/updateCompetitionNews';

export const updateCompetitionNews = createAsyncThunk(
  UPDATE_COMPETITION_NEWS,
  createRequest({
    type: UPDATE_COMPETITION_NEWS,
    loader: data => authApi.updateCompetitionNews(data),
  }),
);
