import React from 'react';
import { Link } from 'react-router-dom';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const ImageBlock = ({ className, video, src, adLink, ...properties }) => (
  <div {...properties} className={`image-block ${className || ''}`}>
    {!video && <img className="image-block__image" alt="block" src={src} />}
    {video && (
      <div className="image-block__image__video-add">
        <Link to={adLink}>
          <img className={`image-block__image video `} alt="block" src={src} />
        </Link>
        <div
          style={{
            backgroundColor: '#06AAEC',
            width: px(80),
            height: px(80),
            position: 'absolute',
            zIndex: 2,
          }}
        />
        <div className="image-block__image__video-add">
          <Link
            to={adLink}
            // target="_blank"
          >
            <img className={`image-block__image video `} alt="block" src={src} />
          </Link>
          <Link
            to={adLink}
            className={`image-block__play-button video `}
            // target="_blank"
          >
            <Icon viewBox="0 0 50 50" width={px(50)} height={px(50)} type={TypesIcon.VIDEO} />
          </Link>
        </div>
      </div>
    )}
  </div>
);

export default ImageBlock;
