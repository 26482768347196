import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/auth';
import { createRequest } from '../../../helpers/api/createRequest';

export const SIGNUP_BIOMETRIC = 'auth/signupBiometric';

export const signupBiometric = createAsyncThunk(
  SIGNUP_BIOMETRIC,
  createRequest({
    type: SIGNUP_BIOMETRIC,
    loader: data => authApi.signupBiometric(data),
  }),
);
