/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { createLocation, getLocations } from '../actions/location/thunks';
import { px } from '../constants/Px';
import { authSelector } from '../selectors/auth';
import { locationsSelector } from '../selectors/location';
import { StyledInput } from './styled-input';

const LocationButton = ({ item, onClick }) => (
  <button
    className="location-button"
    key={item.id}
    type="button"
    onClick={onClick}
    style={{
      width: px(290),
      height: px(60),
      paddingLeft: px(18),
      marginBottom: px(10),
      fontSize: px(20),
      fontWeight: 400,
      textAlign: 'left',
    }}
  >
    {item.name}
  </button>
);

export const LocationCreator = ({
  openFishingMenu,
  setActiveIndex,
  closeLocation,
  setModalType,
  id,
}) => {
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const [locationTitle, setLocationTitle] = useState('');
  const dispatch = useDispatch();
  const locations = useSelector(locationsSelector);

  const changeLocationTitle = e => {
    setLocationTitle(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      const create = async () => {
        await dispatch(createLocation({ name: locationTitle, eventId: +id }));
        await dispatch(getLocations({ id }));
      };
      create();
      setLocationTitle('');
    }
  };

  useEffect(() => {
    dispatch(getLocations({ id }));
  }, []);

  useEffect(() => {
    if (!auth) navigate('/login');
  }, []);

  const handleButtonClick = (index, modalType) => {
    openFishingMenu();
    closeLocation();
    setActiveIndex(index);
    setModalType(modalType);
  };

  return (
    <div style={{ overflowY: 'auto', height: '100%', paddingBottom: px(30) }}>
      <p
        className="text-center"
        style={{
          paddingTop: px(5),
          marginBottom: px(30),
        }}
      >
        Предложения
      </p>
      <div className="container">
        <p
          style={{
            fontSize: px(32),
            lineHeight: px(43),
          }}
        >
          Создать локацию
        </p>
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
            marginBottom: px(48),
          }}
        >
          Добавляйте водоемы, объекты, места отдыха и структуру для бронирования
        </p>
        <div style={{ marginBottom: px(10) }}>
          <StyledInput
            value={locationTitle}
            title="Название места"
            placeholder="ХХХХХХХХ"
            onChange={e => changeLocationTitle(e)}
            onKeyDown={e => handleKeyPress(e)}
          />
        </div>
        {locations?.map((item, index) => (
          <LocationButton key={item.id} item={item} onClick={() => handleButtonClick(index, 1)} />
        ))}
        <LocationButton
          item={{ id: 'common', name: 'Общая территория' }}
          onClick={() => {
            openFishingMenu();
            closeLocation();
            setModalType(2);
          }}
        />
        <LocationButton
          item={{ id: 'services', name: 'Типо услуги' }}
          onClick={() => {
            openFishingMenu();
            closeLocation();
            setModalType(3);
          }}
        />
      </div>
    </div>
  );
};
