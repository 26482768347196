import { request } from '@/helpers/api/request';

export const sectionPost = async data =>
  await request({
    url: `section`,
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    method: 'POST',
    data,
  });
