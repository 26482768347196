import React from 'react';

import { px } from '../../constants/Px';
import { getAdditionalLogo, isObjectOrCompetitionType } from './img-logo-header.utils';

export const ImgLogoHeader = ({ activeType, logo, eventSelectorData, competition }) => {
  const data =
    activeType === 'object' || activeType === 'administeredObject'
      ? eventSelectorData
      : competition;

  const additionalLogo = getAdditionalLogo(activeType, eventSelectorData, competition);

  if (!isObjectOrCompetitionType(activeType)) {
    return null;
  }

  return logo || additionalLogo ? (
    <img
      alt="avatar"
      style={{
        width: px(40),
        height: px(40),
        borderRadius: px(40),
        border: '1px solid gray',
      }}
      src={`${process.env.REACT_APP_STATIC_URL}${logo || additionalLogo}`}
    />
  ) : (
    <div
      style={{ height: px(40), width: px(40) }}
      className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
    >
      <p className="font-light text-black" style={{ fontSize: px(32), lineHeight: px(43) }}>
        {data?.name?.charAt(0)?.toUpperCase()}
      </p>
    </div>
  );
};
