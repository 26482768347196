import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { HomeAddPopup } from '../../components/home-add-popup';

export const FilterPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="global-container">
      <main>
        <HomeAddPopup search>
          <p className="whitespace-pre">
            {`Выберите раздел с\nфильтрами или ищите\nпо названиям, региону,\nстране`}
          </p>
        </HomeAddPopup>
      </main>
    </div>
  );
};
