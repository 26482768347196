import React, { useContext, useEffect, useState } from 'react';

import { filtersDataArray } from '../data/filters';
import SearchMenuItem from './search-menu-item';
import { StateContext } from './state-provider';

export const SearchMenu = ({ selectedPreferences, searchMenuOpen, setSearchMenuOpen }) => {
  const { setSelectedItem } = useContext(StateContext);

  const [searchMenuItems, setSearchMenuItems] = useState(filtersDataArray);
  const handleMenuItemClick = index => {
    setSelectedItem(searchMenuItems[index]);
    setSearchMenuOpen(!searchMenuOpen);
  };
  useEffect(() => {
    if (searchMenuOpen) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }

    return () => document.documentElement.classList.remove('lock');
  }, [searchMenuOpen]);
  return (
    <div className="search-menu-container">
      <div className="search-menu medium">
        <SearchMenuItem
          isCountry
          handleMenuItemClick={handleMenuItemClick}
          selectedPreferences={selectedPreferences}
          strong
        />
        {searchMenuItems?.map((searchMenuItem, index) => (
          <SearchMenuItem
            key={index}
            selectedItem={searchMenuItems[index]}
            svg={searchMenuItem.iconUrl}
            title={searchMenuItem.title}
            index={index}
            handleMenuItemClick={handleMenuItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchMenu;
