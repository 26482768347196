import React from 'react';

export const HotelIcon = props => (
  <svg
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <rect
        x="0.113281"
        y="8.6416"
        width="15.7698"
        height="2.92033"
        transform="rotate(-30 0.113281 8.6416)"
        fill="currentColor"
      />
      <path
        d="M17.5669 31.0787V23.4858H11.0808V31.0787H7.3457V13.8486H11.0808V20.5655H17.5669V13.8486H21.3019V31.0787H17.5669Z"
        fill="currentColor"
      />
      <path
        d="M17.5825 7.02865C17.5959 6.97769 17.5802 6.9246 17.5418 6.89169L16.4651 5.96681L16.6393 4.50385C16.6455 4.45176 16.6223 4.40187 16.5795 4.37512C16.5367 4.34837 16.4817 4.3494 16.4376 4.37781L15.199 5.17564L13.8957 4.61336C13.8494 4.59334 13.7947 4.60244 13.7548 4.63685C13.7149 4.67128 13.6967 4.72502 13.7078 4.77549L14.019 6.19345L13.0394 7.3089C13.0045 7.34862 12.9939 7.40415 13.0121 7.45217C13.0302 7.50018 13.074 7.53235 13.1249 7.53514L14.5558 7.61362L15.2536 8.8653C15.2644 8.88465 15.2796 8.90035 15.2975 8.91154C15.3208 8.92611 15.3488 8.93303 15.3777 8.93035C15.4288 8.9256 15.4741 8.89174 15.4945 8.84299L16.0675 7.47351L17.4785 7.13171C17.5287 7.11954 17.569 7.07959 17.5825 7.02865Z"
        fill="currentColor"
      />
      <path
        d="M24.0512 11.0706C24.0647 11.0197 24.0489 10.9666 24.0106 10.9337L22.9339 10.0088L23.108 8.54584C23.1142 8.49375 23.0911 8.44386 23.0482 8.41711C23.0054 8.39036 22.9504 8.3914 22.9063 8.41981L21.6678 9.21764L20.3645 8.65535C20.3181 8.63533 20.2635 8.64443 20.2236 8.67885C20.1837 8.71327 20.1654 8.76702 20.1765 8.81748L20.4878 10.2354L19.5081 11.3509C19.4733 11.3906 19.4627 11.4461 19.4808 11.4942C19.499 11.5422 19.5427 11.5743 19.5937 11.5771L21.0246 11.6556L21.7224 12.9073C21.7332 12.9266 21.7483 12.9423 21.7662 12.9535C21.7896 12.9681 21.8175 12.975 21.8464 12.9723C21.8975 12.9676 21.9428 12.9337 21.9632 12.885L22.5363 11.5155L23.9473 11.1737C23.9975 11.1615 24.0378 11.1216 24.0512 11.0706Z"
        fill="currentColor"
      />
      <path
        d="M30.4907 15.0941C30.5041 15.0431 30.4884 14.99 30.45 14.9571L29.3733 14.0322L29.5475 12.5693C29.5537 12.5172 29.5305 12.4673 29.4877 12.4405C29.4449 12.4138 29.3899 12.4148 29.3458 12.4432L28.1072 13.2411L26.8039 12.6788C26.7576 12.6588 26.7029 12.6679 26.663 12.7023C26.6231 12.7367 26.6049 12.7905 26.616 12.8409L26.9272 14.2589L25.9476 15.3743C25.9127 15.414 25.9021 15.4696 25.9203 15.5176C25.9384 15.5656 25.9822 15.5978 26.0331 15.6006L27.464 15.679L28.1618 16.9307C28.1726 16.9501 28.1878 16.9658 28.2057 16.977C28.229 16.9915 28.257 16.9985 28.2859 16.9958C28.337 16.991 28.3823 16.9572 28.4027 16.9084L28.9757 15.5389L30.3867 15.1971C30.4369 15.185 30.4773 15.145 30.4907 15.0941Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
