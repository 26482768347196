import React from 'react';

import { formatDate } from '../constants/Function';
import { px } from '../constants/Px';

const startPeriod = `Начало периода`;
const endPeriod = `Конец периода`;

export const Period = ({
  openPicker,
  gap = 14,
  paddingBottom = 20,
  type,
  priceDiscountAction,
  pickerDate,
}) => {
  const data = [
    {
      id: 105,
      title: startPeriod,
      type: 'fishing',
      priceDiscountAction: 'price',
      data: [{ id: 0, subTitle: 'Время', time: pickerDate?.timeStart }],
    },
    {
      id: 106,
      title: endPeriod,
      type: 'fishing',
      priceDiscountAction: 'price',
      data: [{ id: 1, subTitle: 'Время', time: pickerDate?.timeEnd }],
    },
    {
      id: 107,
      title: startPeriod,
      type: 'rest',
      priceDiscountAction: 'price',
      data: [{ id: 25, subTitle: 'Время', time: pickerDate?.restPriceTimeStart }],
    },
    {
      id: 108,
      title: endPeriod,
      type: 'rest',
      priceDiscountAction: 'price',
      data: [{ id: 26, subTitle: 'Время', time: pickerDate?.restPriceTimeEnd }],
    },
    {
      id: 109,
      title: startPeriod,
      type: 'services',
      priceDiscountAction: 'price',
      data: [{ id: 31, subTitle: 'Время', time: pickerDate?.servicesPriceTimeStart }],
    },
    {
      id: 110,
      title: endPeriod,
      type: 'services',
      priceDiscountAction: 'price',
      data: [{ id: 32, subTitle: 'Время', time: pickerDate?.servicesPriceTimeEnd }],
    },
    {
      id: 111,
      title: startPeriod,
      type: 'fishing',
      priceDiscountAction: 'discount',
      data: [
        { id: 21, subTitle: 'Время', time: pickerDate?.fishingDiscountTimeStart },
        {
          id: 37,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.fishingDiscountPeriodStartFirstNumber,
            month: pickerDate?.fishingDiscountPeriodStartSecondNumber,
            year: pickerDate?.fishingDiscountPeriodStartThirdNumber,
          }),
        },
      ],
    },
    {
      id: 112,
      title: endPeriod,
      type: 'fishing',
      priceDiscountAction: 'discount',
      data: [
        { id: 22, subTitle: 'Время', time: pickerDate?.fishingDiscountTimeEnd },
        {
          id: 38,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.fishingDiscountPeriodEndFirstNumber,
            month: pickerDate?.fishingDiscountPeriodEndSecondNumber,
            year: pickerDate?.fishingDiscountPeriodEndThirdNumber,
          }),
        },
      ],
    },
    {
      id: 113,
      title: startPeriod,
      type: 'rest',
      priceDiscountAction: 'discount',
      data: [
        { id: 27, subTitle: 'Время', time: pickerDate?.restDiscountTimeStart },
        {
          id: 41,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.restDiscountPeriodStartFirstNumber,
            month: pickerDate?.restDiscountPeriodStartSecondNumber,
            year: pickerDate?.restDiscountPeriodStartThirdNumber,
          }),
        },
      ],
    },
    {
      id: 114,
      title: endPeriod,
      type: 'rest',
      priceDiscountAction: 'discount',
      data: [
        { id: 28, subTitle: 'Время', time: pickerDate?.restDiscountTimeEnd },
        {
          id: 42,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.restDiscountPeriodEndFirstNumber,
            month: pickerDate?.restDiscountPeriodEndSecondNumber,
            year: pickerDate?.restDiscountPeriodEndThirdNumber,
          }),
        },
      ],
    },
    {
      id: 115,
      title: startPeriod,
      type: 'services',
      priceDiscountAction: 'discount',
      data: [
        { id: 33, subTitle: 'Время', time: pickerDate?.servicesDiscountTimeStart },
        {
          id: 45,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.servicesDiscountPeriodStartFirstNumber,
            month: pickerDate?.servicesDiscountPeriodStartSecondNumber,
            year: pickerDate?.servicesDiscountPeriodStartThirdNumber,
          }),
        },
      ],
    },
    {
      id: 116,
      title: endPeriod,
      type: 'services',
      priceDiscountAction: 'discount',
      data: [
        { id: 34, subTitle: 'Время', time: pickerDate?.servicesDiscountTimeEnd },
        {
          id: 46,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.servicesDiscountPeriodEndFirstNumber,
            month: pickerDate?.servicesDiscountPeriodEndSecondNumber,
            year: pickerDate?.servicesDiscountPeriodEndThirdNumber,
          }),
        },
      ],
    },
    {
      id: 117,
      title: startPeriod,
      type: 'fishing',
      priceDiscountAction: 'action',
      data: [
        { id: 23, subTitle: 'Время', time: pickerDate?.fishingActionTimeStart },
        {
          id: 39,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.fishingActionPeriodStartFirstNumber,
            month: pickerDate?.fishingActionPeriodStartSecondNumber,
            year: pickerDate?.fishingActionPeriodStartThirdNumber,
          }),
        },
      ],
    },
    {
      id: 118,
      title: endPeriod,
      type: 'fishing',
      priceDiscountAction: 'action',
      data: [
        { id: 24, subTitle: 'Время', time: pickerDate?.fishingActionTimeEnd },
        {
          id: 40,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.fishingActionPeriodEndFirstNumber,
            month: pickerDate?.fishingActionPeriodEndSecondNumber,
            year: pickerDate?.fishingActionPeriodEndThirdNumber,
          }),
        },
      ],
    },
    {
      id: 119,
      title: startPeriod,
      type: 'rest',
      priceDiscountAction: 'action',
      data: [
        { id: 29, subTitle: 'Время', time: pickerDate?.restActionTimeStart },
        {
          id: 43,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.restActionPeriodStartFirstNumber,
            month: pickerDate?.restActionPeriodStartSecondNumber,
            year: pickerDate?.restActionPeriodStartThirdNumber,
          }),
        },
      ],
    },
    {
      id: 120,
      title: endPeriod,
      type: 'rest',
      priceDiscountAction: 'action',
      data: [
        { id: 30, subTitle: 'Время', time: pickerDate?.restActionTimeEnd },
        {
          id: 44,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.restActionPeriodEndFirstNumber,
            month: pickerDate?.restActionPeriodEndSecondNumber,
            year: pickerDate?.restActionPeriodEndThirdNumber,
          }),
        },
      ],
    },
    {
      id: 121,
      title: startPeriod,
      type: 'services',
      priceDiscountAction: 'action',
      data: [
        { id: 35, subTitle: 'Время', time: pickerDate?.servicesActionTimeStart },
        {
          id: 47,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.servicesActionPeriodStartFirstNumber,
            month: pickerDate?.servicesActionPeriodStartSecondNumber,
            year: pickerDate?.servicesActionPeriodStartThirdNumber,
          }),
        },
      ],
    },
    {
      id: 122,
      title: endPeriod,
      type: 'services',
      priceDiscountAction: 'action',
      data: [
        { id: 36, subTitle: 'Время', time: pickerDate?.servicesActionTimeEnd },
        {
          id: 48,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.servicesActionPeriodEndFirstNumber,
            month: pickerDate?.servicesActionPeriodEndSecondNumber,
            year: pickerDate?.servicesActionPeriodEndThirdNumber,
          }),
        },
      ],
    },
  ]
    .filter(item => item?.type === type)
    .filter(item => item?.priceDiscountAction === priceDiscountAction);

  return (
    <div
      style={{
        gap: px(gap),
      }}
      className="flex"
    >
      {data.map(item => (
        <div
          style={{
            width: px(139),
          }}
          key={item?.id}
          className="flex flex-col"
        >
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal  opacity-50"
          >
            {item?.title}
          </p>

          <div
            style={{
              gap: px(10),
            }}
            className="flex flex-row"
          >
            {item?.data?.map(element => (
              <div key={element?.id}>
                {element?.subTitle && (
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      paddingBottom: px(paddingBottom),
                    }}
                    className="font-normal  opacity-50"
                  >
                    {element?.subTitle}
                  </p>
                )}

                {element?.time && (
                  <>
                    <div
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                      }}
                      className="font-normal text-black"
                      onClick={() => {
                        openPicker(element?.id);
                      }}
                      onKeyDown={() => {
                        openPicker(element?.id);
                      }}
                    >
                      {element?.time}
                    </div>
                    <hr
                      className="text-black opacity-30"
                      style={{
                        marginTop: px(5),
                      }}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Period;
