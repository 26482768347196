import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import mapBtnImg from '../../assets/images/map-btn-img.png';
import { FavouritesCards } from '../../components/favourites-cards';
import { Icon } from '../../components/Icon';
import { StateContext } from '../../components/state-provider';
import { px } from '../../constants/Px';
import { CircleButton, ScrollButtonsContainer } from '../../new-architecture/shared';
import { authSelector } from '../../selectors/auth';
import { favouritesSelector } from '../../selectors/favourites';
import { TypesIcon } from '../../types/TypesIcon';

export const FavouritesPage = () => {
  const navigate = useNavigate();
  const [scrollActive, setScrollActive] = useState(false);
  const prevScrollTop = useRef(0);
  const cards = useSelector(favouritesSelector);
  const auth = useSelector(authSelector);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = document.documentElement;
      if (scrollTop > prevScrollTop.current) {
        setScrollActive(false);
      } else if (scrollTop < prevScrollTop.current) {
        setScrollActive(true);
      }
      prevScrollTop.current = scrollTop;

      /* const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight; */
      /* setScrollActiveBottom(window.scrollY >= scrollableHeight - 800); */
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!auth) navigate('/login');
  }, []);

  return (
    <>
      <div className="home-add-popup-container" style={{ height: px(60) }}>
        <div className="home-add-popup-container-overflow" style={{ height: px(60) }}>
          <div className="home-add-popup-content">
            <p className="absolute w-full text-center leading-[27px]" style={{ top: px(21) }}>
              Избранное
            </p>
            <div
              aria-hidden
              className="search-menu-item-preferences-close"
              onClick={() => {
                navigate('/user-account');
              }}
            >
              <Icon type={TypesIcon.CLOSE} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: `100dvh`,
          width: px(320),
          backgroundColor: 'rgba(11, 131, 254, 0.3)',
        }}
      >
        <section
          style={{
            position: 'relative',
            paddingTop: px(80),
            paddingBottom: px(20),
            zIndex: 5,
            minWidth: px(320),
            /* width: '100vw', */
          }}
        >
          {cards?.filter(card => !card?.event?.deletedAt)?.length === 0 && (
            <div className="container" style={{ width: '100%' }}>
              <p
                className="text-center  font-normal "
                style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: px(20), lineHeight: px(26) }}
              >
                Ничего не найдено
              </p>
            </div>
          )}
          <div className="container" style={{ width: '100%' }}>
            <div className="row events-cards-container show-all">
              <FavouritesCards />
            </div>
          </div>
        </section>
      </div>
      <ScrollButtonsContainer>
        <CircleButton
          onClick={() => {
            navigate('/filter');
          }}
          icon={{
            type: TypesIcon.SETTINGSFILTER,
            viewBox: '0 0 20 20',
            width: px(20),
            height: px(20),
            fill: '#ffffff',
            stroke: '#0a84fe',
          }}
        />
        <CircleButton
          onClick={() => {
            navigate('/map');
          }}
        >
          <img src={mapBtnImg} alt="action-map-button" className="map-btn-img " />
        </CircleButton>
      </ScrollButtonsContainer>
    </>
  );
};
