import React, { createContext, useMemo, useState } from 'react';

import { INITIAL_STATE } from '../constants';

export const competitionContext = createContext(undefined);

export const CompetitionProvider = ({ children }) => {
  const [data, setData] = useState(INITIAL_STATE);
  const [updateFunction, setUpdateFunction] = useState(() => {});

  const value = useMemo(
    () => ({
      data,
      setData: payload =>
        setData(prevState => ({
          ...prevState,
          ...payload,
        })),
      updateFunction,
      setUpdateFunction,
      initialState: INITIAL_STATE,
    }),
    [data, updateFunction],
  );

  return <competitionContext.Provider value={value}>{children}</competitionContext.Provider>;
};

export default CompetitionProvider;
