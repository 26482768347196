import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { DraggableModal } from './draggable-modal';

export const Modal = ({ noDraggable, children, isOpen, onClose, title, isLine }) => {
  const modalRoot = document.querySelector('#modal');
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
    return () => document.documentElement.classList.remove('lock');
  }, [isOpen]);

  if (isOpen) {
    if (!isMounted) {
      setIsMounted(true);
    }
  } else if (isMounted) {
    setIsMounted(false);
  }

  // рендерим модальное окно в портале
  return isOpen && isMounted
    ? noDraggable
      ? ReactDOM.createPortal(<div>{children}</div>, modalRoot)
      : ReactDOM.createPortal(
          <DraggableModal show={isOpen} onClose={onClose} title={title} isLine={isLine}>
            {children}
          </DraggableModal>,
          modalRoot,
        )
    : undefined;
};

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return { isOpen, openModal, closeModal, setIsOpen };
};
