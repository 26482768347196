import React from 'react';

import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { ScrollButtonsContainer } from '../new-architecture/shared/scroll-buttons-container/scroll-buttons-container';
import { TypesIcon } from '../types/TypesIcon';
import { SearchInputResultsItem } from './search-input-results-item';
import { SearchInputResultsNoItem } from './search-input-results-no-item';

export const SearchInputResults = ({
  searchInputResults,
  setSearchInputOpen,
  clearSearchInput,
  setShowSearch,
}) => (
  <div className="search-input-results-container-overflow">
    <div
      className={`search-input-results-container ${searchInputResults.length <= 0 && 'no-items'}`}
    >
      {searchInputResults
        .filter(result => !result.deletedAt)
        .map((searchInputResult, key) => (
          <SearchInputResultsItem
            key={key}
            svg={searchInputResult.svg}
            title={searchInputResult.name}
            location={searchInputResult.EventContact}
            object={searchInputResult}
            eventTypeId={searchInputResult.eventTypeId}
            setShowSearch={setShowSearch}
          />
        ))}
      {searchInputResults.length <= 0 && <SearchInputResultsNoItem />}
      <ScrollButtonsContainer>
        <CircleButton
          onClick={() => {
            setSearchInputOpen(false);
            clearSearchInput();
          }}
          icon={{
            type: TypesIcon.ROUNDEDRETURN,
            viewBox: '0 0 28.451 26.164',
            width: px(28.451),
            height: px(26.164),
            className: 'text-[#0a84fe]',
            fill: '#0a84fe',
          }}
        />
      </ScrollButtonsContainer>
    </div>
  </div>
);
