/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable unicorn/no-useless-spread */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updatePrice } from '../../../../../../../actions/section-price';
import { interestData, period } from '../../../../../../../constants/Component/date-picker';
import { Picker, PickerInput } from '../../../../../../shared';
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';
import { periodData } from '../../../../constants/picker-data';

export const PriceInfo = ({ locationType, setPriceValue, setPriceStart, setPriceEnd }) => {
  const [showHelp, setShowHelp] = useState(false);
  const [showWithNorm, setShowWithNorm] = useState(false);
  const [showCost, setShowcost] = useState(false);
  const [showCaughtReleaseChecked, setShowCaughtReleaseChecked] = useState(false);
  const [showWithoutNorm, setShowWithoutNorm] = useState(false);
  const [showObjectCheckoutPenalty, setShowObjectCheckoutPenalty] = useState(false);
  const [showObjectWeekendExtraCharge, setShowObjectWeekendExtraCharge] = useState(false);
  const [showWeekendExtraCharge, setShowWeekendExtraCharge] = useState(false);
  const [showCheckoutPenalty, setShowCheckoutPenalty] = useState(false);
  const [showDemage, setShowDemage] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showObjectCheckoutTimeLimit, setShowObjectCheckoutTimeLimit] = useState(false);
  const [showCheckoutTimeLimit, setShowCheckoutTimeLimit] = useState(false);
  const [showStartPeriodPicker, setShowStartPeriodPicker] = useState(false);
  const [showEndPeriodPicker, setShowEndPeriodPicker] = useState(false);
  const dispatch = useDispatch();

  const {
    data: { priceGroup, currentPriceGroup },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  /*  const setPriceGroup = () =>
    setProfile({
      priceGroup: priceGroup.map(item =>
        item.id === currentPriceGroup.id ? currentPriceGroup : item,
      ),
    }); */

  const setCurrentPriceGroup = fields =>
    setProfile({
      currentPriceGroup: {
        ...currentPriceGroup,
        ...fields,
      },
    });

  const {
    caughtValue,
    damagePenalty,
    deposit,
    helpValue,
    isCaught,
    isHelp,
    isWithNorm,
    isWithoutNorm,
    withNormValue,
    withoutNormValue,
    id,
    type,
    periodStart,
    periodEnd,
    weekendExtraCharge,
    checkoutPenalty,
    checkoutTimeLimit,
    cost,
    objectCheckoutPenalty,
    objectWeekendExtraCharge,
    objectCheckoutTimeLimit,
    sectionGroupId,
  } = currentPriceGroup || {};

  useEffect(() => {
    if (!weekendExtraCharge) setCurrentPriceGroup({ weekendExtraCharge: '0%' });
    if (isHelp === null) setCurrentPriceGroup({ isHelp: false });
    if (isCaught === null) setCurrentPriceGroup({ isCaught: false });
    if (isWithNorm === null) setCurrentPriceGroup({ isWithNorm: false });
    if (isWithoutNorm === null) setCurrentPriceGroup({ isWithoutNorm: false });
  }, []);

  useEffect(() => {
    if (currentPriceGroup)
      dispatch(
        updatePrice({
          data: {
            // caughtReleaseChecked,
            caughtValue,
            damagePenalty,
            deposit,
            helpValue,
            isCaught,
            isHelp,
            isWithNorm,
            isWithoutNorm,
            withNormValue,
            withoutNormValue,
            id,
            type,
            periodStart,
            periodEnd,
            weekendExtraCharge,
            checkoutPenalty,
            checkoutTimeLimit,
            cost,
            objectCheckoutPenalty,
            objectWeekendExtraCharge,
            objectCheckoutTimeLimit,
            sectionGroupId,
          },
          id: currentPriceGroup.id,
        }),
      );
    setTimeout(() => updateProfile(), 100);
  }, [currentPriceGroup]);

  useEffect(() => {
    setProfile({
      currentPriceGroup: {
        ...currentPriceGroup,
      },
    });
  }, []);

  const tariffForFirstGuest = [
    {
      id: 1,
      title: 'Поймал - отпусти',
      isCheckbox: true,
      checkedValue: 'isCaught',
      checkValue: 'caughtValue',
      value: 'caughtValue',
      show: showCaughtReleaseChecked,
      setShow: setShowCaughtReleaseChecked,
    },
    {
      id: 2,
      title: 'Без нормы вылова',
      isCheckbox: true,
      checkedValue: 'isWithoutNorm',
      checkValue: 'withoutNormValue',
      value: 'withoutNormValue',
      show: showWithoutNorm,
      setShow: setShowWithoutNorm,
    },
    {
      id: 3,
      title: 'С нормой вылова',
      isCheckbox: true,
      checkedValue: 'isWithNorm',
      checkValue: 'withNormValue',
      value: 'withNormValue',
      show: showWithNorm,
      setShow: setShowWithNorm,
    },
    {
      id: 4,
      title: 'Помощник (1 чел.)',
      isCheckbox: true,
      checkedValue: 'isHelp',
      checkValue: 'helpValue',
      value: 'helpValue',
      show: showHelp,
      setShow: setShowHelp,
    },
    {
      id: 5,
      title: 'Наценка на выходные',
      isCheckbox: false,
      value: 'weekendExtraCharge',
      show: showWeekendExtraCharge,
      setShow: setShowWeekendExtraCharge,
    },
    {
      id: 6,
      title: 'Штраф за поздний выезд (1 час)',
      isCheckbox: false,
      value: 'checkoutPenalty',
      show: showCheckoutPenalty,
      setShow: setShowCheckoutPenalty,
    },
  ];

  const objectOnTheCoast = [
    {
      id: 1,
      title: 'Стоимость аренды',
      checkValue: 'rentCost',
      value: 'cost',
      type: 'FISHING',
      show: showCost,
      setShow: setShowcost,
    },
    {
      id: 2,
      title: 'Наценка на выходные',
      checkValue: 'objectWekendExtraCharge',
      value: 'objectWeekendExtraCharge',
      type: 'FISHING',
      show: showObjectWeekendExtraCharge,
      setShow: setShowObjectWeekendExtraCharge,
    },
    {
      id: 3,
      title: 'Штраф за поздний выезд (1 час)',
      checkValue: 'objectCheckoutPenalty',
      value: 'objectCheckoutPenalty',
      type: 'FISHING',
      show: showObjectCheckoutPenalty,
      setShow: setShowObjectCheckoutPenalty,
    },
    {
      id: 1,
      title: 'Стоимость аренды',
      checkValue: 'rentCost',
      value: 'cost',
      type: 'REST',
      show: showCost,
      setShow: setShowcost,
    },
    {
      id: 2,
      title: 'Наценка на выходные',
      checkValue: 'objectWekendExtraCharge',
      value: 'objectWeekendExtraCharge',
      type: 'REST',
      show: showObjectWeekendExtraCharge,
      setShow: setShowObjectWeekendExtraCharge,
    },
    {
      id: 3,
      title: 'Штраф за поздний выезд (1 час)',
      checkValue: 'objectCheckoutPenalty',
      value: 'objectCheckoutPenalty',
      type: 'REST',
      show: showObjectCheckoutPenalty,
      setShow: setShowObjectCheckoutPenalty,
    },
    {
      id: 1,
      title: 'Стоимость',
      checkValue: 'rentCost',
      value: 'cost',
      type: 'SERVICE',
      show: showCost,
      setShow: setShowcost,
    },
    {
      id: 2,
      title: 'Наценка на выходные',
      checkValue: 'objectWekendExtraCharge',
      value: 'objectWeekendExtraCharge',
      type: 'SERVICE',
      show: showObjectWeekendExtraCharge,
      setShow: setShowObjectWeekendExtraCharge,
    },
    {
      id: 3,
      title: 'Стоимость залога',
      checkValue: 'deposit',
      value: 'deposit',
      type: 'SERVICE',
      show: showDeposit,
      setShow: setShowDeposit,
    },
    {
      id: 4,
      title: 'Штраф за порчу',
      checkValue: 'damagePenalty',
      value: 'damagePenalty',
      type: 'SERVICE',
      show: showDemage,
      setShow: setShowDemage,
    },
    {
      id: 5,
      title: 'Штраф за поздний выезд (1 час)',
      checkValue: 'objectCheckoutPenalty',
      value: 'objectCheckoutPenalty',
      type: 'SERVICE',
      show: showObjectCheckoutPenalty,
      setShow: setShowObjectCheckoutPenalty,
    },
  ];

  useEffect(() => {
    if (caughtValue === '0' || caughtValue === '') setCurrentPriceGroup({ isCaught: false });
    if (withNormValue === '0' || withNormValue === '') setCurrentPriceGroup({ isWithNorm: false });
    if (withoutNormValue === '0' || withoutNormValue === '')
      setCurrentPriceGroup({ isWithoutNorm: false });
    if (helpValue === '0' || helpValue === '') setCurrentPriceGroup({ isHelp: false });
  }, [caughtValue, withNormValue, withoutNormValue, helpValue]);

  useEffect(() => {
    if (locationType === 'FISHING') {
      const priceArray = [];
      if (isCaught && caughtValue !== '0' && caughtValue) priceArray.push(+caughtValue);
      if (isHelp && helpValue !== '0' && helpValue) priceArray.push(+helpValue);
      if (isWithNorm && withNormValue !== '0' && withNormValue) priceArray.push(+withNormValue);
      if (isWithoutNorm && withoutNormValue !== '0' && withoutNormValue)
        priceArray.push(+withoutNormValue);
      if (cost !== '0' && cost) priceArray.push(+cost);
      if (priceArray.length > 0) setPriceValue(Math.min(...priceArray));
      else setPriceValue(0);
    } else {
      setPriceValue(cost);
    }
  }, [
    caughtValue,
    withNormValue,
    withoutNormValue,
    helpValue,
    cost,
    isCaught,
    isHelp,
    isWithNorm,
    isWithoutNorm,
  ]);

  useEffect(() => {
    setPriceStart(periodStart);
    setPriceEnd(periodEnd);
  }, [periodStart, periodEnd]);

  return (
    <>
      <div
        className="flex flex-col"
        style={{
          gap: px(10),
          marginBottom: px(40),
        }}
      >
        <div>
          <p style={{ fontSize: px(20), lineHeight: px(26) }} className="font-normal">
            Период
          </p>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            Указать время работы
          </p>
        </div>
        <div
          style={{
            gap: px(36),
          }}
          className="flex"
        >
          <div
            style={{
              width: px(139),
            }}
            className="flex flex-col"
          >
            <Picker
              singleItem
              title="Период. Время"
              show={showStartPeriodPicker}
              onClose={() => setShowStartPeriodPicker(false)}
              data={{
                periodStart: periodData.period,
              }}
              onChange={({ periodStart }) => setCurrentPriceGroup({ periodStart })}
              value={{
                periodStart,
              }}
              button
              isLine
            />
            <Picker
              singleItem
              title="Период. Время"
              show={showEndPeriodPicker}
              onClose={() => setShowEndPeriodPicker(false)}
              data={{
                periodEnd: periodData.period,
              }}
              onChange={({ periodEnd }) => setCurrentPriceGroup({ periodEnd })}
              value={{
                periodEnd,
              }}
              button
              isLine
            />
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal  opacity-50"
            >
              Начало периода
            </p>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-row"
            >
              <div>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    paddingBottom: px(13),
                  }}
                  className="font-normal  opacity-50"
                >
                  Время
                </p>

                <div
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                  className="font-normal text-black"
                  onClick={() => setShowStartPeriodPicker(true)}
                  onKeyDown={() => setShowStartPeriodPicker(true)}
                >
                  {periodStart || '00:00'}
                </div>
                <hr
                  className="text-black opacity-30"
                  style={{
                    marginTop: px(5),
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: px(139),
            }}
            className="flex flex-col"
          >
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal  opacity-50"
            >
              Конец периода
            </p>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-row"
            >
              <div>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    paddingBottom: px(13),
                  }}
                  className="font-normal  opacity-50"
                >
                  Время
                </p>

                <div
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                  className="font-normal text-black"
                  onClick={() => setShowEndPeriodPicker(true)}
                  onKeyDown={() => setShowEndPeriodPicker(true)}
                >
                  {periodEnd || '00:00'}
                </div>
                <hr
                  className="text-black opacity-30"
                  style={{
                    marginTop: px(5),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {locationType === 'FISHING' && (
          <>
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                paddingBottom: px(18),
              }}
              className="font-normal"
            >
              Тариф для 1-го гостя
            </p>
            <div
              style={{
                gap: px(14),
              }}
              className="flex  flex-col justify-center"
            >
              {tariffForFirstGuest?.map(item => (
                <div key={item.id} className="flex">
                  <div className="flex w-full  items-center justify-between ">
                    <div
                      style={{
                        width: px(item?.isCheckbox && 169),
                        fontSize: px(15),
                        lineHeight: px(20),
                      }}
                      className="font-normal"
                    >
                      {item.title}
                    </div>
                    <div
                      style={{
                        width: px(item?.isCheckbox && 121),
                      }}
                      className={`flex items-center ${item?.isCheckbox && 'justify-between'} `}
                    >
                      {item?.isCheckbox && (
                        <div
                          aria-hidden
                          onClick={() => {
                            item.id === 1 &&
                              caughtValue !== '0' &&
                              caughtValue &&
                              setCurrentPriceGroup({ isCaught: !isCaught });
                            item.id === 2 &&
                              withoutNormValue !== '0' &&
                              withoutNormValue &&
                              setCurrentPriceGroup({ isWithoutNorm: !isWithoutNorm });
                            item.id === 3 &&
                              withNormValue !== '0' &&
                              withNormValue &&
                              setCurrentPriceGroup({ isWithNorm: !isWithNorm });
                            item.id === 4 &&
                              helpValue !== '0' &&
                              helpValue &&
                              setCurrentPriceGroup({ isHelp: !isHelp });
                          }}
                          className={` cursor-pointer ${
                            currentPriceGroup?.[item.checkedValue]
                              ? 'bg-[#0a84fe]'
                              : 'border-[1px] border-solid border-[#000] bg-white'
                          }`}
                          style={{
                            width: px(17),
                            height: px(17),
                          }}
                        />
                      )}
                      <div
                        onClick={() => {
                          item.setShow(true);
                        }}
                        onKeyDown={() => {
                          item.setShow(true);
                        }}
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          color:
                            currentPriceGroup?.[item.checkedValue] ||
                            (currentPriceGroup[item.value] &&
                              currentPriceGroup[item.value] !== '0' &&
                              currentPriceGroup[item.value] !== '0%')
                              ? '#0a84fe'
                              : 'black',
                        }}
                        className="font-normal"
                      >
                        {item.value === 'weekendExtraCharge'
                          ? currentPriceGroup[item.value] || '0%'
                          : currentPriceGroup[item.value] || '0'}
                      </div>
                    </div>
                  </div>
                  {item.title === 'Наценка на выходные' ? (
                    <Picker
                      singleItem
                      title="Наценка на выходные"
                      show={item.show}
                      onClose={() => item.setShow(false)}
                      data={{
                        weekendExtraCharge: interestData.interest,
                      }}
                      onChange={({ weekendExtraCharge }) =>
                        setCurrentPriceGroup({ weekendExtraCharge })
                      }
                      value={currentPriceGroup[item.value] || '0 %'}
                      button
                      isLine
                    />
                  ) : (
                    <PickerInput
                      singleItem
                      title="Стоимость. Цена"
                      show={item.show}
                      onClose={() => item.setShow(false)}
                      onClick={vl =>
                        setCurrentPriceGroup({ ...currentPriceGroup, ...{ [item.value]: vl } })
                      }
                      /* onChange={vl =>
                      setCurrentPriceGroup({ ...currentPriceGroup, ...{ [item.value]: vl } })
                    } */
                      value={currentPriceGroup[item.value]}
                      button
                      isLine
                    />
                  )}
                </div>
              ))}
              <div
                style={{
                  paddingTop: px(3),
                }}
              >
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                  className="font-normal opacity-50"
                >
                  Предельное время позднего выезда
                </p>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                  className="font-normal opacity-50"
                >
                  Период
                </p>
              </div>
              <div>
                <div
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    paddingBottom: px(3),
                  }}
                  className="inline-block border-b-[1px] border-solid border-black border-opacity-30 font-normal"
                  onClick={() => setShowCheckoutTimeLimit(true)}
                  aria-hidden
                >
                  {checkoutTimeLimit || '1 час'}
                </div>
              </div>
              <Picker
                singleItem
                title="Период. Время"
                show={showCheckoutTimeLimit}
                onClose={() => setShowCheckoutTimeLimit(false)}
                data={{
                  checkoutTimeLimit: period.period,
                }}
                onChange={({ checkoutTimeLimit }) => setCurrentPriceGroup({ checkoutTimeLimit })}
                value={{
                  checkoutTimeLimit,
                }}
                button
                isLine
              />
            </div>
          </>
        )}

        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            marginTop: px(40),
            paddingBottom: px(17),
          }}
          className="font-normal"
        >
          {locationType === 'FISHING'
            ? 'Объект на берегу. Наценка'
            : locationType === 'REST'
              ? 'Тариф. Наценки'
              : 'Тариф. Наценки на Услуги'}
        </p>
        <div
          style={{
            gap: px(14),
          }}
          className="flex  flex-col justify-center"
        >
          {objectOnTheCoast
            ?.filter(item => item.type === locationType)
            .map(item => (
              <div key={item.id} className="flex">
                <div className="flex w-full  items-center justify-between ">
                  <div style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
                    {item.title}
                  </div>
                  <div className="flex items-center">
                    <div
                      aria-hidden
                      onClick={() => {
                        item.setShow(true);
                      }}
                      onKeyDown={() => {
                        item.setShow(true);
                      }}
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                        color:
                          currentPriceGroup[item.value] &&
                          currentPriceGroup[item.value] !== '0' &&
                          currentPriceGroup[item.value] !== '0%'
                            ? '#0a84fe'
                            : 'black',
                      }}
                      className="font-normal"
                    >
                      {item.value === 'objectWeekendExtraCharge'
                        ? currentPriceGroup[item.value] || '0%'
                        : currentPriceGroup[item.value] || '0'}
                    </div>
                  </div>
                </div>
                {item.title === 'Наценка на выходные' ? (
                  <Picker
                    singleItem
                    title="Наценка на выходные"
                    show={item.show}
                    onClose={() => item.setShow(false)}
                    data={{
                      objectWeekendExtraCharge: interestData.interest,
                    }}
                    onChange={({ objectWeekendExtraCharge }) =>
                      setCurrentPriceGroup({ objectWeekendExtraCharge })
                    }
                    value={currentPriceGroup[item.value] || '0 %'}
                    button
                    isLine
                  />
                ) : (
                  <PickerInput
                    singleItem
                    title="Стоимость. Цена"
                    show={item.show}
                    onClose={() => item.setShow(false)}
                    onClick={vl =>
                      setCurrentPriceGroup({ ...currentPriceGroup, ...{ [item.value]: vl } })
                    }
                    value={currentPriceGroup[item.value]}
                    button
                    isLine
                  />
                )}
                {/* <PickerInput
                  singleItem
                  title="Стоимость. Цена"
                  show={item.show}
                  onClose={() => item.setShow(false)}
                  onChange={vl =>
                    setCurrentPriceGroup({ ...currentPriceGroup, ...{ [item.value]: vl } })
                  }
                  value={currentPriceGroup[item.value]}
                  button
                  isLine
                /> */}
              </div>
            ))}
        </div>

        <div
          style={{
            paddingTop: px(18),
            paddingBottom: px(13),
          }}
        >
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal opacity-50"
          >
            Предельное время позднего выезда
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal opacity-50"
          >
            Период
          </p>
        </div>
        <div>
          <div
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingBottom: px(5),
            }}
            className="inline-block border-b-[1px] border-solid border-black border-opacity-30 font-normal"
            onClick={() => setShowObjectCheckoutTimeLimit(true)}
            aria-hidden
          >
            {objectCheckoutTimeLimit || '1 час'}
          </div>
          <Picker
            singleItem
            title="Период. Время"
            show={showObjectCheckoutTimeLimit}
            onClose={() => setShowObjectCheckoutTimeLimit(false)}
            data={{
              objectCheckoutTimeLimit: period.period,
            }}
            onChange={({ objectCheckoutTimeLimit }) =>
              setCurrentPriceGroup({ objectCheckoutTimeLimit })
            }
            value={{
              objectCheckoutTimeLimit,
            }}
            button
            isLine
          />
        </div>
      </div>
    </>
  );
};

export default PriceInfo;
