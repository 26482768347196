import { createReducer } from '@reduxjs/toolkit';
import { getObjectFeedback, getObjectFeedbackNoAuth } from '../../actions/event-review';

export const eventReview = createReducer({}, (builder) => {
  builder.addCase(getObjectFeedback.fulfilled, (state, { payload }) => ({
    ...state,
    feedback: payload?.data,
    rating: (payload?.data?.reduce((sum, current) => sum + current.totalRating, 0)) / payload?.data?.length
  }));
});

export const eventReviewNoAuth = createReducer({}, (builder) => {
  builder.addCase(getObjectFeedbackNoAuth.fulfilled, (state, { payload }) => ({
    ...state,
    feedbackNoAuth: payload?.data,
    ratingNoAuth: (payload?.data?.reduce((sum, current) => sum + current.totalRating, 0)) / payload?.data?.length
  }));
});
