import { getProfile } from '../../../../../../actions/profile';
import { addAchievement as addAchievementApi } from '../../../../../../api/achievement';
import { addDeparture as addDepartureApi } from '../../../../../../api/departure';
import { addDocument as addDocumentApi } from '../../../../../../api/document';
import { deleteImage } from '../../../../../../api/image';
import {
  getProfile as getProfileApi,
  getProfileById as getProfileIdApi,
  profilePassport as profilePassportApi,
  profileSocials as profileSocualsApi,
  updateProfile as updateProfileApi,
} from '../../../../../../api/profile';
import {
  addRecommendation as addRecommendationApi,
  deleteRecommendation as deleteRecommendationApi,
} from '../../../../../../api/recommendation';
import { urlToBlob } from '../../../../../utils/blob';

export const updateProfile = async (dispatch, fields, id) => {
  try {
    const { data: profile } = id ? await getProfileIdApi({ id }) : await getProfileApi();

    // REMOVING IMAGES
    if (profile?.passportDetails?.image?.id) {
      await deleteImage({ id: profile?.passportDetails?.image?.id });
    }

    if (profile?.sportAchivments?.image?.id) {
      await deleteImage({ id: profile?.sportAchivments?.image?.id });
    }

    if (profile?.departure?.id) {
      if (profile?.departure?.images?.length) {
        await Promise.all?.(
          profile?.departure?.images?.map(image => deleteImage({ id: image?.id })),
        );
      }
    }

    if (profile?.documents?.id) {
      if (profile?.documents?.images?.length) {
        await Promise.all?.(
          profile?.documents?.images?.map(image => deleteImage({ id: image?.id })),
        );
      }
    }

    // REMOVING OTHER STUFF
    if (profile?.recommends?.length) {
      await Promise.all(profile?.recommends?.map?.(rec => deleteRecommendationApi({ id: rec.id })));
    }

    // UPDATING DOCUMENTS AND DEPARTMENTS
    if (fields?.documents?.length) {
      const formData = new FormData();

      await Promise.all(
        fields?.documents?.map?.(async (file, index) =>
          formData.append('files', await urlToBlob(file?.src, index)),
        ),
      );

      await addDocumentApi({ data: formData, id: profile?.id });
    }

    if (fields?.departures?.length) {
      const formData = new FormData();

      await Promise.all(
        fields?.departures?.map?.(async (file, index) =>
          formData.append('files', await urlToBlob(file?.src, index)),
        ),
      );

      await addDepartureApi({ data: formData, id: profile?.id });
    }

    // UPDATING PASSPORT DATA
    const passportData = new FormData();
    passportData.append('passportNumber', fields?.passportDetails?.passportNumber);
    passportData.append('issuedBy', fields?.passportDetails?.issuedBy);
    passportData.append('divisionCode', fields?.passportDetails?.divisionCode);
    passportData.append('issueDate', fields?.passportDetails?.issueDate);
    if (fields?.passportDetails?.image)
      passportData.append('file', await urlToBlob(fields?.passportDetails?.image));
    await profilePassportApi({ data: passportData, id: profile?.id });

    // UPDATING ACHIEVEMENT DATA
    const achivmentData = new FormData();
    achivmentData.append('club', fields?.sportAchivments?.club);
    achivmentData.append('team', fields?.sportAchivments?.team);
    if (fields?.sportAchivments?.image)
      achivmentData.append('file', await urlToBlob(fields?.sportAchivments?.image));
    await addAchievementApi({ data: achivmentData, id: profile?.id });

    // UPDATING RECCOMENDATIONS
    if (fields?.recommends?.[0])
      await addRecommendationApi({
        data: {
          recommendedId: Number.parseInt(fields?.recommends?.[0], 10),
        },
        id: id || profile?.id,
      });

    if (fields?.recommends?.[1])
      await addRecommendationApi({
        data: {
          recommendedId: Number.parseInt(fields?.recommends?.[1], 10),
        },
        id: id || profile?.id,
      });

    // UPDATING PROFILE SOCIALS DATA
    await profileSocualsApi({
      whatsappLink: fields?.socials?.whatsupLink,
      whatsappLinkVisible: fields?.socials?.whatsupLinkVisible,
      telegramLink: fields?.socials?.telegramLink,
      telegramLinkVisible: fields?.socials?.telegramLinkVisible,
      viberLink: fields?.socials?.viberLink,
      viberLinkVisible: fields?.socials?.viberLinkVisible,
      id: profile?.id,
    });

    // UPDATING PROFILE DATA
    await updateProfileApi({
      name: fields?.name,
      surname: fields?.surname,
      country: fields?.country,
      town: fields?.town,
      id: profile?.id,
    });

    await updateProfileApi({
      phone: fields?.phone,
      id: profile?.id,
    });

    // GETTING THE NEW PROFILE OBJECT AND PUTTING IT IN REDUX
    await dispatch(getProfile());
  } catch {
    console.log('error');
  }
};
