import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { px } from '../constants/Px';
import { schemaObjectSelector } from '../selectors/get-event';
import { ObjectDescriptionTitles } from './object-description-titles';
import { SingleSlider } from './single-slider';

export const MapsObjectDescription = () => {
  const schema = useSelector(schemaObjectSelector);
  /* const [index, setIndex] = useState(0); */

  console.log('schemaObjectSelector', schemaObjectSelector);

  return (
    <>
      <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
        <ObjectDescriptionTitles
          text={'Дополнительные материалы\nулучшат привлекательность и сервис'}
          title="Карты. Схемы. …"
          titleLineHeight={43}
        />
      </div>

      <div
        style={{
          marginTop: px(20),
          marginBottom: px(40),
        }}
      >
        <SingleSlider images={schema} />
      </div>
    </>
  );
};

export default MapsObjectDescription;
