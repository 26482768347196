import React from 'react';

export const AlcoveIcon = props => (
  <svg
    viewBox="0 0 35 27"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4031_1952)">
      <g opacity="0.503">
        <path
          d="M27.2189 11.855H18.1729C17.5283 11.855 17.0059 12.3775 17.0059 13.022C17.0059 13.6665 17.5283 14.189 18.1729 14.189H27.2189C27.8634 14.189 28.3859 13.6665 28.3859 13.022C28.3859 12.3775 27.8634 11.855 27.2189 11.855Z"
          fill="currentColor"
        />
        <path
          d="M27.2189 12.355H18.1729C17.8045 12.355 17.5059 12.6536 17.5059 13.022C17.5059 13.3904 17.8045 13.689 18.1729 13.689H27.2189C27.5872 13.689 27.8859 13.3904 27.8859 13.022C27.8859 12.6536 27.5872 12.355 27.2189 12.355Z"
          stroke="currentColor"
        />
        <path
          d="M27.0989 17.855H18.1269C17.5077 17.855 17.0059 18.3569 17.0059 18.976V18.977C17.0059 19.5961 17.5077 20.098 18.1269 20.098H27.0989C27.718 20.098 28.2199 19.5961 28.2199 18.977V18.976C28.2199 18.3569 27.718 17.855 27.0989 17.855Z"
          fill="currentColor"
        />
        <path
          d="M27.0989 18.355H18.1269C17.7839 18.355 17.5059 18.633 17.5059 18.976V18.977C17.5059 19.3199 17.7839 19.598 18.1269 19.598H27.0989C27.4418 19.598 27.7199 19.3199 27.7199 18.977V18.976C27.7199 18.633 27.4418 18.355 27.0989 18.355Z"
          stroke="currentColor"
        />
        <path
          d="M12.6875 13.245V25.566C12.6875 26.1851 13.1894 26.687 13.8085 26.687H13.8095C14.4286 26.687 14.9305 26.1851 14.9305 25.566V13.245C14.9305 12.6259 14.4286 12.124 13.8095 12.124H13.8085C13.1894 12.124 12.6875 12.6259 12.6875 13.245Z"
          fill="currentColor"
        />
        <path
          d="M13.1875 13.245V25.566C13.1875 25.909 13.4655 26.187 13.8085 26.187H13.8095C14.1525 26.187 14.4305 25.909 14.4305 25.566V13.245C14.4305 12.902 14.1525 12.624 13.8095 12.624H13.8085C13.4655 12.624 13.1875 12.902 13.1875 13.245Z"
          stroke="currentColor"
        />
        <path
          d="M6.6875 19.097C6.6875 19.6493 7.13522 20.097 7.6875 20.097C8.23978 20.097 8.6875 19.6493 8.6875 19.097C8.6875 18.5448 8.23978 18.097 7.6875 18.097C7.13522 18.097 6.6875 18.5448 6.6875 19.097Z"
          fill="currentColor"
        />
        <path
          d="M7.1875 19.097C7.1875 19.3732 7.41136 19.597 7.6875 19.597C7.96364 19.597 8.1875 19.3732 8.1875 19.097C8.1875 18.8209 7.96364 18.597 7.6875 18.597C7.41136 18.597 7.1875 18.8209 7.1875 19.097Z"
          stroke="currentColor"
        />
        <path
          d="M16.8398 13.246V18.976C16.8398 19.5952 17.3417 20.097 17.9608 20.097H17.9618C18.581 20.097 19.0828 19.5952 19.0828 18.976V13.246C19.0828 12.6269 18.581 12.125 17.9618 12.125H17.9608C17.3417 12.125 16.8398 12.6269 16.8398 13.246Z"
          fill="currentColor"
        />
        <path
          d="M17.3398 13.246V18.976C17.3398 19.319 17.6179 19.597 17.9608 19.597H17.9618C18.3048 19.597 18.5828 19.319 18.5828 18.976V13.246C18.5828 12.9031 18.3048 12.625 17.9618 12.625H17.9608C17.6179 12.625 17.3398 12.9031 17.3398 13.246Z"
          stroke="currentColor"
        />
        <path
          d="M29.0759 24.646H5.02686C4.46298 24.646 4.00586 25.1031 4.00586 25.667V25.668C4.00586 26.2319 4.46298 26.689 5.02686 26.689H29.0759C29.6397 26.689 30.0969 26.2319 30.0969 25.668V25.667C30.0969 25.1031 29.6397 24.646 29.0759 24.646Z"
          fill="currentColor"
        />
        <path
          d="M29.0759 25.146H5.02686C4.73912 25.146 4.50586 25.3793 4.50586 25.667V25.668C4.50586 25.9557 4.73912 26.189 5.02686 26.189H29.0759C29.3636 26.189 29.5969 25.9557 29.5969 25.668V25.667C29.5969 25.3793 29.3636 25.146 29.0759 25.146Z"
          stroke="currentColor"
        />
        <path
          d="M28.9944 26.1881C28.6518 26.1881 28.373 25.9093 28.373 25.5667V12.8629L29.6158 13.524V25.5667C29.6158 25.9093 29.3371 26.1881 28.9944 26.1881Z"
          fill="currentColor"
        />
        <path
          d="M28.9944 25.6881C29.0602 25.6881 29.1158 25.6325 29.1158 25.5667V13.8244L28.873 13.6952V25.5667C28.873 25.6325 28.9286 25.6881 28.9944 25.6881ZM28.9944 26.6881C28.3761 26.6881 27.873 26.185 27.873 25.5667V12.0306L30.1158 13.2236V25.5667C30.1158 26.185 29.6128 26.6881 28.9944 26.6881ZM29.738 9.03104L29.2647 8.77942C29.4411 8.82301 29.6049 8.9101 29.7392 9.02941L29.738 9.03104Z"
          fill="currentColor"
        />
        <path
          d="M5.58789 12.8634L5.58789 25.5676C5.58789 25.9102 5.30913 26.189 4.96649 26.189C4.62385 26.189 4.34509 25.9102 4.34509 25.5676L4.34509 13.5242L5.58789 12.8634Z"
          fill="currentColor"
        />
        <path
          d="M5.08789 13.6956L4.84509 13.8247L4.84509 25.5676C4.84509 25.6334 4.90068 25.689 4.96649 25.689C5.0323 25.689 5.08789 25.6334 5.08789 25.5676L5.08789 13.6956ZM4.68806 8.78152L4.68878 8.78337L4.21652 9.0344C4.35179 8.91261 4.51524 8.82572 4.68806 8.78152ZM6.08789 12.0313L6.08789 25.5676C6.08789 26.1859 5.58483 26.689 4.96649 26.689C4.34815 26.689 3.84509 26.1859 3.84509 25.5676L3.84509 13.2238L6.08789 12.0313Z"
          fill="currentColor"
        />
        <path
          d="M32.6246 9.2915L17.9186 0.801C17.1524 0.35862 16.1726 0.621148 15.7303 1.38737C15.2879 2.1536 15.5504 3.13337 16.3166 3.57575L31.0226 12.0662C31.7888 12.5086 32.7686 12.2461 33.211 11.4799C33.6534 10.7136 33.3908 9.73388 32.6246 9.2915Z"
          fill="currentColor"
        />
        <path
          d="M32.3752 9.72448L17.6692 1.23398C17.1421 0.929675 16.4682 1.11026 16.1638 1.63734C15.8595 2.16442 16.0401 2.83839 16.5672 3.1427L31.2732 11.6332C31.8003 11.9375 32.4742 11.7569 32.7785 11.2298C33.0828 10.7028 32.9023 10.0288 32.3752 9.72448Z"
          stroke="currentColor"
        />
        <path
          d="M2.98868 12.0665L17.6947 3.57602C18.4609 3.13364 18.7234 2.15388 18.281 1.38765C17.8387 0.621427 16.8589 0.358899 16.0927 0.801279L1.38668 9.29178C0.620457 9.73416 0.357929 10.7139 0.800309 11.4802C1.24269 12.2464 2.22246 12.5089 2.98868 12.0665Z"
          fill="currentColor"
        />
        <path
          d="M2.7381 11.6335L17.4441 3.14304C17.9712 2.83873 18.1517 2.16476 17.8474 1.63768C17.5431 1.1106 16.8692 0.930012 16.3421 1.23432L1.6361 9.72482C1.10902 10.0291 0.928432 10.7031 1.23274 11.2302C1.53705 11.7573 2.21102 11.9378 2.7381 11.6335Z"
          stroke="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4031_1952">
        <rect width="34.013" height="26.689" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
