import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { px } from '../new-architecture/utils';

export const Checkbox = ({
  className,
  label,
  link,
  checked,
  onChange,
  isChecked,
  onClick,
  selectCard,
  value,
  margin,
  labelDisabled,
  ...props
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="flex items-center justify-start">
      <label className={`checkbox-label relative ${className || ''}`}>
        <input
          {...props}
          type="checkbox"
          className="checkbox-input"
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span
          className={`${
            selectCard ? 'checkbox-select-card' : `checkbox-custom  ${!margin && 'checkbox-margin'}`
          }`}
        />
        {labelDisabled && label && (
          <div>
            {label && (
              <span
                aria-hidden
                className={`checkbox-label-${isChecked ? 'checked' : 'text'}`}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            )}
          </div>
        )}
      </label>
      {label && !labelDisabled && (
        <div>
          {label && (
            <span
              aria-hidden
              onClick={onClick}
              className={`checkbox-label-${
                isChecked ? 'checked' : 'text'
              } font-[400] !text-[#0873de]`}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
