import React from 'react'; // TODO: check component and rework if needed

import { Icon } from '../icon';

export const CircleButton = ({
  className,
  noShadow,
  disabled,
  onClick,
  active,
  icon,
  children,
  style,
  noFill,
  img,
  ...props
}) => (
  <button
    {...props}
    type="button"
    aria-hidden
    style={style}
    className={`${className || ''} rounded-button-container relative ${
      noShadow ? '' : '--shadow'
    }  bg-[#ffffff]  ${disabled ? 'opacity-30' : 'group active:!bg-[#39ca1c]'} ${
      active ? '!bg-[#39ca1c]' : ''
    }`}
    onClick={disabled ? () => {} : onClick}
  >
    {children}
    {!img && (
      <Icon
        {...icon}
        className={`${icon?.className || ''} ${
          noFill ? 'group-active:[&_*]:fill-[#39ca1c]' : 'group-active:[&_*]:fill-white'
        } group-active:[&_*]:stroke-white ${active ? '[&_*]:!fill-white [&_*]:!stroke-white' : ''}`}
      />
    )}
  </button>
);

export default CircleButton;
