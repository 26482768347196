import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/departure';

export const ADD_DEPARTURE = 'departure/addDeparture';

export const addDeparture = createAsyncThunk(
    ADD_DEPARTURE,
    createRequest({
        type: ADD_DEPARTURE,
        loader: (data) => authApi.addDeparture(data),
    }),
);