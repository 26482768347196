import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';

export const CARD_HOLDER_MAIL_SEND = 'card-holder/cardHolderMailSend';

export const cardHolderMailSend = createAsyncThunk(
  CARD_HOLDER_MAIL_SEND,
  createRequest({
    type: CARD_HOLDER_MAIL_SEND,
    loader: data => authApi.cardHolderMailSend(data),
  }),
);
