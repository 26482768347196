import React from 'react';

import { data } from '../constants/Component/object-price-discount-action';
import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const ObjectPriceDiscountAction = ({
  handlePriceDiscountActionChange,
  priceDiscountAction,
}) => (
  <div style={{ gap: px(10) }} className="flex">
    {data.map(element => (
      <div
        key={element?.id}
        onClick={() => handlePriceDiscountActionChange(element?.type)}
        onKeyDown={() => handlePriceDiscountActionChange(element?.type)}
        className={` ${
          element?.type === priceDiscountAction
            ? 'text-[rgb(0,0,0,0.5)]'
            : 'bg-[#212B35] text-[#0A84FE]'
        } flex-column m-auto flex items-center justify-center border-[1px] border-solid border-[black]`}
        style={{
          width: px(90),
          height: px(90),
          borderRadius: px(17),
          paddingTop: px(15),
          paddingBottom: px(15),
          gap: px(9.16),
        }}
      >
        <div className="flex w-full items-center justify-center">
          {element?.type === priceDiscountAction ? (
            <Icon type={TypesIcon.PRICE} width={px(24)} height={px(27)} viewBox="0 0 24 27" />
          ) : (
            <Icon type={TypesIcon.BLUEPRICE} width={px(24)} height={px(27)} viewBox="0 0 24 27" />
          )}
        </div>

        <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
          {element?.title}
        </p>
      </div>
    ))}
  </div>
);

export default ObjectPriceDiscountAction;
