import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Icon } from '../../../../../../../../../components/Icon';
import { px } from '../../../../../../../../utils';

export const Division = ({
  open: defaultOpen = false,
  onToggle,
  children,
  type,
  icon,
  title,
  ...props
}) => {
  const [open, setOpen] = useState();

  useLayoutEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    if (onToggle) onToggle(open);
  }, [open]);

  return (
    <div
      className={`flex-column flex ${
        title && icon ? 'border-b-[1px]' : ''
      } border-solid border-[rgb(0,0,0,0.3)]`}
      style={{
        paddingTop: type === 'FISHING' ? px(0) : type === 'REST' ? px(18) : px(7),
      }}
      {...props}
    >
      {title && icon && (
        <div
          className="flex flex-row justify-between"
          style={{
            paddingBottom: type === 'FISHING' ? px(0) : type === 'REST' ? px(22.11) : px(25.37),
        height: px(70)
          }}
        >
          <div className="flex flex-row items-center justify-center">
            <div style={{ width: px(42.67), marginRight: px(7.33) }}>
              <Icon
                type={icon.iconType}
                width={icon.iconWidth}
                height={icon.iconHeight}
                viewBox={icon.viewBox}
              />
            </div>
            <p
              // ref={Math.min(...scrollIndex) === 8 ? scrollRef : null}
              className="font-normal "
              style={{ fontSize: px(20), lineHeight: px(26) }}
            >
              {title}
            </p>
          </div>
          <p
            className="cursor-pointer font-normal text-[#0A84FE] self-center"
            style={{ fontSize: px(15), lineHeight: px(20), cursor: 'pointer'}}
            aria-hidden
            onClick={() => setOpen(!open)}
          >
            {open ? 'скрыть' : 'показать'}
          </p>
        </div>
      )}
      {open && (
        <div
          className="search-menu-item-filter-items"
          style={{
            paddingTop: px(20),
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Division;
