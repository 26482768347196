/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unicorn/no-null */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { addAttachment, updateAttachment } from '../actions/attachment';
import { getEvent } from '../actions/event';
import { styledSchema } from '../constants/Component';
import getCroppedImg from '../constants/Images';
import { numberPx, px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { schemaObjectSelector } from '../selectors/get-event';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { StateContext } from './state-provider';

export const StyledSchema = ({ index, data, setIndex, setAddSlide, addSlide }) => {
  const { handleClose, dragControls } = useContext(StateContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cropRef = useRef(null);
  const inputRef = useRef(null);
  const [filesSrc, setFilesSrc] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [slideIndex, setslideIndex] = useState(index || 0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const eventObject = useSelector(schemaObjectSelector);

  // handle Change
  const handleImgChange = event => {
    if (event?.target?.files[0]) {
      if (event.target.files) {
        const { files } = event.target;
        // eslint-disable-next-line no-underscore-dangle
        for (let index_ = 0; index_ < files.length; index_++) {
          const file = files.item(index_);

          if (file) {
            const reader = new FileReader();

            reader.addEventListener('load', event_ => {
              setFilesSrc(previousState => ({
                ...previousState,
                [file.name]: String(event_.target?.result) || '',
                url: String(event_.target?.result) || '',
                file,
              }));
            });

            reader.readAsDataURL(file);
          }
        }
      }
    }
    setDisabled(true);
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async event_ => {
    event_.preventDefault();

    try {
      const formDateFile = new FormData();

      const croppedImg = await getCroppedImg(filesSrc?.url, croppedAreaPixels, 0);

      setCroppedImage(croppedImg);

      const id = eventObject[index]?.id;
      const type = 'SCHEMA';

      if (id) {
        /* formDateFile.append('files', croppedImage); */
        const upd = async () => {
          await formDateFile.append('file', croppedImg);
          await dispatch(updateAttachment({ data: formDateFile, id }));
        };
        await upd();
        dispatch(getEvent({ id: data?.id }));
        handleClose();
      } else {
        const add = async () => {
          await formDateFile.append('file', croppedImg);
          await formDateFile.append('eventId', data?.id);
          await formDateFile.append('type', type);
          await dispatch(addAttachment(formDateFile));
        };
        await add();
        dispatch(getEvent({ id: data?.id }));
        handleClose();
      }

      setCroppedImage(null);
      setCrop({ x: 0, y: 0 });
      setFilesSrc(null);
      setCroppedImage(null);
    } catch (error) {
      console.error(error);
    }

    setDisabled(false);
  };

  const handleDeletePhoto = () => {
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setFilesSrc(null);
    setDisabled(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      setIndex(index);
    };
  }, []);

  return (
    <div className="search" style={{ padding: px(0) }}>
      <div
        style={{
          paddingBottom: px(80),
        }}
      >
        <div className="flex-column flex w-full items-center justify-center">
          <div
            style={{
              padding: `${px(2)} ${px(15)} ${px(12)} ${px(15)} `,
            }}
            className="flex w-full items-center justify-center"
          >
            <div>
              <p
                className="w-full text-center font-light"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                onPointerDown={event_ => {
                  dragControls.start(event_);
                }}
              >
                Карты. Схемы
              </p>
            </div>
          </div>
          <div
            style={{
              width: `${numberPx(320)}px`,
              height: `${numberPx(304)}px`,
            }}
            className="relative  bg-[#0A84FE4D]  "
            key={index}
          >
            {(!filesSrc && !eventObject[index]?.src) || addSlide ? (
              <div
                style={{
                  width: px(styledSchema.cropWidth),
                  height: px(styledSchema.cropHeight),
                  borderRadius: px(styledSchema.borderRadius),
                }}
                className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2  transform items-center justify-center bg-[#FFFFFF] opacity-30"
              >
                <Icon type={TypesIcon.RESIZE} viewBox="0 0 70 70" width={px(70)} height={px(70)} />
              </div>
            ) : (
              <div
                style={{
                  width: px(styledSchema.cropWidth),
                  height: px(styledSchema.cropHeight),
                  borderRadius: px(styledSchema.borderRadius),
                }}
                className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2  transform items-center justify-center bg-[#FFFFFF] opacity-30"
              >
                <img
                  src={eventObject[index]?.src}
                  alt="cropp"
                  style={{
                    width: px(styledSchema.cropWidth),
                    height: px(styledSchema.cropHeight),
                    borderRadius: px(styledSchema.borderRadius),
                  }}
                />
              </div>
            )}

            <Cropper
              key={index}
              imageRef={cropRef}
              image={filesSrc?.url}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={handleCropComplete}
              onZoomChange={setZoom}
              cropShape={index === 0 ? 'round' : 'rect'}
              style={{
                cropAreaStyle: {
                  boxShadow: 'none',
                  background: '#ffffff80',
                  border: 'none',
                  borderRadius: numberPx(styledSchema.borderRadius),
                },
              }}
              restrictPosition={false}
              cropSize={{
                width: numberPx(styledSchema.cropWidth),
                height: numberPx(styledSchema.cropHeight),
              }}
              showGrid={false}
            />
          </div>
        </div>

        {/* <Pagination size={styledAttachment?.length} activIndex={index} /> */}

        <div
          style={{
            gap: px(10),
            paddingTop: px(10),
          }}
          className="flex items-center justify-center"
        >
          <CircleButton
            onClick={e => {
              handleSave(e);
              setAddSlide(false);
            }}
            disabled={!filesSrc}
            icon={{
              type: TypesIcon.INFO,
              viewBox: '0 0 17 50',
              width: px(17),
              height: px(50),
            }}
          />
          <CircleButton
            ref={inputRef}
            icon={{
              type: TypesIcon.GALLERY,
              viewBox: '0 0 26.827 23.512',
              width: px(26.827),
              height: px(50),
              fill: '#0A84FE',
            }}
          >
            <label
              htmlFor="upload-photo"
              style={{
                width: px(26.827),
                height: px(23.512),
              }}
              className="flex items-center justify-center"
            >
              <input
                className="leading-0 m-0 h-0 w-0  p-0 opacity-0"
                ref={inputRef}
                id="upload-photo"
                type="file"
                accept="image/*"
                multiple
                onChange={handleImgChange}
              />
            </label>
          </CircleButton>
          <CircleButton
            onClick={e => {
              handleSave(e);
              setAddSlide(false);
            }}
            disabled
            icon={{
              type: TypesIcon.FIRST,
              viewBox: '0 0 8.734 22.859',
              width: px(8.734),
              height: px(22.859),
            }}
            className="bg-[#39CA1C]"
          />
          <CircleButton
            onClick={() => handleDeletePhoto()}
            disabled={!filesSrc}
            icon={{
              type: TypesIcon.SMALLBASKET,
              viewBox: '0 0 20.5 25.231',
              width: px(20.5),
              height: px(25.231),
            }}
            className="bg-[#39CA1C]"
          />
        </div>
      </div>
    </div>
  );
};

export default StyledSchema;
