/* eslint-disable no-param-reassign */
import { motion, useAnimation, useDragControls } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

import { ClosePopupLine } from '../../../../../assets/svgs/close-popup-line';
import { px } from '../../../../utils';
import { ModalHeader } from './modal-header';

const simulateMouseEvent = (type, elem) => {
  elem.dispatchEvent(
    new MouseEvent(type, {
      view: window,
      bubbles: true,
      cancelable: true,
    }),
  );
  return elem;
};

const tryClick = ({ clientX, clientY, target }) => {
  target.hidden = true;
  const newEl = document.elementFromPoint(clientX, clientY);
  setTimeout(() => {
    target.hidden = false;
  }, 100);

  simulateMouseEvent('click', newEl).focus();
};

export const DraggableModal = ({
  children,
  onClose,
  isDragControls = true,
  title,
  isLine,
  customHeader,
  customHeaderProps,
  additionalModal,
}) => {
  const dragControls = useDragControls();
  const controls = useAnimation();
  const CustomHeader = customHeader;
  const prevMousePos = useRef();
  const id = title
    ? `${title
        .replaceAll(/[0-9]/g, '')
        .split('.')[0]
        .split('%')[0]
        .replaceAll(/\s/g, '')}-header-modal`
    : additionalModal
      ? 'additional-hdr'
      : 'header-modal';

  useEffect(() => {
    controls.start({ y: 0 });

    const element = document.querySelector(`#${id}`);
    const onMouseUp = () => {
      setTimeout(() => {
        element.hidden = false;
      }, 100);
    };

    document.addEventListener('pointerup', onMouseUp);

    return () => {
      document.removeEventListener('pointerup', onMouseUp);
    };
  }, []);

  return (
    <motion.div
      initial={{
        y: window.innerHeight,
      }}
      animate={controls}
      exit={{
        y: window.innerHeight,
      }}
      drag="y"
      dragControls={dragControls}
      dragListener={!isDragControls}
      onDragEnd={(event, info) => {
        const { point, velocity } = info || {};
        if (point?.y > window.innerHeight / 2 || velocity?.y > 20) {
          if (onClose) onClose();
        } else {
          controls.start({
            y: 0,
          });
        }
      }}
      dragConstraints={{
        top: 0,
        bottom: window.innerHeight,
      }}
      transition={{ ease: 'easeInOut' }}
      className={`draggable-modal-container `}
    >
      <div
        id={id}
        className="clickable absolute z-50 w-full"
        style={{
          height: px(60),
        }}
        onPointerDown={e => {
          dragControls.start(e);
          if (e.pointerType === 'touch') {
            e.target.hidden = true;
          }

          prevMousePos.current = e.clientY;
        }}
        onPointerUp={e => {
          if (Math.abs(prevMousePos.current - e.clientY) < 10 && e.pointerType === 'mouse') {
            tryClick(e);
          }
        }}
      />
      <ClosePopupLine />
      {customHeader ? (
        <CustomHeader {...customHeaderProps} isLine={isLine} />
      ) : (
        title && <ModalHeader title={title} isLine={isLine} />
      )}

      {children}
    </motion.div>
  );
};

export default DraggableModal;
