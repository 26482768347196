import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section-group';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_GROUP = 'section/createSectionGroup';

export const createSectionGroup = createAsyncThunk(
  CREATE_GROUP,
  createRequest({
    type: CREATE_GROUP,
    loader: data => authApi.createSectionGroup(data),
  }),
);
