import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const DELETE_COMPETITION_NEWS = 'competition/deleteCompetitionNews';

export const deleteCompetitionNews = createAsyncThunk(
  DELETE_COMPETITION_NEWS,
  createRequest({
    type: DELETE_COMPETITION_NEWS,
    loader: data => authApi.deleteCompetitionNews(data),
  }),
);
