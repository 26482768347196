/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-shadow */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ObjectDescriptionTextarea } from '../../../../../../../../../components';
import { StyledInput } from '../../../../../../../../../components/styled-input';
import {
  alphabet,
  assistantData,
  assistantDataWithZero,
  sectorData,
} from '../../../../../../../../../constants/Component/date-picker';
import { useDidMountEffect } from '../../../../../../../../hooks';
import { Button, DynamicImageSlider, Picker } from '../../../../../../../../shared';
import { CheckboxFieldGroup } from '../../../../../../../../shared/checkbox-field-group';
import { capitalizeFirstLetter, px } from '../../../../../../../../utils';
import { useProfile } from '../../../../../../../hooks';
import { numberStringToArray } from '../../../../../../helpers/sector.helpers';
import { Division } from '../division';

export const RestFields = ({ error }) => {
  const {
    data: { sectorFields },
    setData: setProfile,
    initialState: { sectorFields: defaultSectorFields },
  } = useProfile();
  const firstRender = useRef(true);

  const [showNumberPicker, setShowNumberPicker] = useState(false);

  const setSectorFields = useCallback(
    payload =>
      setProfile({
        sectorFields: {
          ...sectorFields,
          ...payload,
        },
      }),
    [JSON.stringify(sectorFields)],
  );

  const {
    type,
    number,
    watersideObject,
    landscape,
    capacityNumber,
    objectType,
    aquazone,
    facilities,
    sleepPlaces,
    photos,
    schemas,
    objectDescription,
    forRest,
    name,
  } = sectorFields || {};

  const numberArray = useMemo(() => numberStringToArray(number), [number]);

  useDidMountEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setSectorFields({
      objectType: defaultSectorFields.objectType,
      aquazone: defaultSectorFields.aquazone,
      facilities: defaultSectorFields.facilities,
      sleepPlaces: defaultSectorFields.sleepPlaces,
      forRest: defaultSectorFields.forRest,
    });
  }, [JSON.stringify(watersideObject)]);

  const changeHandler = e => {
    e === undefined
      ? setSectorFields({ watersideObject: e })
      : setSectorFields({ watersideObject: [e] });
  };

  return (
    <Division type={type} open>
      <CheckboxFieldGroup
        title="Объект"
        id="watersideObject"
        error={error === 'watersideObject'}
        items={[
          { label: 'Отель', value: 'hotel' },
          { label: 'Коттедж', value: 'cottage' },
          { label: 'Домик', value: 'house' },
          { label: 'Эко - Домик', value: 'ecoHouse' },
          { label: 'Беседка', value: 'alcove' },
          { label: 'Сафари - тент,лодж,...', value: 'tent' },
          { label: 'Баня,сауна,...', value: 'bath' },
        ]}
        value={watersideObject?.[0]}
        onChange={watersideObject => changeHandler(watersideObject)}
      />
      <div className="w-full">
        <StyledInput
          isBorder
          onChange={({ target: { value: name } }) => setSectorFields({ name })}
          title="Название объекта"
          placeholder="XXXXXXXX"
          value={name}
        />
      </div>
      <div className="flex flex-row items-center" style={{ marginTop: px(-25) }}>
        <p
          className="font-normal"
          id="number"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            marginRight: px(64),
            color: error === 'number' ? 'red' : 'inherit',
          }}
        >
          Номер
        </p>
        <p
          aria-hidden
          onClick={() => {
            setShowNumberPicker(true);
          }}
          className="flex cursor-pointer justify-center font-light text-[#0A84FE]"
          style={{ minWidth: px(44), fontSize: px(20), lineHeight: px(26) }}
        >
          {number || 0}
        </p>
        <Picker
          singleItem
          title="Номер сектора"
          show={showNumberPicker}
          onClose={() => setShowNumberPicker(false)}
          data={{
            sectorShoreSide: sectorData?.sectorSide,
            sectorShoreNumber: assistantData?.assistant,
            sectorShoreLetter: alphabet?.letters,
          }}
          onChange={v => {
            setSectorFields({
              ...sectorFields,
              number: [v.sectorShoreSide, v.sectorShoreNumber, v.sectorShoreLetter]
                ?.filter(item => item !== '-')
                ?.join(''),
            });
          }}
          value={{
            sectorShoreSide: numberArray?.[0],
            sectorShoreNumber: numberArray?.[1],
            sectorShoreLetter: numberArray?.[2],
          }}
          button
          isLine
        />
      </div>
      <CheckboxFieldGroup
        multiple
        title="Ландшафт"
        items={[
          { label: 'Поле, равнина', value: 'field' },
          { label: 'Редкие деревья', value: 'tree' },
          { label: 'Лесопосадка', value: 'trees' },
          { label: 'Лес', value: 'forest' },
          { label: 'Газон', value: 'lawn' },
          { label: 'Возвышенность', value: 'hill' },
          { label: 'Низменность', value: 'lowland' },
        ]}
        value={landscape}
        onChange={landscape => setSectorFields({ landscape })}
      />
      {watersideObject?.includes?.('bath') ? (
        <>
          <CheckboxFieldGroup
            title="Тип объекта"
            items={[
              { label: 'Русская', value: 'russian' },
              { label: 'Финская', value: 'fin' },
              { label: 'Турецкая ', value: 'turkey' },
              { label: 'Японская', value: 'japen' },
              { label: 'Фитосауна', value: 'fito' },
              { label: 'Римская', value: 'rome' },
              {
                label: 'Вместимость',
                value: 'capacity',
                noCheckbox: true,
                picker: {
                  title: 'Вместимость',
                  data: {
                    capacityNumber: assistantDataWithZero?.assistant.map(mappedItem =>
                      mappedItem.toString(),
                    ),
                  },
                  value: {
                    capacityNumber,
                  },
                  onChange: setSectorFields,
                  formatValue: c => c?.capacityNumber,
                },
              },
            ]}
            value={objectType}
            onChange={objectType => setSectorFields({ objectType })}
            onClear={() => setSectorFields({ capacityNumber: defaultSectorFields?.capacityNumber })}
          />
          <CheckboxFieldGroup
            multiple
            title="Аквазона"
            items={[
              { label: 'Бассейн', value: 'pool' },
              { label: 'Водопад', value: 'waterFall' },
              { label: 'Выход к реке,озеру,...', value: 'toRiver' },
              { label: 'Гейзер', value: 'geizer' },
              { label: 'Гидромассаж', value: 'hydroMassage' },
              { label: 'Горка', value: 'gorka' },
              { label: 'Джакузи', value: 'jacusi' },
              { label: 'Душ', value: 'showerAqua' },
              { label: 'Купель', value: 'cupel' },
              { label: 'Ледяной бассейн', value: 'icePool' },
              { label: 'Сауна с морской водой', value: 'seaSpa' },
              { label: 'Теплый бассейн', value: 'wormPool' },
              { label: 'Ушат', value: 'ushat' },
              { label: 'Чан', value: 'chan' },
            ]}
            value={aquazone}
            onChange={aquazone => setSectorFields({ aquazone })}
          />
          <CheckboxFieldGroup
            multiple
            title="Для отдыха"
            items={[
              { label: 'Ароматы для парной', value: 'aroma' },
              { label: 'Аудио-видео аппаратура', value: 'audioVideo' },
              { label: 'Банные принадлежности', value: 'bathAccessories' },
              { label: 'Бар', value: 'bar' },
              { label: 'Бесплатный Wi-Fi', value: 'freeWiFi' },
              { label: 'Веники', value: 'dust' },
              { label: 'Камин', value: 'hearth' },
              { label: 'Кондиционер', value: 'conditionerForRest' },
              { label: 'Летняя веранда', value: 'summerHouse' },
              { label: 'Мангал', value: 'grill' },
              { label: 'Парковка', value: 'parkingForRest' },
              { label: 'Со своей едой', value: 'yourFood' },
              { label: 'Солярий', value: 'sunroom' },
              { label: 'Бильярд', value: 'billiards' },
              { label: 'Теннис', value: 'tennis' },
              { label: 'Услуги банщика', value: 'rubber' },
              { label: 'Настольные игры', value: 'boardgame' },
            ]}
            value={forRest}
            onChange={forRest => setSectorFields({ forRest })}
          />
        </>
      ) : (
        <>
          <CheckboxFieldGroup
            title="Тип объекта"
            items={[
              { label: 'Открытый', value: 'opened' },
              { label: 'Закрытый', value: 'closed' },
              {
                label: 'Вместимость',
                value: 'capacity',
                noCheckbox: true,
                picker: {
                  title: 'Вместимость',
                  data: {
                    capacityNumber: assistantDataWithZero?.assistant.map(mappedItem =>
                      mappedItem.toString(),
                    ),
                  },
                  value: {
                    capacityNumber,
                  },
                  onChange: setSectorFields,
                  formatValue: c => c?.capacityNumber,
                },
              },
            ]}
            value={objectType}
            onChange={objectType => setSectorFields({ objectType })}
            onClear={() => setSectorFields({ capacityNumber: defaultSectorFields?.capacityNumber })}
          />
          <CheckboxFieldGroup
            multiple
            title="Удобства в объекте"
            items={[
              { id: 1, label: 'С проживанием', value: 'accommodation' },
              { id: 2, label: 'Отдых с детьми', value: 'children' },
              { id: 3, label: 'С домашними животными', value: 'pets' },
              { id: 4, label: 'Электричество', value: 'electricityInObject' },
              { id: 5, label: 'Кондиционер', value: 'conditioner' },
              { id: 6, label: 'Отопление', value: 'heating' },
              {
                id: 7,
                label: 'Спальное место',
                value: 'bed',
                picker: {
                  title: 'Спальное место',
                  data: {
                    sleepPlaces: assistantDataWithZero?.assistant.map(mappedItem =>
                      mappedItem.toString(),
                    ),
                  },
                  value: {
                    sleepPlaces,
                  },
                  onChange: setSectorFields,
                  formatValue: c => c?.sleepPlaces,
                },
              },
              { id: 8, label: 'Мебель (минимум)', value: 'furniture' },
              { id: 9, label: 'Постельное белье', value: 'bedSheets' },
              { id: 10, label: 'Телевизор', value: 'tv' },
              { id: 11, label: 'Электрическая плита', value: 'plate' },
              { id: 12, label: 'Чайник', value: 'kettle' },
              { id: 13, label: 'Туалет', value: 'toiletInObject' },
              { id: 14, label: 'Водопровод', value: 'water' },
              { id: 15, label: 'Душ', value: 'shower' },
              { id: 16, label: 'Wi-Fi', value: 'wifiInObject' },
              { id: 17, label: 'Видеонаблюдение', value: 'videoInObject' },
              { id: 18, label: 'Охрана', value: 'guard' },
              { id: 19, label: 'Уборка мусора', value: 'cleaning' },
            ]}
            value={facilities}
            onChange={facilities => setSectorFields({ facilities })}
            onClear={() => setSectorFields({ sleepPlaces: defaultSectorFields?.sleepPlaces })}
          />
        </>
      )}
      <div className="w-full">
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Фото. Видео
        </p>
        <div
          style={{
            marginRight: px(-15),
            marginLeft: px(-15),
          }}
        >
          <DynamicImageSlider
            items={photos}
            onChange={photos =>
              setSectorFields({ photos: photos?.map?.(i => ({ src: i?.src, first: !!i?.first })) })
            }
          />
        </div>
      </div>
      <div className="w-full">
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Схема. Размещение на карте
        </p>
        <div
          style={{
            marginRight: px(-15),
            marginLeft: px(-15),
          }}
        >
          <DynamicImageSlider
            items={schemas}
            onChange={schemas =>
              setSectorFields({
                schemas: schemas?.map?.(i => ({ src: i?.src, first: !!i?.first })),
              })
            }
          />
        </div>
      </div>
      <div
        className="w-full"
        // ref={Math.min(...scrollIndex) === 7 && imgUrl && schemaUrl ? scrollRef : null}
      >
        <p
          className="font-normal opacity-50"
          style={{
            // color: save && scrollIndex.includes(7) && 'red',
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Описание объекта
        </p>

        <ObjectDescriptionTextarea
          objectStyles={{
            height: 194,
            placeholder: 'Описание: с заглавной,  ….',
            paddingTop: 4,
            paddingLeft: 18,
            paddingRight: 18,
            bottom: 20,
            textareaHeight: 180,
          }}
          value={objectDescription}
          setValue={(_, objectDescription) =>
            setSectorFields({ objectDescription: capitalizeFirstLetter(objectDescription) })
          }
        />
        <div
          className="flex flex-row items-center justify-between"
          style={{
            paddingBottom: px(20),
          }}
        >
          <Button
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#000"
            bgColor="#fff"
            onClick={() => setSectorFields({ objectDescription: '' })}
          >
            Очистить
          </Button>
          <Button
            style={{ width: px(141) }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#fff"
            disabled={!objectDescription}
            onClick={
              () => {}
              // setSaveDescription({ ...saveDescription, saveFishingDescription: true })
            }
          >
            Сохранить
          </Button>
        </div>
      </div>
      <div
        style={{
          marginBottom: px(-80),
        }}
      />
    </Division>
  );
};

export default RestFields;
