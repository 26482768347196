import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEvent } from '../actions/event';
import { textareaFiltersStyles } from '../constants/Component';
import { px } from '../constants/Px';
import { eventObjectSelector } from '../selectors/get-event';
import { Button } from './button';
import { Icon } from './Icon';
import { ObjectDescriptionTextarea } from './object-description-textarea';
import { Modal, useModal } from './portals';
import { SearchMenuFilterBlockFiltersFishing } from './search-menu-filter-block-filters-fishing';
import { Slider } from './slider';
import { StateContext } from './state-provider';
import { StyledGalleryFilters } from './styled-gallery-filters';

const capitalizeFirstLetter = str =>
  typeof str === 'string' && str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export const FiltersFilterFishing = ({
  item,
  arr,
  scrollIndex,
  setScrollIndex,
  id,
  setIsShowAllFilters,
  isShowAllFilters,
  setSave,
  save,
  setSelectedOptionsObjectFilters,
  selectedOptionsObjectFilters,
  oneSelectedOption,
  setOneSelectedOption,
  filterRadiobutton,
  setFilterRadiobutton,
  showFilt,
  setShow,
  date,
  setDataPickerId,
  setImgUrl,
  setSchemaUrl,
  imgUrl,
  schemaUrl,
  saveDescription,
  setSaveDescription,
  imgArr,
  tempImgArr,
  schemaArr,
  tempSchemaArr,
}) => {
  const [galleryIndexPhoto, setGalleryIndexPhoto] = useState(0);
  const [galleryIndexMap, setGalleryIndexMap] = useState(0);
  const [styledComponent, setStyledComponent] = useState('Gallery');
  const [addSlide, setAddSlide] = useState(false);
  const {
    isOpen: imageGalery,
    openModal: openImageGalery,
    closeModal: closeImageGalery,
  } = useModal();

  const {
    isOpen: schemaGalery,
    openModal: openSchemaGalery,
    closeModal: closeSchemaGalery,
  } = useModal();

  const [addSlidePhoto, setAddSlidePhoto] = useState(false);
  const [addSlideMap, setAddSlideMap] = useState(false);
  const dispatch = useDispatch();

  const [isShowFilters, setIsShowFilters] = useState(false);
  const { setSelectedOptionsTitle, selectedOptionsTitle, handleClose, show } =
    useContext(StateContext);

  const data = useSelector(eventObjectSelector);

  useEffect(() => {
    dispatch(getEvent({ id: 12 }));
  }, []);
  const { scrollRef } = useContext(StateContext);

  const handleInputChange = (key, value) => {
    setSave(false);
    setSave(false);
    const text = capitalizeFirstLetter(value);
    setSelectedOptionsObjectFilters({ ...selectedOptionsObjectFilters, 7: [text] });
  };

  return (
    <div>
      <div
        className="flex-column flex border-b-[1px] border-solid border-[rgb(0,0,0,0.3)]"
        style={{
          paddingTop: item.id === 1 ? px(0) : item.id === 2 ? px(18) : px(20),
        }}
      >
        <div
          className="flex flex-row justify-between"
          style={{
            paddingBottom: item.id === 1 ? px(24.47) : item.id === 2 ? px(22.11) : px(25.37),
          }}
        >
          <div className="flex flex-row">
            <div style={{ width: px(42.67), marginRight: px(7.33) }}>
              <Icon
                type={item.iconType}
                width={item.iconWidth}
                height={item.iconHeight}
                viewBox={item.viewBox}
              />
            </div>
            <p
              ref={Math.min(...scrollIndex) === 8 ? scrollRef : null}
              className="font-normal "
              style={{ fontSize: px(20), lineHeight: px(26) }}
            >
              {item.title}
            </p>
          </div>
          <p
            className="cursor-pointer font-normal text-[#0A84FE] "
            style={{ fontSize: px(15), lineHeight: px(20), cursor: 'pointer' }}
            aria-hidden
            onClick={() => {
              setIsShowFilters(!isShowFilters);
              setIsShowAllFilters(0);
            }}
          >
            {isShowFilters || isShowAllFilters === id ? 'скрыть' : 'показать'}
          </p>
        </div>
        {(isShowFilters || isShowAllFilters === id) && (
          <div
            className="search-menu-item-filter-items"
            style={{
              paddingTop: px(20),
              paddingBottom: px(20),
            }}
          >
            {arr.map(item => (
              <SearchMenuFilterBlockFiltersFishing
                setScrollIndex={setScrollIndex}
                scrollIndex={scrollIndex}
                selectedOptionsTitle={selectedOptionsTitle}
                setSelectedOptionsTitle={setSelectedOptionsTitle}
                selectedOptionsObjectFilters={selectedOptionsObjectFilters}
                setSelectedOptionsObjectFilters={setSelectedOptionsObjectFilters}
                key={item?.value}
                filter={item}
                setIsShowFilters={setIsShowFilters}
                save={save}
                setSave={setSave}
                oneSelectedOption={oneSelectedOption}
                setOneSelectedOption={setOneSelectedOption}
                filterRadiobutton={filterRadiobutton}
                setFilterRadiobutton={setFilterRadiobutton}
                filtersObject
                show={showFilt}
                setShow={setShow}
                date={date}
                setDataPickerId={setDataPickerId}
              />
            ))}
          </div>
        )}
        {(isShowFilters && item.id === 2) || (isShowAllFilters === id && item.id === 2) ? (
          <>
            <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
              <p
                className="font-normal opacity-50"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  paddingBottom: px(15),
                  color: save && Math.min(...scrollIndex) === 7 && !imgUrl && 'red',
                }}
                ref={Math.min(...scrollIndex) === 7 && !imgUrl ? scrollRef : null}
              >
                Фото. Видео
              </p>
              <div>
                <Slider
                  images={
                    tempImgArr?.length === 0
                      ? [{ id: undefined, src: '', type: 'blueBg' }]
                      : tempImgArr?.map(item => ({ id: 1, src: item, type: 'GALLERY' }))
                  }
                  sliderInRegistrationCard
                  index={galleryIndexPhoto}
                  setIndex={setGalleryIndexPhoto}
                  gallery
                  openModal={openImageGalery}
                  setStyledComponent={setStyledComponent}
                  setAddSlide={setAddSlide}
                />

                {/* <StyledGalleryFilters
                  index={galleryIndexPhoto}
                  data={data}
                  setIndex={setGalleryIndexPhoto}
                  setAddSlide={setAddSlidePhoto}
                  addSlide={addSlidePhoto}
                  setUrl={setImgUrl}
                  setSave={setSave}
                /> */}
              </div>
            </div>
            <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
              <p
                className="font-normal opacity-50"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  paddingBottom: px(15),
                  color: save && Math.min(...scrollIndex) === 7 && imgUrl && !schemaUrl && 'red',
                }}
                ref={Math.min(...scrollIndex) === 7 && imgUrl && !schemaUrl ? scrollRef : null}
              >
                Схема. Размещение на карте
              </p>
              <div>
                <Slider
                  images={
                    tempSchemaArr?.length === 0
                      ? [{ id: undefined, src: '', type: 'blueBg' }]
                      : tempSchemaArr?.map(item => ({ id: 1, src: item, type: 'MAP' }))
                  }
                  sliderInRegistrationCard
                  index={galleryIndexMap}
                  setIndex={setGalleryIndexMap}
                  map
                  openModal={openSchemaGalery}
                  setStyledComponent={setStyledComponent}
                  setAddSlide={setAddSlide}
                />
                {/* <StyledGalleryFilters
                  index={galleryIndexMap}
                  data={data}
                  setIndex={setGalleryIndexMap}
                  setAddSlide={setAddSlideMap}
                  addSlide={addSlideMap}
                  setUrl={setSchemaUrl}
                  setSave={setSave}
                /> */}
              </div>
            </div>
            <div
              style={{ paddingTop: px(20), paddingBottom: px(20) }}
              ref={Math.min(...scrollIndex) === 7 && imgUrl && schemaUrl ? scrollRef : null}
            >
              <p
                className="font-normal opacity-50"
                style={{
                  color: save && scrollIndex.includes(7) && 'red',
                  fontSize: px(20),
                  lineHeight: px(26),
                  paddingBottom: px(15),
                }}
              >
                Описание объекта
              </p>

              <ObjectDescriptionTextarea
                objectStyles={textareaFiltersStyles}
                value={selectedOptionsObjectFilters[7]}
                setValue={handleInputChange}
              />
              <div className="flex flex-row items-center justify-between">
                <Button
                  fontSize={20}
                  lineHeight={26}
                  height={60}
                  borderRadius={0}
                  color="#000"
                  bgColor="#fff"
                  onClick={() =>
                    setSelectedOptionsObjectFilters({ ...selectedOptionsObjectFilters, 7: [''] })
                  }
                >
                  Очистить
                </Button>
                <Button
                  style={{ width: px(141) }}
                  fontSize={20}
                  lineHeight={26}
                  height={60}
                  borderRadius={0}
                  color="#fff"
                  onClick={() =>
                    setSaveDescription({ ...saveDescription, saveFishingDescription: true })
                  }
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <Modal isOpen={imageGalery} onClose={closeImageGalery}>
        <StyledGalleryFilters
          index={galleryIndexPhoto}
          data={data}
          setIndex={setGalleryIndexPhoto}
          setAddSlide={setAddSlidePhoto}
          addSlide={addSlidePhoto}
          setUrl={setImgUrl}
          setSave={setSave}
          closeModal={closeImageGalery}
        />
      </Modal>

      <Modal isOpen={schemaGalery} onClose={closeSchemaGalery}>
        <StyledGalleryFilters
          index={galleryIndexMap}
          data={data}
          setIndex={setGalleryIndexMap}
          setAddSlide={setAddSlideMap}
          addSlide={addSlideMap}
          setUrl={setSchemaUrl}
          setSave={setSave}
          closeModal={closeSchemaGalery}
        />
      </Modal>
    </div>
  );
};

export default FiltersFilterFishing;
