import { useContext } from 'react';

import { guideContext } from '../context';

export const useGuide = () => {
  const context = useContext(guideContext);

  if (context === undefined) {
    throw new Error('guideContext must be inside a GuideProvider');
  }

  return context;
};

export default useGuide;
