import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_SECTIONS = 'section/getSections';

export const getSections = createAsyncThunk(
  GET_SECTIONS,
  createRequest({
    type: GET_SECTIONS,
    loader: data => authApi.getSections(data),
  }),
);
