/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_GUIDE_ATTACHMENT = 'guide/deleteAttachment';

export const deleteGuideAttachment = createAsyncThunk(
  DELETE_GUIDE_ATTACHMENT,
  createRequest({
    type: DELETE_GUIDE_ATTACHMENT,
    loader: data => authApi.deleteGuideAttachment(data),
  }),
);
