import React from 'react';

export const FloatIcon = props => (
  <svg
    viewBox="0 0 39 37"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.5">
      <path
        d="M37.2152 23.4098C37.2083 23.4098 37.2014 23.4098 37.1945 23.4098C35.8237 23.4098 34.5445 24.0204 33.682 25.087L32.3954 26.678C32.1172 27.0221 31.7205 27.2233 31.2786 27.2447C30.8365 27.2656 30.4224 27.1039 30.1124 26.7883L28.1374 24.778C27.4403 24.0685 26.5089 23.6673 25.5145 23.6485C24.5185 23.6285 23.574 23.9953 22.8506 24.6779L20.2756 27.1079C19.6675 27.6819 18.7291 27.667 18.1393 27.0746L15.9094 24.8343C15.1882 24.1096 14.2253 23.7122 13.2053 23.7218C12.1827 23.7292 11.2281 24.138 10.5173 24.8729L8.59085 26.8647C8.29167 27.174 7.88955 27.3402 7.4597 27.331C7.0294 27.3224 6.63464 27.1409 6.34797 26.8198L4.52392 24.7765C3.66767 23.8174 2.43929 23.2673 1.15364 23.2673H1.12298C0.502791 23.2673 0 23.7701 0 24.3903C0 25.0105 0.502791 25.5135 1.12298 25.5135H1.15334C1.79991 25.5135 2.41763 25.7901 2.84822 26.2724L4.67213 28.3157C5.369 29.0963 6.36844 29.5558 7.4145 29.5768C8.46251 29.5957 9.47762 29.1783 10.2049 28.4264L12.1314 26.4346C12.4195 26.1367 12.8066 25.971 13.2211 25.9679C13.6329 25.9625 14.0249 26.1251 14.3174 26.4188L16.5473 28.6592C17.2457 29.3608 18.1754 29.7558 19.1653 29.7712C19.1855 29.7715 19.2055 29.7717 19.2256 29.7717C20.1934 29.7717 21.1114 29.4072 21.8168 28.7415L24.3919 26.3115C24.9937 25.7436 25.9552 25.7618 26.5351 26.3521L28.5101 28.3624C29.2639 29.1296 30.3139 29.5406 31.3868 29.4881C32.4613 29.4362 33.4654 28.9267 34.1418 28.0903L35.4284 26.4992C35.8621 25.9628 36.5054 25.6558 37.1948 25.6558C37.1999 25.6558 37.2053 25.6558 37.2103 25.6558C37.8281 25.6558 38.3304 25.1563 38.3332 24.5378C38.336 23.9177 37.8355 23.4126 37.2152 23.4098Z"
        fill="currentColor"
      />
      <path
        d="M37.2638 30.4478C37.2568 30.4478 37.2499 30.4478 37.2431 30.4478C35.8722 30.4478 34.593 31.0583 33.7304 32.1249L32.4439 33.716C32.1657 34.0601 31.769 34.2613 31.3272 34.2827C30.8857 34.3034 30.4709 34.1421 30.1609 33.8263L28.1859 31.816C27.4888 31.1065 26.5574 30.7054 25.5629 30.6865C24.5716 30.6668 23.6224 31.0333 22.8991 31.716L20.324 34.146C19.7161 34.7198 18.7779 34.7051 18.1878 34.1126L15.9579 31.8723C15.2365 31.1476 14.2792 30.7519 13.2536 30.7599C12.2313 30.7673 11.2767 31.1761 10.5658 31.9109L8.63938 33.9028C8.34012 34.2122 7.93928 34.3783 7.50808 34.3692C7.07786 34.3605 6.68309 34.179 6.39642 33.8579L4.57237 31.8146C3.71612 30.8554 2.48774 30.3054 1.20209 30.3054H1.17181C0.551619 30.3054 0.0488281 30.8082 0.0488281 31.4284C0.0488281 32.0486 0.551619 32.5514 1.17181 32.5514H1.20209C1.84867 32.5514 2.46638 32.828 2.89698 33.3103L4.72096 35.3535C5.41768 36.134 6.41712 36.5936 7.46318 36.6146C8.50722 36.6366 9.52644 36.2162 10.2538 35.4642L12.1802 33.4726C12.4684 33.1746 12.8553 33.0089 13.2698 33.0059C13.2737 33.0059 13.2775 33.0059 13.2812 33.0059C13.6914 33.0059 14.0765 33.1658 14.3662 33.4568L16.5961 35.6973C17.2945 36.3989 18.2242 36.7938 19.214 36.8092C19.2343 36.8095 19.2543 36.8097 19.2745 36.8097C20.2422 36.8097 21.1602 36.4452 21.8656 35.7795L24.4406 33.3495C25.0425 32.7815 26.0039 32.7997 26.5839 33.39L28.5589 35.4004C29.3129 36.1677 30.3612 36.5787 31.4358 36.526C32.5102 36.4741 33.5143 35.9646 34.1906 35.1282L35.4771 33.5372C35.9109 33.0008 36.5542 32.6938 37.2435 32.6938C37.2487 32.6938 37.2541 32.6938 37.259 32.6938C37.8769 32.6938 38.3792 32.1942 38.3819 31.5758C38.3845 30.9556 37.8839 30.4506 37.2638 30.4478Z"
        fill="currentColor"
      />
      <path
        d="M20.3382 4.27884V1.12273C20.3382 0.502546 19.8354 -0.000244141 19.2153 -0.000244141C18.5951 -0.000244141 18.0923 0.502546 18.0923 1.12273V4.27884C13.7871 4.83178 10.4492 8.51999 10.4492 12.9731C10.4492 17.8067 14.3816 21.7392 19.2153 21.7392C24.0489 21.7392 27.9813 17.8067 27.9813 12.9731C27.9812 8.51999 24.6435 4.83178 20.3382 4.27884ZM19.2153 6.45312C21.2582 6.45312 23.0843 7.39784 24.2807 8.873H14.1498C15.3462 7.39784 17.1723 6.45312 19.2153 6.45312ZM19.2153 19.4932C15.62 19.4932 12.6952 16.5683 12.6952 12.9731C12.6952 12.3291 12.7895 11.7069 12.9642 11.1189H25.4662C25.641 11.7069 25.7353 12.3291 25.7353 12.9731C25.7353 16.5683 22.8105 19.4932 19.2153 19.4932Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
