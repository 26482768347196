import { request } from '../../helpers/api/request';

export const deleteCard = async ({ cardId }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `club-card/${cardId}`,
    method: 'DELETE',
  });

  return response;
};
