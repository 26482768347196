import React, { createContext, useMemo, useState } from 'react';

import { INITIAL_STATE } from '../constants';

export const guideContext = createContext(undefined);

export const GuideProvider = ({ children }) => {
  const [data, setData] = useState(INITIAL_STATE);
  const [updateFunction, setUpdateFunction] = useState(() => {});

  const value = useMemo(
    () => ({
      data,
      setData: payload =>
        setData(prevState => ({
          ...prevState,
          ...payload,
        })),
      updateFunction,
      setUpdateFunction,
      initialState: INITIAL_STATE,
    }),
    [data, updateFunction],
  );

  return <guideContext.Provider value={value}>{children}</guideContext.Provider>;
};

export default GuideProvider;
