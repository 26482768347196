import { request } from '@/helpers/api/request';

export const addRecommendation = async ({data, id}) => {
  const response = await request({
    url: `recommendation/${id}`,   
    method: 'POST',
    data,
  });

  return response;
};
