import { request } from '@/helpers/api/request';

export const updateRecommendation = async ({ data, id}) => {

  const response = await request({
    url: `recommendation/${id}`,   
    method: 'PUT',
    data,
  });

  return response;
};
