import { request } from '@/helpers/api/request';

export const addEventAdmin = async (data) => {
  const response = await request({
    url: `event-admin/invite`,
    method: 'POST',
    data
  });

  return response;
};
