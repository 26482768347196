import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_GUIDE_CABINET = 'guide/getGuideCabinet';

export const getGuideCabinet = createAsyncThunk(
  GET_GUIDE_CABINET,
  createRequest({
    type: GET_GUIDE_CABINET,
    loader: data => authApi.getGuideCabinet(data),
  }),
);
