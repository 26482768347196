import { request } from '../../helpers/api/request';

export const deleteNews = async ({ id }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `event-news/${id}`,
    method: 'DELETE',
  });

  return response;
};
