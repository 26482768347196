import React, { useEffect, useRef, useState } from 'react';

export const ScrollButtonsContainer = ({ children }) => {
  const [scrollActive, setScrollActive] = useState(true);
  const prevScrollY = useRef(0);
  const scrollTimeoutRef = useRef(null);
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScrollActive(false);

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      setScrollActive(true);
    }, 400);

    prevScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleScroll);
    window.addEventListener('touchmove', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };
  }, []);

  return (
    <div
      className={`bottom_overlay-buttons ${scrollActive ? 'scroll-active' : 'scroll-no-active'}`}
    >
      {children}
    </div>
  );
};

export default ScrollButtonsContainer;
