import React, { createContext, useMemo, useState } from 'react';

export const objectContext = createContext(undefined);

export const ObjectProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [updateFunction, setUpdateFunction] = useState(() => {});

  const value = useMemo(
    () => ({
      data,
      setData: payload =>
        setData(prevState => ({
          ...prevState,
          ...payload,
        })),
      updateFunction,
      setUpdateFunction,
    }),
    [data, updateFunction],
  );

  return <objectContext.Provider value={value}>{children}</objectContext.Provider>;
};

export default ObjectProvider;
