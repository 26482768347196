import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addCompetitionRegistration } from '../../../../actions/competition';
import { getProfileById } from '../../../../actions/profile';
import { Checkbox, Main } from '../../../../components';
import { RulesAndConditionsModal } from '../../../../components/rules-and-conditions-modal';
import { StyledInput } from '../../../../components/styled-input';
import { regexEmail } from '../../../../constants/Component';
import { TypesIcon } from '../../../../types/TypesIcon';
import { Button, Icon } from '../../../shared';
import { px } from '../../../utils';

export const TeamRegistrationPage = () => {
  const { pathname, state } = useLocation();

  const [rulesAndConditionsOpen, setRulesAndConditionsOpen] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [country, setCountry] = useState('');
  const [town, setTown] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [captainId, setCaptainId] = useState('');
  const [agreement, setAgreement] = useState(false);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (document.documentElement.classList.contains('lock')) {
      document.documentElement.classList.remove('lock');
    }
    return () => {
      if (document.documentElement.classList.contains('lock')) {
        document.documentElement.classList.add('lock');
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const changeIdHandler = async event => {
    const inputValue = event.target.value;
    const regex = /^\d*$/; // only numbers

    if (regex.test(inputValue)) {
      setCaptainId(inputValue);
      const result = await dispatch(getProfileById({ id: inputValue }));
      setIsValid(result.payload.status === 200);
    } else {
      setIsValid(false);
    }
  };

  const handlePhoneChange = event => {
    const inputValue = event.target.value;
    const regex = /^[\d\s()+-]*$/; // numbers, +, -, (, )

    if (regex.test(inputValue) || inputValue === '') {
      setPhone(inputValue);
    }
  };

  return (
    <div className="">
      <div className="w-full">
        <Main
          light
          registration
          isIconRegistretion
          navigatePatch={`/competition/registration/${state?.id}`}
          paddingBottom
        >
          <div
            className="flex items-center justify-center"
            style={{ gap: px(10), marginBottom: px(18) }}
          >
            <div>
              <Icon
                type={TypesIcon.HUMAN}
                width={px(24.98)}
                height={px(24.98)}
                viewBox="0 0 24.98 24.98"
                stoke="#fffff80"
              />
            </div>
            <p style={{ fontSize: px(32), lineHeight: px(43) }}>Регистрация</p>
          </div>
          <div className="flex flex-col" style={{ gap: px(10), marginBottom: px(25) }}>
            <StyledInput
              isBorder
              placeholder="Название команды"
              value={teamName}
              onChange={e => setTeamName(e.target.value)}
            />
            <StyledInput
              isBorder
              placeholder="Страна"
              value={country}
              onChange={e => setCountry(e.target.value)}
            />
            <StyledInput
              isBorder
              placeholder="Город"
              value={town}
              onChange={e => setTown(e.target.value)}
            />
            <StyledInput
              isBorder
              placeholder="E-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
              letEmailValidation
            />
            <StyledInput
              error={!isValid}
              isBorder
              placeholder="ID кабинета капитана"
              value={captainId}
              onChange={changeIdHandler}
            />
            <StyledInput
              isBorder
              placeholder="Телефон капитана"
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
          <div className="flex items-center" style={{ gap: px(10), marginBottom: px(25) }}>
            <Checkbox checked={agreement} onChange={() => setAgreement(!agreement)} />
            <p
              aria-hidden
              onClick={() => {
                setRulesAndConditionsOpen(true);
              }}
              style={{
                fontSize: px(15),
                fontWeight: 400,
                lineHeight: px(20),
                color: '#0A84FE',
              }}
            >
              Согласие на обработку данных
            </p>
          </div>
          <Button
            style={{ width: '100%' }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            disabled={
              !agreement ||
              teamName === '' ||
              country === '' ||
              town === '' ||
              email === '' ||
              !regexEmail.test(email) ||
              captainId === '' ||
              phone === ''
            }
            onClick={() =>
              dispatch(
                addCompetitionRegistration({
                  data: {
                    teamName,
                    country,
                    town,
                    email,
                    captainId: +captainId,
                    phone,
                    competitionId: +state.competitionId,
                    dataId: state.id,
                  },
                }),
              )
            }
          >
            Отправить
          </Button>
        </Main>
      </div>
      {rulesAndConditionsOpen && (
        <RulesAndConditionsModal
          isOpen={rulesAndConditionsOpen}
          onClose={() => {
            setRulesAndConditionsOpen(false);
          }}
        />
      )}
    </div>
  );
};
