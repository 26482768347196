import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Button, FeedbackStar, Main } from '../../components';
import { px } from '../../constants/Px';
import { addFeedback } from '../../actions/feedback';
import { useDispatch } from 'react-redux';

export const FeedbackPage = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [estimate, setEstimate] = useState(0);

  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(addFeedback({ rating: estimate, text: feedback }));
    } catch (error) {}
  };

  return (
    <Main feedback title='Отзыв' fontSize={32} lineHeight={43} light navigatePatch='/about'>
      <div
        className='flex flex-row justify-center items-center'
        style={{ marginTop: px(18), marginBottom: px(18), gap: px(10) }}
      >
        {[1, 2, 3, 4, 5]?.map((e) => (
          <FeedbackStar key={e} starId={e} selectedStar={estimate} setSelectedStar={setEstimate} />
        ))}
      </div>
      <textarea
        className='outline-none border-solid border-[1px] border-black border-opacity-30 w-full resize-none font-normal placeholder:text-black placeholder:opacity-50'
        style={{
          height: px(151),
          fontSize: px(20),
          lineHeight: px(26),
          paddingLeft: px(18),
          paddingRight: px(18),
          marginBottom: px(10),
        }}
        placeholder='Ваш отзыв...'
        value={feedback?.toString()?.charAt(0)?.toUpperCase()+
          feedback?.toString()?.slice(1)}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <Button
        disabled={!feedback}
        className='w-full'
        borderRadius={0}
        fontSize={24}
        lineHeight={32}
        height={60}
        onClick={onSubmit}
      >
        Отправить
      </Button>
    </Main>
  );
};
