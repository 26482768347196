import React from 'react';

import { px } from '../constants/Px';

export const InputWithDataPicker = ({ openPicker, timePhone, endTimePhone, id }) => (
  <div className="flex flex-row " style={{ marginRight: px(-8), gap: px(3) }}>
    <p
      aria-hidden
      onClick={() => {
        openPicker(id, 'start');
      }}
      className="font-normal text-[#0A84FE]"
      style={{ fontSize: px(20), lineHeight: px(26) }}
    >
      {timePhone.hour === 'Время.' ? timePhone.hour : `${timePhone.hour}` + '-'}
    </p>
    <p
      aria-hidden
      onClick={() => {
        openPicker(id, 'end');
      }}
      className="font-normal text-[#0A84FE]"
      style={{ fontSize: px(20), lineHeight: px(26) }}
    >
      {endTimePhone.hour}
    </p>
  </div>
);

export default InputWithDataPicker;
