import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/event-admin';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_EVENT_ADMINS = 'profile-admin/getEventAdmins';

export const getEventAdmins = createAsyncThunk(
  GET_EVENT_ADMINS,
    createRequest({
        type: GET_EVENT_ADMINS,
        loader: (data) => profileAdminApi.getEventAdmins(data),
    }),
);
