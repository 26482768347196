import { createReducer } from '@reduxjs/toolkit';

import { getLocations } from '../../actions/location/thunks';

export const location = createReducer({}, builder => {
  builder.addCase(getLocations.fulfilled, (state, { payload }) => ({
    ...state,
    locations: payload?.data?.sort((a, b) => b.createdAt.localeCompare(a.createdAt)),
    /* startActiveIndex:
      payload?.data
        ?.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        .indexOf(item => item.sections.length > 0)?.id ||
      payload?.data?.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0].id, */
  }));
});
