import { request } from '@/helpers/api/request';

export const getProfileById = async ({ id }) => {

  const response = await request({
    url: `profile/${id}`,
    method: 'GET',
  });

  return response;
};

