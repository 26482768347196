/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import { Checkbox } from '../../../../../../components/checkbox';
import { Icon } from '../../../../../../components/Icon';
import { TypesIcon } from '../../../../../../types/TypesIcon';
import { px } from '../../../../../utils';
import { FieldView } from '../field-view';

export const FieldGroupView = ({
  items,
  title,
  radio,
  options: { subtitle = '', fontLabel = 20, noHide = false } = {},
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (noHide && !show) {
      setShow(true);
    }
    if (!noHide && show) {
      setShow(false);
    }
  }, [noHide]);

  return radio ? (
    <div className="search-menu-filter-block-game">
      {items?.map?.(option => (
        <div aria-hidden key={option.label} className="search-menu-filter-block-game-item selected">
          <div className="search-menu-filter-block-game-content">
            <div
              className="search-menu-filter-block-game-svg"
              style={{
                color: '#ffffff80',
              }}
            >
              <Icon
                type={TypesIcon.GAMES}
                width={px(24.128)}
                height={px(26.848)}
                viewBox="0 0 24.128 26.848"
              />
            </div>
            <div>
              <h3 style={{ color: '#ffffff80' }}>{title}</h3>
              {subtitle && (
                <p style={{ fontSize: px(15), lineHeight: px(20), color: '#ffffff80' }}>
                  {subtitle}
                </p>
              )}
              <p style={{ color: '#ffffff' }}>{option?.label}</p>
            </div>
          </div>
          <Checkbox checked />
        </div>
      ))}
    </div>
  ) : (
    <div className="search-menu-filter-block">
      <div className="search-menu-filter-block-top">
        <div className="flex flex-col items-start justify-start">
          <h4 className="view">{title}</h4>
          {subtitle && (
            <p
              style={{ fontSize: px(15), lineHeight: px(20), color: '#ffffff80', fontWeight: 400 }}
            >
              {subtitle}
            </p>
          )}
        </div>
        {noHide ? (
          ''
        ) : show ? (
          <div aria-hidden className="search-menu-filter-block-show" onClick={() => setShow(!show)}>
            Скрыть
          </div>
        ) : (
          <div aria-hidden className="search-menu-filter-block-show" onClick={() => setShow(!show)}>
            Показать
          </div>
        )}
      </div>
      {show && (
        <div className="search-menu-filter-block-bottom">
          {items.map(option => (
            <FieldView
              {...option}
              checked
              data={{ value: option?.value, number: option?.number, label: option?.label }}
              key={option?.label}
              fontLabel={fontLabel}
              checkBox={option?.checkBox}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FieldGroupView;
