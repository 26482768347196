import React from 'react';

import SearchSvg from '../assets/svgs/search';
import { Input } from './input';
import { px } from '../new-architecture/utils';

export const HeroSearch = ({ setShowSearch }) => {
  const handleClick = () => {
    setShowSearch(true);
  };

  return (
    <div
      className="hero_search"
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      }}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      <SearchSvg />
      <Input
        style={{
          backgroundColor: 'transparent',
          color: 'white',
          fontSize: px(14),
          marginLeft: px(8),
        }}
        value="РЫБАЛКА НАЧИНАЕТСЯ ЗДЕСЬ"
        readOnly
      />
    </div>
  );
};

export default HeroSearch;
