import { memoize } from 'lodash';
import React from 'react';
import { Navigate , Route } from 'react-router-dom';


const applyParam = (oldParams, newParams, key) => {
    const value = oldParams.get(key);

    if (value) {
        newParams.set(key, value);
    }
};

// Оставляет в query-параметрах только нужные
// Возможно, в будущем список оставляемых параметров нужно будет не хороводить,
// а передавать через пропсы
const getSearch = (search) => {
    const searchParams = new URLSearchParams(search);
    const newSearchParams = new URLSearchParams();
/*     applyParam(searchParams, newSearchParams, queryParams.CATEGORY);
    applyParam(searchParams, newSearchParams, queryParams.VENDOR);
    applyParam(searchParams, newSearchParams, queryParams.PAGE);
    applyParam(searchParams, newSearchParams, queryParams.SORT);
    applyParam(searchParams, newSearchParams, queryParams.FILTER);
    applyParam(searchParams, newSearchParams, queryParams.QUICK_VIEW); */

    return newSearchParams.toString();
};

// Мемоизируем результат фукнции. Ключ - первый аргумент функции
const getSearchMemoized = memoize(getSearch);



export const RedirectWithQuery = ({ to, ...otherProps }) => (
    <Route
        render={props => (
            <Navigate
                {...otherProps}
                to={{
                    pathname: to,
                    search: getSearchMemoized(props.location.search),
                }}
                push
            />
        )}
    />
);
