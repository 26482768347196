import React, { useEffect, useMemo, useRef, useState } from 'react';

import offersBackground from '../../../../assets/images/offers-bg.png';
import { Icon } from '../../../../components/Icon';
import { Slider } from '../../../../components/slider';
import { TypesIcon } from '../../../../types/TypesIcon';
import { sortOptions } from '../../../constants/sort-options';
import {
  AlcoveIcon,
  AwningIcon,
  BathIcon,
  CageIcon,
  CanopyIcon,
  CottageIcon,
  DinghyIcon,
  FishingRodIcon,
  FishMatIcon,
  FloatIcon,
  FreeZoneIcon,
  GrillIcon,
  HotelIcon,
  HouseIcon,
  IceIcon,
  InstructorIcon,
  LandingNetIcon,
  LureIcon,
  ShoreIcon,
  SpinningIcon,
} from '../../../profile/offers/components/fishing-menu/components/sector/icons';
import { BoatIcon } from '../../../profile/offers/components/fishing-menu/components/sector/icons/boat';
import { CircleButton, Dropdown, Modal, ScrollButtonsContainer } from '../../../shared';
import { px } from '../../../utils';
import { useObject } from '../../hooks';
import {
  FieldGroupView,
  ObjectOffersIcons,
  ObjectOffersSectors,
  ObjectOffersTariffCards,
  SortModal,
} from './components';

const priceType = {
  TWHOUR: 'СУТКИ',
  DAY: 'ДЕНЬ',
  NIGHT: 'НОЧЬ',
  HOUR: 'ЧАС',
};

const countPrice = (price, eventType) => {
  const priceArray = [];
  if (price.isCaught && price.caughtValue !== '0' && price.caughtValue)
    priceArray.push(+price.caughtValue);
  if (price.isHelp && price.helpValue !== '0' && price.helpValue) priceArray.push(+price.helpValue);
  if (price.isWithNorm && price.withNormValue !== '0' && price.withNormValue)
    priceArray.push(+price.withNormValue);
  if (price.isWithoutNorm && price.withoutNormValue !== '0' && price.withoutNormValue)
    priceArray.push(+price.withoutNormValue);
  if (price.cost !== '0' && price.cost) priceArray.push(+price.cost);
  const fishingPrice = priceArray.length > 0 ? Math.min(...priceArray) : '0000';
  return {
    id: price?.id,
    priceType: priceType[price?.type],
    periodStart: price?.periodStart,
    periodEnd: price?.periodEnd,
    cost: eventType === 'FISHING' ? fishingPrice : price?.cost,
    disabled: !price?.periodStart || !price?.periodEnd || !price?.cost,
  };
};

export const ObjectOffersModal = ({ setShowSearch, show, onClose, title, eventType }) => {
  const topRef = useRef();
  useEffect(() => {
    if (show) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
    return () => document.documentElement.classList.remove('lock');
  }, [show]);
  const [eyeOpen, setEyeOpen] = useState(true);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [showSortModal, setShowSortModal] = useState(false);
  const [currentSector, setCurrentSector] = useState();
  const [promotion, setPromotion] = useState(1);
  const {
    data: { locations, object, selectedLocation },
    setData: setObject,
  } = useObject();

  const locationsSelector = useMemo(
    () => locations?.filter(location => location?.type === eventType),
    [locations, eventType],
  );
  useEffect(() => {
    setObject({ selectedLocation: locationsSelector[0] });
  }, [show]);
  const { sections: sectors } = selectedLocation || [];
  const sortedSectors = useMemo(
    () => sectors?.sort(sortOption?.cb),
    [sortOption, locations, selectedLocation],
  );

  useEffect(() => {
    const promotionArr = locationsSelector?.[0]?.promotions?.find(
      item => item.id === currentSector?.promotionId,
    )?.rates;
    let promo = 0;
    if (promotionArr) {
      for (const pr of promotionArr) {
        if (
          pr.isCaught &&
          pr.caughtValue &&
          pr.caughtValue !== '0 %' &&
          +pr.caughtValue.slice(0, -1) > promo
        ) {
          promo = pr.caughtValue.slice(0, -1);
        }
        if (
          pr.isWithNorm &&
          pr.withNormValue &&
          pr.withNormValue !== '0 %' &&
          +pr.withNormValue.slice(0, -1) > promo
        ) {
          promo = pr.withNormValue.slice(0, -1);
        }
        if (
          pr.isWithoutNorm &&
          pr.withoutNormValue &&
          pr.withoutNormValue !== '0 %' &&
          +pr.withoutNormValue.slice(0, -1) > promo
        ) {
          promo = pr.withoutNormValue.slice(0, -1);
        }
        if (
          pr.isHelp &&
          pr.helpValue &&
          pr.helpValue !== '0 %' &&
          +pr.helpValue.slice(0, -1) > promo
        ) {
          promo = pr.helpValue.slice(0, -1);
        }
        if (pr.cost && pr.cost !== '0 %' && +pr.cost.slice(0, -1) > promo) {
          promo = pr.cost.slice(0, -1);
        }
      }
    }

    setPromotion(promo);
  }, [locationsSelector, selectedLocation, currentSector, promotion]);

  const icons = useMemo(
    () => [
      {
        id: 1,
        element: (
          <ShoreIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.some(item => item.name === 'winterFromIce') &&
              !currentSector?.place?.some(item => item.name === 'free') &&
              !currentSector?.objects?.includes('cottage') &&
              !currentSector?.objects?.includes('bath') &&
              !currentSector?.objects?.includes('safari') &&
              !currentSector?.objects?.includes('house') &&
              !currentSector?.objects?.includes('ecoHouse') &&
              !currentSector?.objects?.includes('trailer') &&
              !currentSector?.objects?.includes('alcove') &&
              !currentSector?.objects?.includes('tent') &&
              !currentSector?.direction.includes('winter')
            : false,
        visible: eventType === 'FISHING',
        label:
          currentSector?.place?.bridge === 'true'
            ? 'Мостик'
            : currentSector?.place?.pontoon === 'true'
              ? 'Пантон'
              : currentSector?.place?.english === 'true'
                ? 'Английский'
                : currentSector?.place?.wild === 'true'
                  ? 'Дикий берег'
                  : '',
      },
      {
        id: 2,
        element: (
          <FreeZoneIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING' ? currentSector?.place.some(item => item.name === 'free') : false,
        visible: eventType === 'FISHING',
        label: 'FREE ZONE',
      },
      {
        id: 3,
        element: (
          <IceIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? currentSector?.place.some(item => item.name === 'winterFromIce') ||
              currentSector?.direction.includes('winter')
            : false,
        visible: eventType === 'FISHING',
        label: 'ЗИМНЯЯ СО ЛЬДА',
      },
      {
        id: 4,
        element: (
          <CanopyIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.free &&
              !currentSector?.place?.ice &&
              !currentSector?.objects?.includes('cottage') &&
              !currentSector?.objects?.includes('bath') &&
              !currentSector?.objects?.includes('safari') &&
              !currentSector?.objects?.includes('house') &&
              !currentSector?.objects?.includes('ecoHouse') &&
              !currentSector?.objects?.includes('trailer') &&
              !currentSector?.objects?.includes('alcove') &&
              currentSector?.objects?.includes('tent')
            : false,
        visible: eventType === 'FISHING',
        label: 'Навес, шатер, зонт,...',
      },
      {
        id: 5,
        element: (
          <HouseIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.free &&
              !currentSector?.place?.ice &&
              !currentSector?.objects?.includes('cottage') &&
              !currentSector?.objects?.includes('bath') &&
              !currentSector?.objects?.includes('safari') &&
              (currentSector?.objects?.includes('house') ||
                currentSector?.objects?.includes('ecoHouse') ||
                currentSector?.objects?.includes('trailer'))
            : eventType === 'REST'
              ? (!currentSector?.objects?.includes('cottage') &&
                  !currentSector?.objects?.includes('bath') &&
                  !currentSector?.objects?.includes('safari') &&
                  currentSector?.objects?.includes('house')) ||
                currentSector?.objects?.includes('ecoHouse') ||
                currentSector?.objects?.includes('trailer')
              : false,
        label: currentSector?.objects?.includes('house')
          ? 'Домик'
          : currentSector?.objects?.includes('ecoHouse')
            ? 'Эко-домик'
            : currentSector?.objects?.includes('trailer')
              ? 'Вагончик'
              : false,
        visible: eventType === 'FISHING' || eventType === 'REST',
      },
      {
        id: 6,
        element: (
          <AlcoveIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.free &&
              !currentSector?.place?.ice &&
              !currentSector?.objects?.includes('cottage') &&
              !currentSector?.objects?.includes('bath') &&
              !currentSector?.objects?.includes('safari') &&
              !currentSector?.objects?.includes('house') &&
              !currentSector?.objects?.includes('ecoHouse') &&
              !currentSector?.objects?.includes('trailer') &&
              currentSector?.objects?.includes('alcove')
            : eventType === 'REST'
              ? !currentSector?.objects?.includes('cottage') &&
                !currentSector?.objects?.includes('bath') &&
                !currentSector?.objects?.includes('safari') &&
                !currentSector?.objects?.includes('house') &&
                !currentSector?.objects?.includes('ecoHouse') &&
                !currentSector?.objects?.includes('trailer') &&
                currentSector?.objects?.includes('alcove')
              : false,
        visible: eventType === 'FISHING' || eventType === 'REST',
        label: 'Беседка',
      },
      {
        id: 7,
        element: (
          <BathIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.free &&
              !currentSector?.place?.ice &&
              !currentSector?.objects?.includes('cottage') &&
              currentSector?.objects?.includes('bath')
            : eventType === 'REST'
              ? !currentSector?.objects?.includes('cottage') &&
                currentSector?.objects?.includes('bath')
              : false,
        visible: eventType === 'FISHING' || eventType === 'REST',
        label: 'Баня, сауна',
      },
      {
        id: 8,
        element: (
          <AwningIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.free &&
              !currentSector?.place?.ice &&
              !currentSector?.objects?.includes('cottage') &&
              !currentSector?.objects?.includes('bath') &&
              currentSector?.objects?.includes('safari')
            : eventType === 'REST'
              ? !currentSector?.place?.free &&
                !currentSector?.place?.ice &&
                !currentSector?.objects?.includes('cottage') &&
                !currentSector?.objects?.includes('bath') &&
                currentSector?.objects?.includes('tent')
              : false,
        visible: eventType === 'FISHING' || eventType === 'REST',
        label: 'Сафари - тент,лодж,...',
      },
      {
        id: 9,
        element: (
          <CottageIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'FISHING'
            ? !currentSector?.place?.free && currentSector?.objects?.includes('cottage')
            : eventType === 'REST'
              ? currentSector?.objects?.includes('cottage')
              : false,
        visible: eventType === 'FISHING' || eventType === 'REST',
        label: 'Коттедж',
      },
      {
        id: 10,
        element: (
          <HotelIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'REST' && currentSector?.objects?.includes('hotel'),
        visible: eventType === 'REST',
        label: 'Отель',
      },
      {
        id: 11,
        element: (
          <DinghyIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'SERVICE'
            ? currentSector?.services?.includes('boat') ||
              currentSector?.services?.includes('myBoat')
            : false,
        visible: eventType === 'SERVICE',
        label: currentSector?.services?.includes('myBoat') ? 'Со своей лодкой' : 'Прокат лодки',
      },
      {
        id: 12,
        element: (
          <BoatIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'SERVICE'
            ? currentSector?.services?.includes('dinghy') ||
              currentSector?.services?.includes('myDinghy')
            : false,
        visible: eventType === 'SERVICE',
        label: currentSector?.services?.includes('dinghy')
          ? 'Аренда кораблика'
          : currentSector?.services?.includes('myDinghy')
            ? 'Со своим корабликом'
            : '',
      },
      {
        id: 13,
        element: (
          <FishMatIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('fishMat') : false,
        visible: eventType === 'SERVICE',
        label: 'Аренда мата для рыбы',
      },
      {
        id: 14,
        element: (
          <FishingRodIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('fishingRod') : false,
        visible: eventType === 'SERVICE',
        label: 'Аренда удилища',
      },
      {
        id: 15,
        element: (
          <SpinningIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('spinning') : false,
        visible: eventType === 'SERVICE',
        label: 'Аренда спининга',
      },
      {
        id: 16,
        element: (
          <LandingNetIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('landingNet') : false,
        visible: eventType === 'SERVICE',
        label: 'Аренда подсака',
      },
      {
        id: 17,
        element: (
          <CageIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('cage') : false,
        visible: eventType === 'SERVICE',
        label: 'Садок',
      },
      {
        id: 18,
        element: (
          <FloatIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('float') : false,
        visible: eventType === 'SERVICE',
        label: 'Аренда маркер.поплавка',
      },
      {
        id: 19,
        element: (
          <InstructorIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('instructor') : false,
        visible: eventType === 'SERVICE',
        label: 'Инструктор',
      },
      {
        id: 20,
        element: (
          <GrillIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active: eventType === 'SERVICE' ? currentSector?.services?.includes('grill') : false,
        visible: eventType === 'SERVICE',
        label: 'Аренда мангала',
      },
      {
        id: 21,
        element: (
          <LureIcon
            style={{
              width: '100%',
              height: '100%',
              color: '#FFFFFF',
            }}
          />
        ),
        active:
          eventType === 'SERVICE'
            ? currentSector?.services?.includes('lure1') ||
              currentSector?.services?.includes('lure2') ||
              currentSector?.services?.includes('lure3') ||
              currentSector?.services?.includes('lure4') ||
              currentSector?.services?.includes('lure5') ||
              currentSector?.services?.includes('lure6') ||
              currentSector?.services?.includes('lure7')
            : false,
        visible: eventType === 'SERVICE',
        label: currentSector?.services?.includes('lure1')
          ? 'Прикормка 1'
          : currentSector?.services?.includes('lure2')
            ? 'Прикормка 2'
            : currentSector?.services?.includes('lure3')
              ? 'Прикормка 3'
              : currentSector?.services?.includes('lure4')
                ? 'Прикормка 4'
                : currentSector?.services?.includes('lure5')
                  ? 'Прикормка 5'
                  : currentSector?.services?.includes('lure6')
                    ? 'Прикормка 6'
                    : currentSector?.services?.includes('lure7')
                      ? 'Прикормка 7'
                      : '',
      },
    ],
    [currentSector, eventType],
  );
  const objectData = useMemo(
    () => ({
      name: object?.name,
      address: `${object?.EventContact?.country || ''}${
        object?.EventContact?.country && object?.EventContact?.district ? ', ' : ''
      }${object?.EventContact?.district || ''}${
        object?.EventContact?.district && object?.EventContact?.town ? ', ' : ''
      }${object?.EventContact?.town || ''}${
        object?.EventContact?.town && object?.EventContact?.region ? ', ' : ''
      }${object?.EventContact?.street || ''}`,
    }),
    [object],
  );

  /* const tariffCards = useMemo(
    () => (currentSector?.prices?.rates || []).map(price => countPrice(price, eventType)),
    [currentSector?.prices?.rates, eventType],
  ); */

  const tariffCards = useMemo(() => {
    const returnedArray = [];
    const enteredArray = locationsSelector?.[0]?.prices?.find(
      item => item.id === currentSector?.priceId,
    )?.rates;
    if (enteredArray) {
      returnedArray[0] = enteredArray.find(item => item.type === 'HOUR');
      returnedArray[1] = enteredArray.find(item => item.type === 'DAY');
      returnedArray[2] = enteredArray.find(item => item.type === 'TWHOUR');
      returnedArray[3] = enteredArray.find(item => item.type === 'NIGHT');
    }

    return returnedArray.map(price => countPrice(price, eventType));
  }, [currentSector, eventType]);

  useEffect(() => {
    if (topRef?.current) {
      topRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [eyeOpen]);

  useEffect(() => {
    if (show) {
      setEyeOpen(true);
    }
  }, [show]);

  const containerRef = useRef();

  useEffect(() => {
    if (show && containerRef?.current)
      setTimeout(() => {
        containerRef.current.style.opacity = 1;
      }, 300);
  }, [show]);

  return (
    <>
      <Modal show={show} onClose={onClose} title={title}>
        <div
          style={{
            overflowY: 'auto',
            height: '100%',
            paddingBottom: px(80),
            backgroundColor: '#000000',
          }}
        >
          <div
            style={{ marginBottom: px(60), opacity: 0, transition: 'opacity 0.3s' }}
            ref={containerRef}
          >
            <div
              style={{
                width: px(1),
                height: px(1),
              }}
              ref={topRef}
            />

            {eyeOpen && (
              <div className="relative">
                <img
                  src={offersBackground}
                  alt="offers-background"
                  className="z-1 absolute left-0 top-0 w-full max-w-[768px]"
                  style={{
                    maxHeight: eyeOpen ? px(422) : px(65),
                    maxWidth: px(768),
                  }}
                />
                <div
                  className="z-2 relative w-full"
                  style={{ paddingTop: px(10), paddingBottom: px(15) }}
                >
                  <div
                    className="flex-end relative flex w-full justify-end"
                    style={{
                      paddingRight: px(15),
                      paddingLeft: px(15),
                      paddingBottom: px(7.13),
                      height: px(39),
                    }}
                  >
                    {eyeOpen && (
                      <div
                        aria-hidden
                        onClick={() => setEyeOpen(false)}
                        className="text-[#0A84FE]"
                        style={{ paddingTop: px(12.2), marginRight: px(39.53) }}
                      >
                        <Icon
                          type={TypesIcon.EYEOPEN}
                          width={px(26.47)}
                          height={px(16.87)}
                          viewBox="0 0 26.47 16.87"
                        />
                      </div>
                    )}
                    {!eyeOpen && (
                      <div
                        aria-hidden
                        onClick={() => setEyeOpen(true)}
                        className="text-[#0A84FE]"
                        style={{ paddingTop: px(15), marginRight: px(39.53) }}
                      >
                        <Icon
                          type={TypesIcon.EYECLOSE}
                          width={px(26.47)}
                          height={px(16.87)}
                          viewBox="0 0 26.47 16.87"
                        />
                      </div>
                    )}
                    <div style={{ marginRight: px(42.5) }}>
                      <Icon
                        fill="#707070"
                        type={TypesIcon.SEVEN}
                        width={px(24.5)}
                        height={px(29)}
                        viewBox="0 0 24.5 29"
                      />
                    </div>
                    <div
                      aria-hidden
                      className="text-[#707070]"
                      style={{ marginTop: px(6) }}
                      onClick={() => {
                        setShowSortModal(true);
                      }}
                    >
                      <Icon
                        type={TypesIcon.FILTER}
                        width={px(25)}
                        height={px(23.61)}
                        viewBox="0 0 25 23.61"
                      />
                    </div>
                  </div>
                  <div
                    className="relative"
                    style={{ paddingBottom: px(10), marginRight: px(15), marginLeft: px(15) }}
                  >
                    <Dropdown
                      key={selectedLocation?.id}
                      items={locationsSelector?.map(({ name: label, id: value }) => ({
                        label,
                        value,
                      }))}
                      value={selectedLocation?.id || locationsSelector[0]}
                      onChange={id =>
                        setObject({
                          selectedLocation:
                            locationsSelector?.find(i => i?.id === id) || locationsSelector[0],
                        })
                      }
                      light
                    />
                    {promotion !== 0 && (
                      <div
                        style={{
                          left: px(0),
                          top: px(14),
                        }}
                        className="absolute "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={px(21.622)}
                          height={px(23.801)}
                          viewBox="0 0 21.622 23.801"
                        >
                          <g
                            id="Group_3198"
                            data-name="Group 3198"
                            transform="translate(-236.309 -665.069)"
                          >
                            <g
                              id="Group_2650"
                              data-name="Group 2650"
                              transform="translate(925.674 429.009) rotate(90)"
                            >
                              <g id="insignia" transform="translate(236.06 689.366) rotate(-90)">
                                <path
                                  id="Union_24"
                                  data-name="Union 24"
                                  d="M10.255,17.454,0,23.8V0H21.078V23.8Z"
                                  transform="translate(0 0)"
                                  fill="red"
                                />
                              </g>
                            </g>
                            <g
                              id="Личная_информация"
                              data-name="Личная информация"
                              transform="translate(82.93 425.979)"
                            >
                              <text
                                id="_20"
                                data-name="20"
                                transform="translate(155 252)"
                                fill="#fff"
                                fontSize="10"
                                fontFamily="Helvetica-Bold, Helvetica"
                                fontWeight="700"
                              >
                                <tspan x={`${promotion > 9 ? 0 : 3}`} y="0">
                                  {promotion}
                                </tspan>
                              </text>
                              <text
                                id="_"
                                data-name="%"
                                transform="translate(166 252)"
                                fill="#fff"
                                fontSize="10"
                                fontFamily="Helvetica"
                              >
                                <tspan x={`${promotion > 9 ? 0 : -3}`} y="0">
                                  %
                                </tspan>
                              </text>
                            </g>
                          </g>
                        </svg>
                      </div>
                    )}
                  </div>
                  <ObjectOffersSectors
                    sectors={sortedSectors}
                    setCurrentSector={setCurrentSector}
                  />
                  <ObjectOffersIcons icons={icons} />
                  <ObjectOffersTariffCards tariffCards={tariffCards} />
                </div>
              </div>
            )}
            <div className="relative">
              {!eyeOpen && (
                <img
                  src={offersBackground}
                  alt="offers-background"
                  className="z-1 absolute left-0 top-0 w-full max-w-[768px]"
                  style={{
                    maxHeight: px(422),
                    maxWidth: px(768),
                  }}
                />
              )}
              <div
                className="z-2 relative flex w-full items-center justify-center"
                style={{ paddingTop: px(5), paddingBottom: px(10) }}
              >
                {!eyeOpen && (
                  <div
                    aria-hidden
                    onClick={() => setEyeOpen(true)}
                    className="flex items-center justify-center text-[#0A84FE]"
                    style={{ height: px(52), width: px(50) }}
                  >
                    <Icon
                      type={TypesIcon.EYECLOSE}
                      width={px(26.47)}
                      height={px(17)}
                      viewBox="0 0 26.47 16.87"
                    />
                  </div>
                )}
                {eyeOpen && (
                  <div
                    aria-hidden
                    onClick={() => setEyeOpen(false)}
                    className="flex items-center justify-center text-[#0A84FE]"
                    style={{ height: px(50), width: px(50) }}
                  >
                    <Icon
                      type={TypesIcon.EYEOPEN}
                      width={px(26.47)}
                      height={px(16.87)}
                      viewBox="0 0 26.47 16.87"
                    />
                  </div>
                )}
              </div>
              <div
                className="z-2 relative flex w-full flex-col items-center justify-center"
                style={{
                  paddingRight: px(15),
                  paddingLeft: px(15),
                }}
              >
                <div
                  className="flex w-full flex-col items-center justify-center"
                  style={{ gap: px(5) }}
                >
                  {objectData?.name && (
                    <h4
                      className="... w-full truncate text-center font-[300] text-white"
                      style={{ minHeight: px(26), fontSize: px(20), lineHeight: px(26) }}
                    >
                      {objectData?.name}
                    </h4>
                  )}
                  {objectData?.address && (
                    <p
                      className="w-full break-words text-center font-[300] text-white"
                      style={{
                        marginBottom: px(5),
                        fontSize: px(10),
                        lineHeight: px(13),
                      }}
                    >
                      {objectData?.address}
                    </p>
                  )}
                  {selectedLocation?.name && (
                    <h4
                      className="... w-full items-center justify-center truncate text-center font-[300] uppercase text-white"
                      style={{ fontSize: px(15), lineHeight: px(20) }}
                    >
                      {selectedLocation?.name}
                    </h4>
                  )}
                  <p
                    className="truncate text-center font-[400] text-[#39ca1c]"
                    style={{ fontSize: px(15), lineHeight: px(20), width: px(240) }}
                  >
                    Сектор&nbsp;{currentSector?.number}&nbsp;
                    {icons
                      ?.filter(icon => icon?.active)
                      .map(icon => icon?.label && `, ${icon?.label}`)}
                  </p>
                </div>
              </div>
              <div className="z-2 relative" style={{ paddingTop: px(20) }}>
                <p
                  className="container text-white"
                  style={{
                    marginBottom: px(9),
                    fontSize: px(32),
                    lineHeight: px(43),
                  }}
                >
                  Фото. Видео
                </p>
                <div className="bg-white" style={{ height: px(290), width: '100%' }}>
                  {currentSector?.images?.length > 0 ? (
                    <Slider
                      images={currentSector?.images
                        ?.sort((a, b) => a.priority - b.priority)
                        ?.map(image => ({
                          type: 'image',
                          src: `${process.env.REACT_APP_STATIC_URL}${image?.url}`,
                        }))}
                      setShowSearch={setShowSearch}
                      sliderInRegistrationCard
                    />
                  ) : (
                    // <img
                    //   className="h-full w-full object-cover"
                    //   src={`${bannerPlaceholder}`}
                    //   alt="bannerPlaceholder"
                    // />
                    <div />
                  )}
                </div>
              </div>
              {eventType !== 'SERVICE' && (
                <div style={{ paddingTop: px(20) }}>
                  <p
                    className="container text-white"
                    style={{
                      marginBottom: px(9),
                      fontSize: px(32),
                      lineHeight: px(43),
                    }}
                  >
                    На схеме
                  </p>
                  <div className="bg-white" style={{ height: px(290), width: '100%' }}>
                    {currentSector?.schemas?.length > 0 ? (
                      <Slider
                        images={currentSector?.schemas
                          ?.sort((a, b) => a.priority - b.priority)
                          ?.map(schema => ({
                            type: 'image',
                            src: `${process.env.REACT_APP_STATIC_URL}${schema?.url}`,
                          }))}
                        setShowSearch={setShowSearch}
                        sliderInRegistrationCard
                        prevModalOpen
                      />
                    ) : (
                      // <img
                      //   className="h-full w-full object-cover"
                      //   src={`${bannerPlaceholder}`}
                      //   alt="bannerPlaceholder"
                      // />
                      <div />
                    )}
                  </div>
                </div>
              )}
              <div style={{ paddingTop: px(20) }}>
                <p
                  className="container text-white"
                  style={{
                    marginBottom: px(25),
                    fontSize: px(32),
                    lineHeight: px(43),
                  }}
                >
                  Характеристики
                </p>
                <div className="container flex flex-col" style={{ gap: px(40) }}>
                  {currentSector?.number && (
                    <FieldGroupView
                      title="Сектор"
                      items={[
                        { label: 'Номер', pickerValue: currentSector?.number, checkBox: false },
                      ]}
                    />
                  )}
                  {currentSector?.place.length > 0 && (
                    <FieldGroupView
                      title="Место"
                      items={[
                        {
                          label: 'FREE ZONE',
                          value: 'free',
                          number: currentSector?.place?.find(
                            item => item?.name === 'free' && item.value,
                          ),
                        },
                        {
                          label: 'ЗИМНЯЯ СО ЛЬДА',
                          value: 'winterFromIce',
                          number: currentSector?.place?.find(
                            item => item?.name === 'winterFromIce' && item.value,
                          ),
                        },
                        { label: 'Мостик', value: 'bridge' },
                        { label: 'Понтон', value: 'pontoon' },
                        { label: 'Английский', value: 'english' },
                        { label: 'Дикий берег', value: 'wild' },
                      ].filter(item =>
                        currentSector.place.find(place => place.name === item.value),
                      )}
                    />
                  )}
                  {currentSector?.landscape?.length > 0 && (
                    <FieldGroupView
                      title="Ландшафт"
                      items={[
                        { label: 'Поле, равнина', value: 'field' },
                        { label: 'Редкие деревья', value: 'tree' },
                        { label: 'Лесопосадка', value: 'trees' },
                        { label: 'Лес', value: 'forest' },
                        { label: 'Газон', value: 'lawn' },
                        { label: 'Возвышенность', value: 'hill' },
                        { label: 'Низменность', value: 'lowland' },
                      ].filter(item => currentSector?.landscape?.includes(item?.value))}
                    />
                  )}
                  {eventType === 'FISHING' && currentSector?.conditions?.length > 0 && (
                    <FieldGroupView
                      title="Условия на месте"
                      items={[
                        { id: 1, label: 'Стоянка у места ловли', value: 'parking' },
                        { id: 2, label: 'Электричество', value: 'electricity' },
                        { id: 3, label: 'Wi-Fi', value: 'wifi' },
                        { id: 4, label: 'Видеонаблюдение', value: 'video' },
                        { id: 5, label: 'Туалет', value: 'toilet' },
                        { id: 6, label: 'Уборка мусора', value: 'cleaning' },
                      ].filter(item => currentSector?.conditions?.includes(item?.value))}
                    />
                  )}
                  {currentSector?.objects?.length > 0 && (
                    <FieldGroupView
                      title={`${
                        eventType === 'FISHING'
                          ? 'Объект на берегу'
                          : eventType === 'REST'
                            ? 'Объект'
                            : ''
                      }`}
                      items={
                        eventType === 'FISHING'
                          ? [
                              { label: 'Навес, шатер, зонт,...', value: 'tent' },
                              { label: 'Беседка', value: 'alcove' },
                              { label: 'Домик', value: 'house' },
                              { label: 'Эко-Домик', value: 'ecoHouse' },
                              { label: 'Вагончик', value: 'trailer' },
                              { label: 'Сафари - тент,лодж,...', value: 'safari' },
                              { label: 'Коттедж', value: 'cottage' },
                              { label: 'Баня, сауна, ...', value: 'bath' },
                            ].filter(item => currentSector?.objects?.includes(item?.value))
                          : eventType === 'REST'
                            ? [
                                { label: 'Отель', value: 'hotel' },
                                { label: 'Коттедж', value: 'cottage' },
                                { label: 'Домик', value: 'house' },
                                { label: 'Эко - Домик', value: 'ecoHouse' },
                                { label: 'Беседка', value: 'alcovObject' },
                                { label: 'Сафари - тент,лодж,...', value: 'safariTent' },
                                { label: 'Баня,сауна,...', value: 'bath' },
                              ].filter(item => currentSector?.objects?.includes(item?.value))
                            : []
                      }
                    />
                  )}
                  {currentSector?.objectType &&
                    Object?.values(currentSector?.objectType).includes('true') && (
                      <FieldGroupView
                        title="Тип объекта"
                        items={
                          eventType === 'FISHING'
                            ? [
                                { label: 'Открытый', value: 'opened' },
                                { label: 'Закрытый', value: 'closed' },
                                {
                                  label: 'Вместимость',
                                  value: 'capacity',
                                  number: currentSector?.objectType.number,
                                },
                              ].filter(
                                item =>
                                  currentSector?.objectType[item.value] === 'true' ||
                                  (item.value === 'capacity' && currentSector?.objectType.number),
                              )
                            : eventType === 'REST'
                              ? [
                                  { label: 'Открытый', value: 'opened' },
                                  { label: 'Закрытый', value: 'closed' },
                                  { label: 'Русская', value: 'russian' },
                                  { label: 'Финская', value: 'fin' },
                                  { label: 'Турецкая ', value: 'turkey' },
                                  { label: 'Японская', value: 'japen' },
                                  { label: 'Фитосауна', value: 'fito' },
                                  { label: 'Римская', value: 'rome' },
                                  {
                                    label: 'Вместимость',
                                    value: 'capacity',
                                    number: currentSector?.objectType.number,
                                  },
                                ].filter(
                                  item =>
                                    currentSector?.objectType[item.value] === 'true' ||
                                    (item.value === 'capacity' && currentSector?.objectType.number),
                                )
                              : []
                        }
                      />
                    )}
                  {eventType === 'FISHING' && currentSector?.conveniences?.length > 0 ? (
                    <FieldGroupView
                      title="Удобства в объекте"
                      items={[
                        { label: 'С проживанием', value: 'accommodation' },
                        { label: 'Отдых с детьми', value: 'children' },
                        { label: 'С домашними животными', value: 'pets' },
                        { label: 'Электричество', value: 'electricityInObject' },
                        { label: 'Кондиционер', value: 'conditioner' },
                        { label: 'Отопление', value: 'heating' },
                        {
                          label: 'Спальное место',
                          value: 'bed',
                          number: currentSector?.conveniences.find(
                            convenience => convenience.name === 'bed',
                          )?.number,
                        },
                        { label: 'Мебель (минимум)', value: 'furniture' },
                        { label: 'Постельное белье', value: 'bedSheets' },
                        { label: 'Телевизор', value: 'tv' },
                        { label: 'Электрическая плита', value: 'plate' },
                        { label: 'Чайник', value: 'kettle' },
                        { label: 'Туалет', value: 'toiletInObject' },
                        { label: 'Водопровод', value: 'water' },
                        { label: 'Душ', value: 'shower' },
                        { label: 'Wi-Fi', value: 'wifiInObject' },
                        { label: 'Видеонаблюдение', value: 'videoInObject' },
                        { label: 'Охрана', value: 'guard' },
                        { label: 'Уборка мусора', value: 'cleaning' },
                      ].filter(item =>
                        currentSector.conveniences.some(
                          convenience => convenience.name === item.value,
                        ),
                      )}
                    />
                  ) : eventType === 'REST' && currentSector?.conveniences?.length > 0 ? (
                    <FieldGroupView
                      title="Удобства в объекте"
                      items={[
                        { id: 1, label: 'С проживанием', value: 'accommodation' },
                        { id: 2, label: 'Отдых с детьми', value: 'children' },
                        { id: 3, label: 'С домашними животными', value: 'pets' },
                        { id: 4, label: 'Электричество', value: 'electricityInObject' },
                        { id: 5, label: 'Кондиционер', value: 'conditioner' },
                        { id: 6, label: 'Отопление', value: 'heating' },
                        {
                          label: 'Спальное место',
                          value: 'bed',
                          number: currentSector?.conveniences.find(
                            convenience => convenience.name === 'bed',
                          )?.number,
                        },
                        { id: 8, label: 'Мебель (минимум)', value: 'furniture' },
                        { id: 9, label: 'Постельное белье', value: 'bedSheets' },
                        { id: 10, label: 'Телевизор', value: 'tv' },
                        { id: 11, label: 'Электрическая плита', value: 'plate' },
                        { id: 12, label: 'Чайник', value: 'kettle' },
                        { id: 13, label: 'Туалет', value: 'toiletInObject' },
                        { id: 14, label: 'Водопровод', value: 'water' },
                        { id: 15, label: 'Душ', value: 'shower' },
                        { id: 16, label: 'Wi-Fi', value: 'wifiInObject' },
                        { id: 17, label: 'Видеонаблюдение', value: 'videoInObject' },
                        { id: 18, label: 'Охрана', value: 'guard' },
                        { id: 19, label: 'Уборка мусора', value: 'cleaning' },
                      ].filter(item =>
                        currentSector.conveniences.some(
                          convenience => convenience.name === item.value,
                        ),
                      )}
                    />
                  ) : (
                    ''
                  )}
                  {eventType === 'FISHING' && currentSector?.fishingType && (
                    <FieldGroupView
                      radio
                      title="Рыбалка"
                      items={[
                        { label: 'Спортивная', value: 'sport' },
                        { label: 'Любительская', value: 'love' },
                      ].filter(item => currentSector?.fishingType === item?.value)}
                    />
                  )}
                  {eventType === 'FISHING' && currentSector?.discipline?.length > 0 && (
                    <FieldGroupView
                      title="Дисциплина"
                      items={[
                        { label: 'Карпфишинг', value: 'carpfishing' },
                        { label: 'Ловля форели', value: 'troutFishing' },
                        { label: 'Фидер. Донка ', value: 'fider' },
                        { label: 'Матч', value: 'match' },
                        { label: 'Поплавок', value: 'float' },
                        { label: 'Спиннинг с берега', value: 'spinningShore' },
                        { label: 'Спиннинг с лодок', value: 'spinningBoat' },
                        { label: 'Троллинг', value: 'trolling' },
                        { label: 'Джиг', value: 'jig' },
                        { label: 'Квок', value: 'kvock' },
                        { label: 'На живца', value: 'forLiveBait' },
                        { label: 'Нахлыст', value: 'flyFishing' },
                        { label: 'Тенкара', value: 'tenkara' },
                        { label: 'Херабуна', value: 'herabuna' },
                        { label: 'Танаго', value: 'tanago' },
                        { label: 'Подводная охота', value: 'spearfishing' },
                      ].filter(item => currentSector?.discipline?.includes(item?.value))}
                    />
                  )}
                  {eventType === 'FISHING' && currentSector?.direction?.length > 0 && (
                    <FieldGroupView
                      title="Направление"
                      items={[
                        { label: 'Платная', value: 'pay' },
                        { label: 'Трофейная (от 20 кг)', value: 'trofei' },
                        { label: 'Зимняя со льда', value: 'winter' },
                        { label: 'В городе', value: 'city' },
                        { label: 'Дикие места', value: 'wildPlace' },
                        { label: 'На реках с берега', value: 'river' },
                        { label: 'На реках с лодки', value: 'riverBoat' },
                        { label: 'На озерах с берега', value: 'lake' },
                        { label: 'На озерах с лодки', value: 'lakeBoat' },
                        { label: 'Морская с пляжа', value: 'sea' },
                        { label: 'Морская в море', value: 'seaSea' },
                        { label: 'На затонувших судах', value: 'suda' },
                        { label: 'Морская на рифах', value: 'rifs' },
                      ].filter(item => currentSector?.direction?.includes(item?.value))}
                    />
                  )}
                  {eventType === 'FISHING' && currentSector?.additionalConditions?.length > 0 && (
                    <FieldGroupView
                      title="Доп. условия"
                      items={[
                        { label: 'Использовать кораблик', value: 'boat' },
                        { label: 'Со своей лодкой', value: 'yourBoat' },
                        { label: 'Со своей палаткой', value: 'yourTent' },
                      ].filter(item => currentSector?.additionalConditions?.includes(item?.value))}
                    />
                  )}
                  {eventType === 'FISHING' && currentSector?.fishType?.length > 0 && (
                    <FieldGroupView
                      title="Виды рыб"
                      items={[
                        { label: 'Карп', value: 'carp' },
                        { label: 'Сазан', value: 'sazan' },
                        { label: 'Белый амур', value: 'whiteAmur' },
                        { label: 'Черный амур', value: 'blackAmur' },
                        { label: 'Сом обыковенный', value: 'commonCatfish' },
                        { label: 'Сом канальный', value: 'channelCatfish' },
                        { label: 'Толстолобик', value: 'silverCarp' },
                        { label: 'Форель радужная', value: 'rainbowTrout' },
                        { label: 'Форель золотая', value: 'goldenTrout' },
                        { label: 'Щука', value: 'pike' },
                        { label: 'Осетр', value: 'sturgeon' },
                        { label: 'Белуга', value: 'belugaSturgeon' },
                        { label: 'Линь', value: 'tench' },
                        { label: 'Сиг', value: 'sig' },
                        { label: 'Судак', value: 'zander' },
                        { label: 'Карась', value: 'crucianCarp' },
                        { label: 'Окунь', value: 'perch' },
                        { label: 'Жерех', value: 'roach' },
                        { label: 'Лещ', value: 'bream' },
                        { label: 'Хариус европейский', value: 'EuropeanChub' },
                        { label: 'Таймень', value: 'taimen' },
                        { label: 'Налим', value: 'salmon' },
                        { label: 'Язь', value: 'grayling' },
                        { label: 'Нельма', value: 'nelma' },
                        { label: 'Плотва', value: 'plotva' },
                        { label: 'Муксун', value: 'muksun' },
                        { label: 'Пелядь', value: 'vendace' },
                        { label: 'Красноперка', value: 'ruffe' },
                        { label: 'Морской карась', value: 'seaBream' },
                        { label: 'Каранакс', value: 'crappie' },
                        { label: 'Марлин', value: 'marlin' },
                        { label: 'Желтохвост', value: 'yellowtail' },
                        { label: 'Ваху', value: 'wahoo' },
                        { label: 'Корифена (махи-махи)', value: 'dorado' },
                        { label: 'Луциан-кубера', value: 'cuberaSnapper' },
                        { label: 'Красный луциан', value: 'redSnapper' },
                        { label: 'Снук', value: 'snook' },
                        { label: 'Пермит', value: 'permit' },
                        { label: 'Тунец', value: 'tuna' },
                        { label: 'Камбала', value: 'flounder' },
                        { label: 'Кобия', value: 'cobia' },
                        { label: 'Королевская макрель', value: 'kingMackerel' },
                        { label: 'Горбыль', value: 'mullet' },
                        { label: 'Амберджек', value: 'amberjack' },
                        { label: 'Рыба-петух', value: 'triggerfish' },
                        { label: 'Желтопёрый групер', value: 'yellowfinGrouper' },
                        { label: 'Парусник', value: 'sailfish' },
                        { label: 'Пятнистая форель', value: 'speckledTrout' },
                        { label: 'Барракуда', value: 'barracuda' },
                        { label: 'Бонито', value: 'bonito' },
                        { label: 'Спинорог', value: 'spinnerShark' },
                        { label: 'Черноперая акула', value: 'blacktipShark' },
                      ].filter(item => currentSector?.fishType?.includes(item?.value))}
                    />
                  )}

                  {eventType === 'REST' && currentSector?.aquazone?.length > 0 && (
                    <FieldGroupView
                      title="Аквазона"
                      items={[
                        { label: 'Бассейн', value: 'pool' },
                        { label: 'Водопад', value: 'waterFall' },
                        { label: 'Выход к реке,озеру,...', value: 'toRiver' },
                        { label: 'Гейзер', value: 'geizer' },
                        { label: 'Гидромассаж', value: 'hydroMassage' },
                        { label: 'Горка', value: 'gorka' },
                        { label: 'Джакузи', value: 'jacusi' },
                        { label: 'Душ', value: 'showerAqua' },
                        { label: 'Купель', value: 'cupel' },
                        { label: 'Ледяной бассейн', value: 'icePool' },
                        { label: 'Сауна с морской водой', value: 'seaSpa' },
                        { label: 'Теплый бассейн', value: 'wormPool' },
                        { label: 'Ушат', value: 'ushat' },
                        { label: 'Чан', value: 'chan' },
                      ].filter(item => currentSector?.aquazone?.includes(item?.value))}
                    />
                  )}
                  {eventType === 'REST' && currentSector?.forRest?.length > 0 && (
                    <FieldGroupView
                      title="Для отдыха"
                      items={[
                        { label: 'Ароматы для парной', value: 'aroma' },
                        { label: 'Аудио-видео аппаратура', value: 'audioVideo' },
                        { label: 'Банные принадлежности', value: 'bathAccessories' },
                        { label: 'Бар', value: 'bar' },
                        { label: 'Бесплатный Wi-Fi', value: 'freeWiFi' },
                        { label: 'Веники', value: 'dust' },
                        { label: 'Камин', value: 'hearth' },
                        { label: 'Кондиционер', value: 'conditionerForRest' },
                        { label: 'Летняя веранда', value: 'summerHouse' },
                        { label: 'Мангал', value: 'grill' },
                        { label: 'Парковка', value: 'parkingForRest' },
                        { label: 'Со своей едой', value: 'yourFood' },
                        { label: 'Солярий', value: 'sunroom' },
                        { label: 'Бильярд', value: 'billiards' },
                        { label: 'Теннис', value: 'tennis' },
                        { label: 'Услуги банщика', value: 'rubber' },
                        { label: 'Настольные игры', value: 'boardgame' },
                      ].filter(item => currentSector?.forRest?.includes(item?.value))}
                    />
                  )}
                  {eventType === 'SERVICE' && currentSector?.services?.length > 0 && (
                    <FieldGroupView
                      title="Услуги"
                      items={[
                        { label: 'Прокат лодки', value: 'boat' },
                        { label: 'Со своей лодкой', value: 'myBoat' },
                        { label: 'Аренда кораблика', value: 'dinghy' },
                        { label: 'Со своим корабликом', value: 'myDinghy' },
                        { label: 'Аренда мата для рыбы', value: 'fishMat' },
                        { label: 'Аренда удилища', value: 'fishingRod' },
                        { label: 'Аренда спиннинга', value: 'spinning' },
                        { label: 'Аренда подсака', value: 'landingNet' },
                        { label: 'Садок', value: 'cage' },
                        { label: 'Аренда маркер. поплавка', value: 'float' },
                        { label: 'Инструктор', value: 'instructor' },
                        { label: 'Аренда мангала', value: 'grill' },
                        { label: 'Прикормка № 1', value: 'lure1' },
                        { label: 'Прикормка № 2', value: 'lure2' },
                        { label: 'Прикормка № 3', value: 'lure3' },
                        { label: 'Прикормка № 4', value: 'lure4' },
                        { label: 'Прикормка № 5', value: 'lure5' },
                        { label: 'Прикормка № 6', value: 'lure6' },
                        { label: 'Прикормка № 7', value: 'lure7' },
                      ].filter(item => currentSector?.services?.includes(item?.value))}
                    />
                  )}
                  <FieldGroupView
                    options={{ fontLabel: 15 }}
                    title="Тариф для 1-го гостя"
                    items={
                      [{ label: 'Без нормы вылова' }, { label: 'Штраф за поздний выезд (1 час)' }]
                      // .filter(item => currentSector?.fishType?.includes(item?.value),)
                    }
                  />
                  {currentSector?.accessGroup?.payment.length > 0 && (
                    <FieldGroupView
                      title="Оплата при заезде"
                      items={[
                        { label: 'Банковские карты', value: 'card' },
                        { label: 'Денежные переводы', value: 'money' },
                        { label: 'Наличная оплата', value: 'cash' },
                      ].filter(item => currentSector?.accessGroup?.payment.includes(item?.value))}
                    />
                  )}
                  {currentSector?.accessGroup?.prepayment && (
                    <FieldGroupView
                      options={{ fontLabel: 15 }}
                      title="Предоплата"
                      items={[
                        {
                          label: 'Размер аванса',
                          value: `${currentSector?.accessGroup?.prepayment} %`,
                          checkBox: false,
                        },
                      ]}
                    />
                  )}
                  {currentSector?.accessGroup?.refund50 && currentSector?.accessGroup?.refund0 && (
                    <FieldGroupView
                      title="Возврат денег"
                      options={{ fontLabel: 15, subtitle: 'При бронировании.', noHide: true }}
                      items={[
                        { label: '100 % возврат за (часов)', value: '120', checkBox: false },
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        {
                          label: `75% возврат в период (часов)`,
                          value: '72',
                          checkBox: false,
                        },
                        currentSector?.accessGroup?.refund50 && {
                          label: `50 % возврат за (часов)`,
                          value: `${currentSector?.accessGroup?.refund50}` || '48',
                          checkBox: false,
                        },
                        currentSector?.accessGroup?.refund0 && {
                          label: '0 % возврат за менее (часов)',
                          value: `${currentSector?.accessGroup?.refund0}` || '24',
                          checkBox: false,
                        },
                        { label: '100 % при отказе в заезде', checkBox: 'green' },
                      ]}
                    />
                  )}
                  {currentSector?.accessGroup?.discounts.length > 0 && (
                    <FieldGroupView
                      title="Скидки при бронировании"
                      options={{ fontLabel: 20, noHide: true }}
                      items={[
                        { label: 'Клубные Карты', value: 'card' },
                        { label: 'Подарочные сертификаты', value: 'cert' },
                        { label: 'Клубные Карты', value: 'club' },
                        { label: 'Промокоды', value: 'promo' },
                        { label: 'Купоны', value: 'ticket' },
                      ].filter(item =>
                        currentSector?.accessGroup?.discounts?.includes(item?.value),
                      )}
                    />
                  )}
                  {currentSector?.accessGroup?.booking.length > 0 && (
                    <FieldGroupView
                      title="Бронирование при наличии"
                      options={{ fontLabel: 20, noHide: true }}
                      items={[
                        { label: 'Фото ваших выездов', value: 'tripsPhoto' },
                        { label: 'Клубная карта', value: 'card' },
                        { label: 'Рекомендации', value: 'recomendation' },
                        { label: 'Спортивная команда', value: 'team' },
                        { label: 'Спортивный клуб', value: 'club' },
                        { label: 'Книжка спортсмена', value: 'book' },
                      ].filter(item => currentSector?.accessGroup?.booking?.includes(item?.value))}
                    />
                  )}
                </div>
              </div>
              {currentSector?.description && (
                <div style={{ paddingTop: px(20) }}>
                  <p
                    className="container text-[#ffffff]"
                    style={{
                      marginBottom: px(9),
                      fontSize: px(32),
                      lineHeight: px(43),
                    }}
                  >
                    Описание
                  </p>
                  <p className="container w-full break-words text-[#ffffff]">
                    {currentSector?.description}
                  </p>
                </div>
              )}
            </div>
            <ScrollButtonsContainer>
              <CircleButton
                icon={{
                  type: TypesIcon.CHAIR,
                  viewBox: '0 0 24.1 26.8',
                  width: px(24.1),
                  height: px(26.8),
                }}
                className="!bg-[#0A84FE]"
              />
            </ScrollButtonsContainer>
          </div>
        </div>
      </Modal>
      <SortModal
        show={showSortModal}
        onClose={() => setShowSortModal(false)}
        options={sortOptions}
        onChange={option => {
          setSortOption(option);
          setShowSortModal(false);
        }}
      />
    </>
  );
};
export default ObjectOffersModal;
