import { request } from '@/helpers/api/request';

export const addLegalInformation = async (data) => {
  const response = await request({
    url: `mail/legal-information`,
    method: 'POST',
    data
  });

  return response;
};
