import { request } from '../../helpers/api/request';

export const refresh = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  await request({
    url: `auth/refresh`,
    method: 'GET',
    data,
  });
};
