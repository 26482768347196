/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const TRANSFER_COMPETITION = 'competition/transferCompetition';

export const transferCompetition = createAsyncThunk(
  TRANSFER_COMPETITION,
  createRequest({
    type: TRANSFER_COMPETITION,
    loader: data => authApi.transferCompetition(data),
  }),
);
