import 'react-phone-input-2/lib/style.css';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { addActiveType, clearActiveId, loginEnd, loginStart } from '../../actions/auth';
import { Button, Input, Main } from '../../components';
import { PhoneInput } from '../../components/phoneInput';
import { px } from '../../constants/Px';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [isSendPhone, setIsSendPhone] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [time, setTime] = useState(0);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const handleChangePhone = event => {
    const input = event.target.value.replaceAll(/\D/g, '');
    const regex =
      /^((8|\+374|\+9{2}4|\+9{2}5|\+375|\+7|\+380|\+38|\+9{2}6|\+9{2}8|\+9{2}3) ?)?\(?\d{3,5}\)?(?: ?\d){5}(( ?\d)? ?\d)?$/;
    setIsValidPhone(regex.test(input));
    setPhone(event.target.value);
  };

  const handleChangeCode = event => {
    const newValue = event.target.value.replaceAll(/\D/g, ''); // удаляем все, кроме цифр
    if (newValue.length <= 4) {
      setCode(newValue);
    }
  };

  const handleSendNumber = async event => {
    event.preventDefault();

    try {
      const { payload } = await dispatch(loginStart({ phone: `+${phone.replaceAll(/\D/g, '')}` }));

      if (payload?.data?.verificationCode) {
        alert(`Ваш код ${payload?.data?.verificationCode}`);
      }

      if (payload?.status === 201) {
        setIsSendPhone(true);
      }
    } catch {
      console.log('error loginStart');
    }
  };

  const handleSendEntrance = async event => {
    event.preventDefault();
    try {
      const { payload } = await dispatch(
        loginEnd({ phone: `+${phone.replaceAll(/\D/g, '')}`, code }),
      );
      if (payload?.status === 201) {
        navigate('/');
      }
      setIsSendPhone(false);
      setCode('');
    } catch {
      console.log('error loginEnd');
    }
  };

  const handleReg = () => {
    navigate('/registration');
  };

  const navigateSms = () => {
    navigate('/sms');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(previousTime => previousTime - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [time]);

  const formattedSeconds = seconds > 10 ? seconds : `0${seconds}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(addActiveType({ activeType: undefined }));
    dispatch(clearActiveId());
  }, []);

  return (
    <Main light registration title="Вход" navigatePatch="/">
      <form>
        <PhoneInput
          error={!isValidPhone}
          setPhone={setPhone}
          handleChangePhone={event => handleChangePhone(event)}
          phone={phone}
          disabled={isSendPhone}
        />
        {isSendPhone && (
          <div className="number">
            <Input
              style={{
                borderRadius: px(0),
                margin: `${px(0)} ${px(0)} ${px(10)} ${px(0)}`,
              }}
              placeholder="Ввести код…"
              onChange={handleChangeCode}
              value={code}
              type="text"
              name="token"
              inputmode="numeric"
              pattern="\d{4}"
              autocomplete="one-time-code"
              required
            />
          </div>
        )}
        {!isSendPhone && (
          <Button
            type="submit"
            disabled={!phone || !isValidPhone || phone?.length < 5}
            onClick={handleSendNumber}
            className="w-full border-[1px] border-solid border-[#dddddd]"
            height={60}
            borderRadius={0}
            marginBottom={75}
          >
            Выслать код
          </Button>
        )}
        {isSendPhone && (
          <Button
            className="w-full border-[1px] border-solid border-[#dddddd] "
            type="submit"
            onClick={handleSendEntrance}
            disabled={!code || code?.length < 4}
            borderRadius={0}
            marginBottom={10}
            height={60}
          >
            Войти
          </Button>
        )}
        <div
          style={{
            marginBottom: px(5),
          }}
          className="flex items-center justify-between"
        >
          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
            className=" font-normal  text-[#000000]"
          >
            Нет аккаунта?
          </p>

          <Button
            className="border-[1px] border-solid border-[#000000]"
            type="submit"
            onClick={handleReg}
            disabled={false}
            bgColor="#fff"
            borderRadius={0}
            color="#000000"
            height={60}
            style={{
              padding: ` ${px(17)} ${px(12)} ${px(17)} ${px(13)}`,
            }}
          >
            Регистрация
          </Button>
        </div>
        {isSendPhone &&
          (time > 0 ? (
            <div className="item-center flex justify-between" style={{ marginTop: px(89) }}>
              <p
                style={{
                  fontSize: `${px(15)}`,
                  lineHeight: `${px(20)}`,
                }}
              >{` ${minutes}:${formattedSeconds}`}</p>
              <div
                className="font-normal text-[#ff0a0a]"
                style={{
                  fontSize: `${px(15)}`,
                  lineHeight: `${px(20)}`,
                }}
                onClick={navigateSms}
                onKeyDown={navigateSms}
              >
                Не приходит SMS
              </div>
            </div>
          ) : (
            <div className="flex justify-between" style={{ marginTop: px(89) }}>
              <div
                className="cursor-pointer whitespace-nowrap font-normal text-[#0a84fe]"
                style={{
                  padding: px(0),
                  fontSize: px(15),
                  height: px(20),
                  lineHeight: px(20),
                }}
                onClick={event_ => {
                  handleSendNumber(event_);
                  setTime(120);
                }}
                onKeyDown={event_ => {
                  handleSendNumber(event_);
                  setTime(120);
                }}
              >
                Отправить повторно
              </div>

              <div
                className="font-normal text-[#ff0a0a]"
                style={{
                  fontSize: `${px(15)}`,
                  lineHeight: `${px(20)}`,
                }}
                onClick={navigateSms}
                onKeyDown={navigateSms}
              >
                Не приходит SMS
              </div>
            </div>
          ))}
      </form>
    </Main>
  );
};
