import React from 'react';

export const BoatIcon = props => (
  <svg viewBox="0 0 57 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4031_2152)">
      <path
        d="M33.1408 4.79507L39.1988 4.04807C39.3114 4.03594 39.4246 4.0656 39.5168 4.13142C39.609 4.19723 39.6737 4.29462 39.6988 4.40507L40.4128 7.11307C40.4389 7.19427 40.4431 7.28092 40.425 7.36428C40.4069 7.44763 40.3672 7.52474 40.3098 7.58782C40.2524 7.65091 40.1794 7.69773 40.0981 7.72359C40.0168 7.74944 39.9301 7.7534 39.8468 7.73507L33.0708 5.77207C32.966 5.73494 32.8762 5.66443 32.8153 5.57137C32.7544 5.47831 32.7258 5.36784 32.7337 5.25691C32.7417 5.14598 32.7858 5.04071 32.8593 4.95729C32.9329 4.87386 33.0318 4.81687 33.1408 4.79507Z"
        fill="#757575"
      />
      <path
        opacity="0.8"
        d="M51.4004 12.0551L41.3594 11.0741L41.4874 9.31506L51.4724 10.3551C51.6739 10.3925 51.8548 10.5023 51.9809 10.6639C52.1071 10.8254 52.1699 11.0275 52.1574 11.2321C52.1669 11.3369 52.1543 11.4427 52.1205 11.5424C52.0867 11.6421 52.0324 11.7337 51.9611 11.8112C51.8898 11.8887 51.8031 11.9504 51.7066 11.9924C51.61 12.0344 51.5057 12.0558 51.4004 12.0551Z"
        fill="#757575"
      />
      <path d="M50.2174 11.316H48.4844V16.606H50.2174V11.316Z" fill="#757575" />
      <path d="M45.649 11.316H43.916V16.606H45.649V11.316Z" fill="#757575" />
      <path
        opacity="0.5"
        d="M26.6763 5.36603C31.3242 5.4235 35.9507 6.00723 40.4673 7.10603L41.8513 14.622L3.19727 13.349C3.19727 13.349 18.0143 5.26603 26.6763 5.36603Z"
        fill="#ECEFF1"
      />
      <mask
        id="mask0_4031_2152"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="39"
        height="10"
      >
        <path
          d="M26.6763 5.36603C31.3242 5.4235 35.9507 6.00723 40.4673 7.10603L41.8513 14.622L3.19727 13.349C3.19727 13.349 18.0143 5.26603 26.6763 5.36603Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4031_2152)">
        <path
          d="M20.0465 11.3291L2.06055 10.3201L10.3525 5.66907L21.6425 6.30807C21.9984 6.30655 22.3447 6.42373 22.6265 6.64107C22.9083 6.8584 23.1096 7.16349 23.1985 7.50807L22.5595 9.57907C22.3953 10.1089 22.0589 10.5688 21.6037 10.8858C21.1485 11.2028 20.6005 11.3588 20.0465 11.3291Z"
          fill="black"
        />
      </g>
      <mask
        id="mask1_4031_2152"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="39"
        height="10"
      >
        <path
          d="M26.6763 5.36603C31.3242 5.4235 35.9507 6.00723 40.4673 7.10603L41.8513 14.622L3.19727 13.349C3.19727 13.349 18.0143 5.26603 26.6763 5.36603Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_4031_2152)">
        <path
          d="M34.9441 12.0571L27.4591 11.6431C27.2732 11.6331 27.0953 11.5639 26.9514 11.4458C26.8075 11.3276 26.7051 11.1665 26.6591 10.9861L25.9501 8.17608C25.9167 8.04297 25.9151 7.90384 25.9455 7.77001C25.9759 7.63617 26.0375 7.51139 26.1252 7.40579C26.2128 7.30019 26.3242 7.21676 26.4501 7.16225C26.5761 7.10774 26.7131 7.0837 26.8501 7.09208L34.1151 7.58608C34.2863 7.59777 34.4504 7.65956 34.5867 7.76376C34.7231 7.86795 34.8258 8.00995 34.8821 8.17208L35.8121 10.9011C35.8583 11.036 35.8707 11.1801 35.8484 11.3209C35.8261 11.4616 35.7697 11.5948 35.6841 11.7088C35.5985 11.8228 35.4863 11.9142 35.3574 11.9749C35.2284 12.0356 35.0865 12.0638 34.9441 12.0571Z"
          fill="black"
        />
      </g>
      <path
        opacity="0.5"
        d="M0.807774 11.7491C0.00777406 11.7011 -0.179226 11.9561 0.163774 12.5031C2.42992 16.715 6.2745 19.8557 10.8538 21.2361C20.7018 24.4721 49.2068 23.4841 49.2068 23.4841L51.8958 16.8271V14.1821L0.807774 11.7491Z"
        fill="black"
      />
      <g opacity="0.5">
        <mask
          id="mask2_4031_2152"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="11"
          width="52"
          height="13"
        >
          <path
            d="M0.807774 11.7491C0.00777406 11.7011 -0.179226 11.9561 0.163774 12.5031C2.42992 16.715 6.2745 19.8557 10.8538 21.2361C20.7018 24.4721 49.2068 23.4841 49.2068 23.4841L51.8958 16.8271V14.1821L0.807774 11.7491Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_4031_2152)">
          <path
            d="M52.732 26.7462H-0.167969V13.9622L52.715 16.9072L52.732 26.7462Z"
            fill="#F5F5F5"
          />
        </g>
      </g>
      <g opacity="0.2">
        <path
          d="M1.28676 12.652C1.28676 12.652 38.5868 14.02 51.0138 15.019C63.4408 16.018 51.0138 16.65 51.0138 16.65L48.6068 22.615C47.2928 22.655 43.8458 22.738 39.5168 22.738C31.5858 22.738 17.3508 22.438 11.1308 20.393C6.982 19.1631 3.46033 16.3937 1.28676 12.652ZM0.634756 11.744C-0.0132443 11.744 -0.150244 12 0.162756 12.502C2.4289 16.7139 6.27348 19.8546 10.8528 21.235C16.9718 23.245 30.3028 23.624 39.5118 23.624C45.1238 23.624 49.2058 23.483 49.2058 23.483L51.8948 16.826V14.182L0.806756 11.748C0.744756 11.748 0.687756 11.748 0.634756 11.748V11.744Z"
          fill="#424242"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M26.7242 5.36511V6.24711H26.7682C31.141 6.31431 35.4945 6.84324 39.7562 7.82511L40.8142 13.7011L6.76117 12.5771C11.4692 10.2891 20.5512 6.24711 26.7222 6.24711V5.36511M26.5222 5.36511C17.8372 5.36511 3.20117 13.3491 3.20117 13.3491L41.8542 14.6231L40.4612 7.11111C35.9453 6.00991 31.319 5.42417 26.6712 5.36511C26.6232 5.36511 26.5722 5.36511 26.5222 5.36511Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4031_2152">
        <rect width="56.54" height="24.321" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
