import { useContext } from 'react';

import { profileContext } from '../context';

export const useProfile = () => {
  const context = useContext(profileContext);

  if (context === undefined) {
    throw new Error('profileContext must be inside a ProfileProvider');
  }

  return context;
};

export default useProfile;
