/* eslint-disable no-shadow */
import React, { useLayoutEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { px } from '../../utils';

export const InputWithMask = ({
  title,
  value: defaultValue,
  onChange,
  isSettingsProfilePage,
  bigTitle,
  isBorder,
  titleColor,
  mask,
  maskChar,
  style,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState(defaultValue);

  useLayoutEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={`flex-column flex min-w-full border-solid  border-black border-opacity-30 ${
        isFocus ? 'border-[1px]' : value && !isBorder ? 'border-[0px]' : 'border-[1px]'
      }`}
      style={{
        position: 'relative',
        height: `${px(60)}`,
        padding: isSettingsProfilePage
          ? `${px(17)} ${px(18)} ${px(17)} ${px(18)} `
          : `${px(4)} ${px(18)} ${px(4)} ${px(18)} `,
        ...style,
      }}
    >
      <p
        className="font-normal text-[black] opacity-50"
        style={{
          lineHeight: `${px(26)}`,
          fontSize: `${px(20)}`,
          marginRight: bigTitle && px(-18),
          color: titleColor && 'red',
        }}
      >
        {title}
      </p>
      <input
        className="w-[90%] font-normal outline-none disabled:bg-[transparent]"
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          height: px(60),
          background: 'transparent',
          marginBottom: px(isFocus ? -24 : -26),
        }}
        placeholder={`${value}${
          mask
            ?.replaceAll?.('9', maskChar)
            ?.replaceAll?.('a', maskChar)
            ?.replaceAll?.('*', maskChar)
            ?.slice?.(value?.length) ?? ''
        }`}
      />
      <InputMask
        mask={mask}
        alwaysShowMask
        maskChar=""
        className="w-[90%] font-normal outline-none disabled:bg-[transparent]"
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          height: px(60),
          background: 'transparent',
        }}
        onChange={e => {
          setValue(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
        value={value}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
    </div>
  );
};

export default InputWithMask;
