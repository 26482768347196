/* eslint-disable no-undef */
import { addLeadingZeros } from '../../../../../../constants/Function';
import { INITIAL_COMPETITION_FIELDS } from '../constants/competition.constants';

export const mapCompetitionToFields = object => ({
  createdById: object?.createdById ?? INITIAL_COMPETITION_FIELDS?.createdById,
  name: object?.name ?? INITIAL_COMPETITION_FIELDS?.name,
  description: object?.description ?? INITIAL_COMPETITION_FIELDS?.description,
  attachments:
    object?.attachments
      ?.filter?.(
        att => att?.type !== 'GALLERY' && att?.type !== 'SCHEMA' && att?.type !== 'PROTOCOL',
      )
      ?.map(({ url, type: t }, index) => ({
        src: `${process.env.REACT_APP_STATIC_URL}${url}`,
        first: index === 0,
        key: t?.toLowerCase(),
      })) ?? INITIAL_COMPETITION_FIELDS?.attachments,
  gallery:
    object?.attachments
      ?.filter?.(att => att?.type === 'GALLERY')
      ?.sort?.((a, b) => a.priority - b.priority)
      ?.map(({ url }, index) => ({
        src: `${process.env.REACT_APP_STATIC_URL}${url}`,
        first: index === 0,
      })) ?? INITIAL_COMPETITION_FIELDS?.gallery,
  schemas:
    object?.attachments
      ?.filter?.(att => att?.type === 'SCHEMA')
      ?.map(({ url }) => `${process.env.REACT_APP_STATIC_URL}${url}`) ??
    INITIAL_COMPETITION_FIELDS?.schemas,
  protocols:
    object?.attachments
      ?.filter?.(att => att?.type === 'PROTOCOL')
      ?.map(({ url }) => `${process.env.REACT_APP_STATIC_URL}${url}`) ??
    INITIAL_COMPETITION_FIELDS?.protocols,
  contacts: {
    coordinates:
      object?.contacts?.coordinates?.join?.(', ') ??
      INITIAL_COMPETITION_FIELDS?.contacts?.coordinates,
    country: object?.contacts?.country ?? INITIAL_COMPETITION_FIELDS?.contacts?.country,
    region: object?.contacts?.region ?? INITIAL_COMPETITION_FIELDS?.contacts?.region,
    district: object?.contacts?.district ?? INITIAL_COMPETITION_FIELDS?.contacts?.district,
    town: object?.contacts?.town ?? INITIAL_COMPETITION_FIELDS?.contacts?.town,
    street: object?.contacts?.street ?? INITIAL_COMPETITION_FIELDS?.contacts?.street,
    email: object?.contacts?.email ?? INITIAL_COMPETITION_FIELDS?.contacts?.email,
    mainPhone: object?.contacts?.mainPhone ?? INITIAL_COMPETITION_FIELDS?.contacts?.mainPhone,
    mainPhoneStartTime:
      object?.contacts?.mainPhoneStartTime ??
      INITIAL_COMPETITION_FIELDS?.contacts?.mainPhoneStartTime,
    mainPhoneEndTime:
      object?.contacts?.mainPhoneEndTime ?? INITIAL_COMPETITION_FIELDS?.contacts?.mainPhoneEndTime,
    secondaryPhone:
      object?.contacts?.secondaryPhone ?? INITIAL_COMPETITION_FIELDS?.contacts?.secondaryPhone,
    secondaryPhoneStartTime:
      object?.contacts?.secondaryPhoneStartTime ??
      INITIAL_COMPETITION_FIELDS?.contacts?.secondaryPhoneStartTime,
    secondaryPhoneEndTime:
      object?.contacts?.secondaryPhoneEndTime ??
      INITIAL_COMPETITION_FIELDS?.contacts?.secondaryPhoneEndTime,
    websiteLink: object?.contacts?.websiteLink ?? INITIAL_COMPETITION_FIELDS?.contacts?.websiteLink,
    secondaryWebsiteLink:
      object?.contacts?.secondaryWebsiteLink ??
      INITIAL_COMPETITION_FIELDS?.contacts?.secondaryWebsiteLink,
    socialPageLink:
      object?.contacts?.socialPageLink ?? INITIAL_COMPETITION_FIELDS?.contacts?.socialPageLink,
    telegramLink:
      object?.contacts?.telegramLink ?? INITIAL_COMPETITION_FIELDS?.contacts?.telegramLink,
    viberLink: object?.contacts?.viberLink ?? INITIAL_COMPETITION_FIELDS?.contacts?.viberLink,
    whatsAppLink:
      object?.contacts?.whatsAppLink ?? INITIAL_COMPETITION_FIELDS?.contacts?.whatsAppLink,
    cabinetId: addLeadingZeros(object?.id),
  },
});

export const getInitialFields = object => {
  const prevFields = mapCompetitionToFields(object);

  return {
    ...INITIAL_COMPETITION_FIELDS,
    attachments: prevFields?.attachments,
    gallery: prevFields?.gallery,
    schemas: prevFields?.schemas,
    protocols: prevFields?.protocols,
    contacts: {
      ...INITIAL_COMPETITION_FIELDS?.contacts,
      cabinetId: addLeadingZeros(object?.id),
    },
  };
};
