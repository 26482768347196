import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/profile';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const EMAIL_START = 'profile/emailStart';

const onFail = error => async dispatch => {
  // eslint-disable-next-line eqeqeq
  if (error == 'Error: Request failed with status code 400') {
    await dispatch(
      addModal({
        id: 19,
        data: {
          title: 'Проверьте правильность ввода номера телефона',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
  }
};

export const emailStart = createAsyncThunk(
  EMAIL_START,
  createRequest({
    type: EMAIL_START,
    loader: data => authApi.emailStart(data),
  }),
  onFail,
);
