import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';

export const PROFILE_SOCIALS = 'profile/profileSocials';

export const profileSocials = createAsyncThunk(
    PROFILE_SOCIALS,
    createRequest({
        type: PROFILE_SOCIALS,
        loader: (data) => authApi.profileSocials(data),
    }),
);