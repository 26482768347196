import { px } from '../../Px';

export const userGuideData = props => [
  {
    id: 1,
    name: 'Имя. Логотип. Описание',
    iconName: 'PERSONALINFO',
    width: 22.47,
    height: 25.56,
    viewBox: '0 0 22.466 25.561',
    link: 'profile/edit',
    bottom: px(25),
  },
  {
    id: 2,
    name: 'Предложения',
    iconName: 'HOME',
    width: 26.596,
    height: 29.892,
    viewBox: '0 0 26.596 29.892',
    link: `guide-offers/edit`,
    bottom: px(20.9),
  },
  {
    id: 3,
    name: 'Галерея',
    iconName: 'GALLERYOBJECT',
    width: 28.501,
    height: 22.6272,
    viewBox: '0 0 28.501 22.6272',
    onClick: () => {
      if (props?.setShowGalleryLoader) props.setShowGalleryLoader(true);
    },
    bottom: px(23.2),
  },
  {
    id: 4,
    name: 'Новости',
    iconName: 'NEWS',
    width: 26.004,
    height: 25.951,
    viewBox: '0 0 26.004 25.951',
    link: 'news',
    bottom: px(22.3),
  },
  {
    id: 5,
    name: 'Карты. Схемы',
    iconName: 'CARDS',
    width: 29.117,
    height: 23.077,
    viewBox: '0 0 29.117 23.077',
    link: 'schemas',
    bottom: px(25),
  },
  {
    id: 6,
    name: 'Контакты',
    iconName: 'DIRECTION',
    width: 23.423,
    height: 23.423,
    viewBox: '0 0 23.423 23.423',
    link: 'profile/edit#contacts',
    bottom: px(23.8),
  },
  {
    id: 7,
    name: 'Подписки',
    iconName: 'SUBSCRIPTIONS',
    width: 25.827,
    height: 25.827,
    viewBox: '0 0 25.827 25.827',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    link: 'user-guide',
    bottom: px(23.8),
  },
  {
    id: 8,
    name: 'Управление кабинетом',
    iconName: 'CONTROL',
    width: 28.47,
    height: 28.47,
    viewBox: '0 0 28.474 28.474',
    link: 'account-control',
    bottom: px(22.8),
    personal: true,
  },
  {
    id: 9,
    name: 'Реклама',
    iconName: 'AD',
    width: 30.086,
    height: 25.674,
    viewBox: '0 0 30.086 25.674',
    link: 'user-guide',
    bottom: px(25),
  },

  {
    id: 10,
    name: 'Чат',
    iconName: 'SUPPORT',
    width: 24.964,
    height: 25.423,
    viewBox: '0 0 24.964 25.423',
    link: 'user-guide',
    bottom: px(23),
  },
  {
    id: 11,
    name: 'Обращения',
    iconName: 'APPEALS',
    width: 24.964,
    height: 25.423,
    viewBox: '0 0 24.964 25.423',
    link: 'appeals',
    bottom: px(25),
  },
  {
    id: 12,
    name: 'Позвонить нам',
    iconName: 'CALL',
    width: 27.27,
    height: 27.37,
    viewBox: '0 0 27.269 27.372',
    link: 'tel:+74951479784',
    bottom: px(25),
    phoneNumber: true,
  },
  {
    id: 13,
    name: 'Мобильная версия',
    iconName: 'MOBILE',
    width: 25.68,
    height: 23.53,
    viewBox: '0 0 25.683 23.53',
    link: 'mobile',
    bottom: px(25),
  },
  {
    id: 14,
    name: 'Заблокировать кабинет',
    iconName: 'BLOCK',
    width: 21,
    height: 28,
    viewBox: '0 0 21 28',
    link: 'block-account',
    bottom: px(25),
    personal: true,
  },
  {
    id: 15,
    name: 'Удалить кабинет',
    iconName: 'DELETE',
    width: 20.5,
    height: 25.23,
    viewBox: '0 0 20.5 25.231',
    link: 'delete-account',
    bottom: px(25),
    personal: true,
  },
];
