/* eslint-disable import/no-unresolved */
import 'swiper/scss';

import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { px } from '../new-architecture/utils';

const Slide = ({ slide, border }) => (
  <div
    style={{
      width: '100%',
      height: px(290),
      overflowY: 'auto',
      overflowX: 'hidden',
      border: border ? '2px solid red' : 'none',
    }}
  >
    <div className="relative">
      <img src={slide.src} className="sliderNews__image" alt={`slider-${slide.title}`} />
      <div
        className="absolute top-0 flex items-center justify-center"
        style={{
          height: px(290),
          width: '100%',
        }}
      >
        <p
          className="break-words"
          style={{
            marginBottom: px(5),
            fontSize: px(20),
            lineHeight: px(26),
            color: '#FFFFFF',
            fontWeight: 400,
            textAlign: 'center',
            width: px(270),
            paddingLeft: px(10),
            paddingRight: px(10),
            whiteSpace: 'pre-wrap',
          }}
          dangerouslySetInnerHTML={{
            __html: slide.title.toUpperCase().replace('\n', '&#10;'),
          }}
        />
      </div>
    </div>
    <div
      style={{
        backgroundColor: '#FFFFFF',
        minHeight: px(116),
        paddingLeft: px(15),
        paddingRight: px(15),
        paddingBottom: px(20),
        paddingTop: px(15),
      }}
    >
      <p
        className="break-words"
        style={{
          marginBottom: px(5),
          fontSize: px(20),
          lineHeight: px(26),
          color: '#000000',
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
        }}
        dangerouslySetInnerHTML={{
          __html: slide.subtitle.replace('\n', '&#10;'),
        }}
      />
      {/* <p
        style={{
          marginBottom: px(5),
          fontSize: px(20),
          lineHeight: px(26),
          color: '#000000',
          textAlign: 'left',
        }}
      >
        {slide.subtitle}
      </p> */}
      <p
        className="break-words"
        style={{
          fontSize: px(15),
          lineHeight: px(20),
          color: '#000000',
          whiteSpace: 'pre-wrap',
        }}
        dangerouslySetInnerHTML={{
          __html: slide.body.replace('\n', '&#10;'),
        }}
      />
    </div>
  </div>
);

export const SliderNews = ({ images, news }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [screenWidthHorizontal, setScreenWidthHorizontal] = useState(false);
  const [screenWidthVertical, setScreenWidthVertical] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);

    const checkScreenConditions = () => {
      if (screenWidth < screenHeight) {
        setScreenWidthVertical(true);
        setScreenWidthHorizontal(false);
      } else if (screenHeight < screenWidth) {
        setScreenWidthHorizontal(true);
        setScreenWidthVertical(false);
      }
    };
    checkScreenConditions();

    const handleOutsideClick = e => {
      if (sliderRef.current && !sliderRef.current.contains(e.target) && isVideoPlaying) {
        setIsVideoPlaying(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [screenWidth, screenHeight, isVideoPlaying]);

  return (
    <Swiper
      slidesPerView={1.165}
      spaceBetween={10}
      centeredSlides
      className={`home_page-hero ${(screenWidthVertical || screenWidthHorizontal) && 'horizontal'}`}
      ref={sliderRef}
      onRealIndexChange={element => setActiveIndex(element.activeIndex)}
    >
      {images?.map((img, index) => (
        <SwiperSlide key={img.title}>
          <Slide slide={img} news={news} border={index === activeIndex} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderNews;
