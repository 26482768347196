import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_GUIDE_NEWS = 'guide/createGuideNews';

export const createGuideNews = createAsyncThunk(
  CREATE_GUIDE_NEWS,
  createRequest({
    type: CREATE_GUIDE_NEWS,
    loader: data => authApi.createGuideNews(data),
  }),
);
