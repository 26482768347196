import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEvent } from '../actions/event';
import {
  filterObjectBathData,
  filterObjectLandscapeData,
  filterObjectRestData,
  textareaFiltersStyles,
} from '../constants/Component';
import { px } from '../constants/Px';
import { eventObjectSelector } from '../selectors/get-event';
import { galleryObjectSelector } from '../selectors/get-event';

import { Button } from './button';
import { ObjectDescriptionTextarea } from './object-description-textarea';
import { SearchMenuFilterBlockFiltersRest } from './search-menu-filter-block-filters-rest';
import { StateContext } from './state-provider';
import { StyledGalleryFilters } from './styled-gallery-filters';
import { StyledInput } from './styled-input';

const capitalizeFirstLetter = str =>
  typeof str === 'string' && str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export const FiltersFilterRest = ({
  scrollIndex,
  setScrollIndex,
  setSave,
  save,
  oneSelectedOptionRest,
  setOneSelectedOptionRest,
  selectedObjectFiltersRest,
  setSelectedObjectFiltersRest,
  show,
  setShow,
  date,
  setDataPickerId,
  imgUrl,
  schemaUrl,
  setImgUrl,
  setSchemaUrl,
  setSaveDescription,
  sectorValue,
}) => {
  const [galleryIndexPhotoRest, setGalleryIndexPhotoRest] = useState(0);
  const [galleryIndexMapRest, setGalleryIndexMapRest] = useState(0);

  const [addSlidePhotoRest, setAddSlidePhotoRest] = useState(false);
  const [addSlideMapRest, setAddSlideMapRest] = useState(false);
  const dispatch = useDispatch();
  const { setSelectedOptionsTitle, selectedOptionsTitle } = useContext(StateContext);

  const data = useSelector(eventObjectSelector);
  useEffect(() => {
    dispatch(getEvent({ id: 12 }));
  }, []);

  const { scrollRef } = useContext(StateContext);
  const handleInputChange = (e, title) => {
    setSave(false);
    const text = capitalizeFirstLetter(e.target.value);
    setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, 2: [text] });
  };

  const handleChangeTextarea = (key, value) => {
    const text = capitalizeFirstLetter(value);
    setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, 11: [text] });
  };

  return (
    <div>
      <div
        className="search-menu-item-filter-items"
        style={{
          paddingTop: px(20),
          paddingBottom: px(20),
        }}
      >
        {filterObjectRestData?.map(item => (
          <SearchMenuFilterBlockFiltersRest
            setScrollIndex={setScrollIndex}
            scrollIndex={scrollIndex}
            selectedOptionsTitle={selectedOptionsTitle}
            setSelectedOptionsTitle={setSelectedOptionsTitle}
            key={item?.value}
            filter={item}
            selectedObjectFiltersRest={selectedObjectFiltersRest}
            setSelectedObjectFiltersRest={setSelectedObjectFiltersRest}
            save={save}
            setSave={setSave}
            oneSelectedOptionRest={oneSelectedOptionRest}
            setOneSelectedOptionRest={setOneSelectedOptionRest}
            show={show}
            setShow={setShow}
            date={date}
            setDataPickerId={setDataPickerId}
          />
        ))}
      </div>
      <div
        ref={Math.min(...scrollIndex) === 2 ? scrollRef : null}
        style={{ paddingTop: px(20), paddingBottom: px(15) }}
      >
        <StyledInput
          isBorder
          onChange={handleInputChange}
          title="Название объекта"
          titleColor={save && scrollIndex.includes(2)}
          placeholder="XXXXXXXX"
          value={selectedObjectFiltersRest[2]}
        />
      </div>
      <div className="flex flex-row" style={{ paddingBottom: px(40), gap: px(71) }}>
        <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
          Номер
        </p>
        <p
          onClick={() => {
            setDataPickerId(8);
            setShow(true);
          }}
          className="text-[#0A84FE]"
          style={{ fontSize: px(20), lineHeight: px(26) }}
        >
          {sectorValue}
        </p>
      </div>

      {selectedObjectFiltersRest[1].includes('bath') ? (
        <div
          className="search-menu-item-filter-items"
          style={{
            paddingTop: px(20),
            paddingBottom: px(20),
          }}
        >
          {filterObjectBathData?.map(item => (
            <SearchMenuFilterBlockFiltersRest
              setScrollIndex={setScrollIndex}
              scrollIndex={scrollIndex}
              selectedOptionsTitle={selectedOptionsTitle}
              setSelectedOptionsTitle={setSelectedOptionsTitle}
              key={item?.value}
              filter={item}
              selectedObjectFiltersRest={selectedObjectFiltersRest}
              setSelectedObjectFiltersRest={setSelectedObjectFiltersRest}
              save={save}
              setSave={setSave}
              oneSelectedOptionRest={oneSelectedOptionRest}
              setOneSelectedOptionRest={setOneSelectedOptionRest}
              show={show}
              setShow={setShow}
              date={date}
              setDataPickerId={setDataPickerId}
            />
          ))}
        </div>
      ) : (
        <div
          className="search-menu-item-filter-items"
          style={{
            paddingTop: px(20),
            paddingBottom: px(20),
          }}
        >
          {filterObjectLandscapeData?.map(item => (
            <SearchMenuFilterBlockFiltersRest
              setScrollIndex={setScrollIndex}
              scrollIndex={scrollIndex}
              selectedOptionsTitle={selectedOptionsTitle}
              setSelectedOptionsTitle={setSelectedOptionsTitle}
              key={item?.value}
              filter={item}
              selectedObjectFiltersRest={selectedObjectFiltersRest}
              setSelectedObjectFiltersRest={setSelectedObjectFiltersRest}
              save={save}
              setSave={setSave}
              oneSelectedOptionRest={oneSelectedOptionRest}
              setOneSelectedOptionRest={setOneSelectedOptionRest}
              show={show}
              setShow={setShow}
              date={date}
              setDataPickerId={setDataPickerId}
            />
          ))}
        </div>
      )}
      <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
            color: save && Math.min(...scrollIndex) === 11 && !imgUrl && 'red',
          }}
          ref={Math.min(...scrollIndex) === 11 && !imgUrl ? scrollRef : null}
        >
          Фото. Видео
        </p>
        <div>
          <StyledGalleryFilters
            index={galleryIndexPhotoRest}
            data={data}
            setIndex={setGalleryIndexPhotoRest}
            setAddSlide={setAddSlidePhotoRest}
            addSlide={addSlidePhotoRest}
            setUrl={setImgUrl}
            imgUrl={imgUrl}
            setSave={setSave}
          />
        </div>
      </div>
      <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
            color: save && Math.min(...scrollIndex) === 11 && imgUrl && !schemaUrl && 'red',
          }}
          ref={Math.min(...scrollIndex) === 11 && imgUrl && !schemaUrl ? scrollRef : null}
        >
          Схема. Размещение на карте
        </p>
        <div>
          <StyledGalleryFilters
            index={galleryIndexMapRest}
            data={data}
            setIndex={setGalleryIndexMapRest}
            setAddSlide={setAddSlideMapRest}
            addSlide={addSlideMapRest}
            schemaUrl={schemaUrl}
            setUrl={setSchemaUrl}
            setSave={setSave}
          />
        </div>
      </div>
      <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
        <p
          ref={Math.min(...scrollIndex) === 11 && imgUrl && schemaUrl ? scrollRef : null}
          className="font-normal opacity-50"
          style={{
            color: save && scrollIndex.includes(11) && 'red',
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Описание объекта
        </p>

        <ObjectDescriptionTextarea
          objectStyles={textareaFiltersStyles}
          value={selectedObjectFiltersRest[11]}
          setValue={handleChangeTextarea}
        />
        <div className="flex flex-row items-center justify-between">
          <Button
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#000"
            bgColor="#fff"
            onClick={() => setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, 11: [''] })}
          >
            Очистить
          </Button>
          <Button
            style={{ width: px(141) }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#fff"
            onClick={() => setSaveDescription({ ...saveDescription, saveRestDescription: true })}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FiltersFilterRest;
