/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateGroup } from '../../../../../../../actions/allow-group/thunks';
import { AccessSettingsTime, Checkbox, RangeSlider } from '../../../../../../../components';
import { hoursNumber, interestData } from '../../../../../../../constants/Component/date-picker';
import { Picker } from '../../../../../../shared';
import { CheckboxFieldGroup } from '../../../../../../shared/checkbox-field-group';
import { px } from '../../../../../../utils';
// eslint-disable-next-line import/no-cycle
import { useProfile } from '../../../../../hooks';
import { AccessSettingsPeriod } from '../access-settings-period';

export const AccessSettings = ({ type, free, number, chosenNumber }) => {
  const dispatch = useDispatch();
  const week = [
    {
      id: 'monday',
      letter: 'п',
    },
    {
      id: 'tuesday',
      letter: 'в',
    },
    {
      id: 'wednesday',
      letter: 'с',
    },
    {
      id: 'thursday',
      letter: 'ч',
    },
    {
      id: 'friday',
      letter: 'п',
    },
    {
      id: 'saturday',
      letter: 'с',
    },
    {
      id: 'sunday',
      letter: 'в',
    },
  ];

  const [showSeventyPicker, setShowSeventyPicker] = useState(false);
  const [showFiftyPicker, setShowFiftyPicker] = useState(false);
  const [showZeroPicker, setShowZeroPicker] = useState(false);
  const [placeNum, setPlaceNum] = useState(chosenNumber || +number);

  const {
    data: { allowanceGroup },
    setData: setProfile,
  } = useProfile();

  const setAllownaceGroup = payload => {
    setProfile({
      allowanceGroup: {
        ...allowanceGroup,
        ...payload,
      },
    });
  };

  const {
    payment,
    discounts,
    booking,
    workdays,
    weekends,
    refund0,
    refund50,
    openStart,
    openEnd,
    isOpen,
    isBlocked,
    isPause,
    pause,
    blockStart,
    blockEnd,
    isPrefDeparture,
    prefDeparture,
    placeNumber,
  } = allowanceGroup || {};

  useEffect(() => {
    if (allowanceGroup)
      dispatch(
        updateGroup({
          data: {
            payment,
            booking,
            discounts,
            weekends,
            workdays,
            refund0,
            refund50,
            isOpen,
            openStart,
            openEnd,
            isBlocked,
            isPause,
            pause,
            blockStart,
            blockEnd,
            isPrefDeparture,
            prefDeparture,
            placeNumber,
          },
          id: allowanceGroup.id,
        }),
      );
  }, [JSON.stringify(allowanceGroup)]);

  useEffect(() => {
    if (weekends.length === 0)
      setAllownaceGroup({
        weekends: [false, false, false, false, false, false, false],
        workdays: [false, false, false, false, false, false, false],
      });
  }, []);

  return (
    <div>
      <p style={{ fontSize: px(20), lineHeight: px(27) }} className="font-normal">
        Период
      </p>
      <p
        style={{ fontSize: px(15), lineHeight: px(20), marginBottom: px(4) }}
        className="font-normal"
      >
        Указать время действия
      </p>

      <div className="flex items-end justify-between" style={{ marginBottom: px(28) }}>
        <p style={{ fontSize: px(15), lineHeight: px(20), opacity: 0.5 }} className="font-normal">
          День недели
        </p>
        <p style={{ fontSize: px(20), lineHeight: px(27) }} className="font-normal">
          Будни
        </p>
      </div>
      <div
        className="flex"
        style={{
          gap: px(10),
          marginBottom: px(31),
          paddingLeft: px(6.5),
          paddingRight: px(6.5),
        }}
      >
        {week.map((item, index) => (
          <div key={item.id} className="flex flex-col items-center" style={{ gap: px(20) }}>
            <p style={{ fontSize: px(10), lineHeight: px(14) }}>{item.letter}</p>
            <div>
              <Checkbox
                checked={workdays[index] || false}
                onChange={() => {
                  if (workdays[index]) workdays[index] = !workdays[index];
                  else {
                    workdays[index] = !workdays[index];
                    weekends[index] = false;
                  }
                  setAllownaceGroup({ workdays, weekends });
                }}
                margin="0"
              />
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-end justify-between" style={{ marginBottom: px(28) }}>
        <p style={{ fontSize: px(15), lineHeight: px(20), opacity: 0.5 }} className="font-normal">
          День недели
        </p>
        <p style={{ fontSize: px(20), lineHeight: px(27) }} className="font-normal">
          Выходные
        </p>
      </div>
      <div
        className="flex"
        style={{
          gap: px(10),
          marginBottom: px(39),
          paddingLeft: px(6.5),
          paddingRight: px(6.5),
        }}
      >
        {week.map((item, index) => (
          <div key={item.id} className="flex flex-col items-center" style={{ gap: px(20) }}>
            <p style={{ fontSize: px(10), lineHeight: px(14) }}>{item.letter}</p>
            <div>
              <Checkbox
                checked={weekends[index] || false}
                onChange={() => {
                  if (weekends[index]) weekends[index] = !weekends[index];
                  else {
                    weekends[index] = !weekends[index];
                    workdays[index] = false;
                  }
                  setAllownaceGroup({ workdays, weekends });
                }}
                margin="0"
              />
            </div>
          </div>
        ))}
      </div>

      <div style={{ marginBottom: px(43) }}>
        <CheckboxFieldGroup
          multiple
          allownce
          title="Оплата при заезде"
          items={[
            {
              label: 'Банковские карты',
              value: 'card',
            },
            { label: 'Денежные переводы', value: 'money' },
            { label: 'Наличные', value: 'cash' },
          ]}
          value={payment}
          onChange={payment => setAllownaceGroup({ payment })}
        />
      </div>

      <div style={{ marginBottom: px(39) }}>
        <p
          style={{ fontSize: px(20), lineHeight: px(27), marginBottom: px(20) }}
          className="font-normal"
        >
          Предоплата
        </p>
        <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            Размер аванса (не &#60; комиссии)
          </p>
          <p
            style={{ fontSize: px(20), lineHeight: px(20) }}
            className="font-normal text-[#FF0A0A]"
          >
            20 %
          </p>
        </div>
      </div>

      <div style={{ marginBottom: px(39) }}>
        <p style={{ fontSize: px(20), lineHeight: px(27) }} className="font-normal">
          Возврат денег
        </p>
        <p
          style={{ fontSize: px(15), lineHeight: px(20), marginBottom: px(20) }}
          className="font-normal"
        >
          При бронировании. Указать период
        </p>
        <div className="flex items-center justify-between" style={{ marginBottom: px(15) }}>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            100 % возврат за (часов)
          </p>
          <p
            style={{ fontSize: px(20), lineHeight: px(20) }}
            className="font-normal text-[#39CA24]"
          >
            120
          </p>
        </div>

        <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            75 % возврат за (часов)
          </p>
          <p
            style={{ fontSize: px(20), lineHeight: px(20) }}
            className="font-normal text-[#39CA24]"
          >
            72
          </p>
        </div>

        <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            50 % возврат за (часов)
          </p>
          <p
            style={{ fontSize: px(20), lineHeight: px(20) }}
            className={`font-normal ${refund50 === '0' ? 'text-[#FF0A0A]' : 'text-[#0A84FE]'}`}
            onClick={() => setShowFiftyPicker(true)}
            aria-hidden
          >
            {refund50 || '48'}
          </p>
          <Picker
            singleItem
            title="50 % возврат"
            show={showFiftyPicker}
            onClose={() => setShowFiftyPicker(false)}
            data={{
              refund50: hoursNumber.hoursNumber,
            }}
            onChange={({ refund50 }) => setAllownaceGroup({ refund50 })}
            value={{
              refund50,
            }}
            button
            isLine
          />
        </div>

        <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            0 % возврат за менее (часов)
          </p>
          <p
            style={{ fontSize: px(20), lineHeight: px(20) }}
            className={`font-normal ${refund0 === '0' ? 'text-[#FF0A0A]' : 'text-[#0A84FE]'}`}
            onClick={() => setShowZeroPicker(true)}
            aria-hidden
          >
            {refund0 || '24'}
          </p>
          <Picker
            singleItem
            title="0 % возврат"
            show={showZeroPicker}
            onClose={() => setShowZeroPicker(false)}
            data={{
              refund0: hoursNumber.hoursNumber,
            }}
            onChange={({ refund0 }) => setAllownaceGroup({ refund0 })}
            value={{
              refund0,
            }}
            button
            isLine
          />
        </div>

        <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            100 % при отказе в заезде
          </p>
          <div style={{ paddingRight: px(8) }}>
            <div
              style={{
                width: px(17),
                height: px(17),
                backgroundColor: '#39CA24',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>

      {type === 'FISHING' ? (
        <div style={{ marginBottom: px(39) }}>
          <CheckboxFieldGroup
            multiple
            allownce
            title="Скидки при бронировании"
            items={[
              {
                label: 'Клубные карты',
                value: 'card',
              },
              { label: 'Подарочные сертификаты', value: 'cert' },
              { label: 'Промокоды', value: 'promo' },
              { label: 'Купоны', value: 'ticket' },
            ]}
            value={discounts}
            onChange={discounts => setAllownaceGroup({ discounts })}
          />
        </div>
      ) : (
        type === 'REST' && (
          <div style={{ marginBottom: px(39) }}>
            <div style={{ marginBottom: px(20) }}>
              <CheckboxFieldGroup
                multiple
                allownce
                title="Скидки при бронировании"
                items={[
                  {
                    label: 'Клубные карты',
                    value: 'card',
                  },
                  { label: 'Подарочные сертификаты', value: 'cert' },
                ]}
                value={discounts}
                onChange={discounts => setAllownaceGroup({ discounts })}
              />
            </div>

            <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
              <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
                Промокоды системы брони...
              </p>
              <div style={{ paddingRight: px(8) }}>
                <div
                  style={{
                    width: px(17),
                    height: px(17),
                    backgroundColor: '#39CA24',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
            <div className="flex items-center justify-between" style={{ marginBottom: px(20) }}>
              <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
                Клубная карта системы брони...
              </p>
              <div style={{ paddingRight: px(8) }}>
                <div
                  style={{
                    width: px(17),
                    height: px(17),
                    backgroundColor: '#39CA24',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
        )
      )}

      <div style={{ marginBottom: px(53) }}>
        <CheckboxFieldGroup
          multiple
          allownce
          title="Бронирование при наличии"
          items={[
            { label: 'Фото ваших выездов', value: 'tripsPhoto' },
            {
              label: 'Клубная карта',
              value: 'card',
            },
            { label: 'Рекомендации', value: 'recomendation' },
            { label: 'Спортивная команда', value: 'team' },
            { label: 'Спортивный клуб', value: 'club' },
            { label: 'Книжка спортсмена', value: 'book' },
          ]}
          value={booking}
          onChange={booking => setAllownaceGroup({ booking })}
        />
      </div>

      <AccessSettingsTime
        title={`Открыть\nдля бронирования`}
        subTitle="Меняйте период в зависимости от задач, условий, погоды и сезона:"
        description="Открыть"
        checked={isOpen}
        start={openStart}
        end={openEnd}
      />

      <AccessSettingsTime
        title="Заблокировать временно"
        subTitle="Вы можете временно заблокировать просмотр и бронирование, в связи с возникшими обстоятельствами"
        description="Заблокировать"
        checked={isBlocked}
        start={blockStart}
        end={blockEnd}
        block
      />

      <AccessSettingsPeriod />

      {type === 'REST' && <AccessSettingsPeriod pauseType />}

      {free && +number !== 0 && (
        <div style={{ marginBottom: px(19) }}>
          <p style={{ fontSize: px(32), lineHeight: px(32) }}>Лимит на бронирование</p>
          <p
            style={{ fontSize: px(15), lineHeight: px(20), marginBottom: px(19) }}
            className="font-normal"
          >
            Установите допуск к бронированию мест на FREE ZONE. Увеличивайте или уменьшайте их
            относительно продаж
          </p>
          <div
            className="flex justify-between"
            style={{ marginBottom: px(19), fontSize: px(20), lineHeight: px(27), fontWeight: 400 }}
          >
            <span>{placeNum}</span>
            <span>{+number}</span>
          </div>
          <RangeSlider
            free
            min={0}
            // marks={[]}
            max={+number}
            value={placeNum}
            snapRange={0}
            onChange={preferredDistance => {
              setAllownaceGroup({ placeNumber: preferredDistance });
              setPlaceNum(preferredDistance);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AccessSettings;
