/* eslint-disable unicorn/no-useless-fallback-in-spread */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unicorn/no-null */
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useLocation, useNavigate } from 'react-router';

import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../shared/icon';
import { numberPx, px } from '../../utils';
import { CircleButton } from '../circle-button';
import { Modal } from '../modal/modal';
import { ImageLoaderModalHeader } from './components';
import { getCroppedImg } from './helpers/image-cropper.helper';
import emptyImage from './images/empty.png';

export const ImageLoaderModal = ({
  show,
  onClose,
  items: defaultItems,
  onChange,
  canBeFirst,
  noDelete,
  onController,
  label,
  crop,
}) => {
  const [items, setItems] = useState();
  const [currentKey, setCurrentKey] = useState();
  const currentItem = useMemo(
    () => items?.find?.(i => i?.key === currentKey),
    [JSON.stringify(items), currentKey],
  );

  const setCurrentItem = useCallback(
    payload => {
      setItems(
        items?.map(item =>
          item?.key === currentKey
            ? payload?.image
              ? {
                  ...currentItem,
                  ...payload,
                  cropPos: { x: 0, y: 0 },
                  zoom: 1,
                }
              : {
                  ...currentItem,
                  ...payload,
                }
            : item,
        ),
      );
    },
    [JSON.stringify(currentItem)],
  );

  useLayoutEffect(() => setCurrentKey(defaultItems?.[0]?.key || 0), []);

  useLayoutEffect(() => {
    setItems(
      defaultItems?.map((item, index) => ({
        crop: item?.crop || crop || { width: 320, height: 290 },
        label: item?.label || label || 'Фото',
        cropPos: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: undefined,
        savedImage: item?.image,
        image: undefined,
        key: item?.key || index,
      })),
    );
  }, [JSON.stringify(defaultItems)]);

  useEffect(() => {
    if (onController) onController({ currentKey, currentItem, setCurrentKey });
  }, []);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleImgChange = event => {
    const file = event?.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', ({ target: { result: image } }) =>
        setCurrentItem({ image, savedImage: undefined }),
      );
      reader.readAsDataURL(file);
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const modalHeaderProps = useMemo(() => {
    const prevItem = items?.[items?.findIndex?.(i => i?.key === currentKey) - 1];
    const nextItem = items?.[items?.findIndex?.(i => i?.key === currentKey) + 1];

    return {
      title: currentItem?.label,
      onBack: prevItem ? () => setCurrentKey(prevItem?.key) : undefined,
      onNext: nextItem ? () => setCurrentKey(nextItem?.key) : undefined,
    };
  }, [JSON.stringify(items), currentKey]);

  return (
    <Modal
      title="Фото"
      show={show}
      onClose={() => {
        if (onChange) onChange(Object.fromEntries(items.map(item => [item.key, item?.savedImage])));
        if (onClose) onClose();
        setCurrentKey(defaultItems?.[0]?.key || 0);
      }}
      customHeader={ImageLoaderModalHeader}
      customHeaderProps={modalHeaderProps}
    >
      <div
        className="search h-full overflow-y-auto"
        style={{
          marginLeft: px(-15),
          paddingLeft: px(0),
          marginRight: px(-15),
          paddingRight: px(0),
        }}
      >
        <div className="flex-column flex w-full items-center justify-center">
          <div />
          <div
            style={{
              width: px(320),
              height: px(304),
            }}
            className="relative flex items-center justify-center overflow-y-scroll bg-[#0A84FE4D]"
          >
            {currentItem?.savedImage?.src ? (
              <img
                src={currentItem?.savedImage?.src}
                style={{
                  ...currentItem?.crop?.style,
                  width: px(currentItem?.crop?.width),
                  height: px(currentItem?.crop?.height),
                }}
                alt={currentItem?.key}
              />
            ) : (
              <>
                {currentItem && (
                  <Cropper
                    image={currentItem?.image || emptyImage}
                    crop={currentItem?.cropPos}
                    zoom={currentItem?.zoom}
                    onCropChange={cropPos => setCurrentItem({ cropPos })}
                    onZoomChange={zoom => setCurrentItem({ zoom })}
                    onCropComplete={(_, croppedAreaPixels) => setCurrentItem({ croppedAreaPixels })}
                    style={{
                      cropAreaStyle: {
                        boxShadow: 'none',
                        background: '#ffffff60',
                        border: 'none',
                        ...(currentItem?.crop?.style || {}),
                      },
                      mediaStyle: currentItem?.mediaStyle,
                    }}
                    restrictPosition={false}
                    cropSize={{
                      width: numberPx(currentItem?.crop?.width),
                      height: numberPx(currentItem?.crop?.height),
                    }}
                    showGrid={false}
                    minZoom={(currentItem?.crop?.width || 200) / 320}
                    maxZoom={3}
                  />
                )}
                <Icon
                  type={TypesIcon.RESIZE}
                  viewBox="0 0 70 70"
                  width={px(70)}
                  height={px(70)}
                  className="pointer-events-none relative"
                />
                <div
                  style={{
                    position: 'absolute',
                    right: px(19),
                    bottom: px(65),
                    width: px(40),
                    height: px(40),
                    zIndex: 5,
                    border: '1px solid #0A84FE',
                    borderRadius: '50%',
                  }}
                  className="flex items-center justify-center"
                  aria-hidden
                  onClick={() => {
                    setCurrentItem({
                      zoom: 1.2 * currentItem.zoom > 3 ? 3 : 1.2 * currentItem.zoom,
                    });
                  }}
                >
                  <Icon
                    type={TypesIcon.PLUSFORBUTTON}
                    viewBox="0 0 16.971 16.971"
                    width={px(16.971)}
                    height={px(16.971)}
                    fill="#0A84FE"
                    className="pointer-events-none relative"
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    right: px(19),
                    bottom: px(15),
                    width: px(40),
                    height: px(40),
                    zIndex: 5,
                    border: '1px solid #0A84FE',
                    borderRadius: '50%',
                  }}
                  className="flex items-center justify-center"
                  aria-hidden
                  onClick={() => {
                    setCurrentItem({
                      zoom:
                        currentItem.zoom / 1.2 < (currentItem?.crop?.width || 200) / 320
                          ? (currentItem?.crop?.width || 200) / 320
                          : currentItem.zoom / 1.2,
                    });
                  }}
                >
                  <Icon
                    type={TypesIcon.BLUEMINUS}
                    viewBox="0 0 16.5 1"
                    width={px(16.5)}
                    height={px(1)}
                    fill="#0A84FE"
                    stroke="#0A84FE"
                    currentColor="#0A84FE"
                    className="pointer-events-none relative"
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div
          style={{
            gap: px(10),
            paddingTop: px(10),
          }}
          className="flex items-center justify-center"
        >
          <CircleButton
            onClick={() =>
              getCroppedImg(currentItem?.image, currentItem?.croppedAreaPixels).then(image =>
                setCurrentItem({ savedImage: { src: URL.createObjectURL(image) } }),
              )
            }
            disabled={currentItem?.savedImage || !currentItem?.image}
            icon={{
              type: TypesIcon.CONFIRM,
              viewBox: '0 0 19.59 24.806',
              width: px(19.59),
              height: px(50),
            }}
          />

          <CircleButton
            onClick={() => navigate('/demand', { state: { prevPath: pathname } })}
            icon={{
              type: TypesIcon.INFOCURSIVE,
              viewBox: '0 0 17 25',
              width: px(17),
              height: px(25),
            }}
          />

          <CircleButton
            onClick={() => {}}
            noActive
            icon={{
              type: TypesIcon.GALLERY,
              viewBox: '0 0 26.827 23.512',
              width: px(26.827),
              height: px(23.512),
              fill: '#0A84FE',
            }}
          >
            <label className="absolute inset-0 flex items-center justify-center">
              <input
                className="leading-0 m-0 h-0 w-0 overflow-hidden p-0 opacity-0"
                id="upload-photo"
                type="file"
                accept="image/*"
                onChange={handleImgChange}
              />
            </label>
          </CircleButton>
          {canBeFirst && (
            <CircleButton
              style={{
                paddingRight: px(3),
              }}
              onClick={() =>
                setItems(
                  items?.map?.(item => ({
                    ...item,
                    savedImage: item?.savedImage
                      ? {
                          ...item?.savedImage,
                          first: item?.key === currentKey ? !item?.savedImage?.first : false,
                        }
                      : undefined,
                  })),
                )
              }
              disabled={!currentItem?.savedImage?.src}
              active={currentItem?.savedImage?.first}
              icon={{
                type: TypesIcon.FIRST,
                viewBox: '0 0 8.734 22.859',
                width: px(8.734),
                height: px(22.859),
                className: '[&_*]:fill-[#0A84FE]',
              }}
            />
          )}
          <CircleButton
            onClick={() => setCurrentItem({ image: undefined, savedImage: undefined })}
            disabled={noDelete || (!currentItem?.image && !currentItem?.savedImage?.src)}
            icon={{
              type: TypesIcon.SMALLBASKET,
              viewBox: '0 0 20.5 25.231',
              width: px(20.5),
              height: px(25.231),
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImageLoaderModal;
