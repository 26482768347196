import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/profile';
import { createRequest } from '@/helpers/api/createRequest';

export const GET_REGISTRATION_CARD = 'profile/getRegistrationCard';

export const getRegistrationCard = createAsyncThunk(
  GET_REGISTRATION_CARD,
  createRequest({
    type: GET_REGISTRATION_CARD,
    loader: data => authApi.getRegistrationCard(data),
  }),
);
