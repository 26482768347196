/* eslint-disable import/no-unresolved */
import { request } from '@/helpers/api/request';

export const updateGuideAttachment = async data =>
  request({
    url: `competition-attachment/${data.id}`,
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    method: 'PUT',
    data: data.data,
  });
