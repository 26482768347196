/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useState } from 'react';
import Picker from 'react-mobile-picker';
import { useLocation } from 'react-router';

import { getData } from '../constants/Component';
import { saveEmptyFile } from '../constants/Function';
import { numberPx, px } from '../constants/Px';
import { Button } from './button';
import { Modal } from './portals';

export const DatePicker = ({
  show,
  setShow,
  pickerValue,
  setPickerValue,
  data,
  isDate,
  title,
  buttonTitle,
  singleItem,
  isDocumentType,
  isDatePeriod,
  date,
  id,
  onClick,
  closeWithParams,
  isCloseWithParams,
  changeDocumentType,
  setDataPickerId,
  isLine,
}) => {
  const { pathname } = useLocation();

  const modifierData = isDate ? getData(pickerValue) : data;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [type, setType] = useState('');


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = () => {
    if (isDatePeriod) {
      id === 1 && saveEmptyFile(type);
      onClick(pickerValue, true);

      id === 1 && setShow(false);
    } else if (isDocumentType && pickerValue?.statistic === 'Выбрать период') {
      setType(pickerValue?.documentType);
      setDataPickerId(prev => prev + 1);
    } else if (isDocumentType) {
      saveEmptyFile(pickerValue?.documentType);
      setShow(false);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Modal
      isOpen={show}
      title={title}
      isLine={isLine}
      onClose={() => {
        // eslint-disable-next-line no-unused-expressions
        isCloseWithParams ? closeWithParams() : setShow(false);
      }}
    >
      <div
        style={{
          padding: `${px(35)} ${px(15)} ${px(49)} ${px(15)}`,
        }}
      >
        <Picker
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          itemHeight={numberPx(43)}
          height={numberPx(216)}
          value={pickerValue}
          onChange={setPickerValue}
        >
          {Object?.keys(modifierData)?.map((name, index) => (
            <Picker.Column
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:
                  singleItem || index === 1 ? 'center' : index === 0 ? 'start' : index === 2,
              }}
              key={name}
              name={name}
            >
              {modifierData?.[name]?.map(option => (
                <Picker.Item
                  key={option}
                  value={option}
                  style={{
                    justifyContent: isDate && index === 0 ? 'flex-start' : 'flex-end',
                    fontSize: px(32),
                    lineHeight: px(20),
                    height: px(43),
                    width: isDate && index === 0 && px(140.08),
                  }}
                >
                  {({ selected }) => (
                    <div
                      style={{
                        color: '#000000',
                        fontSize: px(32),
                        lineHeight: px(20),
                        height: px(43),
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: isDate && index === 0 && 'left',
                        opacity: selected ? '1' : '0.2',
                      }}
                    >
                      {option}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
          ))}
        </Picker>
        {isDocumentType && (
          <div
            style={{
              gap: px(10),
              marginTop: px(13),
            }}
            className="flex"
          >
            {['TXT (1C)', 'EXCEL', 'PDF'].map(item => (
              <div
                aria-hidden
                style={{
                  height: px(40),
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className={`flex border-[1px] border-solid ${
                  item === pickerValue?.documentType ? 'border-[#0A84FE]' : 'border-black'
                } w-full items-center justify-center border-opacity-30 font-normal`}
                onClick={() => {
                  changeDocumentType(item);
                }}
                key={item}
              >
                {item}
              </div>
            ))}
          </div>
        )}

        {isDatePeriod && (
          <div
            style={{
              gap: px(10),
              marginTop: px(13),
            }}
            className="flex"
          >
            {Array?.from({ length: 2 }, (_, index) => (
              <div
                aria-hidden
                style={{
                  height: px(40),
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className={`flex border-[1px] border-solid ${
                  id === index ? 'border-[#0A84FE]' : 'border-black'
                } w-full items-center justify-center border-opacity-30 font-normal`}
                onClick={() => {
                  onClick(pickerValue);
                }}
                key={index}
              >
                {index === id
                  ? `${
                      +pickerValue?.day < 10 ? `0${pickerValue?.day}` : pickerValue?.day
                    } ${pickerValue?.month?.slice(0, 3)?.toLowerCase()} ${pickerValue?.year}`
                  : index === 0
                    ? `${
                        +date?.start?.day < 10 ? `0${date?.start?.day}` : date?.start?.day
                      } ${date?.start?.month?.slice(0, 3)?.toLowerCase()} ${date?.start?.year}`
                    : `${
                        +date?.end?.day < 10
                          ? date?.end?.day && `0${date?.end?.day}`
                          : date?.end?.day
                      } ${date?.end?.month?.slice(0, 3)?.toLowerCase()} ${date?.end?.year}`}
              </div>
            ))}
          </div>
        )}

        {buttonTitle && (
          <Button
            className="w-full border-[1px] border-solid border-[#dddddd]"
            marginTop={isDatePeriod || isDocumentType ? 10 : 63}
            height={60}
            borderRadius={0}
            onClick={handleClick}
          >
            Применить
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default DatePicker;
