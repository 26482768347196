import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const UPDATE_GUIDE_OFFER = 'guide/updateGuideOffer';

export const updateGuideOffer = createAsyncThunk(
  UPDATE_GUIDE_OFFER,
  createRequest({
    type: UPDATE_GUIDE_OFFER,
    loader: data => authApi.updateGuideOffer(data),
  }),
);
