import React, { useState } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const DropdownList = ({ objectDropdownList, index, setActiveIndex, light }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  function mainSelect() {
    if (isOpen) {
      setSelectedOption(objectDropdownList[index]?.title ?? objectDropdownList[index]?.name);
    }
    setIsOpen(!isOpen);
  }

  function select(e) {
    objectDropdownList?.map((elem, i) => {
      if (e === elem?.id) {
        setSelectedOption(elem?.title ?? elem?.name);
        if (setActiveIndex) setActiveIndex(i);
      }
    });
    setIsOpen(false);
  }

  return (
    <div
      style={{
        paddingTop: px(19),
      }}
    >
      <div
        className={`${
          light ? 'border-[#ffffff80]' : 'border-black'
        } flex flex-row items-center justify-between border-[1px] border-solid border-opacity-30`}
        onClick={mainSelect}
        aria-hidden
        style={{
          height: isOpen ? px(70) : px(60),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
      >
        <p
          className={`${light ? 'text-[#ffffff80]' : 'text-black'} font-normal`}
          style={{
            fontSize: px(20),
            lineHeight: px(26),
          }}
        >
          {isOpen
            ? objectDropdownList[index]?.title || objectDropdownList[index]?.name
            : selectedOption || objectDropdownList[index]?.title || objectDropdownList[index]?.name}
        </p>
        {!isOpen && (
          <div className="">
            <Icon
              fill={light ? '#707070' : '#000000'}
              width={px(8)}
              height={px(6)}
              viewBox="0 0 8 6"
              type={TypesIcon.ARROWDOWN}
            />
          </div>
        )}
      </div>

      {isOpen &&
        objectDropdownList?.map(
          item =>
            item.id !== objectDropdownList[index]?.id && (
              <div key={item.id}>
                <div
                  className={`${
                    light ? 'border-[#ffffff80]' : 'border-black'
                  } flex flex-row items-center justify-between border-[1px] border-solid border-opacity-30`}
                  aria-hidden
                  onClick={() => select(item?.id)}
                  style={{
                    height: px(70),
                    paddingLeft: px(18),
                    paddingRight: px(18),
                  }}
                >
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className={`${light ? 'text-[#ffffff80]' : 'text-black'} font-normal`}
                  >
                    {item?.title ?? item?.name}
                  </p>
                </div>
              </div>
            ),
        )}
    </div>
  );
};

export default DropdownList;
