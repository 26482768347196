import { request } from '@/helpers/api/request';

export const emailEnd = async ({
  email,
  code
}) => {

  const response =   await request({
    url: `profile/email/end`,
    method: 'POST',
    data: {
      email,
      code
    }
  });

  return response;
};

