import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';

export const DELETE_CARD = 'card/deleteCard';

export const deleteCard = createAsyncThunk(
  DELETE_CARD,
  createRequest({
    type: DELETE_CARD,
    loader: data => authApi.deleteCard(data),
  }),
);
