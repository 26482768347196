// eslint-disable-next-line import/no-unresolved
import { request } from '@/helpers/api/request';

export const deleteCompetitionAttachment = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `competition-attachment/${data.id}`,
    method: 'DELETE',
    data: data.data,
  });
  return response;
};
