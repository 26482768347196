/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/attachment';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_ATTACHMENT = 'attachment/deleteAttachment';

export const deleteAttachment = createAsyncThunk(
  DELETE_ATTACHMENT,
  createRequest({
    type: DELETE_ATTACHMENT,
    loader: data => authApi.deleteAttachment(data),
  }),
);
