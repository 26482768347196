import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Main } from '../../components';
import { px } from '../../constants/Px';

export const DemandPage = () => {
  const { pathname, state } = useLocation();
  useEffect(() => {
    if (document.documentElement.classList.contains('lock')) {
      document.documentElement.classList.remove('lock');
    }
    return () => {
      if (document.documentElement.classList.contains('lock')) {
        document.documentElement.classList.add('lock');
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="">
      <div className="w-full">
        <Main
          title="Требования"
          light
          registration
          isIconRegistretion
          navigatePatch={`${state?.prevPath}`}
          paddingBottom
        >
          <div
            style={{
              marginTop: px(4),
            }}
            className="flex flex-col"
          >
            <div
              style={{
                gap: px(6),
                marginBottom: px(20),
              }}
              className="flex flex-col"
            >
              <p
                className="font-normal opacity-50"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                Фото
              </p>
              <p
                className=" whitespace-pre-wrap font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {`Формат: jpg, jpeg, png, gif\nРазмер: не более 25 МБ\nКартинка: w 320 x h 290 px`}
              </p>
            </div>

            <div
              style={{
                gap: px(5),
                marginBottom: px(20),
              }}
              className="flex flex-col"
            >
              <p
                className="font-normal opacity-50"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                Видео
              </p>

              <p
                className=" whitespace-pre-wrap font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {`Формат: jpg, jpeg, png, gif\nРазмер: не более 25 МБ\nмммиип: таптаптаптапап`}
              </p>
            </div>
            <div className="flex flex-col">
              <p
                className="font-normal opacity-50"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                Важно
              </p>

              <p
                className=" whitespace-pre-wrap font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {`Нельзя публиковать фото и видео\nпросто из интернета и те, которые\nмогут ввести других людей в\nзаблуждение.`}
              </p>
              <p
                className=" whitespace-pre-wrap font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {`Чего не должно быть на фото:\n- адреса, телефона, почты или других \nконтактов;\n- цветных рамок или фонов;\n- откровенных, пугающих или \nэстетически неприемлемых \nизображений;\n- мата или оскорблений;\n- подобных фраз: «Выбор …», «Выбор \nпользователей …»;\n- изображений запрещённых товаров;\n- изображений другого человека без его \nсогласия;\n- логотипа (нашего) или других \nлоготипов, на использование которых у \nвас нет прав.\n`}
              </p>
              <p
                className=" whitespace-pre-wrap font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {`\nЗапрещенные товары и услуги:\nЕсли вы не уверены, разрешено ли на … \nваше предложение, обратитесь в `}
              </p>

              <Link
                to="/chat-with-support"
                rel="noreferrer"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
                className="text-[#0A84FE] "
              >
                службу поддержки.
              </Link>

              <p
                className=" whitespace-pre-wrap font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {`\nМедицина и здоровье\n1. Медицинские услуги, за исключением \nкосметологических услуг\n2. Лекарственные средства (включая \nлекарственные \nпрепараты для ветеринарного \nприменения)`}{' '}
              </p>
            </div>
          </div>
        </Main>
      </div>
    </div>
  );
};
