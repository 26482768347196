import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { createRootReducer } from '../reducers';

const makeStore = () =>
  configureStore({
    reducer: createRootReducer(),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these field paths in all actions
          ignoredActionPaths: ['payload.onSuccessAction'],
          // Ignore these paths in the state
          ignoredPaths: ['modals'],
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

export const store = makeStore();

// хачим диспатч чтобы можно было вызывать экшены не только через хуки

export const useAppDispatch = () => useDispatch();

// типизация селектора

export { useSelector as useTypeSelector } from 'react-redux';
