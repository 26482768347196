import { TypesIcon } from '../../../types/TypesIcon';

export const priceModalSectors = [
  {
    id: 100,
    price: '12470',
    type: TypesIcon.SECTOR,
    sectorNumber: 1,
    discount: 40,
  },
  {
    id: 101,
    price: '12470',
    type: TypesIcon.SECTOR,
    sectorNumber: 2,
  },
];

export const tarifCardValues = [
  {
    id: 0,
    value: 'day',
    price: '0',
    time: '00:00',
    endTime: '0:00',
    typeTime: 'СУТКИ',
    isSunActive: true,
  },
  {
    id: 1,
    value: 'night',
    price: '0',
    time: '00:00',
    endTime: '00:00',
    typeTime: 'НОЧЬ',
    isSunActive: false,
  },
  {
    id: 2,
    value: 'hour',
    price: '0',
    time: '00:00',
    endTime: '00:00',
    typeTime: 'ЧАС',
    isSunActive: true,
  },
];
