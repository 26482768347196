import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import icon from '../../assets/images/favicon.png';
import { Icon } from '../../components/Icon';
import { px } from '../../constants/Px';
import { LogoIcon } from '../../new-architecture/icons';
import { BackButton } from '../../new-architecture/shared';
import { TypesIcon } from '../../types/TypesIcon';

export const AboutPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="global-container ">
      <BackButton onClick={() => navigate('/mobile')} />
      <main>
        <div className="flex-column flex items-center justify-center">
          <LogoIcon
            style={{
              width: px(35.7),
              height: px(40),
            }}
          />
          <p
            className="font-light"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginTop: px(20),
            }}
          >
            Booking.fishing
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              marginTop: px(10),
            }}
          >
            Версия: 1.0
          </p>

          <div
            aria-hidden
            onClick={() => navigate('/feedback')}
            className="flex flex-row items-center self-start"
            style={{
              gap: px(19.8),
              marginTop: px(30),
              paddingLeft: px(15),
            }}
          >
            <Icon type={TypesIcon.CLASS} width={px(29)} height={px(28)} viewBox="0 0 29 28" />
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
              className="font-normal"
            >
              Оцените сайт
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};
