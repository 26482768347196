/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/no-useless-undefined */
import { createReducer } from '@reduxjs/toolkit';

import {
  getGuideCabinet,
  getGuideCabinetByProfile,
  getGuideOffer,
  getGuides,
} from '../../actions/guide';
/* import { objectImageTypeSlider } from '../../constants/Component'; */

export const guide = createReducer({}, builder => {
  builder.addCase(getGuideCabinetByProfile.fulfilled, (state, { payload }) => ({
    ...state,
    guides: payload?.data,
  }));
  builder.addCase(getGuideCabinet.fulfilled, (state, { payload }) => ({
    ...state,
    guide: payload?.data,
    logo: payload?.data?.attachments?.find(att => att.type === 'LOGO')?.url,
    schema:
      payload?.data?.attachments?.filter(index => index?.type === 'SCHEMA').length > 0
        ? payload?.data?.attachments
            ?.filter(index => index?.type === 'SCHEMA')
            .slice()
            .sort((a, b) => a.priority - b.priority)
            .map(item => ({
              id: item?.id,
              src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
              type: item?.type,
              title: item?.title,
              textFileName: decodeURIComponent(item?.textFileName),
              textFileUrl: item?.textFileUrl,
            }))
        : [],
    news: payload?.data?.news?.slice().sort((a, b) => a.id - b.id),
    gallery:
      payload?.data?.attachments?.filter(index => index?.type === 'GALLERY').length > 0
        ? payload?.data?.attachments
            ?.filter(index => index?.type === 'GALLERY')
            .map(item => ({
              id: item?.id,
              src: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
              type: item?.type,
            }))
        : [{ id: undefined, src: '', type: 'blueBg' }],
  }));
  builder.addCase(getGuides.fulfilled, (state, { payload }) => ({
    ...state,
    listOfGuides: payload?.data,
  }));
  builder.addCase(getGuideOffer.fulfilled, (state, { payload }) => ({
    ...state,
    guideOffer: payload?.data,
  }));
});
