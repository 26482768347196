import { request } from '../../helpers/api/request';

export const updateCard = async ({ data, cardId }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `club-card/${cardId}`,
    method: 'PUT',
    data,
  });

  return response;
};
