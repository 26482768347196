import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const CARD_HOLDER_INVITE = 'card-holder/cardHolderInvite';

const onFail = (error, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(
    addModal({
      id: 15,
      data: {
        title: 'Проверьте правильность введных данных',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );

  console.error('Error during thunk execution:', error);
};

export const cardHolderInvite = createAsyncThunk(
  CARD_HOLDER_INVITE,
  createRequest({
    type: CARD_HOLDER_INVITE,
    loader: data => authApi.cardHolderInvite(data),
    onFail,
  }),
);
