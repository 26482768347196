/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable unicorn/prefer-spread */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import {
  createCompetitionNews,
  deleteCompetitionNews,
  getCompetitionCabinet,
  updateCompetitionNews,
} from '../../actions/competition';
import { getEvent } from '../../actions/event';
import {
  createGuideNews,
  deleteGuideNews,
  getGuideCabinet,
  updateGuideNews,
} from '../../actions/guide';
import { addNews, deleteNews, updateNews } from '../../actions/news';
import { Button, PeriodDatepicker } from '../../components';
import { getDaysInMonth, selectionsDate, timeDate } from '../../constants/Component';
import { /* getMonth, */ toISO8601 } from '../../constants/Function';
import { px } from '../../constants/Px';
import { BackButton, DynamicImageSlider, Textarea } from '../../new-architecture/shared';
import { capitalizeFirstLetter } from '../../new-architecture/utils';
import { urlToBlob } from '../../new-architecture/utils/blob';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { competitionNewsSelector, competitionSelector } from '../../selectors/competition';
import { eventObjectSelector, newsObjectSelector } from '../../selectors/get-event';
import { guideNewsSelector, guideSelector } from '../../selectors/guide';

const getMonth = month =>
  month === '01'
    ? 'Январь'
    : month === '02'
      ? 'Февраль'
      : month === '03'
        ? 'Март'
        : month === '04'
          ? 'Апрель'
          : month === '05'
            ? 'Май'
            : month === '06'
              ? 'Июнь'
              : month === '07'
                ? 'Июль'
                : month === '08'
                  ? 'Август'
                  : month === '09'
                    ? 'Сентябрь'
                    : month === '10'
                      ? 'Октябрь'
                      : month === '11'
                        ? 'Ноябрь'
                        : 'Декабрь';

const getNextMonth = month =>
  month === 'Январь'
    ? 'Февраль'
    : month === 'Февраль'
      ? 'Март'
      : month === 'Март'
        ? 'Апрель'
        : month === 'Апрель'
          ? 'Май'
          : month === 'Май'
            ? 'Июнь'
            : month === 'Июнь'
              ? 'Июль'
              : month === 'Июль'
                ? 'Август'
                : month === 'Август'
                  ? 'Сентябрь'
                  : month === 'Сентябрь'
                    ? 'Октябрь'
                    : month === 'Октябрь'
                      ? 'Ноябрь'
                      : month === 'Ноябрь'
                        ? 'Декабрь'
                        : 'Январь';

const daysBetweenDates = (date1, date2) => {
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / (1000 * 3600 * 24));
};

const defaultFields = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const currentHour = currentDate?.getHours();

  return {
    title: '',
    subtitle: '',
    body: '',
    startTime: {
      hour: `${currentHour}:00`,
    },
    endTime: {
      hour: `${currentHour}:00`,
    },
    startDate: {
      day: `${currentDay}` || '1',
      month: selectionsDate?.month[currentMonth] || 'Январь',
      year: `${currentYear}` || '2024',
    },
    endDate: {
      day: `${currentDay}` || '1',
      month: getNextMonth(selectionsDate?.month[currentMonth]) || 'Январь',
      year:
        `${selectionsDate?.month[currentMonth] === 'Декабрь' ? currentYear + 1 : currentYear}` ||
        '2024',
    },
  };
};

export const ObjectNewsPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [index, setIndex] = useState(0);
  const activeType = useSelector(authActiveType);
  const news = useSelector(
    activeType === 'competition' || activeType === 'administeredCompetition'
      ? competitionNewsSelector
      : activeType === 'object' || activeType === 'administeredObject'
        ? newsObjectSelector
        : guideNewsSelector,
  );
  const navigate = useNavigate();

  const id = useSelector(authActiveIdSelector);

  const [showPicker, setShowPicker] = useState(false);
  const [pickerId, setPickerId] = useState(1);

  const [images, setImages] = useState(
    news?.map((item, key) => ({
      src: item.images.slice().sort((a, b) => a.id - b.id)[item.images.length - 1].url,
      key,
    })),
  );

  const currentImage = useMemo(() => images?.[index], [index, JSON.stringify(images)]);

  console.log('CURRENT IMAGE', currentImage);

  const [fieldsArray, setFieldsArray] = useState();

  const setCurrentFields = value => {
    const newFieldsArray = { ...fieldsArray };
    newFieldsArray[currentImage?.key || 0] = value;
    setFieldsArray(newFieldsArray);
  };

  const currentFields = useMemo(
    () => fieldsArray?.[currentImage?.key || 0] || '',
    [currentImage?.key, JSON.stringify(fieldsArray)],
  );

  useEffect(() => {
    setImages(
      news?.map((item, key) => ({
        src: `${process.env?.REACT_APP_STATIC_URL}${
          item.images.slice().sort((a, b) => a.id - b.id)[item.images.length - 1].url
        }`,
        key,
      })),
    );

    setFieldsArray(
      Object.fromEntries(
        news?.map(({ title, subtitle, body, startsAt: isoStartDate, endsAt: isoEndDate }, key) => {
          const startDate = new Date(isoStartDate);
          const endDate = new Date(isoEndDate);

          const startYear = startDate.getFullYear();
          const startMonth = startDate.getMonth();
          const startDay = startDate.getDate();
          const startHour = startDate?.getHours();

          const endYear = endDate.getFullYear();
          const endMonth = endDate.getMonth();
          const endDay = endDate.getDate();
          const endHour = endDate?.getHours();

          return [
            key,
            {
              title,
              subtitle,
              body,
              startTime: { hour: `${startHour}:00` },
              endTime: { hour: `${endHour}:00` },
              startDate: {
                day: `${startDay}`,
                month: selectionsDate?.month[startMonth],
                year: `${startYear}`,
              },
              endDate: {
                day: `${endDay}`,
                month: selectionsDate?.month[endMonth],
                year: `${endYear}`,
              },
            },
          ];
        }) || [],
      ),
    );
  }, [JSON.stringify(news)]);

  const openPicker = value => {
    setPickerId(value);
    setShowPicker(true);
  };

  const del = async defaultId => {
    await dispatch(
      activeType === 'competition' || activeType === 'administeredCompetition'
        ? deleteCompetitionNews({ id: defaultId || news[index]?.id })
        : activeType === 'object' || activeType === 'administeredObject'
          ? deleteNews({ id: defaultId || news[index]?.id })
          : deleteGuideNews({ id: defaultId || news[index]?.id }),
    );
    await dispatch(
      activeType === 'competition' || activeType === 'administeredCompetition'
        ? getCompetitionCabinet({ id })
        : activeType === 'object' || activeType === 'administeredObject'
          ? getEvent({ id })
          : getGuideCabinet({ id }),
    );
  };

  const handleClear = () => {
    setCurrentFields(defaultFields());
  };

  const haveNotChanged = useMemo(() => {
    const currentNews = news?.[index];

    const startDateObject = new Date(
      currentFields?.startDate?.year,
      selectionsDate?.month?.indexOf(currentFields?.startDate?.month),
      currentFields?.startDate?.day,
      currentFields?.startTime?.hour?.split(':')[0],
      0,
    );

    const endDateObject = new Date(
      currentFields?.endDate?.year,
      selectionsDate?.month?.indexOf(currentFields?.endDate?.month),
      currentFields?.endDate?.day,
      currentFields?.endTime?.hour?.split(':')[0],
      0,
    );

    return (
      currentFields?.title === currentNews?.title &&
      currentFields?.subtitle === currentNews?.subtitle &&
      currentFields?.body === currentNews?.body &&
      startDateObject.getTime() === new Date(currentNews?.startsAt)?.getTime() &&
      endDateObject.getTime() === new Date(currentNews?.endsAt)?.getTime()
    );
  }, [JSON.stringify(currentFields), JSON.stringify(news)]);

  useEffect(() => {
    dispatch(
      activeType === 'competition' || activeType === 'administeredCompetition'
        ? getCompetitionCabinet({ id })
        : activeType === 'object' || activeType === 'administeredObject'
          ? getEvent({ id })
          : getGuideCabinet({ id }),
    );
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSave = async () => {
    const payload = images
      ?.map(({ src, key }) => ({
        image: src,
        fields: fieldsArray[Number(key)],
      }))
      .filter(({ image, fields }) => image && fields?.title && fields?.subtitle && fields?.body);

    // DELETE ALL NEWS
    await Promise.all(news?.map(s => del(s.id)));

    for (const [i, { image, fields }] of payload.entries()) {
      const formData = new FormData();
      formData.append('files', await urlToBlob(image, i));
      formData.append('title', fields.title);
      formData.append('subtitle', fields.subtitle);
      formData.append('body', fields.body);
      formData.append(
        'startsAt',
        toISO8601(
          fields?.startDate || defaultFields()?.startDate,
          fields?.startTime || defaultFields()?.startTime,
        ),
      );
      formData.append(
        'endsAt',
        toISO8601(
          fields?.endDate || defaultFields()?.endDate,
          fields?.endTime || defaultFields()?.endTime,
        ),
      );
      formData.append('competitionId', id);
      formData.append('guideId', id);
      formData.append('eventId', id);
      formData.append('priority', i);
      await dispatch(
        activeType === 'competition' || activeType === 'administeredCompetition'
          ? createCompetitionNews(formData)
          : activeType === 'object' || activeType === 'administeredObject'
            ? addNews(formData)
            : createGuideNews(formData),
      );
    }

    dispatch(
      activeType === 'competition' || activeType === 'administeredCompetition'
        ? getCompetitionCabinet({ id })
        : activeType === 'object' || activeType === 'administeredObject'
          ? getEvent({ id })
          : getGuideCabinet({ id }),
    );
    navigate(
      activeType === 'competition' || activeType === 'administeredCompetition'
        ? '/user-competition'
        : activeType === 'object' || activeType === 'administeredObject'
          ? '/user-object'
          : '/user-guide',
    );
  };

  const checkForDisabled = () => {
    const def = defaultFields();
    const testFields = {
      title: currentFields?.title || def.title,
      subtitle: currentFields?.subtitle || def.subtitle,
      body: currentFields?.body || def.body,
      startDate: currentFields?.startDate || def.startDate,
      endDate: currentFields?.endDate || def.endDate,
    };

    return (
      !testFields.body ||
      !testFields.title ||
      !testFields.subtitle ||
      daysBetweenDates(
        new Date(
          testFields?.startDate?.year,
          selectionsDate?.month?.indexOf(testFields?.startDate?.month),
          testFields?.startDate?.day,
        ),
        new Date(
          testFields?.endDate?.year,
          selectionsDate?.month?.indexOf(testFields?.endDate?.month),
          testFields?.endDate?.day,
        ),
      ) > 30 ||
      !images?.[index]?.src ||
      haveNotChanged
    );
  };

  return (
    <div className="global-container ">
      <main>
        <BackButton
          onClick={() =>
            navigate(
              activeType === 'competition' || activeType === 'administeredCompetition'
                ? '/user-competition'
                : activeType === 'object' || activeType === 'administeredObject'
                  ? '/user-object'
                  : '/user-guide',
            )
          }
        />
        <div
          style={{
            marginTop: px(25),
          }}
        >
          <div
            style={{
              paddingLeft: px(15),
              paddingRight: px(15),
              paddingBottom: px(20),
            }}
          >
            <p
              style={{
                fontSize: px(32),
                lineHeight: px(32),
              }}
              className="whitespace-pre-wrap  font-light"
            >
              {`Новости.\nСобытия. Акции`}
            </p>

            <p
              className="whitespace-pre-wrap  font-normal"
              style={{ fontSize: px(15), lineHeight: px(20), paddingTop: px(5) }}
            >
              {`Работа с сервисом будет возможна,\nесли Вы заполните все формы`}
            </p>
          </div>

          <DynamicImageSlider
            withText
            clearText={handleClear}
            items={news?.map(item => ({
              src: `${process.env.REACT_APP_STATIC_URL}${
                item?.images.slice().sort((a, b) => a.id - b.id)[item?.images.length - 1]?.url
              }`,
            }))}
            onSwipe={setIndex}
            onChange={setImages}
            crop={{ width: 270, height: 290 }}
          />

          <div
            style={{
              paddingLeft: px(15),
              paddingRight: px(15),
              paddingBottom: px(10),
              paddingTop: px(25),
            }}
          >
            <div
              style={{
                paddingBottom: px(20),
              }}
            >
              <p
                className="font-normal"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                Логотип. Открытка, ...
              </p>
              <p
                className="font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                Редактируйте по вашему усмотрению
              </p>
            </div>

            <Textarea
              maxLength={36}
              placeholder={`Введите текст не более\n36 знаков`}
              title="1. Заголовок на обложке"
              textareaHight={px(90)}
              style={{
                height: px(130),
                marginBottom: px(10),
                paddingTop: px(4),
                paddingLeft: px(18),
                paddingRight: px(18),
              }}
              value={currentFields.title || defaultFields().title || ''}
              onChange={title =>
                setCurrentFields({ ...currentFields, title: capitalizeFirstLetter(title) })
              }
            />
            <Textarea
              maxLength={40}
              placeholder={`Введите текст не более\n40 знаков`}
              title="2. Подзаголовок"
              textareaHight={px(90)}
              style={{
                height: px(130),
                marginBottom: px(10),
                paddingTop: px(4),
                paddingLeft: px(18),
                paddingRight: px(18),
              }}
              value={currentFields.subtitle || defaultFields().subtitle || ''}
              onChange={subtitle =>
                setCurrentFields({ ...currentFields, subtitle: capitalizeFirstLetter(subtitle) })
              }
            />
            <Textarea
              maxLength={600}
              placeholder="Введите текст не более 600 знаков"
              title="3. Текст новости"
              textareaHight={px(90)}
              style={{
                height: px(130),
                paddingTop: px(4),
                paddingLeft: px(18),
                paddingRight: px(18),
              }}
              value={currentFields.body || defaultFields().body || ''}
              onChange={body =>
                setCurrentFields({ ...currentFields, body: capitalizeFirstLetter(body) })
              }
            />
          </div>
        </div>
        <div
          style={{
            paddingLeft: px(15),
            paddingRight: px(15),
            paddingTop: px(17),
          }}
        >
          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
            className="font-normal"
          >
            Срок действия
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              paddingBottom: px(10),
            }}
            className="font-normal"
          >
            Активность новости не более 30 дней
          </p>
          <PeriodDatepicker
            time={currentFields?.startTime || defaultFields().startTime}
            endTime={currentFields?.endTime || defaultFields().endTime}
            startDate={currentFields?.startDate || defaultFields().startDate}
            endDate={currentFields?.endDate || defaultFields().endDate}
            datePickerArray={[
              {
                singleItem: true,
                title: 'Время',
                data: timeDate,
                pickerValue: currentFields?.startTime || defaultFields().startTime,
                setPickerValue: startTime => setCurrentFields({ ...currentFields, startTime }),
                date: undefined,
              },
              {
                singleItem: false,
                title: 'Календарь. Период',
                data: {
                  ...selectionsDate,
                  day: selectionsDate?.day?.slice(
                    0,
                    1 +
                      getDaysInMonth(
                        (currentFields?.startDate || defaultFields().startDate)?.year,
                        getMonth((currentFields?.startDate || defaultFields().startDate)?.month),
                      ),
                  ),
                },
                pickerValue: currentFields?.startDate || defaultFields().startDate,
                setPickerValue: startDate => setCurrentFields({ ...currentFields, startDate }),
                date: undefined,
              },
              {
                singleItem: true,
                title: 'Время',
                data: timeDate,
                pickerValue: currentFields?.endTime || defaultFields().endTime,
                setPickerValue: endTime => setCurrentFields({ ...currentFields, endTime }),
              },
              {
                singleItem: false,
                title: 'Календарь. Период',
                data: {
                  ...selectionsDate,
                  day: selectionsDate?.day?.slice(
                    0,
                    1 +
                      getDaysInMonth(
                        (currentFields?.endDate || defaultFields().endDate)?.year,
                        getMonth((currentFields?.endDate || defaultFields().endDate)?.month),
                      ),
                  ),
                },
                pickerValue: currentFields?.endDate || defaultFields().endDate,
                setPickerValue: endDate => setCurrentFields({ ...currentFields, endDate }),
              },
            ]}
            openPicker={openPicker}
            gap={14}
            show={showPicker}
            setShow={setShowPicker}
            id={pickerId}
            paddingBottom={13}
          />
          <Button
            className="w-full cursor-pointer border-[1px] border-solid border-[#dddddd]"
            marginTop={35}
            height={60}
            borderRadius={0}
            onClick={handleSave}
            disabled={checkForDisabled()}
          >
            Опубликовать
          </Button>
          <div
            style={{
              marginTop: px(20),
              paddingBottom: px(80),
            }}
            className="flex justify-between"
          >
            <div
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="cursor-pointer font-normal text-[#0A84FE]"
              onClick={() => {
                setCurrentFields({
                  ...currentFields,
                  startTime: defaultFields().startTime,
                  endTime: defaultFields().endTime,
                  startDate: defaultFields().startDate,
                  endDate: defaultFields().endDate,
                });
              }}
              onKeyDown={() => {}}
            >
              Продлить
            </div>
            <div
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal text-[#FA0303]"
              onClick={() => {
                del();
              }}
              onKeyDown={() => {
                del();
              }}
            >
              Удалить новость
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
