import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/allow-group';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_GROUP = 'allow/getGroup';

export const getGroup = createAsyncThunk(
  GET_GROUP,
  createRequest({
    type: GET_GROUP,
    loader: data => authApi.getGroup(data),
  }),
);
