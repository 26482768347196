import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_CARDS = 'card/getCards';

export const getCards = createAsyncThunk(
  GET_CARDS,
  createRequest({
    type: GET_CARDS,
    loader: data => authApi.getCards(data),
  }),
);
