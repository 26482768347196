import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/document';

export const ADD_DOCUMENT = 'document/addDocument';

export const addDocument = createAsyncThunk(
    ADD_DOCUMENT,
    createRequest({
        type: ADD_DOCUMENT,
        loader: (data) => authApi.addDocument(data),
    }),
);