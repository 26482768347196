import React from 'react';

import { px } from '../constants/Px';
import { Icon } from './Icon';

export const ItemWithIcon = ({ arrayLinks, title, marginTop, ...properties }) => (
  <>
    {title && (
      <p
        style={{
          fontSize: px(32),
          lineHeight: px(32),
          paddingTop: px(marginTop || 17),
          paddingBottom: px(25),
        }}
        className="line-clamp-2 font-light  text-[#000000]"
      >
        {title}
      </p>
    )}
    <div
      style={{
        gap: px(24),
      }}
      className="flex flex-col"
    >
      {arrayLinks?.map(item => (
        <div key={item.id} className="flex  items-center">
          <div
            style={{
              width: px(48),
            }}
          >
            <Icon
              type={item?.iconType}
              viewBox={item?.viewBox}
              width={item?.width}
              height={item?.height}
            />
          </div>
          <div
            style={{
              width: px(181),
            }}
            className="flex flex-col"
          >
            {item.title && (
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                {item.title}
              </p>
            )}
            {item.subTitle && (
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                {item.subTitle}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  </>
);

export default ItemWithIcon;
