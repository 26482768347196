import React from 'react';
import { Link } from 'react-router-dom';

import ad7Image from '../assets/images/ads/7.png';

export const SearchInputResultsNoItem = () => (
  <div className="search-input-results">
    <p className="no-items">Ничего не найдено</p>
    <section className="home__ad home__ad--2">
      <div className="container ">
        <Link to="/" target="_blank">
          <img src={ad7Image} className="home__ad-image" alt="home-ad-2" />
        </Link>
      </div>
    </section>
  </div>
);

export default SearchInputResultsNoItem;
