import React, { useState } from 'react';

import { selectionsDate } from '../constants/Component';
import { px } from '../constants/Px';
import { useProfile } from '../new-architecture/profile/hooks';
// eslint-disable-next-line import/no-cycle
import { AccessSettingsAll, Sector } from '.';

export const AccessSettings = ({ data, sectors }) => {
  const date = new Date();
  const hour = date?.getHours();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  const {
    data: { allowanceGroup },
    setData: setProfile,
    updateFunction: updateProfile,
    initialState: { allowanceGroup: defaultAllowanceGroup },
  } = useProfile();

  const setAllownaceGroup = fields =>
    setProfile({
      allowanceGroup: {
        ...allowanceGroup,
        ...fields,
      },
    });

  const [hide, setHide] = useState(false);

  const [isPeriod, setIsPeriod] = useState(data?.isPrefDeparture);
  const [periodValue, setPeriodValue] = useState({
    period: {
      period: data?.prefDeparture || '1 час',
    },
  });

  const getMonth = month =>
    month === '01'
      ? 'Январь'
      : month === '02'
        ? 'Февраль'
        : month === '03'
          ? 'Март'
          : month === '04'
            ? 'Апрель'
            : month === '05'
              ? 'Май'
              : month === '06'
                ? 'Июнь'
                : month === '07'
                  ? 'Июль'
                  : month === '08'
                    ? 'Август'
                    : month === '09'
                      ? 'Сентябрь'
                      : month === '10'
                        ? 'Октябрь'
                        : month === '11'
                          ? 'Ноябрь'
                          : 'Декабрь';

  /* const [isOpen, setIsOpen] = useState(data?.isOpen);
  const [time, setTime] = useState({
    hour: data?.openStart ? data?.openStart.slice(11, 16) : `${hour}:00` || '1',
  });
  const [startDate, setStartDate] = useState({
    day: data?.openStart ? data?.openStart.slice(8, 10) : `${currentDay}` || '1',
    month: data?.openStart
      ? getMonth(data?.openStart.slice(5, 7))
      : selectionsDate?.month[currentMonth] || 'Январь',
    year: data?.openStart ? data?.openStart.slice(0, 4) : `${currentYear}` || '2024',
  });

  const [endTime, setEndTime] = useState({
    hour: data?.openEnd ? data?.openEnd.slice(11, 16) : `${hour}:00` || '1',
  });
  const [endDate, setEndDate] = useState({
    day: data?.openEnd ? data?.openEnd.slice(8, 10) : `${currentDay}` || '1',
    month: data?.openEnd
      ? getMonth(data?.openEnd.slice(5, 7))
      : selectionsDate?.month[currentMonth] || 'Январь',
    year: data?.openEnd ? data?.openEnd.slice(0, 4) : `${currentYear}` || '2024',
  }); */

  /* const [isBlock, setIsBlock] = useState(data?.isBlock);
  const [timeBlock, setTimeBlock] = useState({
    hour: data?.blockStart ? data?.blockStart.slice(11, 16) : `${hour}:00` || '1',
  });
  const [startDateBlock, setStartDateBlock] = useState({
    day: data?.blockStart ? data?.blockStart.slice(8, 10) : `${currentDay}` || '1',
    month: data?.blockStart
      ? getMonth(data?.blockStart.slice(5, 7))
      : selectionsDate?.month[currentMonth] || 'Январь',
    year: data?.blockStart ? data?.blockStart.slice(0, 4) : `${currentYear}` || '2024',
  });

  const [endTimeBlock, setEndTimeBlock] = useState({
    hour: data?.blockEnd ? data?.blockEnd.slice(11, 16) : `${hour}:00` || '1',
  });
  const [endDateBlock, setEndDateBlock] = useState({
    day: data?.blockEnd ? data?.blockEnd.slice(8, 10) : `${currentDay}` || '1',
    month: data?.blockEnd
      ? getMonth(data?.blockEnd.slice(5, 7))
      : selectionsDate?.month[currentMonth] || 'Январь',
    year: data?.blockEnd ? data?.blockEnd.slice(0, 4) : `${currentYear}` || '2024',
  }); */

  return (
    <section className="container">
      <div>
        <p style={{ fontSize: px(32), lineHeight: px(32), marginBottom: px(10) }}>
          {data?.groupNumber}
        </p>
      </div>
      <div className="flex flex-wrap" style={{ gap: px(10), marginBottom: px(19) }}>
        {sectors?.map(item => (
          <Sector
            key={item?.id}
            sectorNumber={item?.number}
            discount={item?.discount}
            type={item}
            price={item?.price}
          />
        ))}
      </div>
      <div>
        <div className="flex items-center justify-between">
          <p style={{ fontSize: px(32), lineHeight: px(32) }}>Допуск</p>
          <div>
            <button
              type="button"
              className="font-normal"
              onClick={() => {
                /* setHide(!hide); */
                setAllownaceGroup(data);
              }}
              style={{
                fontSize: px(15),
                lineHeight: px(20),
                color: '#0A84FE',
                cursor: 'pointer',
              }}
            >
              {allowanceGroup.id === data.id ? 'скрыть' : 'показать'}
            </button>
          </div>
        </div>
        <p
          style={{ width: px(260), fontSize: px(15), lineHeight: px(20), marginBottom: px(19) }}
          className="font-normal"
        >
          Выберите периоды. Установите права и условия:
        </p>

        {allowanceGroup.id === data.id && <AccessSettingsAll />}
      </div>
    </section>
  );
};

export default AccessSettings;
