import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/recommendation';

export const ADD_RECOMMENDATION= 'recommendation/addRecommendation';

export const addRecommendation = createAsyncThunk(
    ADD_RECOMMENDATION,
    createRequest({
        type: ADD_RECOMMENDATION,
        loader: (data) => authApi.addRecommendation(data),
    }),
);