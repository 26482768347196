import React, { useEffect, useState } from 'react';

import { cardHolderFind, getCardHolder } from '../api/card-holder';
import { px } from '../constants/Px';
import { ClubCardViewModal } from '../new-architecture/shared/modal';
import { TypesIcon } from '../types/TypesIcon';
import { Button } from './button';
import { CustomAlert } from './custom-alert';
import { Icon } from './Icon';
import { Modal } from './portals';
import { ProfileClubCardsViewModal } from './profile-club-cards-view-modal';
import { StyledInput } from './styled-input';

export const SearchCardModal = ({ isOpen, onClose, buttonTitle, profileId }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [searchNumberCardValue, setSearchNumberCardValue] = useState('');
  const [searchPhoneNumberValue, setSearchPhoneNumberValue] = useState('');
  const [searchProfileIdValue, setSearchProfileIdValue] = useState('');
  const [receivedСardHolder, setReceivedCardHolder] = useState([]);
  const [receivedProfileId, setReceivedProfileId] = useState([]);
  const [showCardTypeView, setShowCardTypeView] = useState(false);
  const [showProfileClubCardsView, setShowProfileClubCardsView] = useState(false);

  const onClickButtonGetProfileCardHolders = async () => {
    try {
      if (searchPhoneNumberValue === '' && searchProfileIdValue === '') {
        setShowAlert(true);
      } else {
        const formattedPhoneNumber = searchPhoneNumberValue.replaceAll('+', '');
        const response = await cardHolderFind({
          profileId: searchProfileIdValue,
          phone: formattedPhoneNumber,
        });

        if (response && response.data) {
          setReceivedProfileId(response.data);
          setShowProfileClubCardsView(true);
        } else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error fetching card holder:', error);
    }
  };

  const onClickButtonGetCardHolder = async () => {
    try {
      if (searchNumberCardValue === '') {
        setShowAlert(true);
      } else {
        const response = await getCardHolder({ holderId: searchNumberCardValue });
        if (response && response.data) {
          setReceivedCardHolder(response.data);
          setShowCardTypeView(true);
        } else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error fetching card holder:', error);
    }
  };

  const handleClose = () => {
    onClose();
    setSearchNumberCardValue('');
  };

  return (
    <>
      <Modal title="Клубные карты" isOpen={isOpen} onClose={handleClose} isLine>
        <div
          style={{
            padding: `${px(0)} ${px(15)} ${px(49)} ${px(15)}`,
          }}
        >
          <div
            style={{
              paddingTop: px(19),
              paddingBottom: px(18),
            }}
            className="flex items-center justify-center"
          >
            <Icon
              type={TypesIcon.BLACKSEARCH}
              viewBox="0 0 22.357 22.357"
              width={px(22.357)}
              height={px(22.357)}
              fill="#0a84fe"
            />
            <p
              style={{
                fontSize: px(32),
                lineHeight: px(43),
                paddingLeft: px(10),
              }}
              className="font-light"
            >
              Поиск
            </p>
          </div>
          <div
            style={{
              gap: px(10),
            }}
            className="flex flex-col"
          >
            <StyledInput
              placeholder={profileId ? 'Номер карты' : 'Номер телефона'}
              onChange={e => {
                if (profileId) {
                  setSearchNumberCardValue(e.target.value);
                } else {
                  setSearchPhoneNumberValue(e.target.value);
                  setSearchProfileIdValue('');
                }
              }}
              value={profileId ? searchNumberCardValue : searchPhoneNumberValue}
            />

            <StyledInput
              disabled={profileId}
              textOpacity={profileId ? '#000000' : '#ffffff'}
              placeholder={profileId || 'ID кабинета'}
              onChange={e => {
                if (!profileId) {
                  setSearchPhoneNumberValue('');
                  setSearchProfileIdValue(e.target.value);
                }
              }}
              value={profileId || searchProfileIdValue}
            />
          </div>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              paddingTop: px(10),
            }}
            className="font-normal "
          >
            {profileId
              ? 'Поиск Клубной карты по номеру карты'
              : 'Поиск Клубной карты по номеру телефона или ID кабинета владельца'}
          </p>

          {buttonTitle && (
            <Button
              className="w-full border-[1px] border-solid border-[#dddddd]"
              marginTop={43}
              height={60}
              borderRadius={0}
              onClick={() => {
                if (profileId) {
                  onClickButtonGetCardHolder();
                } else {
                  onClickButtonGetProfileCardHolders();
                  onClose();
                }
              }}
            >
              Найти
            </Button>
          )}
        </div>
        {showAlert && (
          <CustomAlert
            onClickButtonSubmit={() => {
              setShowAlert(false);
            }}
            title={`${
              searchNumberCardValue === ''
                ? 'Введите номер карты'
                : searchPhoneNumberValue === '' &&
                    searchProfileIdValue === '' &&
                    setSearchPhoneNumberValue === ''
                  ? 'Введите Номер телефона или ID Кабинета'
                  : 'Поиск не дал результатов'
            }`}
            single
            buttonRightText="Ок"
          />
        )}
        {showCardTypeView && (
          <ClubCardViewModal
            showCardTypeView={showCardTypeView}
            setShowCardTypeView={setShowCardTypeView}
            data={receivedСardHolder}
          />
        )}
      </Modal>
      {showProfileClubCardsView && (
        <ProfileClubCardsViewModal
          showProfileClubCardsView={showProfileClubCardsView}
          setShowProfileClubCardsView={setShowProfileClubCardsView}
          data={receivedProfileId}
        />
      )}
    </>
  );
};

export default SearchCardModal;
