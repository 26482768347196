import { createSelector } from '@reduxjs/toolkit';

export const selectRoot = ({ notification }) => notification;

export const notificationSelector = createSelector(
  selectRoot,
  notification => notification?.notification,
);
export const notificationEventsSelector = createSelector(
  selectRoot,
  notification => notification?.events,
);
export const eventNameSelector = createSelector(
  selectRoot,
  notification => notification?.eventName,
);
export const eventDateSelector = createSelector(
  selectRoot,
  notification => notification?.eventDate,
);
export const offersSelector = createSelector(selectRoot, notification => notification?.offers);
