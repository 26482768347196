import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Button, FeedbackStar, Main } from '../../components';
import { px } from '../../constants/Px';
import { useDispatch } from 'react-redux';
import { Icon } from '../../components/Icon';
import { addObjectFeedback } from '../../actions/event-review';
import {
  createFeedbackPageCriterion,
  createFeedbackPageFeedbackButtonIcons,
} from '../../constants/Component';

export const ObjectCreateFeedbackPage = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [estimate, setEstimate] = useState({
    guaranteesEstimate: 0,
    cleaningEstimate: 0,
    silenceEstimate: 0,
    rulesEstimate: 0,
    paymentEstimate: 0,
  });

  const [feedback, setFeedback] = useState({ goodFeedback: '', badFeedback: '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const arr = [1, 2, 3, 4, 5];

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(
        addObjectFeedback({
          guarantees: estimate.guaranteesEstimate,
          cleanliness: estimate.cleaningEstimate,
          silence: estimate.silenceEstimate,
          rules: estimate.rulesEstimate,
          payment: estimate.paymentEstimate,
          positiveText: feedback.goodFeedback,
          negativeText: feedback.badFeedback,
          eventId: 518,
        }),
      );
    } catch (error) {

    }
  };

  return (
    <Main
      feedback
      title='Отзыв'
      fontSize={32}
      lineHeight={43}
      light
      navigatePatch='/about'
      withUserId
    >
      <div className='flex flex-column' style={{ gap: px(10), marginTop: px(36) }}>
        {createFeedbackPageCriterion.map((item) => (
          <div className='flex flex-row justify-between items-center' style={{ height: px(31) }}>
            <p className='font-normal' style={{ fontSize: px(15), lineHeight: px(20) }}>
              {item.title}
            </p>
            <div
              className='flex flex-row justify-center items-center'
              style={{ marginTop: px(18), marginBottom: px(18), gap: px(10) }}
            >
              {arr.map((e) => (
                <FeedbackStar
                  starId={e}
                  objectKey={item.objectKey}
                  estimate={estimate}
                  setEstimate={setEstimate}
                  createObjectFeedback
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className='flex flex-column '>
        {createFeedbackPageFeedbackButtonIcons.map((item) => (
          <>
            <div
              className='flex flex-row items-center'
              style={{ gap: px(17), marginBottom: px(10), marginTop: px(20) }}
            >
              <div
                className='  flex justify-center items-center rounded-[50%] border-solid border-[1px] border-black '
                style={{
                  height: px(31),
                  width: px(31),
                }}
              >
                <Icon
                  type={item.iconType}
                  viewBox={item.viewBox}
                  width={item.iconWidth}
                  height={item.iconHeight}
                />
              </div>
              <p className='font-normal' style={{ fontSize: px(20), lineHeight: px(26) }}>
                {item.title}
              </p>
            </div>
            <textarea
              className='outline-none border-solid rounded-[2%] border-[1px] border-black border-opacity-30 w-full resize-none font-normal placeholder:text-black placeholder:opacity-50'
              style={{
                height: px(114),
                fontSize: px(20),
                lineHeight: px(26),
                paddingLeft: px(20),
              }}
              placeholder='Ваш отзыв...'
              value={item.id === 1 ? feedback.goodFeedback : feedback.badFeedback}
              onChange={(e) =>
                item.id === 1
                  ? setFeedback({ ...feedback, goodFeedback: e.target.value })
                  : setFeedback({ ...feedback, badFeedback: e.target.value })
              }
            />
          </>
        ))}
      </div>

      <Button
        className='w-full'
        borderRadius={5}
        marginTop={10}
        fontSize={24}
        lineHeight={32}
        height={60}
        disabled={!Object.values(estimate).every((num) => num > 0)}
        onClick={onSubmit}
      >
        Отправить
      </Button>
    </Main>
  );
};
