import React from 'react';
import { px } from '../constants/Px';
import { Icon } from './Icon';


 export const SettingProfileTitles = ({gap,bottom,top,iconType,iconViewBox,iconWidth, iconHeight,title}) => {
  return (
    <div
      className='flex min-w-full items-center'
      style={{
        gap: gap,
        paddingBottom: bottom,
        paddingTop:top
      }}
    >
      <Icon
        type={iconType}
        viewBox={iconViewBox}
        width={iconWidth}
        height={iconHeight}
      />
      <p
        className='font-normal'
        style={{
          fontSize: px(20),
          lineHeight: px(26),
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default SettingProfileTitles;