export const routes = {
  login: '/login',
  auth: '/auth',
  blocked: '/blocked',
  profile: '/profile',
  selectProfile: '/select-profile',
  registration: '/registration',
  registrationObject: '/registration-object',
  faceId: '/face-id',
  map: '/map',
  slider: '/slider',
  userAccount: '/user-account',
  preferences: '/preferences',
  itemFilter: '/item-filter',
  openSoon: '/open-soon',
  filter: '/filter',
  sms: '/sms',
  confidentialInfo: 'confidential-info',
  verifyFaceId: '/verify-face-id',
  deleteAccountPage: '/delete-account',
  history: '/history',
  notification: '/notification',
  settingsProfile: '/settings-profile',
  changePhone: '/change-phone',
  addCode: '/add-code',
  notificationDevices: '/notification-devices',
  notificationDevicesList: '/notification-devices-list',
  notificationPushCodes: '/notification-push-codes',
  notificationSendingTime: '/notification-sending-time',
  addEmail: '/add-email',
  appeals: '/appeals',
  chatWithSupport: '/chat-with-support',
  addCard: '/add-card',
  finance: '/finance',
  financeMain: '/finance/main',
  mobile: '/mobile',
  test: '/test',
  accountControl: '/account-control',
  blockTheAccount: '/block-account',
  holiday: '/holiday',
  clubCards: '/club-cards',
  sort: '/sort',
  cardTypes: 'card-types',
  cardsTypesProfile: 'card-types-profile',
  presentCard: 'present-card',
  addLoyalCard: '/add-loyal-card',
  transfer: '/transfer',
  favourites: '/favourites',
  statistic: '/statistic',
  cash: '/cash',
  about: '/about',
  notifications: '/notifications',
  object: '/object',
  addAdministrator: '/add-administrator',
  feedback: '/feedback',
  objectCreateFeedback: '/object-create-feedback',
  objectFeedback: '/object-feedback',
  demand: '/demand',
  distribution: '/distribution',
  notificationExtractStatistics: '/notification/extract/statistics',
  notificationExtractBooking: '/notification/extract/booking',
  notificationExtractOffer: '/notification/extract/offer',
  booking: '/booking',
  objectDescription: '/object-description',
  objectNews: '/news',
  shema: '/schemas',
  sector: '/sector',
  offerMenu: '/offer-menu',
  selection: '/selection',
  userObject: '/user-object',
  userCompetition: '/user-competition',
  userGuide: '/user-guide',
  deleteCard: '/delete-card',
  privacy: '/privacy',
  cookies: '/cookies',
};
