import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/notification';
import { createRequest } from '../../../helpers/api/createRequest';

export const ADD_NOTIFICATION = 'notification/addNotification';

export const addNotification = createAsyncThunk(
  ADD_NOTIFICATION,
  createRequest({
    type: ADD_NOTIFICATION,
    loader: data => authApi.addNotification(data),
  }),
);
