import { createReducer } from '@reduxjs/toolkit';

import {
  addActiveObjectId,
  addActiveType,
  clearActiveId,
  clearBiometric,
} from '../../actions/auth';
import { clearAuth } from '../../actions/auth/clear-auth';
import { clearCode } from '../../actions/auth/clear-code';
import {
  authCodeDelete,
  authCodeUpdate,
  loginBiometric,
  loginCode,
  loginEnd,
  logout,
  signupBiometric,
  verify,
} from '../../actions/auth/thunks';

export const auth = createReducer({}, builder => {
  builder.addCase(loginEnd.fulfilled, (state, { payload }) => ({
    ...state,
    auth: payload?.status === 201,
    isBiometric: payload?.data?.isBiometric,
    isCode: payload?.data?.isCode,
  }));
  builder.addCase(loginBiometric.fulfilled, (state, { payload }) => ({
    ...state,
    auth: true,
  }));
  builder.addCase(loginCode.fulfilled, (state, { payload }) => ({
    ...state,
    auth: true,
  }));
  builder.addCase(authCodeUpdate.fulfilled, (state, { payload }) => ({
    ...state,
    isCode: payload?.data?.isCode,
  }));
  builder.addCase(signupBiometric.fulfilled, (state, { payload }) => ({
    ...state,
    isBiometric: payload?.data?.isBiometric,
  }));
  builder.addCase(authCodeDelete.fulfilled, (state, { payload }) => ({
    ...state,
    isCode: undefined,
  }));
  builder.addCase(verify.fulfilled, (state, { payload }) => ({
    ...state,
    auth: payload?.status === 201,
  }));
  builder.addCase(logout.fulfilled, (state, { payload }) => ({
    ...state,
    auth: false,
  }));
  builder.addCase(clearAuth, state => ({
    ...state,
    auth: false,
  }));
  builder.addCase(clearBiometric, state => ({
    ...state,
    isBiometric: undefined,
  }));
  builder.addCase(clearCode, state => ({
    ...state,
    isCode: undefined,
  }));
  builder.addCase(addActiveObjectId, (state, { payload }) => ({
    ...state,
    eventActiveId: payload,
  }));
  builder.addCase(addActiveType, (state, { payload }) => ({
    ...state,
    eventActiveType: payload,
  }));
  builder.addCase(clearActiveId, state => ({
    ...state,
    eventActiveId: undefined,
  }));
});
