import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';
import { mapSectorToFields } from '../../../../helpers/sector.helpers';
import { Sector } from '../../../fishing-menu/components';
import { AccessSettings } from '../access-settings';

export const AccessGroup = ({ data, sectors, type }) => {
  const {
    data: { allowanceGroup, selectedSector, locations, selectedLocationId },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const setAllownaceGroup = fields =>
    setProfile({
      allowanceGroup: {
        ...allowanceGroup,
        ...fields,
      },
    });

  const setSelectedSector = item =>
    item === selectedSector
      ? setProfile({ selectedSector: undefined })
      : setProfile({ selectedSector: item });

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (allowanceGroup.id !== data.id) setHide(false);
  }, [allowanceGroup]);

  return (
    <section className="container">
      <div>
        <p style={{ fontSize: px(32), lineHeight: px(32), marginBottom: px(19) }}>
          {data?.groupNumber}
        </p>
      </div>
      <div className="flex flex-wrap" style={{ gap: px(10), marginBottom: px(25) }}>
        {sectors?.map(item => (
          <Sector
            onClick={() => {
              setSelectedSector(item.id);
            }}
            sector={mapSectorToFields(item, locations, selectedLocationId)}
            key={item?.id}
            sectorNumber={item?.number}
            discount={item?.discount}
            type={item}
            price={item?.price}
            active={selectedSector === item?.id}
          />
        ))}
      </div>
      <div>
        <div className="flex items-center justify-between">
          <p style={{ fontSize: px(32), lineHeight: px(32) }}>Допуск</p>
          <div>
            <button
              type="button"
              className="font-normal"
              onClick={async () => {
                await updateProfile();
                setHide(!hide);
                setAllownaceGroup(data);
              }}
              style={{
                fontSize: px(15),
                lineHeight: px(20),
                color: '#0A84FE',
                cursor: 'pointer',
              }}
            >
              {allowanceGroup.id === data.id && hide ? 'скрыть' : 'показать'}
            </button>
          </div>
        </div>
        <p
          style={{
            width: px(260),
            fontSize: px(15),
            lineHeight: px(20),
            marginBottom: px(19),
            paddingTop: px(3),
          }}
          className="font-normal"
        >
          Выберите периоды. Установите права и условия:
        </p>

        {allowanceGroup.id === data.id && hide && (
          <AccessSettings
            type={type}
            free={sectors[0]?.place[0]?.name === 'free' || sectors[0]?.place[1]?.name === 'free'}
            chosenNumber={
              allowanceGroup?.placeNumber ||
              sectors[0]?.place[0]?.number ||
              sectors[0]?.place[1]?.number
            }
            number={sectors[0]?.place[0]?.number || sectors[0]?.place[1]?.number}
          />
        )}
      </div>
    </section>
  );
};

export default AccessGroup;
