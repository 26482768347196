import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const UPDATE_COMPETITION_EVENT = 'competition/updateCompetitionEvent';

export const updateCompetitionEvent = createAsyncThunk(
  UPDATE_COMPETITION_EVENT,
  createRequest({
    type: UPDATE_COMPETITION_EVENT,
    loader: data => authApi.updateCompetitionEvent(data),
  }),
);
