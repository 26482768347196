export const getPlasholderName = (activeType, event, guide, competition, profile) => {
  if ((activeType === 'object' || activeType === 'administeredObject') && event?.name) {
    return event.name?.charAt(0)?.toUpperCase();
  }

  if (
    (activeType === 'competition' || activeType === 'administeredCompetition') &&
    competition?.name
  ) {
    return competition.name?.charAt(0)?.toUpperCase();
  }

  if ((activeType === 'guide' || activeType === 'administeredGuide') && guide?.name) {
    return guide.name?.charAt(0)?.toUpperCase();
  }

  if ((!activeType || activeType === 'administeredProfile') && profile?.name) {
    return profile.name?.charAt(0)?.toUpperCase();
  }
  return '';
};

export const getImageUrl = (activeType, event, guide, competition, profile) => {
  if (activeType === 'object' || activeType === 'administeredObject') {
    const logo = event?.EventAttachment?.find(item => item.type === 'LOGO');
    if (logo) {
      return `${process.env.REACT_APP_STATIC_URL}${logo.imageUrl}`;
    }
  }
  if (activeType === 'competition' || activeType === 'administeredCompetition') {
    const competitionLogo = competition?.attachments?.find(item => item.type === 'LOGO');
    if (competitionLogo) {
      return `${process.env.REACT_APP_STATIC_URL}${competitionLogo.url}`;
    }
  }
  if (activeType === 'guide' || activeType === 'administeredGuide') {
    const guideLogo = guide?.attachments?.find(item => item.type === 'LOGO');
    if (guideLogo) {
      return `${process.env.REACT_APP_STATIC_URL}${guideLogo.url}`;
    }
  }
  if (!activeType || activeType === 'administeredProfile') {
    const avatar = profile?.avatar?.url;
    if (avatar) {
      return `${process.env.REACT_APP_STATIC_URL}${avatar}`;
    }
  }

  return null;
};
