import React from 'react';

import { Modal } from '../../../shared';
import { px } from '../../../utils';

export const GetDirectionsModal = ({ show, onClose, title, directions, contacts }) => (
  <Modal show={show} onClose={onClose} title={title}>
    <div style={{ marginBottom: px(20) }} className="relative w-full">
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          paddingLeft: px(15),
          paddingRight: px(15),
          fontWeight: 400,
          marginBottom: px(39),
          marginTop: px(20),
        }}
        className="line-clamp-2 text-center"
      >
        {`${contacts?.country ? `${contacts?.country}, ` : ''}${
          contacts?.region ? `${contacts?.region} регион, ` : ''
        }
        ${contacts?.district ? `${contacts?.district}, ` : ''}
        ${contacts?.town ? `${contacts?.town}, ` : ''}
        ${contacts?.street || ''}
        `}
      </p>
      <div className="flex justify-center" style={{ marginBottom: px(5), gap: px(13) }}>
        {directions.map((item, index) => (
          <div key={item.id} style={{ width: px(58), height: px(58) }}>
            <a
              href={
                index === 3
                  ? `http://maps.google.com/maps?daddr=${contacts?.coordinates[0]},${contacts?.coordinates[1]}&amp;ll=`
                  : index === 2 || index === 1
                    ? `https://yandex.ru/maps/?rtext=~${contacts?.coordinates[0]},${contacts?.coordinates[1]}&rtt=mt`
                    : `http://maps.apple.com/?q=${contacts?.coordinates[0]},${contacts?.coordinates[1]}`
              }
            >
              <img src={item.imageSrc} alt="apple-maps" className="h-full w-full object-cover" />
            </a>
            {/* <img src={item.imageSrc} alt="apple-maps" className="h-full w-full object-cover" /> */}
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        {directions.map(item => (
          <p
            key={item.id}
            className="text-center font-normal"
            style={{
              marginRight:
                item.label === 'Apple Maps'
                  ? px(4)
                  : item.label === 'Яндекс Навигатор'
                    ? px(2)
                    : item.label === 'Яндекс Карты'
                      ? px(14)
                      : item.label === 'Google Maps'
                        ? px(0)
                        : '',
              width:
                item.label === 'Apple Maps'
                  ? px(55)
                  : item.label === 'Яндекс Навигатор'
                    ? px(76)
                    : item.label === 'Яндекс Карты'
                      ? px(61)
                      : item.label === 'Google Maps'
                        ? px(55)
                        : '',
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            {item.label}
          </p>
        ))}
      </div>
    </div>
  </Modal>
);
export default GetDirectionsModal;
