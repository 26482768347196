/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const UPDATE_GUIDE_ATTACHMENT = 'guide/updateGuideAttachment';

export const updateGuideAttachment = createAsyncThunk(
  UPDATE_GUIDE_ATTACHMENT,
  createRequest({
    type: UPDATE_GUIDE_ATTACHMENT,
    loader: data => authApi.updateGuideAttachment(data),
  }),
);
