export const isObjectOrCompetitionType = activeType =>
  activeType === 'object' ||
  activeType === 'competition' ||
  activeType === 'guide' ||
  activeType === 'administeredCompetition' ||
  activeType === 'administeredGuide' ||
  activeType === 'administeredObject';

export const getAdditionalLogo = (activeType, eventSelectorData, competition) => {
  if (activeType === 'administeredObject') {
    const logoAttachment = eventSelectorData?.EventAttachment?.find(
      attachment => attachment.type === 'LOGO',
    );

    return logoAttachment?.imageUrl;
  }
  if (
    activeType === 'competition' ||
    activeType === 'guide' ||
    activeType === 'administeredCompetition' ||
    activeType === 'administeredGuide'
  ) {
    const logoAttachment = competition?.attachments?.find(attachment => attachment.type === 'LOGO');

    return logoAttachment?.url;
  }

  return null;
};
