import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section-promotion';
import { createRequest } from '../../../helpers/api/createRequest';

export const CHANGE_PROMOTION_GROUP = 'promotion/changePromotionGroup';

export const changePromotionGroup = createAsyncThunk(
  CHANGE_PROMOTION_GROUP,
  createRequest({
    type: CHANGE_PROMOTION_GROUP,
    loader: data => authApi.changePromotionGroup(data),
  }),
);
