import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { cardHolderInvite } from '../../../../actions/card-holder/thunks';
import { CustomAlert, ObjectDescriptionTextarea, TruncatedText } from '../../../../components';
import { FileUpload } from '../../../../components/file-upload';
import { Button, Modal } from '../../../shared';
import { capitalizeFirstLetter, px } from '../../../utils';

export const RequestClubCardModal = ({
  show,
  onClose,
  setShowAlert,
  title,
  data: { cardId: initialCardId, profileId: initialProfileId },
}) => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(false);
  const [values, setValues] = useState({
    message: '',
    plasticCardId: '',
    cardId: initialCardId || '',
    profileId: initialProfileId || '',
  });
  useEffect(() => {
    const isDisabled =
      !values.message || !values.plasticCardId || !values.cardId || !values.profileId;
    setDisabledButtonSubmit(isDisabled);
  }, [values]);

  useEffect(() => {
    setValues(prevValues => ({
      ...prevValues,
      selectedFiles,
      cardId: initialCardId,
      profileId: initialProfileId,
    }));
  }, [selectedFiles, initialCardId, initialProfileId]);
  const onClickButtonSubmit = async () => {
    try {
      setDisabledButtonSubmit(true);
      const formData = new FormData();
      formData.append('profileId', values?.profileId || '');
      formData.append('cardId', values?.cardId || '');
      formData.append('message', values?.message || '');
      formData.append('openUntill', new Date().toISOString());
      formData.append('status', 'INVITED');
      formData.append('plasticNumber', values?.plasticCardId || '');
      // eslint-disable-next-line no-restricted-syntax
      for (const file of selectedFiles) {
        formData.append('files', file);
      }
      const { payload } = await dispatch(cardHolderInvite(formData));
      if (payload?.status === 201) {
        onClose();
        setShowAlert(true);
        setDisabledButtonSubmit(false);
      }
    } catch (error) {
      setDisabledButtonSubmit(false);
      console.error('Error during submission:', error);
    }
  };
  return (
    <Modal show={show} onClose={onClose} title={title}>
      <div
        style={{
          overflowY: 'auto',
          height: '100%',
          paddingBottom: px(80),
          backgroundColor: '#fff',
          borderTop: '1px solid #0000004D',
          paddingLeft: px(15),
          paddingRight: px(15),
          paddingTop: px(24),
        }}
      >
        <p
          style={{
            fontSize: px(32),
            lineHeight: px(32),
          }}
          className="font-light"
        >
          Виртуальная
        </p>
        <TruncatedText
          titleAlign="start"
          data={{
            textBefore: 'карта идентична пластиковой. Имеет те же условия.',
          }}
          style={{ paddingRight: px(23), marginBottom: px(16) }}
        />
        <ObjectDescriptionTextarea
          objectStyles={{
            height: 130,
            placeholder:
              'Введите текст. Например: У меня накопилось нужное кол-во посещений или я вхожу в категорию Льготник',
            paddingTop: 4,
            paddingLeft: 18,
            paddingRight: 18,
            textareaHeight: 122,
            textareaFontSize: 18,
            textareaLineHeight: 24,
          }}
          objectKey="applicationScope"
          value={values.message}
          setValue={(_, message) =>
            setValues({
              ...values,
              message: capitalizeFirstLetter(message),
            })
          }
        />
        <div
          className="flex w-full items-center justify-center"
          style={{ gap: px(10), paddingTop: px(10) }}
        >
          <input
            className="... h-full w-full truncate border-[1px] border-solid border-[#0000004D] font-[400] text-[#000000] placeholder-[#000000] outline-none focus:border-[#0A84FE]"
            placeholder="№ Пластик-карты"
            value={values.plasticCardId}
            onChange={e => {
              const inputValue = e.target.value.replaceAll(/\D/g, '').slice(0, 16);
              setValues({ ...values, plasticCardId: inputValue });
            }}
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingTop: px(5),
              paddingBottom: px(5),
              paddingLeft: px(10),
              paddingRight: px(10),
            }}
            type="text"
            maxLength={16}
          />

          <input
            className="... h-full w-full truncate border-[1px] border-solid border-[#000000] font-[400] text-[#000000] placeholder-[#000000] opacity-30 outline-none"
            placeholder={`ID: ${values?.profileId || ''}`}
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingTop: px(5),
              paddingBottom: px(5),
              paddingLeft: px(10),
              paddingRight: px(10),
            }}
            disabled
          />
        </div>
        <Button
          className="w-full border-[1px] border-solid border-[#dddddd]"
          marginTop={20}
          height={60}
          borderRadius={0}
          disabled={disabledButtonSubmit}
          onClick={onClickButtonSubmit}
        >
          Отправить
        </Button>
        <FileUpload
          uploadButton="Прикрепить JPG подтверждения"
          bordered
          uploadButtonStyle={{
            fontSize: px(15),
            lineHeight: px(20),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </div>
    </Modal>
  );
};

export default RequestClubCardModal;
