import { createReducer } from '@reduxjs/toolkit';

import {
  clearProfile,
  getProfile,
  getProfileById,
  profileAvatar,
  updateProfile,
} from '../../actions/profile';

export const profile = createReducer({}, builder => {
  builder.addCase(getProfile.fulfilled, (state, { payload }) => ({
    ...state,
    profile: { ...payload?.data },
  }));
  builder.addCase(getProfileById.fulfilled, (state, { payload }) => ({
    ...state,
    profileById: { ...payload?.data },
  }));
  builder.addCase(updateProfile.fulfilled, (state, { payload }) => ({
    ...state,
    profile: { ...payload?.data },
  }));
  builder.addCase(profileAvatar.fulfilled, (state, { payload }) => ({
    ...state,
    profileById: { ...payload?.data },
  }));
  builder.addCase(clearProfile, () => ({
    profile: {},
  }));
});
