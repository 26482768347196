/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable unicorn/no-useless-spread */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updatePromotion } from '../../../../../../../actions/section-promotion';
import { interestData } from '../../../../../../../constants/Component/date-picker';
import { Picker } from '../../../../../../shared';
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';
import { PromotionPeriod } from '../promotion-period';

export const PromotionInfo = ({ locationType, setPromotion }) => {
  const [showHelp, setShowHelp] = useState(false);
  const [showWithNorm, setShowWithNorm] = useState(false);
  const [showCost, setShowcost] = useState(false);
  const [showCaughtValue, setShowCaughtValue] = useState(false);
  const [showWithoutNorm, setShowWithoutNorm] = useState(false);
  const dispatch = useDispatch();

  const {
    data: { currentPromotionGroup },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const setCurrentPromotionGroup = fields =>
    setProfile({
      currentPromotionGroup: {
        ...currentPromotionGroup,
        ...fields,
      },
    });

  /* const setPromotionGroup = () =>
    setProfile({
      promotionGroup: promotionGroup.map(item =>
        item.id === currentPromotionGroup.id ? currentPromotionGroup : item,
      ),
    }); */

  const {
    id,
    type,
    periodStart,
    periodEnd,
    isCaught,
    caughtValue,
    isWithoutNorm,
    withoutNormValue,
    isWithNorm,
    withNormValue,
    isHelp,
    helpValue,
    cost,
    objectPeriodStart,
    objectperiodEnd,
    sectionGroupId,
  } = currentPromotionGroup || {};

  const tariffForFirstGuest = [
    {
      id: 1,
      title: 'Поймал - отпусти',
      isCheckbox: true,
      checkedValue: 'isCaught',
      checkValue: 'caughtValue',
      value: 'caughtValue',
      show: showCaughtValue,
      setShow: setShowCaughtValue,
    },
    {
      id: 2,
      title: 'Без нормы вылова',
      isCheckbox: true,
      checkedValue: 'isWithoutNorm',
      checkValue: 'withoutNormValue',
      value: 'withoutNormValue',
      show: showWithoutNorm,
      setShow: setShowWithoutNorm,
    },
    {
      id: 3,
      title: 'С нормой вылова',
      isCheckbox: true,
      checkedValue: 'isWithNorm',
      checkValue: 'withNormValue',
      value: 'withNormValue',
      show: showWithNorm,
      setShow: setShowWithNorm,
    },
    {
      id: 4,
      title: 'Помощник (1 чел.)',
      isCheckbox: true,
      checkedValue: 'isHelp',
      checkValue: 'helpValue',
      value: 'helpValue',
      show: showHelp,
      setShow: setShowHelp,
    },
  ];

  useEffect(() => {
    if (currentPromotionGroup)
      dispatch(
        updatePromotion({
          data: {
            id,
            type,
            periodStart,
            periodEnd,
            isCaught,
            caughtValue,
            isWithoutNorm,
            withoutNormValue,
            isWithNorm,
            withNormValue,
            isHelp,
            helpValue,
            cost,
            objectPeriodStart,
            objectperiodEnd,
            sectionGroupId,
          },
          id: currentPromotionGroup.id,
        }),
      );
  }, [currentPromotionGroup]);

  /* useEffect(() => {
    if (!cost) setCurrentPromotionGroup({ cost: '0%' });
    if (!caughtValue) setCurrentPromotionGroup({ caughtValue: '0%' });
    if (!withNormValue) setCurrentPromotionGroup({ withNormValue: '0%' });
    if (!withoutNormValue) setCurrentPromotionGroup({ withoutNormValue: '0%' });
    if (!helpValue) setCurrentPromotionGroup({ helpValue: '0%' });
  }, []); */

  useEffect(() => {
    setProfile({
      currentPromotionGroup: {
        ...currentPromotionGroup,
      },
    });
  }, []);

  /* useEffect(() => {
    setPromotionGroup();
  }, [currentPromotionGroup]); */

  useEffect(() => {
    if (setPromotion) {
      let promo = 0;
      if (isCaught && caughtValue && caughtValue !== '0 %') {
        if (+caughtValue.slice(0, -1) > promo) {
          promo = caughtValue.slice(0, -1);
        }
      }
      if (isHelp && helpValue && helpValue !== '0 %') {
        if (+helpValue.slice(0, -1) > promo) {
          promo = helpValue.slice(0, -1);
        }
      }
      if (isWithoutNorm && withoutNormValue && withoutNormValue !== '0 %') {
        if (+withoutNormValue.slice(0, -1) > promo) {
          promo = withoutNormValue.slice(0, -1);
        }
      }
      if (isWithNorm && withNormValue && withNormValue !== '0 %') {
        if (+withNormValue.slice(0, -1) > promo) {
          promo = withNormValue.slice(0, -1);
        }
      }
      if (cost && cost !== '0 %') {
        if (+cost.slice(0, -1) > promo) {
          promo = cost.slice(0, -1);
        }
      }

      setPromotion(promo);
      setTimeout(() => updateProfile(), 100);
    }
  }, [
    cost,
    helpValue,
    withoutNormValue,
    withNormValue,
    caughtValue,
    isCaught,
    isHelp,
    isWithNorm,
    isWithoutNorm,
  ]);

  return (
    <>
      <div style={{ marginBottom: px(20) }}>
        <div style={{ marginBottom: px(10) }}>
          <p style={{ fontSize: px(20), lineHeight: px(26) }} className="font-normal">
            Период
          </p>
          <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
            Указать время работы
          </p>
        </div>
        <PromotionPeriod start={periodStart} end={periodEnd} />
      </div>
      <div>
        {locationType === 'FISHING' && (
          <>
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                paddingBottom: px(18),
              }}
              className="font-normal"
            >
              Акция на Тарифы
            </p>
            <div
              style={{
                gap: px(14),
              }}
              className="flex  flex-col justify-center"
            >
              {tariffForFirstGuest?.map(item => (
                <div key={item.id} className="flex">
                  <div className="flex w-full  items-center justify-between ">
                    <div
                      style={{
                        width: px(item?.isCheckbox && 169),
                        fontSize: px(15),
                        lineHeight: px(20),
                      }}
                      className="font-normal"
                    >
                      {item.title}
                    </div>
                    <div
                      style={{
                        width: px(item?.isCheckbox && 121),
                      }}
                      className={`flex items-center ${item?.isCheckbox && 'justify-between'} `}
                    >
                      {item?.isCheckbox && (
                        <div
                          aria-hidden
                          onClick={() => {
                            item.id === 1 &&
                              caughtValue !== '0%' &&
                              caughtValue &&
                              setCurrentPromotionGroup({ isCaught: !isCaught });
                            item.id === 2 &&
                              withoutNormValue !== '0%' &&
                              withoutNormValue &&
                              setCurrentPromotionGroup({ isWithoutNorm: !isWithoutNorm });
                            item.id === 3 &&
                              withNormValue !== '0%' &&
                              withNormValue &&
                              setCurrentPromotionGroup({ isWithNorm: !isWithNorm });
                            item.id === 4 &&
                              helpValue !== '0%' &&
                              helpValue &&
                              setCurrentPromotionGroup({ isHelp: !isHelp });
                          }}
                          className={` cursor-pointer ${
                            currentPromotionGroup?.[item.checkedValue]
                              ? 'bg-[#0a84fe]'
                              : 'border-[1px] border-solid border-[#000] bg-white'
                          }`}
                          style={{
                            width: px(17),
                            height: px(17),
                          }}
                        />
                      )}
                      <div
                        onClick={() => {
                          item.setShow(true);
                        }}
                        onKeyDown={() => {
                          item.setShow(true);
                        }}
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          color:
                            currentPromotionGroup?.[item.checkedValue] ||
                            (currentPromotionGroup[item.value] &&
                              currentPromotionGroup[item.value] !== '0' &&
                              currentPromotionGroup[item.value] !== '0%')
                              ? '#0a84fe'
                              : 'black',
                        }}
                        className="font-normal"
                      >
                        {currentPromotionGroup[item.value] || '0 %'}
                      </div>
                    </div>
                  </div>
                  {item.id === 1 && (
                    <Picker
                      singleItem
                      title="Скидка"
                      show={showCaughtValue}
                      onClose={() => setShowCaughtValue(false)}
                      data={{
                        caughtValue: interestData.interest,
                      }}
                      onChange={({ caughtValue }) => setCurrentPromotionGroup({ caughtValue })}
                      value={caughtValue || '0 %'}
                      button
                      isLine
                    />
                  )}
                  {item.id === 2 && (
                    <Picker
                      singleItem
                      title="Скидка"
                      show={showWithoutNorm}
                      onClose={() => setShowWithoutNorm(false)}
                      data={{
                        withoutNormValue: interestData.interest,
                      }}
                      onChange={({ withoutNormValue }) =>
                        setCurrentPromotionGroup({ withoutNormValue })
                      }
                      value={withoutNormValue || '0 %'}
                      button
                      isLine
                    />
                  )}
                  {item.id === 3 && (
                    <Picker
                      singleItem
                      title="Скидка"
                      show={showWithNorm}
                      onClose={() => setShowWithNorm(false)}
                      data={{
                        withNormValue: interestData.interest,
                      }}
                      onChange={({ withNormValue }) => setCurrentPromotionGroup({ withNormValue })}
                      value={withNormValue || '0 %'}
                      button
                      isLine
                    />
                  )}
                  {item.id === 4 && (
                    <Picker
                      singleItem
                      title="Скидка"
                      show={showHelp}
                      onClose={() => setShowHelp(false)}
                      data={{
                        helpValue: interestData.interest,
                      }}
                      onChange={({ helpValue }) => setCurrentPromotionGroup({ helpValue })}
                      value={helpValue || '0 %'}
                      button
                      isLine
                    />
                  )}
                </div>
              ))}
              {/* <Picker
                singleItem
                title="Скидка"
                show={showCost}
                onClose={() => setShowcost(false)}
                data={{
                  cost: interestData.interest,
                }}
                onChange={({ cost }) => setCurrentPromotionGroup({ cost })}
                value={cost || '0 %'}
                button
                isLine
              /> */}
              <div style={{ paddingTop: px(26) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26) }} className="font-normal">
                  Период
                </p>
                <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
                  Указать время работы
                </p>
              </div>
              <PromotionPeriod start={objectPeriodStart} end={objectperiodEnd} object />
            </div>
          </>
        )}

        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            marginTop: px(40),
            paddingBottom: px(17),
          }}
          className="font-normal"
        >
          {locationType === 'FISHING'
            ? 'Акция на Объект на берегу'
            : locationType === 'REST'
              ? 'Акция на Объект'
              : 'Акция на Услуги'}
        </p>
        <div
          style={{
            gap: px(14),
          }}
          className="flex  flex-col justify-center"
        >
          <div className="flex">
            <div className="flex w-full  items-center justify-between ">
              <div style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
                Стоимость аренды
              </div>
              <div className="flex items-center">
                <div
                  aria-hidden
                  onClick={() => {
                    setShowcost(true);
                  }}
                  onKeyDown={() => {
                    setShowcost(true);
                  }}
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    color: cost && cost !== '0' && cost !== '0%' ? '#0a84fe' : 'black',
                  }}
                  className="font-normal"
                >
                  {currentPromotionGroup.cost || '0%'}
                </div>
              </div>
            </div>
            <Picker
              singleItem
              title="Скидка"
              show={showCost}
              onClose={() => setShowcost(false)}
              data={{
                cost: interestData.interest,
              }}
              onChange={({ cost }) => setCurrentPromotionGroup({ cost })}
              value={cost || '0 %'}
              button
              isLine
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionInfo;
