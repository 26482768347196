import cbor from 'cbor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { addActiveType, clearActiveId, loginBiometric, loginCode } from '../../actions/auth';
import { loginChallenge } from '../../api/auth';
import { CustomAlert } from '../../components';
import { FaceIdDot } from '../../components/face-id-dot';
import { Icon } from '../../components/Icon';
import { faceIdPageConstant } from '../../constants/Component';
import { px } from '../../constants/Px';
import { BackButton } from '../../new-architecture/shared';
import { isBiometricSelector, isCodeSelector } from '../../selectors/auth';
import { TypesIcon } from '../../types/TypesIcon';

export const FaceIdPage = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isCode = useSelector(isCodeSelector);
  const isBiometric = useSelector(isBiometricSelector);

  const [code, setCode] = useState('');
  const [greeting, setGreeting] = useState('');
  const [error, setError] = useState(false);
  const [confrirm, setConfirm] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const handleChangeCode = value => {
    if (code?.length < 4) {
      setCode(code + value);
    }
  };

  const onClickButtonClose = () => {
    setErrorCount(0);
    setError(false);
    setCode('');
  };

  const onClickButtonSubmit = () => {
    navigate('/login');
  };

  const handleClose = () => {};

  const loginByKey = async e => {
    try {
      const credentialRequestOptions = await loginChallenge(
        cbor.encode({
          biometricToken: isBiometric,
        }),
      );

      console.log('credentialRequestOptions', credentialRequestOptions);

      const parsedOptions = await cbor.decodeFirstSync(credentialRequestOptions?.data);

      console.log('parsedOptions', parsedOptions);

      const credential = await navigator.credentials.get({
        publicKey: parsedOptions,
      });

      console.log('credential', credential);

      const { payload } = await dispatch(
        loginBiometric(
          cbor.encode({
            biometricToken: isBiometric,
            rawId: credential.rawId,
            type: credential.type,
            response: {
              clientDataJSON: credential.response.clientDataJSON,
              authenticatorData: credential.response.authenticatorData,
              signature: credential.response.signature,
              userHandle: credential.response.userHandle,
            },
          }),
        ),
      );
      console.log('payload', payload);
      payload?.status === 201 && navigate('/profile');
    } catch (error_) {
      console.log('error', error_);
    }
  };

  useEffect(() => {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 6 && hour < 12) {
      setGreeting('Доброе утро!');
    } else if (hour >= 12 && hour < 18) {
      setGreeting('Добрый день!');
    } else {
      setGreeting('Добрый вечер!');
    }
  }, []);

  useEffect(() => {
    if (code?.length === 4) {
      try {
        async function fetchData() {
          const { payload } = await dispatch(
            loginCode({
              authCode: code,
              codeToken: isCode,
            }),
          );

          if (payload?.status === 201) {
            setConfirm(true);
            setTimeout(() => {
              navigate('/profile');
            }, 1000);
          } else if (payload?.data?.reason === 'Too many failing attempts') {
            navigate('/blocked', { state: { time: payload?.data?.blockTime } });
          } else if (errorCount === 1) {
            setError(true);
            setErrorCount(prew => prew + 1);
          } else {
            setError(true);
            setErrorCount(prew => prew + 1);
            setTimeout(() => {
              setError(false);
              setCode('');
            }, 1000);
          }
        }

        fetchData();
      } catch {}
    }
  }, [code, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(addActiveType({ activeType: undefined }));
    dispatch(clearActiveId());
  }, []);

  return (
    <div className="home-add-popup-container">
      <div className="home-add-popup-container-overflow">
        <BackButton onClick={() => navigate(-1)} />
        <div
          style={{
            padding: `${px(0)}  ${px(15)}`,
          }}
        >
          <p
            style={{
              fontSize: `${px(32)}`,
              lineHeight: ` ${px(43)}`,
              paddingTop: `${px(20)}`,
            }}
            className="cursor-pointer text-center font-light text-[#000000]"
          >
            {greeting}
          </p>

          <div
            className="flex flex-row justify-center"
            style={{
              gap: `${px(19.2)}`,
              paddingTop: `${px(16)}`,
              paddingBottom: `${px(23.5)}`,
            }}
          >
            {Array?.from({ length: 4 }, (_, index) => (
              <FaceIdDot confrirm={confrirm} key={index} error={error} code={code} index={index} />
            ))}
          </div>

          <div
            className="grid justify-items-center"
            style={{
              gridTemplateColumns: `${px(24)} ${px(24)} ${px(24)}`,
              gridTemplateRows: `${px(55)} ${px(55)} ${px(55)}`,

              padding: `0 ${px(43)} 0 ${px(46)}`,
              rowGap: `${px(9)}`,
              columnGap: ` ${px(64)}`,
            }}
          >
            {faceIdPageConstant.map(item =>
              item.id < 10 && item.id !== '0' ? (
                <div
                  className="flex cursor-pointer flex-row justify-stretch font-normal"
                  style={{
                    fontSize: `${px(42)}`,
                    lineHeight: `${px(55)}`,
                    padding: 0,
                  }}
                  key={item?.id}
                  onClick={() => {
                    !item?.isText && !item?.isIcon && handleChangeCode(item?.value);
                  }}
                >
                  {item.value}
                </div>
              ) : (
                <div>
                  {item?.isText ? (
                    <div
                      onClick={() => {
                        navigate('/login');
                      }}
                      className="flex-column flex"
                      style={{
                        height: `${px(70)}`,
                        width: `${px(56)}`,
                      }}
                    >
                      {item.value.split(' ').map(item => (
                        <p
                          className="cursor-pointer text-center text-[#000000] opacity-30"
                          style={{
                            fontSize: `${px(20)}`,
                            lineHeight: `${px(22)}`,
                          }}
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  ) : item?.isIcon && code?.length === 0 ? (
                    <Icon
                      type={`${TypesIcon[item.value]}`}
                      viewBox="0 0 40 40"
                      width={px(43)}
                      height={px(43)}
                      style={{
                        marginTop: `${px(16)}`,
                      }}
                      className="cursor-pointer"
                      onClick={e => loginByKey(e)}
                    />
                  ) : item?.isIcon && code?.length !== 0 ? (
                    <Icon
                      type={TypesIcon.DELETECODE}
                      viewBox="0 0 40 40"
                      width={px(41)}
                      height={px(41)}
                      style={{
                        marginTop: `${px(17)}`,
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setCode(code.slice(0, -1));
                        setError(false);
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        fontSize: `${px(42)}`,
                        lineHeight: `${px(55)}`,
                        paddingTop: `${px(8.5)}`,
                        paddingLeft: `${px(50)}`,
                        paddingRight: `${px(50)}`,
                        fontWeight: 400,
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        item?.id === '0' && handleChangeCode(item?.value);
                      }}
                    >
                      {item.value}
                    </div>
                  )}
                </div>
              ),
            )}
          </div>
        </div>
      </div>
      {code.length === 4 && errorCount === 2 && (
        <CustomAlert
          single
          title="Забыли код доступа?Нажмите кнопку выйти, чтобы сбросить код доступа  и создать новый"
          onClickButtonSubmit={onClickButtonSubmit}
          onClickButtonClose={onClickButtonClose}
          buttonLeftText="Отмена"
          buttonRightText="Выйти"
          height={px(150)}
          paddingLeftRight={px(10)}
        />
      )}
    </div>
  );
};
