import React from 'react';
import { Link } from 'react-router-dom';

import ad1Image from '../assets/images/ads/0.png';
import { px } from '../constants/Px';

export const Banner = () => (
  <div
    className="relative z-30 bg-[#e8f2ff]"
    style={{
      padding: `${px(10)} ${px(15)}`,
    }}
  >
    <Link to="/" className="pointer-events-none" target="_blank">
      <img src={ad1Image} alt="home-ad-1" />
    </Link>
  </div>
);

export default Banner;
