import React from 'react';

import { px } from '../constants/Px';
import { Checkbox } from './checkbox';

export const SortModal = ({ data, selectedOption, handleOptionChange }) => (
  <div>
    <div
      style={{
        marginTop: px(15),
        gap: px(39),
        paddingLeft: px(15),
        paddingRight: px(15),
      }}
      className="flex flex-col"
    >
      {data.map(item => (
        <div key={item?.id} className="flex items-center justify-between">
          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
            className="font-normal"
          >
            {item?.title}
          </p>
          <Checkbox
            margin
            checked={selectedOption === item?.value}
            value={item?.value}
            onChange={e => handleOptionChange(e)}
            style={{
              margin: '0px',
            }}
          />
        </div>
      ))}
    </div>
  </div>
);

export default SortModal;
