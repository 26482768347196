import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/profile-admin';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_ADMINS = 'profile-admin/getAdmins';

export const getAdmins = createAsyncThunk(
  GET_ADMINS,
    createRequest({
        type: GET_ADMINS,
        loader: (data) => profileAdminApi.getAdmins(data),
    }),
);
