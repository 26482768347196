import React, { useState } from 'react';

import { DatePicker, Main, Switch } from '../../components';
import {
  selectionsDate,
  statisticDistributionPage,
  statisticsExtractsPage,
  timeDate,
} from '../../constants/Component';
import { getPeriodString } from '../../constants/Function/index';
import { px } from '../../constants/Px';

export const ExtractStatisticsPage = () => {
  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth();
  const currentDay = current.getDate();

  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleNo, setToggleNo] = useState(false);
  const [id, setId] = useState(0);
  const [dataPickerId, setDataPickerId] = useState(2);

  const [time, setTime] = useState({
    hour: '06:00',
  });

  const [suggestionCopyPeriod, setSuggestionCopyPeriod] = useState({
    statistic: 'Прошлая неделя',
    documentType: 'PDF',
  });

  const [date, setDate] = useState({
    start: {
      day: `${currentDay}` || '1',
      month: selectionsDate?.month[currentMonth] || 'Январь',
      year: `${currentYear}` || '2023',
    },
    end: {
      day: '',
      month: '',
      year: '',
    },
  });

  const [startDate, setStartDate] = useState({
    day: `${currentDay}` || '1',
    month: selectionsDate?.month[currentMonth] || 'Январь',
    year: `${currentYear}` || '2023',
  });

  const onClick = (value, isButton) => {
    if (id === 1) {
      setDate({ ...date, end: value });
      setId(0);
      if (isButton) {
        setShow(false);
      }
    } else {
      setDate({ ...date, start: value });
      setId(1);
    }
  };

  const changeDocumentType = value => {
    setSuggestionCopyPeriod({ ...suggestionCopyPeriod, documentType: value });
  };

  const openPicker = value => {
    setDataPickerId(value);
    setShow(true);
  };

  const distributionPageToggleData = [
    { id: 1, label: 'Отправлять', state: toggle, setState: setToggle },
    { id: 2, label: 'Не отправлять если нет', state: toggleNo, setState: setToggleNo },
  ];

  const array = [
    { id: 0, label: 'Стартовое время' },
    { id: 1, label: 'Конец периода' },
  ];

  const datePickerArray = [
    {
      singleItem: true,
      title: 'Время',
      data: timeDate,
      setPickerValue: setTime,
      pickerValue: time,
      isDocumentType: false,
      isDatePeriod: false,
      date: undefined,
      changeDocumentType: () => {},
      onClick: () => {},
    },
    {
      singleItem: true,
      title: 'Период. Формат файла',
      data: statisticDistributionPage,
      setPickerValue: setSuggestionCopyPeriod,
      pickerValue: suggestionCopyPeriod,
      isDocumentType: true,
      isDatePeriod: false,
      date: undefined,
      changeDocumentType,
      onClick: () => {},
    },
    {
      singleItem: false,
      title: 'Календарь. Период',
      data: selectionsDate,
      setPickerValue: setStartDate,
      pickerValue: startDate,
      isDocumentType: false,
      isDatePeriod: true,
      date,
      changeDocumentType: () => {},
      onClick,
    },
  ];

  const periodString = getPeriodString(date?.start, date?.end, suggestionCopyPeriod?.statistic);

  return (
    <Main
      title={`Статистика.\nРассылка копий`}
      fontSize={32}
      lineHeight={32}
      light
      navigatePatch="/notification"
    >
      <DatePicker
        singleItem={datePickerArray?.[dataPickerId]?.singleItem}
        title={datePickerArray?.[dataPickerId]?.title}
        show={show}
        setShow={setShow}
        data={datePickerArray?.[dataPickerId]?.data}
        setPickerValue={datePickerArray?.[dataPickerId]?.setPickerValue}
        pickerValue={datePickerArray?.[dataPickerId]?.pickerValue}
        buttonTitle="Применить"
        isDocumentType={datePickerArray?.[dataPickerId]?.isDocumentType}
        isDatePeriod={datePickerArray?.[dataPickerId]?.isDatePeriod}
        date={datePickerArray?.[dataPickerId]?.date}
        id={id}
        setDataPickerId={setDataPickerId}
        onClick={datePickerArray?.[dataPickerId]?.onClick}
        changeDocumentType={datePickerArray?.[dataPickerId]?.changeDocumentType}
        isLine
      />

      <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
        Контролируйте в единой таблице информацию по тарифам, условиям, ценам и допускам
      </p>
      <div className="flex-column flex" style={{ marginTop: px(36), gap: px(39) }}>
        {distributionPageToggleData.map(element => (
          <div key={element?.id} className="flex flex-row items-center justify-between">
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              {element.label}
            </p>
            <Switch toggle={element.state} setToggle={element.setState} />
          </div>
        ))}
      </div>
      <div
        style={{
          gap: px(40),
          marginTop: px(22),
        }}
        className="flex w-full flex-row justify-between"
      >
        {array.map(element => (
          <div
            key={element.id}
            style={{
              width: px(125),
              height: px(90),
            }}
            onClick={() => {
              openPicker(element?.id);
            }}
            onKeyDown={() => {
              openPicker(element?.id);
            }}
            className="flex flex-col justify-between"
          >
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
                paddingBottom: px(13),
              }}
              className="font-normal  opacity-50"
            >
              {element?.label}
            </p>
            <div>
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className="font-normal text-black"
              >
                {element.id === 0 ? time?.hour : periodString}
              </p>
              <hr
                className="text-black opacity-30"
                style={{
                  marginTop: px(5),
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </Main>
  );
};
