import { request } from '../../helpers/api/request';

export const createGuideNews = async data =>
  request({
    url: `guide-news`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    data,
  });
