import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const CREATE_COMPETITION_CABINET = 'competition/createCompetitionCabinet';

const onSuccess = () => async dispatch => {
  dispatch(
    addModal({
      id: 13,
      data: {
        title: 'Соревнование создано',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'profile',
    }),
  );
};

export const createCompetitionCabinet = createAsyncThunk(
  CREATE_COMPETITION_CABINET,
  createRequest({
    type: CREATE_COMPETITION_CABINET,
    loader: data => authApi.createCompetitionCabinet(data),
    onSuccess,
  }),
);
