import { addLeadingZeros } from '../../../../../../constants/Function';
import { INITIAL_OBJECT_FIELDS } from '../constants/object.constants';

export const mapObjectToFields = object => ({
  name: object?.name ?? INITIAL_OBJECT_FIELDS?.name,
  description: object?.description ?? INITIAL_OBJECT_FIELDS?.description,
  termsAndRules: object?.EventContact?.termsAndRules ?? INITIAL_OBJECT_FIELDS?.termsAndRules,
  attachments:
    object?.EventAttachment?.filter?.(
      att => att?.type !== 'GALLERY' && att?.type !== 'SCHEMA',
    )?.map(({ imageUrl, type: t }, index) => ({
      src: `${process.env.REACT_APP_STATIC_URL}${imageUrl}`,
      first: index === 0,
      key: t?.toLowerCase(),
    })) ?? INITIAL_OBJECT_FIELDS?.attachments,
  gallery:
    object?.EventAttachment?.filter?.(att => att?.type === 'GALLERY')
      ?.sort?.((a, b) => a.priority - b.priority)
      ?.map(({ imageUrl }, index) => ({
        src: `${process.env.REACT_APP_STATIC_URL}${imageUrl}`,
        first: index === 0,
      })) ?? INITIAL_OBJECT_FIELDS?.gallery,
  schemas:
    object?.EventAttachment?.filter?.(att => att?.type === 'SCHEMA')?.map(
      ({ imageUrl }) => `${process.env.REACT_APP_STATIC_URL}${imageUrl}`,
    ) ?? INITIAL_OBJECT_FIELDS?.schemas,
  contacts: {
    coordinates:
      object?.EventContact?.coordinates?.join?.(', ') ??
      INITIAL_OBJECT_FIELDS?.contacts?.coordinates,
    country: object?.EventContact?.country ?? INITIAL_OBJECT_FIELDS?.contacts?.country,
    region: object?.EventContact?.region ?? INITIAL_OBJECT_FIELDS?.contacts?.region,
    district: object?.EventContact?.district ?? INITIAL_OBJECT_FIELDS?.contacts?.district,
    town: object?.EventContact?.town ?? INITIAL_OBJECT_FIELDS?.contacts?.town,
    street: object?.EventContact?.street ?? INITIAL_OBJECT_FIELDS?.contacts?.street,
    email: object?.EventContact?.email ?? INITIAL_OBJECT_FIELDS?.contacts?.email,
    mainPhone: object?.EventContact?.mainPhone ?? INITIAL_OBJECT_FIELDS?.contacts?.mainPhone,
    mainPhoneStartTime:
      object?.EventContact?.mainPhoneStartTime ??
      INITIAL_OBJECT_FIELDS?.contacts?.mainPhoneStartTime,
    mainPhoneEndTime:
      object?.EventContact?.mainPhoneEndTime ?? INITIAL_OBJECT_FIELDS?.contacts?.mainPhoneEndTime,
    secondaryPhone:
      object?.EventContact?.secondaryPhone ?? INITIAL_OBJECT_FIELDS?.contacts?.secondaryPhone,
    secondaryPhoneStartTime:
      object?.EventContact?.secondaryPhoneStartTime ??
      INITIAL_OBJECT_FIELDS?.contacts?.secondaryPhoneStartTime,
    secondaryPhoneEndTime:
      object?.EventContact?.secondaryPhoneEndTime ??
      INITIAL_OBJECT_FIELDS?.contacts?.secondaryPhoneEndTime,
    websiteLink: object?.EventContact?.websiteLink ?? INITIAL_OBJECT_FIELDS?.contacts?.websiteLink,
    secondaryWebsiteLink:
      object?.EventContact?.secondaryWebsiteLink ??
      INITIAL_OBJECT_FIELDS?.contacts?.secondaryWebsiteLink,
    socialPageLink:
      object?.EventContact?.socialPageLink ?? INITIAL_OBJECT_FIELDS?.contacts?.socialPageLink,
    telegramLink:
      object?.EventContact?.telegramLink ?? INITIAL_OBJECT_FIELDS?.contacts?.telegramLink,
    viberLink: object?.EventContact?.viberLink ?? INITIAL_OBJECT_FIELDS?.contacts?.viberLink,
    whatsAppLink:
      object?.EventContact?.whatsAppLink ?? INITIAL_OBJECT_FIELDS?.contacts?.whatsAppLink,
    id: addLeadingZeros(object?.EventContact?.id ?? object?.id),
  },
});

export const getInitialFields = object => {
  const prevFields = mapObjectToFields(object);

  return {
    ...INITIAL_OBJECT_FIELDS,
    attachments: prevFields?.attachments,
    gallery: prevFields?.gallery,
    schemas: prevFields?.schemas,
    contacts: {
      ...INITIAL_OBJECT_FIELDS?.contacts,
      id: addLeadingZeros(object?.EventContact?.id ?? object?.id),
    },
  };
};
