import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_CARD_HOLDER = 'card-holder/getCardHolder';

export const getCardHolder = createAsyncThunk(
  GET_CARD_HOLDER,
  createRequest({
    type: GET_CARD_HOLDER,
    loader: data => authApi.getCardHolder(data),
  }),
);
