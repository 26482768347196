import { request } from '../../helpers/api/request';

export const signUp = async ({ phone, name }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `auth/signup`,
    method: 'POST',
    data: {
      phone,
      name,
    },
  });

  return response;
};
