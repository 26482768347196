import { AnimatePresence, motion, useAnimation, useDragControls } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { ClosePopupLine } from '../assets/svgs/close-popup-line';
import { ModalHeader } from './modal-header';

export const DraggableModal = ({
  children,
  show,
  onClose,
  isDragControls = true,
  title,
  isLine,
}) => {
  const dragControls = useDragControls();
  const controls = useAnimation();

  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    show && controls.start({ y: 0 });
  }, [show]);
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{
            y: window.innerHeight,
          }}
          animate={controls}
          exit={{
            y: window.innerHeight,
          }}
          drag="y"
          dragControls={dragControls}
          dragListener={!isDragControls}
          onDragEnd={(event, info) => {
            const { point, velocity } = info || {};
            if (point?.y > window.innerHeight / 2 || velocity?.y > 20) {
              onClose && onClose();
            } else {
              controls.start({
                y: 0,
              });
            }
            setDragging(false);
          }}
          onDragStart={() => setDragging(true)}
          dragConstraints={{
            top: 0,
            bottom: window.innerHeight,
          }}
          transition={{ ease: 'easeInOut' }}
          className={`draggable-modal-container `}
        >
          <ClosePopupLine
            style={{
              touchAction: 'auto',
            }}
            onPointerDown={e => {
              dragControls.start(e);
            }}
          />
          <div className="scrollable-container">
            {title && (
              <ModalHeader
                title={title}
                isLine={isLine}
                onPointerDown={e => {
                  dragControls.start(e);
                }}
              />
            )}

            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DraggableModal;
