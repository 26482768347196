import * as authApi from '@/api/contacts';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const UPDATE_CONTACTS = 'contacts/update-contacts';

export const updateContacts = createAsyncThunk(
  UPDATE_CONTACTS,
  createRequest({
    type: UPDATE_CONTACTS,
    loader: (data) => authApi.updateContacts(data),
  }),
);
