import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';

export const UPDATE_CARD = 'card/updateCard';

export const updateCard = createAsyncThunk(
  UPDATE_CARD,
  createRequest({
    type: UPDATE_CARD,
    loader: data => authApi.updateCard(data),
  }),
);
