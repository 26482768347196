import React, { useContext, useEffect } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import Checkbox from './checkbox';
import { Icon } from './Icon';
import { SearchMenuFilterBlockItem } from './search-menu-filter-block-item';
import { StateContext } from './state-provider';

export const SearchMenuFilterBlockFiltersFishing = ({
  filter,
  selectedOptionsTitle,
  setSelectedOptionsTitle,
  scrollIndex,
  setScrollIndex,
  save,
  setSave,
  selectedOptionsObjectFilters,
  setSelectedOptionsObjectFilters,
  oneSelectedOption,
  setOneSelectedOption,
  filterRadiobutton,
  setFilterRadiobutton,
  show,
  setShow,
  date,
  setDataPickerId,
}) => {
  const { selectedOptions, setSelectedOptions } = useContext(StateContext);
  const { scrollRef } = useContext(StateContext);

  const toggleOption = (filter, option, title) => {
    setSave(false);
    setSelectedOptionsTitle(prev => [...prev, title]);
    if (filter !== 1 && filter !== 5) {
      const newOptions = selectedOptions.includes(option)
        ? selectedOptions.filter(item => item !== option)
        : [...selectedOptions, option];
      setSelectedOptions(newOptions);
    }

    if (filter === 1 || filter === 5) {
      const newOptions = oneSelectedOption[filter]?.includes(option)
        ? oneSelectedOption[filter].filter(item => item !== option)
        : selectedOptionsObjectFilters[filter][0] === 'free'
          ? ['free', option]
          : [option];

      setOneSelectedOption({ ...oneSelectedOption, [filter]: newOptions });
    }

    if (selectedOptionsObjectFilters[filter]) {
      if (filter === 1 || filter === 5) {
        if (selectedOptionsObjectFilters[filter]?.includes(option)) {
          const a = oneSelectedOption[filter].filter(item => item !== option);
          setSelectedOptionsObjectFilters({ ...selectedOptionsObjectFilters, [filter]: a });
        } else {
          selectedOptionsObjectFilters[filter][0] === 'free'
            ? setSelectedOptionsObjectFilters({
                ...selectedOptionsObjectFilters,
                [filter]: ['free', option],
              })
            : setSelectedOptionsObjectFilters({
                ...selectedOptionsObjectFilters,
                [filter]: [option],
              });
        }
      } else if (selectedOptionsObjectFilters[filter].includes(option)) {
        const newOpt = selectedOptionsObjectFilters[filter].filter(item => item !== option);
        setSelectedOptionsObjectFilters({ ...selectedOptionsObjectFilters, [filter]: newOpt });
      } else
        setSelectedOptionsObjectFilters({
          ...selectedOptionsObjectFilters,
          [filter]: [...selectedOptionsObjectFilters[filter], option],
        });
    }
  };

  const cleanOptions = (e, filterTitle, value) => {
    const newArr = [];
    const newArrCommon = [];
    const arr = [];
    e?.map(el => {
      arr.push(el.value);
    });
    const commonArray = [].concat(
      selectedOptionsObjectFilters[1],
      selectedOptionsObjectFilters[2],
      selectedOptionsObjectFilters[3],
      selectedOptionsObjectFilters[4],
      selectedOptionsObjectFilters[5],
      selectedOptionsObjectFilters[6],
      selectedOptionsObjectFilters[9],
      selectedOptionsObjectFilters[10],
      selectedOptionsObjectFilters[11],
      selectedOptionsObjectFilters[12],
    );

    selectedOptionsObjectFilters[value]?.map(elem => {
      !arr.includes(elem) && newArr.push(elem);
    });

    setSelectedOptionsObjectFilters({ ...selectedOptionsObjectFilters, [value]: newArr });

    commonArray.map(elem => {
      !arr.includes(elem) && newArrCommon.push(elem);
    });
    setSelectedOptions(newArrCommon);

    setSelectedOptionsTitle(selectedOptionsTitle.filter(item => item !== filterTitle));
  };

  useEffect(() => {
    const emptyArrays = Object.keys(selectedOptionsObjectFilters).filter(
      key =>
        (selectedOptionsObjectFilters[key].length === 0 && key !== '3') ||
        (!selectedOptionsObjectFilters[key][0] && key !== '3'),
    );

    setScrollIndex(emptyArrays.map(str => Number.parseInt(str, 10)));
  }, [selectedOptionsObjectFilters]);

  useEffect(() => {
    setSelectedOptionsObjectFilters({ ...selectedOptionsObjectFilters, 8: filterRadiobutton });
  }, [filterRadiobutton]);

  return (
    <>
      {filter.type === 'checkbox' ? (
        <div className="search-menu-filter-block">
          <div className="search-menu-filter-block-top">
            <h4
              style={{
                color: save && filter.id !== 3 && scrollIndex.includes(filter.value) && 'red',
              }}
              ref={filter.value === Math.min(...scrollIndex) ? scrollRef : null}
            >
              {filter.title}
            </h4>
            {selectedOptionsTitle?.includes(filter.title) && (
              <div
                className="search-menu-filter-block-reset"
                onClick={() => cleanOptions(filter.options, filter.title, filter.value)}
              >
                сбросить
              </div>
            )}
          </div>
          <div className="search-menu-filter-block-bottom">
            {filter.options.map((option, key) => (
              <SearchMenuFilterBlockItem
                key={`${option.label}-${key}`}
                option={option}
                isSelected={
                  selectedOptions?.includes(option?.value) ||
                  oneSelectedOption[filter.value]?.includes(option?.value)
                }
                toggleOption={() => toggleOption(filter?.value, option?.value, filter?.title)}
                save={save}
                setSave={setSave}
                show={show}
                setShow={setShow}
                date={date}
                setDataPickerId={setDataPickerId}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            marginBottom: px(40),
          }}
          className="search-menu-filter-block-game"
        >
          {filter.options.map(option => {
            const isCheckedFilters =
              filterRadiobutton.length === 0 ? false : filterRadiobutton === option?.value;

            return (
              <div
                key={option.label}
                className={`search-menu-filter-block-game-item ${
                  isCheckedFilters ? 'selected' : ''
                }`}
                onClick={() => {
                  setFilterRadiobutton(option?.value);
                  setSave(false);
                }}
              >
                <div className="search-menu-filter-block-game-content">
                  <div
                    className="search-menu-filter-block-game-svg"
                    style={{
                      color: '#000000',
                    }}
                  >
                    <Icon
                      type={TypesIcon.GAMES}
                      width={px(24.128)}
                      height={px(26.848)}
                      viewBox="0 0 24.128 26.848"
                    />
                  </div>
                  <div>
                    <h3>{filter.title}</h3>
                    <p>{option.label}</p>
                  </div>
                </div>
                <Checkbox checked={isCheckedFilters} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
