export const headers = [
  {
    id: 1,
    type: 'fishing',
    titles: [
      {
        id: 4,
        priceDiscountAction: 'price',
        title: 'Тариф для 1-го гостя',
        activeIds: [2, 3, 4, 5, 6, 7, 9, 10, 11],
      },
      {
        id: 5,
        priceDiscountAction: 'discount',
        title: 'Скидка на Тарифы',
        activeIds: [16, 17, 18, 19, 20],
      },
      {
        id: 6,
        priceDiscountAction: 'action',
        title: 'Акция на Тарифы',
        activeIds: [51, 52, 53, 54, 55],
      },
    ],
  },
  {
    id: 2,
    type: 'rest',
    titles: [
      {
        id: 7,
        priceDiscountAction: 'price',
        title: 'Тариф. Наценки',
        activeIds: [6, 7, 13],
      },
      {
        id: 8,
        priceDiscountAction: 'discount',
        title: 'Скидка на Объект',
        activeIds: [49],
      },
      {
        id: 9,
        priceDiscountAction: 'action',
        title: 'Скидка на Услуги',
        activeIds: [50],
      },
    ],
  },
  {
    id: 3,
    type: 'services',
    titles: [
      {
        id: 10,
        priceDiscountAction: 'price',
        title: 'Тариф. Наценки на Услуги',
        activeIds: [6, 7, 13, 14, 15],
      },
      {
        id: 11,
        priceDiscountAction: 'discount',
        title: 'Скидка на Услуги',
        activeIds: [50],
      },
      {
        id: 12,
        priceDiscountAction: 'action',
        title: 'services 3',
        activeIds: [6, 7, 13],
      },
    ],
  },
];
