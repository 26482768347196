/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const ADD_COMPETITION_ATTACHMENT = 'competition/addAttachment';

export const addCompetitionAttachment = createAsyncThunk(
  ADD_COMPETITION_ATTACHMENT,
  createRequest({
    type: ADD_COMPETITION_ATTACHMENT,
    loader: data => authApi.addCompetitionAttachment(data),
  }),
);
