import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/notification';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_NOTIFICATION = 'notification/getNotification';

export const getNotification = createAsyncThunk(
  GET_NOTIFICATION,
  createRequest({
    type: GET_NOTIFICATION,
    loader: data => authApi.getNotification(data),
  }),
);
