/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/section-promotion';
import { createRequest } from '@/helpers/api/createRequest';

export const UPDATE_PROMOTION = 'price/updatePromotion';

export const updatePromotion = createAsyncThunk(
  UPDATE_PROMOTION,
  createRequest({
    type: UPDATE_PROMOTION,
    loader: data => authApi.updatePromotion(data),
  }),
);
