import React, { useEffect } from 'react';

import qrCode from '../assets/images/qr-code.png';
import { px } from '../constants/Px';

export const AddLoyalCard = ({
  cardData: {
    cardId,
    cardName,
    cardDiscount,
    cardLogo: { imageSrc, showLogo },
    cardImage,
    cardBackground,
    cardActiveDate,
    cardProfileName,
  },
}) => (
  <div
    className="shadow-md"
    style={{
      height: px(360),
      margin: `${px(16)} ${px(0)} ${px(6)} ${px(0)}`,
      borderRadius: px(10),
    }}
  >
    <div
      className="flex-column relative flex"
      style={{
        height: px(204),
      }}
    >
      {showLogo && (
        <div className="absolute z-[2] flex h-full w-full items-center justify-center overflow-hidden">
          <div
            style={{
              width: px(65),
              height: px(65),
              boxShadow: '0 4px 6px 0 #00000029',
              borderRadius: px(10),
              overflow: 'hidden',
            }}
          >
            {imageSrc && (
              <img
                className="h-full w-full object-cover"
                src={`${process.env.REACT_APP_STATIC_URL}${imageSrc}`}
                alt="avatarImage"
              />
            )}
          </div>
        </div>
      )}
      <div
        className="absolute z-[1] h-full w-full overflow-hidden"
        style={{ borderRadius: px(10) }}
      >
        {cardImage && <img className="h-full w-full" src={`${cardImage}`} alt="cardImage" />}
        {cardBackground && (
          <img
            className="h-full w-full"
            src={`${process.env.REACT_APP_STATIC_URL}${cardBackground}`}
            alt="cardBackground"
          />
        )}
      </div>
      <div
        className="pointer-events-none absolute z-[2] flex self-end font-normal text-[#fa0202]"
        style={{
          right: px(10),
          top: px(0),
          fontSize: px(32),
          lineHeight: px(43),
        }}
      >
        {cardDiscount}
      </div>
      <div
        className="pointer-events-none absolute z-[2] flex flex-row items-start justify-between"
        style={{ bottom: px(10), left: px(20), width: px(250) }}
      >
        <div className="flex-column flex w-full">
          <p
            className={`font-light ${
              cardImage || cardBackground ? 'text-white' : 'text-black'
            } uppercase`}
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              wordWrap: 'break-word',
              WebkitLineClamp: 1,
              paddingBottom: px(5),
            }}
          >
            {cardProfileName}
          </p>
          <div className="flex items-center justify-between">
            <p
              className={`font-light ${cardImage || cardBackground ? 'text-white' : 'text-black'}`}
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                WebkitLineClamp: 1,
              }}
            >
              {cardActiveDate}
            </p>
            <p
              className={`flex self-end font-light ${
                cardImage || cardBackground ? 'text-white' : 'text-black'
              }`}
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                WebkitLineClamp: 1,
              }}
            >
              {cardName}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="flex-column  flex items-center ">
      <div
        style={{
          marginTop: px(11),
        }}
      >
        <img src={qrCode} alt="qr-code" style={{ width: px(118), height: px(118) }} />
      </div>
      <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
        {cardId ? cardId?.toString() : ''}
      </p>
    </div>
  </div>
);

export default AddLoyalCard;
