import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getCompetitions } from '../actions/competition';
import { getEvent, locationEvent } from '../actions/event';
import cardImage6 from '../assets/images/ad.png';
import { px } from '../constants/Px';
import { listOfCompetitionsSelector } from '../selectors/competition';
import { cardsSelector } from '../selectors/event';
import { Card } from './card';

export const EventsCards = ({ cards, showAllEventsCards, setShowAllEventsCards, competitions }) => {
  const advertCards = [
    // eslint-disable-next-line sonarjs/no-duplicate-string
    { link: '/', image: cardImage6, type: 'ad' },
    { link: '/', image: cardImage6, type: 'ad' },
    { link: '/', image: cardImage6, type: 'ad' },
    { link: '/', image: cardImage6, type: 'ad' },
  ];
  /* const navigate = useNavigate(); */
  const dispatch = useDispatch();
  // const cards = useSelector(cardsSelector);
  const competitionCabinets = useSelector(listOfCompetitionsSelector);

  const [selectedCard, setSelectedCard] = useState({});

  useEffect(() => {
    if (competitions) dispatch(getCompetitions());
    else dispatch(locationEvent({ skip: 0 }));
  }, []);

  const [showPopup, SetShowPopup] = useState(false);
  const formattedEventsCards = useMemo(
    () =>
      competitions
        ? competitionCabinets
            ?.filter(card => !card.deletedAt)
            ?.map((eventCard, index) => {
              if (index === 2) return [eventCard, advertCards[0]];
              if ((index - 2) % 4 === 0) return [eventCard, advertCards[0]];
              return eventCard;
            })
            .flat()
        : cards
            ?.filter(card => !card.deletedAt)
            ?.map((eventCard, index) => {
              if (index === 2) return [eventCard, advertCards[0]];
              if ((index - 2) % 4 === 0) return [eventCard, advertCards[0]];
              return eventCard;
            })
            .flat(),
    [cards, competitionCabinets],
  );

  useEffect(() => {
    if (formattedEventsCards?.length > 8) {
      setShowAllEventsCards(false);
    }
  }, [formattedEventsCards]);

  const cardsToDisplay = useMemo(
    () => (showAllEventsCards ? formattedEventsCards : formattedEventsCards?.slice(0, 8)),
    [formattedEventsCards, showAllEventsCards],
  );

  return (
    <>
      <div className="col w-1/2">
        {cardsToDisplay
          ?.filter((_, index) => index % 2 === 0)
          .map((item, key) =>
            item.type === 'ad' ? (
              <div aria-hidden key={item?.id || key} onClick={e => setSelectedCard(item)}>
                <Card
                  item={item}
                  blocked={item.status}
                  image={item?.image}
                  addressTitle={item?.EventContact?.district}
                  address={item?.EventContact?.town}
                  street={item?.EventContact?.street}
                  title={item?.name}
                  link={item?.link}
                  fullSize
                  style={{
                    marginBottom: px(8),
                  }}
                  setShowPopup={SetShowPopup}
                />
              </div>
            ) : (
              <div
                aria-hidden
                key={item?.id || key}
                onClick={e => {
                  setSelectedCard(item);
                }}
              >
                <Card
                  competitions={competitions}
                  item={item}
                  id={item?.id}
                  title={item?.name}
                  blocked={item.status}
                  addressTitle={item?.EventContact?.district}
                  address={item?.EventContact?.town}
                  street={item?.EventContact?.street}
                  image={
                    item?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                      ? `${process.env?.REACT_APP_STATIC_URL}${
                          item?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                        }`
                      : item?.image
                  }
                  competitionImage={
                    item?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                      ? `${process.env?.REACT_APP_STATIC_URL}${
                          item?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                        }`
                      : item?.image
                  }
                  style={{
                    marginBottom: px(8),
                  }}
                  setShowPopup={SetShowPopup}
                />
              </div>
            ),
          )}
      </div>
      <div
        style={{
          paddingTop: px(20),
        }}
        className="col w-1/2"
      >
        {cardsToDisplay
          ?.filter((_, index) => index % 2 !== 0)
          .map((item, key) =>
            item.type === 'ad' ? (
              <div
                aria-hidden
                key={item?.id || key}
                onClick={e => {
                  setSelectedCard(item);
                }}
              >
                <Card
                  item={item}
                  image={item?.image}
                  link={item?.link}
                  addressTitle={item?.EventContact?.district}
                  address={item?.EventContact?.town}
                  street={item?.EventContact?.street}
                  title={item?.name}
                  blocked={item.status}
                  fullSize
                  style={{
                    marginBottom: px(8),
                  }}
                  setShowPopup={SetShowPopup}
                />
              </div>
            ) : (
              <div
                aria-hidden
                onClick={e => {
                  setSelectedCard(item);
                }}
                key={item?.id || key}
              >
                <Card
                  competitions={competitions}
                  item={item}
                  id={item?.id}
                  title={item?.name}
                  blocked={item.status}
                  addressTitle={item?.EventContact?.district}
                  address={item?.EventContact?.town}
                  street={item?.EventContact?.street}
                  image={
                    item?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                      ? `${process.env?.REACT_APP_STATIC_URL}${
                          item?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                        }`
                      : item?.image
                  }
                  competitionImage={
                    item?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                      ? `${process.env?.REACT_APP_STATIC_URL}${
                          item?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                        }`
                      : item?.image
                  }
                  style={{
                    marginBottom: px(8),
                  }}
                  setShowPopup={SetShowPopup}
                />
              </div>
            ),
          )}
      </div>
    </>
  );
};

export default EventsCards;
