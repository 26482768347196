import { request } from '../../helpers/api/request';

export const getCardHolder = async ({ holderId }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `card-holder/${holderId}`,
    method: 'GET',
  });

  return response;
};
