import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { createCompetitionCabinet } from '../../actions/competition/thunks/create-competition-cabinet';
import { addEvent } from '../../actions/event';
import { createGuideCabinet } from '../../actions/guide/thunks/create-guide-cabinet';
import { getProfile } from '../../actions/profile';
import { Button, Input, Main } from '../../components';
import { PhoneInput } from '../../components/phoneInput';
import { regexPhone } from '../../constants/Component';
import { profileSelector } from '../../selectors/profile';

export const RegistrationObjectPage = () => {
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(true);
  const { state } = useLocation();

  const onSubmit = async event_ => {
    event_.preventDefault();
    try {
      if (state.type === 'object') await dispatch(addEvent({ name }));
      if (state.type === 'competition')
        await dispatch(createCompetitionCabinet({ name, description: '' }));
      if (state.type === 'guide') await dispatch(createGuideCabinet({ name, description: '' }));
    } catch {
      console.log('RegistrationObjectPage');
    } finally {
      setName('');
    }
  };

  const handleChangePhone = event => {
    const input = event.target.value.replaceAll(/\D/g, '');
    setIsValidPhone(regexPhone.test(input));

    setPhone(event.target.value);
  };

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  return (
    <Main light registration title="Регистрация" navigatePatch="/profile#registration">
      <form onSubmit={onSubmit}>
        <div className="reg">
          <Input
            placeholder="Введите название"
            onChange={event => setName(event.target.value)}
            value={name}
          />
        </div>

        <PhoneInput
          setPhone={setPhone}
          handleChangePhone={event => handleChangePhone(event)}
          phone={profile?.phone}
          disabled
        />

        <Button
          type="submit"
          disabled={!name}
          className="w-full border-[1px] border-solid border-[#dddddd]"
          height={60}
          borderRadius={0}
          marginBottom={75}
        >
          Зарегистрироваться
        </Button>
      </form>
    </Main>
  );
};
