import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const UPDATE_GUIDE_NEWS = 'guide/updateGuideNews';

export const updateGuideNews = createAsyncThunk(
  UPDATE_GUIDE_NEWS,
  createRequest({
    type: UPDATE_GUIDE_NEWS,
    loader: data => authApi.updateGuideNews(data),
  }),
);
