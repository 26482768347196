import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/subscription';

export const UPDATE_SUBSCRIPTIONS = 'subscription/addSubscription';


export const updateSubscription = createAsyncThunk(
  UPDATE_SUBSCRIPTIONS,
    createRequest({
        type: UPDATE_SUBSCRIPTIONS,
        loader: (data) => authApi.updateSubscription(data),
    }),
);