import React, { useContext } from 'react';

import SearchSvg from '../assets/svgs/search';
import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import Input from './input';
import { SearchInputResults } from './search-input-results';
import { StateContext } from './state-provider';

export const SearchInput = ({
  searchInputOpen,
  searchInputResult,
  setSearchInputOpen,
  searchValue,
  setSearchValue,
  clearSearchInput,
  setShowSearch,
}) => {
  const { dragControls } = useContext(StateContext);

  return (
    <div className="search-input-container">
      <div
        onPointerDown={e => {
          dragControls.start(e);
        }}
      >
        <div className="search-input">
          <Icon
            type={TypesIcon.BLACKSEARCH}
            width={px(21.36)}
            height={px(21.36)}
            viewBox="0 0 21.357 21.358"
          />
          <Input
            placeholder="Искать..."
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              setSearchInputOpen(true);
            }}
          />
        </div>
      </div>
      {searchInputOpen && searchValue && (
        <SearchInputResults
          clearSearchInput={clearSearchInput}
          searchInputResults={searchInputResult}
          setSearchInputOpen={setSearchInputOpen}
          setShowSearch={setShowSearch}
        />
      )}
    </div>
  );
};
export default SearchInput;
