import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';

export const RESTORE_DELETE_CARD = 'card/restoreDeleteCard';

export const restoreDeleteCard = createAsyncThunk(
  RESTORE_DELETE_CARD,
  createRequest({
    type: RESTORE_DELETE_CARD,
    loader: data => authApi.restoreDeleteCard(data),
  }),
);
