import React from 'react';
import StyledInput from '../../components/styled-input';

export const ConfidentialInfoPage = () => {


  return (
    <>
      <main>
        <div className='max-w-[768px] relative w-full flex flex-column bg-white'>
        <StyledInput />
          <StyledInput />
        </div>
      </main>
    </>
  );
};

export default ConfidentialInfoPage;