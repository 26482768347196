import React, { createContext, useMemo, useState } from 'react';

import { INITIAL_STATE } from '../constants';

export const profileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [data, setData] = useState(INITIAL_STATE);
  const [updateFunction, setUpdateFunction] = useState(() => {});

  const value = useMemo(
    () => ({
      data,
      setData: payload => {
        console.trace();

        return setData(prevState =>
          typeof payload === 'function'
            ? payload?.(prevState)
            : {
                ...prevState,
                ...payload,
              },
        );
      },
      updateFunction,
      setUpdateFunction,
      initialState: INITIAL_STATE,
    }),
    [data, updateFunction],
  );

  return <profileContext.Provider value={value}>{children}</profileContext.Provider>;
};

export default ProfileProvider;
