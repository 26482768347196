/* eslint-disable no-undef */
import { addLeadingZeros } from '../../../../../../constants/Function';
import { INITIAL_GUIDE_FIELDS } from '../constants/guide.constants';

export const mapGuideToFields = object => ({
  createdById: object?.createdById ?? INITIAL_GUIDE_FIELDS?.createdById,
  name: object?.name ?? INITIAL_GUIDE_FIELDS?.name,
  description: object?.description ?? INITIAL_GUIDE_FIELDS?.description,
  attachments:
    object?.attachments
      ?.filter?.(att => att?.type !== 'GALLERY' && att?.type !== 'SCHEMA')
      ?.map(({ url, type: t }, index) => ({
        src: `${process.env.REACT_APP_STATIC_URL}${url}`,
        first: index === 0,
        key: t?.toLowerCase(),
      })) ?? INITIAL_GUIDE_FIELDS?.attachments,
  gallery:
    object?.attachments
      ?.filter?.(att => att?.type === 'GALLERY')
      ?.sort?.((a, b) => a.priority - b.priority)
      ?.map(({ url }, index) => ({
        src: `${process.env.REACT_APP_STATIC_URL}${url}`,
        first: index === 0,
      })) ?? INITIAL_GUIDE_FIELDS?.gallery,
  schemas:
    object?.attachments
      ?.filter?.(att => att?.type === 'SCHEMA')
      ?.map(({ url }) => `${process.env.REACT_APP_STATIC_URL}${url}`) ??
    INITIAL_GUIDE_FIELDS?.schemas,
  protocols:
    object?.attachments
      ?.filter?.(att => att?.type === 'PROTOCOL')
      ?.map(({ url }) => `${process.env.REACT_APP_STATIC_URL}${url}`) ??
    INITIAL_GUIDE_FIELDS?.protocols,
  contact: {
    coordinates:
      object?.contact?.coordinates?.join?.(', ') ?? INITIAL_GUIDE_FIELDS?.contact?.coordinates,
    country: object?.contact?.country ?? INITIAL_GUIDE_FIELDS?.contact?.country,
    region: object?.contact?.region ?? INITIAL_GUIDE_FIELDS?.contact?.region,
    district: object?.contact?.district ?? INITIAL_GUIDE_FIELDS?.contact?.district,
    town: object?.contact?.town ?? INITIAL_GUIDE_FIELDS?.contact?.town,
    street: object?.contact?.street ?? INITIAL_GUIDE_FIELDS?.contact?.street,
    email: object?.contact?.email ?? INITIAL_GUIDE_FIELDS?.contact?.email,
    mainPhone: object?.contact?.mainPhone ?? INITIAL_GUIDE_FIELDS?.contact?.mainPhone,
    mainPhoneStartTime:
      object?.contact?.mainPhoneStartTime ?? INITIAL_GUIDE_FIELDS?.contact?.mainPhoneStartTime,
    mainPhoneEndTime:
      object?.contact?.mainPhoneEndTime ?? INITIAL_GUIDE_FIELDS?.contact?.mainPhoneEndTime,
    secondaryPhone:
      object?.contact?.secondaryPhone ?? INITIAL_GUIDE_FIELDS?.contact?.secondaryPhone,
    secondaryPhoneStartTime:
      object?.contact?.secondaryPhoneStartTime ??
      INITIAL_GUIDE_FIELDS?.contact?.secondaryPhoneStartTime,
    secondaryPhoneEndTime:
      object?.contact?.secondaryPhoneEndTime ??
      INITIAL_GUIDE_FIELDS?.contact?.secondaryPhoneEndTime,
    websiteLink: object?.contact?.websiteLink ?? INITIAL_GUIDE_FIELDS?.contact?.websiteLink,
    secondaryWebsiteLink:
      object?.contact?.secondaryWebsiteLink ?? INITIAL_GUIDE_FIELDS?.contact?.secondaryWebsiteLink,
    socialPageLink:
      object?.contact?.socialPageLink ?? INITIAL_GUIDE_FIELDS?.contact?.socialPageLink,
    telegramLink: object?.contact?.telegramLink ?? INITIAL_GUIDE_FIELDS?.contact?.telegramLink,
    viberLink: object?.contact?.viberLink ?? INITIAL_GUIDE_FIELDS?.contact?.viberLink,
    whatsAppLink: object?.contact?.whatsAppLink ?? INITIAL_GUIDE_FIELDS?.contact?.whatsAppLink,
    cabinetId: addLeadingZeros(object?.id),
  },
});

export const getInitialFields = object => {
  const prevFields = mapGuideToFields(object);

  return {
    ...INITIAL_GUIDE_FIELDS,
    attachments: prevFields?.attachments,
    gallery: prevFields?.gallery,
    schemas: prevFields?.schemas,
    contact: {
      ...INITIAL_GUIDE_FIELDS?.contact,
      cabinetId: addLeadingZeros(object?.id),
    },
  };
};
