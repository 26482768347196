/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';

export const TRANSFER_EVENT = 'event/transferEvent';

export const transferEvent = createAsyncThunk(
  TRANSFER_EVENT,
  createRequest({
    type: TRANSFER_EVENT,
    loader: data => authApi.transferEvent(data),
  }),
);
