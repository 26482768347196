import React from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const Dropdown = ({ title, isErrow }) => (
  <div
    style={{
      padding: `${px(17)} ${px(18)}`,
    }}
    className="flex items-center border-[1px] border-solid border-black border-opacity-30"
  >
    <div className="flex w-full items-center justify-between">
      <div>
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
          }}
          className="font-normal"
        >
          {title}
        </p>
      </div>
      {!isErrow && (
        <Icon type={TypesIcon.ARROWDOWN} width={px(8)} height={px(6)} viewBox="0 0 8 6" />
      )}
    </div>
  </div>
);

export default Dropdown;
