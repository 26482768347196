import { Button, Checkbox, Main } from '../../components';
import { px } from '../../constants/Px';
import React, { useState } from 'react';
import StyledInput from '../../components/styled-input';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { addCardPageOptions } from '../../constants/Component';

export const AddCardPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [card, setCard] = useState({
    cardNumber: '',
    cardsOwnerName: '',
    dataOfEnd: '',
    safeCode: '',
  });

  const addCard = (e, key) => {
    setCard((prevCard) => ({
      ...prevCard,
      [key]: e.target.value,
    }));
  };

  return (
    <Main title={`${isOpen ? 'Добавить карту' : 'Кредитные карты'}`} light lineHeight={32}>
      <div
        style={{
          paddingTop: `${px(isOpen ? 18 : 22)}`,
        }}
      >
        {!isOpen ? (
          <>
            <div
              className=' flex flex-row justify-between'
              style={{
                height: px(70),
              }}
            >
              <div className='flex flex-row items-center'>
                <Icon
                  type={TypesIcon.MIR}
                  viewBox='0 0 38 17'
                  width={px(38)}
                  height={px(17)}
                ></Icon>
                <div
                  style={{
                    paddingLeft: px(10),
                  }}
                >
                  <p
                    className='text-black opacity-50'
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                  >
                    Мир *8299
                  </p>
                  <p>12/23</p>
                </div>
              </div>
              <Checkbox selectCard />
            </div>
            <div
              className={`border-b-[1px] border-t-[1px] flex flex-row items-center border-solid  border-black border-opacity-30`}
              style={{
                height: px(70),
              }}
                 onClick={() => setIsOpen(!isOpen)}
            >
              <div
                className=' font-[100] flex justify-center items-center text-lg rounded-[50%] border-solid border-[1px] border-black border-opacity-30'
             
                style={{
                  height: px(31),
                  width: px(31),
                  fontSize: px(40),
                  lineHeight: px(48),
                }}
              >
                +
              </div>
              <p
                className='font-normal'
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  paddingLeft: px(21),
                }}
              >
                Добавить карту
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              marginBottom: px(10),
            }}
            key={addCardPageOptions[0]?.id}
          >
            <StyledInput
              onChange={(e) => addCard(e, addCardPageOptions[0]?.valueTitle)}
              value={card?.cardNumber}
              title={addCardPageOptions[0]?.title}
              placeholder={addCardPageOptions[0]?.placeholder}
              textOpacity={50}
              placeholderOpacity={50}

            />
          </div>
        )}
      </div>

      {isOpen &&
        addCardPageOptions.map(
          (item) =>
            item?.id !== 1 && (
              <div
                style={{
                  marginBottom: px(10),
                }}
                key={item?.id}
              >
                <StyledInput
                  onChange={(e) => addCard(e, item.valueTitle)}
                  value={card[item?.valueTitle]}
                  title={item?.title}
                  placeholder={item?.placeholder}
                  textOpacity={50}
                  placeholderOpacity={50}
                  bigTitle={item?.id===2}
                />
              </div>
            ),
        )}
      {isOpen && (
        <Button
          height={60}
          borderRadius={0}
          className='w-full '
          onClick={() => setIsOpen(!isOpen)}
        >
          Добавить карту
        </Button>
      )}
    </Main>
  );
};
