import React from 'react';

import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { px } from '../../utils';
import { CircleButton } from '../circle-button';

export const BackButton = ({ onClick, marker, circle }) => (
  <div style={{ height: px(60) }}>
    <div className={`z-[100] w-full max-w-[768px] ${circle ? 'absolute z-[2]' : 'fixed'}`}>
      <div
        aria-hidden
        className={`relative w-full cursor-pointer ${circle ? '' : 'bg-white'}`}
        style={{
          padding: circle ? '' : `${px(20)} ${px(0)} ${px(20)} ${px(15)}`,
          top: circle ? px(19) : '',
          left: circle ? px(19) : '',
        }}
        onClick={onClick}
      >
        {circle ? (
          <CircleButton
            onClick={onClick}
            icon={{
              type: TypesIcon.COLORBACK,
              viewBox: '0 0 11.6 19.76',
              width: px(11.6),
              height: px(19.76),
            }}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          />
        ) : (
          <Icon
            type={TypesIcon.COLORBACK}
            viewBox="0 0 11.6 19.76"
            width={px(11.6)}
            height={px(19.76)}
          />
        )}
        {marker && (
          <div className="absolute " style={{ top: px(0.9), right: px(17) }}>
            <Icon
              type={TypesIcon.MARKER}
              viewBox="0 0 25 37.357"
              width={px(25)}
              height={px(37.357)}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

export default BackButton;
