import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const PhoneInput = ({ handleChangePhone, phone, setPhone, error, disabled }) => {
  const [country, setCountry] = useState({
    name: 'Россия',
    code: 'ru',
    phoneCode: '+7',
    svg: TypesIcon.FLAGRUSSIAN,
  });
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const countryData = [
    { id: 1, name: 'Россия', phoneCode: '+7', svg: TypesIcon.FLAGRUSSIAN },
    { id: 2, name: 'Беларусь', phoneCode: '+375', svg: TypesIcon.FLAGBELARUS },
    { id: 3, name: 'Казахстан', phoneCode: '+7', svg: TypesIcon.KAZAKHSTAN },
  ];

  function setSelectedCountry(item) {
    setCountry(item);
    setPhone(item.phoneCode);
    setIsSelectOpen(false);
  }

  useEffect(() => {
    if (phone) {
      if (phone?.slice(0, 2) === '+3') {
        setCountry(countryData[1]);
      }
      if (phone?.slice(0, 2) === '+7') {
        setCountry(countryData[0]);
      }
    } else setPhone(country.phoneCode);
  }, []);

  return (
    <>
      <div
        className={`w-full    ${
          !disabled && isSelectOpen
            ? 'border-none'
            : error
              ? 'border-[1px]  border-solid border-[red]'
              : isFocus
                ? 'border-[1px]  border-solid border-[rgb(0,0,0,0.3)]'
                : 'border-[1px] border-solid border-[rgb(0,0,0,0.3)]'
        }`}
        style={{
          height: px(60),
          marginBottom: !disabled && isSelectOpen ? px(0) : px(10),
        }}
      >
        <div className={` flex w-full cursor-pointer flex-row  items-center  justify-between `}>
          <div
            aria-hidden
            className=" flex flex-row items-center  text-black"
            style={{
              paddingLeft: px(10),
              columnGap: px(10),
              paddingRight: px(10),
            }}
            onClick={() => setIsSelectOpen(!isSelectOpen)}
          >
            <Icon type={country.svg} viewBox="0 0 22 14.667" width={px(30)} height={px(24)} />

            <Icon type={TypesIcon.ARROWDOWN} width={px(8)} height={px(5.17)} viewBox="0 0 8 6" />
          </div>
          <InputMask
            mask={country.phoneCode === '+7' ? '+7 (999) 999-99-99' : '+375 (99) 999-99-99'}
            className="w-[90%] font-normal text-black outline-none disabled:bg-[transparent]"
            style={{
              fontSize: px(22),
              lineHeight: px(29),
              height: px(58),
            }}
            disabled={disabled}
            onChange={e => handleChangePhone(e)}
            value={phone}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
        </div>
      </div>
      {isSelectOpen && !disabled && (
        <div className="relative z-[1000]  h-[0px] bg-[green]">
          <div
            className=" flex-column absolute flex w-full "
            style={{
              height: px(210),
            }}
          >
            {countryData.map(item => (
              <div
                key={item?.id}
                className=" flex shrink-0  cursor-pointer justify-between border-b-[2px] border-solid border-black border-opacity-30  bg-white"
                onClick={item?.name === 'Россия' ? () => setSelectedCountry(item) : () => {}}
                style={{
                  height: px(70),
                  paddingTop: px(19),
                  paddingBottom: px(25),
                }}
              >
                <p
                  className={`${country.name == item.name ? 'font-bold' : 'font-normal'}`}
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                >
                  {item.name}
                </p>
                <div
                  className="flex flex-row"
                  style={{
                    columnGap: px(10),
                  }}
                >
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                  >
                    {item.phoneCode}
                  </p>
                  <Icon
                    type={item.svg}
                    viewBox="0 0 22 14.667"
                    width={px(30)}
                    height={px(24)}
                    onClick={() => setIsSelectOpen(!isSelectOpen)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PhoneInput;
