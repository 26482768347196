import React from 'react';

import { TypesIcon } from '../../../../../types/TypesIcon';
import { px } from '../../../../utils'; // TODO: alias
import { Icon } from '../../../icon';

export const ImageLoaderModalHeader = ({ title, isLine, dragControls, onBack, onNext }) => (
  <div
    style={{
      paddingTop: px(2),
    }}
    className="relative flex flex-col justify-center"
    onPointerDown={e => {
      dragControls?.start(e);
    }}
  >
    <div
      className="absolute flex w-full flex-row justify-between"
      style={{
        paddingLeft: px(15),
        paddingRight: px(15),
        top: px(10),
      }}
    >
      {onBack && (
        <Icon
          type={TypesIcon.ARROWLEFT}
          viewBox="0 0 21 14"
          width={px(21)}
          height={px(14)}
          onClick={onBack}
        />
      )}
      <div />
      {onNext && (
        <Icon
          type={TypesIcon.ARROWRIGHT}
          viewBox="0 0 21 14"
          width={px(21)}
          height={px(14)}
          onClick={onNext}
        />
      )}
    </div>
    <p
      style={{
        fontSize: px(20),
        lineHeight: px(26),
        paddingBottom: px(12),
      }}
      className="text-center font-light"
      onPointerDown={e => {
        dragControls?.start(e);
      }}
    >
      {title || ''}
    </p>
    {isLine && <hr className="w-full text-[#000] opacity-30" />}
  </div>
);

export default ImageLoaderModalHeader;
