import React from 'react';

import { FixTruncate } from './fix-truncate';

export const AddressLineFormat = ({ line, isTitle }) => {
  const lineClass = `${isTitle ? 'card__address-title' : 'card__address'} line-clamp-2`;

  if (!line) {
    return null;
  }

  const commonStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
    WebkitLineClamp: 1,
    hyphens: 'auto',
    height: '100%',
  };

  return isTitle ? (
    <p className="card__address-title line-clamp-2 opacity-100">
      <FixTruncate title={line} linesQuantity={1} spanClass="" />
    </p>
  ) : (
    <p className={lineClass} style={commonStyles}>
      {line}
    </p>
  );
};
