/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';

import { px } from '../../../../../../utils';
import { INITIAL_STATE } from '../../../../../constants';
import { useProfile } from '../../../../../hooks';
import {
  AlcoveIcon,
  AwningIcon,
  BathIcon,
  CageIcon,
  CanopyIcon,
  CottageIcon,
  DinghyIcon,
  FishingRodIcon,
  FishMatIcon,
  FloatIcon,
  FreeZoneIcon,
  GrillIcon,
  HotelIcon,
  HouseIcon,
  IceIcon,
  InstructorIcon,
  LandingNetIcon,
  LureIcon,
  ShoreIcon,
  SpinningIcon,
} from './icons';
import { BoatIcon } from './icons/boat';
import { IceText } from './icons/iceText';
import { SnowFlakeIcon } from './icons/snowflake';

const orderObject = unordered =>
  Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Sector = ({ sector, active, onClick, id }) => {
  const [promotion, setPromotion] = useState(0);
  const {
    data: { locations, selectedLocationId },
  } = useProfile();

  useEffect(() => {
    const promotionArr = locations
      ?.find(item => item.id === selectedLocationId)
      ?.promotions?.find(item => item.sections.find(i => i.id === id))?.rates;
    let promo = 0;
    if (promotionArr) {
      for (const pr of promotionArr) {
        if (pr.isCaught && pr.caughtValue && pr.caughtValue !== '0 %') {
          if (+pr.caughtValue.slice(0, -1) > promo) {
            promo = pr.caughtValue.slice(0, -1);
          }
        }
        if (pr.isWithNorm && pr.withNormValue && pr.withNormValue !== '0 %') {
          if (+pr.withNormValue.slice(0, -1) > promo) {
            promo = pr.withNormValue.slice(0, -1);
          }
        }
        if (pr.isWithoutNorm && pr.withoutNormValue && pr.withoutNormValue !== '0 %') {
          if (+pr.withoutNormValue.slice(0, -1) > promo) {
            promo = pr.withoutNormValue.slice(0, -1);
          }
        }
        if (pr.isHelp && pr.helpValue && pr.helpValue !== '0 %') {
          if (+pr.helpValue.slice(0, -1) > promo) {
            promo = pr.helpValue.slice(0, -1);
          }
        }
        if (pr.cost && pr.cost !== '0 %') {
          if (+pr.cost.slice(0, -1) > promo) {
            promo = pr.cost.slice(0, -1);
          }
        }
      }
    }

    setPromotion(promo);
  }, [JSON.stringify(locations), selectedLocationId]);

  const isDefault = useMemo(
    () =>
      JSON.stringify(orderObject(sector), (k, v) => (v === undefined ? [] : v)).replaceAll(
        '"',
        '',
      ) ===
      JSON.stringify(orderObject(INITIAL_STATE?.sectorFields), (k, v) =>
        v === undefined ? [] : v,
      ).replaceAll('"', ''),
    [JSON.stringify(sector)],
  );

  console.log(isDefault, sector);

  return (
    <div
      style={{
        width: px(90),
        height: px(90),
        borderRadius: px(15),
        border: active ? `${px(1)} solid #0A84FE` : `${px(1)} solid black`,
      }}
      className={`relative ${
        active ||
        isDefault ||
        (sector?.number === '' &&
          (!sector?.place || sector?.place.length === 0) &&
          !sector?.watersideObject &&
          !sector?.services)
          ? 'bg-[#FFFFFF]'
          : 'bg-[#212B35]'
      }`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {promotion !== 0 && (
        <div
          style={{
            left: px(-5),
            top: px(-5),
          }}
          className="absolute "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={px(21.622)}
            height={px(23.801)}
            viewBox="0 0 21.622 23.801"
          >
            <g id="Group_3198" data-name="Group 3198" transform="translate(-236.309 -665.069)">
              <g
                id="Group_2650"
                data-name="Group 2650"
                transform="translate(925.674 429.009) rotate(90)"
              >
                <g id="insignia" transform="translate(236.06 689.366) rotate(-90)">
                  <path
                    id="Union_24"
                    data-name="Union 24"
                    d="M10.255,17.454,0,23.8V0H21.078V23.8Z"
                    transform="translate(0 0)"
                    fill="red"
                  />
                </g>
              </g>
              <g
                id="Личная_информация"
                data-name="Личная информация"
                transform="translate(82.93 425.979)"
              >
                <text
                  id="_20"
                  data-name="20"
                  transform="translate(155 252)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="Helvetica-Bold, Helvetica"
                  fontWeight="700"
                >
                  <tspan x={`${promotion > 9 ? 0 : 3}`} y="0">
                    {promotion}
                  </tspan>
                </text>
                <text
                  id="_"
                  data-name="%"
                  transform="translate(166 252)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="Helvetica"
                >
                  <tspan x={`${promotion > 9 ? 0 : -3}`} y="0">
                    %
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
      )}
      {sector?.place?.includes?.('free') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <FreeZoneIcon
            style={{
              height: px(47),
              width: px(39),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.place?.includes?.('winterFromIce') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <IceIcon
            style={{
              width: px(35),
              height: px(32),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('boat') || sector?.services?.includes?.('myBoat') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <DinghyIcon
            style={{
              width: px(53),
              height: px(43),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('dinghy') || sector?.services?.includes?.('myDinghy') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <BoatIcon
            style={{
              width: px(57),
              height: px(25),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('fishMat') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <FishMatIcon
            style={{
              width: px(41),
              height: px(27),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('fishingRod') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <FishingRodIcon
            style={{
              width: px(33),
              height: px(41),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('spinning') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <SpinningIcon
            style={{
              width: px(30),
              height: px(31),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('landingNet') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <LandingNetIcon
            style={{
              width: px(47),
              height: px(47),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('cage') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <CageIcon
            style={{
              width: px(57),
              height: px(36),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('float') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <FloatIcon
            style={{
              width: px(39),
              height: px(37),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('instructor') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <InstructorIcon
            style={{
              width: px(54),
              height: px(37),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : sector?.services?.includes?.('grill') ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <GrillIcon
            style={{
              width: px(31),
              height: px(32),
              color: active ? '#000000' : '#FFFFFF',
            }}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              height: px(35),
              paddingLeft: px(5),
              paddingRight: px(sector?.number?.length === 1 ? 15 : 5),
            }}
            className="flex justify-between "
          >
            <div className="flex items-end">
              {sector?.watersideObject?.includes?.('cottage') ? (
                <CottageIcon
                  style={{
                    width: px(40),
                    height: px(27),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.watersideObject?.includes?.('bath') ? (
                <BathIcon
                  style={{
                    height: px(33),
                    width: px(36),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.watersideObject?.includes?.('safari') ? (
                <AwningIcon
                  style={{
                    width: px(33),
                    height: px(28),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.watersideObject?.includes?.('house') ||
                sector?.watersideObject?.includes?.('ecoHouse') ||
                sector?.watersideObject?.includes?.('trailer') ? (
                <HouseIcon
                  style={{
                    height: px(31),
                    width: px(27),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.watersideObject?.includes?.('alcove') ? (
                <AlcoveIcon
                  style={{
                    height: px(27),
                    width: px(35),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.watersideObject?.includes?.('tent') ? (
                <CanopyIcon
                  style={{
                    height: px(27),
                    width: px(35),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.watersideObject?.includes?.('hotel') ? (
                <HotelIcon
                  style={{
                    height: px(32),
                    width: px(32),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : sector?.services?.find?.(i => i?.startsWith?.('lure')) ? (
                <LureIcon
                  style={{
                    width: px(27),
                    height: px(24),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              ) : isDefault ||
                (sector?.number === '' &&
                  (!sector?.place || sector?.place.length === 0) &&
                  !sector?.watersideObject &&
                  !sector?.services) ? (
                <div />
              ) : (
                <ShoreIcon
                  style={{
                    width: px(31),
                    height: px(29),
                    color: active ? '#000000' : '#FFFFFF',
                  }}
                />
              )}
            </div>

            <p
              style={{
                fontSize: px(
                  sector?.number?.length === 3 ? 20 : sector?.number?.length > 3 ? 15 : 32,
                ),
                lineHeight: px(
                  sector?.number?.length === 3 ? 26 : sector?.number?.length > 3 ? 20 : 43,
                ),
                paddingTop: px(sector?.number?.length >= 3 ? 10 : 0),
              }}
              className={`${active ? 'text-[#000000]' : 'text-[#FFFFFF]'} font-normal opacity-50`}
            >
              {sector?.number}
            </p>
          </div>
          <div className="relative">
            {sector?.direction?.includes('winter') && (
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-[#FFFFFF]">
                <div style={{ marginLeft: px(4) }}>
                  <SnowFlakeIcon
                    style={{
                      width: px(14),
                      height: px(14),
                      color: '#FFFFFF',
                    }}
                  />
                </div>
                <div className="absolute top-1/2 -translate-y-1/2" style={{ right: px(-6) }}>
                  <IceText
                    style={{
                      width: px(24),
                      height: px(14),
                      color: '#FFFFFF',
                    }}
                  />
                </div>
              </div>
            )}
            {locations?.find(location => location.id === selectedLocationId)?.name !==
              'Общая территория' &&
              locations?.find(location => location.id === selectedLocationId)?.name !==
                'Услуги' && (
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                  className={`${
                    active ? 'text-[#000000]' : 'text-[#FFFFFF]'
                  } text-center font-normal opacity-50`}
                >
                  сектор
                </p>
              )}
          </div>
        </>
      )}

      {sector?.price && (
        <p
          style={{
            bottom: px(9),
            fontSize: px(20),
            lineHeight: px(26),
          }}
          className={`absolute w-full text-center font-normal ${
            sector?.discount ? 'text-[#FF0000]' : 'text-[#0A84FE]'
          }`}
        >
          {sector?.price}
        </p>
      )}
    </div>
  );
};

export default Sector;
