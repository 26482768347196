import React from 'react';

export const StyledVideo = ({ src, captionSrc, captionLabel }) =>
  src && (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video preload="yes" controls loop width="100%" height="auto">
      <source src={`${src}#t=0.1`} type="video/mp4" />
      {captionSrc && <track kind="captions" src={captionSrc} label={captionLabel} />}
    </video>
  );

export default StyledVideo;
