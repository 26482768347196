/* eslint-disable no-shadow */
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { DatePicker } from '../../../../../components';
import { Icon } from '../../../../../components/Icon';
import { StyledInput } from '../../../../../components/styled-input';
import { AreaAndDistrictOptions, timeDate } from '../../../../../constants/Component';
import { addLeadingZeros } from '../../../../../constants/Function';
import { schemaObjectSelector } from '../../../../../selectors/get-event';
import { TypesIcon } from '../../../../../types/TypesIcon';
import {
  BackButton,
  CircleButton,
  DynamicImageSlider,
  ScrollButtonsContainer,
  Textarea,
} from '../../../../shared';
import { px } from '../../../../utils';
import { useProfile } from '../../../hooks';
import { getInitialFields, mapObjectToFields } from './helpers/object.helpers';
import { updateProfile } from './services/object.service';

const PHONE_TIMES = {
  mainPhoneStartTime: 'mainPhoneStartTime',
  mainPhoneEndTime: 'mainPhoneEndTime',
  secondaryPhoneStartTime: 'secondaryPhoneStartTime',
  secondaryPhoneEndTime: 'secondaryPhoneEndTime',
};

export const ObjectForm = ({ object }) => {
  const [fields, setFields] = useState();
  const [id, setId] = useState();
  const [isPeriod, setIsPeriod] = useState('');
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [schemasIndex, setSchemasIndex] = useState(0);

  const [showClearButton, setShowClearButton] = useState(false);
  const scrollValue = useRef(0);

  const schemas = useSelector(schemaObjectSelector);

  useLayoutEffect(() => {
    setFields(mapObjectToFields(object));
  }, [JSON.stringify(object)]);

  useEffect(() => {
    const scrollHandler = throttle(() => {
      setShowClearButton(
        JSON.stringify(getInitialFields(object)) !== JSON.stringify(fields) &&
          !(window.scrollY > scrollValue.current && scrollValue.current > 500),
      );
      scrollValue.current = window.scrollY;
    }, 200);

    scrollHandler();

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [fields, object]);

  const {
    updateFunction: updateProfileFunction,
    data: { object: profileObject },
  } = useProfile();

  const navigate = useNavigate();

  const optionAreaDistrictFilter = (id, country) => {
    if (country === '') {
      return [];
    }
    return id === 2 && country === 'Россия'
      ? AreaAndDistrictOptions.russiaDistricts
      : id === 3 && country === 'Россия'
        ? AreaAndDistrictOptions.regionsAndAreas[fields?.contacts?.region] || []
        : id === 3 && country === 'Беларусь'
          ? AreaAndDistrictOptions.belarusAreas
          : AreaAndDistrictOptions.kazachstanAreas;
  };

  const openPicker = (id_, label) => {
    setId(id_);
    setIsPeriod(label);
    setShow(true);
  };

  const setPhoneTime = (fieldName, value) => {
    setFields(prevFields => ({
      ...prevFields,
      contacts: {
        ...prevFields.contacts,
        [fieldName]: value?.hour,
      },
    }));
  };

  const setPikerValue = value => {
    if (id === 6) {
      if (isPeriod === 'start') {
        setPhoneTime(PHONE_TIMES.mainPhoneStartTime, value);
        return;
      }
      if (isPeriod === 'end') {
        setPhoneTime(PHONE_TIMES.mainPhoneEndTime, value);
        return;
      }
    }
    if (id === 7) {
      if (isPeriod === 'start') {
        setPhoneTime(PHONE_TIMES.secondaryPhoneStartTime, value);
        return;
      }
      if (isPeriod === 'end') {
        setPhoneTime(PHONE_TIMES.secondaryPhoneEndTime, value);
        // eslint-disable-next-line no-useless-return
        return;
      }
    }
  };

  return (
    <>
      <DatePicker
        singleItem
        title="Время. Период"
        setShow={setShow}
        show={show}
        data={timeDate}
        setPickerValue={setPikerValue}
        pickerValue={{
          hour:
            id === 6 && isPeriod === 'start'
              ? fields?.contacts?.mainPhoneStartTime
              : id === 6 && isPeriod === 'end'
                ? fields?.contacts?.mainPhoneEndTime
                : id === 7 && isPeriod === 'start'
                  ? fields?.contacts?.secondaryPhoneStartTime
                  : id === 7 && isPeriod === 'end'
                    ? fields?.contacts?.secondaryPhoneEndTime
                    : '',
        }}
        buttonTitle="Применить"
        isLine
      />
      <BackButton
        onClick={async () => {
          if (!isLoading) {
            setIsLoading(true);
            await updateProfile(dispatch, object, fields);
            await updateProfileFunction();
            setIsLoading(false);
            navigate(`/user-object`);
          }
        }}
      />
      <div
        style={{
          paddingBottom: px(80),
          paddingTop: px(25),
        }}
      >
        <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
          <p
            style={{
              fontSize: px(32),
              lineHeight: px(32),
            }}
            className="whitespace-pre-wrap  font-light"
          >
            Название. Фото. Описание объекта
          </p>
          <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
            Работа с сервисом будет возможна, если Вы заполните все формы
          </p>
          <Textarea
            placeholder="Введите текст"
            title="Название"
            noEnter
            style={{
              height: px(114),
              marginTop: px(26),
              marginBottom: px(10),
              paddingTop: px(6),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })}
          />
          <Textarea
            placeholder="Введите текст"
            title="Описание"
            textareaHight={px(280)}
            style={{
              height: px(330),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(14),
              paddingBottom: px(14),
            }}
            value={fields?.description}
            onChange={value => setFields({ ...fields, description: value })}
          />
          <div className="flex flex-col" style={{ marginTop: px(25), marginBottom: px(26.26) }}>
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Логотип. Открытка, …
            </p>
            <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
              Редактируйте по своему усмотрению
            </p>
          </div>
          <div
            className="flex flex-row items-center"
            style={{ gap: px(16.86), marginTop: px(33), marginBottom: px(26.26) }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 24"
              width={px(31.14)}
              height={px(27.74)}
            />

            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Логотип. Открытка, …
            </p>
          </div>
          <div
            style={{
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <DynamicImageSlider
              items={[
                {
                  label: 'Логотип',
                  key: 'logo',
                  src: fields?.attachments?.find?.(att => att?.key === 'logo')?.src,
                  crop: {
                    width: 130,
                    height: 130,
                    style: {
                      borderRadius: px(15),
                    },
                  },
                },
                {
                  label: 'Открытка',
                  key: 'postcard',
                  src: fields?.attachments?.find?.(att => att?.key === 'postcard')?.src,
                  crop: {
                    width: 141,
                    height: 110,
                  },
                },
                {
                  label: 'Обложка',
                  key: 'banner',
                  src: fields?.attachments?.find?.(att => att?.key === 'banner')?.src,
                  crop: {
                    width: 320,
                    height: 183,
                  },
                },
                {
                  label: 'Предложения. Игра',
                  key: 'game',
                  src: fields?.attachments?.find?.(att => att?.key === 'game')?.src,
                  crop: {
                    width: 141,
                    height: 110,
                    style: {
                      borderRadius: `${px(15)} ${px(15)} 0 0`,
                    },
                  },
                },
                {
                  label: 'Предложения. Отдых',
                  key: 'recreation',
                  src: fields?.attachments?.find?.(att => att?.key === 'recreation')?.src,
                  crop: {
                    width: 141,
                    height: 110,
                    style: {
                      borderRadius: `${px(15)} ${px(15)} 0 0`,
                    },
                  },
                },
                {
                  label: 'Предложения. Услуги',
                  key: 'service',
                  src: fields?.attachments?.find?.(att => att?.key === 'service')?.src,
                  crop: {
                    width: 141,
                    height: 110,
                    style: {
                      borderRadius: `${px(15)} ${px(15)} 0 0`,
                    },
                  },
                },
              ]}
              onChange={files => setFields({ ...fields, attachments: files })}
            />
          </div>

          <div
            className="flex flex-row items-center"
            style={{ gap: px(16.86), marginTop: px(26), marginBottom: px(23.26) }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 24"
              width={px(31.14)}
              height={px(27.74)}
            />

            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Галерея
            </p>
          </div>

          <div
            style={{
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <DynamicImageSlider
              items={fields?.gallery?.map(({ src, first }) => ({ src, first: !!first }))}
              onChange={files => setFields({ ...fields, gallery: files })}
            />
          </div>

          <p
            className="whitespace-pre-wrap font-light"
            style={{ fontSize: px(32), lineHeight: px(32), marginTop: px(45) }}
          >
            Условия
            <br />и правила
          </p>
          <p
            className="whitespace-pre-wrap font-normal"
            style={{ fontSize: px(15), lineHeight: px(20), marginTop: px(6) }}
          >
            Необходимые нормативы
            <br />
            поведения на вашем объекте
          </p>

          <Textarea
            placeholder="Введите текст"
            title="Основные положения"
            textareaHight={px(80)}
            style={{
              height: px(130),
              marginTop: px(20),
              marginBottom: px(40),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(33),
              paddingBottom: px(22),
            }}
            value={fields?.termsAndRules}
            onChange={value => setFields({ ...fields, termsAndRules: value })}
          />

          <p
            style={{
              fontSize: px(32),
              lineHeight: px(43),
            }}
            className="whitespace-pre-wrap  font-light"
          >
            Карты. Схемы. …
          </p>
          <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
            Дополнительные материалы
            <br />
            улучшат привлекательность и сервис
          </p>

          <div
            style={{
              marginTop: px(20),
              marginBottom: px(43),
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <DynamicImageSlider
              items={schemas?.map(schema => ({
                src: schema.src,
              }))}
              onEdit={() => {
                setFields(prevFields => {
                  updateProfile(dispatch, object, prevFields);
                  updateProfileFunction();
                  navigate(`/schemas`, { state: { object, fields: prevFields, schemasIndex } });
                  return prevFields;
                });
              }}
              onAdd={async () => {
                setFields(prevFields => {
                  updateProfile(dispatch, object, prevFields);
                  updateProfileFunction();
                  navigate(`/schemas`, { state: { object, fields: prevFields, schemasIndex: -1 } });
                  return prevFields;
                });
              }}
              crop={{
                width: 141,
                height: 216,
              }}
              onSwipe={setSchemasIndex}
            />
          </div>
          <div className="relative">
            <div id="contacts" className="absolute" style={{ top: px(-60) }} />
          </div>
          <p
            className="whitespace-pre-wrap font-light"
            style={{ fontSize: px(32), lineHeight: px(43) }}
          >
            Контакты
          </p>
          <p
            className="whitespace-pre-wrap font-normal"
            style={{ fontSize: px(15), lineHeight: px(20) }}
          >
            Необходимые нормативы
            <br />
            поведения на вашем объекте
          </p>
          <form
            style={{
              paddingBottom: px(65),
              gap: px(10),
              marginTop: px(18),
            }}
            className="flex flex-col"
          >
            <StyledInput
              isBorder
              id={1}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.country}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: {
                    ...fields.contacts,
                    country: e.target.value,
                    region: '',
                    district: '',
                  },
                })
              }
              options={['Россия', 'Беларусь', 'Казахстан']}
              title="Страна"
              textOpacity={50}
              placeholder="Выбор из списка"
              placeholderOpacity
            />
            <StyledInput
              isBorder
              id={2}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.region}
              onChange={e => {
                const newFields = {
                  ...fields,
                  contacts: {
                    ...fields.contacts,
                    region: e.target.value,
                    district: e.target.value ? fields.contacts.district : '',
                  },
                };
                setFields(newFields);
              }}
              options={optionAreaDistrictFilter(2, fields?.contacts?.country)}
              title="Регион"
              textOpacity={50}
              placeholder="Выбор из списка"
              placeholderOpacity
              disabled={
                fields?.contacts?.country === 'Беларусь' ||
                fields?.contacts?.country === 'Казахстан' ||
                !fields?.contacts?.country
              }
            />
            <StyledInput
              isBorder
              id={3}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.district}
              onChange={e =>
                setFields({ ...fields, contacts: { ...fields.contacts, district: e.target.value } })
              }
              options={optionAreaDistrictFilter(3, fields?.contacts?.country)}
              title="Область"
              textOpacity={50}
              placeholder="Выбор из списка"
              placeholderOpacity
              disabled={
                !fields?.contacts?.country ||
                (fields?.contacts?.country === 'Россия' && !fields?.contacts?.region)
              }
            />
            <StyledInput
              isBorder
              id={4}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.town}
              onChange={e =>
                setFields({ ...fields, contacts: { ...fields.contacts, town: e.target.value } })
              }
              title="Город. Поселок"
              textOpacity={50}
              placeholder=""
              placeholderOpacity
            />
            <StyledInput
              isBorder
              id={5}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.street}
              onChange={e =>
                setFields({ ...fields, contacts: { ...fields.contacts, street: e.target.value } })
              }
              title="Улица,дом, корпус, ..."
              textOpacity={50}
              placeholder=""
              placeholderOpacity
            />
            <StyledInput
              isBorder
              id={6}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.mainPhone}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, mainPhone: e.target.value },
                })
              }
              title="Телефон 1"
              textOpacity={50}
              placeholder={
                fields?.contacts?.country === 'Беларусь'
                  ? '+375 (XX) XXX XX XX'
                  : '+7 (XXX) XXX XX XX'
              }
              placeholderOpacity
              phoneInput
              bigTitle
              withDatePicker
              timePhone={{ hour: fields?.contacts?.mainPhoneStartTime }}
              endTimePhone={{ hour: fields?.contacts?.mainPhoneEndTime }}
              openPicker={openPicker}
            />
            <StyledInput
              isBorder
              id={7}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.secondaryPhone}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, secondaryPhone: e.target.value },
                })
              }
              title="Телефон 2"
              textOpacity={50}
              placeholder={
                fields?.contacts?.country === 'Беларусь'
                  ? '+375 (XX) XXX XX XX'
                  : '+7 (XXX) XXX XX XX'
              }
              placeholderOpacity
              phoneInput
              bigTitle
              withDatePicker
              timePhone={{ hour: fields?.contacts?.secondaryPhoneStartTime }}
              endTimePhone={{ hour: fields?.contacts?.secondaryPhoneEndTime }}
              openPicker={openPicker}
            />
            <StyledInput
              isBorder
              id={8}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.email}
              onChange={e =>
                setFields({ ...fields, contacts: { ...fields.contacts, email: e.target.value } })
              }
              title="Почта электронная"
              textOpacity={50}
              placeholder="XXXX@XXX..."
              placeholderOpacity
              letEmailValidation
            />
            <StyledInput
              isBorder
              id={9}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.whatsAppLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, whatsAppLink: e.target.value },
                })
              }
              title="Ссылка на чат WhatsApp"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={10}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.telegramLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, telegramLink: e.target.value },
                })
              }
              title="Ссылка на чат в Telegram"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={11}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.viberLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, viberLink: e.target.value },
                })
              }
              title="Ссылка на чат в Viber"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={12}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.coordinates}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, coordinates: e.target.value },
                })
              }
              title="Геолокация. Координаты"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={13}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.socialPageLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, socialPageLink: e.target.value },
                })
              }
              title="Страница в соц. сети"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={14}
              country={fields?.contacts?.country || 'Россия'}
              value={fields?.contacts?.websiteLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contacts: { ...fields.contacts, websiteLink: e.target.value },
                })
              }
              title="Web-страница сайта"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              id={15}
              country={fields?.contacts?.country || 'Россия'}
              value={`ID: ${profileObject?.id}`}
              onChange={() => {}}
              title="ID кабинета"
              textOpacity={50}
              placeholder=""
              bigTitle
              placeholderOpacity
              disabled
            />
            <StyledInput
              id={16}
              country={fields?.contacts?.country || 'Россия'}
              value={`ID: ${addLeadingZeros(object?.createdById)}`}
              onChange={() => {}}
              title="ID кабинета Управляющего"
              textOpacity={50}
              placeholder=""
              bigTitle
              placeholderOpacity
              disabled
            />
          </form>
          <ScrollButtonsContainer>
            <CircleButton
              onClick={() => setFields(getInitialFields(object))}
              icon={{
                type: TypesIcon.ROUNDEDRETURN,
                viewBox: '0 0 28.451 26.164',
                width: px(28.451),
                height: px(26.164),
                className: 'text-[#0a84fe]',
                fill: '#0a84fe',
              }}
            />
          </ScrollButtonsContainer>
        </div>
      </div>
    </>
  );
};
export default ObjectForm;
