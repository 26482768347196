/* eslint-disable no-shadow */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeAllowGroup } from '../../../../../actions/allow-group/thunks';
// TODO: move to new arch (everything) and alias
import { Checkbox, CustomAlert } from '../../../../../components';
import { Icon } from '../../../../../components/Icon';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { Button, CircleButton, Dropdown, Picker, ScrollButtonsContainer } from '../../../../shared';
import { px } from '../../../../utils';
import { useProfile } from '../../../hooks';
import { GroupModal } from './components';
import { AccessGroup } from './components/access-group';

export const Access = ({ locationCategory }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(locationCategory || 'REST');
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showGroupPicker, setShowGroupPicker] = useState(false);
  const [groups, setGroups] = useState([]);
  const [id, setId] = useState();
  const [alert, setAlert] = useState(false);
  const {
    data: { locations, selectedLocationId, selectedSector },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const selectedLocation = useMemo(
    () => locations?.find(location => location.id === selectedLocationId) || locations[0],
    [locations, selectedLocationId],
  );

  const selectedSectorItem = useMemo(
    () => selectedLocation?.sections?.find(item => item?.id === selectedSector),
    [selectedLocation, selectedSector],
  );

  const setSelectedSector = item =>
    setProfile({
      selectedSector: item,
    });

  const disabledButton = useMemo(
    () => selectedLocation?.sections?.every(section => section.sectionAllowanceGroupId),

    [selectedLocation],
  );

  const handleMoveSector = async (data, sectionId) => {
    await dispatch(changeAllowGroup(data, sectionId));
    updateProfile();
  };

  const isSectorFree = useMemo(
    () =>
      selectedSectorItem?.place[0]?.name === 'free' ||
      selectedSectorItem?.place[1]?.name === 'free' ||
      selectedSectorItem?.place[1]?.name === 'winterFromIce' ||
      selectedSectorItem?.place[0]?.name === 'winterFromIce',
    [locations, selectedLocationId, selectedSector],
  );

  useEffect(
    () =>
      setProfile({
        selectedLocationId: locations?.find(l => l?.type === type)?.id,
      }),
    [type],
  );

  return (
    <div style={{ overflowY: 'auto', height: '100%', paddingBottom: px(30) }}>
      <p
        className="text-center"
        style={{
          paddingTop: px(5),
          marginBottom: px(22),
        }}
      >
        Допуск. Условия
      </p>
      <div className="container flex justify-between" style={{ marginBottom: px(20) }}>
        <div
          style={{
            width: px(90),
            height: px(222),
            border: '1px solid black',
            borderRadius: px(15),
            backgroundColor: '#082341',
            paddingTop: px(20),
            paddingBottom: px(10),
            textAlign: 'center',
          }}
        >
          <div
            style={{
              paddingRight: px(19.9),
              paddingLeft: px(19.9),
              height: px(43),
              marginBottom: px(12),
            }}
          >
            <Icon
              type={TypesIcon.BIGFISH}
              viewBox="0 0 50.226 22.966"
              width={px(50.226)}
              height={px(22.966)}
            />
          </div>
          <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>МОСТИК</p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            НАВЕС
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ДОМИК
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            FREE ZONE
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ...
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              color: 'white',
              marginTop: px(10),
              marginBottom: px(10),
            }}
          >
            РЫБАЛКА
          </p>
          <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
            <Checkbox
              checked={type === 'FISHING'}
              onChange={() => {
                if (locations?.filter(l => l?.type === 'FISHING')?.length > 0) {
                  setType('FISHING');
                  setProfile({
                    selectedLocationId: locations?.find(l => l?.type === 'FISHING')?.id,
                  });
                }
              }}
            />
          </div>
        </div>

        <div
          style={{
            width: px(90),
            height: px(222),
            border: '1px solid black',
            borderRadius: px(15),
            backgroundColor: '#082341',
            paddingTop: px(20),
            paddingBottom: px(10),
            textAlign: 'center',
          }}
        >
          <div
            style={{
              paddingRight: px(36),
              paddingLeft: px(36),
              height: px(43),
              marginBottom: px(12),
            }}
          >
            <Icon
              type={TypesIcon.RELAX}
              viewBox="0 0 17.987 42.916"
              width={px(17.987)}
              height={px(42.916)}
            />
          </div>
          <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ОТЕЛЬ</p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            КОТТЕДЖ
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            БЕСЕДКА
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            БАНЯ, САУНА
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ...
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              color: 'white',
              marginTop: px(10),
              marginBottom: px(10),
            }}
          >
            ОТДЫХ
          </p>
          <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
            <Checkbox
              checked={type === 'REST'}
              onChange={() => {
                setType('REST');
                setProfile({
                  selectedLocationId: locations?.find(l => l?.type === 'REST')?.id,
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: px(90),
            height: px(222),
            border: '1px solid black',
            borderRadius: px(15),
            backgroundColor: '#082341',
            paddingTop: px(20),
            paddingBottom: px(10),
            textAlign: 'center',
          }}
        >
          <div
            style={{
              paddingRight: px(16.5),
              paddingLeft: px(21.1),
              height: px(43),
              marginBottom: px(12),
            }}
          >
            <Icon
              type={TypesIcon.BOAT}
              viewBox="0 0 52.429 42.517"
              width={px(52.429)}
              height={px(42.517)}
            />
          </div>
          <p style={{ fontSize: px(10), lineHeight: px(14), color: 'white' }}>ЛОДКА</p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            СНАСТИ
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ПРИКОРМКА
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ОБОРУДОВАНИЕ
          </p>
          <p
            style={{
              fontSize: px(10),
              lineHeight: px(14),
              color: 'white',
              marginTop: px(-1),
            }}
          >
            ...
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              color: 'white',
              marginTop: px(10),
              marginBottom: px(10),
            }}
          >
            УСЛУГИ
          </p>
          <div style={{ display: 'inline-block', paddingLeft: px(10) }}>
            <Checkbox
              checked={type === 'SERVICE'}
              onChange={() => {
                setType('SERVICE');
                setProfile({
                  selectedLocationId: locations?.find(l => l?.type === 'SERVICE')?.id,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="container" style={{ marginBottom: px(3) }}>
        <div
          style={{
            width: px(90),
            height: px(90),
            border: '1px solid black',
            borderRadius: px(15),
            paddingTop: px(15),
            paddingBottom: px(15),
            paddingLeft: px(12),
            paddingRight: px(12),
          }}
        >
          <div className="flex justify-center" style={{ opacity: 0.5, marginBottom: px(4.4) }}>
            <Icon
              type={TypesIcon.ACCESS}
              viewBox="0 0 24.703 26.238"
              width={px(24.703)}
              height={px(26.238)}
              fill="#000000"
            />
          </div>
          <p
            style={{
              opacity: 0.5,
              textAlign: 'center',
              fontSize: px(20),
              lineHeight: px(27),
            }}
          >
            Допуск
          </p>
        </div>
      </div>

      <div className="container" style={{ marginBottom: px(22) }}>
        <Dropdown
          key={selectedLocationId}
          items={locations?.map(({ name: label, id: value }) => ({ label, value }))}
          // eslint-disable-next-line unicorn/consistent-destructuring
          value={selectedLocation?.id}
          onChange={id => {
            setProfile({ selectedLocationId: locations?.find(i => i?.id === id)?.id });
            setType(locations?.find(i => i?.id === id)?.type);
          }}
        />
      </div>

      {locations &&
        selectedLocation.allowanceSectionGroups
          ?.slice()
          .sort((a, b) => a.id - b.id)
          .map(item => (
            <AccessGroup
              type={type}
              key={item.id}
              data={item}
              sectors={selectedLocation.sections?.filter(
                it => it.sectionAllowanceGroupId === item.id,
              )}
            />
          ))}
      <div className="container" style={{ marginBottom: px(80) }}>
        <Button
          marginTop={0}
          borderRadius={0}
          height={60}
          border={disabledButton}
          fontSize={19}
          bgColor={disabledButton ? 'white' : '#0A84FE'}
          color={disabledButton ? 'black' : 'white'}
          bgOpacityDisabled={1}
          className="w-full"
          onClick={() => setShowGroupModal(true)}
          disabled={disabledButton}
        >
          {disabledButton ? 'Нет свободных для выбора' : 'Создать новую группу'}
        </Button>
      </div>

      {showGroupModal && (
        <GroupModal show={showGroupModal} onClose={() => setShowGroupModal(false)} />
      )}

      {selectedSector && (
        <ScrollButtonsContainer>
          <CircleButton
            onClick={() => {
              if (isSectorFree) setAlert(true);
              else setShowGroupPicker(true);
            }}
            icon={{
              type: TypesIcon.MOVETOANOTHERGROUP,
              viewBox: '0 0 26.215 30.616',
              width: px(26.215),
              height: px(30.616),
            }}
          />
          <CircleButton
            onClick={async () => {
              await handleMoveSector({
                data: {
                  connect: {
                    sectionIds: [],
                  },
                  disconnect: {
                    sectionIds: [selectedSector],
                  },
                },
                id: selectedSectorItem?.sectionAllowanceGroupId,
              });
              setProfile({ selectedSector: undefined });
            }}
            icon={{
              type: TypesIcon.SMALLBASKET,
              viewBox: '0 0 20.5 25.231',
              width: px(20.5),
              height: px(25.231),
            }}
          />
        </ScrollButtonsContainer>
      )}
      <Picker
        group
        singleItem
        title="Группа"
        show={showGroupPicker}
        onClick={async () => {
          if (
            selectedLocation?.sections?.find(item => item?.accessGroup?.id === id)?.place[0]
              ?.name === 'free' ||
            selectedLocation?.sections?.find(item => item?.accessGroup?.id === id)?.place[1]
              ?.name === 'free' ||
            selectedLocation?.sections?.find(item => item?.accessGroup?.id === id)?.place[0]
              ?.name === 'winterFromIce' ||
            selectedLocation?.sections?.find(item => item?.accessGroup?.id === id)?.place[1]
              ?.name === 'winterFromIce'
          )
            setAlert(true);
          else {
            await handleMoveSector({
              data: {
                connect: {
                  sectionIds: [],
                },
                disconnect: {
                  sectionIds: [selectedSector],
                },
              },
              id: selectedSectorItem?.sectionAllowanceGroupId,
            });
            await handleMoveSector({
              data: {
                connect: {
                  sectionIds: [selectedSector],
                },
                disconnect: {
                  sectionIds: [],
                },
              },
              id,
            });
            setProfile({ selectedSector: undefined });
          }
        }}
        onClose={() => setShowGroupPicker(false)}
        data={{
          groups: selectedLocation?.allowanceSectionGroups
            .map(item => item.groupNumber)
            // eslint-disable-next-line unicorn/prefer-spread
            .slice()
            .sort(),
        }}
        onChange={({ groups }) => {
          setId(
            selectedLocation?.allowanceSectionGroups?.find(item => item.groupNumber === groups)?.id,
          );
          setGroups(groups);
        }}
        value={{
          groups,
        }}
        button
        isLine
      />
      {alert && (
        <CustomAlert
          onClickButtonSubmit={() => setAlert(false)}
          title="FREE ZONE и ЛЕД не могут состоять в группах. Только индивидуально"
          single
          buttonRightText="Ок"
        />
      )}
    </div>
  );
};

export default Access;
