import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';
import { addModal } from '../../modal/addModal';

export const UPDATE_PROFILE = 'profile/updateProfile';

const onFail = (error) => async (dispatch) => {
  await dispatch(
    addModal({
      id: 12,
      data: {
        title: 'Проверьте правильность введенных данных',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

export const updateProfile = createAsyncThunk(
  UPDATE_PROFILE,
  createRequest({
    type: UPDATE_PROFILE,
    loader: (data) => authApi.updateProfile(data),
    onFail,
  }),
);
