import { createReducer } from '@reduxjs/toolkit';

import { addNews } from '../../actions/news';

export const news = createReducer({}, builder => {
  builder.addCase(addNews.fulfilled, (state, { payload }) => ({
    ...state,
    news: payload?.data,
  }));
});
