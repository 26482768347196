/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { createGroup } from '../../../../../../../actions/allow-group/thunks';
import { createDiscountGroup } from '../../../../../../../actions/section-discount/thunks';
import { createSectionGroup } from '../../../../../../../actions/section-group/thunks/create-group';
import { createPromotionGroup } from '../../../../../../../actions/section-promotion';
import { CustomAlert, SectorModal } from '../../../../../../../components';
import { TypesIcon } from '../../../../../../../types/TypesIcon';
import { CircleButton, Modal, ScrollButtonsContainer } from '../../../../../../shared';
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';

export const GroupModal = ({ options, price, onChange, onClose, ...props }) => {
  const dispatch = useDispatch();
  /* const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (onChange) onChange(selectedOption);
  }, [selectedOption]); */
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [hideIfInAGroup, setHideIfInAGroup] = useState(false);
  const [alert, setAlert] = useState(false);
  const [disabledButtonSubmit, setDisabledButtonSubmit] = useState(false);
  const {
    data: { locations, selectedLocationId },
    updateFunction: updateProfile,
  } = useProfile();

  const handleComponentClick = item => {
    if (selectedItems.includes(item))
      setSelectedItems([
        ...selectedItems.slice(0, selectedItems.indexOf(item)),
        ...selectedItems.slice(selectedItems.indexOf(item) + 1),
      ]);
    else setSelectedItems([...selectedItems, item]);
  };

  const handleSelectedAll = () => {
    if (selectedAll) {
      setSelectedItems([]);
      setSelectedAll(false);
    } else {
      const allId = locations
        ?.filter(item => item.id === selectedLocationId)[0]
        .sections?.map(item => item.id);
      setSelectedItems(allId);
      setSelectedAll(true);
    }
  };

  const isIncludedFree = useMemo(
    () =>
      selectedItems.some(item =>
        locations
          ?.find(item_ => item_?.id === selectedLocationId)
          ?.sections?.filter(it => it?.place[0]?.name === 'free')
          .map(f => f.id)
          .includes(item),
      ) ||
      selectedItems.some(item =>
        locations
          ?.find(item_ => item_?.id === selectedLocationId)
          ?.sections?.filter(it => it?.place[1]?.name === 'free')
          .map(f => f.id)
          .includes(item),
      ) ||
      selectedItems.some(item =>
        locations
          ?.find(item_ => item_?.id === selectedLocationId)
          ?.sections?.filter(it => it?.place[0]?.name === 'winterFromIce')
          .map(f => f.id)
          .includes(item),
      ) ||
      selectedItems.some(item =>
        locations
          ?.find(item_ => item_?.id === selectedLocationId)
          ?.sections?.filter(it => it?.place[1]?.name === 'winterFromIce')
          .map(f => f.id)
          .includes(item),
      ),
    [selectedItems, locations, selectedLocationId],
  );

  return (
    <Modal {...props}>
      <div className="fixed left-0 top-0 z-50 h-[100%] w-full overflow-y-auto bg-white">
        <SectorModal
          subTitle="Выбор"
          alternativeTitle="Рыбалка"
          dropdownTitle="Выберите сектор"
          selectedItems={selectedItems}
          handleNavigateBack={() => onClose()}
          disabled={
            disabledButtonSubmit ||
            selectedItems.length === 0 ||
            (price === 'price'
              ? locations
                  ?.filter(item => item.id === selectedLocationId)[0]
                  ?.sections.filter(section => selectedItems.includes(section.id))
                  .some(section => section?.priceId)
              : price === 'action'
                ? locations
                    ?.filter(item => item.id === selectedLocationId)[0]
                    ?.sections.filter(section => selectedItems.includes(section.id))
                    .some(section => section?.promotionId)
                : price === 'discount'
                  ? locations
                      ?.filter(item => item.id === selectedLocationId)[0]
                      ?.sections.filter(section => selectedItems.includes(section.id))
                      .some(section => section?.discountId)
                  : locations
                      ?.filter(item => item.id === selectedLocationId)[0]
                      ?.sections.filter(section => selectedItems.includes(section.id))
                      .some(section => section?.sectionAllowanceGroupId))
          }
          data={
            price === 'price'
              ? hideIfInAGroup
                ? locations
                    ?.filter(item => item.id === selectedLocationId)[0]
                    ?.sections.filter(section => !section?.priceId)
                : locations?.filter(item => item.id === selectedLocationId)[0]?.sections
              : price === 'action'
                ? hideIfInAGroup
                  ? locations
                      ?.filter(item => item.id === selectedLocationId)[0]
                      ?.sections.filter(section => !section?.promotionId)
                  : locations?.filter(item => item.id === selectedLocationId)[0]?.sections
                : price === 'discount'
                  ? hideIfInAGroup
                    ? locations
                        ?.filter(item => item.id === selectedLocationId)[0]
                        ?.sections.filter(section => !section?.discountId)
                    : locations?.filter(item => item.id === selectedLocationId)[0]?.sections
                  : hideIfInAGroup
                    ? locations
                        ?.filter(item => item.id === selectedLocationId)[0]
                        ?.sections.filter(section => !section?.sectionAllowanceGroupId)
                    : locations?.filter(item => item.id === selectedLocationId)[0]?.sections
          }
          handleComponentClick={handleComponentClick}
          buttonTitle="Применить"
          onClick={async () => {
            setDisabledButtonSubmit(true);
            if (price) {
              if (price === 'price') {
                if (selectedItems.length > 1 && isIncludedFree) {
                  setAlert(true);
                  setDisabledButtonSubmit(false);
                } else {
                  await dispatch(
                    createSectionGroup({
                      locationId: selectedLocationId,
                      sectionIds: selectedItems,
                    }),
                  );
                  await updateProfile();
                  setDisabledButtonSubmit(false);
                  onClose();
                }
              }

              if (price === 'discount') {
                if (selectedItems.length > 1 && isIncludedFree) {
                  setAlert(true);
                  setDisabledButtonSubmit(false);
                } else {
                  await dispatch(
                    createDiscountGroup({
                      locationId: selectedLocationId,
                      sectionIds: selectedItems,
                    }),
                  );
                  await updateProfile();
                  setDisabledButtonSubmit(false);
                  onClose();
                }
              }

              if (price === 'action') {
                if (selectedItems.length > 1 && isIncludedFree) {
                  setAlert(true);
                  setDisabledButtonSubmit(false);
                } else {
                  await dispatch(
                    createPromotionGroup({
                      locationId: selectedLocationId,
                      sectionIds: selectedItems,
                    }),
                  );
                  await updateProfile();
                  setDisabledButtonSubmit(false);
                  onClose();
                }
              }
            } else {
              if (selectedItems.length > 1 && isIncludedFree) {
                setAlert(true);
                setDisabledButtonSubmit(false);
                /* onClose(); */
              } else {
                await dispatch(
                  createGroup({ locationId: selectedLocationId, sectionIds: selectedItems }),
                );
                await updateProfile();
                setDisabledButtonSubmit(false);
                onClose();
              }
            }
          }}
        />
        <ScrollButtonsContainer>
          <CircleButton
            onClick={handleSelectedAll}
            icon={{
              type: TypesIcon.GROUP,
              viewBox: '0 0 30.783 30.783',
              width: px(30.783),
              height: px(30.783),
            }}
          />
          {hideIfInAGroup ? (
            <CircleButton
              onClick={() => {
                setHideIfInAGroup(false);
              }}
              icon={{
                type: TypesIcon.REMOVEALPHABET,
                viewBox: '0 0 26 41',
                width: px(26),
                height: px(41),
              }}
            />
          ) : (
            <CircleButton
              onClick={() => {
                setHideIfInAGroup(true);
              }}
              icon={{
                type: TypesIcon.ALPHABET,
                viewBox: '0 0 8.939 40.957',
                width: px(8.939),
                height: px(40.957),
              }}
            />
          )}
        </ScrollButtonsContainer>
      </div>
      {alert && (
        <CustomAlert
          onClickButtonSubmit={() => setAlert(false)}
          title="FREE ZONE и ЛЕД не могут состоять в группах. Только индивидуально"
          single
          buttonRightText="Ок"
        />
      )}
    </Modal>
  );
};

export default GroupModal;
