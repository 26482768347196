import React from 'react';

import { priceMenuData } from '../constants/Component';
import { px } from '../constants/Px';
import { Checkbox } from './checkbox';
import { Icon } from './Icon';

export const ObjectTypes = ({ type, handleTypeChange }) => (
  <div className="flex" style={{ gap: px(10) }}>
    {priceMenuData.map(item => (
      <div
        key={item?.id}
        className="relative bg-[#082341]"
        style={{ height: px(222), width: px(90), borderRadius: px(15) }}
      >
        <div
          className="absolute flex w-full items-center justify-center"
          style={{
            top: px(20),
          }}
        >
          <Icon
            type={item.iconType}
            width={item.iconWidth}
            height={item.iconHeight}
            viewBox={item.viewBox}
          />
        </div>

        <div
          className="flex-column absolute flex w-full items-center justify-center "
          style={{
            top: px(76),
          }}
        >
          {item.subTitle.map(elem => (
            <p
              key={elem}
              className="font-light text-white"
              style={{ fontSize: px(10), lineHeight: px(13) }}
            >
              {elem}
            </p>
          ))}
        </div>
        <p
          className="absolute w-full text-center font-normal text-white"
          style={{
            top: px(151),
            fontSize: px(15),
            lineHeight: px(20),
          }}
        >
          {item.title}
        </p>

        <div
          className="absolute flex w-full flex-row items-center justify-center "
          style={{
            top: px(181),
          }}
        >
          <Checkbox
            margin
            onChange={e => handleTypeChange(e)}
            checked={type === item?.type}
            value={item?.type}
          />
        </div>
      </div>
    ))}
  </div>
);

export default ObjectTypes;
