/* eslint-disable sonarjs/no-duplicate-string */
import landRover from '../../assets/images/land-rover-card.png';
import { TypesIcon } from '../../types/TypesIcon';
import { px } from '../Px';

export const faceIdPageConstant = [
  { id: '1', value: '1', isIcon: false, position: 'left' },
  { id: '2', value: '2', isIcon: false, position: 'center' },
  { id: '3', value: '3', isIcon: false, position: 'right' },
  { id: '4', value: '4', isIcon: false, position: 'left' },
  { id: '5', value: '5', isIcon: false, position: 'center' },
  { id: '6', value: '6', isIcon: false, position: 'right' },
  { id: '7', value: '7', isIcon: false, position: 'left' },
  { id: '8', value: '8', isIcon: false, position: 'center' },
  { id: '9', value: '9', isIcon: false, position: 'right' },
  { id: '10', value: 'Не могу войти', isIcon: false, isText: true, position: 'left' },
  { id: '0', value: '0', isIcon: false, position: 'center' },
  { id: '12', value: 'TOUCHID', isIcon: true, position: 'right' },
];

export const changeCodePageConstant = [
  { id: '1', value: '1', isIcon: false, position: 'left' },
  { id: '2', value: '2', isIcon: false, position: 'center' },
  { id: '3', value: '3', isIcon: false, position: 'right' },
  { id: '4', value: '4', isIcon: false, position: 'left' },
  { id: '5', value: '5', isIcon: false, position: 'center' },
  { id: '6', value: '6', isIcon: false, position: 'right' },
  { id: '7', value: '7', isIcon: false, position: 'left' },
  { id: '8', value: '8', isIcon: false, position: 'center' },
  { id: '9', value: '9', isIcon: false, position: 'right' },
  { id: '10', value: 'Продолжить', isIcon: false, isText: true, position: 'left' },
  { id: '0', value: '0', isIcon: false, position: 'center' },
  { id: '12', value: '', isIcon: true, position: 'right' },
];

export const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const appealsPage = [
  {
    id: 1,
    title: 'Финансовый отдел',
    telegram: 'https://t.me/BookingFishing',
    viber: 'viber://chat?number=+375297347320',
    whatsapp: 'https://wa.me/message/CTQ7CHGNK4PFJ1',
  },
  {
    id: 2,
    title: 'Отдел бронирования',
    telegram: 'https://t.me/BookingFishing',
    viber: 'viber://chat?number=+375297347320',
    whatsapp: 'https://wa.me/message/CTQ7CHGNK4PFJ1',
  },
  {
    id: 3,
    title: 'Отдел претензий',
    telegram: 'https://t.me/BookingFishing',
    viber: 'viber://chat?number=+375297347320',
    whatsapp: 'https://wa.me/message/CTQ7CHGNK4PFJ1',
  },
  {
    id: 4,
    title: 'Как улучшить',
    telegram: 'https://t.me/BookingFishing',
    viber: 'viber://chat?number=+375297347320',
    whatsapp: 'https://wa.me/message/CTQ7CHGNK4PFJ1',
  },
];

export const addCardPageOptions = [
  { id: 1, title: 'Номер карты', placeholder: 'XXXX XXXX XXXX XXXX', valueTitle: 'cardNumber' },
  {
    id: 2,
    title: 'Имя владельца как на карте',
    placeholder: 'Марина Мнишек',
    valueTitle: 'cardsOwnerName',
  },
  { id: 3, title: 'Дата окончания действия', placeholder: 'XX/XX', valueTitle: 'dataOfEnd' },
  { id: 4, title: 'Код безопасности в CVC', placeholder: 'XXX', valueTitle: 'safeCode' },
];

export const verifyFaceId = [
  {
    id: 1,
    title: 'Логин (номер телефона)',
    iconType: 'KEY',
    width: px(28.492),
    height: px(28.492),
    viewBox: '0 0 28.492 28.492',
    navigatePatch: 'change-phone',
    deleteCode: false,
  },
  {
    id: 2,
    title: 'Изменить код доступа',
    iconType: 'CHANGEKEY',
    width: px(31.658),
    height: px(29.215),
    viewBox: '0 0 31.658 29.215',
    navigatePatch: 'add-code',
    deleteCode: false,
  },
  {
    id: 3,
    title: 'Удалить код доступа',
    iconType: 'BIGBASKET',
    width: px(20.761),
    height: px(27.868),
    viewBox: '0 0 20.761 27.868',
    navigatePatch: 'sms',
    deleteCode: true,
  },
  {
    id: 4,
    title: 'История авторизаций',
    iconType: 'CLOCK',
    width: px(29.504),
    height: px(25.816),
    viewBox: '0 0 29.504 25.816',
    navigatePatch: 'history',
    deleteCode: false,
  },
];
export const notificationPageLinks = [
  {
    id: 1,
    title: 'Настройка СМС и    push-уведомлений     от сервиса',
    subTitle: '',
    iconType: 'NOTIFICATION',
    width: px(22.97),
    height: px(27.56),
    viewBox: '0 0 22.968 27.562',
    navigatePatch: '/sms',
  },
  {
    id: 2,
    title: 'Бронирования',
    subTitle: 'После события',
    iconType: 'GAMES',
    width: px(24.128),
    height: px(26.848),
    viewBox: '0 0 24.128 26.848',
    navigatePatch: '/notification/extract/booking',
  },
  {
    id: 3,
    title: 'Статистика',
    subTitle: 'Еженедельно',
    iconType: 'STATS',
    width: px(24.324),
    height: px(26.848),
    viewBox: '0 0 24.324 26.848',
    navigatePatch: '/notification/extract/statistics',
  },
  {
    id: 4,
    title: 'Предложения',
    subTitle: 'Еженедельно',
    iconType: 'HOME',
    width: px(26.596),
    height: px(29.892),
    viewBox: '0 0 26.596 29.892',
    navigatePatch: '/notification/extract/offer',
  },
];

export const notificationPageBlockTitles = [
  { id: 1, title: 'Рассылка копий' },
  { id: 2, title: 'Выписки' },
];

export const regexPhone =
  /^((8|\+374|\+9{2}4|\+9{2}5|\+375|\+7|\+380|\+38|\+9{2}6|\+9{2}8|\+9{2}3)[ \-]?)?\(?\d{3,5}\)?(?:[ \-]?\d){5}(([ \-]?\d)?[ \-]?\d)?$/;

export const mobilePageLinks = [
  {
    id: 1,
    name: 'Политика конфиденциальности',
    iconName: 'SECURITY',
    width: 24.78,
    height: 29.05,
    viewBox: '0 0 26.128 30.074',
    link: './privacy',
    paddingBottom: px(18),
  },
  {
    id: 2,
    name: 'Сбор статистики',
    iconName: 'ANALYTICS',
    width: 27.15,
    height: 27.15,
    viewBox: '0 0 27.145 27.145',
    link: './statistic',
    paddingBottom: px(42.4),
  },
  {
    id: 3,
    name: 'Кэширование данных',
    iconName: 'CASH',
    width: 27.19,
    height: 28.08,
    viewBox: '0 0 27.194 28.078',
    link: './cash',
    paddingBottom: px(41.9),
  },
  {
    id: 4,
    name: 'О приложении',
    iconName: 'CHAT',
    width: 25.84,
    height: 23.22,
    viewBox: '0 0 25.843 23.218',
    link: './about',
    paddingBottom: px(0),
  },
];

export const blockTheAccountPageTimeData = [
  {
    id: 5,
    title: 'Начало периода',
    data: [
      { id: 1, subTitle: 'Время' },
      { id: 2, subTitle: 'Дата' },
    ],
  },
  {
    id: 6,
    title: 'Конец периода',
    data: [
      { id: 3, subTitle: 'Время' },
      { id: 4, subTitle: 'Дата' },
    ],
  },
];

export const monthObject = {
  Январь: '01',
  Февраль: '02',
  Март: '03',
  Апрель: '04',
  Май: '05',
  Июнь: '06',
  Июль: '07',
  Август: '08',
  Сентябрь: '09',
  Октябрь: '10',
  Ноябрь: '11',
  Декабрь: '12',
};

export const selectionsDate = {
  month: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  day: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ],
  year: [
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2033',
    '2034',
    '2035',
    '2036',
    '2037',
    '2038',
    '2039',
    '2040',
    '2041',
    '2042',
    '2043',
    '2044',
    '2045',
    '2046',
    '2047',
    '2048',
    '2049',
    '2050',
    '2051',
    '2052',
    '2053',
    '2054',
    '2055',
    '2056',
    '2057',
    '2058',
    '2059',
    '2060',
  ],
};

export const timeDate = {
  hour: [
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '24:00',
  ],
};

export const settingsProfilePageValuesOthers = {
  other1: '',
  other2: '',
  data: '',
  number: '',
};

export const settingsProfilePageTitlesNetwork = ['WhatsUp', 'Telegram', 'Viber'];

export const settingsProfilePageTitlesPersonalInfo = [
  'Имя',
  'Фамилия',
  'Телефон (логин)',
  'Почта электронная',
  'Страна',
  'Город',
];

export const settingsProfilePageTitlesPassport = [
  'Серия и номер',
  'Кем выдан',
  'Дата выдачи',
  'Код подразделения',
];

export const settingsProfilePageTitlesSport = ['Спортивная команда', 'Спортивный клуб'];

export const settingsProfilePageTitleOthers = [
  'Что-то другое',
  'Что-то другое',
  'Дата регистрации',
  'Номер личного кабинета',
];
export const settingsProfilePagePlaceholderPassport = [
  'XX XX XXXXXX',
  'Подразделение...',
  '00.00.0000',
  'XXX-XXX',
];
export const settingsProfilePagePlaceholdersPersonalInfo = [
  'Введите имя…',
  'Введите фамилию…',
  'Введите телефон…',
  'Введите адрес электр…',
  'Страна проживания',
  'Город проживания',
];
export const settingsProfilePagePlaceholderOthers = [
  'XX XX XXXXXX',
  'OOOOOOO...',
  '04.06.2021',
  'ID 87548475',
];

export const sortPlaceholder = ['Номер карты', 'ID кабинета'];

export const settingsProfilePagePlaceholderNetwork = ['https://', 'https://', 'https://'];

export const timeDateSendingNotification = {
  hour: [
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    'Круглосуточно',
  ],
};

export const NotificationSendingTimePageDataDays = [
  { id: 1, day: 'Ежедневно' },
  { id: 2, day: 'Будние' },
  { id: 3, day: 'Выходные' },
];
export const UserProfilePageMenuOptions = [
  {
    id: 1,
    name: 'Личная информация',
    iconName: 'PERSONALINFO',
    width: 22.47,
    height: 25.56,
    viewBox: '0 0 22.466 25.561',
    link: 'settings-profile',
    bottom: px(25),
  },
  {
    id: 2,
    name: 'Вход и Надежность',
    iconName: 'SECURITY',
    width: 26.13,
    height: 30.07,
    viewBox: '0 0 26.128 30.074',
    link: 'verify-face-id',
    bottom: px(20.9),
  },
  {
    id: 3,
    name: 'Уведомления. Копии',
    iconName: 'NOTIFICATION',
    width: 22.97,
    height: 27.56,
    viewBox: '0 0 22.968 27.562',
    link: 'notification',
    bottom: px(23.2),
  },
  {
    id: 4,
    name: 'Управление кабинетом',
    iconName: 'CONTROL',
    width: 28.47,
    height: 28.47,
    viewBox: '0 0 28.474 28.474',
    link: 'account-control',
    bottom: px(22.3),
  },
  {
    id: 5,
    name: 'Банковские карты',
    iconName: 'CARDS',
    width: 29.12,
    height: 23.08,
    viewBox: '0 0 29.117 23.077',
    link: 'add-card',
    bottom: px(23.9),
  },
  {
    id: 6,
    name: 'Финансы. Организации',
    iconName: 'FINANCE',
    width: 26.58,
    height: 28.74,
    viewBox: '0 0 27.58 29.737',
    link: 'finance',
    bottom: px(25),
  },
  {
    id: 7,
    name: 'Аналитика. Финансы',
    iconName: 'ANALYTICS',
    width: 27.15,
    height: 27.15,
    viewBox: '0 0 27.145 27.145',
    link: 'profile',
    bottom: px(22.9),
  },
  {
    id: 8,
    name: 'Подписки',
    iconName: 'SUBSCRIPTIONS',
    width: 25.83,
    height: 25.83,
    viewBox: '0 0 25.827 25.827',
    link: 'profile',
    bottom: px(23.6),
  },
  {
    id: 9,
    name: 'Клубные карты',
    iconName: 'CLUBCARTS',
    width: 27.41,
    height: 19.17,
    viewBox: '0 0 27.41 19.172',
    link: 'cards-types-profile',
    bottom: px(25),
    personal: true,
  },
  {
    id: 10,
    name: 'Сертификаты',
    iconName: 'SERTIFICATE',
    width: 23.19,
    height: 23.19,
    viewBox: '0 0 23.188 23.188',
    link: 'profile',
    bottom: px(25),
  },
  {
    id: 11,
    name: 'Чат с техподдержкой',
    iconName: 'SUPPORT',
    width: 24.96,
    height: 25.42,
    viewBox: '0 0 24.964 25.423',
    link: 'profile',
    bottom: px(23.8),
  },
  {
    id: 12,
    name: 'Обращения',
    iconName: 'APPEALS',
    width: 24.96,
    height: 25.42,
    viewBox: '0 0 24.964 25.423',
    link: 'appeals',
    bottom: px(23.8),
  },
  {
    id: 13,
    name: 'Позвонить нам',
    iconName: 'CALL',
    width: 27.27,
    height: 27.37,
    viewBox: '0 0 27.269 27.372',
    link: 'profile',
    bottom: px(22.8),
  },
  {
    id: 14,
    name: 'Мобильная версия',
    iconName: 'MOBILE',
    width: 25.68,
    height: 23.53,
    viewBox: '0 0 25.683 23.53',
    link: 'mobile',
    bottom: px(25),
  },
  {
    id: 15,
    name: 'Заблокировать кабинет',
    iconName: 'BLOCK',
    width: 21,
    height: 28,
    viewBox: '0 0 21 28',
    link: 'block-account',
    bottom: px(23),
  },
  {
    id: 16,
    name: 'Перевод кабинета',
    iconName: 'TRANSFER',
    width: 28.98,
    height: 26.08,
    viewBox: '0 0 30.48 27.582',
    link: 'transfer',
    bottom: px(25),
  },
  {
    id: 17,
    name: 'Удалить кабинет',
    iconName: 'DELETE',
    width: 20.5,
    height: 25.23,
    viewBox: '0 0 20.5 25.231',
    link: 'delete-account',
    bottom: px(25),
  },
];
export const UserProfilePageOptions = [
  { id: 1, name: 'Игра. Отдых. Услуги' },
  { id: 2, name: 'Гиды. Обучение. Туры' },
  { id: 3, name: 'Турниры. Соревнования' },
];

export const footerNetworkIcons = [
  {
    id: 1,
    type: TypesIcon.TELEGRAMWITHOUTBG,
    width: px(31.15),
    height: px(24),
    viewBox: '0 0 31.154 24',
    href: 'https:/t.me',
  },
  {
    id: 2,
    type: TypesIcon.VKONTAKTE,
    width: px(40.22),
    height: px(22.97),
    viewBox: '0 0 40.222 22.97',
    href: 'https:/t.me',
  },
  {
    id: 3,
    type: TypesIcon.YOUTUBE,
    width: px(22.05),
    height: px(23.99),
    viewBox: '0 0 22.05 23.993',
    href: 'https:/t.me',
  },
];
export const footerProjectXOptions = [
  { id: 1, value: 'option1', label: 'О Бронируйрыбалку.рф', link: '#', disabledLinks: true },
  { id: 2, value: 'option2', label: 'Добавить Объект или Услугу', link: '/profile#registration' },
  { id: 3, value: 'option3', label: 'Стать акционером', link: '#', disabledLinks: true },
];

export const footerChatOptions = [
  { id: 1, value: 'option1', label: 'info@booking.fishing', link: 'mailto:info@booking.fishing' },
  { id: 2, value: 'option2', label: 'Служба поддержки', link: '/chat-with-support' },
  { id: 3, value: 'option3', label: '8 800 333-89-53', link: 'tel:88003338953' },
];

export const footerAdvertOptions = [
  {
    value: 'Скачать MEDIA KIT',
    label: 'Скачать MEDIA KIT',
    link: '#',
    disabledLinks: true,
  },
  {
    value: 'Разместить рекламу',
    label: 'Разместить рекламу',
    link: '#',
    disabledLinks: true,
  },
];

export const clubCards = [
  {
    name: 'ДЖОННИ КОЛЬТ',
    location: 'Франция, Марсель',
    id: '7579086',
    type: 'Classic',
    startDate: `06:30 26 МАР 2021`,
    endDate: `06:30 26 МАР 2024`,
    cardNumber: '000000000100',
  },
  {
    name: 'ДЖОННИ КОЛЬТ',
    location: 'Франция, Марсель',
    id: '7579087',
    type: 'Classic',
    startDate: `06:30 26 МАР 2022`,
    endDate: `06:30 26 МАР 2024`,
    cardNumber: '000000000101',
  },
  {
    name: 'ДЖОННИ КОЛЬТ',
    location: 'Франция, Марсель',
    id: '7579088',
    type: 'Classic',
    startDate: `06:30 26 МАР 2022`,
    endDate: `06:30 26 МАР 2023`,
    cardNumber: '000000000134',
  },
];

export const sort = [
  {
    id: 0,
    title: 'По умолчанию',
    value: 'default',
  },
  {
    id: 1,
    title: 'По дате присвоения',
    value: 'dateStart',
  },
  {
    id: 2,
    title: 'По дате окончания',
    value: 'dateEnd',
  },
  {
    id: 3,
    title: 'Последовательно',
    value: 'sequentially',
  },
  {
    id: 4,
    title: 'Сначала новые',
    value: 'new',
  },
  {
    id: 5,
    title: 'Заблокированные',
    value: 'blocked',
  },
  {
    id: 6,
    title: 'Удаленные',
    value: 'delete',
  },
  {
    id: 7,
    title: 'Утратившие действия',
    value: 'expired',
  },
];

export const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

export const getData = data => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  const days = getDaysInMonth(data?.year, selectionsDate?.month?.indexOf(data?.month) + 1);

  if (data?.year === `${currentYear}` && data?.month === selectionsDate?.month?.[currentMonth]) {
    return {
      month: selectionsDate?.month?.filter((item, index) => index >= currentMonth),
      day: selectionsDate?.day?.slice(0, days)?.filter(item => +item >= currentDay),
      year: selectionsDate?.year?.filter(item => +item >= currentYear),
    };
  }
  if (data?.year === `${currentYear}`) {
    return {
      month: selectionsDate?.month?.filter((item, index) => index >= currentMonth),
      day: selectionsDate?.day?.slice(0, days),
      year: selectionsDate?.year?.filter(item => +item >= currentYear),
    };
  }
  return {
    month: selectionsDate?.month,
    day: selectionsDate?.day?.slice(0, days),
    year: selectionsDate?.year?.filter(item => +item >= currentYear),
  };
};

export const footerCardsSliderIcons = [
  {
    id: 1,
    type: TypesIcon.JCB,
    width: px(56.613),
    height: px(45.695),
    viewBox: '0 0 56.613 45.695',
    className: 'jcb',
  },
  {
    id: 2,
    type: TypesIcon.VISA,
    width: px(52.455),
    height: px(16.843),
    viewBox: '0 0 52.455 16.843',
    className: 'visa',
  },
  {
    id: 3,
    type: TypesIcon.MASTERCARD,
    width: px(68.485),
    height: px(48.525),
    viewBox: '0 0 68.485 48.525',
    className: 'mastercard',
  },
  {
    id: 4,
    type: TypesIcon.MIRFOOTER,
    width: px(60),
    height: px(60),
    viewBox: '252.448 288.608 776.384 219.776',
    className: 'mir',
  },
  {
    id: 5,
    type: TypesIcon.PAYPAL,
    width: px(60),
    height: px(60),
    viewBox: '0 -140 780 780',
    className: 'paypal',
  },
];
const options = [
  { id: 1, value: 'option1', label: 'Option 1' },
  { id: 2, value: 'option2', label: 'Option 2' },
  { id: 3, value: 'option3', label: 'Option 3' },
];

export const cardTypesPageLabels = [
  {
    id: 5,
    label: 'Где применяется',
    value: '',
    paddingBottom: 26,
    paddingTop: 24,
    option: options,
    isDropdownIcon: true,
  },
  {
    id: 6,
    label: 'Соглашение',
    value: '',
    paddingBottom: 26,
    paddingTop: 24,
    option: options,
    isDropdownIcon: true,
  },
  {
    id: 7,
    label: 'Регламент использования',
    value: '',
    option: [
      {
        id: 4,
        value: 'text',
        label:
          'Карты лояльности не действуют, когда \n' +
          'вы покупаете товары брендов или \n' +
          'секций: Apple, Baden, Chanel, Dior, корнер \n' +
          'Fendi, Глобус Гурмэ, корнер Gucci, LOUIS \n' +
          'VUITTON, Prada/miu miu, ресторан BURO, \n' +
          'кафе ЦУМ (2 и 4 этаж), секция \n' +
          'подарочной упаковки, Stephen Webster,',
      },
    ],
    paddingTop: 22,
    paddingBottom: 15,
    fontLabelSize: 20,
    labelLineHeight: 26,
    isDropdownIcon: true,
  },
];

export const presentCard = [
  { id: 1, label: 'Имя Владельца', value: 'ID кабинета', paddingTop: 15, isDropdownIcon: false },
  { id: 2, label: 'Имя карты', value: 'Classic', paddingTop: 15, isDropdownIcon: false },
  { id: 3, label: 'Вид карты', value: 'Скидочная', isDropdownIcon: false },
  { id: 4, label: 'Допуск', value: '', isDropdownIcon: true, children: true },
  { id: 5, label: 'Комментарий', value: '', isDropdownIcon: true },
];

export const cardTypesPageCardData = [
  {
    id: 1,
    bgImg: landRover,
    textColor: '#fff',
    percent: '15',
    ownerName: 'Имя владельца',
    cardNumber: '00/00',
    cardType: 'Classic',
  },
  {
    id: 2,
    bgColor: 'transparent',
    textColor: '#000',
    percent: '15',
    ownerName: '',
    cardNumber: '',
    cardType: 'Льготник',
  },
  {
    id: 3,
    bgColor: 'red',
    textColor: '#fff',
    percent: '15',
    ownerName: 'Имя владельца',
    cardNumber: '00/00',
    cardType: '',
  },
];
export const addLoyalCardPageLabels = [
  { id: 1, label: 'Имя карты', value: 'Classic', isDropdownIcon: false },
  { id: 2, label: 'Вид карты', value: 'Скидочная', isDropdownIcon: false },
  { id: 3, label: 'Тип карты', value: 'Платная', isDropdownIcon: false },
  { id: 4, label: 'Условия получения', value: '5000.00', isDropdownIcon: false },
  { id: 5, label: 'Статус карты', value: '10%', isDropdownIcon: false },
  { id: 6, label: 'Срок действия', value: '00/00', isDropdownIcon: false },
  { id: 7, label: 'Допуск', value: '', isDropdownIcon: true },
  {
    id: 8,
    links: [
      { id: 1, label: 'Загрузить фото с моим логотипом', link: '' },
      { id: 2, label: 'Загрузить фото без логотипа', link: '' },
    ],
  },
  {
    id: 9,
    label: 'Общая информация',
    value: '',
    isDropdownIcon: true,
    bottomTop: true,
    paddingTop: 25,
    paddingBottom: 25,
  },
  {
    id: 10,
    label: 'Где применяется',
    value: '',
    isDropdownIcon: true,
    fontLabelSize: 20,
    labelLineHeight: 26,
    paddingTop: 22,
    paddingBottom: 22,
  },
  {
    id: 11,
    label: 'Соглашение',
    value: '',
    isDropdownIcon: true,
    paddingTop: 25,
    paddingBottom: 25,
  },
  {
    id: 12,
    label: 'Oбслуживание клиентов',
    value: '',
    isDropdownIcon: true,
    fontLabelSize: 20,
    labelLineHeight: 26,
    paddingTop: 22,
    paddingBottom: 30,
  },
];

export const pickerTitleAddLoyalCardPage = [
  { id: 1, title: 'Создать карту.Имя карты' },
  { id: 2, title: 'Создать карту.Вид карты' },
  { id: 3, title: 'Создать карту.Тип карты' },
  { id: 5, title: 'Создать карту.Статус карты' },
  { id: 6, title: 'Создать карту.Срок действия карты' },
];

export const settingsProfile = [
  { id: 1, social: 'whatsupLink' },
  { id: 2, social: 'telegramLink' },
  { id: 3, social: 'viberLink' },
];

export const financePlaceholder = [
  'ОПФ',
  'Наименование',
  'Налоговый режим',
  'ИНН',
  'КПП (при наличии)',
  'БИК',
  'Р/с',
];

export const financePlaceholderInfo = [
  'ООО, ИП, АО, ...',
  'Название, ФИО',
  'Выбрать',
  'ХХХХХХХХХХ',
  'ХХХХХХХХХХ',
  'ХХХХХХХХХХ',
  'Номер счета',
];
export const organization = {
  legal_form: ['НПД', 'ИП', 'ООО', 'АО', 'Физическое лицо', 'Самозанятый'],
};

export const nalog = {
  tax: ['НПД', 'УСН', 'УСХН', 'ПСН'],
};

export const feedbackComponentFeedbackTitles = [
  {
    id: 1,
    iconType: TypesIcon.PLUSFORBUTTON,
    iconWidth: px(16.971),
    iconHeight: px(16.971),
    viewBox: '0 0 16.971 16.971',
    title: 'Что вам понравилось?',
    objKey: 'positiveText',
  },
  {
    id: 2,
    iconType: TypesIcon.MINUSFORBUTTON,
    iconWidth: px(16.5),
    iconHeight: px(1),
    viewBox: '0 0 16.5 1',
    title: 'Что вас огорчило?',
    objKey: 'negativeText',
  },
];

export const feedbackComponentCriterion = [
  { id: 1, objectKey: 'guarantees', title: 'Гарантии' },
  { id: 2, objectKey: 'cleanliness', title: 'Чистота' },
  { id: 3, objectKey: 'silence', title: 'Тишина' },
  { id: 4, objectKey: 'rules', title: 'Правила' },
  { id: 5, objectKey: 'payment', title: 'Оплата' },
];

export const userCardMonthDate = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

export const createFeedbackPageCriterion = [
  { id: 1, objectKey: 'guaranteesEstimate', title: 'Гарантии' },
  { id: 2, objectKey: 'cleaningEstimate', title: 'Чистота' },
  { id: 3, objectKey: 'silenceEstimate', title: 'Тишина' },
  { id: 4, objectKey: 'rulesEstimate', title: 'Правила' },
  { id: 5, objectKey: 'paymentEstimate', title: 'Оплата' },
];

export const createFeedbackPageFeedbackButtonIcons = [
  {
    id: 1,
    iconType: TypesIcon.PLUSFORBUTTON,
    iconWidth: px(16.971),
    iconHeight: px(16.971),
    viewBox: '0 0 16.971 16.971',
    title: 'Что вам понравилось?',
  },
  {
    id: 2,
    iconType: TypesIcon.MINUSFORBUTTON,
    iconWidth: px(16.5),
    iconHeight: px(1),
    viewBox: '0 0 16.5 1',
    title: 'Что вас огорчило?',
  },
];

export const styledAvatar = [
  {
    id: 0,
    title: 'Аватар',
    width: 320,
    height: 304,
    cropWidth: 204,
    cropHeight: 204,
    borderRadius: 204,
    types: 'RESIZE',
    type: 'AVATAR',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 1,
    title: 'Логотип',
    width: 320,
    height: 304,
    cropWidth: 130,
    cropHeight: 130,
    borderRadius: 15,
    types: 'RESIZE',
    type: 'LOGO',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 2,
    title: 'Открытка',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 0,
    types: 'RESIZE',
    type: 'POSTCARD',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 3,
    title: 'Обложка',
    width: 320,
    height: 304,
    cropWidth: 320,
    cropHeight: 183,
    borderRadius: 0,
    types: 'RESIZE',
    type: 'BANNER',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 4,
    title: 'Предложения. Игра',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 15,
    types: 'RESIZE',
    type: 'GAME',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 5,
    title: 'Предложения. Отдых',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 15,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 6,
    title: 'Предложения. Услуги ',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 15,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },

  {
    id: 7,
    title: '  Карты. Схемы',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 216,
    borderRadius: 0,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 8,
    title: 'Новости',
    width: 320,
    height: 304,
    cropWidth: 270,
    cropHeight: 290,
    borderRadius: 0,
    types: 'RESIZE',
    type: 'NEWS',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 9,
    title: 'Галерея',
    width: 320,
    height: 304,
    cropWidth: 320,
    cropHeight: 290,
    borderRadius: 15,
    types: 'RESIZE',
    type: 'GALLERY',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
];

export const bookingDistributionPage = {
  distribution: ['После события', 'Каждые 12 часов', 'Ежедневно', 'Еженедельно'],
};

export const statisticDistributionPage = {
  statistic: [
    'Прошлая неделя',
    'Прошлый месяц',
    'Выбрать период',
    'Текущий месяц',
    'Прошлый квартал',
  ],
};

export const statisticsExtractsPage = {
  'Прошлая неделя': 'Прошлая н...',
  'Прошлый месяц': 'Прошлый м..',
  'Выбрать период': 'Выбрать период',
  'Текущий месяц': 'Текущий м...',
  'Прошлый квартал': 'Прошлый кв.',
};

export const distributionPageShortTitle = {
  'После события': 'После собы...',
  'Каждые 12 часов': 'Каждые 12 ч',
  Ежедневно: 'Ежедневно',
  Еженедельно: 'Еженедельно',
};

export const transferTheAccountPageTimeData = {
  title: 'Начало периода',
  data: [
    { id: 1, subTitle: 'Время' },
    { id: 2, subTitle: 'Дата' },
  ],
};

export const objectDescriptionPageTextareaSizes = [
  {
    id: 0,
    placeholder: 'Введите текст',
    title: 'Название',
    textareaHeight: 75,
    objectName: true,
    top: 20,
    bottom: 10,
    height: 114,
    paddingTop: 11,
    paddingLeft: 18,
    paddingRight: 18,
    objectKey: 'objectName',
  },
  {
    id: 1,
    top: 0,
    bottom: 27,
    height: 330,
    paddingTop: 4,
    paddingLeft: 18,
    paddingRight: 14,
    paddingBottom: 14,
    title: 'Описание',
    placeholder: 'Введите текст',
    textareaHeight: 286,
    objectKey: 'objectDescription',
  },
  {
    id: 2,
    textareaHeight: 67,
    placeholder: 'Введите текст',
    title: 'Основные положения',
    top: 20,
    bottom: 40,
    height: 130,
    paddingTop: 4,
    paddingLeft: 18,
    paddingRight: 33,
    paddingBottom: 22,
  },
];

export const objectNewsPage = [
  {
    id: 0,
    placeholder: 'Введите текст не более 36 знаков.',
    title: '1. Заголовок на обложке',
    textareaHeight: 75,
    objectName: true,
    top: 20,
    bottom: 10,
    height: 130,
    paddingTop: 11,
    paddingLeft: 18,
    paddingRight: 18,
    objectKey: 'title',
  },
  {
    id: 1,
    title: '2. Подзаголовок',
    placeholder: 'Введите текст не более 40 знаков',
    textareaHeight: 75,
    objectName: true,
    top: 20,
    bottom: 10,
    height: 130,
    paddingTop: 11,
    paddingLeft: 18,
    paddingRight: 18,
    objectKey: 'subtitle',
  },
  {
    id: 2,
    placeholder: 'Введите текст не более 600 знаков',
    title: '3. Текст новости',
    textareaHeight: 75,
    objectName: true,
    top: 20,
    bottom: 10,
    height: 130,
    paddingTop: 11,
    paddingLeft: 18,
    paddingRight: 18,
    objectKey: 'body',
  },
];
export const objectDescriptionPageData = {
  objectName: '',
  objectDescription: '',
  objectRules: '',
  country: '',
  region: '',
  area: '',
  city: '',
  street: '',
  phone1: '',
  phone2: '',
  email: '',
  whatsApp: '',
  telegram: '',
  viber: '',
  geo: [],
  network: '',
  webPage1: '',
  webPage2: '',
  accountId: '',
};
export const objectDescriptionPageInputs = [
  {
    id: 1,
    title: 'Страна',
    placeholder: 'Выбор из списка',
    options: ['Россия', 'Беларусь', 'Казахстан'],
    objKey: 'country',
  },
  {
    id: 2,
    title: 'Регион',
    placeholder: 'Выбор из списка',
    objKey: 'region',
  },

  {
    id: 3,
    title: 'Область',
    placeholder: 'Выбор из списка',
    objKey: 'area',
  },
  { id: 4, title: 'Город. Поселок', objKey: 'city' },
  { id: 5, title: 'Улица,дом, корпус, ...', objKey: 'street' },
  {
    id: 6,
    title: 'Телефон 1',
    objKey: 'phone1',
    phoneInput: true,
    bigTitle: true,
  },
  {
    id: 7,
    title: 'Телефон 2',
    objKey: 'phone2',
    phoneInput: true,
    bigTitle: true,
  },
  { id: 8, title: 'Почта электронная', placeholder: 'XXXX@XXX...', objKey: 'email' },
  {
    id: 9,
    title: 'Ссылка на чат WhatsApp',
    placeholder: 'Прикрепить',
    bluePlaceholder: true,
    objKey: 'whatsApp',
  },
  {
    id: 10,
    title: 'Ссылка на чат в Telegram',
    placeholder: 'Прикрепить',
    bluePlaceholder: true,
    objKey: 'telegram',
  },
  {
    id: 11,
    title: 'Ссылка на чат в Viber ',
    placeholder: 'Прикрепить',
    bluePlaceholder: true,
    objKey: 'viber',
  },
  {
    id: 12,
    title: 'Геолокация. Координаты',
    placeholder: 'Прикрепить',
    bluePlaceholder: true,
    objKey: 'geo',
  },
  {
    id: 13,
    title: 'Страница в соц. сети',
    placeholder: 'Прикрепить',
    bluePlaceholder: true,
    objKey: 'network',
  },
  {
    id: 14,
    title: 'Web-страница сайта',
    placeholder: 'Прикрепить',
    bluePlaceholder: true,
    objKey: 'webPage1',
  },
  { id: 15, title: 'Web-страница сайта', objKey: 'webPage2' },
  { id: 16, title: 'ID кабинета Управляющего', bigTitle: true, objKey: 'accountId' },
];
export const objectDescriptionPageSliders = [
  {
    id: 1,
    title: ' Логотип. Открытка, …',
    top: 33,
    bottom: 26.26,
    images: [
      { id: 1, src: '', type: 'blueBg' },
      { id: 2, src: '', type: 'blueBg' },
      { id: 3, src: '', type: 'blueBg' },
      { id: 4, src: '', type: 'blueBg' },
      { id: 5, src: '', type: 'blueBg' },
      { id: 6, src: '', type: 'blueBg' },
    ],
  },
  {
    id: 2,
    title: ' Галерея',
    top: 26,
    bottom: 23.26,
    images: [
      // {id:000, src:scr?src:'', type:src?'img':'blueBg'}
      { id: 1, src: '', type: 'blueBg' },
      { id: 2, src: '', type: 'blueBg' },
      { id: 3, src: '', type: 'blueBg' },
      { id: 4, src: '', type: 'blueBg' },
      { id: 5, src: '', type: 'blueBg' },
      { id: 6, src: '', type: 'blueBg' },
    ],
  },
];

export const styledAttachment = [
  {
    id: 0,
    title: 'Логотип',
    width: 320,
    height: 304,
    cropWidth: 130,
    cropHeight: 130,
    borderRadius: 15,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 1,
    title: 'Открытка',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 0,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 2,
    title: 'Обложка',
    width: 320,
    height: 304,
    cropWidth: 320,
    cropHeight: 183,
    borderRadius: 0,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 3,
    title: 'Предложения. Игра',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 15,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 4,
    title: 'Предложения. Отдых',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 15,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
  {
    id: 5,
    title: 'Предложения. Услуги ',
    width: 320,
    height: 304,
    cropWidth: 141,
    cropHeight: 110,
    borderRadius: 15,
    types: 'RESIZE',
    widthIcon: 70,
    heightIcon: 70,
    viewBox: '0 0 70 70',
  },
];

export const styledGallery = {
  id: 8,
  title: 'Галерея',
  width: 320,
  height: 304,
  cropWidth: 320,
  cropHeight: 290,
  borderRadius: 0,
  types: 'RESIZE',
  widthIcon: 70,
  heightIcon: 70,
  viewBox: '0 0 70 70',
};

export const objectImageTypeSlider = [
  'LOGO',
  'POSTCARD',
  'BANNER',
  'GAME',
  'RECREATION',
  'SERVICE',
];

export const galleryObjectDescription = [
  {
    id: 1,
    title: ' Логотип. Открытка, …',
    top: 33,
    bottom: 26.26,
    images: [
      { id: 1, src: '', type: 'blueBg' },
      { id: 2, src: '', type: 'blueBg' },
      { id: 3, src: '', type: 'blueBg' },
      { id: 4, src: '', type: 'blueBg' },
      { id: 5, src: '', type: 'blueBg' },
      { id: 6, src: '', type: 'blueBg' },
    ],
  },
  {
    id: 2,
    title: ' Галерея',
    top: 26,
    bottom: 23.26,
    images: [
      { id: 1, src: '', type: 'blueBg' },
      { id: 2, src: '', type: 'blueBg' },
      { id: 3, src: '', type: 'blueBg' },
      { id: 4, src: '', type: 'blueBg' },
      { id: 5, src: '', type: 'blueBg' },
      { id: 6, src: '', type: 'blueBg' },
    ],
  },
];

export const objectNewsGallery = [
  {
    id: 1,
    subTitle: ' Логотип. Открытка, …',
    top: 33,
    bottom: 26.26,
    images: [
      { id: 1, src: '', type: 'blueBg' },
      { id: 2, src: '', type: 'blueBg' },
      { id: 3, src: '', type: 'blueBg' },
      { id: 4, src: '', type: 'blueBg' },
      { id: 5, src: '', type: 'blueBg' },
      { id: 6, src: '', type: 'blueBg' },
    ],
  },
];
export const priceMenuData = [
  {
    id: 1,
    title: 'Рыбалка',
    subTitle: ['МОСТИК', 'НАВЕС', 'ДОМИК', 'FREE ZONE', '...'],
    iconType: TypesIcon.BIGFISH,
    iconWidth: px(51),
    iconHeight: px(23),
    viewBox: '0 0 51 23',
    type: 'fishing',
  },
  {
    id: 2,
    title: 'Отдых',
    subTitle: ['ОТЕЛЬ', 'КОТТЕДЖ', 'БЕСЕДКА', 'БАНЯ,САУНА', '...'],
    iconType: TypesIcon.HOTELKEY,
    iconWidth: px(18),
    iconHeight: px(43),
    viewBox: '0 0 18 43',
    type: 'rest',
  },
  {
    id: 3,
    title: 'Услуги',
    subTitle: ['ЛОДКА', 'СНАСТИ', 'ПРИКОРМКА', 'ОБОРУДОВАНИЕ', '...'],
    iconType: TypesIcon.BOAT,
    iconWidth: px(53),
    iconHeight: px(43),
    viewBox: '0 0 53 43',
    type: 'services',
  },
];

export const menuObjectFiltersData = [
  {
    id: 1,
    title: 'Берег.Ландшафт',
    iconType: TypesIcon.WATERSIDE,
    iconWidth: px(31),
    iconHeight: px(30),
    viewBox: '0 0 31 30',
  },
  {
    id: 2,
    title: 'Объект',
    iconType: TypesIcon.HOUSE,
    iconWidth: px(27),
    iconHeight: px(30),
    viewBox: '0 0 27 30',
  },
  {
    id: 3,
    title: 'Рыбалка',
    iconType: TypesIcon.FISH,
    iconWidth: px(43),
    iconHeight: px(25),
    viewBox: '0 0 43 25',
  },
];
export const objectShemaPage = [
  {
    id: 0,
    placeholder: 'Введите текст не более 34 знаков.',
    title: '1. Заголовок на обложке',
    textareaHeight: 75,
    objectName: true,
    top: 20,
    bottom: 10,
    height: 130,
    paddingTop: 11,
    paddingLeft: 18,
    paddingRight: 18,
    objectKey: 'title',
  },
];
export const filterPlaceData = [
  {
    id: 1,
    title: 'Место',
    type: 'checkbox',
    options: [
      { id: 1, label: 'FREE ZONE', value: 'free', withNumber: true },
      { id: 2, label: 'Мостик', value: 'bridge' },
      { id: 3, label: 'Понтон', value: 'pontoon' },
      { id: 4, label: 'Английский', value: 'english' },
      { id: 5, label: 'Дикий берег', value: 'wild' },
    ],
    value: 1,
  },
  {
    id: 2,
    title: 'Ландшафт',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Поле, равнина', value: 'field' },
      { id: 2, label: 'Редкие деревья', value: 'tree' },
      { id: 3, label: 'Лесопосадка', value: 'trees' },
      { id: 4, label: 'Лес', value: 'forest' },
      { id: 5, label: 'Газон', value: 'lawn' },
      { id: 6, label: 'Возвышенность', value: 'hill' },
      { id: 7, label: 'Низменность', value: 'lowland' },
    ],
    value: 2,
  },
  {
    id: 3,
    title: 'Условия на месте',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Стоянка у места ловли', value: 'parking' },
      { id: 2, label: 'Электричество', value: 'electricity' },
      { id: 3, label: 'Wi-Fi', value: 'wifi' },
      { id: 4, label: 'Видеонаблюдение', value: 'video' },
      { id: 5, label: 'Туалет', value: 'toilet' },
      { id: 6, label: 'Уборка мусора', value: 'cleaning' },
    ],
    value: 3,
  },
];

export const filterObjectData = [
  {
    id: 4,
    title: 'Объект на месте',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Навес, шатер, зонт,...', value: 'tent' },
      { id: 2, label: 'Беседка', value: 'alcove' },
      { id: 3, label: 'Домик', value: 'house' },
      { id: 4, label: 'Эко-Домик', value: 'ecoHouse' },
      { id: 5, label: 'Вагончик', value: 'trailer' },
      { id: 6, label: 'Сафари - тент,лодж,...', value: 'safari' },
    ],
    value: 4,
  },
  {
    id: 5,
    title: 'Тип объекта',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Открытый', value: 'opened' },
      { id: 2, label: 'Закрытый', value: 'closed' },
      { id: 3, label: 'Вместимость', value: 'capacity', withoutCheckbox: true, withNumber: true },
    ],
    value: 5,
  },
  {
    id: 6,
    title: 'Удобства в объекте',
    type: 'checkbox',
    options: [
      { id: 1, label: 'С проживанием', value: 'accommodation' },
      { id: 2, label: 'Отдых с детьми', value: 'children' },
      { id: 3, label: 'С домашними животными', value: 'pets' },
      { id: 4, label: 'Электричество', value: 'electricityInObject' },
      { id: 5, label: 'Кондиционер', value: 'conditioner' },
      { id: 6, label: 'Отопление', value: 'heating' },
      { id: 7, label: 'Спальное место', value: 'bed', withNumber: true },
      { id: 8, label: 'Мебель (минимум)', value: 'furniture' },
      { id: 9, label: 'Постельное белье', value: 'bedSheets' },
      { id: 10, label: 'Телевизор', value: 'tv' },
      { id: 11, label: 'Электрическая плита', value: 'plate' },
      { id: 12, label: 'Чайник', value: 'kettle' },
      { id: 13, label: 'Туалет', value: 'toiletInObject' },
      { id: 14, label: 'Водопровод', value: 'water' },
      { id: 15, label: 'Душ', value: 'shower' },
      { id: 16, label: 'Wi-Fi', value: 'wifiInObject' },
      { id: 17, label: 'Видеонаблюдение', value: 'videoInObject' },
      { id: 18, label: 'Охрана', value: 'guard' },
      { id: 19, label: 'Уборка мусора', value: 'cleaning' },
    ],
    value: 6,
  },
];

export const fishingFilterData = [
  {
    id: 7,
    title: 'Рыбалка',
    type: '',
    options: [
      { id: 1, label: 'Спортивная', value: 'sport' },
      { id: 2, label: 'Любительская', value: 'love' },
    ],
    value: 7,
  },
  {
    id: 9,
    title: 'Дисциплина',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Карпфишинг', value: 'carpfishing' },
      { id: 2, label: 'Ловля форели', value: 'troutFishing' },
      { id: 3, label: 'Фидер. Донка ', value: 'fider' },
      { id: 4, label: 'Матч', value: 'match' },
      { id: 5, label: 'Поплавок', value: 'float' },
      { id: 6, label: 'Спиннинг с берега', value: 'spinningShore' },
      { id: 7, label: 'Спиннинг с лодок', value: 'spinningBoat' },
      { id: 8, label: 'Троллинг', value: 'trolling' },
      { id: 9, label: 'Джиг', value: 'jig' },
      { id: 10, label: 'Квок', value: 'kvock' },
      { id: 11, label: 'На живца', value: 'forLiveBait' },
      { id: 12, label: 'Нахлыст', value: 'flyFishing' },
      { id: 13, label: 'Тенкара', value: 'tenkara' },
      { id: 14, label: 'Херабуна', value: 'herabuna' },
      { id: 15, label: 'Танаго', value: 'tanago' },
      { id: 16, label: 'Подводная охота', value: 'spearfishing' },
    ],
    value: 9,
  },

  {
    id: 10,
    title: 'Направление',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Платная', value: 'pay' },
      { id: 2, label: 'Трофейная (от 20 кг)', value: 'trofei' },
      { id: 3, label: 'Зимняя со льда', value: 'winter' },
      { id: 6, label: 'В городе', value: 'city' },
      { id: 6, label: 'Дикие места', value: 'wildPlace' },
      { id: 6, label: 'На реках с берега', value: 'river' },
      { id: 6, label: 'На реках с лодки', value: 'riverBoat' },
      { id: 6, label: 'На озерах с берега', value: 'lake' },
      { id: 6, label: 'На озерах с лодки', value: 'lakeBoat' },
      { id: 6, label: 'Морская с пляжа', value: 'sea' },
      { id: 6, label: 'Морская в море', value: 'seaSea' },
      { id: 6, label: 'На затонувших судах', value: 'suda' },
      { id: 6, label: 'Морская на рифах', value: 'rifs' },
    ],
    value: 10,
  },
  {
    id: 11,
    title: 'Доп. условия',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Использовать кораблик', value: 'boat' },
      { id: 2, label: 'Со своей лодкой', value: 'yourBoat' },
      { id: 3, label: 'Со своей палаткой', value: 'yourTent' },
    ],
    value: 11,
  },
  {
    id: 12,
    title: 'Виды рыб',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Карп', value: 'carp' },
      { id: 2, label: 'Сазан', value: 'sazan' },
      { id: 3, label: 'Белый амур', value: 'whiteAmur' },
      { id: 4, label: 'Черный амур', value: 'blackAmur' },
      { id: 5, label: 'Сом обыковенный', value: 'commonCatfish' },
      { id: 6, label: 'Сом канальный', value: 'channelCatfish' },
      { id: 7, label: 'Толстолобик', value: 'silverCarp' },
      { id: 8, label: 'Форель радужная', value: 'rainbowTrout' },
      { id: 9, label: 'Форель золотая', value: 'goldenTrout' },
      { id: 10, label: 'Щука', value: 'pike' },
      { id: 11, label: 'Осетр', value: 'sturgeon' },
      { id: 12, label: 'Белуга', value: 'belugaSturgeon' },
      { id: 13, label: 'Линь', value: 'tench' },
      { id: 14, label: 'Сиг', value: 'sig' },
      { id: 15, label: 'Судак', value: 'zander' },
      { id: 16, label: 'Карась', value: 'crucianCarp' },
      { id: 17, label: 'Окунь', value: 'perch' },
      { id: 18, label: 'Жерех', value: 'roach' },
      { id: 19, label: 'Лещ', value: 'bream' },
      { id: 20, label: 'Хариус европейский', value: 'EuropeanChub' },
      { id: 21, label: 'Таймень', value: 'taimen' },
      { id: 22, label: 'Налим', value: 'salmon' },
      { id: 23, label: 'Язь', value: 'grayling' },
      { id: 24, label: 'Нельма', value: 'nelma' },
      { id: 25, label: 'Плотва', value: 'plotva' },
      { id: 26, label: 'Муксун', value: 'muksun' },
      { id: 27, label: 'Пелядь', value: 'vendace' },
      { id: 28, label: 'Красноперка', value: 'ruffe' },
      { id: 29, label: 'Морской карась', value: 'seaBream' },
      { id: 30, label: 'Каранакс', value: 'crappie' },
      { id: 31, label: 'Марлин', value: 'marlin' },
      { id: 32, label: 'Желтохвост', value: 'yellowtail' },
      { id: 33, label: 'Ваху', value: 'wahoo' },
      { id: 34, label: 'Корифена (махи-махи)', value: 'dorado' },
      { id: 35, label: 'Луциан-кубера', value: 'cuberaSnapper' },
      { id: 36, label: 'Красный луциан', value: 'redSnapper' },
      { id: 37, label: 'Снук', value: 'snook' },
      { id: 38, label: 'Пермит', value: 'permit' },
      { id: 39, label: 'Тунец', value: 'tuna' },
      { id: 40, label: 'Камбала', value: 'flounder' },
      { id: 41, label: 'Кобия', value: 'cobia' },
      { id: 42, label: 'Королевская макрель', value: 'kingMackerel' },
      { id: 43, label: 'Горбыль', value: 'mullet' },
      { id: 44, label: 'Амберджек', value: 'amberjack' },
      { id: 45, label: 'Рыба-петух', value: 'triggerfish' },
      { id: 46, label: 'Желтопёрый групер', value: 'yellowfinGrouper' },
      { id: 47, label: 'Парусник', value: 'sailfish' },
      { id: 48, label: 'Пятнистая форель', value: 'speckledTrout' },
      { id: 49, label: 'Барракуда', value: 'barracuda' },
      { id: 50, label: 'Бонито', value: 'bonito' },
      { id: 51, label: 'Спинорог', value: 'spinnerShark' },
      { id: 52, label: 'Черноперая акула', value: 'blacktipShark' },
    ],
    value: 12,
  },
];
export const textareaFiltersStyles = {
  height: 194,
  placeholder: 'Описание: с заглавной,  ….',
  paddingTop: 4,
  paddingLeft: 18,
  paddingRight: 18,
  bottom: 20,
};

export const capacityCountData = {
  capacity: ['1', '2', '3', '4'],
};

export const freeZoneCountData = {
  freeZone: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};
export const sleepingPlaceCountData = {
  sleepingPlace: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};
export const hotelCountData = {
  hotel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};

export const capacityObjectCountData = {
  capacityObject: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};

export const bedObjectCountData = {
  bedObject: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};
export const filterObjectRestData = [
  {
    id: 1,
    title: 'Объект',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Отель', value: 'hotel', withNumber: true },
      { id: 2, label: 'Коттедж', value: 'cottage' },
      { id: 3, label: 'Домик', value: 'littleHouse' },
      { id: 4, label: 'Беседка', value: 'alcovObject' },
      { id: 5, label: 'Сафари - тент,лодж,...', value: 'safariTent' },
      { id: 6, label: 'Баня,сауна,...', value: 'bath' },
    ],
    value: 1,
  },
];

export const filterObjectLandscapeData = [
  {
    id: 4,
    title: 'Ландшафт',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Поле, равнина', value: 'fieldObject' },
      { id: 2, label: 'Редкие деревья', value: 'treeObject' },
      { id: 3, label: 'Лесопосадка', value: 'treesObject' },
      { id: 4, label: 'Лес', value: 'forestObject' },
      { id: 5, label: 'Газон', value: 'lawnObject' },
      { id: 6, label: 'Возвышенность', value: 'hillObject' },
      { id: 7, label: 'Низменность', value: 'lowlandObject' },
    ],
    value: 4,
  },
  {
    id: 5,
    title: 'Тип объекта',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Открытый', value: 'openedObject' },
      { id: 2, label: 'Закрытый', value: 'closedObject' },
      {
        id: 3,
        label: 'Вместимость',
        value: 'capacityObject',
        withNumber: true,
        withoutCheckbox: true,
      },
    ],
    value: 5,
  },
  {
    id: 6,
    title: 'Удобства в объекте',
    type: 'checkbox',
    options: [
      { id: 1, label: 'С проживанием', value: 'accommodationObject' },
      { id: 2, label: 'Отдых с детьми', value: 'childrenObject' },
      { id: 3, label: 'С домашними животными', value: 'petsObject' },
      { id: 4, label: 'Электричество', value: 'electricityObject' },
      { id: 5, label: 'Кондиционер', value: 'conditionerObject' },
      { id: 6, label: 'Отопление', value: 'heatingObject' },
      { id: 7, label: 'Спальное место', value: 'bedObject', withNumber: true },
      { id: 8, label: 'Мебель (минимум)', value: 'furnitureObject' },
      { id: 9, label: 'Постельное белье', value: 'bedSheetsObject' },
      { id: 10, label: 'Телевизор', value: 'tvObject' },
      { id: 11, label: 'Электрическая плита', value: 'plateObject' },
      { id: 12, label: 'Чайник', value: 'kettleObject' },
      { id: 13, label: 'Туалет', value: 'toiletObject' },
      { id: 14, label: 'Водопровод', value: 'waterObject' },
      { id: 15, label: 'Душ', value: 'showerObject' },
      { id: 16, label: 'Wi-Fi', value: 'wifiObject' },
      { id: 17, label: 'Видеонаблюдение', value: 'videoObject' },
      { id: 18, label: 'Охрана', value: 'guardObject' },
      { id: 19, label: 'Уборка мусора', value: 'cleaningObject' },
    ],
    value: 6,
  },
];

export const filterObjectBathData = [
  {
    id: 7,
    title: 'Ландшафт',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Поле, равнина', value: 'fieldObjectBath' },
      { id: 2, label: 'Редкие деревья', value: 'treeObjectBath' },
      { id: 3, label: 'Лесопосадка', value: 'treesObjectBath' },
      { id: 4, label: 'Лес', value: 'forestObjectBath' },
      { id: 5, label: 'Газон', value: 'lawnObjectBath' },
      { id: 6, label: 'Возвышенность', value: 'hillObjectBath' },
      { id: 7, label: 'Низменность', value: 'lowlandObjectBath' },
    ],
    value: 7,
  },
  {
    id: 8,
    title: 'Дисциплина',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Русская', value: 'russian' },
      { id: 2, label: 'Финская', value: 'fin' },
      { id: 3, label: 'Турецкая ', value: 'turkey' },
      { id: 5, label: 'Японская', value: 'japen' },
      { id: 6, label: 'Фитосауна', value: 'fito' },
      { id: 7, label: 'Римская', value: 'rome' },
      { id: 8, label: 'Вместимость', value: 'capacityBath', withoutCheckbox: true },
    ],
    value: 8,
  },

  {
    id: 9,
    title: 'Аквазона',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Бассейн', value: 'pool' },
      { id: 2, label: 'Водопад', value: 'waterFall' },
      { id: 3, label: 'Выход к реке,озеру,...', value: 'toRiver' },
      { id: 4, label: 'Гейзер', value: 'geizer' },
      { id: 5, label: 'Гидромассаж', value: 'hydroMassage' },
      { id: 6, label: 'Горка', value: 'gorka' },
      { id: 7, label: 'Джакузи', value: 'jacusi' },
      { id: 8, label: 'Душ', value: 'showerAqua' },
      { id: 9, label: 'Купель', value: 'cupel' },
      { id: 10, label: 'Ледяной бассейн', value: 'icePool' },
      { id: 11, label: 'Сауна с морской водой', value: 'seaSpa' },
      { id: 12, label: 'Теплый бассейн', value: 'wormPool' },
      { id: 13, label: 'Ушат', value: 'ushat' },
      { id: 14, label: 'Чан', value: 'chan' },
    ],
    value: 9,
  },
  {
    id: 10,
    title: 'Для отдыха',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Ароматы для парной', value: 'aroma' },
      { id: 2, label: 'Аудио-видео аппаратура', value: 'audioVideo' },
      { id: 3, label: 'Банные принадлежности', value: 'bathAccessories' },
      { id: 4, label: 'Бар', value: 'bar' },
      { id: 5, label: 'Бесплатный Wi-Fi', value: 'freeWiFi' },
      { id: 6, label: 'Веники', value: 'dust' },
      { id: 7, label: 'Камин', value: 'hearth' },
      { id: 8, label: 'Кондиционер', value: 'conditionerForRest' },
      { id: 9, label: 'Летняя веранда', value: 'summerHouse' },
      { id: 10, label: 'Мангал', value: 'grill' },
      { id: 11, label: 'Парковка', value: 'parkingForRest' },
      { id: 12, label: 'Со своей едой', value: 'yourFood' },
      { id: 13, label: 'Солярий', value: 'sunroom' },
      { id: 14, label: 'Бильярд', value: 'billiards' },
      { id: 15, label: 'Теннис', value: 'tennis' },
      { id: 16, label: 'Услуги банщика', value: 'rubber' },
      { id: 17, label: 'Настольные игры', value: 'boardgame' },
    ],
    value: 10,
  },
];

export const styledSchema = {
  id: 7,
  title: '  Карты. Схемы',
  width: 320,
  height: 304,
  cropWidth: 141,
  cropHeight: 216,
  borderRadius: 0,
  types: 'RESIZE',
  widthIcon: 70,
  heightIcon: 70,
  viewBox: '0 0 70 70',
};

export const filterObjectService = [
  {
    id: 1,
    title: 'Услуги',
    type: 'checkbox',
    options: [
      { id: 1, label: 'Лодка', value: 'boatService' },
      { id: 2, label: 'Снасти', value: 'snastiService' },
      { id: 3, label: 'Прикормка', value: 'foodService' },
    ],
    value: 1,
  },
];

export const AreaAndDistrictOptions = {
  regionsAndAreas: {
    Южный: [
      'Астраханская область',
      'Волгоградская область',
      'Донецкая Народная Республика',
      'Запорожская область',
      'Краснодарский край',
      'Луганская Народная Республика',
      'Республика Адыгея',
      'Республика Калмыкия',
      'Республика Крым',
      'Ростовская область',
      'Херсонская область',
    ],
    Сибирский: [
      'Алтайский край',
      'Забайкальский край',
      'Иркутская область',
      'Кемеровская область',
      'Красноярский край',
      'Новосибирская область',
      'Омская область',
      'Республика Алтай',
      'Республика Тыва',
      'Республика Хакасия',
      'Томская область',
    ],
    'Северо-Западный': [
      'Архангельская область',
      'Вологодская область',
      'Калининградская область',
      'Ленинградская область',
      'Мурманская область',
      'Ненецкий автономный округ',
      'Новгородская область',
      'Псковская область',
      'Республика Карелия',
      'Республика Коми',
    ],
    Приволжский: [
      'Кировская область',
      'Нижегородская область',
      'Оренбургская область',
      'Пензенская область',
      'Пермский край',
      'Республика Башкортостан',
      'Республика Марий Эл',
      'Республика Мордовия',
      'Республика Татарстан',
      'Самарская область',
      'Саратовская область',
      'Удмуртская Республика',
      'Ульяновская область',
      'Чувашская Республика',
    ],
    Центральный: [
      'Белгородская область',
      'Брянская область',
      'Владимирская область',
      'Воронежская область',
      'Ивановская область',
      'Калужская область',
      'Костромская область',
      'Курская область',
      'Липецкая область',
      'Московская область',
      'Орловская область',
      'Рязанская область',
      'Смоленская область',
      'Тамбовская область',
      'Тверская область',
      'Тульская область',
      'Ярославская область',
    ],
    'Северо-Кавказский': [
      'Кабардино-Балкарская Республика',
      'Карачаево-Черкесская Республика',
      'Республика Дагестан',
      'Республика Ингушетия',
      'Республика Северная Осетия - Алания',
      'Ставропольский край',
      'Чеченская Республика',
    ],
    Дальневосточный: [
      'Амурская область',
      'Еврейская автономная область',
      'Забайкальский край',
      'Камчатский край',
      'Магаданская область',
      'Приморский край',
      'Республика Бурятия',
      'Республика Саха',
      'Сахалинская область',
      'Хабаровский край',
      'Чукотский автономный округ',
    ],
    Уральский: [
      'Курганская область',
      'Свердловская область',
      'Тюменская область',
      'Ханты-Мансийский автономный округ',
      'Челябинская область',
      'Ямало-Ненецкий автономный округ',
    ],
  },
  russiaAreas: [
    'Алтайский край',
    'Амурская область',
    'Архангельская область',
    'Астраханская область',
    'Белгородская область',
    'Брянская область',
    'Владимирская область',
    'Волгоградская область',
    'Вологодская область',
    'Воронежская область',
    'Донецкая Народная Республика',
    'Еврейская автономная область',
    'Забайкальский край',
    'Забайкальский край',
    'Запорожская область',
    'Ивановская область',
    'Иркутская область',
    'Кабардино-Балкарская Республика',
    'Калининградская область',
    'Калужская область',
    'Камчатский край',
    'Карачаево-Черкесская Республика',
    'Кемеровская область',
    'Кировская область',
    'Костромская область',
    'Краснодарский край',
    'Красноярский край',
    'Курганская область',
    'Курская область',
    'Ленинградская область',
    'Липецкая область',
    'Луганская Народная Республика',
    'Магаданская область',
    'Московская область',
    'Мурманская область',
    'Ненецкий автономный округ',
    'Нижегородская область',
    'Новгородская область',
    'Новосибирская область',
    'Омская область',
    'Оренбургская область',
    'Орловская область',
    'Пензенская область',
    'Пермский край',
    'Приморский край',
    'Псковская область',
    'Республика Адыгея',
    'Республика Алтай',
    'Республика Башкортостан',
    'Республика Бурятия',
    'Республика Дагестан',
    'Республика Ингушетия',
    'Республика Калмыкия',
    'Республика Карелия',
    'Республика Коми',
    'Республика Крым',
    'Республика Марий Эл',
    'Республика Мордовия',
    'Республика Саха',
    'Республика Северная Осетия - Алания',
    'Республика Татарстан',
    'Республика Тыва',
    'Республика Хакасия',
    'Ростовская область',
    'Рязанская область',
    'Самарская область',
    'Саратовская область',
    'Сахалинская область',
    'Свердловская область',
    'Смоленская область',
    'Ставропольский край',
    'Тамбовская область',
    'Тверская область',
    'Томская область',
    'Тульская область',
    'Тюменская область',
    'Удмуртская Республика',
    'Ульяновская область',
    'Хабаровский край',
    'Ханты-Мансийский автономный округ',
    'Херсонская область',
    'Челябинская область',
    'Чеченская Республика',
    'Чувашская Республика',
    'Чукотский автономный округ',
    'Ямало-Ненецкий автономный округ',
    'Ярославская область',
  ],
  russiaDistricts: [
    'Дальневосточный',
    'Приволжский',
    'Северо-Западный',
    'Северо-Кавказский',
    'Сибирский',
    'Уральский',
    'Центральный',
    'Южный',
  ],
  belarusAreas: [
    'Брестская область',
    'Витебская область',
    'Гомельская область',
    'Гродненская область',
    'Минская область',
    'Могилевская область',
  ],
  kazachstanAreas: [
    'Абайская область',
    'Акмолинская область',
    'Актюбинская область',
    'Алматинская область',
    'Атырауская область',
    'Восточно-Казахстанская область',
    'Жамбылская область',
    'Жетысуская область',
    'Западно-Казахстанская область',
    'Карагандинская область',
    'Костанайская область',
    'Кызылординская область',
    'Мангистауская область',
    'Павлодарская область',
    'Северо-Казахстанская область',
    'Туркестанская область',
    'Улытауская область',
  ],
};
