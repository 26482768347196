import React from 'react';

import avatarPlaceholder from '../assets/images/avatar-placeholder.png';
import bannerPlaceholder from '../assets/images/banner-placeholder.png';
import { px } from '../constants/Px';

export const LoyalCard = ({
  bgColor,
  color,
  data: {
    openUntill,
    bgImg,
    ownerName,
    cardType,
    percent,
    logo: { logoSrc, showLogo },
  },
}) => (
  <div
    className="flex-column relative flex justify-between bg-cover bg-no-repeat"
    style={{
      width: px(135),
      height: px(100),
      borderRadius: px(10),
      padding: `${0} ${px(10)} ${px(5)} ${px(10)}`,
      color,
      background: bgColor,
      backgroundImage: bgImg && `url(${process.env.REACT_APP_STATIC_URL}${bgImg})`,
    }}
  >
    {showLogo && (
      <div className="absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center overflow-hidden">
        <div
          style={{
            width: px(40),
            height: px(40),
            boxShadow: '0 4px 6px 0 #00000029',
            borderRadius: px(10),
            overflow: 'hidden',
          }}
        >
          {logoSrc && (
            <img
              className="h-full w-full object-cover"
              src={`${process.env.REACT_APP_STATIC_URL}${logoSrc}`}
              alt="avatarImage"
            />
          )}
        </div>
      </div>
    )}
    <p
      className="self-end font-normal text-[#fa0202]"
      style={{
        fontSize: px(15),
        lineHeight: px(20),
      }}
    >
      {percent || 0}%
    </p>
    <div className="flex flex-row justify-between">
      <div className="w-full">
        <p
          className="... truncate font-light"
          style={{
            fontSize: px(8),
            lineHeight: px(10),
            color: bgImg ? '#fff' : '#000',
            width: px(85),
          }}
        >
          {ownerName}
        </p>
        <p
          className="font-light"
          style={{
            fontSize: px(8),
            lineHeight: px(10),
            color: bgImg ? '#fff' : '#000',
          }}
        >
          {openUntill}
        </p>
      </div>
      <p
        className="font-light"
        style={{
          fontSize: px(8),
          lineHeight: px(10),
          color: bgImg ? '#fff' : '#000',
        }}
      >
        {cardType}
      </p>
    </div>
  </div>
);

export default LoyalCard;
