// TODO: implement sort by prices

export const sortOptions = [
  {
    id: 1,
    title: 'По умолчанию',
    cb: (a, b) => b.id - a.id,
  },
  {
    id: 2,
    title: 'Последовательно',
    cb: (a, b) => a.id - b.id,
  },
  {
    id: 3,
    title: 'По группам ( А - Z )',
    cb: (a, b) => a.sectionGroupId - b.sectionGroupId,
  },
  {
    id: 4,
    title: 'По цене',
    cb: (a, b) => b.id - a.id,
  },
  {
    id: 5,
    title: 'Сначала Скидки',
    cb: (a, b) => b.id - a.id,
  },
  {
    id: 6,
    title: 'Сначала Акции',
    value: 'blocked',
    cb: (a, b) => b.id - a.id,
  },
];

export const sortOptionsCards = [
  {
    id: 0,
    title: 'По умолчанию',
    cb: (a, b) => b.id - a.id,
  },
  {
    id: 1,
    title: 'По дате присвоения',
    cb: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  },
  {
    id: 2,
    title: 'По дате окончания',
    cb: (a, b) => new Date(a.openUntill).getTime() - new Date(b.openUntill).getTime(),
  },
  {
    id: 3,
    title: 'Последовательно',
    cb: (a, b) => a.id - b.id,
  },
  {
    id: 4,
    title: 'Сначала новые',
    cb: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  },
  {
    id: 5,
    title: 'Заблокированные',
    cb: (a, b) => new Date(a.blockedUntill).getTime() - new Date(b.blockedUntill).getTime(),
  },
  {
    id: 6,
    title: 'Удаленные',
    cb: (a, b) => new Date(a.deletedAt).getTime() - new Date(b.deletedAt).getTime(),
  },
  {
    id: 7,
    title: 'Утратившие действия',
    cb: (a, b) => new Date(a.openUntill).getTime() - new Date(b.openUntill).getTime(),
  },
];
