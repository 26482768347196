import * as authApi from '@/api/attachment';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_ATTACHMENT_EVENT = 'attachment/getAttachmentEvent';


export const getAttachmentEvent = createAsyncThunk(
  GET_ATTACHMENT_EVENT,
  createRequest({
    type: GET_ATTACHMENT_EVENT,
    loader: (data) => authApi.getAttachmentEvent(data),
  }),
);
