import { request } from '../../helpers/api/request';

export const loginCode = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `auth/login/code`,
    method: 'POST',
    data,
  });
  return response;
};
