export const alphabet = {
  letters: [
    '-',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ],
};

export const test = ['Л', 'П'];

export const interestData = {
  interest: [
    '1%',
    '2%',
    '3%',
    '4%',
    '5%',
    '6%',
    '7%',
    '8%',
    '9%',
    '10%',
    '11%',
    '12%',
    '13%',
    '14%',
    '15%',
    '16%',
    '17%',
    '18%',
    '19%',
    '20%',
    '21%',
    '22%',
    '23%',
    '24%',
    '25%',
    '26%',
    '27%',
    '28%',
    '29%',
    '30%',
    '31%',
    '32%',
    '33%',
    '34%',
    '35%',
    '36%',
    '37%',
    '38%',
    '39%',
    '40%',
    '41%',
    '42%',
    '43%',
    '44%',
    '45%',
    '46%',
    '47%',
    '48%',
    '49%',
    '50%',
    '51%',
    '52%',
    '53%',
    '54%',
    '55%',
    '56%',
    '57%',
    '58%',
    '59%',
    '60%',
    '61%',
    '62%',
    '63%',
    '64%',
    '65%',
    '66%',
    '67%',
    '68%',
    '69%',
    '70%',
    '71%',
    '72%',
    '73%',
    '74%',
    '75%',
    '76%',
    '77%',
    '78%',
    '79%',
    '80%',
    '81%',
    '82%',
    '83%',
    '84%',
    '85%',
    '86%',
    '87%',
    '88%',
    '89%',
    '90%',
    '91%',
    '92%',
    '93%',
    '94%',
    '95%',
    '96%',
    '97%',
    '98%',
    '99%',
    '100%',
  ],
};

export const priceData = {
  freeFirstNumber: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
    '100',
  ],
  freeSecondNumber: [
    '0',
    '50',
    '100',
    '150',
    '200',
    '250',
    '300',
    '350',
    '400',
    '450',
    '500',
    '550',
    '600',
    '650',
    '700',
    '750',
    '800',
    '850',
    '900',
    '950',
  ],
  freeThirdNumber: [
    '0',
    '1000',
    '2000',
    '3000',
    '4000',
    '5000',
    '6000',
    '7000',
    '8000',
    '9000',
    '10000',
    '11000',
    '12000',
    '13000',
    '14000',
    '15000',
    '16000',
    '17000',
    '18000',
    '19000',
    '20000',
    '21000',
    '22000',
    '23000',
    '24000',
    '25000',
    '26000',
    '27000',
    '28000',
    '29000',
    '30000',
    '31000',
    '32000',
    '33000',
    '34000',
    '35000',
    '36000',
    '37000',
    '38000',
    '39000',
    '40000',
    '41000',
    '42000',
    '43000',
    '44000',
    '45000',
    '46000',
    '47000',
    '48000',
    '49000',
    '50000',
    '51000',
    '52000',
    '53000',
    '54000',
    '55000',
    '56000',
    '57000',
    '58000',
    '59000',
    '60000',
    '61000',
    '62000',
    '63000',
    '64000',
    '65000',
    '66000',
    '67000',
    '68000',
    '69000',
    '70000',
    '71000',
    '72000',
    '73000',
    '74000',
    '75000',
    '76000',
    '77000',
    '78000',
    '79000',
    '80000',
    '81000',
    '82000',
    '83000',
    '84000',
    '85000',
    '86000',
    '87000',
    '88000',
    '89000',
    '90000',
    '91000',
    '92000',
    '93000',
    '94000',
    '95000',
    '96000',
    '97000',
    '98000',
    '99000',
    '100000',
    '101000',
    '102000',
    '103000',
    '104000',
    '105000',
    '106000',
    '107000',
    '108000',
    '109000',
    '110000',
    '111000',
    '112000',
    '113000',
    '114000',
    '115000',
    '116000',
    '117000',
    '118000',
    '119000',
    '120000',
    '121000',
    '122000',
    '123000',
    '124000',
    '125000',
    '126000',
    '127000',
    '128000',
    '129000',
    '130000',
    '131000',
    '132000',
    '133000',
    '134000',
    '135000',
    '136000',
    '137000',
    '138000',
    '139000',
    '140000',
    '141000',
    '142000',
    '143000',
    '144000',
    '145000',
    '146000',
    '147000',
    '148000',
    '149000',
    '150000',
    '151000',
    '152000',
    '153000',
    '154000',
    '155000',
    '156000',
    '157000',
    '158000',
    '159000',
    '160000',
    '161000',
    '162000',
    '163000',
    '164000',
    '165000',
    '166000',
    '167000',
    '168000',
    '169000',
    '170000',
    '171000',
    '172000',
    '173000',
    '174000',
    '175000',
    '176000',
    '177000',
    '178000',
    '179000',
    '180000',
    '181000',
    '182000',
    '183000',
    '184000',
    '185000',
    '186000',
    '187000',
    '188000',
    '189000',
    '190000',
    '191000',
    '192000',
    '193000',
    '194000',
    '195000',
    '196000',
    '197000',
    '198000',
    '199000',
    '200000',
    '201000',
    '202000',
    '203000',
    '204000',
    '205000',
    '206000',
    '207000',
    '208000',
    '209000',
    '210000',
    '211000',
    '212000',
    '213000',
    '214000',
    '215000',
    '216000',
    '217000',
    '218000',
    '219000',
    '220000',
    '221000',
    '222000',
    '223000',
    '224000',
    '225000',
    '226000',
    '227000',
    '228000',
    '229000',
    '230000',
    '231000',
    '232000',
    '233000',
    '234000',
    '235000',
    '236000',
    '237000',
    '238000',
    '239000',
    '240000',
    '241000',
    '242000',
    '243000',
    '244000',
    '245000',
    '246000',
    '247000',
    '248000',
    '249000',
    '250000',
    '251000',
    '252000',
    '253000',
    '254000',
    '255000',
    '256000',
    '257000',
    '258000',
    '259000',
    '260000',
    '261000',
    '262000',
    '263000',
    '264000',
    '265000',
    '266000',
    '267000',
    '268000',
    '269000',
    '270000',
    '271000',
    '272000',
    '273000',
    '274000',
    '275000',
    '276000',
    '277000',
    '278000',
    '279000',
    '280000',
    '281000',
    '282000',
    '283000',
    '284000',
    '285000',
    '286000',
    '287000',
    '288000',
    '289000',
    '290000',
    '291000',
    '292000',
    '293000',
    '294000',
    '295000',
    '296000',
    '297000',
    '298000',
    '299000',
    '300000',
  ],
};

export const priceModal = [
  {
    id: 5,
    title: 'Начало периода',
    data: [{ id: 1, subTitle: 'Время' }],
  },
  {
    id: 6,
    title: 'Конец периода',
    data: [{ id: 3, subTitle: 'Время' }],
  },
];

export const period = {
  period: [
    '30 минут',
    '1 час',
    '2 часа',
    '3 часа',
    '4 часа',
    '5 часов',
    '6 часов',
    '7 часов',
    '8 часов',
    '9 часов',
    '10 часов',
    '11 часов',
    '12 часов',
    '13 часов',
    '14 часов',
    '15 часов',
    '16 часов',
    '17 часов',
    '18 часов',
    '19 часов',
    '20 часов',
    '21 час',
    '22 часа',
    '23 часа',
    '24 часа',
  ],
};

export const hoursNumber = {
  hoursNumber: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
  ],
};

export const noLimitData = {
  noLimit: [
    '1%',
    '2%',
    '3%',
    '4%',
    '5%',
    '6%',
    '7%',
    '8%',
    '9%',
    '10%',
    '11%',
    '12%',
    '13%',
    '14%',
    '15%',
    '16%',
    '17%',
    '18%',
    '19%',
    '20%',
    '21%',
    '22%',
    '23%',
    '24%',
    '25%',
    '26%',
    '27%',
    '28%',
    '29%',
    '30%',
    '31%',
    '32%',
    '33%',
    '34%',
    '35%',
    '36%',
    '37%',
    '38%',
    '39%',
    '40%',
    '41%',
    '42%',
    '43%',
    '44%',
    '45%',
    '46%',
    '47%',
    '48%',
    '49%',
    '50%',
    '51%',
    '52%',
    '53%',
    '54%',
    '55%',
    '56%',
    '57%',
    '58%',
    '59%',
    '60%',
    '61%',
    '62%',
    '63%',
    '64%',
    '65%',
    '66%',
    '67%',
    '68%',
    '69%',
    '70%',
    '71%',
    '72%',
    '73%',
    '74%',
    '75%',
    '76%',
    '77%',
    '78%',
    '79%',
    '80%',
    '81%',
    '82%',
    '83%',
    '84%',
    '85%',
    '86%',
    '87%',
    '88%',
    '89%',
    '90%',
    '91%',
    '92%',
    '93%',
    '94%',
    '95%',
    '96%',
    '97%',
    '98%',
    '99%',
    '100%',
  ],
};

export const weekendExtraChargeData = {
  weekendExtraCharge: [
    '1%',
    '2%',
    '3%',
    '4%',
    '5%',
    '6%',
    '7%',
    '8%',
    '9%',
    '10%',
    '11%',
    '12%',
    '13%',
    '14%',
    '15%',
    '16%',
    '17%',
    '18%',
    '19%',
    '20%',
    '21%',
    '22%',
    '23%',
    '24%',
    '25%',
    '26%',
    '27%',
    '28%',
    '29%',
    '30%',
    '31%',
    '32%',
    '33%',
    '34%',
    '35%',
    '36%',
    '37%',
    '38%',
    '39%',
    '40%',
    '41%',
    '42%',
    '43%',
    '44%',
    '45%',
    '46%',
    '47%',
    '48%',
    '49%',
    '50%',
    '51%',
    '52%',
    '53%',
    '54%',
    '55%',
    '56%',
    '57%',
    '58%',
    '59%',
    '60%',
    '61%',
    '62%',
    '63%',
    '64%',
    '65%',
    '66%',
    '67%',
    '68%',
    '69%',
    '70%',
    '71%',
    '72%',
    '73%',
    '74%',
    '75%',
    '76%',
    '77%',
    '78%',
    '79%',
    '80%',
    '81%',
    '82%',
    '83%',
    '84%',
    '85%',
    '86%',
    '87%',
    '88%',
    '89%',
    '90%',
    '91%',
    '92%',
    '93%',
    '94%',
    '95%',
    '96%',
    '97%',
    '98%',
    '99%',
    '100%',
  ],
};
export const assistantData = {
  assistant: [
    '-',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};
export const assistantDataWithZero = {
  assistant: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ],
};

export const arrayTest = {
  month: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  day: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ],
  year: [
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2033',
    '2034',
    '2035',
    '2036',
    '2037',
    '2038',
    '2039',
    '2040',
    '2041',
    '2042',
    '2043',
    '2044',
    '2045',
    '2046',
    '2047',
    '2048',
    '2049',
    '2050',
    '2051',
    '2052',
    '2053',
    '2054',
    '2055',
    '2056',
    '2057',
    '2058',
    '2059',
    '2060',
  ],
};

export const periodEndData = {
  periodEnd: [
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '24:00',
  ],
};

export const periodData = {
  month: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  day: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ],
  year: [
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2033',
    '2034',
    '2035',
    '2036',
    '2037',
    '2038',
    '2039',
    '2040',
    '2041',
    '2042',
    '2043',
    '2044',
    '2045',
    '2046',
    '2047',
    '2048',
    '2049',
    '2050',
    '2051',
    '2052',
    '2053',
    '2054',
    '2055',
    '2056',
    '2057',
    '2058',
    '2059',
    '2060',
  ],
};
export const sectorData = {
  sectorSide: ['-', 'Л', 'П'],
};
