import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/auth';

export const AUTH_CODE_UPDATE = 'auth/authCodeUpdate';

export const authCodeUpdate = createAsyncThunk(
  AUTH_CODE_UPDATE,
  createRequest({
    type: AUTH_CODE_UPDATE,
    loader: (data) => authApi.authCodeUpdate(data),
  }),
);
