import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_CARD = 'card/createCard';

export const createCard = createAsyncThunk(
  CREATE_CARD,
  createRequest({
    type: CREATE_CARD,
    loader: data => authApi.createCard(data),
  }),
);
