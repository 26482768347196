/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { CircleButton } from '../new-architecture/shared';
import { px } from '../new-architecture/utils';
import { authActiveType } from '../selectors/auth';
import { TypesIcon } from '../types/TypesIcon';

export const Menu = ({ activeId }) => {
  const navigate = useNavigate();
  const activeType = useSelector(authActiveType);

  return (
    <>
      <img
        src="/menu.png"
        alt="cover"
        style={{
          position: 'absolute',
          top: px(60),
          minHeight: `calc(100dvh - ${px(60)})`,
          width: px(320),
          bottom: 0,
          objectFit: 'cover',
        }}
      />
      <section
        style={{
          position: 'relative',
          zIndex: 5,
          minWidth: px(320),
        }}
      >
        <div
          className="flex cursor-pointer items-center justify-center overflow-hidden"
          style={{
            position: 'absolute',
            top: px(106),
            left: px(95),
            height: px(130),
            width: px(130),
            borderRadius: px(100),
          }}
        >
          <img
            src="/booking-cover.png"
            alt="cover"
            style={{
              position: 'absolute',
              objectFit: 'cover',
              minHeight: px(130),
              /* width: px(320), */
            }}
          />
          <svg
            className="z-10"
            xmlns="http://www.w3.org/2000/svg"
            width={px(63.72)}
            height={px(73.252)}
            viewBox="0 0 63.72 73.252"
          >
            <g id="Сгруппировать_3131" data-name="Сгруппировать 3131" transform="translate(0 -2)">
              <g id="Сгруппировать_2773" data-name="Сгруппировать 2773" transform="translate(0 0)">
                <g id="Сгруппировать_2735" data-name="Сгруппировать 2735">
                  <rect
                    id="Прямоугольник_618"
                    data-name="Прямоугольник 618"
                    width="0.879"
                    height="39.991"
                    rx="0.439"
                    transform="translate(8.996 35.964) rotate(13)"
                    fill="#fff"
                  />
                  <rect
                    id="Прямоугольник_619"
                    data-name="Прямоугольник 619"
                    width="0.879"
                    height="46.043"
                    rx="0.439"
                    transform="translate(48.34 35.449) rotate(90)"
                    fill="#fff"
                  />
                  <rect
                    id="Прямоугольник_620"
                    data-name="Прямоугольник 620"
                    width="0.879"
                    height="56.151"
                    rx="0.439"
                    transform="matrix(-0.978, 0.208, -0.208, -0.978, 63.72, 75.069)"
                    fill="#fff"
                  />
                  <rect
                    id="Прямоугольник_621"
                    data-name="Прямоугольник 621"
                    width="0.879"
                    height="17.918"
                    rx="0.439"
                    transform="translate(51.955 21.098) rotate(-151)"
                    fill="#fff"
                  />
                  <text
                    id="БРО"
                    transform="translate(17 13)"
                    fill="#fff"
                    fontSize="10"
                    fontFamily="SegoeUI-Light, Segoe UI"
                    fontWeight="300"
                    letterSpacing="0.3em"
                  >
                    <tspan x="0" y="0">
                      БРО
                    </tspan>
                  </text>
                  <text
                    id="НИР"
                    transform="translate(17 28.001)"
                    fill="#fff"
                    fontSize="10"
                    fontFamily="SegoeUI-Light, Segoe UI"
                    fontWeight="300"
                    letterSpacing="0.3em"
                  >
                    <tspan x="0" y="0">
                      НИР
                    </tspan>
                  </text>
                </g>
                <text
                  id="ОВА"
                  transform="translate(17 51)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="SegoeUI-Light, Segoe UI"
                  fontWeight="300"
                  letterSpacing="0.3em"
                >
                  <tspan x="0" y="0">
                    ОВА
                  </tspan>
                </text>
                <text
                  id="НИЯ"
                  transform="translate(17 66.001)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="SegoeUI-Light, Segoe UI"
                  fontWeight="300"
                  letterSpacing="0.3em"
                >
                  <tspan x="0" y="0">
                    НИЯ
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
        <CircleButton
          disabled
          className="!group-active:[&_*]:fill-[#0A84FE] ![&_*]:!fill-[#0A84FE]"
          style={{
            position: 'absolute',
            top: px(20),
            left: px(20),
            height: px(100),
            width: px(100),
            borderRadius: '50%',
            background: '#0A84FE',
          }}
          icon={{
            type: TypesIcon.DIAGRAMS,
            viewBox: '0 0 61.584 34.768',
            width: px(61.584),
            height: px(34.768),
          }}
        />
        <CircleButton
          disabled
          style={{
            position: 'absolute',
            top: px(15),
            left: px(140),
            height: px(70),
            width: px(70),
            borderRadius: '50%',
          }}
          icon={{
            type: TypesIcon.LAMPBLUE,
            viewBox: '0 0 24.715 29.646',
            width: px(38.17),
            height: px(45.78),
            fill: '#FFFFFF',
          }}
        />
        <CircleButton
          onClick={() => {
            if (activeId) navigate('/favourites');
            else console.log('click');
          }}
          disabled={!activeId}
          style={{
            position: 'absolute',
            top: px(56),
            left: px(220),
            height: px(85),
            width: px(85),
            borderRadius: '50%',
            color: 'white',
          }}
          icon={
            activeId
              ? {
                  type: TypesIcon.STAR,
                  viewBox: '0 0 27.5 26.233',
                  width: px(47),
                  height: px(44.83),
                  fill: '#0A84FE',
                }
              : {
                  type: TypesIcon.FRAME,
                  viewBox: '0 0 44.835 44.835',
                  width: px(44.835),
                  height: px(44.835),
                  fill: '#0A84FE',
                }
          }
        />
        <CircleButton
          disabled
          style={{
            position: 'absolute',
            top: px(136),
            left: px(15),
            height: px(60),
            width: px(60),
            borderRadius: '50%',
          }}
          icon={{
            type: TypesIcon.SEVEN,
            viewBox: '0 0 24.5 31',
            width: px(32.25),
            height: px(37),
            fill: '#0A84FE',
          }}
        />
        <CircleButton
          onClick={() =>
            navigate(
              activeId
                ? '/profile'
                : activeType === 'object' || activeType === 'administeredObject'
                  ? '/user-object'
                  : activeType === 'competition' || activeType === 'administeredCompetition'
                    ? '/user-competition'
                    : '/user-guide',
            )
          }
          style={{
            position: 'absolute',
            top: px(181),
            left: px(230),
            height: px(75),
            width: px(75),
            borderRadius: '50%',
          }}
          icon={{
            type: TypesIcon.SETTINGS,
            viewBox: '0 0 28.214 28.214',
            width: px(44.1),
            height: px(44.1),
            fill: '#0A84FE',
          }}
        />
        <CircleButton
          disabled
          style={{
            position: 'absolute',
            top: px(216),
            left: px(25),
            height: px(85),
            width: px(85),
            borderRadius: '50%',
            background: '#0A84FE',
          }}
          icon={{
            type: TypesIcon.OPENMAIL,
            viewBox: '0 0 40.081 40.081',
            width: px(40.081),
            height: px(40.081),
            fill: '#0A84FE',
          }}
        />
        <CircleButton
          disabled={activeId}
          style={{
            position: 'absolute',
            top: px(256),
            left: px(122),
            height: px(95),
            width: px(95),
            borderRadius: '50%',
            background: '#0A84FE',
          }}
          icon={
            activeId
              ? {
                  type: TypesIcon.CIRCLES,
                  viewBox: '0 0 37.361 41.58',
                  width: px(35.86),
                  height: px(40.08),
                  className: '[&_*]:!fill-[#0A84FE]',
                  fill: '#0A84FE',
                }
              : {
                  type: TypesIcon.RECORDS,
                  viewBox: '0 0 39.782 39.694',
                  width: px(39.782),
                  height: px(39.694),
                  className: '[&_*]:!fill-[#0A84FE]',
                  fill: '#0A84FE',
                }
          }
        />
        {activeId ? (
          <CircleButton
            disabled
            style={{
              position: 'absolute',
              top: px(276),
              left: px(237),
              height: px(65),
              width: px(65),
              borderRadius: '50%',
              background: '#0A84FE',
            }}
            icon={{
              type: TypesIcon.TOP,
              viewBox: '0 0 31.986 41.08',
              width: px(31.986),
              height: px(41.08),
              fill: '#0A84FE',
              className: '[&_*]:!fill-[#0A84FE]',
            }}
          />
        ) : (
          <div
            style={{
              position: 'absolute',
              top: px(276),
              left: px(237),
              height: px(65),
              width: px(65),
              borderRadius: '50%',
              background: '#0A84FE',
              opacity: 0.3,
            }}
          />
        )}
        {activeId ? (
          <CircleButton
            disabled
            style={{
              position: 'absolute',
              top: px(321),
              left: px(37),
              height: px(75),
              width: px(75),
              borderRadius: '50%',
              background: '#0A84FE',
            }}
            icon={{
              type: TypesIcon.NOTE,
              viewBox: '0 0 40.375 29.125',
              width: px(40.375),
              height: px(29.125),
              fill: '#0A84FE',
            }}
          />
        ) : (
          <div
            style={{
              position: 'absolute',
              top: px(321),
              left: px(37),
              height: px(75),
              width: px(75),
              borderRadius: '50%',
              background: '#0A84FE',
              opacity: 0.3,
            }}
          />
        )}
        {activeId ? (
          <CircleButton
            disabled
            style={{
              position: 'absolute',
              top: px(361),
              left: px(174),
              height: px(105),
              width: px(105),
              borderRadius: '50%',
              background: '#0A84FE',
            }}
            icon={{
              type: TypesIcon.SOMETHING,
              viewBox: '0 0 42.776 47.765',
              width: px(42.776),
              height: px(47.765),
              fill: '#0A84FE',
            }}
          />
        ) : (
          <div
            style={{
              position: 'absolute',
              top: px(361),
              left: px(174),
              height: px(105),
              width: px(105),
              borderRadius: '50%',
              background: '#0A84FE',
              opacity: 0.3,
            }}
          />
        )}
        {activeId ? (
          <CircleButton
            disabled
            style={{
              position: 'absolute',
              top: px(416),
              left: px(69),
              height: px(85),
              width: px(85),
              borderRadius: '50%',
              background: '#0A84FE',
            }}
            icon={{
              type: TypesIcon.NOTEBOOK,
              viewBox: '0 0 42 42.688',
              width: px(42),
              height: px(42.688),
              fill: '#0A84FE',
            }}
          />
        ) : (
          <div
            style={{
              position: 'absolute',
              top: px(416),
              left: px(69),
              height: px(85),
              width: px(85),
              borderRadius: '50%',
              background: '#0A84FE',
              opacity: 0.3,
            }}
          />
        )}
        <CircleButton
          disabled
          style={{
            position: 'absolute',
            top: px(481),
            left: px(265),
            height: px(40),
            width: px(40),
            borderRadius: '50%',
          }}
          icon={{
            type: TypesIcon.DOTSMORE,
            viewBox: '0 0 28 6',
            width: px(28),
            height: px(6),
            className: '[&_*]:!fill-[#0A84FE] [&_*]:!stroke-[#0A84FE]',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: px(501),
            left: px(15),
            height: px(65),
            width: px(65),
            borderRadius: '50%',
            background: '#0A84FE',
            opacity: 0.3,
          }}
        />
        {/* <CircleButton
        style={{
          position: 'absolute',
          top: px(501),
          left: px(15),
          height: px(65),
          width: px(65),
          borderRadius: '50%',
          background: '#0A84FE',
        }}
      /> */}
        <div
          style={{
            position: 'absolute',
            top: px(486),
            left: px(154),
            height: px(85),
            width: px(85),
            borderRadius: '50%',
            background: '#0A84FE',
            opacity: 0.3,
          }}
        />
        {/* <CircleButton
        style={{
          position: 'absolute',
          top: px(486),
          left: px(154),
          height: px(85),
          width: px(85),
          borderRadius: '50%',
          background: '#0A84FE',
        }}
      /> */}
      </section>
    </>
  );
};

export default Menu;
