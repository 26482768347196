import React from 'react';

export const Option = ({
  className,
  style,
  onClick,
  children,
  title,
  arrowDown,
  ...properties
}) => (
  <div
    {...properties}
    className={`option ${className || ''}`}
    style={style}
    onClick={onClick}
    onKeyDown={onClick}
  >
    {arrowDown && (
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
        <g id="down-arrow" transform="translate(-2.247 -55.333)">
          <g id="Сгруппировать_37" data-name="Сгруппировать 37" transform="translate(2.247 55.333)">
            <path
              id="Контур_29"
              data-name="Контур 29"
              d="M0,53.333l4,6,4-6Z"
              transform="translate(0 -53.333)"
            />
          </g>
        </g>
      </svg>
    )}
    {children}
  </div>
);

export default Option;
