import { createReducer } from '@reduxjs/toolkit';
import { search } from '../../actions/search';



export const searchedValue = createReducer({}, (builder) => {
  builder.addCase(search.fulfilled, (state, { payload }) => ({
    ...state,
    valueSearch: payload?.data?.data,
  }));

});
