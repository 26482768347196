import React, { useMemo } from 'react';

import { px } from '../constants/Px';
import { useProfile } from '../new-architecture/profile/hooks';
import { Sector } from '../new-architecture/profile/offers/components/fishing-menu/components';
import { mapSectorToFields } from '../new-architecture/profile/offers/helpers/sector.helpers';
import { Button, Dropdown } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { ModalHeader } from './modal-header';

export const SectorModal = ({
  data,
  title,
  alternativeTitle,
  subTitle,
  selectedItems,
  handleComponentClick,
  handleNavigateBack,
  buttonTitle,
  onClick,
  disabled,
}) => {
  const {
    data: { locations, selectedLocationId },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const selectedLocation = useMemo(
    () => locations?.find(location => location.id === selectedLocationId) || locations[0],
    [locations, selectedLocationId, useProfile],
  );

  return (
    <div>
      {title && <ModalHeader title={title} />}
      {alternativeTitle && (
        <div className="home-add-popup-container" style={{ position: 'relative', height: px(60) }}>
          <div className="home-add-popup-container-overflow" style={{ height: px(60) }}>
            <div className="home-add-popup-content">
              <p
                className="absolute w-full text-center"
                style={{ top: px(21), lineHeight: px(27) }}
              >
                {alternativeTitle}
              </p>
              <div
                className="search-menu-item-preferences-close"
                onClick={handleNavigateBack}
                aria-hidden
              >
                <Icon type={TypesIcon.CLOSE} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          maxWidth: '768px',
          paddingTop: px(10),
          overflowY: 'auto',
          minHeight: px(568),
          margin: '0 auto',
          /* height: '100vh', */
          /* maxHeight: '-webkit-fill-available', */
        }}
      >
        {subTitle && (
          <div
            style={{
              paddingTop: px(20),
              paddingBottom: px(18),
            }}
          >
            <p
              style={{
                fontSize: px(32),
                lineHeight: px(43),
              }}
              className="text-center"
            >
              {subTitle}
            </p>
          </div>
        )}
        <div
          style={{
            paddingLeft: px(15),
            paddingRight: px(15),
          }}
        >
          <Dropdown
            items={locations?.map(({ name: label, id: value }) => ({ label, value }))}
            // eslint-disable-next-line unicorn/consistent-destructuring
            value={selectedLocation?.id}
            onChange={id => {
              setProfile({ selectedLocationId: locations?.find(i => i?.id === id)?.id });
            }}
          />
          <div
            style={{
              paddingTop: px(20),
              gap: px(10),
              paddingBottom: px(80),
            }}
            className="flex flex-wrap"
          >
            {data?.map(item => (
              <Sector
                key={item?.id}
                sector={mapSectorToFields(item, locations, selectedLocationId)}
                // sectorNumber={item?.sectorNumber}
                // discount={item?.discount}
                onClick={() => {
                  handleComponentClick(item?.id);
                }}
                // type={item}
                // price={item?.price}
                active={selectedItems?.includes(item?.id)}
              />
            ))}
            {buttonTitle && (
              <div
                style={{
                  marginRight: px(0),
                  marginBottom: px(80),
                }}
                className="w-full"
              >
                <Button
                  className="w-full border-[1px] border-solid border-[#dddddd]"
                  marginTop={10}
                  height={60}
                  borderRadius={0}
                  onClick={onClick}
                  disabled={disabled}
                >
                  {buttonTitle}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorModal;
