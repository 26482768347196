import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as favouritesApi from '@/api/favourites';
import { getFavourites } from './get-favourites';

export const ADD_FAVOURITES = 'favourites/addFavourites';

const onSuccess = () => async (dispatch) => {
    dispatch(
      getFavourites()
    );
  };

export const addFavourites = createAsyncThunk(
    ADD_FAVOURITES,
    createRequest({
        type: ADD_FAVOURITES,
        loader: (data) => favouritesApi.addFavourites(data),
        onSuccess,
    }),
);