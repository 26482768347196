/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import { px } from '../constants/Px';

export const FooterSlider = ({
  cards,
  slidesPerView,
  gap,
  loop,
  pagination,
  children,
  onRealIndexChange,
  centeredSlides,
}) => (
  <Swiper
    className={`swiper-wrapper ${cards ? 'pagination' : ''}`}
    slidesPerView={slidesPerView}
    spaceBetween={gap}
    loop={loop}
    centeredSlides={centeredSlides}
    modules={[Pagination]}
    pagination={{ el: '.loyal-card-pagination', clickable: true }}
    onRealIndexChange={onRealIndexChange && (element => onRealIndexChange(element.activeIndex))}
  >
    {children}
    {pagination && (
      <div
        className="loyal-card-pagination"
        style={{ position: 'absolute', left: '0', bottom: px(-18) }}
      />
    )}
  </Swiper>
);
