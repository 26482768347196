import * as authApi from '@/api/event-review';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_OBJECT_FEEDBACK_NOAUTH = 'event-review/getObjectFeedbackNoAuth';

export const getObjectFeedbackNoAuth = createAsyncThunk(
  GET_OBJECT_FEEDBACK_NOAUTH,
  createRequest({
    type: GET_OBJECT_FEEDBACK_NOAUTH,
    loader: (data) => authApi.getObjectFeedbackNoAuth(data),
  }),
);
