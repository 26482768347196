import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/allow-group';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_GROUP = 'allow/createGroup';

export const createGroup = createAsyncThunk(
  CREATE_GROUP,
  createRequest({
    type: CREATE_GROUP,
    loader: data => authApi.createGroup(data),
  }),
);
