/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { addAttachment, deleteAttachment } from '../../actions/attachment';
import {
  addCompetitionAttachment,
  deleteCompetitionAttachment,
  getCompetitionCabinet,
} from '../../actions/competition';
import { getEvent } from '../../actions/event';
import { addGuideAttachment, deleteGuideAttachment, getGuideCabinet } from '../../actions/guide';
import { Button } from '../../components';
import { FileUpload } from '../../components/file-upload';
import { px } from '../../constants/Px';
import { updateCompetition } from '../../new-architecture/profile/edit/components/competition-form/services/competition.service';
import { updateGuide } from '../../new-architecture/profile/edit/components/guide-form/services/guide.service';
import { updateProfile } from '../../new-architecture/profile/edit/components/object-form/services/object.service';
import { BackButton, DynamicImageSlider, Textarea } from '../../new-architecture/shared';
import { capitalizeFirstLetter } from '../../new-architecture/utils';
import { urlToBlob } from '../../new-architecture/utils/blob';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { competitionSchemasSelector } from '../../selectors/competition';
import { schemaObjectSelector } from '../../selectors/get-event';
import { guideSchemasSelector } from '../../selectors/guide';

const getFileFromUrl = async (url, name, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const SchemaPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [index, setIndex] = useState(0);
  const id = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const [sliderController, setSliderController] = useState();
  const didStateEvent = useRef(false);

  const schema = useSelector(
    activeType === 'competition' || activeType === 'administeredCompetition'
      ? competitionSchemasSelector
      : activeType === 'object' || activeType === 'administeredObject'
        ? schemaObjectSelector
        : guideSchemasSelector,
  );

  const [images, setImages] = useState([]);

  const currentImage = useMemo(() => images?.[index], [index, JSON.stringify(images)]);

  const [titles, setTitles] = useState({});
  const [files, setFiles] = useState({});

  const setTitle = value => {
    const newTitles = { ...titles };
    newTitles[currentImage?.key || 0] = value;
    setTitles(newTitles);
  };

  const setFile = value => {
    const newFiles = { ...files };
    newFiles[currentImage?.key || 0] = value;
    setFiles(newFiles);
  };

  const currentTitle = useMemo(
    () => titles?.[currentImage?.key || 0] || '',
    [currentImage?.key, JSON.stringify(titles)],
  );

  const currentFile = useMemo(
    () => files?.[currentImage?.key || 0],
    [currentImage?.key, JSON.stringify(files)],
  );

  useLayoutEffect(() => {
    setImages(
      schema?.map((item, key) => ({
        src: item?.src,
        key,
      })),
    );
    setTitles(Object.fromEntries(schema?.map((item, key) => [key, item?.title || '']) || []));

    (async () => {
      const newFiles = Object.fromEntries(
        (await Promise.all(
          schema?.map(async (item, key) => {
            const pdfFile = await getFileFromUrl(
              `${process.env?.REACT_APP_STATIC_URL}${item?.textFileUrl}`,
              item?.textFileName,
            );

            return [key, pdfFile];
          }) || [],
        )) || [],
      );

      setFiles(newFiles);
    })();
  }, [schema]);

  useEffect(() => {
    if (didStateEvent.current) return;

    if (
      state?.fields?.schemas?.length &&
      sliderController?.setCurrentKey &&
      sliderController?.addImage &&
      sliderController?.slider?.slides?.length === state?.fields?.schemas?.length
    ) {
      if (state?.schemasIndex === -1 && images?.length > 0) {
        sliderController?.addImage();
      } else if (state?.schemasIndex !== undefined) {
        sliderController?.slider?.slideTo?.(state?.schemasIndex);
      }
      didStateEvent.current = true;
    }
  }, [
    sliderController?.slider?.slides?.length,
    sliderController?.addImage,
    sliderController?.setCurrentKey,
  ]);

  const handleNavigateObject = async () => {
    if (state?.prevPath) {
      navigate(
        activeType === 'competition' || activeType === 'administeredCompetition'
          ? '/user-competition'
          : activeType === 'object' || activeType === 'administeredObject'
            ? '/user-object'
            : '/user-guide',
      );
    } else {
      /* if (activeType === 'object' || activeType === 'administeredObject')
        await updateProfile(dispatch, state?.object, state?.fields);
      if (activeType === 'competition' || activeType === 'administeredCompetition')
        await updateCompetition(dispatch, state?.competition, state?.fields);
      if (activeType === 'guide' || activeType === 'administeredGuide')
        await updateGuide(dispatch, state?.guide, state?.fields); */
      navigate(`/profile/edit`);
    }
  };

  const clearAttachement = async removedId => {
    if (activeType === 'competition' || activeType === 'administeredCompetition') {
      await dispatch(deleteCompetitionAttachment({ id: removedId }));
      await dispatch(getCompetitionCabinet({ id }));
    }
    if (activeType === 'object' || activeType === 'administeredObject') {
      await dispatch(deleteAttachment({ id: removedId }));
      await dispatch(getEvent({ id }));
    }
    if (activeType === 'guide' || activeType === 'administeredGuide') {
      await dispatch(deleteGuideAttachment({ id: removedId }));
      await dispatch(getGuideCabinet({ id }));
    }
  };

  const handleClear = () => {
    clearAttachement(schema?.find(item => item?.src === images[index]?.src)?.id);
  };

  const handleSave = async () => {
    const payload = images
      ?.map(({ src, key }) => ({
        image: src,
        title: titles[Number(key)],
        file: files[Number(key)],
      }))
      .filter(({ image, title }) => image && title);

    // DELETE ALL ATTACHEMENTS
    await Promise.all(schema?.map(s => clearAttachement(s.id)));

    for (const [i, { image, title, file }] of payload.entries()) {
      const formData = new FormData();
      formData.append('file', await urlToBlob(image, i));
      formData.append('pdf', file, encodeURIComponent(file.name));
      formData.append('title', title);
      formData.append('priority', i);
      formData.append('eventId', id);
      formData.append('cabinetId', id);
      formData.append('type', 'SCHEMA');

      await dispatch(
        activeType === 'competition' || activeType === 'administeredCompetition'
          ? addCompetitionAttachment(formData)
          : activeType === 'object' || activeType === 'administeredObject'
            ? addAttachment(formData)
            : addGuideAttachment(formData),
      );
    }

    await dispatch(
      activeType === 'competition' || activeType === 'administeredCompetition'
        ? getCompetitionCabinet({ id })
        : activeType === 'object' || activeType === 'administeredObject'
          ? getEvent({ id })
          : getGuideCabinet({ id }),
    );
    handleNavigateObject();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getEvent({ id }));
  }, []);

  return (
    <div className="global-container ">
      <main>
        <BackButton onClick={handleNavigateObject} />
        <div
          style={{
            marginTop: px(25),
          }}
        >
          <div
            style={{
              paddingLeft: px(15),
              paddingRight: px(15),
              paddingBottom: px(22),
            }}
          >
            <p
              style={{
                fontSize: px(32),
                lineHeight: px(32),
              }}
              className="whitespace-pre-wrap  font-light"
            >
              {`Карты. Схемы\n`}
            </p>

            <p
              className="whitespace-pre-wrap  font-normal"
              style={{ fontSize: px(15), lineHeight: px(20), paddingTop: px(5) }}
            >
              {`Работа с сервисом будет возможна,\nесли Вы заполните все формы`}
            </p>
          </div>

          <DynamicImageSlider
            withText
            crop={{ width: 141, height: 215 }}
            clearText={() => setTitle('')}
            items={schema?.map(item => ({
              src: `${item.src}`,
            }))}
            onSwipe={setIndex}
            onChange={setImages}
            onController={setSliderController}
          />

          <div
            style={{
              paddingLeft: px(15),
              paddingRight: px(15),
              paddingBottom: px(10),
              paddingTop: px(25),
            }}
          >
            <div
              style={{
                paddingBottom: px(22),
              }}
            >
              <p
                className="font-normal"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                Название файла
              </p>
              <p
                className="font-normal"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
              >
                Редактируйте по вашему усмотрению
              </p>
            </div>
            <Textarea
              maxLength={36}
              placeholder={`Введите текст не более\n34 знаков`}
              title="1. Заголовок на обложке"
              textareaHight={px(90)}
              style={{
                height: px(130),
                paddingTop: px(4),
                paddingLeft: px(18),
                paddingRight: px(18),
              }}
              value={currentTitle}
              onChange={title => setTitle(capitalizeFirstLetter(title))}
            />
            <FileUpload
              uploadButton="Прикрепить PDF"
              single
              uploadButtonStyle={{
                fontSize: px(15),
                lineHeight: px(18),
              }}
              accept="application/pdf"
              selectedFiles={currentFile ? [currentFile] : []}
              setSelectedFiles={cb => setFile(cb([currentFile])[1])}
              styles={{ marginTop: px(20) }}
            />
          </div>
        </div>
        <div
          style={{
            paddingLeft: px(15),
            paddingRight: px(15),
          }}
        >
          <Button
            className="w-full border-[1px] border-solid border-[#dddddd]"
            marginTop={0}
            height={60}
            borderRadius={0}
            disabled={images && (!images[index]?.src || !currentTitle || !currentFile)}
            onClick={() => {
              handleSave();
            }}
          >
            Применить
          </Button>
          <div
            style={{
              marginTop: px(20),
              paddingBottom: px(80),
            }}
            className="flex justify-center"
          >
            <div
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal text-[#FA0303]"
              onClick={handleClear}
              onKeyDown={handleClear}
            >
              Удалить
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
