/* eslint-disable no-shadow */
import React, { useState } from 'react';

import { Switch } from '../../../../../../../components';
import { period } from '../../../../../../../constants/Component/date-picker';
import { Picker } from '../../../../../../shared';
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';

export const AccessSettingsPeriod = ({ pauseType }) => {
  const [show, setShow] = useState(false);

  const {
    data: { allowanceGroup },
    setData: setProfile,
  } = useProfile();

  const setAllownaceGroup = payload => {
    setProfile({
      allowanceGroup: {
        ...allowanceGroup,
        ...payload,
      },
    });
  };

  const { prefDeparture, isPrefDeparture, isPause, pause } = allowanceGroup || {};

  return (
    <div style={{ marginBottom: px(29) }}>
      <p style={{ fontSize: px(32), lineHeight: px(32) }}>
        {pauseType ? 'Пауза между бронированием' : 'Льготный период на выезд'}
      </p>
      <p
        style={{ fontSize: px(15), lineHeight: px(20), marginBottom: px(19) }}
        className="font-normal"
      >
        {pauseType
          ? 'Установите свободный час на уборку, обслуживание или другие работы, между бронированием'
          : 'Установите допустимое время на сборы и выезд без применения штрафа за поздний выезд'}
      </p>
      <div className="flex items-center justify-between">
        <div>
          <p style={{ fontSize: px(20), lineHeight: px(27) }} className="font-normal">
            {pauseType ? 'Делать паузу' : 'Льготное время'}
          </p>
          <p
            style={{ fontSize: px(15), lineHeight: px(20), paddingBottom: px(10) }}
            className="font-normal"
          >
            {pauseType ? 'Пропускать в календаре' : 'Выезжайте с комфортом'}
          </p>
        </div>
        <div>
          <Switch // TODO
            toggle={pauseType ? isPause : isPrefDeparture}
            setToggle={
              pauseType
                ? isPause => setAllownaceGroup({ isPause })
                : isPrefDeparture => setAllownaceGroup({ isPrefDeparture })
            }
          />
        </div>
      </div>
      {!pauseType && isPrefDeparture && (
        <div
          style={{
            gap: px(14),
          }}
          className="flex"
        >
          <div className="flex flex-col">
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal opacity-50"
            >
              {pauseType ? 'Период между бронированием' : 'Период на сборы (выезд)'}
            </p>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-row"
            >
              <div>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    paddingBottom: px(13),
                  }}
                  className="font-normal  opacity-50"
                >
                  Время
                </p>
                <p
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                  className="font-normal text-black"
                  onClick={() => setShow(true)}
                  aria-hidden
                >
                  {prefDeparture || '1 час'}
                </p>
                <hr
                  className="text-black opacity-30"
                  style={{
                    marginTop: px(5),
                  }}
                />
              </div>
            </div>
          </div>
          <Picker // TODO
            singleItem
            title="Период. Время"
            show={show}
            onClose={() => setShow(false)}
            data={{
              prefDeparture: period.period,
            }}
            onChange={({ prefDeparture }) => setAllownaceGroup({ prefDeparture })}
            value={{
              prefDeparture,
            }}
            button
            isLine
          />
        </div>
      )}
      {pauseType && isPause && (
        <div
          style={{
            gap: px(14),
          }}
          className="flex"
        >
          <div className="flex flex-col">
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal opacity-50"
            >
              {pauseType ? 'Период между бронированием' : 'Период на сборы (выезд)'}
            </p>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-row"
            >
              <div>
                <p
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    paddingBottom: px(10),
                  }}
                  className="font-normal  opacity-50"
                >
                  Время
                </p>
                <p
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                  className="font-normal text-black"
                  onClick={() => setShow(true)}
                  aria-hidden
                >
                  {pause || '1 час'}
                </p>
                <hr
                  className="text-black opacity-30"
                  style={{
                    marginTop: px(5),
                  }}
                />
              </div>
            </div>
          </div>
          <Picker // TODO
            singleItem
            title="Период. Время"
            show={show}
            onClose={() => setShow(false)}
            data={{
              pause: period.period,
            }}
            onChange={({ pause }) => setAllownaceGroup({ pause })}
            value={{
              pause,
            }}
            button
            isLine
          />
        </div>
      )}
    </div>
  );
};

export default AccessSettingsPeriod;
