import React from 'react';

import { addLeadingZeros } from '../constants/Function';
import { px } from '../constants/Px';
import { StyledInput } from './styled-input';

export const SettingProfileInfo = ({
  isBigMarginBottom,
  isSettingsProfilePage,
  onePlaceholder,
  valuesObj,
  numberType,
  titles,
  placeholders,
  values,
  setValues,
  isMarginBottom,
  navigateChangeEmail,
  isBorder,
  onClick,
}) => {
  const valuesEntries = Object.entries(valuesObj);
  const handleInputChange = (key, value) => {
    setValues(prevInputs => ({
      ...prevInputs,
      [key]:
        key === 'recommendedId' || key === 'recommendedId2'
          ? value?.toString?.()?.replaceAll?.(/\D/g, '')
          : value,
    }));
  };
  return (
    <div
      style={{
        marginTop: px(27),
      }}
      className="flex flex-col"
    >
      <div className="min-w-full">
        {valuesEntries.map(([key, value], index) => (
          <div
            aria-hidden
            className={`${index === 3 && 'cursor-pointer'}`}
            onClick={
              navigateChangeEmail && index === 3
                ? navigateChangeEmail
                : onClick
                  ? () => {
                      onClick(index);
                    }
                  : () => {}
            }
            key={key}
          >
            <StyledInput
              numberType={numberType}
              isBorder={isBorder}
              cursorPointer={index === 3}
              phoneInput={key === 'phone'}
              title={titles ? titles[index] : null}
              isBigMarginBottom={isBigMarginBottom && index === 1 ? isBigMarginBottom : null}
              placeholder={onePlaceholder || placeholders[index]}
              value={
                values[key]
                  ? key === 'number'
                    ? `ID: ${addLeadingZeros(values[key]?.split?.(' ')?.[1])}`
                    : values[key]?.toString()?.charAt(0)?.toUpperCase() +
                      values[key]?.toString()?.slice(1)
                  : ''
              }
              onChange={e => handleInputChange(key, e.target.value)}
              isMarginBottom={isMarginBottom}
              isSettingsProfilePage={isSettingsProfilePage}
              textOpacity={50}
              disabled={
                placeholders?.[index] === 'Введите телефон…' ||
                placeholders?.[index] === '04.06.2021' ||
                placeholders?.[index] === 'ID 87548475'
              }
              placeholderOpacity={50}
              readOnly={index === 0 || index === 2}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingProfileInfo;
