import { createReducer } from '@reduxjs/toolkit';

import { getNotification } from '../../actions/notification/thunks/get-notification';
import { getFormatDate } from '../../constants/Function';

const initialState = {
  notification: null,
  events: null,
  eventName: [],
  eventDate: [],
};

export const notification = createReducer(initialState, builder => {
  builder.addCase(getNotification.fulfilled, (state, { payload }) => ({
    ...state,
    notification: payload?.data?.notifications?.all,
    events: payload?.data?.notifications?.events,
    eventName: [...new Set(payload?.data?.notifications?.events?.map(item => item?.eventName))],
    eventDate: [
      ...new Set(
        payload?.data?.notifications?.all?.map(item => getFormatDate(item?.createdAt, true)),
      ),
    ],
  }));
});
