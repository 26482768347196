/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { deleteCompetitionEvent, updateCompetitionEvent } from '../../../../actions/competition';
import competition from '../../../../assets/images/competition.png';
import { CardInformation } from '../../../../components';
import { blockTheAccountPageTimeData } from '../../../../constants/Component';
import { px } from '../../../../constants/Px';
import { TypesIcon } from '../../../../types/TypesIcon';
import { BackButton, Button, CircleButton } from '../../../shared';
import { useCompetition } from '../../hooks';
import { InformationForm } from '../components/information-form/information-form';

export const CompetitionRegistrationEditPage = () => {
  const [showInformationForm, setShowInformationForm] = useState(false); // form for edit
  const [hide, setHide] = useState(false); // expand data
  const [activeIndex, setActiveIndex] = useState(0); // choosen reg
  const [information, setInformation] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: { competitions },
    updateFunction: updateCompetition,
  } = useCompetition();

  const days =
    competitions &&
    Math.floor((new Date(competitions[activeIndex]?.periodStart) - Date.now()) / 86_400_000);

  const hours =
    competitions &&
    Math.floor(
      (new Date(competitions[activeIndex]?.periodStart) - Date.now() - 86_400_000 * days) /
        3_600_000,
    );

  const minutes =
    competitions &&
    Math.floor(
      (new Date(competitions[activeIndex]?.periodStart) -
        Date.now() -
        86_400_000 * days -
        3_600_000 * hours) /
        60_000,
    );

  return (
    <>
      <div className="global-container">
        <div className="fixed top-0 z-10 w-full bg-[white]">
          <BackButton
            onClick={() =>
              showInformationForm ? setShowInformationForm(false) : navigate('/user-competition')
            }
          />
        </div>

        {!showInformationForm && (
          <div
            className="w-full"
            style={{
              paddingBottom: px(40),
              paddingTop: px(80),
              width: px(320),
            }}
          >
            <div style={{ marginBottom: px(17) }}>
              <p style={{ fontSize: px(32), lineHeight: px(43), textAlign: 'center' }}>
                Регистрация
              </p>
              <p style={{ fontSize: px(15), lineHeight: px(20), textAlign: 'center' }}>
                {competitions?.length === 0
                  ? 'Добавьте соревнование'
                  : 'Виртуальные карты лояльности'}
              </p>
            </div>
            <Swiper
              className="home_page-heroadd"
              slidesPerView={1.1852}
              spaceBetween={10}
              cards
              onRealIndexChange={element => setActiveIndex(element.activeIndex)}
              centeredSlides
              modules={[Pagination]}
              pagination={{ clickable: true }}
              style={{ height: px(218) }}
            >
              {(competitions?.length === 0 ? [{}] : competitions)?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={index}>
                  <div className="relative" style={{ width: px(270), height: px(218) }}>
                    <img
                      src={competition}
                      alt="card"
                      style={{ width: px(270), height: px(218), objectFit: 'cover' }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        top: px(105),
                        left: px(30),
                        color: 'white',
                        fontSize: px(32),
                        lineHeight: px(43),
                      }}
                    >
                      {(competitions && competitions[activeIndex]?.periodStart?.slice(0, 4)) ||
                        '0000'}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        top: px(167),
                        left: px(12),
                        color: 'white',
                        fontSize: px(20),
                        lineHeight: px(26),
                        minWidth: px(33),
                        textAlign: 'center',
                      }}
                    >
                      {days || '000'}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        top: px(167),
                        left: px(53),
                        color: 'white',
                        fontSize: px(20),
                        lineHeight: px(26),
                        minWidth: px(23),
                        textAlign: 'center',
                      }}
                    >
                      {hours || '00'}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        top: px(167),
                        left: px(90),
                        color: 'white',
                        fontSize: px(20),
                        lineHeight: px(26),
                        minWidth: px(23),
                        textAlign: 'center',
                      }}
                    >
                      {minutes || '00'}
                    </span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!showInformationForm && (
          <div
            className="fixed flex w-full flex-row items-center justify-center"
            style={{
              bottom: px(54),
              left: 0,
              gap: px(10),
              zIndex: 10,
            }}
          >
            <CircleButton
              icon={{
                type: TypesIcon.ADDPLUS,
                viewBox: '0 0 26.95 26.95',
                width: px(26.95),
                height: px(26.95),
              }}
              onClick={() => {
                setInformation();
                setShowInformationForm(true);
              }}
            />

            {competitions?.length > 0 && (
              <CircleButton
                onClick={() => {
                  setInformation(competitions[activeIndex]);
                  setShowInformationForm(true);
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0A84FE',
                }}
              />
            )}
          </div>
        )}

        {showInformationForm && (
          <InformationForm
            information={information}
            onClose={() => setShowInformationForm(false)}
          />
        )}

        {!showInformationForm && competitions?.length > 0 && (
          <div className="container w-full">
            <div style={{ marginBottom: px(14) }}>
              <p style={{ fontSize: px(32), lineHeight: px(43) }}>Информация</p>
              <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
                Дорогие друзья, с большой радостью приглашаем вас принять участие
              </p>
            </div>

            <div style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                Место проведения
              </p>
              <p
                className="break-words"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: competitions[activeIndex]?.placeDescription.replace('\n', '&#10;'),
                }}
              />
            </div>

            <div style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                Период проведения
              </p>
              <div
                style={{
                  gap: px(14),
                }}
                className="flex"
              >
                {blockTheAccountPageTimeData.map(item => (
                  <div key={item?.id} className="flex flex-col">
                    <p
                      style={{
                        fontSize: px(15),
                        lineHeight: px(20),
                        fontWeight: 400,
                      }}
                      className="font-normal opacity-50"
                    >
                      {item?.title}
                    </p>

                    <div
                      style={{
                        gap: px(10),
                      }}
                      className="flex flex-row"
                    >
                      {item?.data?.map(el => (
                        <div key={el?.id}>
                          <p
                            style={{
                              fontSize: px(15),
                              lineHeight: px(20),
                              paddingBottom: px(20),
                              fontWeight: 400,
                            }}
                            className="font-normal  opacity-50"
                          >
                            {el?.subTitle}
                          </p>
                          <p
                            style={{
                              fontSize: px(20),
                              lineHeight: px(26),
                              fontWeight: 400,
                            }}
                            className="font-normal text-black"
                          >
                            {el?.id === 1
                              ? competitions[activeIndex]?.periodStart?.slice(11, 16)
                              : el?.id === 3
                                ? competitions[activeIndex]?.periodEnd?.slice(11, 16)
                                : el?.id === 2
                                  ? `${competitions[activeIndex]?.periodStart?.slice(
                                      8,
                                      10,
                                    )}.${competitions[activeIndex]?.periodStart?.slice(
                                      5,
                                      7,
                                    )}.${competitions[activeIndex]?.periodStart?.slice(2, 4)}`
                                  : `${competitions[activeIndex]?.periodEnd?.slice(
                                      8,
                                      10,
                                    )}.${competitions[activeIndex]?.periodEnd?.slice(
                                      5,
                                      7,
                                    )}.${competitions[activeIndex]?.periodEnd?.slice(2, 4)}`}
                          </p>
                          <hr
                            className="text-black opacity-30"
                            style={{
                              marginTop: px(5),
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Организаторы</p>
              <p
                className="break-words"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: competitions[activeIndex]?.sponsors.replace('\n', '<br />'),
                }}
              />
            </div>

            <div className="flex items-center justify-between" style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                Характеристики
              </p>
              <div>
                <button
                  type="button"
                  className="font-normal"
                  onClick={() => setHide(!hide)}
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    color: '#0A84FE',
                    cursor: 'pointer',
                  }}
                >
                  {hide ? 'скрыть' : 'показать'}
                </button>
              </div>
            </div>

            {hide && (
              <>
                <div style={{ marginBottom: px(40) }}>
                  <p
                    style={{
                      marginBottom: px(13),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Вид турнира
                  </p>
                  {competitions[activeIndex]?.tournamentType.map(item => (
                    <div
                      key={item}
                      className="flex justify-between"
                      style={{ paddingRight: px(8), marginTop: px(13) }}
                    >
                      <p
                        style={{
                          paddingTop: px(2),
                          fontSize: px(20),
                          lineHeight: px(26),
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </p>
                      <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                    </div>
                  ))}
                </div>

                <div style={{ marginBottom: px(40) }}>
                  <p
                    style={{
                      marginBottom: px(13),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Тип участников
                  </p>
                  {competitions[activeIndex]?.participantType.map(item => (
                    <div
                      key={item}
                      className="flex justify-between"
                      style={{ paddingRight: px(8), marginTop: px(13) }}
                    >
                      <p
                        style={{
                          paddingTop: px(2),
                          fontSize: px(20),
                          lineHeight: px(26),
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </p>
                      <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                    </div>
                  ))}
                </div>

                <div style={{ marginBottom: px(40) }}>
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Зачет
                  </p>
                  {competitions[activeIndex]?.standings.map(item => (
                    <div
                      key={item}
                      className="flex justify-between"
                      style={{ paddingRight: px(8), marginTop: px(13) }}
                    >
                      <p
                        style={{
                          paddingTop: px(2),
                          fontSize: px(20),
                          lineHeight: px(26),
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </p>
                      <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                    </div>
                  ))}
                </div>

                <div style={{ marginBottom: px(40) }}>
                  <p
                    style={{
                      marginBottom: px(13),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Дисциплина
                  </p>
                  {competitions[activeIndex]?.discipline.map(item => (
                    <div
                      key={item}
                      className="flex justify-between"
                      style={{ paddingRight: px(8), marginTop: px(13) }}
                    >
                      <p
                        style={{
                          paddingTop: px(2),
                          fontSize: px(20),
                          lineHeight: px(26),
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </p>
                      <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                    </div>
                  ))}
                </div>

                <div style={{ marginBottom: px(40) }}>
                  <p
                    style={{
                      marginBottom: px(13),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Направление
                  </p>
                  {competitions[activeIndex]?.direction.map(item => (
                    <div
                      key={item}
                      className="flex justify-between"
                      style={{ paddingRight: px(8), marginTop: px(13) }}
                    >
                      <p
                        style={{
                          paddingTop: px(2),
                          fontSize: px(20),
                          lineHeight: px(26),
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </p>
                      <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                    </div>
                  ))}
                </div>

                <div style={{ marginBottom: px(40) }}>
                  <p
                    style={{
                      marginBottom: px(13),
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Виды рыб
                  </p>
                  {competitions[activeIndex]?.fishType.map(item => (
                    <div
                      key={item}
                      className="flex justify-between"
                      style={{ paddingRight: px(8), marginTop: px(13) }}
                    >
                      <p
                        style={{
                          paddingTop: px(2),
                          fontSize: px(20),
                          lineHeight: px(26),
                          fontWeight: 400,
                        }}
                      >
                        {item}
                      </p>
                      <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                    </div>
                  ))}
                </div>
              </>
            )}

            <div style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                Количество участников
              </p>
              <p
                className="break-words"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: competitions[activeIndex]?.perticipantsNumber.replace('\n', '&#10;'),
                }}
              />
              {/* <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
                <pre style={{ fontFamily: 'inherit' }}>
                  {competitions[activeIndex]?.perticipantsNumber}
                </pre>
              </p> */}
            </div>

            <div style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                Старт регистрации
              </p>
              <p
                style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400, gap: px(9) }}
                className="flex"
              >
                <span>{competitions[activeIndex]?.registrationStart?.slice(11, 16)}</span>
                <span>
                  {`${competitions[activeIndex]?.registrationStart?.slice(8, 10)}.${competitions[
                    activeIndex
                  ]?.periodStart?.slice(5, 7)}.${competitions[activeIndex]?.periodStart?.slice(
                    2,
                    4,
                  )}`}
                </span>
              </p>
            </div>

            <div style={{ marginBottom: px(10) }}>
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Призовой фонд</p>
              {/* <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
                <pre style={{ fontFamily: 'inherit' }}>{competitions[activeIndex]?.prizePool}</pre>
              </p> */}
              <p
                className="break-words"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: competitions[activeIndex]?.prizePool.replace('\n', '&#10;'),
                }}
              />
            </div>

            <div
              style={{ paddingBottom: px(26) }}
              className="border-b-[1px] border-solid border-black border-opacity-30"
            >
              <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Сумма взносов</p>
              {/* <p style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
                <pre style={{ fontFamily: 'inherit' }}>
                  {competitions[activeIndex]?.contributionsAmount}
                </pre>
              </p> */}
              <p
                className="break-words"
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: competitions[activeIndex]?.contributionsAmount.replace('\n', '&#10;'),
                }}
              />
            </div>

            <CardInformation
              dropDown
              cardHolder
              data={[
                {
                  id: 6,
                  title: { label: 'Список участников и резерв' },
                  text: (
                    <div>
                      <p
                        className="break-words"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: competitions[activeIndex]?.participantsList.replace(
                            '\n',
                            '&#10;',
                          ),
                        }}
                      />
                      {/* <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        <pre style={{ fontFamily: 'inherit' }}>
                          {competitions[activeIndex]?.participantsList}
                        </pre>
                      </p> */}
                    </div>
                  ),
                },
              ]}
            />
            <CardInformation
              dropDown
              cardHolder
              data={[
                {
                  id: 6,
                  title: { label: 'Порядок регистрации' },
                  text: (
                    <div>
                      <p
                        className="break-words"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: competitions[activeIndex]?.registrationProcedure.replace(
                            '\n',
                            '&#10;',
                          ),
                        }}
                      />
                      {/* <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        <pre style={{ fontFamily: 'inherit' }}>
                          {competitions[activeIndex]?.registrationProcedure}
                        </pre>
                      </p> */}
                    </div>
                  ),
                },
              ]}
            />
            <CardInformation
              dropDown
              cardHolder
              data={[
                {
                  id: 6,
                  title: { label: 'Оплата участия' },
                  text: (
                    <div>
                      <p
                        className="break-words"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: competitions[activeIndex]?.participationPayment.replace(
                            '\n',
                            '&#10;',
                          ),
                        }}
                      />
                      {/* <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        <pre style={{ fontFamily: 'inherit' }}>
                          {competitions[activeIndex]?.participationPayment}
                        </pre>
                      </p> */}
                    </div>
                  ),
                },
              ]}
            />
            <CardInformation
              dropDown
              cardHolder
              data={[
                {
                  id: 6,
                  title: { label: 'Регламент' },
                  text: (
                    <div>
                      <p
                        className="break-words"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: competitions[activeIndex]?.regulations.replace('\n', '&#10;'),
                        }}
                      />
                      {/* <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        <pre style={{ fontFamily: 'inherit' }}>
                          {competitions[activeIndex]?.regulations}
                        </pre>
                      </p> */}
                    </div>
                  ),
                },
              ]}
            />
            <CardInformation
              dropDown
              cardHolder
              data={[
                {
                  id: 6,
                  title: { label: 'Программа' },
                  text: (
                    <div>
                      <p
                        className="break-words"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: competitions[activeIndex]?.program.replace('\n', '&#10;'),
                        }}
                      />
                      {/* <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                      >
                        <pre style={{ fontFamily: 'inherit' }}>
                          {competitions[activeIndex]?.program}
                        </pre>
                      </p> */}
                    </div>
                  ),
                },
              ]}
            />
            <CardInformation
              dropDown
              cardHolder
              data={[
                {
                  id: 6,
                  title: { label: 'Условия и Правила' },
                  text: (
                    <div className="flex flex-col" style={{ gap: px(10) }}>
                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          1. Календарь
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[0]}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          2. Основные положения
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[1]}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          3. Регистрация. Взносы
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[2]}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          4. Результаты
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[3]}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          5. Рейтинг команд
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[4]}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          6. Основные правила
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[5]}
                        </p>
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                        >
                          7. Нарушения правил
                        </p>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {competitions[activeIndex]?.rules[6]}
                        </p>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
            <div style={{ marginBottom: px(20), paddingTop: px(20) }}>
              <Button
                style={{ width: '100%' }}
                fontSize={20}
                lineHeight={26}
                height={60}
                borderRadius={0}
                /* disabled={loading} */
                onClick={async () => {
                  // eslint-disable-next-line unicorn/prefer-ternary
                  await dispatch(
                    updateCompetitionEvent({
                      data: {
                        isRegistrationOpen: !competitions[activeIndex]?.isRegistrationOpen,
                      },
                      id: competitions[activeIndex]?.id,
                    }),
                  );
                  updateCompetition();
                }}
              >
                {competitions[activeIndex]?.isRegistrationOpen
                  ? 'Остановить регистрацию'
                  : 'Запустить регистрацию'}
              </Button>
            </div>
            <p
              aria-hidden
              style={{
                marginBottom: px(120),
                textAlign: 'center',
                fontSize: px(15),
                lineHeight: px(20),
                fontWeight: 400,
                color: '#FA0303',
              }}
              onClick={async () => {
                await dispatch(deleteCompetitionEvent(competitions[activeIndex]?.id));
                updateCompetition();
              }}
            >
              Удалить форму регистрации
            </p>
          </div>
        )}
      </div>
    </>
  );
};
