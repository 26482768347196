import React from 'react';

import { px } from '../constants/Px';

export const ObjectDescriptionTextarea = ({ objectStyles, value, setValue, objectKey }) => {
  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleChange = event => {
    const inputValue = event.target.value;
    const formattedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setValue(objectKey, formattedValue);
  };

  return (
    <div>
      <div
        className="w-full border-[1px] border-solid border-[rgb(0,0,0,0.3)]"
        style={{
          marginTop: px(objectStyles?.top),
          marginBottom: px(objectStyles?.bottom),
          height: px(objectStyles?.height),
          paddingTop: px(objectStyles?.paddingTop),
          paddingLeft: px(objectStyles?.paddingLeft),
          paddingRight: px(objectStyles?.paddingRight),
          paddingBottom: px(objectStyles?.paddingBottom || 0),
        }}
      >
        <p className="font-normal opacity-50" style={{ fontSize: px(20), lineHeight: px(26) }}>
          {objectStyles?.title}
        </p>
        <textarea
          value={value}
          onChange={handleChange}
          onKeyDown={objectStyles?.objectName && handleKeyDown}
          placeholder={objectStyles?.placeholder}
          className={`w-full outline-none focus:outline-none ${
            objectStyles?.textareaLight ? 'font-light' : 'font-normal'
          }  resize-none placeholder:text-[rgb(0,0,0,0.5)]`}
          style={{
            height: px(objectStyles?.textareaHeight),
            fontSize: objectStyles?.textareaFontSize ? px(objectStyles?.textareaFontSize) : px(20),
            lineHeight: objectStyles?.textareaLineHeight
              ? px(objectStyles?.textareaLineHeight)
              : px(26),
          }}
        />
      </div>
    </div>
  );
};

export default ObjectDescriptionTextarea;
