// eslint-disable-next-line import/no-unresolved
import { request } from '@/helpers/api/request';

export const cancelDeleteCabinet = async ({ id }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `competition-cabinet/cancel-delete/${id}`,
    method: 'GET',
  });

  return response;
};
