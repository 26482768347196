import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';
import { clearProfile } from '../clear-profile';
import { clearAuth } from '../../auth';

export const GET_PROFILE = 'profile/getProfile';

const onFail = () => async (dispatch) => {
    dispatch(clearProfile());
    dispatch(clearAuth());
 
  };

export const getProfile = createAsyncThunk(
    GET_PROFILE,
    createRequest({
        type: GET_PROFILE,
        loader: (data) => authApi.getProfile(data),
        onFail,
    }),
    
);