import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/auth';
import { addModal } from '@/actions/modal';
import { getProfile } from '../../profile';

export const LOGIN_END = 'auth/loginEnd';
const onFail = (error) => async (dispatch) => {
  if (error == 'Error: Request failed with status code 403') {
    await dispatch(
      addModal({
        id: 2,
        data: {
          title: 'Вы ввели не верный пароль',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
  }
};

const onSuccess =  () =>async  dispatch => {
  dispatch(getProfile());

};

export const loginEnd = createAsyncThunk(
  LOGIN_END,
  createRequest({
    type: LOGIN_END,
    loader: (data) => authApi.loginEnd(data),
    onFail,
    onSuccess,
  }),
);
