import * as authApi from '@/api/event-review';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addModal } from '../../modal/addModal';

export const ADD_OBJECT_FEEDBACK = 'appeal/addObjectFeedback';

const onSuccess = () => async (dispatch) => {
  dispatch(
    addModal({
      id: 17,
      data: {
        title: 'Спасибо за оценку',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'object-create-feedback',
    }),
  );
};

export const addObjectFeedback = createAsyncThunk(
  ADD_OBJECT_FEEDBACK,
  createRequest({
    type: ADD_OBJECT_FEEDBACK,
    loader: (data) => authApi.addObjectFeedback(data),
    onSuccess,
  }),
);
