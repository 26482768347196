import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { allowGroup } from './allow-group';
import { auth } from './auth';
import { card } from './card';
import { competition } from './competition';
import { contacts } from './contacts';
import { event, eventObject } from './event';
import { eventReview, eventReviewNoAuth } from './event-review';
import { favourites } from './favourites';
import { guide } from './guide';
import { image } from './image';
import { loaders } from './loaders/index';
import { location } from './location';
import { modals } from './modal';
import { news } from './news';
import { notification } from './notification';
import { profile } from './profile';
import { profileAdmin } from './profile-admin';
import { searchedValue } from './search';
import { section } from './section';
import { sector } from './sector';
import { isSubscription } from './subscription';
import { visit } from './visit';

const persistConfig = {
  key: 'root', // ключ для сохранения данных в local storage
  storage, // выбираем тип хранилища, например, local storage
};

// тут any потому-что не знаем какой еще стор и не ломаем динамическую типизацию
/* const getClearState = (state, notClearSystemData) =>
  pick(state, notClearSystemData ? ['router', 'systems'] : ['router']); */

export const createRootReducer = () => {
  const reducers = combineReducers({
    profile,
    loaders,
    image,
    visit,
    modals,
    auth: persistReducer(persistConfig, auth),
    event,
    isSubscription,
    searchedValue,
    profileAdmin,
    notification,
    favourites,
    eventReview,
    eventReviewNoAuth,
    eventObject,
    contacts,
    news,
    sector,
    location,
    allowGroup,
    section,
    card,
    competition,
    guide,
  });

  return (state, action) =>
    /*     const realState = 'auth/logout'
      ? getClearState(state, action.payload)
      : state; */
    reducers(state, action);
};
