import React, { useState } from 'react';

import { Checkbox, Main, SearchCardModal } from '../../components';
import { Icon } from '../../components/Icon';
import { sort } from '../../constants/Component';
import { px } from '../../constants/Px';
import { TypesIcon } from '../../types/TypesIcon';

export const SortPage = () => {
  const [selectedOption, setSelectedOption] = useState('default');
  const [show, setShow] = useState(true);
  const handleOptionChange = e => setSelectedOption(e.target.value);

  return (
    <Main registration>
      <div
        style={{
          gap: px(10),
          marginBottom: px(33),
        }}
        className="flex  items-center justify-center"
      >
        <Icon type={TypesIcon.SORT} viewBox="0 0 25 23.607" width={px(25)} height={px(23.607)} />
        <p
          style={{
            fontSize: px(32),
            lineHeight: px(32),
          }}
          className="font-light"
        >
          Сортировка
        </p>
      </div>

      <div
        style={{
          gap: px(39),
        }}
        className="flex flex-col"
      >
        {sort.map(item => (
          <div key={item?.id} className="flex items-center justify-between">
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
              className="font-normal"
            >
              {item?.title}
            </p>
            <Checkbox
              margin
              checked={selectedOption === item?.value}
              value={item?.value}
              onChange={e => handleOptionChange(e)}
              style={{
                margin: '0px',
              }}
            />
          </div>
        ))}
      </div>
      <SearchCardModal title="Клубные карты" setShow={setShow} show={show} buttonTitle="Найти" />
    </Main>
  );
};
