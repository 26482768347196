import React from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const LoyaltyCard = ({
  data: {
    location,
    profileId,
    profileName,
    profileLogo,
    createdAt,
    openUntill,
    cardNumber,
    cardName,
  },
  onChangeCardId,
}) => (
  <div
    style={{
      height: px(210),
      padding: `${px(6)} ${px(10)} ${px(8)}   ${px(10)}`,
    }}
    className="border-[1px] border-solid border-[#DDDDDD]"
    onClick={() => onChangeCardId()}
    onKeyDown={() => onChangeCardId()}
  >
    <div
      style={{
        gap: px(10),
        paddingLeft: px(8),
        paddingRight: px(8),
      }}
      className="flex w-full"
    >
      <div className="relative" style={{ width: px(41), height: px(32), overflow: 'hidden' }}>
        <div className="absolute z-[2] overflow-hidden" style={{ top: px(0), left: px(0) }}>
          <Icon type={TypesIcon.CARD} viewBox="0 0 41 33.02" width={px(41)} height={px(33)} />
          {!profileLogo && (
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
                width: px(20),
                height: px(20),
                position: 'absolute',
                top: '50%',
                left: '40%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
            >
              {profileName?.charAt(0)?.toUpperCase()}
            </p>
          )}
        </div>
        {profileLogo && (
          <img
            src={`${process.env.REACT_APP_STATIC_URL}${profileLogo}`}
            className="absolute z-[1]"
            style={{
              width: px(30),
              height: px(30),
              borderRadius: '100%',
              top: px(1.5),
              left: px(1),
            }}
            alt="imglogo"
          />
        )}
      </div>
      <div>
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
            width: px(203),
            height: px(20),
          }}
          className="... w-full truncate font-light"
        >
          {profileName}
        </p>
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
            width: px(203),
            height: px(20),
          }}
          className="... w-full truncate font-light opacity-50"
        >
          {location}
        </p>
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
          }}
          className="font-light opacity-50"
        >
          {`ID: ${profileId}`}
        </p>
      </div>
    </div>
    <div
      style={{
        gap: px(7),
      }}
      className="flex"
    >
      <div style={{ height: px(118), width: px(118), padding: px(5) }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value="https://www.mcgc.ru/"
          viewBox="0 0 256 256"
        />
        <p
          className="text-center font-normal"
          style={{
            fontSize: px(15),
            lineHeight: px(20),
          }}
        >
          {cardNumber}
        </p>
      </div>
      <div
        style={{
          gap: px(10),
          marginTop: px(10),
        }}
        className="flex flex-col"
      >
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
          }}
          className="font-light"
        >
          Срок действия
        </p>
        <div>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-light opacity-50"
          >
            {createdAt}
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-light opacity-50"
          >
            {openUntill}
          </p>
        </div>
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
          }}
          className="font-normal"
        >
          {cardName}
        </p>
      </div>
    </div>
  </div>
);
