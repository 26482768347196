/* eslint-disable unicorn/prefer-add-event-listener */
export const urlToBlob = async (url, priority) =>
  fetch(url)
    .then(r => r.blob())
    .then(
      blobFile =>
        new File(
          [blobFile],
          priority === undefined ? 'image.jpg' : `image_priority-${priority}_.jpg`,
          {
            type: 'image/jpeg',
          },
        ),
    );
