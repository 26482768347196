import React, { useContext, useEffect, useState } from 'react';
import DraggableModal from './draggable-modal';
import { SearchMenuItemPreferencesOption } from './search-menu-item-preferences-option';
import { Select } from './select';
import { StateContext } from './state-provider';
import { Icon } from './Icon';
import { TypesIcon } from '../types/TypesIcon';
import { px } from '../constants/Px';
import { Modal } from './portals';
export const SearchMenuItemPreferencesSelect = ({
  title,
  value,
  arrowDown,
  options,
  onSelect,
  selectedCountry,
  selectedRegion,
  selectedLanguage,
  selectedCurrency,
}) => {
  const { dragControls } = useContext(StateContext);
  const [isOpen, setIsOpen] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  useEffect(() => {
    if (options.length > 2) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  }, [options]);

  return (
    <>
      <Select className="search-menu-item-preferences-select" 
      // onClick={() => setIsOpen(!isOpen)}
      >
        <div className="search-menu-item-preferences-select-container">
          {title && <p>{title}</p>}
          {selectedCountry ? (
            <h3>{selectedCountry}</h3>
          ) : selectedRegion ? (
            <h3>{selectedRegion}</h3>
          ) : selectedLanguage ? (
            <h3>{selectedLanguage}</h3>
          ) : (
            <h3>{selectedCurrency}</h3>
          )}
          {arrowDown && (
            <div className={`arrow-down ${isOpen ? 'open' : ''}`}>
              <Icon type={TypesIcon.ARROWDOWN} width={px(8)} height={px(6)} viewBox={'0 0 8 6'} />
            </div>
          )}
        </div>
      </Select>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="opened-options">
          <div
            className="start "
            onPointerDown={e => {
              dragControls.start(e);
            }}
          >
            <div className=" search-menu-item-preferences-select-container">
              {title && <p>{title}</p>}
              {selectedCountry ? (
                <h3>{selectedCountry}</h3>
              ) : selectedRegion ? (
                <h3>{selectedRegion}</h3>
              ) : selectedLanguage ? (
                <h3>{selectedLanguage}</h3>
              ) : (
                <h3>{selectedCurrency}</h3>
              )}
            </div>
          </div>

          <div className="search-menu-item-preferences-options-overflow">
            {options.map((option, index) => (
              <SearchMenuItemPreferencesOption
                key={index}
                option={option}
                setSelectedOption={setSelectedOption}
                onSelect={() => {
                  onSelect(option);
                  setSelectedOption(option);
                  setIsOpen(false);
                }}
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};
