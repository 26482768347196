import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getProfile, updateProfile } from '../../actions/profile';
import { Button, Input, Main } from '../../components';
import { PhoneInput } from '../../components/phoneInput';
import { px } from '../../constants/Px';
import { request } from '../../helpers/api/request';
import { profileSelector } from '../../selectors/profile';

export const ChangePhonePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [isSendPhone, setIsSendPhone] = useState(false);

  const profile = useSelector(profileSelector);

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  const handleChangePhone = e => {
    const input = e.target.value.replaceAll(/\D/g, '');
    const regex =
      /^((8|\+374|\+9{2}4|\+9{2}5|\+375|\+7|\+380|\+38|\+9{2}6|\+9{2}8|\+9{2}3)[ \-]?)?\(?\d{3,5}\)?(?:[ \-]?\d){5}(([ \-]?\d)?[ \-]?\d)?$/;
    setIsValidPhone(regex.test(input));
    setPhone(input);
  };

  const handleChangeCode = event => {
    const newValue = event.target.value.replaceAll(/\D/g, ''); // удаляем все, кроме цифр
    if (newValue.length <= 4) {
      setCode(newValue);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!profile || !profile.id) {
      alert('ID не найден. Перезайдите в кабинет.');
      return;
    }
    try {
      const { payload } = await dispatch(
        updateProfile({
          id: profile.id,
          phone: `+${phone}`,
        }),
      );
      if (payload?.data?.verificationCode) {
        alert(`Ваш код ${payload?.data?.verificationCode}`);
        setIsSendPhone(true);
      } else {
        alert('Этот номер телефона уже используется');
      }
    } catch (error) {
      console.error('Ошибка при обновлении профиля:', error);
    }
  };

  const handleSendEntrance = async event => {
    event.preventDefault();
    if (!profile || !profile.id) {
      alert('ID не найден. Перезайдите в кабинет.');
      return;
    }
    try {
      const response = await request({
        url: `profile/phone-change/verify/${profile.id}`,
        method: 'PUT',
        data: {
          verificationCode: code,
        },
      });
      if (response.status === 200) {
        navigate('/');
      } else {
        const data = await response.json();
        alert(`Ошибка: ${data.message}`);
      }
    } catch (error) {
      console.error('Ошибка при подтверждении номера телефона:', error);
    }
  };

  return (
    <Main title="Единый Аккаунт для любой среды" light lineHeight={32}>
      <p
        style={{
          fontSize: px(15),
          lineHeight: px(20),
          marginBottom: px(16),
        }}
        className="font-normal"
      >
        Работа с сервисом будет еще удобнее, если Вы заполните все формы
      </p>

      <form onSubmit={handleSubmit}>
        <PhoneInput
          error={!isValidPhone}
          setPhone={setPhone}
          handleChangePhone={e => handleChangePhone(e)}
          phone={phone}
          disabled={isSendPhone}
        />
        {isSendPhone && (
          <div className="number">
            <Input
              style={{
                borderRadius: px(0),
                margin: `${px(0)} ${px(0)} ${px(10)} ${px(0)}`,
              }}
              placeholder="Ввести код…"
              onChange={handleChangeCode}
              value={code}
              type="text"
              name="token"
              inputmode="numeric"
              pattern="\d{4}"
              autocomplete="one-time-code"
              required
            />
          </div>
        )}
        {!isSendPhone && (
          <Button
            style={{
              padding: `0 ${px(17)}`,
            }}
            className="focus:shadow-outline  w-full focus:outline-none"
            onClick={handleSubmit}
            borderRadius={0}
            height={60}
            disabled={!isValidPhone || phone?.length < 5}
          >
            Сохранить
          </Button>
        )}
        {isSendPhone && (
          <Button
            className="w-full border-[1px] border-solid border-[#dddddd] "
            type="submit"
            onClick={handleSendEntrance}
            disabled={!code || code?.length < 4}
            borderRadius={0}
            marginBottom={10}
            height={60}
          >
            Войти
          </Button>
        )}
      </form>
    </Main>
  );
};
