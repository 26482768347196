import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { px } from '../../../../../utils';

export const ObjectOffersSectors = ({ sectors, setCurrentSector }) => {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    setCurrentSector(sectors?.[0]);
  }, [sectors]);
  const handleSlideChange = () => {
    if (swiper) {
      setCurrentSector(sectors[swiper.activeIndex]);
    }
  };

  const handleClick = index => {
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  return (
    <div className="w-full" style={{ height: px(46) }}>
      <Swiper
        onSlideChange={handleSlideChange}
        onSwiper={setSwiper}
        spaceBetween={0}
        slidesPerView={5}
        centeredSlides
      >
        {sectors?.map((sector, index) => (
          <SwiperSlide key={sector.id}>
            {({ isActive }) => (
              <div
                aria-hidden
                className={`flex cursor-pointer items-center justify-center ${
                  isActive ? 'text-[#39CA1C]' : 'text-[#707070]'
                }`}
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  fontWeight: 400,
                  paddingTop: px(10),
                  paddingBottom: px(10),
                }}
                onClick={() => handleClick(index)}
              >
                {sector?.number}
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ObjectOffersSectors;
