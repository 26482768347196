import { request } from '@/helpers/api/request';

export const deleteRecommendation = async ({id}) => {

  const response = await request({
    url: `recommendation/${id}`,   
    method: 'DELETE',

  });

  return response;
};
