import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/event';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_EVENTS = 'event/getEvents';

export const getEvents = createAsyncThunk(
  GET_EVENTS,
  createRequest({
    type: GET_EVENTS,
    loader: data => authApi.getEvents(data),
  }),
);
