import React, { useState } from 'react';

import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Modal, useModal } from './portals';
import { StyledDocs } from './styled-docs';

export const SettingProfileAddPhoto = ({ withBgImage, images, setValues, single }) => {
  const buttonData = [
    {
      id: 1,
      type: TypesIcon.CHECK,
      viewBox: '0 0 19.59 24.806',
      width: px(19.59),
      height: px(24.81),
      background: '#39ca1c',
    },
    { id: 2 },
    {
      id: 3,
      type: TypesIcon.GALLERY,
      viewBox: '0 0 26.827 23.512',
      width: px(26.83),
      height: px(23.51),
      fill: '#0a84fe',
    },
    {
      id: 4,
      type: TypesIcon.NUMBERONE,
      viewBox: '0 0 18 43',
      width: px(18),
      height: px(43),
      background: '#39ca1c',
    },
    {
      id: 5,
      type: TypesIcon.SMALLBASKET,
      viewBox: '0 0 20.5 25.231',
      width: px(20.5),
      height: px(25.23),
      fill: '#ff0a0a',
    },
  ];

  const [addSlide, setAddSlide] = useState(false);

  const [temp, setTemp] = useState();

  const {
    isOpen: imageGalery,
    openModal: openImageGalery,
    closeModal: closeImageGalery,
  } = useModal();

  return (
    <div
      className={`${
        withBgImage ? 'bgImg bg-cover bg-no-repeat' : ''
      } relative border-[1px] border-solid border-[#0a84fe]  `}
      style={{
        height: px(290),
        marginBottom: withBgImage ? px(22.2) : px(27),
        marginRight: px(-15),
        marginLeft: px(-15),
      }}
    >
      {images ? (
        temp ? (
          <img src={temp} style={{ height: px(290), width: px(320) }} alt="temp" />
        ) : single ? (
          <img
            src={`${process.env.REACT_APP_STATIC_URL}${images?.url}`} // - will be changed
            style={{ height: px(290), width: px(320) }}
            alt="single"
          />
        ) : (
          <img
            src={`${process.env.REACT_APP_STATIC_URL}${images[0]?.url}`} // - will be changed
            style={{ height: px(290), width: px(320) }}
            alt="default"
          />
        )
      ) : (
        <div />
      )}
      <CircleButton
        onClick={openImageGalery}
        icon={{
          type: TypesIcon.PENSIL,
          viewBox: '0 0 18.51 23.873',
          width: px(18.51),
          height: px(23.873),
          fill: '#0A84FE',
        }}
      />
      <Modal isOpen={imageGalery} onClose={closeImageGalery}>
        <StyledDocs
          index={0}
          setAddSlide={setAddSlide}
          addSlide={addSlide}
          setUrl={setValues}
          setTemp={setTemp}
          closeModal={closeImageGalery}
        />
      </Modal>
    </div>
  );
};

export default SettingProfileAddPhoto;
