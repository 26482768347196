import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVisit } from '../../actions/visit/thunks';
import { Main } from '../../components';
import { addLeadingZeros, getFormatDate } from '../../constants/Function';
import { px } from '../../constants/Px';
import { visitSelector } from '../../selectors/visit';

export const History = () => {
  const dispatch = useDispatch();
  const visit = useSelector(visitSelector);

  useEffect(() => {
    dispatch(getVisit());
  }, []);

  return (
    <Main title="История посещен..." light paddingBottom fixed paddingTop={60}>
      <p
        style={{
          fontSize: px(15),
          lineHeight: px(20),
          paddingBottom: px(37),
        }}
        className="font-normal"
      >
        Заметили, что кто-то посещает систему без Вашего согласия? Измените код доступа в настройках
        личного кабинета
      </p>
      <div
        style={{
          gap: px(24),
        }}
        className="flex flex-col"
      >
        {visit?.map(item => (
          <div key={item?.id}>
            <p
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              Мобильная версия
            </p>
            <div className="flex flex-row">
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  marginRight: px(9),
                  marginTop: px(-1),
                }}
                className="font-normal "
              >
                {`${getFormatDate(item?.createdAt)} `}
              </p>
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  marginRight: px(8),
                  marginTop: px(-1),
                }}
                className="font-normal "
              >
                |
              </p>
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  marginTop: px(-1),
                }}
                className="font-normal "
              >
                ID: {addLeadingZeros(item?.profileId)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Main>
  );
};
