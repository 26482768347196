import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_GUIDE_CABINET_BY_PROFILE = 'guide/getGuideCabinetByProfile';

export const getGuideCabinetByProfile = createAsyncThunk(
  GET_GUIDE_CABINET_BY_PROFILE,
  createRequest({
    type: GET_GUIDE_CABINET_BY_PROFILE,
    loader: data => authApi.getGuideCabinetByProfile(data),
  }),
);
