/* eslint-disable no-unsafe-optional-chaining */
import React, { useLayoutEffect, useState } from 'react';

import { Checkbox } from '../../../components/checkbox';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { px } from '../../utils';
import { CheckboxField } from '../checkbox-field';

export const CheckboxFieldGroup = ({
  items,
  onChange,
  onClear,
  multiple,
  radio,
  value: defaultValue,
  title,
  allownce,
  error,
  ...props
}) => {
  const [value, setValue] = useState();

  useLayoutEffect(() => {
    setValue(multiple ? defaultValue || [] : [defaultValue || '']);
  }, [JSON.stringify(defaultValue)]);

  const handleChange = v => {
    setValue(v);
    if (onChange) onChange(multiple ? v : v[0]);
  };

  return radio ? (
    <div
      style={{
        marginBottom: px(27),
         marginTop: px(-5)
      }}
      className="search-menu-filter-block-game"
      {...props}
    >
      {items?.map?.(option => {
        const checked = value?.includes(option.value);

        return (
          <div
            aria-hidden
            key={option.label}
            className={`search-menu-filter-block-game-item ${checked ? 'selected' : ''}`}
            style={{ paddingTop: px(9), paddingBottom: px(9) }}
            onClick={() => handleChange([option.value])}
          >
            <div className="search-menu-filter-block-game-content">
              <div className="search-menu-filter-block-game-svg">
                <Icon
                  type={TypesIcon.GAMES}
                  width={px(24.128)}
                  height={px(26.848)}
                  viewBox="0 0 24.128 26.848"
                />
              </div>
              <div>
                <h3 style={error ? { color: 'red' } : undefined}>{title}</h3>
                <p>{option.label}</p>
              </div>
            </div>
            <Checkbox checked={checked} />
          </div>
        );
      })}
    </div>
  ) : (
    <div
      className="search-menu-filter-block"
      {...props}
      style={{ paddingBottom: props?.paddingBottom }}
    >
      <div className="search-menu-filter-block-top">
        <h4
          // style={{
          //   color: save && filter.id !== 3 && scrollIndex.includes(filter.value) && 'red',
          // }}
          // ref={filter.value === Math.min(...scrollIndex) ? scrollRef : null}
          style={error ? { color: 'red' } : { color: allownce && 'black' }}
        >
          {title}
        </h4>
        {value?.length > 0 && value[0] !== '' && !allownce && (
          <div
            aria-hidden
            className="search-menu-filter-block-reset"
            onClick={() => {
              if (onClear) onClear();
              handleChange([]);
            }}
          >
            сбросить
          </div>
        )}
      </div>
      <div className="search-menu-filter-block-bottom" style={{ rowGap: allownce && px(20) }}>
        {items.map(option => {
          const checked = value?.includes(option.value);

          return (
            <CheckboxField
              allowance={allownce}
              {...option}
              checked={checked}
              key={option.label}
              onChange={c =>
                handleChange(
                  multiple
                    ? c
                      ? [...value?.filter(i => i !== option?.value), option?.value]
                      : value?.filter(i => i !== option?.value)
                    : c
                      ? [option?.value]
                      : [],
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxFieldGroup;
