import { createReducer } from '@reduxjs/toolkit';
import { addSubscription } from '../../actions/subscription';



export const isSubscription = createReducer({}, (builder) => {
  builder.addCase(addSubscription.fulfilled, (state, { payload }) => ({
    ...state,
    isSubscribed:  false ,
  }));

});
