/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/section-discount';
import { createRequest } from '@/helpers/api/createRequest';

export const UPDATE_DISCOUNT = 'price/updateDiscount';

export const updateDiscount = createAsyncThunk(
  UPDATE_DISCOUNT,
  createRequest({
    type: UPDATE_DISCOUNT,
    loader: data => authApi.updateDiscount(data),
  }),
);
