import { request } from '../../helpers/api/request';

export const createCard = async data => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `club-card`,
    method: 'POST',
    data,
  });
  console.log(response);

  return response;
};
