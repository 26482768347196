import React from 'react';

import { px } from '../constants/Px';
import Button from './button';

export const CreateSimilarFilters = ({ setDataPickerId, setShow, date, setDate }) => (
  <>
    <p className="font-light" style={{ fontSize: px(32), lineHeight: px(43) }}>
      Создать похожий
    </p>
    <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
      Выберите из списка уже созданных объектов похожий и примените те же условия
    </p>

    <div
      className="flex-column flex"
      style={{ marginTop: px(20), gap: px(20), marginBottom: px(40) }}
    >
      <div className="flex flex-row items-center justify-between">
        <p className="font-normal opacity-50 " style={{ fontSize: px(20), lineHeight: px(26) }}>
          Выбрать из списка
        </p>
        <p
          onClick={() => {
            setDataPickerId(9);
            setShow(true);
          }}
          className={`${date?.similarSector === '0' && 'text-[#FA0303]'} font-light text-[#0A84FE]`}
          style={{ fontSize: px(20), lineHeight: px(26) }}
        >
          {date?.similarSector}
        </p>
      </div>
      <div className="flex flex-row items-center justify-between">
        <Button
          fontSize={20}
          lineHeight={26}
          height={60}
          borderRadius={0}
          color="#000"
          bgColor="#fff"
          disabled={date?.similarSector === '0'}
          onClick={() => {
            setDate({
              ...date,
              similarSector: '0',
            });
          }}
        >
          Очистить
        </Button>
        <Button
          style={{ width: px(141) }}
          fontSize={20}
          lineHeight={26}
          height={60}
          borderRadius={0}
          color="#fff"
        >
          Применить
        </Button>
      </div>
    </div>
  </>
);

export default CreateSimilarFilters;
