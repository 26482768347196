import React, { useEffect, useState } from 'react';

import { Checkbox } from '../../../../../components/checkbox';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { px } from '../../../../utils';
import { Icon } from '../../../icon';
import { Modal } from '../../modal';

export const SortModal = ({ options, closeButton, title, onChange, show, onClose, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (onChange) onChange(selectedOption);
  }, [selectedOption]);
  useEffect(() => {
    if (show) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }

    return () => document.documentElement.classList.remove('lock');
  }, [show]);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {closeButton && show && (
        <div className="scrollable-container fixed top-[0] z-[101] h-full w-full max-w-[768px] bg-white">
          <div
            className="absolute z-[22]"
            style={{ top: px(19), right: px(28) }}
            onClick={onClose}
            aria-hidden
          >
            <Icon type={TypesIcon.CLOSE} viewBox="0 0 17 17" width={px(17)} height={px(17)} />
          </div>
          <div
            className="relative text-center"
            style={{
              paddingTop: px(22),
              paddingBottom: px(12),
              borderBottom: '1px solid #B1B1B1',
              marginBottom: px(19),
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            {title}
          </div>
          <div
            className="flex items-center justify-center"
            style={{ fontSize: px(32), lineHeight: px(32), gap: px(10), paddingBottom: px(36) }}
          >
            <Icon type={TypesIcon.SORT} viewBox="0 0 25 25" width={px(25)} height={px(23.61)} />
            Сортировка
          </div>
          <div
            className="flex flex-col"
            style={{
              gap: px(39),
              paddingLeft: px(15),
              paddingRight: px(15),
              paddingBottom: px(21),
            }}
          >
            {options?.map(item => (
              <div key={item?.id} className="flex items-center justify-between">
                <p
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                  }}
                  className="font-normal"
                >
                  {item?.title}
                </p>
                <Checkbox
                  margin
                  checked={selectedOption?.id === item?.id}
                  value={item?.value}
                  onChange={() => setSelectedOption(item)}
                  style={{
                    margin: '0px',
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!closeButton && (
        <Modal {...props}>
          <div className="h-full overflow-y-auto">
            <div
              className="flex flex-col"
              style={{
                marginTop: px(15),
                gap: px(39),
                paddingLeft: px(15),
                paddingRight: px(15),
              }}
            >
              {options?.map(item => (
                <div key={item?.id} className="flex items-center justify-between">
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="font-normal"
                  >
                    {item?.title}
                  </p>
                  <Checkbox
                    margin
                    checked={selectedOption?.id === item?.id}
                    value={item?.value}
                    onChange={() => setSelectedOption(item)}
                    style={{
                      margin: '0px',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SortModal;
