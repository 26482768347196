import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { authCodeDelete, signupBiometric } from '../../actions/auth';
import { deleteBiometric } from '../../actions/profile';
import { signupChallenge } from '../../api/auth';
import { CustomAlert, Main, Switch } from '../../components';
import { Icon } from '../../components/Icon';
import { verifyFaceId } from '../../constants/Component';
import { px } from '../../constants/Px';
import { isBiometricSelector } from '../../selectors/auth';

const cbor = require('cbor');

export const VerifyFaceIdPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isBiometric = useSelector(isBiometricSelector);
  const [toggle, setToggle] = useState(!!isBiometric);

  const [error, setError] = useState(false);

  const handleNavigate = async ({ patch, deleteCode }) => {
    if (deleteCode) {
      try {
        dispatch(authCodeDelete());
      } catch {
        console.log('error authCodeDelete');
      }
    } else {
      navigate(`/${patch}`);
    }
  };

  const genKey = async event => {
    event.preventDefault();
    try {
      const response = await signupChallenge(cbor.encode({}));
      console.log('response', response);

      const parsedOptions = await cbor.decodeFirstSync(response?.data);

      const credential = await navigator.credentials.create({
        publicKey: parsedOptions,
      });
      console.log(' credential', credential);

      const responseBiometric = await dispatch(
        signupBiometric(
          cbor.encode({
            rawId: credential.rawId,
            type: credential.type,
            response: {
              attestationObject: credential.response.attestationObject,
              clientDataJSON: credential.response.clientDataJSON,
            },
          }),
        ),
      );
    } catch (error_) {
      console.log(error_, 'error');
    }
  };

  const onClickButtonSubmit = () => {
    setToggle(false);
    setError(false);
  };
  const onClickToggle = event => {
    if (toggle) {
      dispatch(deleteBiometric());
    } else {
      genKey(event);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Main
      navigatePatch="/profile"
      title="Вход и Надежность"
      light
      lineHeight={32}
      paddingBottom
      titleWidth
    >
      <div
        style={{
          marginTop: px(15),
          marginBottom: px(22),
        }}
        className="flex  items-center justify-between"
      >
        <div
          style={{
            width: px(218),
          }}
          className="flex flex-col"
        >
          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              width: px(219),
            }}
            className="font-normal"
          >
            Face ID и Touch ID
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal opacity-80"
          >
            Использовать для входа и подтверждения операций
          </p>
        </div>
        <div
          onClick={event => {
            onClickToggle(event);
          }}
          onKeyDown={event => {
            onClickToggle(event);
          }}
        >
          <Switch toggle={toggle} setToggle={setToggle} />
        </div>
      </div>

      <div
        style={{
          gap: px(41),
        }}
        className="flex flex-col"
      >
        {verifyFaceId.map(item => (
          <div
            key={item.id}
            className="flex items-center"
            onClick={() => {
              handleNavigate({ patch: item.navigatePatch, deleteCode: item?.deleteCode });
            }}
            onKeyDown={() => {
              handleNavigate({ patch: item.navigatePatch, deleteCode: item?.deleteCode });
            }}
          >
            <div
              style={{
                width: px(48),
              }}
            >
              <Icon
                type={item.iconType}
                viewBox={item.viewBox}
                width={item.width}
                height={item.height}
              />
            </div>
            <p className="font-normal">{item.title}</p>
          </div>
        ))}
        {error && (
          <CustomAlert
            onClickButtonSubmit={onClickButtonSubmit}
            single
            title="Проверьте настройки WebAuth"
            buttonRightText="Ок"
          />
        )}
      </div>
    </Main>
  );
};
