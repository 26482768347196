import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';

import { getProfile } from '../../../../api/profile';
import { CustomAlert, FooterSlider, LoyalCard, TruncatedText } from '../../../../components';
import { profileSelector } from '../../../../selectors/profile';
import { TypesIcon } from '../../../../types/TypesIcon';
import { BackButton, CardInformation, CircleButton, ScrollButtonsContainer } from '../../../shared';
import { format2DigitDate, px } from '../../../utils';
import { useObject } from '../../hooks';
import { RequestClubCardModal } from '../request-club-card-modal/request-club-card-modal';

export const ClubCardsModal = ({ show, onClose }) => {
  const [openDropdownIds, setOpenDropdownIds] = useState([]);
  const [openRequestClubCardModal, setOpenRequestClubCardModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);
  const {
    data: { object },
  } = useObject();
  const clubCards = useMemo(
    () => object?.ClubCards?.filter(clubCard => !clubCard?.deletedAt),
    [object],
  );

  const handleComponentClick = id => {
    if (openDropdownIds.includes(id)) {
      setOpenDropdownIds(openDropdownIds.filter(item => item !== id));
    } else {
      setOpenDropdownIds([...openDropdownIds, id]);
    }
  };
  useEffect(() => {
    if (show) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }

    return () => document.documentElement.classList.remove('lock');
  }, [show]);

  const onRealIndexChange = element => {
    setActiveIndex(element);
  };
  const slider = useMemo(
    () =>
      clubCards?.map(card => ({
        data: {
          bgImg: card?.image?.url,
          cardNumber: card?.id,
          ownerName: 'ИМЯ ВЛАДЕЛЬЦА',
          cardType: card?.name,
          cardDiscount: card?.cardDiscount,
          displayLogo: card?.displayLogo,
          logoSrc: object?.EventAttachment?.filter(attachment => attachment.type === 'LOGO')[0]
            ?.imageUrl,
          openUntill: format2DigitDate(card?.openUntill),
          cardPaymentType: card?.cardPaymentType,
        },
      })),
    [clubCards],
  );
  return (
    <>
      <div className="draggable-modal-container z-[101]">
        <div className="global-container">
          <BackButton onClick={onClose} />
          <main>
            <div style={{ paddingBottom: px(80) }} className="scrollable-container">
              <p
                style={{
                  fontSize: px(32),
                  lineHeight: px(32),
                  paddingTop: px(26),
                  paddingBottom: px(5),
                }}
                className="text-center font-light"
              >
                Виды карт
                <br /> и привилегии
              </p>
              <TruncatedText
                data={{
                  textBefore: 'Виртуальные карты лояльности для постоянных гостей. Является...',
                  textAfter:
                    'Виртуальные карты лояльности для постоянных гостей. Является Виртуальные карты лояльности для постоянных гостей. Является Виртуальные карты лояльности для постоянных гостей. Является',
                  lines: 2,
                }}
                style={{
                  paddingLeft: px(27),
                  paddingRight: px(27),
                }}
              />
              {clubCards?.length > 0 ? (
                <div
                  className="w-full"
                  style={{
                    padding: `${px(16)} ${px(15)} ${px(0)} ${px(15)}`,
                  }}
                >
                  <FooterSlider
                    slidesPerView={2}
                    gap={20}
                    cards
                    pagination
                    onRealIndexChange={onRealIndexChange}
                    centeredSlides
                  >
                    {slider?.map(item => (
                      <SwiperSlide key={item?.data?.cardNumber} className="swiper-slide">
                        <div className="flex w-full justify-center">
                          <LoyalCard
                            color="#fff"
                            data={{
                              bgImg: item?.data?.bgImg,
                              cardNumber: item?.data?.cardNumber,
                              ownerName: item?.data?.ownerName,
                              cardType: item?.data?.cardType,
                              percent: item?.data?.cardDiscount,
                              openUntill: item?.data?.openUntill,
                              logo: {
                                logoSrc: item?.data?.logoSrc,
                                showLogo: item?.data?.displayLogo,
                              },
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </FooterSlider>
                  <div>
                    <CardInformation
                      key={`cardInfoBlocks-${activeIndex}`}
                      data={[
                        {
                          id: 1,
                          title: { label: 'Имя карты' },
                          value: clubCards?.[activeIndex]?.name,
                        },
                        {
                          id: 2,
                          title: { label: 'Статус карты' },
                          value: `${clubCards?.[activeIndex]?.cardDiscount || 0}%`,
                        },
                        {
                          id: 3,
                          title: { label: 'Срок действия' },
                          value: (() => {
                            const openUntillDate = clubCards?.[activeIndex]?.openUntill;
                            if (openUntillDate) {
                              return new Date(openUntillDate).toLocaleDateString('en-US', {
                                year: '2-digit',
                                month: '2-digit',
                              });
                            }
                            return '00/00';
                          })(),
                        },
                        clubCards?.[activeIndex]?.cardPaymentType !== 'FREE' && {
                          id: 4,
                          title: { label: 'Условия получения' },
                          value: clubCards?.[activeIndex]?.receiptConditions,
                        },
                      ]}
                    />
                    <CardInformation
                      key={`cardInfoDropdowns-${activeIndex}`}
                      handleComponentClick={handleComponentClick}
                      data={[
                        {
                          id: 1,
                          title: { label: 'Общая информация' },
                          text: clubCards?.[activeIndex]?.generalInformation,
                          disabled: clubCards?.[activeIndex]?.generalInformation === '',
                        },
                        {
                          id: 2,
                          title: { label: 'Где применяется' },
                          text: clubCards?.[activeIndex]?.applicationScope,
                          disabled: clubCards?.[activeIndex]?.applicationScope === '',
                        },
                        {
                          id: 3,
                          title: { label: 'Соглашение' },
                          text: clubCards?.[activeIndex]?.agreement,
                          disabled: clubCards?.[activeIndex]?.agreement === '',
                        },
                        {
                          id: 4,
                          title: { label: 'Регламент использования' },
                          text: clubCards?.[activeIndex]?.useTerms,
                          disabled: clubCards?.[activeIndex]?.useTerms === '',
                        },
                        {
                          id: 5,
                          title: { label: 'Обслуживание клиентов' },
                          text: [
                            {
                              label: 'Email:',
                              value: object?.EventContact?.email,
                            },
                            {
                              value: object?.EventContact?.mainPhone,
                            },
                            {
                              value: object?.EventContact?.secondaryPhone,
                            },
                          ],
                        },
                      ]}
                      dropDown
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    paddingTop: px(20),
                  }}
                >
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="text-center font-normal opacity-50"
                  >
                    Карты не найдены
                  </p>
                </div>
              )}
              {clubCards?.[activeIndex]?.cardPaymentType === 'FREE' && (
                <ScrollButtonsContainer>
                  <CircleButton
                    onClick={() => {
                      setOpenRequestClubCardModal(true);
                    }}
                    icon={{
                      type: TypesIcon.HAND,
                      viewBox: '0 0 19 26',
                      width: px(19.34),
                      height: px(25.49),
                    }}
                    className="text-[#0A84FE]"
                  />
                </ScrollButtonsContainer>
              )}
              {clubCards?.[activeIndex]?.cardPaymentType === 'PAID' && (
                <ScrollButtonsContainer>
                  <CircleButton
                    icon={{
                      type: TypesIcon.RUB,
                      viewBox: '0 0 24 24',
                      width: px(24),
                      height: px(24),
                    }}
                    className="flex items-center justify-center text-[#0A84FE]"
                  />
                </ScrollButtonsContainer>
              )}
            </div>
          </main>
        </div>
      </div>
      {openRequestClubCardModal && (
        <RequestClubCardModal
          show={openRequestClubCardModal}
          onClose={() => {
            setOpenRequestClubCardModal(false);
          }}
          title="Запрос Клубной Карты"
          data={{ cardId: clubCards?.[activeIndex]?.id, profileId: profile?.id }}
          setShowAlert={setShowAlert}
        />
      )}
      {showAlert && (
        <CustomAlert
          onClickButtonSubmit={() => setShowAlert(false)}
          title="Запрос отправлен. После обработки данных, ответ поступит в раздел «Уведомления»"
          buttonRightText="Ок"
        />
      )}
    </>
  );
};

export default ClubCardsModal;
