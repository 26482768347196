import React, { useState } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const CardInformation = ({ data, onChange, cardHolder, dropDown, handleComponentClick }) => {
  const [openIndex, setOpenIndex] = useState();

  const handleClose = index => {
    if (handleComponentClick) {
      handleComponentClick(data[index]?.id);
    }
    setOpenIndex(openIndex === index ? undefined : index);
  };

  return (
    <div className="w-full">
      {data?.map((el, index) => (
        <div
          style={{
            paddingTop: px(25),
            paddingBottom: px(25),
            gap: dropDown && px(10),
          }}
          key={cardHolder ? el?.id : el?.drumId}
          className={`${
            dropDown
              ? 'flex flex-col items-start justify-start'
              : 'flex items-center justify-between'
          } border-b-[1px] border-solid border-black border-opacity-30`}
        >
          <div
            aria-hidden
            className={`flex w-full items-center justify-between ${dropDown && 'cursor-pointer'} ${
              el?.disabled && 'pointer-events-none opacity-30'
            }`}
            onClick={() => handleClose(index)}
          >
            <p
              style={{
                fontSize: px(openIndex === index && dropDown ? 20 : 15),
                lineHeight: px(openIndex === index && dropDown ? 26 : 20),
              }}
              className="font-normal"
            >
              {el?.title?.label}
            </p>
            {dropDown && (
              <div
                className={`${
                  openIndex === index
                    ? 'rotate-0 transform transition-all duration-300'
                    : 'rotate-180'
                }`}
              >
                <Icon
                  type={TypesIcon.DROPDOWNTRIANGLE}
                  width={px(8)}
                  height={px(6)}
                  viewBox="0 0 8 6"
                  fill="black"
                />
              </div>
            )}
            {cardHolder && !dropDown && (
              <div>
                <p
                  className="font-normal"
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    color: el?.color,
                  }}
                >
                  {el?.value}
                </p>
              </div>
            )}
            {!cardHolder && (
              <div aria-hidden onClick={() => onChange && onChange(el?.drumId)}>
                <p
                  aria-hidden
                  className="w-full break-words font-normal"
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    color: el?.color,
                  }}
                  onClick={el?.onClick}
                >
                  {el?.value}
                </p>
              </div>
            )}
          </div>
          {openIndex === index &&
            (Array.isArray(el.text)
              ? el.text.some(item => item.value) && (
                  <div
                    className="w-full font-normal"
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                    }}
                  >
                    <div className="flex flex-col items-start justify-start">
                      {el.text.map(item => (
                        <React.Fragment key={item.label + item.value}>
                          {item.label && item.value && (
                            <p
                              style={{
                                fontSize: px(15),
                                lineHeight: px(20),
                              }}
                              className="w-full break-words font-normal"
                            >
                              {item.label} {item.value}
                            </p>
                          )}
                          {item.value && (
                            <p
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                              }}
                              className="w-full break-words font-normal font-normal text-[#0A84FE]"
                            >
                              {item.value}
                            </p>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )
              : el?.text && (
                  <div
                    className="w-full font-normal"
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                    }}
                  >
                    <p
                      style={{
                        fontSize: px(15),
                        lineHeight: px(20),
                      }}
                      className="w-full break-words font-normal font-normal"
                    >
                      {el.text}
                    </p>
                  </div>
                ))}
        </div>
      ))}
    </div>
  );
};

export default CardInformation;
