import { request } from '../../helpers/api/request';

export const getCards = async ({ eventId }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `club-card`,
    method: 'GET',
    params: { eventId },
  });

  return response;
};
