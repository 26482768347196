import React, { useEffect, useState } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { CircleButton } from '../new-architecture/shared';

export const ScrollUpToTopArrow = () => {
  const [scrollActiveBottom, setScrollActiveBottom] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
      setScrollActiveBottom(window.scrollY >= scrollableHeight - 800);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const containerClass = `scroll-up-to-top-container ${
    scrollActiveBottom ? 'scroll-active' : 'scroll-no-active'
  }`;

  return (
    <div className={containerClass} onClick={handleScrollToTop} aria-hidden>
      <CircleButton
        icon={{
          type: TypesIcon.ARROWTOP,
          viewBox: '0 0 14 21',
          width: px(14),
          height: px(21),
        }}
        className="text-[#0A84FE]"
      />
    </div>
  );
};

export default ScrollUpToTopArrow;
