// eslint-disable-next-line import/no-unresolved
import { request } from '@/helpers/api/request';

export const deleteCompetitionEvent = async id => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `competition-event/${id}`,
    method: 'DELETE',
  });
  return response;
};
