import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { px } from '../constants/Px';

const mapRange = (value, inMin, inMax, outMin, outMax) =>
  ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

export const RangeSlider = ({
  min = 0,
  max = 10000,
  snapRange = 30,
  value: defaultValue = 10000,
  onChange,
  marks: defaultMarks = [],
  free,
}) => {
  const constraintsRef = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const [trackWidth, setTrackWidth] = useState(null);
  const controls = useAnimation();

  console.log('val', value);

  useEffect(() => {
    if (constraintsRef?.current) {
      setTrackWidth(parseFloat(getComputedStyle?.(constraintsRef?.current, ':before')?.width));
    }
  }, []);

  const marks = useMemo(() => [...new Set([min, ...defaultMarks, max])], [defaultMarks, max, min]);

  useEffect(() => {
    const handleResize = () => {
      setTrackWidth(parseFloat(getComputedStyle?.(constraintsRef?.current, ':before')?.width));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="range-slider__holder">
      <input type="hidden" name="slider" value={value} />
      <motion.div className="range-slider__track" ref={constraintsRef}>
        {marks?.map(mark => (
          <div
            className="range-slider__mark"
            key={mark}
            style={{
              position: 'absolute',
              left: px(7.5),
              top: px(-5),
              transform: `translateX(${mapRange(mark, min, max, 0, trackWidth)}px)`,
            }}
          />
        ))}
        <div className="range-slider__knob range-slider__knob--grey" />
        {trackWidth && (
          <motion.div
            className="range-slider__knob"
            drag="x"
            initial={{
              x: mapRange(defaultValue, min, max, 0, trackWidth),
            }}
            animate={controls}
            dragMomentum={false}
            onDragEnd={() => {
              const { delta, point } = marks
                ?.map(mark => ({
                  delta: Math.abs(value - mark),
                  point: mark,
                }))
                .reduce((prev, curr) => (prev?.delta < curr?.delta ? prev : curr));

              if (delta < snapRange) {
                const x = mapRange(point, min, max, 0, trackWidth);
                controls.start({
                  x,
                });
                const mapped = mapRange(x, 0, trackWidth, min, max);
                const newValue = Math.round(mapped < min ? min : mapped > max ? max : mapped);
                const returnedValue = free
                  ? newValue
                  : newValue < 8400
                    ? Math.round(newValue / 8.535)
                    : newValue < 9000
                      ? 1000
                      : 10_000;
                setValue(returnedValue);
                return onChange(returnedValue);
              }

              return onChange(value);
            }}
            onDrag={(event, info) => {
              const mapped = mapRange(info?.point?.x, 0, trackWidth, min, max);
              const newValue = Math.round(mapped < min ? min : mapped > max ? max : mapped);
              const returnedValue = free
                ? newValue
                : newValue < 8100
                  ? Math.round(newValue / 8.535)
                  : newValue < 9000
                    ? 8535
                    : 10_000;
              setValue(returnedValue);
              return onChange(returnedValue === 8535 ? 1000 : returnedValue);
            }}
            dragConstraints={constraintsRef}
          />
        )}
      </motion.div>
    </div>
  );
};

export default RangeSlider;
