import React from 'react';

import { px } from '../constants/Px';
import { ObjectDescriptionTextarea } from './object-description-textarea';

export const ObjectNameObjectDescriptionPage = ({
  inputsData,
  setInputsData,
  update,
  title,
  subTitle,
  data,
  children,
  isUpdate,
}) => {
  const handleInputChange = (key, value) => {
    setInputsData(previousInputs => ({
      ...previousInputs,
      [key]: value,
    }));
    /* if (!isUpdate) {
      update();
    } */
  };

  return (
    <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
      {title && (
        <p
          style={{
            fontSize: px(32),
            lineHeight: px(32),
          }}
          className="whitespace-pre-wrap  font-light"
        >
          {title}
        </p>
      )}
      {subTitle && (
        <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
          {subTitle}
        </p>
      )}

      {children}
      {data?.length &&
        data?.map(item => (
          <ObjectDescriptionTextarea
            key={item?.id}
            objectStyles={item}
            value={Object?.values(inputsData)?.[item?.id]}
            setValue={handleInputChange}
            objectKey={item?.objectKey}
          />
        ))}
    </div>
  );
};

export default ObjectNameObjectDescriptionPage;
