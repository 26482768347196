import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/location';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_LOCATION = 'location/createLocation';

export const createLocation = createAsyncThunk(
  CREATE_LOCATION,
  createRequest({
    type: CREATE_LOCATION,
    loader: data => authApi.createLocation(data),
  }),
);
