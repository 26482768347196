import { bookingDistributionPage } from '../../constants/Component';
import { useEffect, useState } from 'react';
import DistributionPageComponent from '../../components/distribution-page-component';
import { useLocation } from 'react-router';
import { useContext } from 'react';
import { StateContext } from '../../components/state-provider';

export const Distribution = () => {
  const [isPageIndex, setIsPageIndex] = useState(0);

  const { bookingCopyPeriod, setBookingCopyPeriod } = useContext(StateContext);
  const { statisticCopyPeriod, setStatisticCopyPeriod } = useContext(StateContext);
  const { suggestionCopyPeriod, setSuggestionCopyPeriod } = useContext(StateContext);


  const { state } = useLocation();
  const [isSendBookingCopy, setIsSendBookingCopy] = useState(false);
  const [isSendStatisticCopy, setIsSendStatisticCopy] = useState(false);
  const [isSendSuggestionCopy, setIsSendSuggestionCopy] = useState(false);
  const [isSendBookingCopyIfNo, setIsSendBookingCopyIfNo] = useState(false);
  const [isSendStatisticCopyIfNo, setIsSendStatisticCopyIfNo] = useState(false);
  const [isSendSuggestionCopyIfNo, setIsSendSuggestionCopyIfNo] = useState(false);
  const [timeBooking, setTimeBooking] = useState({
    hour: '06:00',
  });
  const [timeStatistic, setTimeStatistic] = useState({
    hour: '06:00',
  });
  const [timeSuggestion, setTimeSuggestion] = useState({
    hour: '06:00',
  });

  const stateCollection = [
    {
      id: 1,
      pageTitle: 'Бронирования.\n' + 'Рассылка копий',
      pageSubtitle:
        'Дополнительная уверенность в ваших\n' +
        'Бронированиях. Отправляйте копии и \n' +
        'выписки на свою электронную почту',
      datePickerData: bookingDistributionPage,
      toggle: isSendBookingCopy,
      setToggle: setIsSendBookingCopy,
      toggleNo: isSendBookingCopyIfNo,
      setToggleNo: setIsSendBookingCopyIfNo,
      time: timeBooking,
      setTime: setTimeBooking,
      period: bookingCopyPeriod,
      setPeriod: setBookingCopyPeriod,
      periodValue: bookingCopyPeriod.distribution,
    },
    {
      id: 2,
      pageTitle: 'Статистика.\n' + 'Рассылка копий',
      pageSubtitle:
        'Дополнительная уверенность в ваших\n' +
        'Финансах и Показателях. Отправляйте \n' +
        'копии на свою электронную почту',
      datePickerData: bookingDistributionPage,
      toggle: isSendStatisticCopy,
      setToggle: setIsSendStatisticCopy,
      toggleNo: isSendStatisticCopyIfNo,
      setToggleNo: setIsSendStatisticCopyIfNo,
      time: timeStatistic,
      setTime: setTimeStatistic,
      period: statisticCopyPeriod,
      setPeriod: setStatisticCopyPeriod,
      periodValue: statisticCopyPeriod.distribution,
    },
    // {
    //   id: 3,
    //   pageTitle: 'Предложения.\n' + 'Рассылка копий',
    //   pageSubtitle:
    //     'Контролируйте в единой таблице информацию по тарифам, условиям, ценам и допускам',
    //   datePickerData: bookingDistributionPage,
    //   toggle: isSendSuggestionCopy,
    //   setToggle: setIsSendSuggestionCopy,
    //   toggleNo: isSendSuggestionCopyIfNo,
    //   setToggleNo: setIsSendSuggestionCopyIfNo,
    //   time: timeSuggestion,
    //   setTime: setTimeSuggestion,
    //   period: suggestionCopyPeriod,
    //   setPeriod: setSuggestionCopyPeriod,
    //   periodValue: suggestionCopyPeriod.distribution,
    // },
  ];

  useEffect(() => {
    state?.data.id === 'booking'
      ? setIsPageIndex(0)
      : state?.data.id === 'statistic'
      ? setIsPageIndex(1)
      : setIsPageIndex(2);
  }, [state]);
  return (
    <>
      <DistributionPageComponent
        title={stateCollection[isPageIndex].pageTitle}
        dataPickerTitle={'Период'}
        dataPickerData={stateCollection[isPageIndex].datePickerData}
        setPickerValue={stateCollection[isPageIndex].setPeriod}
        setTime={stateCollection[isPageIndex].setTime}
        pickerValue={stateCollection[isPageIndex].period}
        time={stateCollection[isPageIndex].time}
        subtitle={stateCollection[isPageIndex].pageSubtitle}
        toggleNo={stateCollection[isPageIndex].toggleNo}
        setToggleNo={stateCollection[isPageIndex].setToggleNo}
        toggle={stateCollection[isPageIndex].toggle}
        setToggle={stateCollection[isPageIndex].setToggle}
        period={stateCollection[isPageIndex].periodValue}
      />
    </>
  );
};
