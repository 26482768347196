import React from 'react';

import { formatDate } from '../constants/Function';
import { px } from '../constants/Px';

export const PeriodBlock = ({ show, gap = 14, paddingBottom = 20, pickerDate, date }) => {
  const data = [
    {
      id: 121,
      title: `Начало периода`,
      data: [
        { id: 46, subTitle: 'Время', time: date?.time },
        {
          id: 47,
          subTitle: 'Дата',
          time: `${date?.day}.${date?.month}.${date?.year}`,
        },
      ],
    },
    {
      id: 122,
      title: `Конец периода`,

      data: [
        { id: 0, subTitle: 'Время', time: date?.time },
        {
          id: 1,
          subTitle: 'Дата',
          time: formatDate({
            day: pickerDate?.day,
            month: pickerDate?.month,
            year: pickerDate?.year,
          }),
          onClick: show,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        gap: px(gap),
      }}
      className="flex"
    >
      {data.map(item => (
        <div
          style={{
            width: px(139),
          }}
          key={item?.id}
          className="flex flex-col"
        >
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal  opacity-50"
          >
            {item?.title}
          </p>

          <div
            style={{
              gap: px(10),
            }}
            className="flex flex-row"
          >
            {item?.data?.map(element => (
              <div key={element?.id}>
                {element?.subTitle && (
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      paddingBottom: px(paddingBottom),
                    }}
                    className="font-normal  opacity-50"
                  >
                    {element?.subTitle}
                  </p>
                )}
                {element?.time && (
                  <>
                    <div
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                      }}
                      className={`font-normal ${
                        element?.id === 1 ? 'text-[#0A84FE]' : 'text-black'
                      } `}
                      onClick={element?.onClick}
                      onKeyDown={show}
                    >
                      {element?.time}
                    </div>
                    <hr
                      className="text-black opacity-30"
                      style={{
                        marginTop: px(5),
                      }}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PeriodBlock;
