import React from 'react';
import { px } from '../constants/Px';

export const Checkboxes = ({ checkboxes, handleInputChange }) => {

  return (
    <>
      {Object?.keys(checkboxes)?.map((key) => (
        <div
          key={key}
          value={checkboxes?.[key]}
          onClick={() => handleInputChange(key, !checkboxes?.[key])}
          className={` cursor-pointer ${
            checkboxes?.[key] ? 'bg-[#0a84fe]' : 'bg-white border-solid border-[1px] border-[#000]'
          }`}
          style={{
            width: px(17),
            height: px(17),
            marginLeft: px(10),
          }}
        />
      ))}
    </>
  );
};

export default Checkboxes;
