import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/react';

import mastercardImage from '../assets/images/payments/mastercard.png';
import mirImage from '../assets/images/payments/mir.png';
import mtspayImage from '../assets/images/payments/mtspay.png';
import spbImage from '../assets/images/payments/spb.png';
import unionpayImage from '../assets/images/payments/unionpay.png';
import visaImage from '../assets/images/payments/visa.png';
import yapayImage from '../assets/images/payments/yapay.png';
import {
  footerAdvertOptions,
  footerCardsSliderIcons,
  footerChatOptions,
  footerNetworkIcons,
  footerProjectXOptions,
} from '../constants/Component';
import { px } from '../constants/Px';
import { numberPx } from '../new-architecture/utils';
import { TypesIcon } from '../types/TypesIcon';
import { FooterDropdown } from './footer-dropdown';
import { FooterSlider } from './footer-slider';
import { Icon } from './Icon';

export const Footer = () => {
  const { pathname } = useLocation();
  const paymentMethodsReference = useRef(null);
  const handleScrollFooterPayments = event => {
    const delta = event.deltaY || event.detail || event.wheelDelta;
    if (delta !== 0) {
      const currentScrollLeft = paymentMethodsReference.current.scrollLeft;
      paymentMethodsReference.current.scrollLeft = currentScrollLeft + delta;
    }
  };
  useEffect(() => {
    const element = paymentMethodsReference.current;
    element.addEventListener('wheel', handleScrollFooterPayments);

    return () => {
      element.removeEventListener('wheel', handleScrollFooterPayments);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <footer>
      <div className="footer__content">
        <div className="footer__follow-us">
          <h2>Подпишитесь на нас</h2>
          <div className="footer__follow-us-links">
            {footerNetworkIcons.map(item => (
              <a
                className="pointer-events-none"
                href={item.href}
                target="_blank"
                rel="noreferrer"
                key={item.id}
              >
                <Icon
                  type={item.type}
                  width={item.width}
                  height={item.height}
                  viewBox={item.viewBox}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="footer__dropdowns">
          <FooterDropdown footerIcon label="Booking.fishing" options={footerProjectXOptions} />
          <FooterDropdown footerIcon label="Реклама" options={footerAdvertOptions} />
          <FooterDropdown
            footerIcon
            paddingTop={18}
            label="Чат. Обращения"
            options={footerChatOptions}
          />
        </div>
        <div className="footer__payment-methods">
          <div className="footer__payment-methods-protection">
            <Icon
              type={TypesIcon.WHITELOCK}
              width={px(16.992)}
              height={px(22.656)}
              viewBox="0 0 16.992 22.656"
            />
            <p>Безопасные покупки</p>
          </div>
          <div className="footer__payment-methods-images" ref={paymentMethodsReference}>
            <FooterSlider slidesPerView={3} gap={numberPx(15)}>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={mirImage} alt="mir" />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={mastercardImage} alt="mastercard" />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={visaImage} alt="visa" />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={unionpayImage} alt="unionpay" />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={spbImage} alt="spb" />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={yapayImage} alt="yapay" />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="footer__payment-methods-images-img">
                  <img src={mtspayImage} alt="mtspay" />
                </div>
              </SwiperSlide>
            </FooterSlider>
          </div>
        </div>
        <div className="footer__copyright">
          <p>
            <span>2024 Booking.fishing</span>
            <span>Бронирование</span>
            <span>рыбалки по всему миру. </span>
            <span>© Все права защищены</span>
          </p>
        </div>
        <div className="footer__bottom-links">
          <Link to="/privacy">Privacy</Link>
          <Link to="/cookies">Cookies</Link>
          <Link href="/terms-of-use">Terms and conditions</Link>
          <Link href="/rss-news">RSS News</Link>
          <Link href="/of-use">of Use</Link>
          <Link href="/rss-products">Rss Products</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
