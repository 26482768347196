/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unicorn/no-null */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { styledGallery } from '../constants/Component';
import getCroppedImg from '../constants/Images';
import { numberPx, px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { galleryObjectSelector } from '../selectors/get-event';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { StateContext } from './state-provider';

export const StyledGalleryFilters = ({
  index,
  data,
  setIndex,
  setAddSlide,
  addSlide,
  setUrl,
  setSave,
  closeModal,
}) => {
  const { handleClose, dragControls } = useContext(StateContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const cropRef = useRef(null);
  const inputRef = useRef(null);
  const [filesSrc, setFilesSrc] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [slideIndex, setslideIndex] = useState(index || 0);
  const [croppedImage, setCroppedImage] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const eventObject = useSelector(galleryObjectSelector);

  // setUrl(filesSrc?.file);

  // handle Change
  const handleImgChange = event => {
    if (event?.target?.files[0]) {
      if (event.target.files) {
        const { files } = event.target;
        // eslint-disable-next-line no-underscore-dangle
        for (let index_ = 0; index_ < files.length; index_++) {
          const file = files.item(index_);

          if (file) {
            const reader = new FileReader();

            reader.addEventListener('load', event_ => {
              setFilesSrc(previousState => ({
                ...previousState,
                [file.name]: String(event_.target?.result) || '',
                url: String(event_.target?.result) || '',
                file,
              }));
            });

            reader.readAsDataURL(file);
          }
        }
      }
    }
    setDisabled(true);
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async event_ => {
    event_.preventDefault();

    const croppedImg = await getCroppedImg(filesSrc?.url, croppedAreaPixels, 0);

    setUrl(croppedImg);
    if (setSave) setSave(false);
    handleClose();
  };

  const handleDeletePhoto = () => {
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setFilesSrc(null);
    setDisabled(false);
  };

  const handleNavigate = () => {
    navigate('/demand', { state: { prevPath: pathname } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (setIndex) setIndex(index);
    };
  }, []);

  return (
    <div
      className="search"
      style={{ marginLeft: px(-15), paddingLeft: px(0), marginRight: px(-15), paddingRight: px(0) }}
    >
      <div className="flex-column flex w-full items-center justify-center">
        <div />
        <div
          style={{
            width: `${numberPx(320)}px`,
            height: `${numberPx(304)}px`,
          }}
          className="relative  bg-[#0A84FE4D]  "
        >
          {(!filesSrc && !eventObject[index]?.src) || addSlide ? (
            <div
              style={{
                width: px(styledGallery.cropWidth),
                height: px(styledGallery.cropHeight),
                borderRadius: px(styledGallery.borderRadius),
              }}
              className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2  transform items-center justify-center bg-[#FFFFFF] opacity-30"
            >
              <Icon type={TypesIcon.RESIZE} viewBox="0 0 70 70" width={px(70)} height={px(70)} />
            </div>
          ) : (
            <div
              style={{
                width: px(styledGallery.cropWidth),
                height: px(styledGallery.cropHeight),
                borderRadius: px(styledGallery.borderRadius),
              }}
              className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2  transform items-center justify-center bg-[#FFFFFF] opacity-30"
            >
              <img
                src={eventObject[index]?.src}
                alt="cropp"
                style={{
                  width: px(styledGallery.cropWidth),
                  height: px(styledGallery.cropHeight),
                  borderRadius: px(styledGallery.borderRadius),
                }}
              />
            </div>
          )}

          <Cropper
            key={index}
            imageRef={cropRef}
            image={filesSrc?.url}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
            cropShape={index === 0 ? 'round' : 'rect'}
            style={{
              cropAreaStyle: {
                boxShadow: 'none',
                background: '#ffffff80',
                border: 'none',
                borderRadius: numberPx(styledGallery.borderRadius),
              },
            }}
            restrictPosition={false}
            cropSize={{
              width: numberPx(styledGallery.cropWidth),
              height: numberPx(styledGallery.cropHeight),
            }}
            showGrid={false}
          />
        </div>
      </div>

      <div
        style={{
          gap: px(10),
          paddingTop: px(10),
        }}
        className="flex items-center justify-center"
      >
        <CircleButton
          onClick={e => {
            handleSave(e);
            if (setAddSlide) setAddSlide(false);
            closeModal();
          }}
          disabled={!filesSrc}
          icon={{
            type: TypesIcon.CONFIRM,
            viewBox: '0 0 19.59 24.806',
            width: px(19.59),
            height: px(50),
          }}
        />
        <CircleButton
          onClick={() => handleNavigate()}
          icon={{
            type: TypesIcon.INFO,
            viewBox: '0 0 17 50',
            width: px(17),
            height: px(50),
          }}
        />
        <CircleButton
          ref={inputRef}
          icon={{
            type: TypesIcon.GALLERY,
            viewBox: '0 0 26.827 23.512',
            width: px(26.827),
            height: px(23.512),
            fill: '#0A84FE',
          }}
        >
          <label
            style={{
              width: px(26.827),
              height: px(23.512),
            }}
            className="flex items-center justify-center"
          >
            <input
              className="leading-0 m-0 h-0 w-0  p-0 opacity-0"
              ref={inputRef}
              id="upload-photo"
              type="file"
              accept="image/*"
              multiple
              onChange={handleImgChange}
            />
          </label>
        </CircleButton>
        <CircleButton
          onClick={() => handleNavigate()}
          icon={{
            type: TypesIcon.FIRST,
            viewBox: '0 0 8.734 22.859',
            width: px(8.734),
            height: px(22.859),
          }}
          className="bg-[#39CA1C]"
          disabled
        />
        <CircleButton
          onClick={() => handleDeletePhoto()}
          disabled={!filesSrc}
          icon={{
            type: TypesIcon.SMALLBASKET,
            viewBox: '0 0 20.5 25.231',
            width: px(20.5),
            height: px(25.231),
          }}
          className="bg-[#39CA1C]"
        />
      </div>
    </div>
  );
};

export default StyledGalleryFilters;
