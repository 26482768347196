/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { blockCompetitionCabinet } from '../../actions/competition';
import { blockEvent } from '../../actions/event';
import { blockGuideCabinet } from '../../actions/guide';
import { CustomAlert, DatePicker, Main } from '../../components';
import { Checkbox } from '../../components/checkbox';
import { StyledInput } from '../../components/styled-input';
import { Switch } from '../../components/switch';
import {
  blockTheAccountPageTimeData,
  getDaysInMonth,
  selectionsDate,
  timeDate,
} from '../../constants/Component';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { competitionSelector } from '../../selectors/competition';
import { eventObjectSelector } from '../../selectors/get-event';
import { guideSelector } from '../../selectors/guide';

export const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export const BlockTheAccountPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const date = new Date();
  const tz = date.getTimezoneOffset();
  const tzString = -tz / 60;
  const tzForRequest = `${tzString > 0 ? '+' : '-'}${tzString < 10 ? '0' : ''}${tzString}:00`;
  const hour = date?.getHours();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const event = useSelector(
    activeType === 'competition'
      ? competitionSelector
      : activeType === 'guide'
        ? guideSelector
        : eventObjectSelector,
  );
  const [value, setValue] = useState();
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(event?.status === 'BLOCKED');
  const [toggle, setToggle] = useState(event?.status === 'BLOCKED');
  const [showAlert, setShowAlert] = useState(true);
  const [id, setId] = useState();
  const [time, setTime] = useState({
    hour: event?.blockStartAt ? `${new Date(event?.blockStartAt)}`.slice(16, 21) : `${hour}:00`,
  });
  const [startDate, setStartDate] = useState({
    day: event?.blockStartAt ? `${new Date(event?.blockStartAt)}`.slice(8, 10) : `${currentDay}`,
    month: event?.blockStartAt
      ? months[`${new Date(event?.blockStartAt)}`.slice(4, 7)]
      : selectionsDate?.month[currentMonth],
    year: event?.blockStartAt ? `${new Date(event?.blockStartAt)}`.slice(13, 15) : `${currentYear}`,
  });

  const [endTime, setEndTime] = useState({
    hour: event?.blockEndsAt ? `${new Date(event?.blockEndsAt)}`.slice(16, 21) : `${hour}:00`,
  });
  const [endDate, setEndDate] = useState({
    day: event?.blockEndsAt ? `${new Date(event?.blockEndsAt)}`.slice(8, 10) : `${currentDay}`,
    month: event?.blockEndsAt
      ? months[`${new Date(event?.blockEndsAt)}`.slice(4, 7)]
      : selectionsDate?.month[currentMonth],
    year: event?.blockEndsAt ? `${new Date(event?.blockEndsAt)}`.slice(13, 15) : `${currentYear}`,
  });

  const changeHandler = e => {
    setValue(e.target.value);
  };

  const openPicker = value => {
    setId(value);
    setShow(true);
  };

  const onClickButtonClose = () => {
    setToggle(!toggle);
  };

  useLayoutEffect(() => {
    setShowAlert(!showAlert);
  }, [toggle]);

  const onClickButtonSubmit = () => {
    if (event?.status === 'BLOCKED') {
      dispatch(
        activeType === 'competition'
          ? blockCompetitionCabinet({
              data: {
                blockStartsAt: date.toISOString(),
                blockEndsAt: date.toISOString(),
                blockMessage: '',
              },
              id: activeId,
            })
          : activeType === 'guide'
            ? blockGuideCabinet({
                data: {
                  blockStartsAt: date.toISOString(),
                  blockEndsAt: date.toISOString(),
                  blockMessage: '',
                },
                id: activeId,
              })
            : blockEvent({
                eventId: activeId,
                blockStartsAt: event?.blockStartAt,
                blockEndsAt: date.toISOString(),
                blockMessage: '',
              }),
      );
      navigate(
        activeType === 'competition'
          ? '/user-competition'
          : activeType === 'guide'
            ? '/user-guide'
            : '/user-object',
      );
    } else {
      const requestData = {
        eventId: activeId,
        blockStartsAt: `${startDate?.year}-${
          selectionsDate?.month?.indexOf(startDate?.month) > 9
            ? selectionsDate?.month?.indexOf(startDate?.month) + 1
            : `0${selectionsDate?.month?.indexOf(startDate?.month) + 1}`
        }-${startDate?.day?.length > 1 ? startDate?.day : `0${startDate?.day}`}T${
          time?.hour.length < 5 ? `0${time?.hour}` : time?.hour
        }:00.000${tzForRequest}`,
        blockEndsAt: `${endDate?.year}-${
          selectionsDate?.month?.indexOf(endDate?.month) > 9
            ? selectionsDate?.month?.indexOf(endDate?.month) + 1
            : `0${selectionsDate?.month?.indexOf(endDate?.month) + 1}`
        }-${endDate?.day?.length > 1 ? endDate?.day : `0${endDate?.day}`}T${
          endTime?.hour.length < 5 ? `0${endTime?.hour}` : endTime?.hour
        }:00.000${tzForRequest}`,
        blockMessage: value,
      };
      dispatch(
        activeType === 'competition'
          ? blockCompetitionCabinet({
              data: requestData,
              id: activeId,
            })
          : activeType === 'guide'
            ? blockGuideCabinet({
                data: requestData,
                id: activeId,
              })
            : blockEvent(requestData),
      );
      navigate('/holiday', {
        state: {
          endDate: `${endDate?.year}-${
            selectionsDate?.month?.indexOf(endDate?.month) > 9
              ? selectionsDate?.month?.indexOf(endDate?.month) + 1
              : `0${selectionsDate?.month?.indexOf(endDate?.month) + 1}`
          }-${endDate?.day?.length > 1 ? endDate?.day : `0${endDate?.day?.length}`}T${
            endTime?.hour
          }:00.000Z`,
          blockMessage: value,
          prevPage: 'block',
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Main title="Заблокировать кабинет" light lineHeight={32}>
      <DatePicker
        singleItem={id === 1 || id === 3}
        title="Календарь"
        setShow={setShow}
        show={show}
        isDate={id === 2 || id === 4}
        data={
          id === 2 || id === 4
            ? {
                ...selectionsDate,
                day: selectionsDate?.day?.slice(
                  0,
                  1 +
                    getDaysInMonth(
                      startDate?.year,
                      selectionsDate?.month?.indexOf(startDate?.month) + 1,
                    ),
                ),
              }
            : timeDate
        }
        setPickerValue={
          id === 1
            ? setTime
            : id === 2
              ? setStartDate
              : id === 3
                ? setEndTime
                : id === 4
                  ? setEndDate
                  : () => {}
        }
        pickerValue={
          id === 1 ? time : id === 2 ? startDate : id === 3 ? endTime : id === 4 ? endDate : ''
        }
        buttonTitle="Применить"
        isLine
      />
      <p
        className="font-normal"
        style={{
          fontSize: px(15),
          lineHeight: px(20),
        }}
      >
        Временно запретить просмотр страницы, и ввод надписи на входе:
      </p>
      <div
        style={{
          paddingTop: px(16),
          paddingBottom: px(17),
        }}
      >
        <StyledInput
          maxLength={60}
          textOpacity={50}
          placeholderOpacity={50}
          title="Ваша надпись на входе"
          value={value && value?.toString()?.charAt(0)?.toUpperCase() + value?.toString()?.slice(1)}
          onChange={e => changeHandler(e)}
          placeholder="Введите текст (не > 60 зн.)"
        />
      </div>

      <div className="flex flex-row items-center ">
        <div
          style={{
            paddingRight: px(17),
            marginBottom: px(10),
          }}
        >
          <p
            className="font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            Заблокировать
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            Указать период
          </p>
        </div>
        <Checkbox onChange={e => setIsChecked(e.target.checked)} checked={isChecked} />
        <div
          style={{
            paddingLeft: px(36),
          }}
        >
          <Switch toggle={toggle} setToggle={setToggle} />
        </div>
      </div>
      {isChecked && (
        <div
          style={{
            gap: px(14),
          }}
          className="flex"
        >
          {blockTheAccountPageTimeData.map(item => (
            <div key={item?.id} className="flex flex-col">
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
                className="font-normal  opacity-50"
              >
                {item?.title}
              </p>

              <div
                style={{
                  gap: px(10),
                }}
                className="flex flex-row"
              >
                {item?.data?.map(el => (
                  <div key={el?.id}>
                    <p
                      style={{
                        fontSize: px(15),
                        lineHeight: px(20),
                        paddingBottom: px(20),
                      }}
                      className="font-normal  opacity-50"
                    >
                      {el?.subTitle}
                    </p>
                    <p
                      aria-hidden
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                      }}
                      className="font-normal text-black"
                      onClick={() => {
                        openPicker(el?.id);
                      }}
                    >
                      {el?.id === 1
                        ? `${time?.hour}`
                        : el?.id === 3
                          ? `${endTime?.hour}`
                          : el?.id === 2
                            ? `${
                                +startDate?.day < 10 && startDate?.day?.length < 2
                                  ? '0' + startDate?.day
                                  : startDate?.day
                              }.${
                                selectionsDate?.month?.indexOf(+startDate?.month) + 1 < 10
                                  ? startDate?.month?.length === 2
                                    ? startDate?.month
                                    : `0${selectionsDate?.month?.indexOf(startDate?.month) + 1}`
                                  : selectionsDate?.month?.indexOf(startDate?.month) + 1
                              }.${
                                String(startDate?.year).length > 2
                                  ? String(startDate?.year)?.slice(2, 4)
                                  : String(startDate?.year)
                              }`
                            : `${
                                +endDate?.day < 10 && endDate?.day?.length < 2
                                  ? '0' + endDate?.day
                                  : endDate?.day
                              }.${
                                selectionsDate?.month?.indexOf(endDate?.month) + 1 < 10
                                  ? endDate?.month?.length === 2
                                    ? endDate?.month
                                    : `0${selectionsDate?.month?.indexOf(endDate?.month) + 1}`
                                  : selectionsDate?.month?.indexOf(endDate?.month) + 1
                              }.${
                                String(endDate?.year).length > 2
                                  ? String(endDate?.year)?.slice(2, 4)
                                  : String(endDate?.year)
                              }`}
                    </p>
                    <hr
                      className="text-black opacity-30"
                      style={{
                        marginTop: px(5),
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {showAlert && (
        <CustomAlert
          onClickButtonSubmit={onClickButtonSubmit}
          onClickButtonClose={onClickButtonClose}
          title={
            event?.status === 'BLOCKED'
              ? 'Разблокировать кабинет??'
              : 'Временно заблокировать кабинет??'
          }
          buttonLeftText="Отмена"
          buttonRightText="Да"
        />
      )}
    </Main>
  );
};
