import React from 'react';
import { objectDescriptionPageTextareaSizes } from '../constants/Component';
import ObjectDescriptionTitles from './object-description-titles';
import ObjectDescriptionTextarea from './object-description-textarea';
import { px } from '../constants/Px';

const RulesObjectDescriptionPage = ({inputsData,setInputsData,update}) => {

  const handleInputChange = (key, value) => {
    setInputsData((prevInputs) => ({
      ...prevInputs,
      [key]: value,
    }));

    update();

  };

  return (
    <div style={{paddingRight:px(15),paddingLeft:px(15)}}>
      <ObjectDescriptionTitles
        top={40}
        text={'Необходимые нормативы\nповедения на вашем объекте'}
        title={'Условия\nи правила'}
        titleLineHeight={32}
      />

      <ObjectDescriptionTextarea
        objectStyles={objectDescriptionPageTextareaSizes[2]}
        value={inputsData.objectRules}
        setValue={handleInputChange}
        objectKey={'objectRules'}

      />
    </div>
  );
};

export default RulesObjectDescriptionPage;