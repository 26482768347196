import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const SEND_CARDS = 'card/sendCards';

const onSuccess = () => async dispatch => {
  dispatch(
    addModal({
      id: 40,
      data: {
        title: 'Список Клубных Карт успешно отправлен на Ваш электронный адрес',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};
const onFail = () => async dispatch => {
  await dispatch(
    addModal({
      id: 41,
      data: {
        title: 'Необходимо добавить электронную почту',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

export const sendCards = createAsyncThunk(
  SEND_CARDS,
  createRequest({
    type: SEND_CARDS,
    loader: data => authApi.sendCards(data),
    onSuccess,
    onFail,
  }),
);
