import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { TypesIcon } from '@/types/TypesIcon';

import { regexEmail, timeDate } from '../constants/Component';
import { px } from '../constants/Px';
import { Dropdown } from './dropdown';
import { Icon } from './Icon';
import { InputWithDataPicker } from './input-with-dataPicker';

export const StyledInput = ({
  isBigMarginBottom,
  title,
  error,
  numberType,
  value,
  onChange,
  placeholder,
  isMarginBottom,
  navigate,
  isSettingsProfilePage,
  textOpacity,
  placeholderOpacity,
  disabled,
  phoneInput,
  bigTitle,
  isBorder,
  bluePlaceholder,
  options,
  country,
  id,
  withDatePicker,
  openPicker,
  timePhone,
  endTimePhone,
  letEmailValidation,
  cursorPointer,
  onKeyDown,
  maxLength,
  titleColor,
  center,
  withoutPaddingLeft,
  readOnly,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const onFocus = () => setIsFocus(true);
  const onBlur = () => setIsFocus(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  return (
    <div
      aria-hidden
      className={`${options && `relative`} flex-column flex min-w-full justify-center ${
        error ? 'border-[2px] border-solid !border-[red]' : ''
      } ${
        isValidEmail
          ? `border-solid  border-black border-opacity-30 ${
              isFocus
                ? 'border-[1px]'
                : center || (value && !isBorder)
                  ? 'border-0'
                  : 'border-[1px]'
            }`
          : 'border-[2px] border-solid border-[red]'
      }
       `}
      style={{
        borderRight: center && 'none',
        borderLeft: center && 'none',
        height: center ? px(43) : px(60),
        padding: isSettingsProfilePage
          ? `${px(17)} ${px(18)} ${px(17)} ${px(18)} `
          : `${px(4)} ${px(18)} ${px(4)} ${withoutPaddingLeft ? px(0) : px(18)} `,
        marginBottom: isBigMarginBottom ? px(23) : isMarginBottom ? px(10) : null,
        paddingBottom: center && 0,
        paddingTop: center && 0,
      }}
      onClick={navigate}
    >
      {!isSettingsProfilePage && !withDatePicker && title ? (
        <p
          className={`font-normal text-[black]  ${
            textOpacity ? `opacity-${textOpacity}` : 'opacity-30'
          }   ${options && 'absolute top-0'}`}
          style={{
            lineHeight: `${px(26)}`,
            fontSize: `${px(20)}`,
            marginRight: bigTitle && px(-18),
            color: titleColor && 'red',
          }}
        >
          {title}
        </p>
      ) : (
        !isSettingsProfilePage &&
        withDatePicker && (
          <div
            className="flex w-full flex-row items-center"
            style={{ gap: px(20), marginRight: bigTitle && withDatePicker && px(-18) }}
          >
            <p
              className="font-normal text-[black]"
              style={{
                lineHeight: `${px(26)}`,
                fontSize: `${px(20)}`,
              }}
            >
              {title}
            </p>
            <InputWithDataPicker
              id={id}
              openPicker={openPicker}
              endTimePhone={endTimePhone}
              timePhone={timePhone}
            />
          </div>
        )
      )}

      {phoneInput ? (
        <InputMask
          mask={
            country
              ? country === 'Беларусь'
                ? '+375 (99) 999-99-99'
                : '+7 (999) 999-99-99'
              : value[1] === '7'
                ? '+7 (999) 999-99-99'
                : '+375 (99) 999-99-99'
          }
          className={`font-normal outline-none focus:outline-none  ${
            placeholderOpacity ? `placeholder:opacity-50` : 'placeholder:opacity-30'
          } min-w-full  placeholder:text-black disabled:text-black disabled:opacity-100`}
          style={{
            lineHeight: `${px(26)}`,
            fontSize: `${px(20)}`,
            height: `${px(26)}`,
          }}
          disabled={!!(navigate || disabled)}
          placeholder={placeholder}
          onChange={e => onChange(e, title)}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      ) : options ? (
        <div className="relative bottom-0 flex flex-row-reverse" style={{}}>
          <div className="absolute top-1/2">
            <Icon type={TypesIcon.DROPDOWN} width={px(12)} height={px(7)} viewBox="0 0 12 7" />
          </div>
          <select
            value={value}
            onChange={e => onChange(e, title)}
            style={{
              lineHeight: `${px(26)}`,
              fontSize: `${px(20)}`,
              height: `${px(50)}`,
              paddingRight: px(28),
              paddingTop: px(26),
              opacity: (value === 'Выбор из списка' || value === '') && 0.5,
              background: 'transparent',
              zIndex: 3,
            }}
            className={` ... min-w-full appearance-none overflow-hidden text-ellipsis font-normal  outline-none focus:outline-none `}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={!!(navigate || disabled)}
          >
            <option value="" selected>
              Выбор из списка
            </option>
            {options?.map(e => (
              <option className=" text-[black]" value={e} key={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
      ) : numberType ? (
        <input
          maxLength={maxLength}
          value={value ? value?.toString?.()?.replaceAll?.(/\D/g, '') : value}
          /* value={value} */
          onKeyDown={onKeyDown ? e => onKeyDown(e) : () => {}}
          onChange={
            letEmailValidation
              ? e => {
                  onChange(e, title);
                  setIsValidEmail(regexEmail.test(e.target.value));
                }
              : e => {
                  onChange(e, title);
                }
          }
          style={{
            lineHeight: `${center ? px(43) : px(26)}`,
            fontSize: `${center ? px(32) : px(20)}`,
            height: `${center ? px(43) : px(26)}`,
            textAlign: `${center && 'center'}`,
          }}
          className={`outline-none ${
            cursorPointer || readOnly ? 'cursor-pointer' : ''
          } font-normal !text-black focus:outline-none  ${
            bluePlaceholder
              ? ''
              : placeholderOpacity
                ? `placeholder:opacity-50`
                : 'placeholder:opacity-30'
          }  ${
            bluePlaceholder ? 'placeholder:text-[#0A84FE]' : 'placeholder:text-black'
          } min-w-full `}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={!!(navigate || disabled)}
          readOnly={readOnly}
        />
      ) : (
        <input
          maxLength={maxLength}
          value={value}
          onKeyDown={onKeyDown ? e => onKeyDown(e) : () => {}}
          onChange={
            letEmailValidation
              ? e => {
                  onChange(e, title);
                  setIsValidEmail(regexEmail.test(e.target.value));
                }
              : e => {
                  onChange(e, title);
                }
          }
          style={{
            lineHeight: `${px(26)}`,
            fontSize: `${px(20)}`,
            height: `${px(26)}`,
          }}
          className={`!text-black outline-none ${
            cursorPointer || readOnly ? 'cursor-pointer' : ''
          } font-normal focus:outline-none  ${
            bluePlaceholder
              ? ''
              : placeholderOpacity
                ? `placeholder:opacity-50`
                : 'placeholder:opacity-30'
          }  ${
            bluePlaceholder ? 'placeholder:text-[#0A84FE]' : 'placeholder:text-black'
          } min-w-full `}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={!!(navigate || disabled)}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default StyledInput;
