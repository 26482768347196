import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';

import { Main } from '../../components';
import { Icon } from '../../components/Icon';
import { ItemWithIcon } from '../../components/item-with-icon';
import { StateContext } from '../../components/state-provider';
import {
  notificationPageBlockTitles as notificationData,
  notificationPageLinks as arrayLinks,
} from '../../constants/Component';
import { px } from '../../constants/Px';

export const Notification = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const navigateNotificationDevices = () => {
    navigate('/notification-devices');
  };

  const { bookingCopyPeriod } = useContext(StateContext);
  const { statisticCopyPeriod } = useContext(StateContext);
  const { suggestionCopyPeriod } = useContext(StateContext);

  const navigateTo = (link, id) => {
    navigate(link, {
      state: {
        data: {
          id,
        },
      },
    });
  };

  return (
    <Main title="Уведомления" light lineHeight={32} navigatePatch="/profile" paddingBottom fixed
    paddingTop={60}>
      <div
        style={{
          marginTop: px(9),
        }}
        className="flex  items-center "
        onClick={navigateNotificationDevices}
      >
        <div
          style={{
            width: px(48),
          }}
        >
          <Icon
            type={arrayLinks[0]?.iconType}
            viewBox={arrayLinks[0]?.viewBox}
            width={arrayLinks[0]?.width}
            height={arrayLinks[0]?.height}
          />
        </div>
        <div
          style={{
            width: px(181),
          }}
          className="flex flex-col "
        >
          <p
            className="font-normal  "
            style={{
              fontSize: px(20),
              lineHeight: px(26),
            }}
          >
            {arrayLinks[0].title}
          </p>
        </div>
      </div>
      {notificationData?.map(element => (
        <>
          <ItemWithIcon title={element.title} marginTop={20} />
          <div
            key={element.id}
            style={{
              gap: px(24),
            }}
            className="flex flex-col "
          >
            {arrayLinks?.map(
              item =>
                item.id !== 1 && (
                  <div
                    key={item.id}
                    className="flex  cursor-pointer items-center"
                    onClick={() => {
                      navigateTo(
                        item.navigatePatch,
                        item.id === 2 ? 'booking' : item.id === 3 ? 'statistic' : 'suppose',
                      );
                    }}
                  >
                    <div
                      style={{
                        width: px(48),
                      }}
                    >
                      <Icon
                        type={item?.iconType}
                        viewBox={item?.viewBox}
                        width={item?.width}
                        height={item?.height}
                      />
                    </div>
                    <div
                      style={{
                        width: px(181),
                      }}
                      className="flex flex-col "
                    >
                      {item.title && (
                        <p
                          className="font-normal "
                          style={{
                            fontSize: px(20),
                            lineHeight: px(26),
                          }}
                        >
                          {item.title}
                        </p>
                      )}
                      {element.id == 1 ? (
                        item.subTitle && (
                          <p
                            className="font-normal"
                            style={{
                              fontSize: px(15),
                              lineHeight: px(20),
                            }}
                          >
                            {item.id === 2
                              ? bookingCopyPeriod.distribution
                              : item.id === 3
                                ? statisticCopyPeriod.distribution
                                : suggestionCopyPeriod.distribution}
                          </p>
                        )
                      ) : (
                        <p
                          className="font-normal"
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                          }}
                        >
                          Сформировать выписку
                        </p>
                      )}
                    </div>
                  </div>
                ),
            )}
          </div>
        </>
      ))}
    </Main>
  );
};
