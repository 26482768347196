import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomAlert, Main } from '../../components';

import { Icon } from '../../components/Icon';
import FaceIdDot from '../../components/face-id-dot';
import { changeCodePageConstant } from '../../constants/Component';
import { px } from '../../constants/Px';

import { TypesIcon } from '../../types/TypesIcon';
import { authCodeUpdate } from '../../actions/auth';

export const AddCodePage = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [confirm, setСonfirm] = useState('');

  const navigate = useNavigate();

  const handleChangeCode = value => {
    if (code?.length < 4) {
      setCode(code + value);
    }
  };

  const handleConfirm = () => {
    setСonfirm(code);
    setCode('');
  };

  const onClickButtonSubmit = async () => {
    if (code === confirm) {
      try {
        dispatch(
          authCodeUpdate({
            authCode: confirm,
          }),
        );

        navigate('/verify-face-id');
      } catch (error) {}
    }

    setСonfirm('');
    setCode('');
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Main navigatePatch={'/profile'}>
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          paddingTop: px(10),
          paddingBottom: px(8),
        }}
        className="text-center font-normal"
      >
        {!!confirm ? 'Повторите код доступа' : 'Придумайте код доступа'}
      </p>
      <div
        className="flex flex-row justify-center"
        style={{
          gap: `${px(19.2)}`,
          paddingTop: `${px(16)}`,
          paddingBottom: `${px(23.5)}`,
        }}
      >
        {Array?.from({ length: 4 }, (_, index) => (
          <FaceIdDot key={index} code={code} index={index} />
        ))}
      </div>
      <div className="flex justify-center">
        <div
          className="grid justify-items-center"
          style={{
            gridTemplateColumns: `${px(24)} ${px(24)} ${px(24)}`,
            gridTemplateRows: `${px(55)} ${px(55)} ${px(55)}`,
            padding: `${px(0)} ${px(41.1)} ${px(0)} ${px(46)}`,
            rowGap: `${px(9)}`,
            columnGap: ` ${px(64)}`,
          }}
        >
          {changeCodePageConstant.map(item =>
            10 > item.id && item.id !== '0' ? (
              <div
                className="flex cursor-pointer flex-row justify-stretch font-normal"
                style={{
                  fontSize: `${px(42)}`,
                  lineHeight: `${px(55)}`,
                  padding: 0,
                }}
                key={item?.id}
                onClick={() => {
                  !item?.isText && !item?.isIcon && handleChangeCode(item?.value);
                }}
              >
                {item.value}
              </div>
            ) : (
              <div key={item?.id} className="flex items-center">
                {item?.isText ? (
                  code?.length === 4 && (
                    <div
                      className="flex-column flex items-center justify-center"
                      style={{
                        height: `${px(55)}`,
                        width: `${px(56)}`,
                      }}
                      onClick={handleConfirm}
                    >
                      {item.value.split(' ').map(item => (
                        <p
                          className="font-normal text-[#0A84FE]"
                          style={{
                            fontSize: `${px(15)}`,
                            lineHeight: `${px(20)}`,
                          }}
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  )
                ) : item?.isIcon ? (
                  <div
                    key={item?.id}
                    style={{
                      paddingRight: px(9),
                    }}
                  >
                    <Icon
                      type={TypesIcon.DELETECODESMALL}
                      viewBox="0 0 34.876 23.251"
                      width={px(34.876)}
                      height={px(23.251)}
                      className="cursor-pointer"
                      onClick={() => {
                        setCode(code.slice(0, -1));
                      }}
                    />
                  </div>
                ) : (
                  <div
                    key={item?.id}
                    style={{
                      fontSize: `${px(42)}`,
                      lineHeight: `${px(55)}`,
                      fontWeight: 400,
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      item?.id === '0' && handleChangeCode(item?.value);
                    }}
                  >
                    {item.value}
                  </div>
                )}
              </div>
            ),
          )}
        </div>
      </div>

      {code.length === 4 && !!confirm && (
        <CustomAlert
          onClickButtonSubmit={onClickButtonSubmit}
          single
          title={
            confirm === code ? `Код доступа изменен` : 'Код доступа  и потверждение не совпадают'
          }
          buttonRightText={'Ок'}
        />
      )}
    </Main>
  );
};
