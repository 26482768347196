import { request } from '../../helpers/api/request';

export const cardHolderMailSend = async () => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `card-holder/mail/send`,

    method: 'GET',
  });

  return response;
};
