import { createReducer } from '@reduxjs/toolkit';
import { getFavourites } from '../../actions/favourites';



export const favourites = createReducer({}, (builder) => {
  builder.addCase(getFavourites.fulfilled, (state, { payload }) => ({
    ...state,
    favourites: payload?.data,
  }));
  builder.addCase(getFavourites.rejected, (state, { payload }) => ({
    ...state,
    favourites: [],
  }));
});