/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompetitions, transferCompetition } from '../../actions/competition/thunks';
import { getProfileEvent, transferEvent } from '../../actions/event';
import { getProfile, getProfileById } from '../../actions/profile';
import { Button, CustomAlert, DatePicker, Main } from '../../components';
import { Checkbox } from '../../components/checkbox';
import { Icon } from '../../components/Icon';
import { StyledInput } from '../../components/styled-input';
import { Switch } from '../../components/switch';
import {
  getDaysInMonth,
  selectionsDate,
  timeDate,
  transferTheAccountPageTimeData,
} from '../../constants/Component';
import { addLeadingZeros, toISO8601 } from '../../constants/Function';
import { px } from '../../constants/Px';
import { authActiveIdSelector } from '../../selectors/auth';
import { competitionsSelector } from '../../selectors/competition';
import { eventsSelector } from '../../selectors/event';
import { guidesSelector } from '../../selectors/guide';
import { profileByIdSelector, profileSelector } from '../../selectors/profile';
import { TypesIcon } from '../../types/TypesIcon';
import { transferGuide } from '../../actions/guide';

export const TransferPage = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState();
  const [confirm, setConfirm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const profile = useSelector(profileSelector);
  const profileById = useSelector(profileByIdSelector);
  const profileEvent = useSelector(eventsSelector);
  const competitions = useSelector(competitionsSelector);
  const guides = useSelector(guidesSelector);
  const [isValid, setIsValid] = useState(true);
  const [eventId, setEventId] = useState();

  const date = new Date();
  const hour = date?.getHours();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  const [time, setTime] = useState({
    hour: `${hour}:00` || '1',
  });
  const [startDate, setStartDate] = useState({
    day: `${currentDay}` || '1',
    month: selectionsDate?.month[currentMonth] || 'Январь',
    year: `${currentYear}` || '2023',
  });

  function select(e) {
    [...profileEvent, ...competitions, ...guides]?.map(elem => {
      if (e === elem?.id) setSelectedOption(elem?.name);
    });
    setIsOpen(false);
  }

  function mainSelect() {
    if (
      (!profileEvent || !profileEvent[0]) &&
      (!competitions || !competitions[0]) &&
      (!guides || !guides[0])
    )
      return;
    if (isOpen) {
      setSelectedOption([...profileEvent, ...competitions, ...guides][0]?.name);
    }
    setIsOpen(!isOpen);
  }

  const openPicker = value => {
    setId(value);
    setShow(true);
  };

  const changeHandler = async e => {
    setValue(e.target.value);
    const rezult = await dispatch(getProfileById({ id: e.target.value }));
    setIsValid(rezult?.payload?.status === 200);
  };

  const handleSubmit = () => {
    if (profileEvent.map(item => item.name).includes(selectedOption))
      dispatch(
        transferEvent({ toId: +value, eventId, transferStartsAt: toISO8601(startDate, time) }),
      );
    if (competitions.map(item => item.name).includes(selectedOption))
      dispatch(
        transferCompetition({
          toId: +value,
          competitionCabinetId: eventId,
          transferStartsAt: toISO8601(startDate, time),
        }),
      );
    if (guides.map(item => item.name).includes(selectedOption))
      dispatch(
        transferGuide({
          toId: +value,
          guideId: eventId,
          transferStartsAt: toISO8601(startDate, time),
        }),
      );
    setConfirm(false);
    setSubmit(true);
  };

  const handleConfirm = value => {
    dispatch(getProfileById({ id: value }));
    setConfirm(true);
  };

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getProfileEvent({ id: profile?.id }));
      // dispatch(getCompetitions());
    }
  }, [profile?.id]);

  return (
    <Main
      title={`Перевод\nкабинета`}
      light
      lineHeight={32}
      marginTop={px(10)}
      titleClassName="pt-[7px] pr-[30px] pb-[4px]"
    >
      <DatePicker
        singleItem={id === 1}
        title="Календарь"
        setShow={setShow}
        show={show}
        isDate={id === 2}
        data={
          id === 2
            ? {
                ...selectionsDate,
                day: selectionsDate?.day?.slice(
                  0,
                  1 +
                    getDaysInMonth(
                      startDate?.year,
                      selectionsDate?.month?.indexOf(startDate?.month) + 1,
                    ),
                ),
              }
            : timeDate
        }
        setPickerValue={id === 1 ? setTime : setStartDate}
        pickerValue={id === 1 ? time : startDate}
        buttonTitle="Применить"
        isLine
      />
      <p
        className="font-normal"
        style={{
          width: px(290),
          fontSize: px(15),
          lineHeight: px(20),
          paddingRight: px(0),
        }}
      >
        Смена управляющего вашего кабинета с сохранением данных:
      </p>

      <div
        style={{
          paddingTop: px(18),
        }}
      >
        <div
          aria-hidden
          className="flex flex-row items-center justify-between border-[1px] border-solid border-black border-opacity-30"
          onClick={mainSelect}
          style={{
            width: px(290),
            height: isOpen ? px(70) : px(60),
            paddingLeft: px(18),
            paddingRight: px(18),
          }}
        >
          <p
            className="... w-full overflow-hidden text-ellipsis whitespace-nowrap font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingRight: px(4),
            }}
          >
            {isOpen && (profileEvent || competitions || guides)
              ? [...profileEvent, ...competitions, ...guides][0]?.name
              : selectedOption || 'Выбрать кабинет'}
          </p>
          {!isOpen && (
            <Icon width={px(8)} height={px(6)} viewBox="0 0 8 6" type={TypesIcon.ARROWDOWN} />
          )}
        </div>
      </div>
      {isOpen &&
        [...profileEvent, ...competitions, ...guides]?.map(
          item =>
            item?.id !== 1 && (
              <div key={item?.name}>
                <div
                  aria-hidden
                  className=" flex flex-row items-center justify-start border-[1px] border-t-[0px] border-solid border-black border-opacity-30"
                  onClick={() => {
                    select(item?.id);
                    setEventId(item?.id);
                  }}
                  style={{
                    height: px(70),
                    paddingLeft: px(18),
                    paddingRight: px(18),
                  }}
                >
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="... w-full overflow-hidden text-ellipsis whitespace-nowrap font-normal"
                  >
                    {item?.name}
                  </p>
                </div>
              </div>
            ),
        )}

      {selectedOption && !isOpen && (
        <div
          style={{
            width: px(290),
            marginTop: px(17),
          }}
        >
          <div className=" flex items-center">
            <div
              style={{
                marginRight: px(51),
              }}
            >
              <p
                className=" font-normal text-[black]"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
              >
                Перевод
              </p>
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
                className=" w-full font-normal text-[black]"
              >
                Указать период
              </p>
            </div>
            <div
              style={{
                marginRight: px(36),
              }}
            >
              <Checkbox onChange={e => setIsChecked(e.target.checked)} checked={isChecked} />
            </div>
            <div>
              <Switch toggle={toggle} setToggle={setToggle} />
            </div>
          </div>

          {isChecked && (
            <div>
              <div
                style={{
                  marginBottom: px(20),
                }}
              >
                <div className="flex flex-col">
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                    }}
                    className="font-normal  opacity-50"
                  >
                    {transferTheAccountPageTimeData?.title}
                  </p>

                  <div
                    style={{
                      gap: px(10),
                    }}
                    className="flex flex-row"
                  >
                    {transferTheAccountPageTimeData?.data?.map(el => (
                      <div key={el?.id}>
                        <p
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            paddingBottom: px(20),
                          }}
                          className="font-normal  opacity-50"
                        >
                          {el?.subTitle}
                        </p>
                        <p
                          aria-hidden
                          style={{
                            fontSize: px(20),
                            lineHeight: px(26),
                          }}
                          className="font-normal text-black"
                          onClick={() => {
                            openPicker(el?.id);
                          }}
                        >
                          {el?.id === 1
                            ? `${time?.hour}`
                            : `${+startDate?.day < 10 ? `0${startDate?.day}` : startDate?.day}.${
                                selectionsDate?.month?.indexOf(startDate?.month) + 1 < 10
                                  ? `0${selectionsDate?.month?.indexOf(startDate?.month) + 1}`
                                  : selectionsDate?.month?.indexOf(startDate?.month) + 1
                              }.${String(startDate?.year).slice(2, 4)}`}
                        </p>
                        <hr
                          className="text-black opacity-30"
                          style={{
                            marginTop: px(5),
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {toggle && (
                <div>
                  <p
                    className="font-light text-[black]"
                    style={{
                      fontSize: px(32),
                      lineHeight: px(32),
                    }}
                  >
                    Переход
                  </p>
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      marginBottom: px(20),
                    }}
                    className="font-normal text-[black]"
                  >
                    Введите номер ID кабинета нового управляющего. После подтверждения будет
                    произведен переход Кабинета в установленное вами время. Все права и условия на
                    управление перейдут новому владельцу
                  </p>
                  <div
                    className="flex flex-row"
                    style={{
                      paddingBottom: px(20),
                      gap: px(10),
                    }}
                  >
                    {profile?.avatar?.url ? (
                      <img
                        className="rounded-[50%]"
                        src={`${process.env.REACT_APP_STATIC_URL}${profile?.avatar?.url}`}
                        alt="user"
                        style={{
                          height: px(60),
                          width: px(60),
                        }}
                      />
                    ) : (
                      <div
                        style={{ height: px(60), width: px(60) }}
                        className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
                      >
                        <p
                          className="font-light text-black"
                          style={{ fontSize: px(40), lineHeight: px(43) }}
                        >
                          {profile?.name?.charAt(0)?.toUpperCase() || ''}
                        </p>
                      </div>
                    )}
                    <div>
                      <p
                        className="... overflow-hidden text-ellipsis whitespace-nowrap font-normal"
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          width: px(220),
                        }}
                      >
                        {profile?.name}
                      </p>
                      <p
                        className="font-normal"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                        }}
                      >
                        ID: {addLeadingZeros(profile?.id)}
                      </p>
                      <p
                        className="font-normal"
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                        }}
                      >
                        Управляющий
                      </p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className=" font-normal text-[black]"
                  >
                    Новый управляющий
                  </p>
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      marginBottom: px(20),
                    }}
                    className=" font-normal  text-[black]"
                  >
                    Кабинет нового управляющего должен быть заполнен, активен и подтвержден
                    модератором сайта
                  </p>
                  <div
                    style={{
                      marginBottom: px(10),
                    }}
                  >
                    <StyledInput
                      numberType
                      onChange={e => changeHandler(e)}
                      value={value}
                      placeholder="Введите ID кабинета ..."
                      textOpacity={50}
                      placeholderOpacity={50}
                      isSettingsProfilePage
                      error={!isValid}
                    />
                  </div>
                  <Button
                    disabled={!isValid || !value}
                    height={60}
                    borderRadius={0}
                    className="w-full"
                    onClick={() => handleConfirm(value)}
                  >
                    Отправить на заверение
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {confirm && (
        <CustomAlert
          onClickButtonSubmit={handleSubmit}
          onClickButtonClose={() => setConfirm(false)}
          /* title={'Временно заблокировать кабинет??'} */
          buttonLeftText="Отмена"
          buttonRightText="Да"
        >
          <div
            className="self-center font-[400]"
            style={{
              padding: `${px(21)} ${px(19)} ${px(19)} ${px(20)}`,
            }}
          >
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                marginBottom: px(10),
              }}
              className=" text-center font-normal "
            >
              Подтверждение
            </p>
            <div
              className="flex flex-row"
              style={{
                gap: px(10),
              }}
            >
              {profileById?.avatar?.url ? (
                <img
                  className="rounded-[50%]"
                  src={`${process.env.REACT_APP_STATIC_URL}${profileById?.avatar?.url}`}
                  alt="user"
                  style={{
                    height: px(60),
                    width: px(60),
                  }}
                />
              ) : (
                <div
                  style={{ height: px(60), width: px(60) }}
                  className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
                >
                  <p
                    className="font-light text-black"
                    style={{ fontSize: px(40), lineHeight: px(43) }}
                  >
                    {profileById?.name?.charAt(0)?.toUpperCase() || ''}
                  </p>
                </div>
              )}

              <div>
                <p
                  className="... overflow-hidden text-ellipsis whitespace-nowrap font-normal"
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    width: px(180),
                  }}
                >
                  {profileById?.name}
                </p>
                <p
                  className="font-normal"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                >
                  ID: {addLeadingZeros(profileById?.id)}
                </p>
              </div>
            </div>
          </div>
        </CustomAlert>
      )}
      {submit && (
        <CustomAlert
          onClickButtonSubmit={() => setSubmit(false)}
          /* onClickButtonClose={() => setSubmit(false)} */
          title="Смена управляющего произошла успешно"
          single
          buttonRightText="Ок"
        />
      )}
    </Main>
  );
};
