import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { search } from '../actions/search';
import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { searchSelector } from '../selectors/search';
import { TypesIcon } from '../types/TypesIcon';
import { ResultsList } from './results-list';
import { SearchInput } from './search-input';
import { SearchMenu } from './search-menu';
import { StateContext } from './state-provider';

export const Search = ({ searchMenuOpen, setSearchMenuOpen, setShowSearch }) => {
  const { selectedPreferences, resultsListOpen, setResultsListOpen } = useContext(StateContext);
  const [searchInputOpen, setSearchInputOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [resultsListItems, setResultsListItems] = useState([]);
  const dispatch = useDispatch();
  const searchedData = useSelector(searchSelector);
  const clearSearchInput = () => {
    setSearchValue('');
  };
  useEffect(() => {
    dispatch(search(searchValue));
  }, [searchValue]);

  return (
    <>
      <div className="search">
        <SearchInput
          searchInputOpen={searchInputOpen}
          searchInputResult={searchedData}
          setSearchInputOpen={setSearchInputOpen}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          clearSearchInput={clearSearchInput}
          setShowSearch={setShowSearch}
        />

        <SearchMenu
          selectedPreferences={selectedPreferences}
          setResultsListItems={setResultsListItems}
          resultsListItems={resultsListItems}
          setResultsListOpen={setResultsListOpen}
          searchMenuOpen={searchMenuOpen}
          setSearchMenuOpen={setSearchMenuOpen}
        />
      </div>

      {resultsListOpen && (
        <ResultsList
          resultsListItems={resultsListItems}
          setResultsListOpen={setResultsListOpen}
          searchInputOpen={searchInputOpen}
          searchInputResult={searchedData}
          setSearchInputOpen={setSearchInputOpen}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
    </>
  );
};

export default Search;
