/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/section-price';
import { createRequest } from '@/helpers/api/createRequest';

export const UPDATE_PRICE = 'price/updatePrice';

export const updatePrice = createAsyncThunk(
  UPDATE_PRICE,
  createRequest({
    type: UPDATE_PRICE,
    loader: data => authApi.updatePrice(data),
  }),
);
