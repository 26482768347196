import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section-promotion';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_PROMOTION_GROUP = 'promotion/createPromotionGroup';

export const createPromotionGroup = createAsyncThunk(
  CREATE_PROMOTION_GROUP,
  createRequest({
    type: CREATE_PROMOTION_GROUP,
    loader: data => authApi.createPromotionGroup(data),
  }),
);
