import React from 'react';

import { Checkbox } from '../../../../../../components/checkbox';
import { px } from '../../../../../utils';

export const FieldView = ({
  option,
  data: { label, value, number },
  pickerValue,
  checkBox = true,
  fontLabel,
}) => (
  <div className="search-menu-filter-block-item disabled">
    <h5
      className="text-[#ffffff]"
      style={{
        fontSize: px(fontLabel),
      }}
    >
      {label}
    </h5>
    {!checkBox && value ? (
      (
        <p
          className="whitespace-nowrap font-normal text-[#ffffff]"
          style={{ fontSize: px(20), lineHeight: px(26) }}
        >
          {value}
        </p>
      ) || ''
    ) : checkBox ? (
      <Checkbox
        className={`search-menu-filter-block-checkbox ${checkBox === 'green' && 'green'}`}
        checked
      />
    ) : option?.number ? (
      <p>{option?.number}</p>
    ) : (
      ''
    )}
    {number && (
      <p className="absolute font-[400] text-white" style={{ right: px(65) }}>
        {number}
      </p>
    )}
    {pickerValue && label !== 'Номер' ? (
      <p
        className=" absolute z-[2] font-normal text-[#ffffff]"
        style={{ right: px(63), fontSize: px(20), lineHeight: px(26) }}
      >
        {pickerValue}
      </p>
    ) : (
      <p
        className=" absolute z-[2] font-normal text-[#ffffff]"
        style={{
          left: '44%',
          minWidth: px(49),
          display: 'flex',
          justifyContent: 'center',
          fontSize: px(20),
          lineHeight: px(26),
        }}
      >
        {pickerValue}
      </p>
    )}
  </div>
);

export default FieldView;
