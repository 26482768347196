export const INITIAL_GUIDE_FIELDS = {
  name: '',
  description: '',
  createdById: '',
  attachments: [],
  gallery: [],
  schemas: [],
  contact: {
    coordinates: '',
    country: '',
    region: '',
    district: '',
    town: '',
    street: '',
    email: '',
    mainPhone: '',
    mainPhoneStartTime: 'Время.',
    mainPhoneEndTime: 'Период',
    secondaryPhone: '',
    secondaryPhoneStartTime: 'Время.',
    secondaryPhoneEndTime: 'Период',
    websiteLink: '',
    secondaryWebsiteLink: '',
    socialPageLink: '',
    telegramLink: '',
    viberLink: '',
    whatsAppLink: '',
    id: '',
  },
};
