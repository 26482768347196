import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';

import {
  monthObject,
  selectionsDate,
  statisticsExtractsPage,
  userCardMonthDate,
} from '../Component';
import { headers } from '../Component/modal-price';

const optionsFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const dayInMilliseconds = 1000 * 60 * 60 * 24;

export const getFormatDate = (dateString, isHours, feedback) => {
  if (dateString) {
    const date = new Date(dateString);

    const day = date?.getDate()?.toString()?.padStart(2, '0');
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, '0');
    const year = date?.getFullYear()?.toString();
    const hours = date?.getHours()?.toString()?.padStart(2, '0');
    const minutes = date?.getMinutes()?.toString()?.padStart(2, '0');
    if (!isHours && !feedback) {
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
    if (feedback) {
      const isMonth = userCardMonthDate[month - 1];

      return `${day} ${isMonth} ${hours}:${minutes}`;
    }
    return `${day}.${month}.${year}`;
  }
};

export const getFormatDateHours = dateString => {
  const date = new Date(dateString);

  const hours = date?.getHours()?.toString()?.padStart(2, '0');
  const minutes = date?.getMinutes()?.toString()?.padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const getFormatDateDayMonthYear = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${day}.${month}.${year}`;
};

export const getMinutesString = minutes => {
  const lastDigit = minutes % 10;
  if (lastDigit === 1 && minutes !== 11) {
    return 'минуту';
  }
  if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(minutes)) {
    return 'минуты';
  }
  return 'минут';
};

export const saveEmptyFile = fileType => {
  switch (fileType) {
    case 'EXCEL': {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'empty.xlsx');

      break;
    }
    case 'PDF': {
      const document_ = new jsPDF();
      document_.save('empty.pdf');
      break;
    }
    case 'TXT (1C)': {
      const text = '';
      const filename = 'empty.txt';
      const blob = new Blob([text], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();

      break;
    }
    // No default
  }
};

export const formatDate = dateObject => {
  const day =
    dateObject.day < 10 && dateObject.day.length < 2 ? `0${dateObject.day}` : dateObject.day;
  const month = selectionsDate.month.indexOf(dateObject.month) + 1;
  const monthString = month < 10 ? `0${month}` : month;
  const year = String(dateObject.year).slice(2, 4);

  return `${day}.${monthString}.${year}`;
};

export const formatTime = value => {
  const day = value < 10 ? `0${value}` : value;

  return `${day}`;
};

export const getPeriod = (id, time, endTime, startDate, endDate) => {
  const period =
    id === 1 || id === 3
      ? formatTime(id === 1 ? time?.hour : endTime?.hour)
      : formatDate(id === 2 ? startDate : endDate);

  return `${period}`;
};

export const getMonth = date => {
  const month = selectionsDate?.month ? selectionsDate.month.indexOf(date) + 1 : undefined;

  return `${month}`;
};

export const toISO8601 = (data, hour) => {
  const date = new Date(
    // eslint-disable-next-line no-unsafe-optional-chaining
    `${data?.year}/${monthObject[data?.month]}/${data?.day} ${hour?.hour}`,
  );
  return date.toISOString();
};

export const addLeadingZeros = num => (num?.length < 4 ? `0000${num?.toString()}`?.slice(-4) : num);

export function createObjectDram(keys, ...arrays) {
  return keys?.reduce((obj, key, i) => {
    obj[key] = arrays[i];
    return obj;
  }, {});
}

export function sumThreeStrings(str1, str2, str3) {
  let sum = 0;

  if (!Number.isNaN(str1)) {
    sum += Number(str1);
  }

  if (!Number.isNaN(str2)) {
    sum += Number(str2);
  }

  if (!Number.isNaN(str3)) {
    sum += Number(str3);
  }

  return sum;
}

export function getHeader(type, priceDiscountAction) {
  return headers
    ?.find(el => el?.type === type)
    ?.titles?.find(item => item?.priceDiscountAction === priceDiscountAction);
}

const months = {
  Январь: '01',
  Февраль: '02',
  Март: '03',
  Апрель: '04',
  Май: '05',
  Июнь: '06',
  Июль: '07',
  Август: '08',
  Сентябрь: '09',
  Октябрь: '10',
  Ноябрь: '11',
  Декабрь: '12',
};

export function formatDateForCard(month, year) {
  const formattedMonth = months?.[month];

  const formattedYear = year?.toString()?.slice(-2);
  return `${formattedMonth}/${formattedYear}`;
}

export function formatDateForCardGet(month, year) {
  const formattedMonth = month >= 10 ? month : `0${month}`;

  const formattedYear = year?.toString()?.slice(-2);
  return `${formattedMonth}/${formattedYear}`;
}
export const formatMonth = string => {
  const formattedMonth = months?.[string];

  return `${formattedMonth}`;
};

export const formatYear = str => {
  if (str?.length === 2) {
    return `20${str}`;
  }
  if (str?.length === 4) {
    return str.slice(2);
  }
  return str;
};

export const removePercent = string => string.replace('%', '');

export function addYearsToDate(years) {
  const currentDate = new Date();
  let newDate;

  switch (years) {
    case '2 года': {
      newDate = new Date(
        currentDate.getFullYear() + 2,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      break;
    }
    case '1 год': {
      newDate = new Date(
        currentDate.getFullYear() + 1,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      break;
    }
    case '5 лет': {
      newDate = new Date(
        currentDate.getFullYear() + 5,
        currentDate.getMonth(),
        currentDate.getDate(),
      );
      break;
    }
    case 'Точная дата': {
      newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      break;
    }
    case 'Бессрочно': {
      return '00/00';
    }
    default: {
      return 'Точная дата';
    }
  }

  return `${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate
    .getFullYear()
    .toString()
    .slice(-2)}`;
}

export function standartDate(dateString) {
  const shortMonths = [
    'ЯНВ',
    'ФЕВ',
    'МАР',
    'АПР',
    'МАЙ',
    'ИЮН',
    'ИЮЛ',
    'АВГ',
    'СЕН',
    'ОКТ',
    'НОЯ',
    'ДЕК',
  ];

  const date = new Date(dateString);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const day = date.getUTCDate();
  const month = shortMonths[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${day} ${month} ${year}`;
}

export const formattedExpirationDate = openUntillDate => {
  if (openUntillDate) {
    return new Date(openUntillDate).toLocaleDateString('en-US', {
      year: '2-digit',
      month: '2-digit',
    });
  }
  return '00/00';
};

// eslint-disable-next-line consistent-return
export const datePlusOneDay = date => new Date(date.getTime() + dayInMilliseconds);

export const getFormatedDate = (date, option = optionsFormat, format = 'en-GB') =>
  date.toLocaleDateString(format, option)?.replace(/\//g, '.');

export const convertDateFromObject = options => {
  const { day, month, year } = options;
  const date = new Date();
  if (day && month && year) {
    date.setDate(Number(day));
    date.setMonth(Number(selectionsDate.month.indexOf(month)));
    date.setFullYear(Number(year));
  }

  return date;
};

export const getPeriodString = (dateStart, dateEnd, suggestionCopyPeriod) => {
  if (suggestionCopyPeriod !== 'Выбрать период') {
    return statisticsExtractsPage[suggestionCopyPeriod];
  }
  const startTimeDate = convertDateFromObject(dateStart);
  const startTimeDateString = getFormatedDate(startTimeDate);

  const endTimeData =
    dateEnd?.day === '' ? datePlusOneDay(startTimeDate) : convertDateFromObject(dateEnd);
  const endTimeDateString = getFormatedDate(endTimeData);

  return `${startTimeDateString} ${endTimeDateString}`;
};
