import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getEvent } from '../actions/event';
import { sectorModal } from '../constants/Component/sector-modal';
import { px } from '../constants/Px';
import { CircleButton, ScrollButtonsContainer } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { DropdownList } from './dropdown-list';
import { Filter } from './filter';

export const FishingMenu = ({
  id,
  objectDropdownList,
  index,
  modalType,
  selectedOptionsObjectFilters,
  setSelectedOptionsObjectFilters,
  selectedObjectFiltersRest,
  setSelectedObjectFiltersRest,
  selectedObjectFiltersService,
  setSelectedObjectFiltersService,
  date,
  setDate,
  formData,
  setSchemaUrl,
  setImgUrl,
  setLocationsNameId,
  imgUrl,
  schemaUrl,
  saveDescription,
  setSaveDescription,
  setActiveIndex,
  similarSector,
  setShowSort,
  showFilters,
  setShowFilters,
  imgArr,
  tempImgArr,
  schemaArr,
  tempSchemaArr,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvent({ id: 12 }));
  }, []);
  return (
    <div className=" relative h-full w-full">
      <div
        className="flex-column absolute  top-0 flex items-center justify-center"
        style={{ width: '100%' }}
      >
        <p
          className="font-light"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingTop: px(2),
            paddingBottom: px(12),
          }}
        >
          {modalType === 1 ? 'Рыбалка' : modalType === 2 ? 'Отдых' : 'Услуги'}
        </p>
        <div
          style={{
            paddingRight: px(11),
            paddingLeft: px(12),
            paddingBottom: px(80),
            width: '100%',
          }}
        >
          <div
            style={{
              width: px(290),
            }}
          >
            <DropdownList
              setActiveIndex={setActiveIndex}
              index={index}
              objectDropdownList={objectDropdownList}
            />
          </div>
          {!showFilters && (
            <div
              style={{
                paddingTop: px(20),
                gap: px(10),
                paddingBottom: px(80),
              }}
              className="flex flex-wrap"
            />
          )}

          {showFilters && (
            <Filter
              id={id}
              setSelectedObjectFiltersService={setSelectedObjectFiltersService}
              selectedObjectFiltersService={selectedObjectFiltersService}
              selectedObjectFiltersRest={selectedObjectFiltersRest}
              setSelectedObjectFiltersRest={setSelectedObjectFiltersRest}
              selectedOptionsObjectFilters={selectedOptionsObjectFilters}
              setSelectedOptionsObjectFilters={setSelectedOptionsObjectFilters}
              modalType={modalType}
              date={date}
              setDate={setDate}
              setImgUrl={setImgUrl}
              imgUrl={imgUrl}
              setSchemaUrl={setSchemaUrl}
              schemaUrl={schemaUrl}
              formData={formData}
              saveDescription={saveDescription}
              setSaveDescription={setSaveDescription}
              similarSector={similarSector}
              imgArr={imgArr}
              tempImgArr={tempImgArr}
              schemaArr={schemaArr}
              tempSchemaArr={tempSchemaArr}
              setShowFilters={setShowFilters}
            />
          )}
        </div>
      </div>
      {!showFilters && (
        <ScrollButtonsContainer>
          <CircleButton
            onClick={() => {
              setShowFilters(true);
              setLocationsNameId({
                id: objectDropdownList[0].id,
                title: objectDropdownList[0].title,
              });
            }}
            icon={{
              type: TypesIcon.PLUS,
              viewBox: '0 0 19.785 19.785',
              width: px(19.785),
              height: px(19.785),
              fill: '#0A84FE',
            }}
          />
          {sectorModal.length > 0 && (
            <CircleButton
              onClick={() => {
                setShowSort(true);
              }}
              icon={{
                type: TypesIcon.FILTER,
                viewBox: '0 0 25 24',
                width: px(25),
                height: px(24),
                fill: '#0A84FE',
              }}
              className="text-[#0A84FE]"
            />
          )}
        </ScrollButtonsContainer>
      )}
    </div>
  );
};
export default FishingMenu;
