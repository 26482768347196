import React from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const FeedbackStar = ({
  estimate,
  setEstimate,
  createObjectFeedback,
  objectKey,
  starId,
  selectedStar,
  setSelectedStar,
  objectFeedback,
  feedbackEstimate,
}) => {
  const feedbackRound = categoryName => {
    let sum = 0;
    feedbackEstimate?.map(item => {
      sum += item[categoryName];
    });
    return Math.round(sum / feedbackEstimate?.length);
  };

  const appEstimate = () => {
    if (starId === selectedStar) setSelectedStar(selectedStar => selectedStar - 1);
    else setSelectedStar(starId);
  };

  const objectEstimate = () => {
    if (starId === estimate[objectKey]) {
      setEstimate({ ...estimate, [objectKey]: starId - 1 });
    } else {
      setEstimate({ ...estimate, [objectKey]: starId });
    }
  };
  const starBg = createObjectFeedback
    ? starId <= estimate[objectKey]
      ? 'FEEDBACKSTARFILL'
      : 'FEEDBACKSTARBORDER'
    : starId <= selectedStar
      ? 'FEEDBACKSTARFILL'
      : 'FEEDBACKSTARBORDER';

  const objectFeedbackBgStar =
    objectFeedback &&
    (starId <= feedbackRound(objectKey) ? 'FEEDBACKSTARFILL' : 'FEEDBACKSTARBORDER');

   
  return (
    <div onClick={objectFeedback ? null : createObjectFeedback ? objectEstimate : appEstimate}>
      <Icon
        type={TypesIcon[objectFeedback ? objectFeedbackBgStar : starBg]}
        width={px(31)}
        height={px(31)}
        viewBox="0 0 31 31"
      />
    </div>
  );
};

export default FeedbackStar;
