import { createReducer } from '@reduxjs/toolkit';
import { getContacts } from '../../actions/contacts';
import {updateContacts} from '../../actions/contacts';


export const contacts = createReducer({}, (builder) => {
  builder.addCase(getContacts.fulfilled, (state, { payload }) => ({
    ...state,
    contact: payload?.data,
  }));
});


