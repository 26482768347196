import { createSelector } from '@reduxjs/toolkit';

export const selectRoot = ({ guide }) => guide;
export const guidesSelector = createSelector(selectRoot, guide => guide?.guides);
export const guideSelector = createSelector(selectRoot, guide => guide?.guide);
export const guideLogoSelector = createSelector(selectRoot, guide => guide?.logo);
export const guideGallerySelector = createSelector(selectRoot, guide => guide?.gallery);
export const listOfGuidesSelector = createSelector(selectRoot, guide => guide?.listOfGuides);

export const guideOfferSelector = createSelector(selectRoot, guide => guide?.guideOffer);

/* export const competitionProtocolsSelector = createSelector(
  selectRoot,
  competition => competition?.protocol,
); */
export const guideSchemasSelector = createSelector(selectRoot, guide => guide?.schema);
export const guideNewsSelector = createSelector(selectRoot, guide => guide?.news);
