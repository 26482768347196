/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/allow-group';
import { createRequest } from '@/helpers/api/createRequest';

export const UPDATE_GROUP = 'allow/updateGroup';

export const updateGroup = createAsyncThunk(
  UPDATE_GROUP,
  createRequest({
    type: UPDATE_GROUP,
    loader: data => authApi.updateGroup(data),
  }),
);
