/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/competition';

import { createRequest } from '../../../helpers/api/createRequest';
/* import { getAdmins } from './get-admins'; */

export const BLOCK_COMPETITION_ADMIN = 'competition/blockCompetitionAdmin';

/* const onSuccess = () => async (dispatch) => {
  dispatch(
    getAdmins()
  );
}; */

export const blockCompetitionAdmin = createAsyncThunk(
  BLOCK_COMPETITION_ADMIN,
  createRequest({
    type: BLOCK_COMPETITION_ADMIN,
    loader: data => profileAdminApi.blockCompetitionAdmin(data),
  }),
);
