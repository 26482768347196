import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSectors } from '../../actions/sector/thunks';
import { DatePicker, ModalPrice, SectorModal } from '../../components';
import { Icon } from '../../components/Icon';
import { Modal, useModal } from '../../components/portals';
import { selectionsDate } from '../../constants/Component';
import {
  interestData,
  period,
  periodData,
  periodEndData,
  priceData,
} from '../../constants/Component/date-picker';
import { sectorModal } from '../../constants/Component/sector-modal';
import { createObjectDram } from '../../constants/Function';
import { px } from '../../constants/Px';
import { CircleButton, ScrollButtonsContainer } from '../../new-architecture/shared';
import { sectorsSelector } from '../../selectors/sector';
import { TypesIcon } from '../../types/TypesIcon';

const startPeriod = `Начало периода`;
const endPeriod = `Конец периода`;
const cost = `Стоимость аренды`;

export const SelectionPage = () => {
  const dispatch = useDispatch();
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  // it is data for SectorModal
  const sectors = useSelector(sectorsSelector);
  // modalId = 0
  const { isOpen: price, openModal: openPrice, closeModal: closePrice } = useModal();
  // modalId = 1
  const { isOpen: dram, closeModal: closeDram, setIsOpen: setDram } = useModal();

  const [pickerId, setPickerId] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState('day');
  const [type, setType] = useState('fishing');
  const [priceDiscountAction, setPriceDiscountAction] = useState('price');

  const groups = [
    { id: 1, name: 'A' },
    { id: 2, name: 'B' },
    { id: 3, name: 'C' },
    { id: 4, name: 'D' },
    { id: 5, name: 'E' },
    { id: 6, name: 'F' },
  ];

  const [selectedGroupId, setSelectedGroupId] = useState();

  const handleOpenGroup = id => {
    setSelectedGroupId(id);
  };

  const handleCloseGroup = () => {
    setSelectedGroupId(null);
  };

  const [pickerDate, setPickerDate] = useState({
    timeStart: '07:00',
    timeEnd: '09:00',
    fishingDiscountTimeStart: '07:00',
    fishingDiscountTimeEnd: '09:00',
    fishingActionTimeStart: '07:00',
    fishingActionTimeEnd: '09:00',
    fishingActionFree: '1%',
    fishingActionNoLimit: '2%',
    fishingActionLimit: '3%',
    fishingActionAssistant: '4%',
    fishingActionAshore: '5%',

    fishingDiscountPeriodStartFirstNumber: `${currentDay}`,
    fishingDiscountPeriodStartSecondNumber: selectionsDate?.month[currentMonth],
    fishingDiscountPeriodStartThirdNumber: `${currentYear}`,

    fishingDiscountPeriodEndFirstNumber: `${currentDay}`,
    fishingDiscountPeriodEndSecondNumber: selectionsDate?.month[currentMonth],
    fishingDiscountPeriodEndThirdNumber: `${currentYear}`,

    fishingActionPeriodStartFirstNumber: `${currentDay}`,
    fishingActionPeriodStartSecondNumber: selectionsDate?.month[currentMonth],
    fishingActionPeriodStartThirdNumber: `${currentYear}`,

    fishingActionPeriodEndFirstNumber: `${currentDay}`,
    fishingActionPeriodEndSecondNumber: selectionsDate?.month[currentMonth],
    fishingActionPeriodEndThirdNumber: `${currentYear}`,

    restPriceTimeStart: '07:00',
    restPriceTimeEnd: '09:00',
    restDiscountTimeStart: '07:00',
    restDiscountTimeEnd: '09:00',
    restActionTimeStart: '07:00',
    restActionTimeEnd: '09:00',

    restDiscountPeriodStartFirstNumber: `${currentDay}`,
    restDiscountPeriodStartSecondNumber: selectionsDate?.month[currentMonth],
    restDiscountPeriodStartThirdNumber: `${currentYear}`,

    restDiscountPeriodEndFirstNumber: `${currentDay}`,
    restDiscountPeriodEndSecondNumber: selectionsDate?.month[currentMonth],
    restDiscountPeriodEndThirdNumber: `${currentYear}`,

    restActionPeriodStartFirstNumber: `${currentDay}`,
    restActionPeriodStartSecondNumber: selectionsDate?.month[currentMonth],
    restActionPeriodStartThirdNumber: `${currentYear}`,

    restActionPeriodEndFirstNumber: `${currentDay}`,
    restActionPeriodEndSecondNumber: selectionsDate?.month[currentMonth],
    restActionPeriodEndThirdNumber: `${currentYear}`,

    restDiscount: '5%',

    servicesPriceTimeStart: '07:00',
    servicesPriceTimeEnd: '09:00',
    servicesDiscountTimeStart: '07:00',
    servicesDiscountTimeEnd: '09:00',
    servicesActionTimeStart: '07:00',
    servicesActionTimeEnd: '09:00',

    servicesDiscountPeriodStartFirstNumber: `${currentDay}`,
    servicesDiscountPeriodStartSecondNumber: selectionsDate?.month[currentMonth],
    servicesDiscountPeriodStartThirdNumber: `${currentYear}`,

    servicesDiscountPeriodEndFirstNumber: `${currentDay}`,
    servicesDiscountPeriodEndSecondNumber: selectionsDate?.month[currentMonth],
    servicesDiscountPeriodEndThirdNumber: `${currentYear}`,

    servicesActionPeriodStartFirstNumber: `${currentDay}`,
    servicesActionPeriodStartSecondNumber: selectionsDate?.month[currentMonth],
    servicesActionPeriodStartThirdNumber: `${currentYear}`,

    servicesActionPeriodEndFirstNumber: `${currentDay}`,
    servicesActionPeriodEndSecondNumber: selectionsDate?.month[currentMonth],
    servicesActionPeriodEndThirdNumber: `${currentYear}`,

    servicesDiscount: '6%',
    //    три параметра для цены
    freeFirstNumber: '0',
    freeSecondNumber: '0',
    freeThirdNumber: '0',
    discountFree: '10%',
    //    три параметра для цены
    noLimitFirstNumber: '0',
    noLimitSecondNumber: '0',
    noLimitThirdNumber: '0',
    discountNoLimit: '12%',
    //    три параметра для цены
    limitFirstNumber: '0',
    limitSecondNumber: '0',
    limitThirdNumber: '0',
    discountLimit: '13%',
    //    три параметра для цены
    assistantFirstNumber: '0',
    assistantSecondNumber: '0',
    assistantThirdNumber: '0',
    discountAssistant: '14%',
    weekendExtraCharge: '20%',
    //    три параметра для цены
    checkoutPenaltyFirstNumber: '0',
    checkoutPenaltySecondNumber: '0',
    checkoutPenaltyThirdNumber: '0',

    checkoutTimeLimit: '2 часа',
    //    три параметра для цены
    ashoreCostFirstNumber: '0',
    ashoreCostSecondNumber: '0',
    ashoreCostThirdNumber: '0',
    discountAshore: '14%',
    ashoreWeekendExtraCharge: '20%',
    //    три параметра для цены
    ashoreCheckoutPenaltyFirstNumber: '0',
    ashoreCheckoutPenaltySecondNumber: '0',
    ashoreCheckoutPenaltyThirdNumber: '0',
    ashoreСheckoutTimeLimit: '3 часа',

    //    три параметра для цены
    rentPriceFirstNumber: '0',
    rentPriceSecondNumber: '0',
    rentPriceThirdNumber: '0',
    //    три параметра для цены
    depositFirstNumber: '0',
    depositSecondNumber: '0',
    depositThirdNumber: '0',
    //    три параметра для цены
    damageFirstNumber: '0',
    damageSecondNumber: '0',
    damageThirdNumber: '0',
  });

  const drumData = [
    {
      pickerId: 0,
      title: startPeriod,
      data: createObjectDram(['timeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
    },
    {
      pickerId: 1,
      title: endPeriod,
      data: createObjectDram(['timeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
    },
    {
      pickerId: 2,
      title: 'Поймал - отпусти',
      data: createObjectDram(
        ['freeFirstNumber', 'freeSecondNumber', 'freeThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: 'free',
    },
    {
      pickerId: 3,
      title: 'Без нормы вылова',
      data: createObjectDram(
        ['noLimitFirstNumber', 'noLimitSecondNumber', 'noLimitThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: 'noLimit',
    },
    {
      pickerId: 4,
      title: 'С нормой вылова',
      data: createObjectDram(
        ['limitFirstNumber', 'limitSecondNumber', 'limitThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: 'limit',
    },
    {
      pickerId: 5,
      title: 'Помощник (1 чел.)',
      data: createObjectDram(
        ['assistantFirstNumber', 'assistantSecondNumber', 'assistantThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      key: 'assistant',
    },
    {
      pickerId: 6,
      title: 'Наценка на выходные',
      data: createObjectDram(['weekendExtraCharge'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 7,
      title: 'Штраф за поздний выезд (1 час)',
      data: createObjectDram(
        ['checkoutPenaltyFirstNumber', 'checkoutPenaltySecondNumber', 'checkoutPenaltyThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 8,
      title: 'Период',
      data: createObjectDram(['checkoutTimeLimit'], period?.period),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 9,
      title: cost,
      data: createObjectDram(
        ['ashoreCostFirstNumber', 'ashoreCostSecondNumber', 'ashoreCostThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 10,
      title: 'Наценка на выходные',
      data: createObjectDram(['ashoreWeekendExtraCharge'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 11,
      title: 'Штраф за поздний выезд (1 час)',
      data: createObjectDram(
        [
          'ashoreCheckoutPenaltyFirstNumber',
          'ashoreCheckoutPenaltySecondNumber',
          'ashoreCheckoutPenaltyThirdNumber',
        ],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 12,
      title: 'Период',
      data: createObjectDram(['ashoreСheckoutTimeLimit'], period?.period),
      pickerValue: pickerDate,
      key: '',
    },

    {
      pickerId: 13,
      title: 'Стоимость аренды',
      data: createObjectDram(
        ['rentPriceFirstNumber', 'rentPriceSecondNumber', 'rentPriceThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 14,
      title: 'Стоимость залога',
      data: createObjectDram(
        ['depositFirstNumber', 'depositSecondNumber', 'depositThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 15,
      title: 'Штраф за порчу',
      data: createObjectDram(
        ['damageFirstNumber', 'damageSecondNumber', 'damageThirdNumber'],
        priceData?.freeFirstNumber,
        priceData?.freeSecondNumber,
        priceData?.freeThirdNumber,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 16,
      title: 'Поймал - отпусти',
      data: createObjectDram(['discountFree'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 17,
      title: 'Без нормы вылова',
      data: createObjectDram(['discountNoLimit'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 18,
      title: 'С нормой вылова',
      data: createObjectDram(['discountLimit'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 19,
      title: 'Помощник (1 чел.)',
      data: createObjectDram(['discountAssistant'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 20,
      title: 'Стоимость аренды',
      data: createObjectDram(['discountAshore'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 21,
      title: startPeriod,
      data: createObjectDram(['fishingDiscountTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 22,
      title: endPeriod,
      data: createObjectDram(['fishingDiscountTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 23,
      title: startPeriod,
      data: createObjectDram(['fishingActionTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 24,
      title: endPeriod,
      data: createObjectDram(['fishingActionTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 25,
      title: startPeriod,
      data: createObjectDram(['restPriceTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 26,
      title: endPeriod,
      data: createObjectDram(['restPriceTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 27,
      title: startPeriod,
      data: createObjectDram(['restDiscountTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 28,
      title: endPeriod,
      data: createObjectDram(['restDiscountTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 29,
      title: startPeriod,
      data: createObjectDram(['restActionTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 30,
      title: endPeriod,
      data: createObjectDram(['restActionTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 31,
      title: startPeriod,
      data: createObjectDram(['servicesPriceTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 32,
      title: endPeriod,
      data: createObjectDram(['servicesPriceTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 33,
      title: startPeriod,
      data: createObjectDram(['servicesDiscountTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 34,
      title: endPeriod,
      data: createObjectDram(['servicesDiscountTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 35,
      title: startPeriod,
      data: createObjectDram(['servicesActionTimeStart'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 36,
      title: endPeriod,
      data: createObjectDram(['servicesActionTimeEnd'], periodEndData?.periodEnd),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 37,
      title: 'Дата',
      data: createObjectDram(
        [
          'fishingDiscountPeriodStartFirstNumber',
          'fishingDiscountPeriodStartSecondNumber',
          'fishingDiscountPeriodStartThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 38,
      title: 'Дата',
      data: createObjectDram(
        [
          'fishingDiscountPeriodEndFirstNumber',
          'fishingDiscountPeriodEndSecondNumber',
          'fishingDiscountPeriodEndThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 39,
      title: 'Дата',
      data: createObjectDram(
        [
          'fishingActionPeriodStartFirstNumber',
          'fishingActionPeriodStartSecondNumber',
          'fishingActionPeriodStartThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 40,
      title: 'Дата',
      data: createObjectDram(
        [
          'fishingActionPeriodEndFirstNumber',
          'fishingActionPeriodEndSecondNumber',
          'fishingActionPeriodEndThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 41,
      title: 'Дата',
      data: createObjectDram(
        [
          'restDiscountPeriodStartFirstNumber',
          'restDiscountPeriodStartSecondNumber',
          'restDiscountPeriodStartThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 42,
      title: 'Дата',
      data: createObjectDram(
        [
          'restDiscountPeriodEndFirstNumber',
          'restDiscountPeriodEndSecondNumber',
          'restDiscountPeriodEndThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 43,
      title: 'Дата',
      data: createObjectDram(
        [
          'restActionPeriodStartFirstNumber',
          'restActionPeriodStartSecondNumber',
          'restActionPeriodStartThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 44,
      title: 'Дата',
      data: createObjectDram(
        [
          'restActionPeriodEndFirstNumber',
          'restActionPeriodEndSecondNumber',
          'restActionPeriodEndThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 45,
      title: 'Дата',
      data: createObjectDram(
        [
          'servicesDiscountPeriodStartFirstNumber',
          'servicesDiscountPeriodStartSecondNumber',
          'servicesDiscountPeriodStartThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 46,
      title: 'Дата',
      data: createObjectDram(
        [
          'servicesDiscountPeriodEndFirstNumber',
          'servicesDiscountPeriodEndSecondNumber',
          'servicesDiscountPeriodEndThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 47,
      title: 'Дата',
      data: createObjectDram(
        [
          'servicesActionPeriodStartFirstNumber',
          'servicesActionPeriodStartSecondNumber',
          'servicesActionPeriodStartThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 48,
      title: 'Дата',
      data: createObjectDram(
        [
          'servicesActionPeriodEndFirstNumber',
          'servicesActionPeriodEndSecondNumber',
          'servicesActionPeriodEndThirdNumber',
        ],
        periodData?.day,
        periodData?.month,
        periodData?.year,
      ),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 49,
      title: 'Стоимость аренды',
      data: createObjectDram(['restDiscount'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 50,
      title: 'Стоимость аренды',
      data: createObjectDram(['servicesDiscount'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 51,
      title: 'Поймал - отпусти',
      data: createObjectDram(['fishingActionFree'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 52,
      title: 'Без нормы вылова',
      data: createObjectDram(['fishingActionNoLimit'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 53,
      title: 'С нормой вылова',
      data: createObjectDram(['fishingActionLimit'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 54,
      title: 'Помощник (1 чел.)',
      data: createObjectDram(['fishingActionAssistant'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
    {
      pickerId: 55,
      title: 'Помощник (1 чел.)',
      data: createObjectDram(['fishingActionAshore'], interestData?.interest),
      pickerValue: pickerDate,
      key: '',
    },
  ];

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onchangeValue = value => {
    switch (pickerId) {
      case 0: {
        setPickerDate({
          ...pickerDate,
          timeStart: value?.timeStart,
        });

        break;
      }
      case 1: {
        setPickerDate({
          ...pickerDate,
          timeEnd: value?.timeEnd,
        });

        break;
      }
      case 2: {
        setPickerDate({
          ...pickerDate,
          freeFirstNumber: value?.freeFirstNumber,
          freeSecondNumber: value?.freeSecondNumber,
          freeThirdNumber: value?.freeThirdNumber,
        });

        break;
      }
      case 3: {
        setPickerDate({
          ...pickerDate,
          noLimitFirstNumber: value?.noLimitFirstNumber,
          noLimitSecondNumber: value?.noLimitSecondNumber,
          noLimitThirdNumber: value?.noLimitThirdNumber,
        });

        break;
      }
      case 4: {
        setPickerDate({
          ...pickerDate,
          limitFirstNumber: value?.limitFirstNumber,
          limitSecondNumber: value?.limitSecondNumber,
          limitThirdNumber: value?.limitThirdNumber,
        });

        break;
      }
      case 5: {
        setPickerDate({
          ...pickerDate,
          assistantFirstNumber: value?.assistantFirstNumber,
          assistantSecondNumber: value?.assistantSecondNumber,
          assistantThirdNumber: value?.assistantThirdNumber,
        });

        break;
      }
      case 6: {
        setPickerDate({
          ...pickerDate,
          weekendExtraCharge: value?.weekendExtraCharge,
        });

        break;
      }
      case 7: {
        setPickerDate({
          ...pickerDate,
          checkoutPenaltyFirstNumber: value?.checkoutPenaltyFirstNumber,
          checkoutPenaltySecondNumber: value?.checkoutPenaltySecondNumber,
          checkoutPenaltyThirdNumber: value?.checkoutPenaltyThirdNumber,
        });

        break;
      }
      case 8: {
        setPickerDate({
          ...pickerDate,
          checkoutTimeLimit: value?.checkoutTimeLimit,
        });

        break;
      }
      case 9: {
        setPickerDate({
          ...pickerDate,
          ashoreCostFirstNumber: value?.ashoreCostFirstNumber,
          ashoreCostSecondNumber: value?.ashoreCostSecondNumber,
          ashoreCostThirdNumber: value?.ashoreCostThirdNumber,
        });

        break;
      }
      case 10: {
        setPickerDate({
          ...pickerDate,
          ashoreWeekendExtraCharge: value?.ashoreWeekendExtraCharge,
        });

        break;
      }
      case 11: {
        setPickerDate({
          ...pickerDate,
          ashoreCheckoutPenaltyFirstNumber: value?.ashoreCheckoutPenaltyFirstNumber,
          ashoreCheckoutPenaltySecondNumber: value?.ashoreCheckoutPenaltySecondNumber,
          ashoreCheckoutPenaltyThirdNumber: value?.ashoreCheckoutPenaltyThirdNumber,
        });

        break;
      }
      case 12: {
        setPickerDate({
          ...pickerDate,
          ashoreСheckoutTimeLimit: value?.ashoreСheckoutTimeLimit,
        });

        break;
      }
      case 13: {
        setPickerDate({
          ...pickerDate,
          rentPriceFirstNumber: value?.rentPriceFirstNumber,
          rentPriceSecondNumber: value?.rentPriceSecondNumber,
          rentPriceThirdNumber: value?.rentPriceThirdNumber,
        });

        break;
      }
      case 14: {
        setPickerDate({
          ...pickerDate,
          depositFirstNumber: value?.depositFirstNumber,
          depositSecondNumber: value?.depositSecondNumber,
          depositThirdNumber: value?.depositThirdNumber,
        });

        break;
      }
      case 15: {
        setPickerDate({
          ...pickerDate,
          damageFirstNumber: value?.damageFirstNumber,
          damageSecondNumber: value?.damageSecondNumber,
          damageThirdNumber: value?.damageThirdNumber,
        });

        break;
      }
      case 16: {
        setPickerDate({
          ...pickerDate,
          discountFree: value?.discountFree,
        });

        break;
      }
      case 17: {
        setPickerDate({
          ...pickerDate,
          discountNoLimit: value?.discountNoLimit,
        });

        break;
      }
      case 18: {
        setPickerDate({
          ...pickerDate,
          discountLimit: value?.discountLimit,
        });

        break;
      }
      case 19: {
        setPickerDate({
          ...pickerDate,
          discountAssistant: value?.discountAssistant,
        });

        break;
      }
      case 20: {
        setPickerDate({
          ...pickerDate,
          discountAshore: value?.discountAshore,
        });

        break;
      }
      case 21: {
        setPickerDate({
          ...pickerDate,
          fishingDiscountTimeStart: value?.fishingDiscountTimeStart,
        });

        break;
      }
      case 22: {
        setPickerDate({
          ...pickerDate,
          fishingDiscountTimeEnd: value?.fishingDiscountTimeEnd,
        });

        break;
      }
      case 23: {
        setPickerDate({
          ...pickerDate,
          fishingActionTimeStart: value?.fishingActionTimeStart,
        });

        break;
      }
      case 24: {
        setPickerDate({
          ...pickerDate,
          fishingActionTimeEnd: value?.fishingActionTimeEnd,
        });

        break;
      }
      case 25: {
        setPickerDate({
          ...pickerDate,
          restPriceTimeStart: value?.restPriceTimeStart,
        });

        break;
      }
      case 26: {
        setPickerDate({
          ...pickerDate,
          restPriceTimeEnd: value?.restPriceTimeEnd,
        });

        break;
      }
      case 27: {
        setPickerDate({
          ...pickerDate,
          restDiscountTimeStart: value?.restDiscountTimeStart,
        });

        break;
      }
      case 28: {
        setPickerDate({
          ...pickerDate,
          restDiscountTimeEnd: value?.restDiscountTimeEnd,
        });

        break;
      }
      case 29: {
        setPickerDate({
          ...pickerDate,
          restActionTimeStart: value?.restActionTimeStart,
        });

        break;
      }
      case 30: {
        setPickerDate({
          ...pickerDate,
          restActionTimeEnd: value?.restActionTimeEnd,
        });

        break;
      }
      case 31: {
        setPickerDate({
          ...pickerDate,
          servicesPriceTimeStart: value?.servicesPriceTimeStart,
        });

        break;
      }
      case 32: {
        setPickerDate({
          ...pickerDate,
          servicesPriceTimeEnd: value?.servicesPriceTimeEnd,
        });

        break;
      }
      case 33: {
        setPickerDate({
          ...pickerDate,
          servicesDiscountTimeStart: value?.servicesDiscountTimeStart,
        });

        break;
      }
      case 34: {
        setPickerDate({
          ...pickerDate,
          servicesDiscountTimeEnd: value?.servicesDiscountTimeEnd,
        });

        break;
      }
      case 35: {
        setPickerDate({
          ...pickerDate,
          servicesActionTimeStart: value?.servicesActionTimeStart,
        });

        break;
      }
      case 36: {
        setPickerDate({
          ...pickerDate,
          servicesActionTimeEnd: value?.servicesActionTimeEnd,
        });

        break;
      }
      case 37: {
        setPickerDate({
          ...pickerDate,
          fishingDiscountPeriodStartFirstNumber: value?.fishingDiscountPeriodStartFirstNumber,
          fishingDiscountPeriodStartSecondNumber: value?.fishingDiscountPeriodStartSecondNumber,
          fishingDiscountPeriodStartThirdNumber: value?.fishingDiscountPeriodStartThirdNumber,
        });

        break;
      }
      case 38: {
        setPickerDate({
          ...pickerDate,
          fishingDiscountPeriodEndFirstNumber: value?.fishingDiscountPeriodEndFirstNumber,
          fishingDiscountPeriodEndSecondNumber: value?.fishingDiscountPeriodEndSecondNumber,
          fishingDiscountPeriodEndThirdNumber: value?.fishingDiscountPeriodEndThirdNumber,
        });

        break;
      }
      case 39: {
        setPickerDate({
          ...pickerDate,
          fishingActionPeriodStartFirstNumber: value?.fishingActionPeriodStartFirstNumber,
          fishingActionPeriodStartSecondNumber: value?.fishingActionPeriodStartSecondNumber,
          fishingActionPeriodStartThirdNumber: value?.fishingActionPeriodStartThirdNumber,
        });

        break;
      }
      case 40: {
        setPickerDate({
          ...pickerDate,
          fishingActionPeriodEndFirstNumber: value?.fishingActionPeriodEndFirstNumber,
          fishingActionPeriodEndSecondNumber: value?.fishingActionPeriodEndSecondNumber,
          fishingActionPeriodEndThirdNumber: value?.fishingActionPeriodEndThirdNumber,
        });

        break;
      }
      case 41: {
        setPickerDate({
          ...pickerDate,
          restDiscountPeriodStartFirstNumber: value?.restDiscountPeriodStartFirstNumber,
          restDiscountPeriodStartSecondNumber: value?.restDiscountPeriodStartSecondNumber,
          restDiscountPeriodStartThirdNumber: value?.restDiscountPeriodStartThirdNumber,
        });

        break;
      }
      case 42: {
        setPickerDate({
          ...pickerDate,
          restDiscountPeriodEndFirstNumber: value?.restDiscountPeriodEndFirstNumber,
          restDiscountPeriodEndSecondNumber: value?.restDiscountPeriodEndSecondNumber,
          restDiscountPeriodEndThirdNumber: value?.restDiscountPeriodEndThirdNumber,
        });

        break;
      }
      case 43: {
        setPickerDate({
          ...pickerDate,
          restActionPeriodStartFirstNumber: value?.restActionPeriodStartFirstNumber,
          restActionPeriodStartSecondNumber: value?.restActionPeriodStartSecondNumber,
          restActionPeriodStartThirdNumber: value?.restActionPeriodStartThirdNumber,
        });

        break;
      }
      case 44: {
        setPickerDate({
          ...pickerDate,
          restActionPeriodEndFirstNumber: value?.restActionPeriodEndFirstNumber,
          restActionPeriodEndSecondNumber: value?.restActionPeriodEndSecondNumber,
          restActionPeriodEndThirdNumber: value?.restActionPeriodEndThirdNumber,
        });

        break;
      }
      case 45: {
        setPickerDate({
          ...pickerDate,
          servicesDiscountPeriodStartFirstNumber: value?.servicesDiscountPeriodStartFirstNumber,
          servicesDiscountPeriodStartSecondNumber: value?.servicesDiscountPeriodStartSecondNumber,
          servicesDiscountPeriodStartThirdNumber: value?.servicesDiscountPeriodStartThirdNumber,
        });

        break;
      }
      case 46: {
        setPickerDate({
          ...pickerDate,
          servicesDiscountPeriodEndFirstNumber: value?.servicesDiscountPeriodEndFirstNumber,
          servicesDiscountPeriodEndSecondNumber: value?.servicesDiscountPeriodEndSecondNumber,
          servicesDiscountPeriodEndThirdNumber: value?.servicesDiscountPeriodEndThirdNumber,
        });

        break;
      }
      case 47: {
        setPickerDate({
          ...pickerDate,
          servicesActionPeriodStartFirstNumber: value?.servicesActionPeriodStartFirstNumber,
          servicesActionPeriodStartSecondNumber: value?.servicesActionPeriodStartSecondNumber,
          servicesActionPeriodStartThirdNumber: value?.servicesActionPeriodStartThirdNumber,
        });

        break;
      }
      case 48: {
        setPickerDate({
          ...pickerDate,
          servicesActionPeriodEndFirstNumber: value?.servicesActionPeriodEndFirstNumber,
          servicesActionPeriodEndSecondNumber: value?.servicesActionPeriodEndSecondNumber,
          servicesActionPeriodEndThirdNumber: value?.servicesActionPeriodEndThirdNumber,
        });

        break;
      }
      case 49: {
        setPickerDate({
          ...pickerDate,
          restDiscount: value?.restDiscount,
        });

        break;
      }
      case 50: {
        setPickerDate({
          ...pickerDate,
          servicesDiscount: value?.servicesDiscount,
        });

        break;
      }
      case 51: {
        setPickerDate({
          ...pickerDate,
          fishingActionFree: value?.fishingActionFree,
        });

        break;
      }
      case 52: {
        setPickerDate({
          ...pickerDate,
          fishingActionNoLimit: value?.fishingActionNoLimit,
        });

        break;
      }
      case 53: {
        setPickerDate({
          ...pickerDate,
          fishingActionLimit: value?.fishingActionLimit,
        });

        break;
      }
      // No default
    }

    // fishingActionFree: '1%',
    // fishingActionNoLimit: '2%',
    // fishingActionLimit: '3%',
    // fishingActionAssistant: '4%',
    // fishingActionAshore: '5%',
  };

  const handleOptionChange = event => setSelectedOption(event.target.value);
  const handleTypeChange = event => setType(event.target.value);
  const handlePriceDiscountActionChange = value => setPriceDiscountAction(value);

  const handleComponentClick = id => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const openPicker = value => {
    setPickerId(value);
    setDram(true);
  };

  const handleSelectedAll = () => {
    const allId = sectorModal?.map(item => item.id);
    setSelectedItems(allId);
  };

  useEffect(() => {
    dispatch(getSectors({ id: 1 }));
  }, []);

  return (
    <div>
      <DatePicker
        isLine
        modalId={1}
        singleItem
        title={drumData?.[pickerId]?.title}
        show={dram}
        setShow={setDram}
        data={drumData?.[pickerId]?.data}
        setPickerValue={onchangeValue}
        pickerValue={pickerDate}
        buttonTitle="Применить"
      />

      <Modal title="Цена. Скидка. Акции" key={0} isOpen={price} onClose={closePrice}>
        <ModalPrice
          groups={groups}
          handleOpenGroup={handleOpenGroup}
          handleCloseGroup={handleCloseGroup}
          selectedGroupId={selectedGroupId}
          modalId={0}
          dropdownTitle="Пруд бобровый"
          type={type}
          handleTypeChange={handleTypeChange}
          priceDiscountAction={priceDiscountAction}
          handlePriceDiscountActionChange={handlePriceDiscountActionChange}
          buttonTitle="Создать новую группу"
          pickerDate={pickerDate}
          openPicker={openPicker}
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          drumData={drumData}
        />
      </Modal>

      <div>
        <SectorModal
          subTitle="Выбор"
          alternativeTitle="Рыбалка"
          dropdownTitle="Выберите сектор"
          selectedItems={selectedItems}
          data={sectorModal}
          handleComponentClick={handleComponentClick}
          buttonTitle="Применить"
          onClick={() => {
            openPrice();
          }}
        />
        <ScrollButtonsContainer>
          <CircleButton
            onClick={handleSelectedAll}
            icon={{
              type: TypesIcon.GROUP,
              viewBox: '0 0 30.783 30.783',
              width: px(30.783),
              height: px(30.783),
            }}
          />
          {selectedItems?.length ? (
            <CircleButton
              icon={{
                type: TypesIcon.REMOVEALPHABET,
                viewBox: '0 0 26 41',
                width: px(26),
                height: px(41),
              }}
            />
          ) : (
            <CircleButton
              icon={{
                type: TypesIcon.ALPHABET,
                viewBox: '0 0 8.939 40.957',
                width: px(8.939),
                height: px(40.957),
              }}
            />
          )}
        </ScrollButtonsContainer>
      </div>
    </div>
  );
};
