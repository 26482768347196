import './globals.css';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './app';

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(<App />);
