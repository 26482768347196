import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_CARD_HOLDER_CARDS = 'card-holder/getCardHolderCards';

export const getCardHolderCards = createAsyncThunk(
  GET_CARD_HOLDER_CARDS,
  createRequest({
    type: GET_CARD_HOLDER_CARDS,
    loader: data => authApi.getCardHolderCards(data),
  }),
);
