/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-unused-expressions */
/* eslint-disable unicorn/no-null */
/* eslint-disable import/no-unresolved */
import 'swiper/scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { authActiveIdSelector } from '../selectors/auth';
import { emptyIndexObjectSelector } from '../selectors/get-event';
import { TypesIcon } from '../types/TypesIcon';
import { HeroSearch } from './hero-search';
import { StateContext } from './state-provider';
import { StyledVideo } from './styled-video';

export const Slider = ({
  images,
  setShowSearch,
  sliderInRegistrationCard,
  sliderInObjectCard,
  index,
  map,
  setIndex,
  setStyledComponent,
  gallery,
  setAddSlide,
  openModal,
  prevModalOpen,
}) => {
  const { state } = useLocation();
  useEffect(() => {
    console.log('state', state);
  }, [state]);
  const navigate = useNavigate();
  const id = useSelector(authActiveIdSelector);

  const [selectedSlideIndex, setSelectedSlideIndex] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [screenWidthHorizontal, setScreenWidthHorizontal] = useState(false);
  const [screenWidthVertical, setScreenWidthVertical] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(0);
  const { showSearch, show, handleClose, handleShow } = useContext(StateContext);
  const sliderReference = useRef(null);
  const emptyIndex = useSelector(emptyIndexObjectSelector);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);

    const checkScreenConditions = () => {
      if (screenWidth < screenHeight) {
        setScreenWidthVertical(true);
        setScreenWidthHorizontal(false);
      } else if (screenHeight < screenWidth) {
        setScreenWidthHorizontal(true);
        setScreenWidthVertical(false);
      }
    };
    checkScreenConditions();

    const handleOutsideClick = e => {
      if (
        sliderReference.current &&
        !sliderReference.current.contains(e.target) &&
        isVideoPlaying
      ) {
        setIsVideoPlaying(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [screenWidth, screenHeight, isVideoPlaying]);

  const openPopup = inx => {
    setSelectedSlideIndex(inx);
    navigate('/slider', { state: { images, isOpen: prevModalOpen } });
  };

  return (
    <Swiper
      spaceBetween={0}
      modules={[Navigation, Pagination]}
      pagination={{ clickable: true }}
      navigation={
        screenWidthHorizontal
          ? {
              prevEl: '.popup-arrow-prev',
              nextEl: '.popup-arrow-next',
            }
          : {
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }
      }
      className={`home_page-hero   ${
        (screenWidthVertical || screenWidthHorizontal) && 'horizontal'
      }`}
      initialSlide={index}
      ref={sliderReference}
      onRealIndexChange={element => {
        setActiveIndex(element.activeIndex);
        if (index !== null && index !== undefined) setIndex(element.activeIndex);
        else setActiveIndex(element.activeIndex);
      }}
    >
      {images?.map((img, index_) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index_}>
          {img.type === 'image' ? (
            <img
              src={img.src}
              className="slider__image"
              alt={`slider-${index_}`}
              onClick={() => openPopup(index_)}
              aria-hidden
            />
          ) : img.type === 'youtube' ? (
            <div className="swiper-youtube">
              <StyledVideo src={img.src} />
            </div>
          ) : img.type === 'blueBg' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290) }}
            >
              <CircleButton
                onClick={() => {
                  handleShow();
                  if (openModal) openModal();
                  gallery
                    ? setStyledComponent('Gallery')
                    : map
                      ? setStyledComponent('Map')
                      : setStyledComponent('Attachment');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#ffffff',
                  stroke: '#0a84fe',
                }}
              />
              {emptyIndex !== -1 && (
                <CircleButton
                  onClick={() => {
                    /* setIndex(emptyIndex); */
                    handleShow();
                    if (openModal) openModal();
                    setAddSlide(true);
                    gallery
                      ? setStyledComponent('Gallery')
                      : map
                        ? setStyledComponent('Map')
                        : setStyledComponent('Attachment');
                  }}
                  icon={{
                    type: TypesIcon.ADDPLUS,
                    viewBox: '0 0 26.95 26.95',
                    width: px(26.95),
                    height: px(26.95),
                    fill: '#0a84fe',
                  }}
                />
              )}
            </div>
          ) : img.type === 'LOGO' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  setIndex(0);
                  handleShow();
                  setStyledComponent('Attachment');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              {emptyIndex !== -1 && (
                <CircleButton
                  onClick={() => {
                    setIndex(emptyIndex);
                    handleShow();
                    setStyledComponent('Attachment');
                  }}
                  icon={{
                    type: TypesIcon.ADDPLUS,
                    viewBox: '0 0 26.95 26.95',
                    width: px(26.95),
                    height: px(26.95),
                    fill: '#0a84fe',
                  }}
                />
              )}
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(130),
                  height: px(130),
                  borderRadius: px(15),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : img.type === 'POSTCARD' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  setIndex(1);
                  handleShow();
                  setStyledComponent('Attachment');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              {emptyIndex !== -1 && (
                <CircleButton
                  onClick={() => {
                    setIndex(emptyIndex);
                    handleShow();
                    setStyledComponent('Attachment');
                  }}
                  icon={{
                    type: TypesIcon.ADDPLUS,
                    viewBox: '0 0 26.95 26.95',
                    width: px(26.95),
                    height: px(26.95),
                    fill: '#0a84fe',
                  }}
                />
              )}
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(141),
                  height: px(110),
                  borderRadius: px(0),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : img.type === 'BANNER' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  setIndex(2);
                  handleShow();
                  setStyledComponent('Attachment');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              {emptyIndex !== -1 && (
                <CircleButton
                  onClick={() => {
                    setIndex(emptyIndex);
                    handleShow();
                    setStyledComponent('Attachment');
                  }}
                  icon={{
                    type: TypesIcon.ADDPLUS,
                    viewBox: '0 0 26.95 26.95',
                    width: px(26.95),
                    height: px(26.95),
                    fill: '#0a84fe',
                  }}
                />
              )}
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(320),
                  height: px(183),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : img.type === 'GAME' || img.type === 'SERVICE' || img.type === 'RECREATION' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  setIndex(img.type === 'GAME' ? 3 : img.type === 'SERVICE' ? 5 : 4);
                  handleShow();
                  setStyledComponent('Attachment');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              {emptyIndex !== -1 && (
                <CircleButton
                  onClick={() => {
                    setIndex(emptyIndex);
                    handleShow();
                    setStyledComponent('Attachment');
                  }}
                  icon={{
                    type: TypesIcon.ADDPLUS,
                    viewBox: '0 0 26.95 26.95',
                    width: px(26.95),
                    height: px(26.95),
                    fill: '#0a84fe',
                  }}
                />
              )}
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(141),
                  height: px(110),
                  borderTopLeftRadius: px(15),
                  borderTopRightRadius: px(15),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : img.type === 'GALLERY' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  setAddSlide(false);
                  handleShow();
                  openModal();
                  setStyledComponent('Gallery');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              <CircleButton
                onClick={() => {
                  setAddSlide(true);
                  setIndex(gallery ? null : emptyIndex);
                  handleShow();
                  if (openModal) openModal();
                  setStyledComponent('Gallery');
                }}
                icon={{
                  type: TypesIcon.ADDPLUS,
                  viewBox: '0 0 26.95 26.95',
                  width: px(26.95),
                  height: px(26.95),
                  fill: '#0a84fe',
                }}
              />
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(320),
                  height: px(290),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : img.type === 'MAP' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  setAddSlide(false);
                  handleShow();
                  if (openModal) openModal();
                  setStyledComponent('Map');
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              <CircleButton
                onClick={() => {
                  setAddSlide(true);
                  setIndex(map ? null : emptyIndex);
                  handleShow();
                  openModal();
                  setStyledComponent('Map');
                }}
                icon={{
                  type: TypesIcon.ADDPLUS,
                  viewBox: '0 0 26.95 26.95',
                  width: px(26.95),
                  height: px(26.95),
                  fill: '#0a84fe',
                }}
              />
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(320),
                  height: px(290),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : img.type === 'SCHEMA' ? (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={() => {
                  navigate(`/object-description/${id}/shema/new`);
                }}
                icon={{
                  type: TypesIcon.ADDPLUS,
                  viewBox: '0 0 26.95 26.95',
                  width: px(26.95),
                  height: px(26.95),
                  fill: '#0a84fe',
                }}
              />
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(141),
                  height: px(216),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          ) : (
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290), width: '100%' }}
            >
              <CircleButton
                onClick={handleShow}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0a84fe',
                }}
              />
              {emptyIndex !== -1 && (
                <CircleButton
                  onClick={() => {
                    setIndex(emptyIndex);
                    handleShow();
                  }}
                  icon={{
                    type: TypesIcon.ADDPLUS,
                    viewBox: '0 0 26.95 26.95',
                    width: px(26.95),
                    height: px(26.95),
                    fill: '#0a84fe',
                  }}
                />
              )}
              <img
                src={img.src}
                className="slider__image"
                style={{
                  width: px(130),
                  height: px(130),
                  borderRadius: px(15),
                }}
                alt={`slider-${index_}`}
              />
            </div>
          )}
        </SwiperSlide>
      ))}

      <>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
        {(index !== null &&
          index !== undefined &&
          !sliderInRegistrationCard &&
          !sliderInObjectCard &&
          index === 0) ||
        (!sliderInRegistrationCard && !sliderInObjectCard && activeIndex === 0 && setShowSearch) ? (
          <HeroSearch setShowSearch={setShowSearch} />
        ) : null}
      </>
    </Swiper>
  );
};

export default Slider;
