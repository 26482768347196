export const colors = {

    background_60: '#ACACAC99',
    black: '#000000',
    blue: '#0A84FE',
    gray_10: '#DDDDDD',
   

    red: '#FF0A0A',
    redError: '#D14545',
    red_10: '#D6230019',
    redDark: '#A71B00',

    brown: '#502314',
    brown_8: '#50231414',
    brown_10: '#5023141A',
    brown_20: '#50231433',
    brown_50: '#50231480',

    orange: '#FF8732',
    orange_10: '#E5792D',
    orange_50: '#FF873280',

    yellow: '#FFAA00',
    yellowBrown: '#D89000',
    yellowDark: '#CC8800',

    green: '#198737',
    secondary_green: '#1B8737',
    green_50: '#1B87377F',

    eggWhite: '#F5EBDC',
    eggWhite_10: '#F5EBDCFF',
    eggWhite_100: '#F5EBDC00',
    eggWhite_76: '#F5EBDCC1',

    white: '#FFFFFF',
    white_100: '#FAF5EE',
    white_50: '#FFFFFF80',
    white_59: '#FFFFFF96',

    blackSienna: '#2B0200',


    coffee: '#845F50',

    beaver: '#9F816F',
    beaver_50: '#9F816F7F',
    beaver_60: '#9F816F99',
    beaver_75: '#9F816FBF',

    almond: '#D7C7B5',
    almond_50: '#D7C7B580',
    almond_60: '#D7C7B599',

    gray: '#C3C2BE',
    gray2: '#E0E0E0',
    gray3: '#828282',
    gray_20: '#FFFFFF33',
    gray_50: '#FFFFFF7F',
    gray74: '#BDBDBD',
    gray73: '#BABABA',
    grayLight: '#EDECEA',
    darkGray: '#383838',
    gray_30: '#76767633',
    gray_100: '#767676',

    rose: '#EDB9A5',
};
