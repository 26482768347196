import React, { useContext, useEffect } from 'react';

import { px } from '../constants/Px';
import { Checkbox } from './checkbox';
import { StateContext } from './state-provider';

export const SearchMenuFilterBlockItem = ({
  option,
  isSelected,
  toggleOption,
  setShow,
  date,
  setDataPickerId,
}) => (
  <div
    aria-hidden
    className={`search-menu-filter-block-item ${isSelected ? 'selected' : ''}`}
    onClick={
      option?.withoutCheckbox
        ? undefined
        : () => {
            toggleOption(option);
          }
    }
  >
    <h5
      style={{
        color: option?.withoutCheckbox && 'black',
      }}
    >
      {option?.label}
    </h5>
    {!option?.withoutCheckbox && (
      <Checkbox className="search-menu-filter-block-checkbox" checked={isSelected} />
    )}
    {option?.withNumber && option?.value === 'free' && (
      <p
        aria-hidden
        onClick={() => {
          setDataPickerId(0);
          setShow(true);
        }}
        className={`pointer-events-auto absolute z-[2] font-normal ${
          date?.freeZone === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
        }`}
        style={{ right: px(79), fontSize: px(20), lineHeight: px(26) }}
      >
        {date?.freeZone}
      </p>
    )}
    {option?.withoutCheckbox && option?.withNumber && option?.value === 'capacity' && (
      <p
        aria-hidden
        onClick={() => {
          setDataPickerId(1);
          setShow(true);
        }}
        className={`pointer-events-auto absolute z-[2] font-normal ${
          date?.capacity === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
        }`}
        style={{ right: px(79), fontSize: px(20), lineHeight: px(26) }}
      >
        {date?.capacity}
      </p>
    )}
    {option?.withNumber && option?.value === 'bed' && (
      <p
        aria-hidden
        onClick={() => {
          setDataPickerId(2);
          setShow(true);
        }}
        className={`pointer-events-auto absolute z-[2] font-normal ${
          date?.sleepingPlace === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
        }`}
        style={{ right: px(79), fontSize: px(20), lineHeight: px(26) }}
      >
        {date?.sleepingPlace}
      </p>
    )}
    {option?.withoutCheckbox && option?.withNumber && option?.value === 'capacityObject' && (
      <p
        aria-hidden
        onClick={() => {
          setDataPickerId(4);
          setShow(true);
        }}
        className={`pointer-events-auto absolute z-[2] font-normal ${
          date?.capacityObject === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
        }`}
        style={{ right: px(79), fontSize: px(20), lineHeight: px(26) }}
      >
        {date?.capacityObject}
      </p>
    )}
    {option?.withNumber && option?.value === 'hotel' && (
      <p
        onClick={() => {
          setDataPickerId(3);
          setShow(true);
        }}
        className={`pointer-events-auto absolute z-[2] font-normal ${
          date?.hotel === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
        }`}
        style={{ right: px(79), fontSize: px(20), lineHeight: px(26) }}
      >
        {date?.hotel}
      </p>
    )}
    {option?.withNumber && option?.value === 'bedObject' && (
      <p
        onClick={() => {
          setDataPickerId(5);
          setShow(true);
        }}
        className={`pointer-events-auto absolute z-[2] font-normal ${
          date?.bedObject === '0' ? 'text-[#FA0303]' : 'text-[#0A84FE]'
        }`}
        style={{ right: px(79), fontSize: px(20), lineHeight: px(26) }}
      >
        {date?.bedObject}
      </p>
    )}
  </div>
);
