import React from 'react';

import { px } from '../constants/Px';
import { Button } from './button';

export const CustomAlert = ({
  title,
  children,
  onClickButtonSubmit,
  onClickButtonClose,
  buttonLeftText,
  buttonRightText,
  single,
  height,
  paddingLeftRight,
}) => (
  <div
    className={`fixed bottom-0 left-0 right-0 top-0 z-[102] flex items-center justify-center `}
    onClick={onClickButtonClose}
    onKeyDown={onClickButtonClose}
    style={{
      background: 'rgba(0, 0, 0, 0.7)',
    }}
  >
    <div
      className="grid grid-rows-[3fr_1fr] bg-[white] "
      style={{
        width: px(290),
        height: height || px(184),
      }}
      onClick={e => e.stopPropagation()}
      onKeyDown={e => e.stopPropagation()}
    >
      {title && (
        <p
          className="self-center text-center font-[400]"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            padding: `${px(17)} ${paddingLeftRight || px(30)} ${px(17)} ${
              paddingLeftRight || px(30)
            }`,
          }}
        >
          {title}
        </p>
      )}
      {children}
      <div className="flex flex-row">
        {buttonLeftText && (
          <Button
            bgColor="#FFFFFF"
            color="#000"
            onClick={onClickButtonClose}
            style={{ padding: ` 0 ${px(15)}` }}
            borderRadius={0}
            className="flex w-full cursor-pointer items-center justify-center border-[1px] border-solid border-[#dddddd]"
          >
            {buttonLeftText}
          </Button>
        )}
        {buttonRightText && (
          <Button
            bgColor="#FFFFFF"
            borderRadius={0}
            onClick={onClickButtonSubmit}
            style={{ padding: ` 0 ${px(15)}` }}
            color={single ? '#0A84FE' : '#000'}
            className="flex w-full cursor-pointer items-center justify-center border-[1px] border-solid border-[#dddddd]"
          >
            {buttonRightText}
          </Button>
        )}
      </div>
    </div>
  </div>
);

export default CustomAlert;
