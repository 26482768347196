import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/visit';

export const GET_VISIT = 'visit/getVisit';

export const getVisit = createAsyncThunk(
    GET_VISIT,
    createRequest({
        type: GET_VISIT,
        loader: (data) => authApi.getVisit(data),
    }),
);