/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_COMPETITION_ATTACHMENT = 'competition/deleteAttachment';

export const deleteCompetitionAttachment = createAsyncThunk(
  DELETE_COMPETITION_ATTACHMENT,
  createRequest({
    type: DELETE_COMPETITION_ATTACHMENT,
    loader: data => authApi.deleteCompetitionAttachment(data),
  }),
);
