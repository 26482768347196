/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const DELETE_COMPETITION_EVENT = 'competition/deleteCompetitionEvent';

export const deleteCompetitionEvent = createAsyncThunk(
  DELETE_COMPETITION_EVENT,
  createRequest({
    type: DELETE_COMPETITION_EVENT,
    loader: data => authApi.deleteCompetitionEvent(data),
  }),
);
