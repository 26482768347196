import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/auth';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const LOGIN_START = 'auth/loginStart';

const onFail = error => async dispatch => {
  // eslint-disable-next-line eqeqeq
  if (error == 'Error: Request failed with status code 403') {
    await dispatch(
      addModal({
        id: 1,
        data: {
          title: 'Пользователь не зарегистрирован',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
    // eslint-disable-next-line eqeqeq
  } else if (error == 'Error: Request failed with status code 400') {
    await dispatch(
      addModal({
        id: 14,
        data: {
          title: 'Проверьте правильность ввода номера телефона',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
  }
};

export const loginStart = createAsyncThunk(
  LOGIN_START,
  createRequest({
    type: LOGIN_START,
    loader: data => authApi.loginStart(data),
    onFail,
  }),
);
