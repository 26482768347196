import { request } from '@/helpers/api/request';

export const profileSocials = async (data) => {

  const response =   await request({
    url: `profile/socials/${data.id}`,
    method: 'PUT',
    data
  });

  return response;
};

