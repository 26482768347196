import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectModals } from '../selectors/modals';
import { Modal } from './Modal/modal';

export const Modals = () => {
  const modals = useSelector(selectModals);
  const reference = useRef(null);
  const modalHeight = reference.current?.offsetHeight || 0;
  const modalWidth = reference.current?.offsetWidth || 0;

  if (modals?.length === 0) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    <div
      className="bg-red fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      ref={reference}
      height={modalHeight}
      width={modalWidth}
    >
      {modals?.map(({ id, ...modal }) => (
        <Modal key={id} id={id} {...modal} />
      ))}
    </div>
  );
};

export default Modals;
