import React from 'react';

import { blockTheAccountPageTimeData } from '../constants/Component';
import { priceModal } from '../constants/Component/date-picker';
import { getPeriod } from '../constants/Function';
import { px } from '../constants/Px';
import { DatePicker } from './date-picker';

export const PeriodDatepicker = ({
  time,
  datePickerArray,
  startDate,
  endTime,
  endDate,
  openPicker,
  gap = 14,
  paddingBottom = 20,
  show,
  setShow,
  id,
  onlyTime,
}) => {
  const data = onlyTime ? priceModal : blockTheAccountPageTimeData;
  return (
    <>
      <DatePicker
        singleItem={datePickerArray?.[id - 1]?.singleItem}
        title={datePickerArray?.[id - 1]?.title}
        setShow={setShow}
        show={show}
        isDate={!datePickerArray?.[id - 1]?.singleItem}
        data={datePickerArray?.[id - 1]?.data}
        setPickerValue={datePickerArray?.[id - 1]?.setPickerValue}
        pickerValue={datePickerArray?.[id - 1]?.pickerValue}
        buttonTitle="Применить"
        isLine
      />
      <div
        style={{
          gap: px(gap),
        }}
        className="flex"
      >
        {data.map(item => (
          <div key={item?.id} className="flex flex-col">
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal  opacity-50"
            >
              {item?.title}
            </p>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-row"
            >
              {item?.data?.map(element => (
                <div key={element?.id}>
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      paddingBottom: px(paddingBottom),
                    }}
                    className="font-normal  opacity-50"
                  >
                    {element?.subTitle}
                  </p>
                  <div
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="font-normal text-black"
                    onClick={() => {
                      openPicker(element?.id);
                    }}
                    onKeyDown={() => {
                      openPicker(element?.id);
                    }}
                  >
                    {getPeriod(element?.id, time, endTime, startDate, endDate)}
                  </div>
                  <hr
                    className="text-black opacity-30"
                    style={{
                      marginTop: px(5),
                      /* width: px(50), */
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PeriodDatepicker;
