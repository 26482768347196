/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFavourites } from '../actions/favourites';
import { px } from '../constants/Px';
import { authSelector } from '../selectors/auth';
import { favouritesSelector } from '../selectors/favourites';
import { Card } from './card';
import { CardCompetition } from './card-competition';
import { CardGuide } from './card-guide';

export const FavouritesCards = () => {
  const dispatch = useDispatch();
  const cards = useSelector(favouritesSelector);
  const auth = useSelector(authSelector);

  const [selectedCard, setSelectedCard] = useState({});

  useEffect(() => {
    if (auth) dispatch(getFavourites());
  }, []);

  const [showPopup, SetShowPopup] = useState(false);

  return (
    <>
      {cards
        ?.filter(card => !card?.event?.deletedAt)
        ?.filter(card => !card?.competition?.deletedAt)
        ?.filter(card => !card?.guide?.deletedAt)?.length === 1 ? (
        <div className="col w-1/2">
          <div
            aria-hidden
            onClick={() => {
              setSelectedCard(cards[0]);
            }}
          >
            {cards[0].event && (
              <Card
                item={cards[0]?.event}
                id={cards[0]?.event?.id}
                title={cards[0]?.event?.name}
                blocked={cards[0].event?.status}
                addressTitle={cards[0]?.event?.EventContact?.district}
                address={cards[0]?.event?.EventContact?.town}
                street={cards[0]?.event?.EventContact?.street}
                image={
                  cards[0]?.event?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                    ? `${process.env?.REACT_APP_STATIC_URL}${
                        cards[0]?.event?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')
                          ?.imageUrl
                      }`
                    : cards[0]?.event?.image
                }
                style={{
                  marginBottom: px(8),
                }}
                setShowPopup={SetShowPopup}
              />
            )}
            {cards[0].competition && (
              <CardCompetition
                item={cards[0]?.competition}
                id={cards[0]?.competition?.id}
                title={cards[0]?.competition?.name}
                blocked={cards[0].competition?.status}
                addressTitle={
                  cards[0]?.competition?.contacts?.region ||
                  cards[0]?.competition?.contacts?.district ||
                  cards[0]?.competition?.region
                }
                address={cards[0]?.competition?.contacts?.town || cards[0]?.competition?.address}
                image={
                  cards[0]?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                    ? `${process.env?.REACT_APP_STATIC_URL}${
                        cards[0]?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                      }`
                    : cards[0]?.competition?.image
                }
                style={{
                  marginBottom: px(8),
                }}
                setShowPopup={SetShowPopup}
              />
            )}
            {cards[0].guide && (
              <CardGuide
                item={cards[0]?.guide}
                id={cards[0]?.guide?.id}
                title={cards[0]?.guide?.name}
                blocked={cards[0].guide?.status}
                addressTitle={cards[0]?.guide?.contact?.district}
                address={cards[0]?.guide?.contact?.town || cards[0]?.guide?.address}
                image={
                  cards[0]?.guide?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                    ? `${process.env?.REACT_APP_STATIC_URL}${
                        cards[0]?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                      }`
                    : cards[0]?.guide?.image
                }
                style={{
                  marginBottom: px(8),
                }}
                setShowPopup={SetShowPopup}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="col w-1/2">
            {cards
              ?.filter(card => !card?.event?.deletedAt)
              ?.filter((_, index) => index % 2 === 0)
              .map((item, key) => (
                <div
                  aria-hidden
                  key={item?.id || key}
                  onClick={e => {
                    setSelectedCard(item);
                  }}
                >
                  {item?.event && (
                    <Card
                      item={item?.event}
                      id={item?.event?.id}
                      title={item?.event?.name}
                      blocked={item.event?.status}
                      addressTitle={item?.event?.EventContact?.district}
                      address={item?.event?.EventContact?.town}
                      street={item?.event?.EventContact?.street}
                      image={
                        item?.event?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                          ? `${process.env?.REACT_APP_STATIC_URL}${
                              item?.event?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')
                                ?.imageUrl
                            }`
                          : item?.event?.image
                      }
                      style={{
                        marginBottom: px(8),
                      }}
                      setShowPopup={SetShowPopup}
                    />
                  )}
                  {item?.competition && !item?.competition?.deletedAt && (
                    <CardCompetition
                      item={item?.competition}
                      id={item?.competition?.id}
                      title={item?.competition?.name}
                      blocked={item?.competition?.status}
                      addressTitle={item?.competition?.contacts?.district}
                      address={item?.competition?.contacts?.town}
                      street={item?.competition?.contacts?.street}
                      image={
                        item?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                          ? `${process.env?.REACT_APP_STATIC_URL}${
                              item?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')
                                ?.url
                            }`
                          : item?.competition?.image
                      }
                      style={{
                        marginBottom: px(8),
                      }}
                      setShowPopup={SetShowPopup}
                    />
                  )}
                  {item?.guide && !item?.guide?.deletedAt && (
                    <CardGuide
                      item={item?.guide}
                      id={item?.guide?.id}
                      title={item?.guide?.name}
                      blocked={item?.guide?.status}
                      addressTitle={item?.guide?.contact?.district}
                      address={item?.guide?.contact?.town || item?.guide?.address}
                      image={
                        item?.guide?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                          ? `${process.env?.REACT_APP_STATIC_URL}${
                              item?.guide?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                            }`
                          : item?.guide?.image
                      }
                      style={{
                        marginBottom: px(8),
                      }}
                      setShowPopup={SetShowPopup}
                    />
                  )}
                </div>
              ))}
          </div>
          <div
            style={{
              paddingTop: px(20),
            }}
            className="col w-1/2"
          >
            {cards
              ?.filter(card => !card?.event?.deletedAt)
              ?.filter((_, index) => index % 2 !== 0)
              .map((item, key) => (
                <div
                  aria-hidden
                  onClick={e => {
                    setSelectedCard(item);
                  }}
                  key={item?.id || key}
                >
                  {item?.event && (
                    <Card
                      item={item?.event}
                      id={item?.event?.id}
                      title={item?.event?.name}
                      blocked={item.event?.status}
                      addressTitle={item?.event?.EventContact?.district}
                      address={item?.event?.EventContact?.town}
                      street={item?.event?.EventContact?.street}
                      image={
                        item?.event?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                          ? `${process.env?.REACT_APP_STATIC_URL}${
                              item?.event?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')
                                ?.imageUrl
                            }`
                          : item?.event?.image
                      }
                      style={{
                        marginBottom: px(8),
                      }}
                      setShowPopup={SetShowPopup}
                    />
                  )}
                  {item?.competition && !item?.competition?.deletedAt && (
                    <CardCompetition
                      item={item?.competition}
                      id={item?.competition?.id}
                      title={item?.competition?.name}
                      blocked={item?.competition?.status}
                      addressTitle={item?.competition?.contacts?.district}
                      address={item?.competition?.contacts?.town}
                      street={item?.competition?.contacts?.street}
                      image={
                        item?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                          ? `${process.env?.REACT_APP_STATIC_URL}${
                              item?.competition?.attachments?.find?.(i => i?.type === 'POSTCARD')
                                ?.url
                            }`
                          : item?.competition?.image
                      }
                      style={{
                        marginBottom: px(8),
                      }}
                      setShowPopup={SetShowPopup}
                    />
                  )}
                  {item?.guide && !item?.guide?.deletedAt && (
                    <CardGuide
                      item={item?.guide}
                      id={item?.guide?.id}
                      title={item?.guide?.name}
                      blocked={item?.guide?.status}
                      addressTitle={item?.guide?.contact?.district}
                      address={item?.guide?.contact?.town || item?.guide?.address}
                      image={
                        item?.guide?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                          ? `${process.env?.REACT_APP_STATIC_URL}${
                              item?.guide?.attachments?.find?.(i => i?.type === 'POSTCARD')?.url
                            }`
                          : item?.guide?.image
                      }
                      style={{
                        marginBottom: px(8),
                      }}
                      setShowPopup={SetShowPopup}
                    />
                  )}
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default FavouritesCards;
