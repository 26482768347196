/* eslint-disable import/no-unresolved */
import 'swiper/scss';

import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { amiriFont } from '../constants/Font';
import { Icon } from '../new-architecture/shared';
import { numberPx, px } from '../new-architecture/utils';
import { TypesIcon } from '../types/TypesIcon';

const Slide = ({ slide }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div className="relative">
      <div
        className="absolute"
        style={{
          zIndex: 10,
          top: px(20),
          right: px(-5),
          width: px(59),
          height: px(32),
          backgroundColor: 'red',
          color: 'white',
          fontSize: px(15),
          lineHeight: px(32),
          textAlign: 'center',
        }}
      >
        <span>PDF</span>
      </div>
      <div className="group" style={{ position: 'relative', overflow: 'hidden' }}>
        <img
          aria-hidden
          style={{ width: '100%', height: px(215) }}
          src={slide.src}
          className="sliderMaps__image_maps"
          alt={`slider-${slide.title}`}
        />
        <p
          aria-hidden
          className="bottom-[-100%] flex items-center justify-center group-hover:bottom-0"
          style={{
            cursor: 'pointer',
            backgroundColor: '#313131',
            position: 'absolute',
            color: 'white',
            textAlign: 'center',
            fontSize: numberPx(15),
            width: '100%',
            transition: '0.3s',
            gap: px(13),
          }}
          onClick={() => {
            fetch(slide?.file)
              .then(response => response.blob())
              .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = slide?.name;
                link.click();
              })
              .catch(console.error);
          }}
        >
          <span>
            <Icon
              type={TypesIcon.ARROWDOWNLOAD}
              width={px(6.669)}
              height={px(10.003)}
              viewBox="0 0 6.669 10.003"
            />
          </span>
          <span>СКАЧАТЬ</span>
        </p>
      </div>
    </div>
  );
};

export const SliderMaps = ({ images }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [screenWidthHorizontal, setScreenWidthHorizontal] = useState(false);
  const [screenWidthVertical, setScreenWidthVertical] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);

    const checkScreenConditions = () => {
      if (screenWidth < screenHeight) {
        setScreenWidthVertical(true);
        setScreenWidthHorizontal(false);
      } else if (screenHeight < screenWidth) {
        setScreenWidthHorizontal(true);
        setScreenWidthVertical(false);
      }
    };
    checkScreenConditions();

    const handleOutsideClick = e => {
      if (sliderRef.current && !sliderRef.current.contains(e.target) && isVideoPlaying) {
        setIsVideoPlaying(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [screenWidth, screenHeight, isVideoPlaying]);

  return (
    <Swiper
      spaceBetween={8}
      slidesPerView={2}
      modules={[Pagination]}
      pagination={{ clickable: true, horizontalClass: 'pagination-maps' }}
      className={`home_page-heroadd ${
        (screenWidthVertical || screenWidthHorizontal) && 'horizontal'
      }`}
      ref={sliderRef}
      onRealIndexChange={element => setActiveIndex(element.activeIndex)}
    >
      {images?.map(img => (
        <SwiperSlide key={img.src}>
          <Slide slide={img} />
          <p
            className="line-clamp-2 break-all"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              fontWeight: 400,
              marginTop: px(34),
              height: px(40),
            }}
          >
            {img.title}
          </p>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderMaps;
