/* eslint-disable no-shadow */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { getProfileById } from '../../../../../actions/profile';
import { getProfileById as getProfileByIdRequest } from '../../../../../api/profile';
import ad7Image from '../../../../../assets/images/ads/7.png';
import { Checkboxes, Footer, SettingProfileTitles } from '../../../../../components';
import { StyledInput } from '../../../../../components/styled-input';
import { addLeadingZeros, getFormatDate } from '../../../../../constants/Function';
import { authActiveType } from '../../../../../selectors/auth';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { BackButton, DynamicImageSlider, Icon, InputWithMask } from '../../../../shared';
import { px } from '../../../../utils';
import { useProfile } from '../../../hooks';
import { mapProfileToFields } from './helpers/profile.helpers';
import { updateProfile } from './services/profile.service';

export const ProfileForm = ({ profile }) => {
  const [fields, setFields] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const activeType = useSelector(authActiveType);

  const { updateFunction: updateProfileFunction } = useProfile();

  const navigate = useNavigate();

  useLayoutEffect(() => setFields(mapProfileToFields(profile)), [profile]);

  const [rec1Error, setRec1Error] = useState(false);
  const [rec2Error, setRec2Error] = useState(false);

  const [other1, setOther1] = useState('');
  const [other2, setOther2] = useState('');

  useEffect(() => {
    const rec1 = fields?.recommends?.[0];
    const rec2 = fields?.recommends?.[1];

    (async () => {
      if (rec1)
        getProfileByIdRequest({ id: rec1 })
          ?.then(({ data }) => setRec1Error(!data))
          ?.catch(() => setRec1Error(true));
      if (rec2)
        getProfileByIdRequest({ id: rec2 })
          ?.then(({ data }) => setRec2Error(!data))
          ?.catch(() => setRec2Error(true));
    })();
  }, [JSON.stringify(fields?.recommends)]);

  return (
    <>
      <div className="fixed top-0 z-10 w-full bg-[white]">
        <BackButton
          onClick={async () => {
            if (!isLoading) {
              setIsLoading(true);
              await updateProfile(dispatch, fields, profile?.id);
              await updateProfileFunction();
              if (activeType === 'administeredProfile')
                await dispatch(getProfileById({ id: profile?.id }));
              setIsLoading(false);
              navigate(`/profile`);
            }
          }}
        />
        {/* <HeaderColorBack
          onClick={async () => {
            if (!isLoading) {
              setIsLoading(true);
              await updateProfile(dispatch, fields, profile?.id);
              await updateProfileFunction();
              if (activeType === 'administeredProfile')
                await dispatch(getProfileById({ id: profile?.id }));
              setIsLoading(false);
              navigate(`/profile`);
            }
          }}
        /> */}
      </div>
      <div
        style={{
          paddingBottom: px(80),
          paddingTop: px(79),
        }}
      >
        <div style={{ paddingRight: px(15), paddingLeft: px(15), marginBottom: px(18) }}>
          <p
            style={{
              fontSize: px(32),
              lineHeight: px(32),
            }}
            className="whitespace-pre-wrap  font-light"
          >
            Личная информация
          </p>
          <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
            Работа с сервисом будет возможна, если Вы заполните все формы
          </p>
        </div>
        {/* <StyledInput
          cursorPointer={index === 3}
          phoneInput={key === 'phone'}
          title={titles ? titles[index] : null}
          isBigMarginBottom={isBigMarginBottom && index === 1 ? isBigMarginBottom : null}
          placeholder={onePlaceholder || placeholders[index]}
          value={
            values[key]
              ? key === 'number'
                ? `ID: ${addLeadingZeros(values[key]?.split?.(' ')?.[1])}`
                : values[key]?.toString()?.charAt(0)?.toUpperCase() +
                  values[key]?.toString()?.slice(1)
              : ''
          }
          onChange={e => handleInputChange(key, e.target.value)}
          isMarginBottom={isMarginBottom}
          isSettingsProfilePage={isSettingsProfilePage}
          textOpacity={50}
          disabled={
            placeholders?.[index] === 'Введите телефон…' ||
            placeholders?.[index] === '04.06.2021' ||
            placeholders?.[index] === 'ID 87548475'
          }
          placeholderOpacity={50}
        /> */}

        <div
          style={{
            paddingLeft: px(15),
            paddingRight: px(15),
          }}
        >
          <StyledInput
            title="Имя"
            placeholder="Введите имя..."
            value={fields?.name}
            onChange={e => setFields({ ...fields, name: e.target.value })}
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />
          <StyledInput
            title="Фамилия"
            placeholder="Введите фамилию..."
            value={fields?.surname}
            onChange={e => setFields({ ...fields, surname: e.target.value })}
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />
          {fields?.phone && (
            <StyledInput
              title="Телефон (логин)"
              value={fields?.phone}
              isMarginBottom
              textOpacity={50}
              placeholderOpacity={50}
              phoneInput
              disabled
            />
          )}
          <div
            aria-hidden
            onClick={() => {
              setFields(prevFields => {
                updateProfile(dispatch, prevFields, profile?.id);
                updateProfileFunction();
                if (activeType === 'administeredProfile')
                  dispatch(getProfileById({ id: profile?.id }));
                navigate(`/add-email`);
                return prevFields;
              });
            }}
          >
            <StyledInput
              title="Почта электронная"
              placeholder="Введите адрес электр..."
              value={fields?.email}
              isMarginBottom
              textOpacity={50}
              placeholderOpacity={50}
            />
          </div>
          <StyledInput
            title="Страна"
            placeholder="Страна проживания"
            value={fields?.country}
            onChange={e => setFields({ ...fields, country: e.target.value })}
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />
          <StyledInput
            title="Город"
            placeholder="Город проживания"
            value={fields?.town}
            onChange={e => setFields({ ...fields, town: e.target.value })}
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />

          <p
            className="font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingTop: px(18),
            }}
          >
            Документы. Паспорт
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              paddingBottom: px(30),
            }}
          >
            Для сложных бронирований и аренды
          </p>
          <div
            className="flex min-w-full items-center"
            style={{
              gap: px(21.9),
              paddingBottom: px(5),
              marginBottom: px(18),
            }}
          >
            <Icon
              type={TypesIcon.PASSPORT}
              viewBox="0 0 26.116 34.821"
              width={px(26.116)}
              height={px(34.821)}
            />
            <p
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              Паспорт
            </p>
          </div>
          <InputWithMask
            title="Серия и номер"
            value={fields?.passportDetails?.passportNumber || ''}
            onChange={passportNumber =>
              setFields({
                ...fields,
                passportDetails: { ...fields?.passportDetails, passportNumber },
              })
            }
            isBorder
            mask="** 99 999999"
            maskChar="X"
            style={{
              marginBottom: px(10),
            }}
          />
          <StyledInput
            title="Кем выдан"
            placeholder="Подразделение..."
            value={fields?.passportDetails?.issuedBy}
            onChange={e =>
              setFields({
                ...fields,
                passportDetails: { ...fields?.passportDetails, issuedBy: e.target.value },
              })
            }
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />
          <InputWithMask
            title="Дата выдачи"
            value={fields?.passportDetails?.issueDate || ''}
            onChange={issueDate =>
              setFields({
                ...fields,
                passportDetails: { ...fields?.passportDetails, issueDate },
              })
            }
            isBorder
            mask="99.99.9999"
            maskChar="0"
            style={{
              marginBottom: px(10),
            }}
          />
          <InputWithMask
            title="Код подразделения"
            value={fields?.passportDetails?.divisionCode || ''}
            onChange={divisionCode =>
              setFields({
                ...fields,
                passportDetails: { ...fields?.passportDetails, divisionCode },
              })
            }
            isBorder
            mask="999-999"
            maskChar="X"
            style={{
              marginBottom: px(10),
            }}
          />

          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginTop: px(4),
              marginLeft: px(17),
              marginBottom: px(15),
            }}
            className="font-normal text-[black] opacity-50 "
          >
            Копия первого разворота
          </p>

          <div
            style={{
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <DynamicImageSlider
              items={[
                {
                  key: 'passport',
                  src: fields?.passportDetails?.image,
                },
              ]}
              onChange={files =>
                setFields({
                  ...fields,
                  passportDetails: { ...fields?.passportDetails, image: files?.[0]?.src },
                })
              }
            />
          </div>

          <div
            className="flex min-w-full items-center"
            style={{
              gap: px(27),
              paddingBottom: px(27),
              marginTop: px(24),
            }}
          >
            <Icon
              type={TypesIcon.DOCUMENT}
              viewBox="0 0 20.819 27.331"
              width={px(20.819)}
              height={px(27.331)}
            />
            <p
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              Документы
            </p>
          </div>
          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginBottom: px(10),
            }}
            className="text-center font-normal opacity-50"
          >
            Лицензии. Права. Прочее…
          </p>
          <div
            style={{
              marginRight: px(-15),
              marginLeft: px(-15),
            }}
          >
            <DynamicImageSlider
              items={fields?.documents?.map?.(({ src, first }) => ({ src, first: !!first }))}
              onChange={files => setFields({ ...fields, documents: files })}
            />
          </div>

          <div
            style={{
              paddingTop: px(29),
              paddingBottom: px(10),
            }}
          >
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Регистрационная карта
            </p>
            <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
              Гарантии для сложных бронирований
            </p>
          </div>

          <div
            className="flex min-w-full items-center"
            style={{
              gap: px(17),
              paddingBottom: px(31.3),
              marginTop: px(27),
            }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 23.512"
              width={px(31.14)}
              height={px(27.74)}
            />
            <p
              className="font-normal"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
            >
              Фотографии
            </p>
          </div>

          <p
            className="font-normal opacity-50"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginLeft: px(18),
              marginBottom: px(10),
            }}
          >
            Фото ваших выездов, ...{' '}
          </p>
          <div
            style={{
              marginRight: px(-15),
              marginLeft: px(-15),
            }}
          >
            <DynamicImageSlider
              items={fields?.departures?.map?.(({ src, first }) => ({ src, first: !!first }))}
              onChange={files => setFields({ ...fields, departures: files })}
            />
          </div>
          <SettingProfileTitles
            gap={px(17)}
            bottom={px(24)}
            top={px(27)}
            iconType={TypesIcon.PERSONINCIRCLE}
            iconViewBox="0 0 30.936 30.936"
            iconWidth={px(30.5)}
            iconHeight={px(30.936)}
            title="Рекомендации"
          />

          <StyledInput
            title="ID кабинета"
            error={rec1Error}
            placeholder="Введите ID кабинета"
            value={fields?.recommends?.[0]}
            onChange={e =>
              setFields({
                ...fields,
                recommends: [e.target.value, fields?.recommends?.[1]],
              })
            }
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
            // isSettingsProfilePage
            numberType
          />
          <StyledInput
            title="ID кабинета"
            error={rec2Error}
            placeholder="Введите ID кабинета"
            value={fields?.recommends?.[1]}
            onChange={e =>
              setFields({
                ...fields,
                recommends: [fields?.recommends?.[0], e.target.value],
              })
            }
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
            // isSettingsProfilePage
            numberType
          />

          <SettingProfileTitles
            gap={px(17.8)}
            bottom={px(23)}
            top={px(17)}
            iconType={TypesIcon.REWARD}
            iconViewBox="0 0 30.198 30.936"
            iconWidth={px(30.2)}
            iconHeight={px(30.94)}
            title="Спортивные достижения"
          />

          <StyledInput
            title="Спортивная команда"
            placeholder="Название или ссылка"
            value={fields?.sportAchivments?.team}
            onChange={e =>
              setFields({
                ...fields,
                sportAchivments: { ...fields?.sportAchivments, team: e.target.value },
              })
            }
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />
          <StyledInput
            title="Спортивный клуб"
            placeholder="Название или ссылка"
            value={fields?.sportAchivments?.club}
            onChange={e =>
              setFields({
                ...fields,
                sportAchivments: { ...fields?.sportAchivments, club: e.target.value },
              })
            }
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
          />

          <p
            className="font-normal opacity-50"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingTop: px(4),
              paddingBottom: px(10),
              paddingLeft: px(18),
            }}
          >
            Книжка спортсмена
          </p>
          <div
            style={{
              marginRight: px(-15),
              marginLeft: px(-15),
            }}
          >
            <DynamicImageSlider
              items={[
                {
                  key: 'sport',
                  src: fields?.sportAchivments?.image,
                },
              ]}
              onChange={files =>
                setFields({
                  ...fields,
                  sportAchivments: { ...fields?.sportAchivments, image: files?.[0]?.src },
                })
              }
            />
          </div>

          <SettingProfileTitles
            gap={px(21.9)}
            bottom={px(22)}
            top={px(24)}
            iconType={TypesIcon.PASSPORT}
            iconViewBox="0 0 26.116 34.821"
            iconWidth={px(26.116)}
            iconHeight={px(34.821)}
            title="Другое"
          />
          <InputWithMask
            title="Что-то другое"
            mask="99 99 999999"
            maskChar="X"
            value={other1 || ''}
            onChange={value => setOther1(value)}
            style={{
              marginBottom: px(10),
            }}
          />
          <InputWithMask
            title="Что-то другое"
            mask="******..."
            maskChar="O"
            value={other2 || ''}
            onChange={value => setOther2(value)}
            style={{
              marginBottom: px(10),
            }}
          />
          <StyledInput
            title="Дата регистрации"
            placeholder=""
            value={getFormatDate(profile?.departure?.createdAt, true)}
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
            disabled
            withoutPaddingLeft
          />
          <StyledInput
            title="Номер личного кабинета"
            placeholder=""
            value={profile?.id ? `ID: ${addLeadingZeros(profile?.id)} ` : 'ID:'}
            isMarginBottom
            textOpacity={50}
            placeholderOpacity={50}
            disabled
            withoutPaddingLeft
          />

          <SettingProfileTitles
            gap={px(16.4)}
            bottom={px(26)}
            top={px(12)}
            iconType={TypesIcon.MAIL}
            iconViewBox="0 0 31.611 22.227"
            iconWidth={px(31.61)}
            iconHeight={px(22.23)}
            title="Ваша соц. сеть"
          />

          <div className="flex flex-row">
            <div
              style={{
                width: px(255),
              }}
            >
              <StyledInput
                title="WhatsUp"
                placeholder="https://"
                value={fields?.socials?.whatsupLink}
                onChange={e =>
                  setFields({
                    ...fields,
                    socials: { ...fields?.socials, whatsupLink: e.target.value },
                  })
                }
                isMarginBottom
                textOpacity={50}
                placeholderOpacity={50}
              />
              <StyledInput
                title="Telegram"
                placeholder="https://"
                value={fields?.socials?.telegramLink}
                onChange={e =>
                  setFields({
                    ...fields,
                    socials: { ...fields?.socials, telegramLink: e.target.value },
                  })
                }
                isMarginBottom
                textOpacity={50}
                placeholderOpacity={50}
              />
              <StyledInput
                title="Viber"
                placeholder="https://"
                value={fields?.socials?.viberLink}
                onChange={e =>
                  setFields({
                    ...fields,
                    socials: { ...fields?.socials, viberLink: e.target.value },
                  })
                }
                isMarginBottom
                textOpacity={50}
                placeholderOpacity={50}
              />
            </div>
            <div
              className="flex-column flex  justify-center "
              style={{
                gap: px(53),
                height: px(200),
              }}
            >
              <Checkboxes
                handleInputChange={v =>
                  setFields({
                    ...fields,
                    socials: { ...fields?.socials, [v]: !fields?.socials?.[v] },
                  })
                }
                checkboxes={{
                  whatsupLinkVisible: fields?.socials?.whatsupLinkVisible,
                  telegramLinkVisible: fields?.socials?.telegramLinkVisible,
                  viberLinkVisible: fields?.socials?.viberLinkVisible,
                }}
              />
            </div>
          </div>
        </div>
        <section className="home__ad home__ad--2">
          <div className="container">
            <Link to="/" target="_blank" className="pointer-events-none">
              <img src={ad7Image} className="home__ad-image" alt="home-ad-2" />
            </Link>
          </div>
        </section>
        <div
          style={{
            marginTop: px(10),
            marginBottom: px(-80),
          }}
        >
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
