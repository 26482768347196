import React from 'react';
import Truncate from 'react-truncate';

import { numberPx } from '../../constants/Px';

export const FixTruncate = ({ fixTruncateKey, title, linesQuantity, spanClass }) => (
  <>
    <span className={`${spanClass}`}>{title.split(' ')[0]} </span>
    <Truncate key={fixTruncateKey} lines={linesQuantity} width={numberPx(124)}>
      {title.split(' ').slice(1).join(' ')}
    </Truncate>
  </>
);
