import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { authSelector } from '../../selectors/auth';
import { ObjectProvider } from './context';
import { useObject } from './hooks';
import { ObjectPage } from './object.page';
import { getObject } from './object.service';

const ROUTES = [
  {
    path: '/object/:id',
    element: ObjectPage,
  },
];

const Stack = () => {
  const auth = useSelector(authSelector);
  const activeId = window.location.pathname.split('/')[2];
  const location = useLocation();
  const onStack = !!matchRoutes(ROUTES, location);

  const {
    data: objectData,
    setData: setObject,
    setUpdateFunction: setUpdateObjectFunction,
  } = useObject();
  const getData = () => getObject({ objectId: activeId }).then(setObject);

  useEffect(() => {
    if (onStack) {
      setUpdateObjectFunction(() => getData);
      getData();
    }
  }, [onStack]);

  return (
    <Routes>
      {ROUTES?.map(({ private_, element: Element, ...route }) => (
        <Route
          key={route.path}
          element={
            private_ ? (
              auth ? (
                <Element />
              ) : (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            ) : (
              <Element />
            )
          }
          {...route}
        />
      ))}
    </Routes>
  );
};

export const ObjectStack = () => (
  <ObjectProvider>
    <Stack />
  </ObjectProvider>
);

export default ObjectStack;
