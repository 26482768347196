/* eslint-disable import/no-unresolved */
/* eslint-disable sonarjs/prefer-immediate-return */
import { request } from '@/helpers/api/request';

export const updateCompetitionAttachment = async data => {
  const response = await request({
    url: `competition-attachment/${data.id}`,
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    method: 'PUT',
    data: data.data,
  });
  return response;
};
