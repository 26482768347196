import React from 'react';

export const ClosePopupLine = ({ style = {}, onPointerDown, onClick }) => (
  <div
    style={style}
    onPointerDown={onPointerDown}
    className="close-popup-line"
    onClick={onClick}
    aria-hidden
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4">
      <line
        id="Линия_141"
        data-name="Линия 141"
        x2="30"
        transform="translate(0 2)"
        fill="none"
        stroke="#000"
        strokeWidth="4"
        opacity="0.298"
      />
    </svg>
  </div>
);
