import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_COMPETITION_EVENT = 'competition/createCompetitionEvent';

export const createCompetitionEvent = createAsyncThunk(
  CREATE_COMPETITION_EVENT,
  createRequest({
    type: CREATE_COMPETITION_EVENT,
    loader: data => authApi.createCompetitionEvent(data),
  }),
);
