import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section-discount';
import { createRequest } from '../../../helpers/api/createRequest';

export const CHANGE_DISCOUNT_GROUP = 'discount/changeDiscountGroup';

export const changeDiscountGroup = createAsyncThunk(
  CHANGE_DISCOUNT_GROUP,
  createRequest({
    type: CHANGE_DISCOUNT_GROUP,
    loader: data => authApi.changeDiscountGroup(data),
  }),
);
