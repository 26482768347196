import React from 'react';

import { px } from '../constants/Px/index';

export const Button = ({
  className,
  style,
  onClick,
  children,
  disabled,
  color,
  bgColor,
  fontSize,
  lineHeight,
  fontWeight,
  height,
  bgOpacityDisabled,
  marginTop,
  marginBottom,
  borderRadius,
  ...props
}) => (
  <button
    type="button"
    {...props}
    className={`button ${className || ''}  
      
      ${bgColor ? `active:bg-[${bgColor}]` : 'active:bg-[#0873de]'}
      
       ${bgColor ? `hover:bg-[${bgColor}]` : 'hover:bg-[#087bef]'}
      

       ${fontWeight ? `font-[${fontWeight}]` : 'font-normal'}
      
       `}
    style={{
      fontSize: px(fontSize || 20),
      lineHeight: px(lineHeight || 26),
      height: px(height || 50),
      marginTop: px(marginTop),
      marginBottom: px(marginBottom),
      borderRadius: borderRadius === undefined ? px(25) : px(borderRadius),
      color: color ? `${color}` : '#fff',
      background: bgColor ? `${bgColor}` : '#0a84fe',
      opacity: disabled ? bgOpacityDisabled || 0.5 : null,
      cursor: disabled && 'default',
      ...style,
    }}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
