export const INITIAL_STATE = {
  locations: [],
  selectedLocationId: undefined,
  object: undefined,
  sectorFields: {
    number: '',
    place: undefined,
    landscape: undefined,
    conditions: undefined,
    watersideObject: undefined,
    objectType: undefined,
    forRest: undefined,
    name: '',
    objectDescription: '',
    freeZoneNumber: 0,
    winterFromIceNumber: 0,
    capacityNumber: 0,
    sleepPlaces: 0,
    facilities: undefined,
    fishing: undefined,
    discipline: undefined,
    direction: undefined,
    services: undefined,
    addConditions: undefined,
    aquazone: undefined,
    fish: undefined,
    photos: [],
    schemas: [],
    price: undefined,
  },
  allowanceGroup: {
    groupNumber: undefined,
    payment: undefined,
    blockEnd: undefined,
    blockStart: undefined,
    booking: undefined,
    discount: undefined,
    id: undefined,
    isBlocked: false,
    isOpen: false,
    isPause: false,
    isPrefDeparture: false,
    locationID: undefined,
    openEnd: undefined,
    openStart: undefined,
    pause: undefined,
    prefDeparture: undefined,
    prepayment: undefined,
    refund0: undefined,
    refund50: undefined,
    refund75: '75 %',
    refund100: undefined,
    weekends: [false, false, false, false, false, false, false],
    workdays: [false, false, false, false, false, false, false],
  },
  selectedSector: undefined,
  priceGroup: {},
  currentPriceGroup: {
    caughtValue: undefined,
    damagePenalty: undefined,
    deposit: undefined,
    helpValue: undefined,
    isCaught: undefined,
    isHelp: undefined,
    isWithNorm: undefined,
    isWithoutNorm: undefined,
    withNormValue: undefined,
    withoutNormValue: undefined,
    id: undefined,
    type: undefined,
    periodStart: undefined,
    periodEnd: undefined,
    weekendExtraCharge: undefined,
    checkoutPenalty: undefined,
    checkoutTimeLimit: undefined,
    cost: undefined,
    objectCheckoutPenalty: undefined,
    objectWeekendExtraCharge: undefined,
    objectCheckoutTimeLimit: undefined,
    sectionGroupId: undefined,
  },
  promotionGroup: [],
  currentPromotionGroup: {
    id: undefined,
    type: undefined,
    periodStart: undefined,
    periodEnd: undefined,
    weekendExtraCharge: undefined,
    checkoutPenalty: undefined,
    checkoutTimeLimit: undefined,
    cost: '0 %',
    objectCheckoutPenalty: undefined,
    objectWeekendExtraCharge: undefined,
    objectCheckoutTimeLimit: undefined,
    sectionGroupId: undefined,
    caughtValue: undefined,
    helpValue: undefined,
    isCaught: undefined,
    isHelp: undefined,
    isWithNorm: undefined,
    isWithoutNorm: undefined,
    withNormValue: undefined,
    withoutNormValue: undefined,
  },
  discountGroup: [],
  currentDiscountGroup: {
    id: undefined,
    type: undefined,
    periodStart: undefined,
    periodEnd: undefined,
    weekendExtraCharge: undefined,
    checkoutPenalty: undefined,
    checkoutTimeLimit: undefined,
    cost: undefined,
    objectCheckoutPenalty: undefined,
    objectWeekendExtraCharge: undefined,
    objectCheckoutTimeLimit: undefined,
    sectionGroupId: undefined,
    caughtValue: undefined,
    helpValue: undefined,
    isCaught: undefined,
    isHelp: undefined,
    isWithNorm: undefined,
    isWithoutNorm: undefined,
    withNormValue: undefined,
    withoutNormValue: undefined,
  },
};
