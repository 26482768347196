import { log } from '@craco/craco/dist/lib/logger';
import React, { useContext, useEffect, useState } from 'react';

import { LOGOUT } from '../actions/auth';
import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import Checkbox from './checkbox';
import { Icon } from './Icon';
import { SearchMenuFilterBlockItem } from './search-menu-filter-block-item';
import { StateContext } from './state-provider';

export const SearchMenuFilterBlock = ({
  filter,
  onChange,
  selectedOptionsTitle,
  setSelectedOptionsTitle,
  filtersObject,
  scrollIndex,
  setScrollIndex,
  show,
  setShow,
  date,
  setDataPickerId,
}) => {
  const { selectedOptions, setSelectedOptions } = useContext(StateContext);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    onChange && onChange(filter?.type === 'checkbox' ? selectedOptions : selectedOption);
  }, [selectedOptions, selectedOption]);

  const toggleOption = (option, title) => {
    setSelectedOptionsTitle(prev => [...prev, title]);
    const newOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(item => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newOptions);
  };

  const cleanOptions = (e, filterTitle) => {
    const newArr = [];
    const arr = [];

    e?.map(el => {
      arr.push(el.value);
    });

    selectedOptions?.map(elem => {
      !arr.includes(elem) && newArr.push(elem);
    });
    setSelectedOptions(newArr);

    setSelectedOptionsTitle(selectedOptionsTitle.filter(item => item !== filterTitle));
  };

  return (
    <>
      {filter.type === 'checkbox' ? (
        <div className="search-menu-filter-block">
          <div className="search-menu-filter-block-top">
            <h4>{filter.title}</h4>
            {selectedOptionsTitle?.includes(filter.title) && (
              <div
                className="search-menu-filter-block-reset"
                onClick={() => cleanOptions(filter.options, filter.title)}
              >
                сбросить
              </div>
            )}
          </div>
          <div className="search-menu-filter-block-bottom">
            {filter.options.map((option, key) => (
              <SearchMenuFilterBlockItem
                key={`${option.label}-${key}`}
                option={option}
                isSelected={selectedOptions.includes(option?.value)}
                toggleOption={() => toggleOption(option?.value, filter?.title)}
                setShow={setShow}
                date={date}
                setDataPickerId={setDataPickerId}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            marginBottom: px(19),
          }}
          className="search-menu-filter-block-game"
        >
          {filter.options.map((option, index) => {
            const isChecked =
              selectedOption == undefined ? index === 0 : selectedOption === option?.value;

            return (
              <div
                key={option.label}
                className={`search-menu-filter-block-game-item ${isChecked ? 'selected' : ''}`}
                onClick={() => setSelectedOption(option?.value)}
              >
                <div className="search-menu-filter-block-game-content">
                  <div
                    className="search-menu-filter-block-game-svg"
                    style={{
                      color: '#000000',
                    }}
                  >
                    <Icon
                      type={TypesIcon.GAMES}
                      width={px(24.128)}
                      height={px(26.848)}
                      viewBox="0 0 24.128 26.848"
                    />
                  </div>
                  <div>
                    <h3>{filter.title}</h3>
                    <p>{option.label}</p>
                  </div>
                </div>
                <Checkbox checked={isChecked} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
