import { toFormData } from 'axios';

import { sectionPost } from '../../../../api/section';
import { sectionUpdate } from '../../../../api/section/update-section';
import { request } from '../../../../helpers/api/request';
import { urlToBlob } from '../../../utils/blob';
// import { toFormData } from '../../../utils';
import { mapFieldsToPayload } from '../helpers/sector.helpers';

export const sectorRequest = async (fields, location, sectorId) => {
  const formData = toFormData(await mapFieldsToPayload(fields, location));

  const { schemas, photos } = fields;

  await Promise.all(
    photos?.map(async ({ src }, index) => formData.append('images', await urlToBlob(src, index))),
  );
  await Promise.all(
    schemas?.map(async ({ src }, index) => formData.append('schemas', await urlToBlob(src, index))),
  );

  return sectorId ? sectionUpdate(sectorId, formData) : sectionPost(formData);
};

export const propagateSector = async (sector, location, startingNumber, endingNumber) => {
  const payload = {
    ...sector,
    locationId: location?.id,
    startingNumber: Number(startingNumber),
    endingNumber: Number(endingNumber),
  };

  return request({
    url: 'section/copy',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: payload,
  });
};
