import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { addAchievement } from '../actions/achievement';
import { addDeparture } from '../actions/departure/thunks/add-departure';
import { addDocument } from '../actions/document/thunks';
import { getProfile, profilePassport, profileSocials, updateProfile } from '../actions/profile';
import { addRecommendation } from '../actions/recommendation';
import ad2Image from '../assets/images/ad-2.png';
import {
  default as sliderImage1,
  default as sliderImage2,
  default as sliderImage3,
} from '../assets/images/slider/1.png';
import {
  settingsProfile,
  settingsProfilePagePlaceholderNetwork as placeholderNetwork,
  settingsProfilePagePlaceholderOthers as placeholderOthers,
  settingsProfilePagePlaceholderPassport as placeholderPassport,
  settingsProfilePagePlaceholdersPersonalInfo as placeholdersPersonalInfo,
  settingsProfilePageTitleOthers as titleOthers,
  settingsProfilePageTitlesNetwork as titlesNetwork,
  settingsProfilePageTitlesPassport as titlesPassport,
  settingsProfilePageTitlesPersonalInfo as titlesPersonalInfo,
  settingsProfilePageTitlesSport as titlesSport,
  settingsProfilePageValuesOthers as valuesOthers,
} from '../constants/Component';
import { getFormatDate } from '../constants/Function';
import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Checkboxes } from './checkboxes';
import { Footer } from './footer';
import { Icon } from './Icon';
import { Modal, useModal } from './portals';
import SettingProfileAddPhoto from './setting-profile-add-photo';
import SettingProfileInfo from './setting-profile-info';
import SettingProfileTitles from './setting-profile-titles';
import Slider from './slider';
import StyledGalleryDocs from './styled-gallery-docs';
import StyledGalleryFilters from './styled-gallery-filters';

export const SettingProfileComponent = ({ profile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documentsIndex, setDocumentsIndex] = useState(0);
  const [departureIndex, setDepartureIndex] = useState(0);
  const [addSlide, setAddSlide] = useState(false);
  const [addSlideDoc, setAddSlideDoc] = useState(false);
  const [styledComponent, setStyledComponent] = useState('Gallery');
  const {
    isOpen: imageGalery,
    openModal: openImageGalery,
    closeModal: closeImageGalery,
  } = useModal();

  const { isOpen: imageDocs, openModal: openImageDocs, closeModal: closeImageDocs } = useModal();

  const [inputsPersonalInfo, setInputsPersonalInfo] = useState({
    name: profile?.name,
    surname: profile?.surname,
    phone: profile?.phone,
    email: profile?.email,
    country: profile?.country,
    town: profile?.town,
  });
  const [inputsPassport, setInputsPassport] = useState({
    passportNumber: profile?.passportDetails?.passportNumber,
    issuedBy: profile?.passportDetails?.issuedBy,
    issueDate: profile?.passportDetails?.issueDate,
    divisionCode: profile?.passportDetails?.divisionCode,
    // images: profile?.passportDetails?.images,
  });

  const [passportImage, setPassportImage] = useState(profile?.passportDetails?.images);

  const [inputRecomendation, setInputRecomendation] = useState({
    recommendedId: profile?.recommends?.[0]?.id || '',
    recommendedId2: profile?.recommends?.[0]?.id || '',
  });
  const [inputSport, setInputSport] = useState({
    club: profile?.sportAchivments?.club === 'null' ? undefined : profile?.sportAchivments?.club,
    team: profile?.sportAchivments?.team === 'null' ? undefined : profile?.sportAchivments?.team,
    /* image: profile?.sportAchivments?.image, */
  });

  console.log('input sport', inputSport);

  const [sportImage, setSportImage] = useState(profile?.sportAchivments?.image);

  const [inputOthers, setInputOthers] = useState({
    other1: '',
    other2: '',
    data: getFormatDate(profile?.departure?.createdAt, true),
    number: profile?.socials?.id ? `ID: ${profile?.socials?.id} ` : 'ID:',
  });

  const [inputNetwork, setInputNetwork] = useState({
    whatsupLink: profile?.socials?.whatsupLink,
    telegramLink: profile?.socials?.telegramLink,
    viberLink: profile?.socials?.viberLink,
  });

  const [checkboxes, setCheckboxes] = useState({
    whatsupLinkVisible: profile?.socials?.whatsupLinkVisible,
    telegramLinkVisible: profile?.socials?.telegramLinkVisible,
    viberLinkVisible: profile?.socials?.viberLinkVisible,
  });

  const [departureImg, setDepartureImg] = useState(profile?.departure?.images);
  const [tempDep, setTempDep] = useState(profile?.departure?.images);
  const [documentsImg, setDocumentsImg] = useState(profile?.documents?.images);
  const [tempDoc, setTempDoc] = useState(profile?.documents?.images);

  const handleInputChange = (key, value) => {
    setCheckboxes(prevInputs => ({
      ...prevInputs,
      [key]: value,
    }));
  };

  const onSubmitProfile = async () => {
    try {
      const formData = new FormData();
      formData.append('passportNumber', inputsPassport?.passportNumber);
      formData.append('issuedBy', inputsPassport?.issuedBy);
      formData.append('divisionCode', inputsPassport?.divisionCode);
      formData.append('issueDate', inputsPassport?.issueDate);
      formData.append('file', passportImage);
      const achivmentData = new FormData();
      achivmentData.append('club', inputSport?.club);
      achivmentData.append('team', inputSport?.team);
      achivmentData.append('file', sportImage);

      const departureData = new FormData();
      for (const item of departureImg) departureData.append('files', item);

      const documentsData = new FormData();
      for (const item of documentsImg) documentsData.append('files', item);

      await dispatch(
        updateProfile({
          name: inputsPersonalInfo?.name,
          surname: inputsPersonalInfo?.surname,
          phone: inputsPersonalInfo?.phone,
          email: inputsPersonalInfo?.email,
          country: inputsPersonalInfo?.country,
          town: inputsPersonalInfo?.town,
          id: profile?.id,
        }),
      );
      await dispatch(profilePassport({ data: formData, id: 238 }));
      await dispatch(addDeparture({ data: departureData, id: profile?.id }));
      await dispatch(addDocument({ data: documentsData, id: profile?.id }));
      await dispatch(
        addRecommendation({
          data: {
            recommendedId: Number.parseInt(inputRecomendation?.recommendedId),
            recommendedId2: Number.parseInt(inputRecomendation?.recommendedId2),
          },
          id: profile?.id,
        }),
      );
      // await dispatch(addRecommendation({ recommendedId: Number.parseInt(inputRecomendation?.recommendedId2) }));
      await dispatch(
        profileSocials({
          whatsappLink: inputNetwork?.whatsupLink,
          whatsappLinkVisible: checkboxes?.whatsupLinkVisible,
          telegramLink: inputNetwork?.telegramLink,
          telegramLinkVisible: checkboxes?.telegramLinkVisible,
          viberLink: inputNetwork?.viberLink,
          viberLinkVisible: checkboxes?.viberLinkVisible,
          id: profile?.id,
        }),
      );

      await dispatch(addAchievement({ data: achivmentData, id: profile?.id }));
      await dispatch(getProfile());
    } catch {
      console.log('error');
    }
  };

  const navigateChangeEmail = () => {
    navigate('/add-email');
  };

  const handleNavigateBack = () => {
    onSubmitProfile();
    navigate('/profile');
  };

  return (
    <>
      <div
        className="w-full "
        style={{
          height: px(60),
        }}
      >
        <div
          className=" flex-column flex cursor-pointer justify-center"
          style={{
            height: px(60),
            width: px(60),
            paddingLeft: px(15),
          }}
          onClick={handleNavigateBack}
          aria-hidden
        >
          <Icon
            type={TypesIcon.COLORBACK}
            viewBox="0 0 11.6 19.76"
            width={px(11.6)}
            height={px(19.76)}
          />
        </div>
      </div>
      <main
        style={{
          paddingRight: px(15),
          paddingLeft: px(15),
        }}
      >
        <p
          className="font-light"
          style={{
            fontSize: px(32),
            lineHeight: px(32),
            paddingTop: px(20),
          }}
        >
          Личная информация
        </p>
        <p
          style={{
            fontSize: px(15),
            lineHeight: px(20),
            paddingRight: px(13),
          }}
          className="font-normal"
        >
          Работа с сервисом будет еще удобнее, если Вы заполните все формы
        </p>
        <div className="flex flex-col ">
          <SettingProfileInfo
            setValues={setInputsPersonalInfo}
            placeholders={placeholdersPersonalInfo}
            titles={titlesPersonalInfo}
            valuesObj={inputsPersonalInfo}
            isMarginBottom
            values={inputsPersonalInfo}
            navigateChangeEmail={navigateChangeEmail}
          />
          <p
            className="font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingTop: px(16),
            }}
          >
            Документы. Паспорт
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              paddingBottom: px(30),
            }}
          >
            Для сложных бронирований и аренды
          </p>
          <SettingProfileTitles
            gap={px(21.9)}
            bottom={px(11)}
            iconType={TypesIcon.PASSPORT}
            iconViewBox="0 0 26.116 34.821"
            iconWidth={px(26.116)}
            iconHeight={px(34.821)}
            title="Паспорт"
          />
          <SettingProfileInfo
            setValues={setInputsPassport}
            titles={titlesPassport}
            values={inputsPassport}
            valuesObj={inputsPassport}
            isMarginBottom
            placeholders={placeholderPassport}
          />
          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginTop: px(4),
              marginLeft: px(17),
              marginBottom: px(15),
            }}
            className="font-normal text-[black] opacity-50 "
          >
            Копия первого разворота
          </p>

          <SettingProfileAddPhoto images={passportImage} setValues={setPassportImage} />

          <SettingProfileTitles
            gap={px(27)}
            bottom={px(29.7)}
            iconType={TypesIcon.DOCUMENT}
            iconViewBox="0 0 20.819 27.331"
            iconWidth={px(20.819)}
            iconHeight={px(27.331)}
            title="Документы"
          />

          <p
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginBottom: px(10),
            }}
            className="text-center font-normal opacity-50"
          >
            Лицензии. Права. Прочее…
          </p>
          <div
            style={{
              marginRight: px(-15),
              marginLeft: px(-15),
            }}
          >
            {/* <Slider
              images={[
                { type: 'image', src: sliderImage1 },
                { type: 'image', src: sliderImage2 },
                { type: 'image', src: sliderImage3 },
              ]}
              sliderInRegistrationCard
            /> */}
            <Slider
              images={
                tempDoc?.length === 0
                  ? [{ id: undefined, src: '', type: 'blueBg' }]
                  : tempDoc?.map(item => ({
                      id: item.id,
                      src: item.url ? `${process.env.REACT_APP_STATIC_URL}${item.url}` : item,
                      type: 'GALLERY',
                    }))
              }
              sliderInRegistrationCard
              index={documentsIndex}
              setIndex={setDocumentsIndex}
              gallery
              openModal={openImageDocs}
              setStyledComponent={setStyledComponent}
              setAddSlide={setAddSlide}
            />
          </div>

          <div
            style={{
              paddingTop: px(27),
              paddingBottom: px(34),
            }}
          >
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Регистрационная карта
            </p>
            <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
              Гарантия для сложных бронирований
            </p>
          </div>

          <SettingProfileTitles
            gap={px(17)}
            bottom={px(31.3)}
            iconType={TypesIcon.GALLERY}
            iconViewBox="0 0 26.827 23.512"
            iconWidth={px(31.14)}
            iconHeight={px(27.74)}
            title="Фотографии (не > 4 шт.)"
          />

          <p
            className="font-normal opacity-50"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              marginLeft: px(18),
              marginBottom: px(10),
            }}
          >
            Фото ваших выездов, ...{' '}
          </p>

          <div
            style={{
              height: px(290),
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <Slider
              images={
                tempDep?.length === 0
                  ? [{ id: undefined, src: '', type: 'blueBg' }]
                  : tempDep?.map(item => ({
                      id: item.id,
                      src: item.url ? `${process.env.REACT_APP_STATIC_URL}${item.url}` : item,
                      type: 'GALLERY',
                    }))
              }
              sliderInRegistrationCard
              index={departureIndex}
              setIndex={setDepartureIndex}
              gallery
              openModal={openImageGalery}
              setStyledComponent={setStyledComponent}
              setAddSlide={setAddSlide}
            />
            {/* 
            
            <div>
              <img className="h-full w-full" src={sliderImage1} alt="photo" />
            </div>
            <div>
              <img className="h-full w-full" src={sliderImage1} alt="photo" />
            </div>
            <div>
              <img className="h-full w-full" src={sliderImage1} alt="photo" />
            </div>

            <div className="flex h-full w-full items-center justify-center  ">
              <CircleButton
                icon={{
                  type: TypesIcon.GALLERY,
                  viewBox: '0 0 26.827 23.512',
                  width: px(26.83),
                  height: px(23.51),
                }}
              />
            </div>
            
             */}
          </div>
          <SettingProfileTitles
            gap={px(17)}
            bottom={px(8)}
            top={px(27)}
            iconType={TypesIcon.PERSONINCIRCLE}
            iconViewBox="0 0 30.936 30.936"
            iconWidth={px(30.5)}
            iconHeight={px(30.936)}
            title="Рекомендации"
          />

          <SettingProfileInfo
            isSettingsProfilePage
            setValues={setInputRecomendation}
            onePlaceholder="Введите ID кабинета"
            valuesObj={inputRecomendation}
            values={inputRecomendation}
            isMarginBottom
            numberType
          />

          <SettingProfileTitles
            gap={px(17.8)}
            bottom={px(7)}
            top={px(17)}
            iconType={TypesIcon.REWARD}
            iconViewBox="0 0 30.198 30.936"
            iconWidth={px(30.2)}
            iconHeight={px(30.94)}
            title="Спортивные достижения"
          />
          <SettingProfileInfo
            setValues={setInputSport}
            values={inputSport}
            isMarginBottom
            titles={titlesSport}
            valuesObj={inputSport}
            onePlaceholder="Название или ссылка"
          />

          <p
            className="font-normal opacity-50"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingTop: px(4),
              paddingBottom: px(10),
              paddingLeft: px(18),
            }}
          >
            Книжка спортсмена
          </p>

          <SettingProfileAddPhoto images={sportImage} setValues={setSportImage} single />

          <SettingProfileTitles
            gap={px(21.9)}
            bottom={px(6)}
            iconType={TypesIcon.PASSPORT}
            iconViewBox="0 0 26.116 34.821"
            iconWidth={px(26.116)}
            iconHeight={px(34.821)}
            title="Другое"
          />

          <SettingProfileInfo
            setValues={setInputOthers}
            values={inputOthers}
            valuesObj={valuesOthers}
            titles={titleOthers}
            isMarginBottom
            isBigMarginBottom
            placeholders={placeholderOthers}
          />

          <SettingProfileTitles
            gap={px(16.4)}
            bottom={px(12.8)}
            top={px(15)}
            iconType={TypesIcon.MAIL}
            iconViewBox="0 0 31.611 22.227"
            iconWidth={px(31.61)}
            iconHeight={px(22.23)}
            title="Ваша соц. сеть"
          />

          <div className="flex flex-row">
            <div
              style={{
                width: px(255),
              }}
            >
              <SettingProfileInfo
                setValues={setInputNetwork}
                values={inputNetwork}
                valuesObj={inputNetwork}
                titles={titlesNetwork}
                isMarginBottom
                placeholders={placeholderNetwork}
              />
            </div>
            <div
              className="flex-column flex  justify-center"
              style={{
                gap: px(53),
              }}
            >
              <Checkboxes handleInputChange={handleInputChange} checkboxes={checkboxes} />
            </div>
          </div>
        </div>
        <section
          className="home__ad home__ad--2"
          style={{
            marginRight: px(-15),
            marginLeft: px(-15),
          }}
        >
          <div className="container">
            <Link to="/" target="_blank">
              <img src={ad2Image} className="home__ad-image" alt="home-ad-2" />
            </Link>
          </div>
        </section>
        <div
          style={{
            marginRight: px(-15),
            marginLeft: px(-15),
            marginTop: px(10),
          }}
        >
          <Footer />
        </div>
        <Modal isOpen={imageGalery} onClose={closeImageGalery}>
          <StyledGalleryDocs
            data={profile?.departure?.images}
            index={departureIndex}
            setIndex={setDepartureIndex}
            setAddSlide={setAddSlide}
            addSlide={addSlide}
            img={departureImg}
            setUrl={setDepartureImg}
            setTemp={setTempDep}
            temp={tempDep}
            /* setSave={setSave} */
            closeModal={closeImageGalery}
          />
        </Modal>
        <Modal isOpen={imageDocs} onClose={closeImageDocs}>
          <StyledGalleryDocs
            data={profile?.documents?.images}
            index={documentsIndex}
            setIndex={setDocumentsIndex}
            setAddSlide={setAddSlide}
            addSlide={addSlide}
            img={documentsImg}
            setUrl={setDocumentsImg}
            setTemp={setTempDoc}
            temp={tempDoc}
            /* setSave={setSave} */
            closeModal={closeImageDocs}
          />
        </Modal>
      </main>
    </>
  );
};

export default SettingProfileComponent;
