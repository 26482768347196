import qrCode from '@assets/images/qr-code.png';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { cardHolderBlock } from '../../../../../actions/card-holder/thunks/card-holder-block';
import { getCardHolderCards } from '../../../../../api/card-holder';
import { restoreDeleteCardHolder } from '../../../../../api/card-holder/delete-card-holder';
import { CustomAlert } from '../../../../../components/custom-alert';
import { DatePicker } from '../../../../../components/date-picker';
import { Switch } from '../../../../../components/switch';
import {
  blockTheAccountPageTimeData,
  getDaysInMonth,
  selectionsDate,
  timeDate,
} from '../../../../../constants/Component';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { format2DigitDate } from '../../../../utils';
import { px } from '../../../../utils/px';
import { BackButton } from '../../../back-button';
import { Button } from '../../../button';
import { CardInformation } from '../../../card-information';
import { CircleButton } from '../../../circle-button';

export const ClubCardViewModal = ({
  data,
  showCardTypeView,
  setShowCardTypeView,
  setCards,
  removeDeleteButton,
  cardHolderId,
  setCardHolderId,
}) => {
  useEffect(() => {
    if (showCardTypeView) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
    return () => document.documentElement.classList.remove('lock');
  }, [showCardTypeView]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDropdownIds, setOpenDropdownIds] = useState([]);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(false);
  const [disabledButtonBlock, setDisabledButtonBlock] = useState(false);
  const [event, setEvent] = useState();
  const [toggle, setToggle] = useState(false);
  const [time, setTime] = useState({
    hour: event?.blockStartAt?.slice(11, 16) || `${new Date()?.getHours()}:00` || '1',
  });
  const [startDate, setStartDate] = useState({
    day: event?.blockStartAt?.slice(8, 10) || `${new Date()?.getDate()}` || '1',
    month:
      event?.blockStartAt?.slice(5, 7) || selectionsDate?.month[new Date()?.getMonth()] || 'Январь',
    year: event?.blockStartAt?.slice(0, 4) || `${new Date()?.getFullYear()}` || '2023',
  });
  const [endTime, setEndTime] = useState({
    hour: event?.blockEndsAt?.slice(11, 16) || `${new Date()?.getHours()}:00` || '1',
  });
  const [endDate, setEndDate] = useState({
    day: event?.blockEndsAt?.slice(8, 10) || `${new Date()?.getDate()}` || '1',
    month:
      event?.blockEndsAt?.slice(5, 7) || selectionsDate?.month[new Date()?.getMonth()] || 'Январь',
    year: event?.blockEndsAt?.slice(0, 4) || `${new Date()?.getFullYear()}` || '2024',
  });
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const [showAlertBlockCard, setShowAlertBlockCard] = useState(false);
  const [showAlertDeleteCard, setShowAlertDeleteCard] = useState(false);

  const openPicker = value => {
    setId(value);
    setShow(true);
  };

  const clubCardData = useMemo(
    () => ({
      holderId: cardHolderId && `ID: ${cardHolderId?.profileId || ''}`,
      cardIdToShow: cardHolderId ? cardHolderId?.id : data?.id,
      cardId: cardHolderId ? cardHolderId?.id : data?.id,
      logoUrl: cardHolderId
        ? data?.event?.EventAttachment?.find(attachment => attachment.type === 'LOGO')?.imageUrl
        : data?.card?.event?.EventAttachment?.find(attachment => attachment.type === 'LOGO')
            ?.imageUrl || '',
      cardDiscount: `${cardHolderId ? data?.cardDiscount : data?.card?.cardDiscount}%` || '',
      profileName: cardHolderId ? cardHolderId?.profile?.name : data?.profile?.name || '',
      openUntill: cardHolderId ? cardHolderId?.openUntill : data?.openUntill,
      cardName: cardHolderId ? data?.name : data?.card?.name || '',
      imageUrl: cardHolderId ? data?.image?.url : data?.card?.image?.url || '',
      receiptConditions: data?.card?.receiptConditions,
      cardPaymentType: data?.card?.cardPaymentType,
      generalInformation: data?.card?.generalInformation,
      agreement: data?.card?.agreement,
      applicationScope: data?.card?.applicationScope,
      useTerms: data?.card?.useTerms,
      eventId: cardHolderId ? data?.eventId : data?.card?.eventId,
      message: cardHolderId && cardHolderId?.message,
      blockedUntill: cardHolderId ? cardHolderId?.blockedUntill : data?.card?.blockedUntill,
      deletedAt: cardHolderId ? cardHolderId?.deletedAt : data?.card?.deletedAt,
      status: cardHolderId ? cardHolderId?.status : data?.card?.status,
      contacts: cardHolderId
        ? cardHolderId?.card?.event?.EventContact
        : data?.card?.event?.EventContact,
    }),
    [cardHolderId, data],
  );

  const handleComponentClick = id => {
    if (openDropdownIds.includes(id)) {
      setOpenDropdownIds(openDropdownIds.filter(item => item !== id));
    } else {
      setOpenDropdownIds([...openDropdownIds, id]);
    }
  };

  const onClickButtonBlockClubCard = async holderId => {
    try {
      setDisabledButtonBlock(true);
      const jsonData = {
        status: clubCardData?.status === 'BLOCKED' ? 'ACTIVE' : 'BLOCKED',
        openUntill: clubCardData?.openUntill,
        blockedUntill:
          clubCardData?.status === 'BLOCKED'
            ? null
            : `${endDate?.year}-${
                selectionsDate?.month?.indexOf(endDate?.month) > 9
                  ? selectionsDate?.month?.indexOf(endDate?.month) + 1
                  : `0${selectionsDate?.month?.indexOf(endDate?.month) + 1}`
              }-${endDate?.day.length > 1 ? endDate?.day : `0${endDate?.day}`}T${
                endTime?.hour
              }:00.000Z`,
      };
      const { payload } = await dispatch(cardHolderBlock({ holderId, data: jsonData }));
      if (payload?.status === 200) {
        setCardHolderId({
          ...cardHolderId,
          status: payload?.data?.status,
          openUntill: payload?.data?.openUntill,
          blockedUntill: payload?.data?.blockedUntill,
        });
        setShowAlertBlockCard(false);
        setDisabledButtonBlock(false);
      }
    } catch (error) {
      console.error('Error blocking card:', error);
    } finally {
      setDisabledButtonBlock(false);
    }
  };

  const onClickButtonDeleteClubCard = async cardId => {
    console.log('');
    try {
      setDisabledButtonDelete(true);
      await restoreDeleteCardHolder({ cardId });
      const response = await getCardHolderCards();
      if (response?.data.length > 0) {
        setCards(response.data || []);
      }
    } catch (error) {
      console.error('Error deleting card:', error);
    } finally {
      setDisabledButtonDelete(false);
      setShowAlertDeleteCard(false);
      setShowCardTypeView(false);
    }
  };
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <div className="fixed top-0 z-[102] h-full w-full max-w-[768px] bg-white">
        <div className="scrollable-container h-full">
          <div className="global-container">
            <div className="scrollable-container max-h-full">
              <BackButton
                onClick={() => {
                  setShowCardTypeView(false);
                }}
              />
              <div
                className={`container ${clubCardData?.status === 'BLOCKED' && 'opacity-30'}`}
                style={{ paddingTop: px(10) }}
              >
                <div
                  className=" flex flex-col overflow-hidden bg-white"
                  style={{
                    boxShadow: '0px 6px 7.5px 0 #00000066',
                    borderRadius: px(10),
                    marginBottom: px(15),
                  }}
                >
                  <div
                    style={{ height: px(204), width: px(290), borderRadius: px(10) }}
                    className="relative overflow-hidden"
                  >
                    <div className="absolute z-[2] flex h-full w-full items-center justify-center overflow-hidden">
                      <div
                        style={{
                          width: px(65),
                          height: px(65),
                          boxShadow: '0 4px 6px 0 #00000029',
                          borderRadius: px(10),
                          overflow: 'hidden',
                        }}
                      >
                        {clubCardData?.logoUrl && (
                          <img
                            className="h-full w-full object-cover"
                            src={`${process.env.REACT_APP_STATIC_URL}${clubCardData?.logoUrl}`}
                            alt="avatarImage"
                          />
                        )}
                      </div>
                    </div>
                    <div className="absolute z-[2] h-full w-full">
                      <div className="relative h-full w-full">
                        <p
                          className="absolute font-[400] text-[#FA0202]"
                          style={{
                            fontSize: px(32),
                            lineHeight: px(43),
                            top: px(0),
                            right: px(12),
                          }}
                        >
                          {clubCardData?.cardDiscount}
                        </p>
                        <div
                          className="absolute z-[2]"
                          style={{ bottom: px(10), left: px(20), width: px(250) }}
                        >
                          <h3
                            className="font-[400] uppercase text-white"
                            style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
                          >
                            {clubCardData?.profileName}
                          </h3>
                          <div className="flex w-full items-center justify-between">
                            <p
                              className="font-[400] text-white"
                              style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
                            >
                              {format2DigitDate(clubCardData?.openUntill) || ''}
                            </p>
                            <p
                              className="font-[400] text-white"
                              style={{ fontSize: px(20), lineHeight: px(26) }}
                            >
                              {clubCardData?.cardName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {clubCardData?.imageUrl && (
                      <img
                        className="absolute z-[1] h-full w-full object-cover"
                        src={`${process.env.REACT_APP_STATIC_URL}${clubCardData?.imageUrl}`}
                        alt="card-type-view"
                      />
                    )}
                  </div>
                  <div
                    className="relative flex flex-col items-center justify-center"
                    style={{ paddingTop: px(10), paddingBottom: px(10) }}
                  >
                    <img src={qrCode} alt="qr-code" style={{ width: px(118), height: px(118) }} />
                    <p className="font-[400]" style={{ fontSize: px(15), lineHeight: px(20) }}>
                      {clubCardData?.cardIdToShow?.toString()}
                    </p>
                    <div className="absolute" style={{ right: px(10), bottom: px(10) }}>
                      <CircleButton
                        onClick={() => setShowInfo(!showInfo)}
                        icon={{
                          type: TypesIcon.ARROWTOP,
                          viewBox: '0 0 14 21',
                          width: px(14),
                          height: px(21),
                          className: `${showInfo ? '' : 'rotate-[180deg]'}`,
                        }}
                        className="text-[#0A84FE]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showInfo && (
                <div
                  className=" footer__dropdowns "
                  style={{
                    background: 'white',
                  }}
                >
                  {cardHolderId ? (
                    <CardInformation
                      data={[
                        {
                          id: 1,
                          title: { label: 'Имя владельца' },
                          value: clubCardData?.holderId,
                          disabled: clubCardData?.status === 'BLOCKED',
                        },
                        {
                          id: 2,
                          title: { label: 'Статус карты' },
                          value: clubCardData?.cardDiscount,
                          disabled: clubCardData?.status === 'BLOCKED',
                        },
                        {
                          id: 3,
                          title: { label: 'Вид карты. Название' },
                          value: clubCardData?.cardName,
                          disabled: clubCardData?.status === 'BLOCKED',
                        },
                      ]}
                    />
                  ) : (
                    <CardInformation
                      data={[
                        {
                          id: 1,
                          title: { label: 'Имя карты' },
                          value: clubCardData?.cardName,
                        },
                        {
                          id: 2,
                          title: { label: 'Статус карты' },
                          value: clubCardData?.cardDiscount,
                        },
                        {
                          id: 3,
                          title: { label: 'Срок действия' },
                          value: format2DigitDate(clubCardData?.openUntill),
                        },
                        {
                          id: 4,
                          title: { label: 'Условия получения' },
                          value: clubCardData?.receiptConditions,
                        },
                      ]}
                    />
                  )}

                  {!cardHolderId && (
                    <CardInformation
                      handleComponentClick={handleComponentClick}
                      data={[
                        {
                          id: 1,
                          title: { label: 'Общая информация' },
                          text: clubCardData?.generalInformation,
                          disabled: clubCardData?.generalInformation === '',
                        },
                        {
                          id: 2,
                          title: { label: 'Где применяется' },
                          text: clubCardData?.applicationScope,
                          disabled: clubCardData?.applicationScope === '',
                        },
                        {
                          id: 3,
                          title: { label: 'Соглашение' },
                          text: clubCardData?.agreement,
                          disabled: clubCardData?.agreement === '',
                        },
                        {
                          id: 4,
                          title: { label: 'Регламент использования' },
                          text: clubCardData?.useTerms,
                          disabled: clubCardData?.useTerms === '',
                        },
                        {
                          id: 5,
                          title: { label: 'Обслуживание клиентов' },
                          disabled:
                            event?.EventContact?.email === '' &&
                            event?.EventContact?.mainPhone === '' &&
                            event?.EventContact?.secondaryPhone === '',
                          text: [
                            {
                              label: 'Email:',
                              value: clubCardData?.contacts?.email,
                            },
                            {
                              value: clubCardData?.contacts?.mainPhone,
                            },
                            {
                              value: clubCardData?.contacts?.secondaryPhone,
                            },
                          ],
                        },
                      ]}
                      dropDown
                    />
                  )}
                  {cardHolderId && (
                    <CardInformation
                      data={[
                        {
                          id: 4,
                          title: { label: 'Допуск' },
                          text: (
                            <div>
                              <p
                                style={{
                                  fontSize: px(32),
                                  lineHeight: px(32),
                                  paddingBottom: px(10),
                                }}
                                className="whitespace-pre-wrap font-light"
                              >
                                {`Заблокировать\nвременно`}
                              </p>
                              <p
                                style={{
                                  fontSize: px(15),
                                  lineHeight: px(20),
                                }}
                                className="font-normal"
                              >
                                Вы можете временно приостановить действие клубной карты, услуг или
                                бронирование по ней
                              </p>
                              <div
                                style={{
                                  paddingTop: px(20),
                                  paddingBottom: px(15),
                                }}
                                className="flex w-full items-center justify-between"
                              >
                                <div>
                                  <p
                                    className="font-normal"
                                    style={{
                                      fontSize: px(20),
                                      lineHeight: px(26),
                                    }}
                                  >
                                    Заблокировать
                                  </p>
                                  <p
                                    className="font-normal"
                                    style={{
                                      fontSize: px(15),
                                      lineHeight: px(20),
                                    }}
                                  >
                                    Указать период
                                  </p>
                                </div>
                                <Switch toggle={toggle} setToggle={setToggle} />
                              </div>
                              {toggle && (
                                <div
                                  style={{
                                    gap: px(14),
                                  }}
                                  className="flex"
                                >
                                  {blockTheAccountPageTimeData.map(item => (
                                    <div key={item?.id} className="flex flex-col">
                                      <p
                                        style={{
                                          fontSize: px(15),
                                          lineHeight: px(20),
                                        }}
                                        className="font-normal  opacity-50"
                                      >
                                        {item?.title}
                                      </p>
                                      <div
                                        style={{
                                          gap: px(10),
                                        }}
                                        className="flex flex-row"
                                      >
                                        {item?.data?.map(el => (
                                          <div key={el?.id} disabled={el?.id === 0}>
                                            <p
                                              style={{
                                                fontSize: px(15),
                                                lineHeight: px(20),
                                                paddingBottom: px(20),
                                              }}
                                              className="font-normal opacity-50"
                                            >
                                              {el?.subTitle}
                                            </p>
                                            <p
                                              aria-hidden
                                              style={{
                                                fontSize: px(20),
                                                lineHeight: px(26),
                                              }}
                                              className={`font-normal ${
                                                el?.id === 1 || el?.id === 2
                                                  ? 'text-[#000]'
                                                  : 'text-[#0A84FE]'
                                              }`}
                                              onClick={() => {
                                                el?.id !== 1 && el?.id !== 2 && openPicker(el?.id);
                                              }}
                                            >
                                              {el?.id === 1
                                                ? `${time?.hour}`
                                                : el?.id === 3
                                                  ? `${endTime?.hour}`
                                                  : el?.id === 2
                                                    ? `${
                                                        +startDate?.day < 10 &&
                                                        startDate?.day.length < 2
                                                          ? `0${startDate?.day}`
                                                          : startDate?.day
                                                      }.${
                                                        selectionsDate?.month?.indexOf(
                                                          +startDate?.month,
                                                        ) +
                                                          1 <
                                                        10
                                                          ? startDate?.month.length === 2
                                                            ? startDate?.month
                                                            : `0${
                                                                selectionsDate?.month?.indexOf(
                                                                  startDate?.month,
                                                                ) + 1
                                                              }`
                                                          : selectionsDate?.month?.indexOf(
                                                              startDate?.month,
                                                            ) + 1
                                                      }.${String(startDate?.year)?.slice(2, 4)}`
                                                    : `${
                                                        +endDate?.day < 10 &&
                                                        endDate?.day.length < 2
                                                          ? `0${endDate?.day}`
                                                          : endDate?.day
                                                      }.${
                                                        selectionsDate?.month?.indexOf(
                                                          endDate?.month,
                                                        ) +
                                                          1 <
                                                        10
                                                          ? endDate?.month.length === 2
                                                            ? endDate?.month
                                                            : `0${
                                                                selectionsDate?.month?.indexOf(
                                                                  endDate?.month,
                                                                ) + 1
                                                              }`
                                                          : selectionsDate?.month?.indexOf(
                                                              endDate?.month,
                                                            ) + 1
                                                      }.${String(endDate?.year)?.slice(2, 4)}`}
                                            </p>
                                            <hr
                                              className="text-black opacity-30"
                                              style={{
                                                marginTop: px(5),
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ),
                          disabled: clubCardData?.status === 'BLOCKED',
                        },
                        {
                          id: 5,
                          title: { label: 'Комментарий' },
                          text: clubCardData?.message,
                          disabled: clubCardData?.message === '',
                        },
                      ]}
                      dropDown
                    />
                  )}
                  {cardHolderId && (
                    <Button
                      onClick={() => {
                        setShowAlertBlockCard(true);
                      }}
                      className="w-full"
                      height={60}
                      borderRadius={0}
                      marginTop={20}
                      marginBottom={cardHolderId && 20}
                      bgColor="#0A84FE"
                      color="#ffffff"
                      disabled={disabledButtonBlock && !toggle}
                    >
                      {clubCardData?.status === 'BLOCKED' ? 'Восстановить' : 'Заблокировать карту'}
                    </Button>
                  )}
                  {!removeDeleteButton && !cardHolderId && !clubCardData?.deletedAt && (
                    <Button
                      onClick={() => {
                        setShowAlertDeleteCard(true);
                      }}
                      className="w-full"
                      height={60}
                      borderRadius={0}
                      marginTop={20}
                      marginBottom={20}
                      bgColor="#FA0303"
                      color="#ffffff"
                      disabled={disabledButtonDelete}
                    >
                      Удалить карту
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          {cardHolderId && (
            <DatePicker
              singleItem={id === 1 || id === 3}
              title="Календарь"
              setShow={setShow}
              show={show}
              isDate={id === 2 || id === 4}
              data={
                id === 2 || id === 4
                  ? {
                      ...selectionsDate,
                      day: selectionsDate?.day?.slice(
                        0,
                        1 +
                          getDaysInMonth(
                            startDate?.year,
                            selectionsDate?.month?.indexOf(startDate?.month) + 1,
                          ),
                      ),
                    }
                  : timeDate
              }
              setPickerValue={
                id === 1
                  ? setTime
                  : id === 2
                    ? setStartDate
                    : id === 3
                      ? setEndTime
                      : id === 4
                        ? setEndDate
                        : () => {}
              }
              pickerValue={
                id === 1
                  ? time
                  : id === 2
                    ? startDate
                    : id === 3
                      ? endTime
                      : id === 4
                        ? endDate
                        : ''
              }
              buttonTitle="Применить"
              isLine
            />
          )}
        </div>
      </div>
      {showAlertBlockCard && (
        <CustomAlert
          onClickButtonSubmit={() => onClickButtonBlockClubCard(cardHolderId?.id)}
          onClickButtonClose={() => setShowAlertBlockCard(false)}
          title={`${
            clubCardData?.status === 'BLOCKED'
              ? 'Восстановить клубную карту?'
              : 'Приостановить действие клубной карты?'
          }`}
          buttonLeftText="Отмена"
          buttonRightText="Да"
        />
      )}
      {showAlertDeleteCard && (
        <CustomAlert
          onClickButtonSubmit={() => onClickButtonDeleteClubCard(clubCardData?.cardId)}
          onClickButtonClose={() => setShowAlertDeleteCard(false)}
          title="Подтвердить удаление клубной карты?"
          buttonLeftText="Отмена"
          buttonRightText="Да"
        />
      )}
    </>
  );
};

export default ClubCardViewModal;
