import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/profile';
import { createRequest } from '@/helpers/api/createRequest';

import { addModal } from '../../modal/addModal';

export const EMAIL_END = 'profile/emailEnd';

const onFail = error => async dispatch => {
  await dispatch(
    addModal({
      id: 5,
      data: {
        title: 'Вы ввели не верный пароль',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

const onSuccess = () => async dispatch => {
  dispatch(
    addModal({
      id: 11,
      data: {
        title: 'Ваша электронная почта успешно зарегистрирована',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'profile',
    }),
  );
};

export const emailEnd = createAsyncThunk(
  EMAIL_END,
  createRequest({
    type: EMAIL_END,
    loader: data => authApi.emailEnd(data),
    onSuccess,
    onFail,
  }),
);
