import React, { useEffect, useRef, useState } from 'react';

import { px } from '../constants/Px';
import { CircleButton, Icon, ImageLoaderModal } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Checkbox } from './checkbox';

export const SelectPhotoForCard = ({ selectedOption, handleOptionChange, image, onChange }) => {
  const [showCardLoaderModal, setShowCardLoaderModal] = useState(false);

  return (
    <div>
      <div
        style={{
          marginBottom: px(31),
        }}
        className="flex flex-col "
      >
        <div className="flex ">
          <div
            style={{
              width: px(163),
            }}
            className="flex flex-col"
          >
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                paddingBottom: px(4),
              }}
              className="font-normal"
            >
              Загрузить фото
            </p>
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal"
            >
              Без логотипа
            </p>
          </div>
          <div className="flex flex-1 items-center  justify-between">
            <Checkbox
              margin
              checked={selectedOption === 'logo'}
              value="logo"
              onChange={e => handleOptionChange(e)}
              style={{
                margin: '0px',
              }}
            />
            {selectedOption === 'logo' && (
              <CircleButton
                onClick={() => setShowCardLoaderModal(true)}
                icon={{
                  type: TypesIcon.GALLERY,
                  viewBox: '0 0 26.827 23.512',
                  width: px(26.827),
                  height: px(23.512),
                }}
                className="text-[#0A84FE]"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex  flex-col ">
        <div className="flex items-center ">
          <div
            style={{
              width: px(163),
            }}
            className="flex flex-col"
          >
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                paddingBottom: px(4),
              }}
              className="font-normal"
            >
              Загрузить фото
            </p>
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal"
            >
              С моим логотипом
            </p>
          </div>

          <div className="flex flex-1 items-center  justify-between">
            <Checkbox
              margin
              checked={selectedOption === 'noLogo'}
              value="noLogo"
              onChange={e => handleOptionChange(e)}
              style={{
                margin: '0px',
              }}
            />
            {selectedOption === 'noLogo' && (
              <CircleButton
                onClick={() => setShowCardLoaderModal(true)}
                icon={{
                  type: TypesIcon.GALLERY,
                  viewBox: '0 0 26.827 23.512',
                  width: px(26.827),
                  height: px(23.512),
                }}
                className="text-[#0A84FE]"
              />
            )}
            <ImageLoaderModal
              show={showCardLoaderModal}
              onClose={() => setShowCardLoaderModal(false)}
              items={[
                {
                  label: 'Фото',
                  key: 'card',
                  image: image
                    ? {
                        src: image,
                      }
                    : undefined,
                  crop: { width: 290, height: 201, style: { borderRadius: px(15) } },
                },
              ]}
              onChange={files => {
                if (onChange && files?.card?.src) {
                  onChange(files?.card?.src);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPhotoForCard;
