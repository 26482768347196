/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-shadow */
/* eslint-disable unicorn/no-useless-fallback-in-spread */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-unused-expressions */
/* eslint-disable unicorn/no-null */
/* eslint-disable import/no-unresolved */
import 'swiper/scss';

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { TypesIcon } from '../../../types/TypesIcon';
import { px } from '../../utils';
import { CircleButton } from '../circle-button';
import { ImageLoaderModal } from '../image-loader-modal';
import emptyImage from '../image-loader-modal/images/empty.png';

export const DynamicImageSlider = ({
  items: defaultItems,
  onChange,
  label,
  crop: defaultCrop,
  onAdd,
  onEdit,
  onSwipe,
  withText,
  clearText,
  onController,
}) => {
  const [slider, setSlider] = useState();
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [items, setItems] = useState();
  const [imageLoaderController, setImageLoaderController] = useState();
  const [isStatic, setIsStatic] = useState(false);

  useLayoutEffect(() => {
    const stat = defaultItems?.[0]?.key !== undefined;

    setIsStatic(stat);

    setItems(
      (stat ? defaultItems : defaultItems?.map((item, index) => ({ ...item, key: index }))) || [],
    );
  }, [JSON.stringify(defaultItems)]);

  const handleAddImage = useCallback(() => {
    if (onAdd) {
      onAdd();
      return;
    }
    if (onEdit) {
      onEdit();
      return;
    }

    const newItems = [...items, undefined];

    console.log('NEW ITEMS', newItems, imageLoaderController);

    setItems(newItems);
    imageLoaderController?.setCurrentKey(newItems.length - 1 || 0);

    setShowLoaderModal(true);
    setTimeout(() => slider?.slideTo?.(items?.length), 0);
  }, [imageLoaderController?.setCurrentKey, JSON.stringify(items), slider?.slides?.length]);

  useEffect(() => {
    if (onController && slider)
      onController({
        addImage: handleAddImage,
        slider,
        items,
        setCurrentKey: imageLoaderController?.setCurrentKey,
      });
  }, [
    handleAddImage,
    slider?.slides?.length,
    onController,
    JSON.stringify(items),
    imageLoaderController?.setCurrentKey,
  ]);

  return (
    <>
      <Swiper
        onSwiper={setSlider}
        onRealIndexChange={element => {
          if (onSwipe) onSwipe(element.activeIndex);
        }}
        spaceBetween={0}
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        className="home_page-hero"
      >
        {items?.length ? (
          items?.map(item => {
            const crop = item?.crop ?? defaultCrop;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={item?.key}>
                <div
                  className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
                  style={{ height: px(290) }}
                >
                  <img
                    src={item?.src || emptyImage}
                    className="slider__image"
                    alt={`slider-${item?.key}`}
                    aria-hidden
                    style={{
                      width: crop?.width ? px(crop?.width) : px(320),
                      height: crop?.height ? px(crop?.height) : px(290),
                      ...crop?.style,
                      overflow: 'hidden',
                      isolation: 'isolate',
                    }}
                  />
                  <div className="absolute flex items-center justify-center">
                    {!item?.src && (
                      <div
                        className="absolute"
                        style={{
                          background: '#ffffff60',
                          width: px(crop?.width),
                          height: px(crop?.height),
                          ...crop?.style,
                        }}
                      />
                    )}
                    {withText ? (
                      <div className="relative flex" style={{ gap: px(10) }}>
                        {item?.src && (
                          <>
                            {(!isStatic || !item?.src) && (
                              <CircleButton
                                onClick={() => {
                                  if (item?.onAdd) {
                                    item.onAdd();
                                    return;
                                  }

                                  if (onAdd) {
                                    onAdd();
                                    return;
                                  }

                                  if (isStatic) {
                                    if (item?.onEdit) {
                                      item.onEdit();
                                      return;
                                    }
                                    if (onEdit) {
                                      onEdit();
                                      return;
                                    }
                                    imageLoaderController?.setCurrentKey(item?.key);
                                    setShowLoaderModal(true);
                                    return;
                                  }
                                  handleAddImage();
                                }}
                                icon={{
                                  type: TypesIcon.ADDPLUS,
                                  viewBox: '0 0 26.95 26.95',
                                  width: px(26.95),
                                  height: px(26.95),
                                  fill: '#0A84FE',
                                }}
                              />
                            )}
                            {item?.src && (
                              <CircleButton
                                onClick={() => {
                                  if (item?.onEdit) {
                                    item.onEdit();
                                    return;
                                  }
                                  if (onEdit) {
                                    onEdit();
                                    return;
                                  }
                                  imageLoaderController?.setCurrentKey(item?.key);
                                  setShowLoaderModal(true);
                                }}
                                icon={{
                                  type: TypesIcon.PENSIL,
                                  viewBox: '0 0 18.51 23.873',
                                  width: px(18.51),
                                  height: px(23.873),
                                  fill: '#0A84FE',
                                }}
                              />
                            )}
                            <CircleButton
                              onClick={clearText}
                              icon={{
                                type: TypesIcon.ROUNDEDRETURN,
                                viewBox: '0 0 28.451 26.164',
                                width: px(28.451),
                                height: px(26.164),
                                fill: '#0A84FE',
                              }}
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="relative flex" style={{ gap: px(10) }}>
                        {item?.src && (
                          <CircleButton
                            onClick={() => {
                              if (item?.onEdit) {
                                item.onEdit();
                                return;
                              }
                              if (onEdit) {
                                onEdit();
                                return;
                              }
                              imageLoaderController?.setCurrentKey(item?.key);
                              setShowLoaderModal(true);
                            }}
                            icon={{
                              type: TypesIcon.PENSIL,
                              viewBox: '0 0 18.51 23.873',
                              width: px(18.51),
                              height: px(23.873),
                              fill: '#0A84FE',
                            }}
                          />
                        )}
                        {(!isStatic || !item?.src) && (
                          <CircleButton
                            onClick={() => {
                              if (item?.onAdd) {
                                item.onAdd();
                                return;
                              }

                              if (onAdd) {
                                onAdd();
                                return;
                              }

                              if (isStatic) {
                                if (item?.onEdit) {
                                  item.onEdit();
                                  return;
                                }
                                if (onEdit) {
                                  onEdit();
                                  return;
                                }
                                imageLoaderController?.setCurrentKey(item?.key);
                                setShowLoaderModal(true);
                                return;
                              }
                              handleAddImage();
                            }}
                            icon={{
                              type: TypesIcon.ADDPLUS,
                              viewBox: '0 0 26.95 26.95',
                              width: px(26.95),
                              height: px(26.95),
                              fill: '#0A84FE',
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide>
            <div
              className="relative flex w-full items-center justify-center bg-[#B6DAFD]"
              style={{ height: px(290) }}
            >
              <div className="absolute flex" style={{ gap: px(10) }}>
                {withText ? (
                  <>
                    <CircleButton
                      onClick={handleAddImage}
                      icon={{
                        type: TypesIcon.ADDPLUS,
                        viewBox: '0 0 26.95 26.95',
                        width: px(26.95),
                        height: px(26.95),
                        fill: '#0A84FE',
                      }}
                    />
                    <CircleButton
                      onClick={clearText}
                      icon={{
                        type: TypesIcon.ROUNDEDRETURN,
                        viewBox: '0 0 28.451 26.164',
                        width: px(28.451),
                        height: px(26.164),
                        fill: '#0A84FE',
                      }}
                    />
                  </>
                ) : (
                  <CircleButton
                    onClick={handleAddImage}
                    icon={{
                      type: TypesIcon.ADDPLUS,
                      viewBox: '0 0 26.95 26.95',
                      width: px(26.95),
                      height: px(26.95),
                      fill: '#0A84FE',
                    }}
                  />
                )}
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      <ImageLoaderModal
        onController={setImageLoaderController}
        show={showLoaderModal}
        onClose={() => setShowLoaderModal(false)}
        onChange={v => {
          const mergedItems = Object?.values?.(v)?.map?.((vitem, index) => {
            const vkey = Object?.keys?.(v)?.[index];

            return {
              ...items?.find?.(i => i?.key === vkey),
              src: vitem?.src,
              first: vitem?.first,
              key: vkey,
            };
          });

          const newItems = (
            isStatic ? mergedItems : mergedItems?.filter(item => item?.src)
          )?.sort?.((a, b) => Number(b.first) - Number(a.first));

          setItems(newItems);
          if (onChange) onChange(newItems);
        }}
        canBeFirst={!isStatic}
        items={items?.map((item, key) => ({
          label: item?.label ?? label ?? 'Фото',
          key: item?.key ?? key,
          image: {
            src: item?.src,
            first: item?.first,
          },
          crop: item?.crop ??
            defaultCrop ?? {
              width: 320,
              height: 290,
            },
        }))}
      />
    </>
  );
};

export default DynamicImageSlider;
