import { request } from '@/helpers/api/request';

export const search = async (data) => {

  const response = await request({
    url: `search`,
    method: 'GET',
    params:{term:data}
  });
  return response;
};
