import React, { useState } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const AvatarFilter = ({ sectorValue, selectedOptionsObjectFilters }) =>
  selectedOptionsObjectFilters[1].includes('free') ? (
    <div
      className="m-auto rounded-[15%] border-[1px] border-solid border-[black] bg-[#212B35]"
      style={{
        width: px(90),
        height: px(90),
        marginTop: px(20),
        marginBottom: px(20),
      }}
    >
      <div
        className="flex w-full items-center justify-center"
        style={{
          paddingTop: px(5.5),
          paddingBottom: px(7.2),
        }}
      >
        <Icon type={TypesIcon.FREEZONEAVATAR} viewBox="0 0 36 35" width={px(36)} height={px(35)} />
      </div>
      <div
        className="ml-auto mr-auto flex bg-[#fff] opacity-[0.5]"
        style={{
          width: px(37.63),
          height: px(4.55),
          marginBottom: px(3.95),
        }}
      />
      {/* <p
        className="flex items-center justify-center text-[#0A84FE]"
        style={{
          fontSize: px(20),
          fontWeight: 400,
          lineHeight: px(26),
          paddingBottom: px(9),
        }}
      >
        10650
      </p> */}
    </div>
  ) : (selectedOptionsObjectFilters[1].length > 0 ||
      selectedOptionsObjectFilters[2].length > 0 ||
      selectedOptionsObjectFilters[3].length > 0) &&
    selectedOptionsObjectFilters[4].length <= 0 ? (
    <div
      className="m-auto rounded-[15%] border-[1px] border-solid border-[black] bg-[#212B35]"
      style={{
        width: px(90),
        height: px(90),
        marginTop: px(20),
        marginBottom: px(20),
      }}
    >
      <div
        className="flex w-full items-center justify-between"
        style={{
          paddingTop: px(6.2),
          paddingRight: px(5),
          paddingLeft: px(5),
        }}
      >
        <Icon
          type={TypesIcon.SHORELANDSCAPEAVATAR}
          viewBox="0 0 31 29"
          width={px(31)}
          height={px(29)}
        />
        <p
          className="text-[#fff] opacity-[0.5]"
          style={{ fontSize: px(20), fontWeight: 400, lineHeight: px(22), marginTop: px(6.8) }}
        >
          {sectorValue}
        </p>
      </div>
      <p
        className="ml-auto mr-auto flex items-center justify-center text-[#fff] opacity-[0.5]"
        style={{
          fontSize: px(15),
          fontWeight: 400,
          lineHeight: px(20),
        }}
      >
        сектор
      </p>
      {/* <p
        className="flex items-center justify-center text-[#0A84FE]"
        style={{
          fontSize: px(20),
          fontWeight: 400,
          lineHeight: px(26),
          paddingBottom: px(9),
        }}
      >
        12470
      </p> */}
    </div>
  ) : selectedOptionsObjectFilters[4].length > 0 ? (
    <div
      className="m-auto rounded-[15%] border-[1px] border-solid border-[black] bg-[#212B35]"
      style={{
        width: px(90),
        height: px(90),
        marginTop: px(20),
        marginBottom: px(20),
      }}
    >
      <div
        className="flex w-full items-center justify-between"
        style={{
          paddingTop: px(6.2),
          paddingRight: px(5),
          paddingLeft: px(5),
        }}
      >
        {selectedOptionsObjectFilters[4].includes('house') ? (
          <Icon type={TypesIcon.HOUSEAVATAR} viewBox="0 0 27 30" width={px(27)} height={px(30)} />
        ) : selectedOptionsObjectFilters[4].includes('alcove') ? (
          <Icon type={TypesIcon.ALCOVEAVATAR} viewBox="0 0 35 27" width={px(35)} height={px(27)} />
        ) : selectedOptionsObjectFilters[4].includes('tent') ? (
          <Icon type={TypesIcon.CANOPYAVATAR} viewBox="0 0 35 27" width={px(35)} height={px(27)} />
        ) : (
          <Icon
            type={TypesIcon.BATHHOUSEAVATAR}
            viewBox="0 0 36 33"
            width={px(36)}
            height={px(33)}
          />
        )}
        <p
          className="text-[#fff] opacity-[0.5]"
          style={{ fontSize: px(20), fontWeight: 400, lineHeight: px(22), marginTop: px(6.8) }}
        >
          {sectorValue}
        </p>
      </div>
      <p
        className="ml-auto mr-auto flex items-center justify-center text-[#fff] opacity-[0.5]"
        style={{
          fontSize: px(15),
          fontWeight: 400,
          lineHeight: px(20),
        }}
      >
        сектор
      </p>
      {/* <p
      className="flex items-center justify-center text-[#0A84FE]"
      style={{
        fontSize: px(20),
        fontWeight: 400,
        lineHeight: px(26),
        paddingBottom: px(9),
      }}
    >
      12470
    </p> */}
    </div>
  ) : (
    <div
      className="m-auto rounded-[15%] border-[1px] border-solid border-[black]"
      style={{
        width: px(90),
        height: px(90),
        marginTop: px(20),
        marginBottom: px(20),
      }}
    />
  );

export default AvatarFilter;
