import React from 'react';

export const CanopyIcon = props => (
  <svg
    viewBox="0 0 35 27"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4031_1887)">
      <g opacity="0.5">
        <path
          d="M21.5939 15.4651H12.5479C11.9033 15.4651 11.3809 15.9876 11.3809 16.6321C11.3809 17.2766 11.9033 17.7991 12.5479 17.7991H21.5939C22.2384 17.7991 22.7609 17.2766 22.7609 16.6321C22.7609 15.9876 22.2384 15.4651 21.5939 15.4651Z"
          fill="currentColor"
        />
        <path
          d="M21.5939 15.9651H12.5479C12.1795 15.9651 11.8809 16.2637 11.8809 16.6321C11.8809 17.0005 12.1795 17.2991 12.5479 17.2991H21.5939C21.9622 17.2991 22.2609 17.0005 22.2609 16.6321C22.2609 16.2637 21.9622 15.9651 21.5939 15.9651Z"
          stroke="currentColor"
        />
        <path
          d="M24.0241 24.552L19.1344 17.0245C18.797 16.5051 18.1024 16.3575 17.5829 16.6949L17.5821 16.6955C17.0626 17.0329 16.9151 17.7275 17.2525 18.2469L22.1422 25.7744C22.4797 26.2939 23.1743 26.4414 23.6937 26.104L23.6945 26.1035C24.214 25.7661 24.3615 25.0714 24.0241 24.552Z"
          fill="currentColor"
        />
        <path
          d="M23.6051 24.8245L18.7154 17.297C18.5285 17.0093 18.1437 16.9275 17.8559 17.1144L17.8551 17.115C17.5673 17.3019 17.4856 17.6867 17.6725 17.9745L22.5623 25.502C22.7492 25.7897 23.134 25.8715 23.4217 25.6845L23.4226 25.684C23.7103 25.4971 23.7921 25.1123 23.6051 24.8245Z"
          stroke="currentColor"
          strokeWidth="1.00047"
        />
        <path
          d="M14.8386 17.021L9.94884 24.5485C9.61142 25.0679 9.75898 25.7625 10.2784 26.1L10.2792 26.1005C10.7987 26.4379 11.4933 26.2904 11.8307 25.7709L16.7205 18.2434C17.0579 17.724 16.9103 17.0294 16.3909 16.692L16.39 16.6914C15.8706 16.354 15.176 16.5016 14.8386 17.021Z"
          fill="currentColor"
        />
        <path
          d="M15.2575 17.2935L10.3677 24.821C10.1808 25.1088 10.2626 25.4936 10.5503 25.6805L10.5512 25.681C10.8389 25.8679 11.2237 25.7862 11.4106 25.4985L16.3004 17.9709C16.4873 17.6832 16.4055 17.2984 16.1178 17.1115L16.1169 17.1109C15.8292 16.924 15.4444 17.0058 15.2575 17.2935Z"
          stroke="currentColor"
          strokeWidth="1.00047"
        />
        <path
          d="M24.9489 20.134H8.89991C8.33602 20.134 7.87891 20.5912 7.87891 21.155V21.156C7.87891 21.7199 8.33602 22.177 8.89991 22.177H24.9489C25.5128 22.177 25.9699 21.7199 25.9699 21.156V21.155C25.9699 20.5912 25.5128 20.134 24.9489 20.134Z"
          fill="currentColor"
        />
        <path
          d="M24.9489 20.634H8.89991C8.61217 20.634 8.37891 20.8673 8.37891 21.155V21.156C8.37891 21.4438 8.61217 21.677 8.89991 21.677H24.9489C25.2366 21.677 25.4699 21.4438 25.4699 21.156V21.155C25.4699 20.8673 25.2366 20.634 24.9489 20.634Z"
          stroke="currentColor"
        />
        <path
          d="M28.9944 26.1881C28.6518 26.1881 28.373 25.9093 28.373 25.5667V12.8629L29.6158 13.524V25.5667C29.6158 25.9093 29.3371 26.1881 28.9944 26.1881Z"
          fill="currentColor"
        />
        <path
          d="M28.9944 25.6881C29.0602 25.6881 29.1158 25.6325 29.1158 25.5667V13.8244L28.873 13.6952V25.5667C28.873 25.6325 28.9286 25.6881 28.9944 25.6881ZM28.9944 26.6881C28.3761 26.6881 27.873 26.185 27.873 25.5667V12.0306L30.1158 13.2236V25.5667C30.1158 26.185 29.6128 26.6881 28.9944 26.6881ZM29.738 9.03104L29.2647 8.77942C29.4411 8.82301 29.6049 8.9101 29.7392 9.02941L29.738 9.03104Z"
          fill="currentColor"
        />
        <path
          d="M5.58594 12.8624L5.58594 25.5666C5.58594 25.9092 5.30718 26.188 4.96454 26.188C4.6219 26.188 4.34314 25.9092 4.34314 25.5666L4.34314 13.5232L5.58594 12.8624Z"
          fill="currentColor"
        />
        <path
          d="M5.08594 13.6946L4.84314 13.8237L4.84314 25.5666C4.84314 25.6324 4.89873 25.688 4.96454 25.688C5.03035 25.688 5.08594 25.6324 5.08594 25.5666L5.08594 13.6946ZM4.68611 8.78054L4.68683 8.78239L4.21457 9.03343C4.34984 8.91163 4.51329 8.82474 4.68611 8.78054ZM6.08594 12.0303L6.08594 25.5666C6.08594 26.1849 5.58288 26.688 4.96454 26.688C4.3462 26.688 3.84314 26.1849 3.84314 25.5666L3.84314 13.2228L6.08594 12.0303Z"
          fill="currentColor"
        />
        <path
          d="M32.6246 9.2915L17.9186 0.801C17.1524 0.35862 16.1726 0.621148 15.7303 1.38737C15.2879 2.1536 15.5504 3.13337 16.3166 3.57575L31.0226 12.0662C31.7888 12.5086 32.7686 12.2461 33.211 11.4799C33.6534 10.7136 33.3908 9.73388 32.6246 9.2915Z"
          fill="currentColor"
        />
        <path
          d="M32.3752 9.72448L17.6692 1.23398C17.1421 0.929675 16.4682 1.11026 16.1638 1.63734C15.8595 2.16442 16.0401 2.83839 16.5672 3.1427L31.2732 11.6332C31.8003 11.9375 32.4742 11.7569 32.7785 11.2298C33.0828 10.7028 32.9023 10.0288 32.3752 9.72448Z"
          stroke="currentColor"
        />
        <path
          d="M2.98868 12.0665L17.6947 3.57602C18.4609 3.13364 18.7234 2.15388 18.281 1.38765C17.8387 0.621427 16.8589 0.358899 16.0927 0.801279L1.38668 9.29178C0.620457 9.73416 0.357929 10.7139 0.800309 11.4802C1.24269 12.2464 2.22246 12.5089 2.98868 12.0665Z"
          fill="currentColor"
        />
        <path
          d="M2.7381 11.6335L17.4441 3.14304C17.9712 2.83873 18.1517 2.16476 17.8474 1.63768C17.5431 1.1106 16.8692 0.930012 16.3421 1.23432L1.6361 9.72482C1.10902 10.0291 0.928432 10.7031 1.23274 11.2302C1.53705 11.7573 2.21102 11.9378 2.7381 11.6335Z"
          stroke="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4031_1887">
        <rect width="34.013" height="26.71" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
