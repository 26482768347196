import { createReducer } from '@reduxjs/toolkit';

import { getSections } from '../../actions/section/thunks';

export const section = createReducer({}, builder => {
  builder.addCase(getSections.fulfilled, (state, { payload }) => ({
    ...state,
    sections: payload?.data,
    sectionsNumbers: payload?.data?.map(item => item.number),
  }));
});
