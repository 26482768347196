import { createReducer } from '@reduxjs/toolkit';

import { getGroup } from '../../actions/allow-group/thunks';

export const allowGroup = createReducer({}, builder => {
  builder.addCase(getGroup.fulfilled, (state, { payload }) => ({
    ...state,
    allowGroup: payload?.data,
  }));
});
