/* eslint-disable sonarjs/no-nested-template-literals */
import React, { useEffect, useState } from 'react';

import { StyledInput } from '../../../components/styled-input';
import { getData } from '../../../constants/Component';
import { saveEmptyFile } from '../../../constants/Function';
import { px } from '../../utils';
import { Button } from '../button';
import { Modal } from '../modal';

export const PickerInput = ({
  value: defaultValue,
  onChange,
  data,
  button,
  singleItem,
  isDocumentType,
  period,
  id,
  onClick,
  changeDocumentType,
  onClose,
  type,
  ...rest
}) => {
  const modifierData = type === 'date' ? getData(defaultValue) : data;
  const [fileType, setFileType] = useState('');

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // TODO: value doesnt go into columns

  const handleClick = () => {
    if (type === 'datePeriod') {
      if (id === 1) saveEmptyFile(fileType);
      onClick(value, true);

      if (id === 1) onClose();
    } else if (isDocumentType && value?.statistic === 'Выбрать период') {
      setFileType(value?.documentType);
    } else if (isDocumentType) {
      saveEmptyFile(value?.documentType);
      onClose();
    } else {
      if (onClick) onClick(value);
      onClose();
    }
  };

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  return (
    <Modal onClose={onClose} {...rest}>
      <div
        style={{
          padding: `${px(121)} ${px(15)} ${px(49)} ${px(15)}`,
        }}
      >
        <StyledInput
          numberType
          center
          placeholder={value || 0}
          onChange={e =>
            e.target.value.length < 7 &&
            setValue(
              e.target.value[0] === '0'
                ? e.target.value.slice(1)
                : +e.target.value.at(-1) || +e.target.value.at(-1) === 0
                  ? e.target.value
                  : e.target.value.slice(0, -1),
            )
          }
          value={value}
          textOpacity={50}
          placeholderOpacity={50}
          isSettingsProfilePage
        />

        {button && (
          <Button
            className="w-full border-[1px] border-solid border-[#dddddd]"
            marginTop={type === 'datePeriod' || isDocumentType ? 10 : 149}
            height={60}
            borderRadius={0}
            onClick={handleClick}
          >
            Применить
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default PickerInput;
