import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { acceptAdmins } from '../../../actions/profile-admin/thunks/accept-admins';
import { px } from '../../../constants/Px';
import { formatCreditCardNumber } from '../../../new-architecture/utils';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../Icon';
import { Popup } from '../../../new-architecture/shared';

export const NotificationTypeInvite = ({ item }) => {
  const dispatch = useDispatch();
  const [showOkPopup, setShowOkPopup] = useState(false);
  const notificationMessage = useMemo(
    () => ({
      message: item?.body?.message || '',
      profileName: `${item?.body?.name || ''} ${item?.body?.surname || ''}` || '',
      additionalLink: item?.additionalLink || '',
    }),
    [],
  );
  const handleAcceptInvite = async additionalLink => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${additionalLink}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('Запрос успешно выполнен');
      } else {
        console.error('Ошибка при выполнении запроса:', response.statusText);
      }
    } catch (error) {
      console.error('Произошла ошибка:', error.message);
    }
  };
  return (
    <div className="flex w-full flex-col items-start justify-start">
      <p className="w-full break-words">
        {notificationMessage?.message}&nbsp;{notificationMessage?.profileName}
      </p>
      <div style={{ fontSize: px(20), lineHeight: px(26) }}>
        Для подтверждения нажмите на:
        <p
          aria-hidden
          className="cursor-pointer text-[#0A84FE] underline"
          onClick={() => {
            // handleAcceptInvite(item?.additionalLink);
            dispatch(acceptAdmins({ link: item?.additionalLink.slice(1) }));
            setShowOkPopup(true);
          }}
          style={{ display: 'inline', marginLeft: px(5) }}
        >
          Ок
        </p>
        {showOkPopup && (
          <Popup
            onClose={() => setShowOkPopup(false)}
            data={{
              title: 'Поздравляем! Вы стали администратором кабинета.',
              buttonLeftText: 'Ок',
              single: true,
            }}
          />
        )}
      </div>
    </div>
  );
};
export default NotificationTypeInvite;
