import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const BLOCK_GUIDE_CABINET = 'guide/blockGuideCabinet';

export const blockGuideCabinet = createAsyncThunk(
  BLOCK_GUIDE_CABINET,
  createRequest({
    type: BLOCK_GUIDE_CABINET,
    loader: data => authApi.blockGuideCabinet(data),
  }),
);
