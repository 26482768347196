/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { AddressLineFormat, FixTruncate } from './line-component';
import { useWindowSize } from './line-component/useWndiwSize';

export const Card = ({
  className,
  item,
  image,
  fullSize,
  title,
  addressTitle,
  address,
  street,
  outlined,
  link,
  id,
  competitionImage,
  competitions,
  blocked,
  setShowPopup,
  ...props
}) => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const fixTruncateKey = `event ${width} ${id}`;
  const navigate = useNavigate();
  const handleClick = objectId =>
    blocked === 'BLOCKED'
      ? navigate('/holiday', {
          state: {
            blockMessage: item?.blockMessage || '',
            endDate: item?.blockEndsAt,
            link: pathname,
          },
        })
      : navigate(`/object/${objectId}`);

  return (
    <>
      {link ? (
        <Link
          target="_blank"
          to={link}
          {...props}
          className={`card ${outlined ? 'card--outlined' : ''} ${className || ''} home-events-card`}
        >
          {image || competitionImage ? (
            <img
              src={`${competitions ? competitionImage : image}`}
              className={`card__image ${fullSize ? 'card__image--full' : ''}`}
              alt="card-pic"
            />
          ) : (
            <div />
          )}
        </Link>
      ) : (
        <div
          aria-hidden
          {...props}
          className={`card ${outlined ? 'card--outlined' : ''} ${
            className || ''
          } home-events-card cursor-pointer`}
          onClick={() => handleClick(id)}
        >
          <div
            style={{
              padding: `${px(21)} ${px(8)} ${px(31)} ${px(9)}`,
              height: px(97),
              minHeight: px(97),
              lineHeight: px(20),
              textAlign: 'center',
            }}
            className="text-center "
          >
            {title && (
              <>
                <p className="line-clamp-2 font-bold opacity-100">
                  <FixTruncate
                    fixTruncateKey={fixTruncateKey}
                    title={title}
                    linesQuantity={1}
                    spanClass="break-all"
                  />
                </p>
              </>
            )}
          </div>
          <div className="relative mx-auto" style={{ minHeight: px(110) }}>
            {blocked === 'ACTIVE' ? (
              <></>
            ) : (
              <Icon
                className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform"
                viewBox="0 0 68 68"
                width={px(68)}
                height={px(68)}
                type={TypesIcon.BIGLOCK}
              />
            )}
            {image || competitionImage ? (
              <img
                src={`${competitions ? competitionImage : image}`}
                className={`card__image ${fullSize ? 'card__image--full' : ''}`}
                alt="card-pic"
              />
            ) : (
              <div />
            )}
          </div>
          <div
            style={{
              padding: `${px(14)} ${px(8)} ${px(8)} ${px(8)}`,
            }}
            className="h-full"
          >
            <div style={{ height: px(35) }}>
              <AddressLineFormat line={addressTitle} isTitle />
            </div>
            <div style={{ height: px(16) }}>
              <AddressLineFormat line={address} />
            </div>
            <div style={{ height: px(16) }}>
              <AddressLineFormat line={street} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
