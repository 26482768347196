import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as favouritesApi from '@/api/favourites';

export const GET_FAVOURITES = 'favourites/getFavourites';

export const getFavourites = createAsyncThunk(
    GET_FAVOURITES,
    createRequest({
        type: GET_FAVOURITES,
        loader: (data) => favouritesApi.getFavourites(data),
    }),
);