import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const CREATE_GUIDE_CABINET = 'guide/createGuideCabinet';

const onSuccess = () => async dispatch => {
  dispatch(
    addModal({
      id: 13,
      data: {
        title: 'Кабинет создан',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'profile',
    }),
  );
};

export const createGuideCabinet = createAsyncThunk(
  CREATE_GUIDE_CABINET,
  createRequest({
    type: CREATE_GUIDE_CABINET,
    loader: data => authApi.createGuideCabinet(data),
    onSuccess,
  }),
);
