import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { px } from '../../../constants/Px';
import { format2DigitDate, formatCreditCardNumber } from '../../../new-architecture/utils';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../Icon';

export const NotificationTypeInviteResponseDirect = ({ item }) => {
  const notificationMessage = useMemo(
    () => ({
      eventName: item?.body?.eventName ? `Объект: ${item?.body?.eventName}` : '',
      cardName: item?.body?.cardName ? `Имя карты: ${item?.body?.cardName}` : '',
      cardType: item?.body?.cardType
        ? `Вид карты: ${
            item?.body?.cardType === 'CLUB'
              ? 'Клубная'
              : item?.body?.cardType === 'DISCOUNT'
                ? 'Скидочная'
                : 'Льготная'
          }`
        : '',
      cardStatus: item?.body?.cardStatus ? `Статус карты: ${item?.body?.cardStatus}%` : '',
      cardActiveDate: item?.body?.cardActiveDate
        ? `Срок действия карты: ${format2DigitDate(item?.body?.openUntill)}`
        : '',
      message: item?.body?.message ? `${item?.body?.message}` : '',
    }),
    [item],
  );
  return (
    <div className="flex w-full flex-col items-start justify-start" style={{ gap: px(20) }}>
      <p>ВРУЧЕНИЕ КАРТЫ:</p>
      <div className="flex w-full flex-col items-start justify-start">
        <p className="w-full break-words">{notificationMessage?.eventName}</p>
        <p className="w-full break-words">{notificationMessage?.cardName}</p>
        <p className="w-full break-words">{notificationMessage?.cardType}</p>
        <p className="w-full break-words">{notificationMessage?.cardStatus}</p>
        <p className="w-full break-words">{notificationMessage?.cardActiveDate}</p>
      </div>
      {notificationMessage?.message && (
        <div className="flex w-full flex-col items-start justify-start">
          <h4 className="font-[400]">Комментарий:</h4>
          <p className="w-full break-words">{notificationMessage?.message}</p>
        </div>
      )}
    </div>
  );
};
export default NotificationTypeInviteResponseDirect;
