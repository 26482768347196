import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import { addModal } from '../../modal/addModal';
import * as authApi from '@/api/auth';

export const SIGNUPCODEREFRESH = 'auth/signUpCodeRefresh';

const onFail = (error) => async (dispatch) => {
    await dispatch(
      addModal({
        id: 3,
        data: {
          title: 'Данный телефон уже зарегистрирован',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
  
};

export const signUpCodeRefresh = createAsyncThunk(
    SIGNUPCODEREFRESH,
  createRequest({
    type: SIGNUPCODEREFRESH,
    loader: (data) => authApi.signUpCodeRefresh(data),
    onFail,
  }),
);