import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { addLegalInformation } from '../../actions/mail/thunks';
import { Button, CustomAlert } from '../../components';
import { FileUpload } from '../../components/file-upload';
import { SettingProfileInfo } from '../../components/setting-profile-info';
import { financePlaceholder, financePlaceholderInfo } from '../../constants/Component';
import { createObjectDram } from '../../constants/Function';
import { px } from '../../constants/Px';
import {
  BackButton,
  CircleButton,
  Picker,
  ScrollButtonsContainer,
} from '../../new-architecture/shared';
import { TypesIcon } from '../../types/TypesIcon';

export const FinanceMainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [id, setId] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUploadLimit, setFileUploadLimit] = useState(false);
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    legalForm: '',
    organizationName: '',
    tax: '',
    inn: '',
    kpp: '',
    bik: '',
    paymentAccount: '',
  });
  const onClickButtonSubmit = event => {
    event?.preventDefault();
    const formData = new FormData();
    for (const file of selectedFiles) {
      if (file.size > 2 * 1024 * 1024) {
        setFileUploadLimit(true);
        return;
      }
      formData.append('files', file);
    }
    formData.append('legal_form', values?.legalForm);
    formData.append('organization_name', values?.organizationName);
    formData.append('tax', values?.tax);
    formData.append('inn', values?.inn);
    formData.append('kpp', values?.kpp);
    formData.append('bik', values?.bik);
    formData.append('payment_account', values?.paymentAccount);
    try {
      dispatch(addLegalInformation(formData));
    } catch (error) {
      console.error('Error dispatching addLegalInformation:', error);
    }
  };

  const handleRemoveAll = () => {
    setValues({
      legalForm: '',
      organization_name: '',
      tax: '',
      inn: '',
      kpp: '',
      bik: '',
      payment_account: '',
    });
    setSelectedFiles([]);
  };

  const openPicker = value => {
    setId(value);
    if (value === 2 || value === 0) {
      setShow(true);
    }
  };
  return (
    <div className="search-menu-item-filter-container">
      <BackButton
        onClick={() => {
          navigate('/profile');
        }}
      />
      <div id="scroll" className="search-menu-item-filter-overflow ">
        <div
          style={{
            padding: `${px(20)} ${px(12)} ${px(18)} ${px(15)}`,
          }}
          className="flex flex-col"
        >
          <p
            style={{
              fontSize: px(32),
              lineHeight: px(43),
            }}
            className={`whitespace-pre-wrap font-light text-[#000000] `}
          >
            Финансы
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal text-[#000000]"
          >
            Добавьте организацию и банковские реквизиты для приема платежей за ваши услуги и товары
          </p>
          <Picker
            singleItem
            title="Выбор ОПФ"
            show={id === 0 && show}
            onClose={() => setShow(false)}
            data={createObjectDram(
              ['legalForm'],
              ['НПД', 'ИП', 'ООО', 'АО', 'Физическое лицо', 'Самозанятый'],
            )}
            onChange={({ legalForm }) => setValues({ ...values, legalForm })}
            value={values?.legalForm}
            button
            isLine
            dontSetDefaultValue
          />
          <Picker
            singleItem
            title="Выбор ОПФ"
            show={id === 2 && show}
            onClose={() => setShow(false)}
            data={createObjectDram(['tax'], ['НПД', 'УСН', 'УСХН', 'ПСН'])}
            onChange={({ tax }) => setValues({ ...values, tax })}
            value={values?.tax}
            button
            isLine
            dontSetDefaultValue
          />
          <form
            style={{
              paddingBottom: px(65),
            }}
            className="flex flex-col"
          >
            <SettingProfileInfo
              isBorder
              setValues={setValues}
              placeholders={financePlaceholderInfo}
              titles={financePlaceholder}
              valuesObj={values}
              isMarginBottom
              values={values}
              onClick={openPicker}
            />
            <FileUpload
              title="Карточка клиента"
              uploadButton="Прикрепить файл"
              bordered
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              styles={{ gap: px(29), marginTop: px(18) }}
            />
            <Button
              onClick={onClickButtonSubmit}
              borderRadius={0}
              height={60}
              className="focus:shadow-outline w-full focus:outline-none"
              style={{
                padding: `0 ${px(17)}`,
              }}
              disabled={selectedFiles?.length === 0 || Object.values(values).includes('')}
            >
              Добавить
            </Button>
            <ScrollButtonsContainer>
              <CircleButton
                onClick={() => {
                  navigate('/demand', { state: { prevPath: pathname } });
                }}
                icon={{
                  type: TypesIcon.INFOCURSIVE,
                  viewBox: '0 0 17 25',
                  width: px(17),
                  height: px(25),
                }}
                className="text-[#0A84FE]"
              />
              {(Object?.values(values)?.some(value => value !== '') || !!selectedFiles?.length) && (
                <CircleButton
                  onClick={handleRemoveAll}
                  icon={{
                    type: TypesIcon.ROUNDEDRETURN,
                    viewBox: '0 0 28.451 26.164',
                    width: px(28.451),
                    height: px(26.164),
                    className: 'text-[#0a84fe]',
                    fill: '#0a84fe',
                  }}
                />
              )}
            </ScrollButtonsContainer>
            {fileUploadLimit && (
              <CustomAlert
                onClickButtonSubmit={() => {
                  setFileUploadLimit(false);
                }}
                single
                title="Размер файла не должен превышать 2 Мегабайта"
                buttonRightText="Ок"
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
