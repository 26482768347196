/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import map1 from '@assets/images/apples-maps.png';
import glory from '@assets/images/glory.png';
import map4 from '@assets/images/google-maps.png';
import clubCardsObject from '@assets/images/object-club-cards.png';
import statisticsImage from '@assets/images/object-statistics.png';
import map3 from '@assets/images/yandex-maps.png';
import map2 from '@assets/images/yandex-nav.png';
import { Header, Slider, SliderMaps, SliderNews, Tabs } from '@components';
import { Map, YMaps } from '@pbe/react-yandex-maps';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getObjectFeedbackNoAuth } from '../../actions/event-review';
import { getObjectFeedback } from '../../actions/event-review/thunks/get-object-feedback';
import { addFavourites, deleteFavourites, getFavourites } from '../../actions/favourites';
import { StateContext } from '../../components/state-provider';
import { px } from '../../constants/Px';
import { authSelector } from '../../selectors/auth';
import {
  feedbackRatingSelector,
  feedbackRatingSelectorNoAuth,
  feedbackSelector,
  feedbackSelectorNoAuth,
} from '../../selectors/event-review';
import { favouritesSelector } from '../../selectors/favourites';
import { TypesIcon } from '../../types/TypesIcon';
import { CircleButton, Icon } from '../shared';
import { ScrollButtonsContainer } from '../shared/scroll-buttons-container/scroll-buttons-container';
import { ClubCardsModal } from './components/club-cards-modal/club-cards-modal';
import { GetDirectionsModal } from './components/get-directions-modal';
import { ObjectOffersModal } from './components/object-offers-modal';
import { useObject } from './hooks';

export const ObjectPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { showSearch, setShowSearch } = useContext(StateContext);
  const [showAdPopup, setShowAdPopup] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [menuToggleOpen, setMenuToggleOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const auth = useSelector(authSelector);
  const favouritesCards = useSelector(favouritesSelector);
  const dispatch = useDispatch();
  const feedback = useSelector(feedbackSelector);
  const feedbackNoAuth = useSelector(feedbackSelectorNoAuth);

  const {
    data: { object },
  } = useObject();

  const rating = useSelector(auth ? feedbackRatingSelector : feedbackRatingSelectorNoAuth);

  const [eventType, setEventType] = useState();
  const [showClubCards, setShowClubCards] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const toggleLockClass = () => {
    setIsLocked(!isLocked);
  };

  const handleFavourites = () => {
    if (auth) {
      if (favouritesCards?.some(item => item?.eventId === object?.id))
        dispatch(
          deleteFavourites({
            id: favouritesCards.find(item => item?.eventId === object?.id)?.id,
          }),
        );
      else dispatch(addFavourites({ ...object, eventId: object?.id }));
    } else {
      navigate('/login');
    }
  };

  const openBooking = () => {
    navigate('/booking');
  };
  useEffect(() => {
    if (window.location.hash === '#ad') {
      toggleLockClass();
      setShowAdPopup(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getFavourites());
    if (auth) {
      dispatch(getObjectFeedback({ id }));
    } else {
      dispatch(getObjectFeedbackNoAuth({ id }));
    }
  }, []);

  const [offersOpen, setOffersOpen] = useState(false);
  const [activeLocation, setActiveLocation] = useState(0);
  const directions = [
    { id: 1, imageSrc: map1, label: 'Apple Maps', link: '...' },
    { id: 2, imageSrc: map2, label: 'Яндекс Навигатор', link: '...' },
    { id: 3, imageSrc: map3, label: 'Яндекс Карты', link: '...' },
    { id: 4, imageSrc: map4, label: 'Google Maps', link: '...' },
  ];
  const icons = ['shore', 'canopy', 'house', 'free', 'ice'];

  if (object && object?.status === 'BLOCKED')
    navigate('/holiday', {
      state: { blockMessage: object?.blockMessage || '', endDate: object?.blockEndsAt },
    });

  if (object && object?.status === 'ACTIVE')
    return (
      <main>
        <div className="global-container">
          {!(showAdPopup || offersOpen) && (
            <Header
              menuToggleOpen={menuToggleOpen}
              setMenuToggleOpen={setMenuToggleOpen}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              toggleLockClass={toggleLockClass}
              showAdd={showAdd}
              setShowAdd={setShowAdd}
            />
          )}

          <main>
            <div className="relative" style={{ marginBottom: px(75) }}>
              <div style={{ height: px(183) }}>
                {object?.EventAttachment &&
                object?.EventAttachment?.find(item => item.type === 'BANNER')?.imageUrl ? (
                  <img
                    src={`${process.env.REACT_APP_STATIC_URL}${
                      object?.EventAttachment?.find(item => item.type === 'BANNER')?.imageUrl
                    }`}
                    alt="heroImage"
                    className="h-full w-full object-cover"
                  />
                ) : null}
              </div>
              <div
                className="absolute z-10 overflow-hidden bg-white shadow-[0_4px_3px_0px_rgba(0,0,0,0.16)]"
                style={{
                  left: `calc(50% - ${px(65)})`,
                  bottom: `${px(-65)}`,
                  height: px(130),
                  width: px(130),
                  borderRadius: px(15),
                  border: '1px solid rgba(0, 0, 0, 0.16)',
                }}
              >
                {object?.EventAttachment &&
                object?.EventAttachment?.find(item => item.type === 'LOGO')?.imageUrl ? (
                  <img
                    src={`${process.env.REACT_APP_STATIC_URL}${
                      object?.EventAttachment?.find(item => item.type === 'LOGO')?.imageUrl
                    }`}
                    alt="heroImage"
                    className="w-full"
                  />
                ) : null}
              </div>
            </div>
            <div
              className="w-full "
              style={{
                padding: `${0} ${px(15)}`,
              }}
            >
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className="... overflow-hidden text-ellipsis whitespace-nowrap text-center font-normal"
              >
                {object?.name || ''}
              </p>
            </div>
            {(object?.EventContact?.country || object?.EventContact?.district) && (
              <p
                style={{
                  marginBottom: px(10),
                  gap: px(5),
                  fontSize: px(15),
                  lineHeight: px(20),
                }}
                className="flex justify-center"
              >
                <span>{object?.EventContact?.country || ''}</span>
                {object?.EventContact?.district && <span>/</span>}
                <span>{object?.EventContact?.district || ''}</span>
              </p>
            )}
            <div
              className="flex items-center justify-center font-normal text-[#39CA1C]"
              style={{
                marginBottom: px(20),
                fontSize: px(20),
                lineHeight: px(26),
                gap: px(11),
              }}
              onClick={() => navigate('/object-feedback', { state: object?.id })}
              aria-hidden
            >
              <div className="flex flex-row" style={{ gap: px(4.7) }}>
                {[1, 2, 3, 4, 5].map(e => (
                  <Icon
                    key={e}
                    type={
                      rating >= e - 0.5
                        ? TypesIcon.LITTLEFEEDBACKSTARFILL
                        : TypesIcon.LITTLEFEEDBACKSTARBORDER
                    }
                    width={px(15)}
                    height={px(16)}
                    viewBox="0 0 15 16"
                  />
                ))}
              </div>
              <p>{auth ? feedback?.length : feedbackNoAuth?.length}</p>
              <p>Отзывов</p>
            </div>
            <div
              className="flex items-center justify-center"
              style={{ gap: px(10), marginBottom: px(20) }}
            >
              <button
                type="button"
                className="flex cursor-pointer items-center overflow-hidden border-[1px] border-[#0A84FE] bg-white font-normal text-[#0A84FE]"
                style={{ height: px(50), fontSize: px(20), borderRadius: px(100) }}
                onClick={openBooking}
              >
                <span
                  className="flex h-full items-center bg-[#0A84FE]"
                  style={{ paddingRight: px(9), paddingLeft: px(13) }}
                >
                  <Icon
                    type={TypesIcon.CHAIR}
                    width={px(24.1)}
                    height={px(26.8)}
                    viewBox="0 0 24.1 26.8"
                  />
                </span>
                <span style={{ paddingRight: px(5), paddingLeft: px(10) }}>Бронировать</span>
              </button>
              <div>
                <CircleButton
                  onClick={handleFavourites}
                  icon={{
                    type: TypesIcon.STAR,
                    viewBox: '0 0 27.5 26.233',
                    width: px(27.5),
                    height: px(26.233),
                    fill: favouritesCards?.some(item => item?.eventId === object?.id)
                      ? 'white'
                      : '#0a84fe',
                  }}
                  className={`${
                    favouritesCards?.some(item => item?.eventId === object?.id)
                      ? '!bg-[#39CA1C]'
                      : ''
                  }`}
                />
              </div>
            </div>

            <Tabs
              setActiveTab={setActiveTab}
              tabsArray={[
                {
                  title: 'Описание',
                  body: (
                    <div className="container">
                      {object?.description && (
                        <p
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Описание
                        </p>
                      )}
                      {object?.description && (
                        <p
                          className="break-words"
                          style={{
                            marginBottom: px(27),
                            fontSize: px(20),
                            lineHeight: px(26),
                            whiteSpace: 'pre-wrap',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: object?.description?.replace('\n', '&#10;'),
                          }}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  title: 'Предложения',
                  body: (
                    <div
                      className="container"
                      style={{
                        minHeight: activeTab === 'Предложения' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: px(9),
                          fontSize: px(32),
                          lineHeight: px(43),
                        }}
                      >
                        Предложения
                      </p>
                      <div
                        className="grid grid-cols-2"
                        style={{ gap: px(8), marginBottom: px(22) }}
                      >
                        <div
                          aria-hidden
                          onClick={() => {
                            setEventType('FISHING');
                            setOffersOpen(true);
                          }}
                          className="cursor-pointer overflow-hidden shadow-[0_4px_3px_0px_rgba(0,0,0,0.16)]"
                          style={{ height: px(192), width: px(141), borderRadius: px(15) }}
                        >
                          <div style={{ width: px(141), height: px(110) }}>
                            {object?.EventAttachment &&
                            object?.EventAttachment?.find(item => item.type === 'GAME')
                              ?.imageUrl ? (
                              <img
                                src={`${process.env.REACT_APP_STATIC_URL}${
                                  object?.EventAttachment?.find(item => item.type === 'GAME')
                                    ?.imageUrl
                                }`}
                                alt="Рыбалка"
                                className="h-full w-full object-cover"
                                style={{ height: px(110) }}
                              />
                            ) : (
                              // <img src={bannerPlaceholder} alt="banner-placeholder" />
                              <div />
                            )}
                          </div>
                          <p
                            className="text-center font-normal"
                            style={{ paddingTop: px(20), fontSize: px(15), lineHeight: px(20) }}
                          >
                            Рыбалка
                          </p>
                        </div>
                        <div
                          aria-hidden
                          onClick={() => {
                            setEventType('REST');
                            setOffersOpen(true);
                          }}
                          className="cursor-pointer overflow-hidden shadow-[0_4px_3px_0px_rgba(0,0,0,0.16)]"
                          style={{ height: px(192), width: px(141), borderRadius: px(15) }}
                        >
                          <div style={{ width: px(141), height: px(110) }}>
                            {object?.EventAttachment &&
                            object?.EventAttachment?.find(item => item.type === 'RECREATION')
                              ?.imageUrl ? (
                              <img
                                src={`${process.env.REACT_APP_STATIC_URL}${
                                  object?.EventAttachment?.find(item => item.type === 'RECREATION')
                                    ?.imageUrl
                                }`}
                                alt="Игра"
                                className="h-full w-full object-cover"
                                style={{ height: px(110) }}
                              />
                            ) : (
                              // <img
                              //   className="h-full w-full object-cover"
                              //   src={bannerPlaceholder}
                              //   alt="banner-placeholder"
                              // />
                              <div />
                            )}
                          </div>
                          <p
                            className="text-center font-normal"
                            style={{ paddingTop: px(20), fontSize: px(15), lineHeight: px(20) }}
                          >
                            Отдых
                          </p>
                        </div>
                        <div
                          aria-hidden
                          onClick={() => {
                            setEventType('SERVICE');
                            setOffersOpen(true);
                          }}
                          className="cursor-pointer overflow-hidden shadow-[0_4px_3px_0px_rgba(0,0,0,0.16)]"
                          style={{ height: px(192), width: px(141), borderRadius: px(15) }}
                        >
                          <div style={{ width: px(141), height: px(110) }}>
                            {object?.EventAttachment &&
                            object?.EventAttachment?.find(item => item.type === 'SERVICE') ? (
                              <img
                                src={`${process.env.REACT_APP_STATIC_URL}${
                                  object?.EventAttachment?.find(item => item.type === 'SERVICE')
                                    ?.imageUrl
                                }`}
                                alt="Игра"
                                className="h-full w-full object-cover"
                                style={{ height: px(110) }}
                              />
                            ) : (
                              // <img src={bannerPlaceholder} alt="banner-placeholder" />
                              <div />
                            )}
                          </div>
                          <p
                            className="text-center font-normal"
                            style={{ paddingTop: px(20), fontSize: px(15), lineHeight: px(20) }}
                          >
                            Услуги
                          </p>
                        </div>
                      </div>
                    </div>
                  ),
                },
                object?.EventAttachment &&
                  object?.EventAttachment.some(image => image.type === 'GALLERY') && {
                    title: 'Галерея',
                    body: (
                      <div
                        className="cursor-pointer"
                        style={{
                          marginBottom: px(20),
                          minHeight: activeTab === 'Галерея' ? `calc(100dvh - ${px(60)})` : '0',
                        }}
                      >
                        <p
                          className="container"
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Галерея
                        </p>
                        <Slider
                          images={object?.EventAttachment?.filter(item => item.type === 'GALLERY')
                            ?.sort((a, b) => a.priority - b.priority)
                            ?.map(image => ({
                              type: 'image',
                              src: `${process.env.REACT_APP_STATIC_URL}${image?.imageUrl}`,
                            }))}
                          setShowSearch={setShowSearch}
                          // sliderInRegistrationCard
                          sliderInObjectCard
                        />
                      </div>
                    ),
                  },
                object?.EventNews?.length > 0 && {
                  title: 'Новости',
                  body: (
                    <div
                      className="cursor-pointer"
                      style={{
                        marginBottom: px(20),
                        minHeight: activeTab === 'Новости' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      {object?.EventNews?.filter?.(item => item.status === 'ACTIVE')?.length >
                        0 && (
                        <p
                          className="container"
                          style={{
                            marginBottom: px(9),
                            fontSize: px(32),
                            lineHeight: px(43),
                          }}
                        >
                          Новости
                        </p>
                      )}
                      <SliderNews
                        images={object?.EventNews?.filter?.(item => item.status === 'ACTIVE')?.map(
                          image => ({
                            type: 'image',
                            title: image.title,
                            subtitle: image.subtitle,
                            body: image.body,
                            src: `${process.env.REACT_APP_STATIC_URL}${image.images[0].url}`,
                          }),
                        )}
                      />
                    </div>
                  ),
                },
                object?.EventContact?.termsAndRules && {
                  title: 'Условия и правила',
                  body: (
                    <div
                      className="container cursor-pointer"
                      style={{
                        marginBottom: px(20),
                        minHeight:
                          activeTab === 'Условия и правила' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      <p
                        className="font-normal"
                        style={{
                          marginBottom: px(10),
                          fontSize: px(20),
                          lineHeight: px(26),
                        }}
                      >
                        Условия и правила:
                      </p>
                      <div style={{ paddingRight: px(2) }}>
                        <p
                          className="break-words"
                          style={{
                            paddingRight: px(2),
                            fontSize: px(15),
                            lineHeight: px(20),
                            whiteSpace: 'pre-wrap',
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              object?.EventContact?.termsAndRules?.replace('\n', '&#10;') || '',
                          }}
                        />
                      </div>
                    </div>
                  ),
                },
                object?.EventAttachment &&
                  object?.EventAttachment.some(image => image.type === 'SCHEMA') && {
                    title: 'Карты, Схемы',
                    body: (
                      <div
                        className="container cursor-pointer"
                        style={{
                          marginBottom: px(40),
                          minHeight:
                            activeTab === 'Карты, Схемы' ? `calc(100dvh - ${px(60)})` : '0',
                        }}
                      >
                        {object?.EventAttachment &&
                          object?.EventAttachment.some(image => image.type === 'SCHEMA') && (
                            <SliderMaps
                              images={object?.EventAttachment?.filter(
                                item => item.type === 'SCHEMA',
                              )
                                ?.sort((a, b) => a.priority - b.priority)
                                ?.map(item => ({
                                  title: item.title,
                                  src: `${process.env.REACT_APP_STATIC_URL}${item?.imageUrl}`,
                                  file: `${process.env.REACT_APP_STATIC_URL}${item?.textFileUrl}`,
                                  name: item?.textFileName,
                                }))}
                            />
                          )}
                      </div>
                    ),
                  },
                {
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  title: 'Клубные карты',
                  body: (
                    <div
                      className="relative cursor-pointer"
                      style={{
                        marginBottom: px(20),
                        minHeight: activeTab === 'Клубные карты' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_SITE_URL}${clubCardsObject}`}
                        alt="club-cards-object"
                        style={{ width: px(320), height: px(290) }}
                      />
                      {activeTab === 'Клубные карты' && (
                        <div className="absolute" style={{ top: px(255), left: px(135) }}>
                          <CircleButton
                            /* onClick={() => {
                              setShowClubCards(true);
                            }} */
                            icon={{
                              type: TypesIcon.EYEOPEN,
                              viewBox: '0 0 26 18',
                              width: px(26.47),
                              height: px(16.87),
                            }}
                            className="text-[#0A84FE]"
                          />
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: 'Статистика',
                  body: (
                    <div
                      className="relative cursor-pointer"
                      style={{
                        marginBottom: px(20),
                        minHeight: activeTab === 'Статистика' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      <p
                        className="text-center font-normal"
                        style={{
                          marginBottom: px(-20),
                          fontSize: px(15),
                          lineHeight: px(20),
                          position: 'relative',
                        }}
                      >
                        Аналитика. Опыт. Исследования
                      </p>
                      <img src={statisticsImage} alt="statistics" style={{ width: '100%' }} />

                      <p
                        className="text-center font-normal text-[#0A84FE]"
                        style={{ fontSize: px(15), lineHeight: px(20), marginTop: px(30) }}
                      >
                        Доступна по Подписке
                      </p>
                      {activeTab === 'Статистика' && (
                        <div className="absolute" style={{ top: px(255), left: px(135) }}>
                          <CircleButton
                            onClick={() => {
                              // setShowClubCards(true);
                            }}
                            icon={{
                              type: TypesIcon.RUBLE,
                              width: px(24),
                              height: px(24),
                            }}
                            className="text-[#0A84FE]"
                          />
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: 'Зал славы',
                  body: (
                    <div
                      className="container cursor-pointer"
                      style={{
                        marginBottom: px(20),
                        minHeight: activeTab === 'Зал славы' ? `calc(100dvh - ${px(60)})` : '0',
                      }}
                    >
                      <img src={glory} alt="glory" style={{ width: '100%' }} />
                    </div>
                  ),
                },
                {
                  title: 'Контакты',
                  body: (
                    <div style={{ marginBottom: px(20) }} className="relative w-full">
                      <p
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          paddingLeft: px(15),
                          paddingRight: px(15),
                          fontWeight: 400,
                          marginBottom: px(19),
                        }}
                        className="line-clamp-3"
                      >
                        {`${
                          object?.EventContact?.country ? `${object?.EventContact?.country}, ` : ''
                        }${
                          object?.EventContact?.region
                            ? `${object?.EventContact?.region} регион, `
                            : ''
                        }
                        ${
                          object?.EventContact?.district
                            ? `${object?.EventContact?.district}, `
                            : ''
                        }
                        ${object?.EventContact?.town ? `${object?.EventContact?.town}, ` : ''}
                        ${object?.EventContact?.street || ''}
                        `}
                      </p>
                      <p
                        style={{
                          fontSize: px(20),
                          lineHeight: px(26),
                          paddingLeft: px(19),
                          fontWeight: 400,
                          marginBottom: px(10),
                        }}
                      >
                        Карта местности
                      </p>
                      <YMaps className="w-full" style={{ height: px(290) }}>
                        <div style={{ height: px(290) }}>
                          <Map
                            style={{ height: px(290) }}
                            defaultState={{
                              /* center: [
                                event?.EventContact?.coordinates?.[0]?.split?.(',')?.[0]?.trim?.(),
                                event?.EventContact?.coordinates?.[0]?.split?.(',')?.[1]?.trim?.(),
                              ], */
                              center: [
                                object?.EventContact?.coordinates?.[0],
                                object?.EventContact?.coordinates?.[1],
                              ],
                              zoom: 8,
                            }}
                            behaviors={{ disable: ['drag'] }}
                          />
                        </div>
                      </YMaps>
                      <div
                        style={{ paddingBottom: px(80), paddingTop: px(19), paddingLeft: px(15) }}
                      >
                        <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                          ID кабинета: {id}
                        </p>
                      </div>
                      <div
                        className="absolute flex"
                        style={{ left: px(75), gap: px(10), bottom: px(241) }}
                      >
                        <CircleButton
                          onClick={openBooking}
                          icon={{
                            type: TypesIcon.CHAIR,
                            viewBox: '0 0 24.1 26.8',
                            width: px(24.1),
                            height: px(26.8),
                          }}
                          className="!bg-[#0A84FE]"
                        />
                        <CircleButton
                          onClick={() => setShowAdPopup(true)}
                          icon={{
                            type: TypesIcon.DIRECTION,
                            viewBox: '0 0 23.4 23.4',
                            width: px(23.4),
                            height: px(23.4),
                            fill: '#0A84FE',
                          }}
                        />
                        <CircleButton
                          onClick={handleFavourites}
                          icon={{
                            type: TypesIcon.STAR,
                            viewBox: '0 0 27.5 26.233',
                            width: px(27.5),
                            height: px(26.233),
                            fill: favouritesCards?.some(item => item?.eventId === object?.id)
                              ? 'white'
                              : '#0a84fe',
                          }}
                          className={`${
                            favouritesCards?.some(item => item?.eventId === object?.id)
                              ? '!bg-[#39CA1C]'
                              : ''
                          }`}
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </main>
        </div>
        <GetDirectionsModal
          show={showAdPopup}
          contacts={object?.EventContact}
          onClose={() => setShowAdPopup(false)}
          title="Проложить маршрут"
          directions={directions}
        />
        {showClubCards && (
          <ClubCardsModal
            show={showClubCards}
            onClose={() => setShowClubCards(false)}
            title="Запрос Клубной Карты"
          />
        )}
        <ObjectOffersModal
          show={offersOpen}
          onClose={() => {
            setOffersOpen(false);
          }}
          title={`${
            eventType === 'FISHING' ? 'Рыбалка' : eventType === 'REST' ? 'Отдых' : 'Услуги'
          }`}
          eventType={eventType}
          icons={icons}
          activeLocation={activeLocation}
          setActiveLocation={setActiveLocation}
          setShowSearch={setShowSearch}
          event={object}
        />
      </main>
    );
};

export default ObjectPage;
