import React, { useEffect, useMemo, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { standartDate } from '../constants/Function';
import { px } from '../constants/Px';
import { BackButton } from '../new-architecture/shared/back-button';
import { ClubCardViewModal } from '../new-architecture/shared/modal';
import { format2DigitDate } from '../new-architecture/utils';
import { FooterSlider } from './footer-slider';
import { LoyalCard } from './loyal-card';
import { LoyaltyCard } from './loyalty-card';

export const ProfileClubCardsViewModal = ({ data, setShowProfileClubCardsView }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showCardTypeView, setShowCardTypeView] = useState(false);
  const [activeCardView, setActiveCardView] = useState();
  const [cardHolderId, setCardHolderId] = useState();

  const cards = useMemo(
    () =>
      data?.map(item => ({
        clubCardHolder: {
          blockedUntill: item?.blockedUntill,
          createdAt: item?.createdAt,
          deletedAt: item?.deletedAt,
          id: item?.id,
          message: item?.message,
          openUntill: item?.openUntill,
          plasticNumber: item?.plasticNumber,
          cardDiscount: item?.card?.cardDiscount,
          event: item?.card?.event,
          name: item?.card?.name,
          image: item?.card?.image,
          card: item?.card,
          profile: item?.profile,
        },
        id: item?.id,
        profileId: item?.profileId.toString(),
        cardDiscount: item?.card?.cardDiscount,
        openUntill: item?.openUntill,
        profile: item?.profile,
      })),
    [data],
  );
  return (
    <div className="fixed top-0 z-[101] h-full w-full max-w-[768px] bg-white">
      <div className="scrollable-container h-full">
        <div className="global-container">
          <div className="scrollable-container max-h-full">
            <BackButton onClick={() => setShowProfileClubCardsView(false)} />
            <div
              className="container"
              style={{ paddingTop: px(10), paddingLeft: px(0), paddingRight: px(0) }}
            >
              <div style={{ paddingBottom: px(80) }}>
                <p
                  style={{
                    fontSize: px(32),
                    lineHeight: px(32),
                    paddingTop: px(26),
                    paddingBottom: px(5),
                  }}
                  className="text-center font-light"
                >
                  Карты <br /> клубные
                </p>

                <p
                  className="text-center font-normal"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                  }}
                >
                  Виртуальные карты лояльности для постоянных гостей.
                </p>
                {cards?.length > 0 ? (
                  <>
                    <div
                      className="w-full"
                      style={{
                        padding: `${px(16)} ${px(15)} ${px(0)} ${px(15)}`,
                      }}
                    >
                      <FooterSlider
                        slidesPerView={2}
                        gap={20}
                        cards
                        pagination
                        onRealIndexChange={elem => {
                          setActiveIndex(elem);
                        }}
                        centeredSlides
                      >
                        {cards?.map(item => (
                          <SwiperSlide key={item?.id} className="swiper-slide">
                            <div className="flex w-full justify-center ">
                              <LoyalCard
                                color="#fff"
                                data={{
                                  bgImg: item?.clubCardHolder?.image?.url,
                                  ownerName: item?.profile?.name,
                                  cardType: item?.clubCardHolder?.card?.name,
                                  percent: item?.cardDiscount,
                                  openUntill: format2DigitDate(item?.openUntill),
                                  logo: {
                                    logoSrc:
                                      item?.clubCardHolder?.card?.event?.EventAttachment?.find(
                                        attachment => attachment.type === 'LOGO',
                                      )?.imageUrl || '',
                                    showLogo: item?.clubCardHolder?.card?.displayLogo,
                                  },
                                }}
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </FooterSlider>
                    </div>
                    <div
                      style={{
                        gap: px(10),
                        marginTop: px(20),
                        paddingRight: px(15),
                        paddingLeft: px(15),
                        paddingBottom: px(19),
                      }}
                      className="flex flex-col"
                    >
                      <LoyaltyCard
                        key={cards?.[activeIndex]?.id}
                        data={{
                          location:
                            cards?.[activeIndex]?.profile?.town ||
                            ('' && cards?.[activeIndex]?.profile?.country) ||
                            ''
                              ? `${cards?.[activeIndex]?.profile?.town || ''}, ${
                                  cards?.[activeIndex]?.profile?.country || ''
                                }`
                              : `${cards?.[activeIndex]?.profile?.town || ''}${
                                  cards?.[activeIndex]?.profile?.country || ''
                                }`,
                          profileId: cards?.[activeIndex]?.profileId?.toString(),
                          profileName: cards?.[activeIndex]?.profile?.name,
                          profileLogo: cards?.[activeIndex]?.profile?.avatar?.url,
                          createdAt: standartDate(cards?.[activeIndex].clubCardHolder?.createdAt),
                          openUntill: standartDate(cards?.[activeIndex].clubCardHolder?.openUntill),
                          cardNumber: cards?.[activeIndex]?.clubCardHolder?.id.toString(),
                          cardName: cards?.[activeIndex]?.clubCardHolder?.name,
                        }}
                        type={cards?.[activeIndex]?.profile?.cardName}
                        onChangeCardId={() => {
                          setActiveCardView(cards?.[activeIndex]?.clubCardHolder);
                          setCardHolderId(cards?.[activeIndex]);
                          setShowCardTypeView(true);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      paddingTop: px(20),
                    }}
                  >
                    <p
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                      }}
                      className="text-center font-normal opacity-50"
                    >
                      Карты не найдены
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showCardTypeView && (
          <ClubCardViewModal
            showCardTypeView={showCardTypeView}
            setShowCardTypeView={setShowCardTypeView}
            data={activeCardView}
            cardHolderId={cardHolderId}
            setCardHolderId={setCardHolderId}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileClubCardsViewModal;
