/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const CANCEL_DELETE_GUIDE_CABINET = 'guide/cancelDeleteGuideCabinet';

export const cancelDeleteGuideCabinet = createAsyncThunk(
  CANCEL_DELETE_GUIDE_CABINET,
  createRequest({
    type: CANCEL_DELETE_GUIDE_CABINET,
    loader: data => authApi.cancelDeleteGuideCabinet(data),
  }),
);
