import React from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const TariffCard = ({
  price,
  time,
  endTime,
  typeTime,
  bgClassName,
  priceColor,
  disabled,
  promotion,
  discount,
  priceDiscountAction,
}) => (
  <div
    style={{
      width: px(90),
      height: px(149),
      borderRadius: px(15),
      padding: `${px(7)} ${px(4)} ${px(10)} ${px(5)}`,
    }}
    className={`${bgClassName || 'bg-[#212B35]'} flex flex-col items-center ${
      disabled ? 'opacity-30' : ''
    }`}
  >
    <div
      style={{
        gap: px(1.8),
        paddingBottom: px(4.11),
      }}
      className="flex items-center justify-center"
    >
      <Icon
        type={typeTime === 'НОЧЬ' ? TypesIcon.SUN : TypesIcon.ACTIVESUN}
        width={px(30.624)}
        height={px(29.889)}
        viewBox="0 0 30.624 29.889"
      />
      <Icon
        type={typeTime === 'ДЕНЬ' ? TypesIcon.NIGHT : TypesIcon.ACTIVENIGHT}
        width={px(23.127)}
        height={px(24.893)}
        viewBox="0 0 23.127 24.893"
      />
    </div>
    <p
      style={{
        fontSize: px(10),
        lineHeight: px(13),
      }}
      className="font-light text-[#FFFFFF] opacity-50"
    >
      БРОНЬ
    </p>
    <p
      style={{
        fontSize: px(15),
        lineHeight: px(20),
      }}
      className="font-light text-[#FFFFFF] opacity-50"
    >{`${time || '00'}-${endTime || '00'}`}</p>
    <p
      style={{
        fontSize: px(20),
        lineHeight: px(26),
      }}
      className="font-normal text-[#FFFFFF]"
    >
      {typeTime.toUpperCase()}
    </p>
    <p
      style={{
        fontSize: px(10),
        lineHeight: px(13),
      }}
      className="font-light text-[#FFFFFF] opacity-50"
    >
      стоимость от
    </p>
    {priceDiscountAction === 'discount' && (
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
        }}
        className={`${
          priceColor ? `text-[${priceColor}]` : discount === 0 ? 'text-[#39CA1C]' : 'text-[#FF0A0A]'
        } font-normal`}
      >
        {(discount && price ? Math.round((price * (100 - discount)) / 100) : price) || 0}
      </p>
    )}
    {priceDiscountAction === 'action' && (
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
        }}
        className={`${
          priceColor
            ? `text-[${priceColor}]`
            : promotion === 0
              ? 'text-[#39CA1C]'
              : 'text-[#FF0A0A]'
        } font-normal`}
      >
        {(promotion && price ? Math.round((price * (100 - promotion)) / 100) : price) || 0}
      </p>
    )}
    {priceDiscountAction === 'price' && (
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
        }}
        className={`${priceColor ? `text-[${priceColor}]` : 'text-[#39CA1C]'} font-normal`}
      >
        {price || '0000'}
      </p>
    )}
    {!priceDiscountAction && (
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
        }}
        className={`${priceColor ? `text-[${priceColor}]` : 'text-[#39CA1C]'} font-normal`}
      >
        {price || '0000'}
      </p>
    )}
  </div>
);

export default TariffCard;
