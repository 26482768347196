/* eslint-disable sonarjs/no-extra-arguments */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';

export const ADD_COMPETITION_REGISTRATION = 'competition/addCompetitionRegistration';

const onSuccess = id => async dispatch => {
  dispatch(
    addModal({
      id: 13,
      data: {
        title: 'Заявка отправлена',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: `competition/${id}`,
    }),
  );
};

export const addCompetitionRegistration = createAsyncThunk(
  ADD_COMPETITION_REGISTRATION,
  createRequest({
    type: ADD_COMPETITION_REGISTRATION,
    loader: data => authApi.addCompetitionRegistration(data.data),
    onSuccess: data => onSuccess(JSON.parse(data.config.data).dataId),
  }),
);
