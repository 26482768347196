/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/guide';

import { createRequest } from '../../../helpers/api/createRequest';
/* import { getAdmins } from './get-admins'; */

export const BLOCK_GUIDE_ADMIN = 'guide/blockGuideAdmin';

/* const onSuccess = () => async (dispatch) => {
  dispatch(
    getAdmins()
  );
}; */

export const blockGuideAdmin = createAsyncThunk(
  BLOCK_GUIDE_ADMIN,
  createRequest({
    type: BLOCK_GUIDE_ADMIN,
    loader: data => profileAdminApi.blockGuideAdmin(data),
  }),
);
