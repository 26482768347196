import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_COMPETITIONS = 'competition/getCompetitions';

export const getCompetitions = createAsyncThunk(
  GET_COMPETITIONS,
  createRequest({
    type: GET_COMPETITIONS,
    loader: data => authApi.getCompetitions(data),
  }),
);
