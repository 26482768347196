import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const DELETE_GUIDE_NEWS = 'guide/deleteGuideNews';

export const deleteGuideNews = createAsyncThunk(
  DELETE_GUIDE_NEWS,
  createRequest({
    type: DELETE_GUIDE_NEWS,
    loader: data => authApi.deleteGuideNews(data),
  }),
);
