// TODO: refactor the whole component

import React, { useCallback, useRef } from 'react';

import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';
import { useOutSideClick } from '../../../hooks/useOutSideClick';
import { px } from '../../utils';
import { Button } from '../button';

export const Popup = ({ onClose, data, onSubmit }) => {
  const ref = useRef(null);

  useLockBodyScroll();

  const handleOutSideClick = useCallback(
    event => {
      if (!ref.current?.contains(event.target) && onClose) onClose();
    },
    [onClose],
  );

  useOutSideClick(ref, handleOutSideClick);

  return (
    <div
      aria-hidden
      className={`fixed bottom-0 left-0 right-0 top-0 z-[100] flex items-center justify-center `}
      onClick={data?.onClickButtonClose}
      style={{
        background: 'rgba(0, 0, 0, 0.7)',
      }}
      ref={ref}
    >
      <div
        aria-hidden
        className="grid grid-rows-[3fr_1fr] bg-[white] "
        style={{
          width: px(290),
          height: px(184),
        }}
        onClick={e => e.stopPropagation()}
      >
        {data?.title && (
          <p
            className="self-center text-center font-[400]"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              padding: `${px(17)} ${px(30)} ${px(17)} ${px(30)}`,
            }}
          >
            {data?.title}
          </p>
        )}

        <div className="flex flex-row" style={{ borderTop: '1px solid #dddddd' }}>
          {data?.buttonLeftText && (
            <Button
              bgColor="#fff"
              className="flex w-full cursor-pointer items-center  justify-center border-[1px] border-solid border-[#dddddd]"
              color={data?.single ? '#0A84FE' : '#000'}
              style={{ padding: ` 0 ${px(15)}` }}
              borderRadius={0}
              onClick={onClose}
            >
              {data?.buttonLeftText}
            </Button>
          )}
          {data?.buttonRightText && (
            <Button
              bgColor="#fff"
              color={data?.single ? '#0A84FE' : '#000'}
              className="flex w-full cursor-pointer items-center  justify-center border-[1px] border-solid border-[#dddddd]"
              style={{ padding: ` 0 ${px(15)}` }}
              borderRadius={0}
              onClick={onSubmit}
            >
              {data?.buttonRightText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
