export const filtersDataArray = [
  {
    title: `Рыбалка.\nОтдых.Услуги`,
    value: 'games',
    eventType: 'game',
    iconUrl: '/games.svg',
    filters: [
      {
        title: 'Игра',
        type: 'radio',
        value: 'gameType',
        options: [
          { value: 'sport', label: 'Спортивная' },
          {
            value: 'amateur',
            label: 'Любительская',
          },
        ],
      },
      {
        title: 'Скидки. Акции',
        type: 'checkbox',
        value: 'discount',
        options: [
          { label: 'Скидка на выходные', value: 'weekends' },
          { label: 'Скидка в будни', value: 'weekdays' },
          { label: 'Активные', value: 'active' },
          { label: 'С блокировкой', value: 'blocked' },
        ],
      },
      {
        title: 'Дисциплина',
        type: 'checkbox',
        value: 'discipline',
        options: [
          { label: 'Футбол', value: 'football' },
          { label: 'Хоккей', value: 'hockey' },
          { label: 'Поло', value: 'polo' },
          { label: 'Мотогонки', value: 'motorcycle_racing' },
          { label: 'Гольф', value: 'golf' },
          { label: 'Регби', value: 'rugby' },
        ],
      },
    ],
  },
  {
    title: 'Гиды.\nОбучение.Туры',
    value: 'guides_education',
    iconUrl: '/guide.svg',
    filters: [
      {
        title: 'Стоимость',
        type: 'checkbox',
        value: 'price',
        options: [
          { value: 'one_hour', label: '1 час' },
          { value: 'something_else', label: 'Что-то еще' },
        ],
      },
      {
        title: 'Вид турнира',
        type: 'checkbox',
        value: 'tournament',
        options: [
          { value: 'commercial', label: 'Коммерческий' },
          {
            value: 'rate',
            label: 'Рейтинговый',
          },
          {
            value: 'amateur',
            label: 'Любительский',
          },
          {
            value: 'professional',
            label: 'Профессиональный',
          },
        ],
      },
      {
        title: 'Тип участников',
        type: 'checkbox',
        value: 'participant',
        options: [
          { value: 'all', label: 'Все желающие' },
          {
            value: 'youth',
            label: 'Молодежный',
          },
          {
            value: 'child',
            label: 'Детский',
          },
          {
            value: 'female',
            label: 'Женский',
          },
          {
            value: 'male',
            label: 'Мужской',
          },
        ],
      },
      {
        title: 'Зачет',
        type: 'checkbox',
        value: 'standing',
        options: [
          { value: 'all', label: 'Все желающие' },
          {
            value: 'personal',
            label: 'Личный',
          },
          {
            value: 'team',
            label: 'Командный',
          },
          {
            value: 'personal_team',
            label: 'Лично-командный',
          },
        ],
      },
      {
        title: 'Дисциплина',
        type: 'checkbox',
        value: 'discipline',
        options: [
          { label: 'Футбол', value: 'football' },
          { label: 'Хоккей', value: 'hockey' },
          { label: 'Поло', value: 'polo' },
          { label: 'Мотогонки', value: 'motorcycle_racing' },
          { label: 'Гольф', value: 'golf' },
          { label: 'Регби', value: 'rugby' },
        ],
      },
      {
        title: 'Направления',
        type: 'checkbox',
        value: 'directions',
        options: [
          { label: 'Платная', value: 'paid' },
          { label: 'Суперлига', value: 'super_league' },
          { label: 'Хоккей с шайбой', value: 'hockey_with_ball' },
        ],
      },
      {
        title: 'Виды мячей',
        type: 'checkbox',
        value: 'ball',
        options: [
          { label: 'Желтый', value: 'yellow' },
          { label: 'Белый', value: 'white' },
          { label: 'Розовый', value: 'pink' },
        ],
      },
      {
        title: 'Услуги',
        type: 'checkbox',
        value: 'service',
        options: [
          { label: 'Проживание', value: 'accommodation' },
          { label: 'Обучение', value: 'education' },
          { label: 'Машина', value: 'car' },
          { label: 'Оборудование', value: 'equipment' },
          { label: 'Аренда оборудования', value: 'equipment_rent' },
          { label: 'Что-то еще', value: 'something_else' },
        ],
      },
    ],
  },
  /*   {
    title: "Туры. Гиды.",
    value: "guides_education",
    eventType: "guides_education",
    iconUrl: "/tour-golf.svg",
    filters: [
      {
        title: "Стоимость",
        type: "checkbox",
        value: "price",
        options: [
          { value: "one_hour", label: "1 час" },
          { value: "something_else", label: "Что-то еще" },
        ],
      },
      {
        title: "Вид турнира",
        type: "checkbox",
        value: "tournament",
        options: [
          { value: "commercial", label: "Коммерческий" },
          {
            value: "rate",
            label: "Рейтинговый",
          },
          {
            value: "amateur",
            label: "Любительский",
          },
          {
            value: "professional",
            label: "Профессиональный",
          },
        ],
      },
      {
        title: "Тип участников",
        type: "checkbox",
        value: "participant",
        options: [
          { value: "all", label: "Все желающие" },
          {
            value: "youth",
            label: "Молодежный",
          },
          {
            value: "child",
            label: "Детский",
          },
          {
            value: "female",
            label: "Женский",
          },
          {
            value: "male",
            label: "Мужской",
          },
        ],
      },
      {
        title: "Зачет",
        type: "checkbox",
        value: "standing",
        options: [
          { value: "all", label: "Все желающие" },
          {
            value: "personal",
            label: "Личный",
          },
          {
            value: "team",
            label: "Командный",
          },
          {
            value: "personal_team",
            label: "Лично-командный",
          },
        ],
      },
      {
        title: "Дисциплина",
        type: "checkbox",
        value: "discipline",
        options: [
          { label: "Футбол", value: "football" },
          { label: "Хоккей", value: "hockey" },
          { label: "Поло", value: "polo" },
          { label: "Мотогонки", value: "motorcycle_racing" },
          { label: "Гольф", value: "golf" },
          { label: "Регби", value: "rugby" },
        ],
      },
      {
        title: "Направления",
        type: "checkbox",
        value: "directions",
        options: [
          { label: "Платная", value: "paid" },
          { label: "Суперлига", value: "super_league" },
          { label: "Хоккей с шайбой", value: "hockey_with_ball" },
        ],
      },
      {
        title: "Виды мячей",
        type: "checkbox",
        value: "ball",
        options: [
          { label: "Желтый", value: "yellow" },
          { label: "Белый", value: "white" },
          { label: "Розовый", value: "pink" },
        ],
      },
      {
        title: "Услуги",
        type: "checkbox",
        value: "service",
        options: [
          { label: "Проживание", value: "accommodation" },
          { label: "Обучение", value: "education" },
          { label: "Машина", value: "car" },
          { label: "Оборудование", value: "equipment" },
          { label: "Аренда оборудования", value: "equipment_rent" },
          { label: "Что-то еще", value: "something_else" },
        ],
      },
    ],
  }, */
  {
    title: 'Турниры. Соревнования',
    value: 'tournaments',
    eventType: 'tournaments',
    iconUrl: '/tournament.svg',
    filters: [
      {
        title: 'Вид турнира',
        type: 'checkbox',
        value: 'tournament',
        options: [
          { value: 'commercial', label: 'Коммерческий' },
          {
            value: 'rate',
            label: 'Рейтинговый',
          },
          {
            value: 'amateur',
            label: 'Любительский',
          },
          {
            value: 'professional',
            label: 'Профессиональный',
          },
        ],
      },
      {
        title: 'Тип участников',
        type: 'checkbox',
        value: 'participant',
        options: [
          { value: 'all', label: 'Все желающие' },
          {
            value: 'youth',
            label: 'Молодежный',
          },
          {
            value: 'child',
            label: 'Детский',
          },
          {
            value: 'female',
            label: 'Женский',
          },
          {
            value: 'male',
            label: 'Мужской',
          },
        ],
      },
      {
        title: 'Зачет',
        type: 'checkbox',
        value: 'standing',
        options: [
          { value: 'all', label: 'Все желающие' },
          {
            value: 'personal',
            label: 'Личный',
          },
          {
            value: 'team',
            label: 'Командный',
          },
          {
            value: 'personal_team',
            label: 'Лично-командный',
          },
        ],
      },
      {
        title: 'Дисциплина',
        type: 'checkbox',
        value: 'discipline',
        options: [
          { label: 'Футбол', value: 'football' },
          { label: 'Хоккей', value: 'hockey' },
          { label: 'Поло', value: 'polo' },
          { label: 'Мотогонки', value: 'motorcycle_racing' },
          { label: 'Гольф', value: 'golf' },
          { label: 'Регби', value: 'rugby' },
        ],
      },
      {
        title: 'Направления',
        type: 'checkbox',
        value: 'directions',
        options: [
          { label: 'Платная', value: 'paid' },
          { label: 'Суперлига', value: 'super_league' },
          { label: 'Хоккей с шайбой', value: 'hockey_with_ball' },
        ],
      },
      {
        title: 'Виды мячей',
        type: 'checkbox',
        value: 'ball',
        options: [
          { label: 'Желтый', value: 'yellow' },
          { label: 'Белый', value: 'white' },
          { label: 'Розовый', value: 'pink' },
        ],
      },
    ],
  },
];
