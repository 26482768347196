import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { deleteCompetitionCabinet } from '../../actions/competition';
import { deleteEvent } from '../../actions/event';
import { deleteGuideCabinet } from '../../actions/guide';
import { Button, CustomAlert, Main } from '../../components';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { addActiveType } from '../../actions/auth';

export const DeleteAccountPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeType = useSelector(authActiveType);

  const activeId = useSelector(authActiveIdSelector);

  const [showAlert, setShowAlert] = useState(false);
  const [futureDate, setFutureDate] = useState(new Date());

  const handleClose = () => {
    setShowAlert(false);
  };
  const handleOpen = () => {
    setShowAlert(true);
  };

  const Submit = async () => {
    try {
      const { payload } = await dispatch(
        activeType === 'object'
          ? deleteEvent({ id: activeId })
          : activeType === 'competition'
            ? deleteCompetitionCabinet({ id: activeId })
            : deleteGuideCabinet({ id: activeId }),
      );

      if (payload?.status === 200) {
        dispatch(addActiveType({ activeType: undefined }));
        navigate('/profile');
      }

      handleClose();
    } catch {
      console.log('deleteEvent console;');
    }
  };

  useEffect(() => {
    const date = new Date();
    date.setDate(futureDate.getDate() + 30);
    setFutureDate(date);
  }, []);

  return (
    <Main title="Удалить личный кабинет" light lineHeight={32}>
      <p
        style={{
          fontSize: px(15),
          lineHeight: px(20),
        }}
        className="font-normal"
      >
        Удалив личный кабинет ваши данные исчезнут через 30 дней. До окончания этого периода вы
        можете отменить операцию просто войдя в свою учетную запись. Личный кабинет можно удалить
        только после закрытия или смены управляющего в кабинетах объектов или услуг при наличии
      </p>

      <Button
        style={{
          padding: `0 ${px(17)}`,
        }}
        onClick={handleOpen}
        className="focus:shadow-outline w-full focus:outline-none "
        bgColor="#fd0303"
        height={60}
        marginTop={36}
        borderRadius={0}
      >
        Удалить
      </Button>

      {showAlert && (
        <CustomAlert
          onClickButtonSubmit={Submit}
          onClickButtonClose={handleClose}
          title={`Ваша страница будет удалена.Вы можете отменить операцию до: ${futureDate.toLocaleString(
            'ru',
            { hour: 'numeric', minute: 'numeric' },
          )} ${futureDate.toLocaleString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })}`}
          buttonLeftText="Отмена"
          buttonRightText="Ок"
        />
      )}
    </Main>
  );
};
