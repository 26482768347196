export const countriesDataArray = [
    {
        countryName: "Россия",
        
        iconUrl: "/russia.png",
        svgUrl: "FLAGRUSSIAN",
        languages: [
            { name: "Русский" },
            { name: "Английский" },
            { name: "Китайский" },
        ],
        currencies: [{ name: "RUB" }, { name: "USD" }, { name: "CNY" }],
        regions: [
            { name: "Все" },
            { name: "Дальневосточный" },
            { name: "Приволжский" },
            { name: "Северо-Западный" },
            { name: "Северо-Кавказский" },
            { name: "Сибирский" },
            { name: "Уральский" },
            { name: "Центральный" },
            { name: "Южный" },
        ],
    },
    {
        countryName: "Беларусь",
        iconUrl: "/belarus.png",
        svgUrl: "FLAGBELARUS",
        languages: [{ name: "Белорусский" }, { name: "Русский" }],
        currencies: [{ name: "BYN" }, { name: "USD" }, { name: "CYN" }],
        regions: [
            { name: "Гомельская область" },
            { name: "Минская область" },
            { name: "Могилевская область" },
            { name: "Брестская область" },
            { name: "Гродненская область" },
            { name: "Витебская область" },
            { name: "г. Минск" },
        ],
    },
    {
        countryName: "Казахстан",
        iconUrl: "/belarus.png",
        svgUrl: "KAZAKHSTAN",
        languages: [{ name: "Казахский" }, { name: "Русский" }],
        currencies: [{ name: "BYN" }, { name: "USD" }, { name: "CYN" }],
        regions: [
            { name: "Абайская область" },
            { name: "Акмолинская область" },
            { name: "Актюбинская область" },  
            { name: "Алматинская область" },  
            { name: "Атырауская область" },
            { name: "Восточно-Казахстанская область" },
            { name: "Жамбылская область" },
            { name: "Жетысуская область" },
            { name: "Западно-Казахстанская область" },
            { name: "Карагандинская область" },
            { name: "Костанайская область" },
            { name: "Кызылординская область" },
            { name: "Мангистауская область" },
            { name: "Павлодарская область" },
            { name: "Северо-Казахстанская область" },
            { name: "Туркестанская область" },
            { name: "Улытауская область" },
        ],
    },
]
