/* eslint-disable security/detect-object-injection */
/* eslint-disable no-restricted-syntax */
import { getCompetitionCabinet } from '../../../api/competition';
import { getEvent } from '../../../api/event';
import { getGuideCabinet } from '../../../api/guide';
import { getLocations } from '../../../api/location';

// eslint-disable-next-line consistent-return
export const getProfile = async ({ objectId, activeType }) => {
  try {
    if (activeType === 'object' || activeType === 'administeredObject') {
      const [locations, object] = (
        await Promise.allSettled([getLocations({ id: objectId }), getEvent({ id: objectId })])
      )
        // eslint-disable-next-line unicorn/no-await-expression-member
        .map(item => item?.value?.data); // TODO: current object
      // Inject sectors for each location with their group
      const injectedLocations = await Promise.all(
        locations?.map?.(location => ({
          ...location,
          sections: location?.sections?.map?.(sector => ({
            ...sector,
            priceGroup: location?.prices?.find?.(({ id }) => id === sector?.priceId),
            promotionGroup: location?.promotions?.find?.(({ id }) => id === sector?.promotionId),
            discountGroup: location?.discounts?.find?.(({ id }) => id === sector?.discountId),
            accessGroup: location?.allowanceSectionGroups?.find?.(
              ({ id }) => id === sector?.sectionAllowanceGroupId,
            ),
          })),
        })),
      );

      return {
        locations: injectedLocations,
        object,
        competition: {},
        guide: {},
      };
    }

    if (activeType === 'competition' || activeType === 'administeredCompetition') {
      const [competition] = (await Promise.allSettled([getCompetitionCabinet({ id: objectId })]))
        // eslint-disable-next-line unicorn/no-await-expression-member
        .map(item => item?.value?.data); // TODO: current object

      return {
        locations: [],
        object: {},
        competition,
        guide: {},
      };
    }

    if (activeType === 'guide' || activeType === 'administeredGuide') {
      const [guide] = (await Promise.allSettled([getGuideCabinet({ id: objectId })]))
        // eslint-disable-next-line unicorn/no-await-expression-member
        .map(item => item?.value?.data); // TODO: current object

      return {
        locations: [],
        object: {},
        competition: {},
        guide,
      };
    }
  } catch (error) {
    console.log(error);

    return {
      locations: [],
      object: {},
      competition: {},
      guide: {},
    };
  }
};
