import { request } from '@/helpers/api/request';

export const addFeedback = async (data) => {
  const response = await request({
    url: `mail/review`,
    method: 'POST',
    data
  });

  return response;
};
