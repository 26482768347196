import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/card-holder';
import { createRequest } from '../../../helpers/api/createRequest';

export const CARD_HOLDER_FIND = 'card-holder/cardHolderFind';

export const cardHolderFind = createAsyncThunk(
  CARD_HOLDER_FIND,
  createRequest({
    type: CARD_HOLDER_FIND,
    loader: data => authApi.cardHolderFind(data),
  }),
);
