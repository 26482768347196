import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const LOCATION_EVENT = 'event/locationEvent';

export const locationEvent = createAsyncThunk(
  LOCATION_EVENT,
  createRequest({
    type: LOCATION_EVENT,
    loader: (data) => authApi.locationEvent(data),
  }),
);
