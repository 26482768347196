import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_COMPETITION_CABINET = 'competition/getCompetitionCabinet';

export const getCompetitionCabinet = createAsyncThunk(
  GET_COMPETITION_CABINET,
  createRequest({
    type: GET_COMPETITION_CABINET,
    loader: data => authApi.getCompetitionCabinet(data),
  }),
);
