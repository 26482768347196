import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { createCard } from '../../actions/card';
import { getEvent } from '../../api/event';
import { Button, ObjectDescriptionTextarea, SelectPhotoForCard } from '../../components';
import { AddLoyalCard } from '../../components/add-loyal-card';
import { addYearsToDate, createObjectDram, removePercent } from '../../constants/Function';
import { px } from '../../constants/Px';
import { BackButton, CardInformation, Picker } from '../../new-architecture/shared';
import { PickerInput } from '../../new-architecture/shared/picker-input/picker-input';
import { capitalizeFirstLetter } from '../../new-architecture/utils';
import { urlToBlob } from '../../new-architecture/utils/blob';
import { authActiveIdSelector } from '../../selectors/auth';

export const AddLoyalCardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeId = useSelector(authActiveIdSelector);
  const [openDropdownIds, setOpenDropdownIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState('logo');
  const [values, setValues] = useState({
    name: 'Classic',
    kind: 'Скидочная',
    type: 'С оплатой',
    receiptConditions: '0',
    cardDiscount: '10%',
    activeDate: 'Точная дата',
    allowance: '',
    generalInformation: '',
    applicationScope: '',
    agreement: '',
    useTerms: '',
  });
  // States for hide/show Pickers
  const [showCardKindPicker, setShowCardKindPicker] = useState(false);
  const [showCardTypePicker, setShowCardTypePicker] = useState(false);
  const [showCardReceiptConditionsPicker, setShowCardReceiptConditionsPicker] = useState(false);
  const [showCardDiscountPicker, setShowCardDiscountPicker] = useState(false);
  const [showCardActiveDatePicker, setShowCardActiveDatePicker] = useState(false);
  const [cardImage, setCardImage] = useState();
  const [disabledButtonCreate, setDisabledButtonCreate] = useState(false);
  const [event, setEvent] = useState([]);

  useEffect(() => {}, [selectedOption]);

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleComponentClick = id => {
    if (openDropdownIds.includes(id)) {
      setOpenDropdownIds(openDropdownIds.filter(item => item !== id));
    } else {
      setOpenDropdownIds([...openDropdownIds, id]);
    }
  };

  const handleChangeCode = event => {
    setValues({
      ...values,
      name: event.target.value,
    });
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEvent({ id: activeId });
        if (eventData?.data) {
          setEvent(eventData.data);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    fetchEvent();
  }, [activeId]);

  const onClickButtonSubmit = async () => {
    try {
      setDisabledButtonCreate(true);
      const formData = new FormData();
      formData.append('allowance', values?.allowance || '');
      formData.append('generalInformation', values?.generalInformation || '');
      formData.append('applicationScope', values?.applicationScope || '');
      formData.append('agreement', values?.agreement || '');
      formData.append('useTerms', values?.useTerms || '');
      formData.append('name', values?.name || 'Classic');
      formData.append('activeDate', values?.activeDate || 'Точная дата');
      formData.append(
        'receiptConditions',
        values?.type === 'С оплатой' ? values?.receiptConditions : '0',
      );
      formData.append('cardDiscount', removePercent(values?.cardDiscount));
      formData.append('cardPaymentType', values?.type === 'Бесплатная' ? 'FREE' : 'PAID');
      formData.append(
        'cardType',
        values?.kind === 'Скидочная'
          ? 'DISCOUNT'
          : values?.kind === 'Клубная'
            ? 'CLUB'
            : 'PREFERENTIAL',
      );
      formData.append('displayLogo', selectedOption === 'logo');
      formData.append('eventId', activeId?.toString());

      if (cardImage) {
        formData.append('file', await urlToBlob(cardImage), 'image.jpg');
      }
      const { payload } = await dispatch(createCard(formData));
      if (payload?.status === 201) {
        navigate('/card-types');
      }
    } catch (error) {
      console.error('Error during submission:', error);
    } finally {
      setDisabledButtonCreate(false);
    }
  };

  return (
    <div className="global-container ">
      <BackButton onClick={() => navigate('/card-types')} />
      <main
        style={{
          paddingBottom: px(80),
        }}
      >
        <p
          style={{
            fontSize: px(32),
            lineHeight: px(32),
            marginTop: px(20),
          }}
          className="text-center font-light"
        >
          Создать карту
        </p>
        <p
          className="text-center font-normal"
          style={{
            fontSize: px(15),
            lineHeight: px(20),
          }}
        >
          Создавайте сколько угодно карт с <br /> разными номиналами и условиями.
        </p>

        <div
          style={{
            paddingLeft: px(15),
            paddingRight: px(15),
          }}
        >
          <AddLoyalCard
            cardData={{
              cardName: values?.name,
              cardDiscount: values?.cardDiscount,
              cardLogo: {
                imageSrc: event?.EventAttachment?.filter(
                  attachment => attachment.type === 'LOGO',
                )[0]?.imageUrl,
                showLogo: selectedOption === 'logo',
              },
              cardImage,
              cardActiveDate: addYearsToDate(values?.activeDate),
              cardProfileName: 'Имя владельца',
            }}
          />
          <CardInformation
            data={[
              {
                id: 1,
                title: { label: 'Имя карты' },
                value: (
                  <div>
                    <input
                      type="text"
                      name="my-input"
                      placeholder="Classic"
                      className="font-normal"
                      style={{
                        height: px(36),
                        width: px(180),
                        fontSize: px(20),
                        lineHeight: px(26),
                        textAlign: 'right',
                        border: 'none',
                        appearance: 'none',
                        outline: 'none',
                      }}
                      maxLength="10"
                      value={values?.name}
                      onChange={handleChangeCode}
                    />
                  </div>
                ),
              },
            ]}
            clickable
          />
          <CardInformation
            data={[
              {
                id: 2,
                title: { label: 'Вид карты' },
                value: values?.kind || 'Скидочная',
                onClick: () => {
                  setShowCardKindPicker(true);
                },
              },
              {
                id: 3,
                title: { label: 'Тип карты' },
                value: values?.type || 'С оплатой',
                onClick: () => {
                  setShowCardTypePicker(true);
                },
              },
              {
                id: 4,
                title: { label: 'Условия получения' },
                value: values?.type === 'Бесплатная' ? '0' : values?.receiptConditions || '0',
                disabled: values?.type === 'Бесплатная',
                onClick: () => {
                  setShowCardReceiptConditionsPicker(true);
                },
              },
              {
                id: 5,
                title: { label: 'Статус карты' },
                value: values?.cardDiscount || '10%',
                onClick: () => {
                  setShowCardDiscountPicker(true);
                },
              },
              {
                id: 6,
                title: { label: 'Срок действия' },
                value: values?.activeDate || 'Точная дата',
                onClick: () => {
                  setShowCardActiveDatePicker(true);
                },
              },
            ]}
            clickable
          />
          <Picker
            singleItem
            title="Создать карту. Вид карты"
            show={showCardKindPicker}
            onClose={() => setShowCardKindPicker(false)}
            data={createObjectDram(['kind'], ['Скидочная', 'Клубная', 'Льготная'])}
            onChange={({ kind }) => setValues({ ...values, kind })}
            value={values?.kind}
            button
            isLine
            dontSetDefaultValue
          />
          <Picker
            singleItem
            title="Создать карту. Тип карты"
            show={showCardTypePicker}
            onClose={() => setShowCardTypePicker(false)}
            data={createObjectDram(['type'], ['С оплатой', 'Бесплатная'])}
            onChange={({ type }) => setValues({ ...values, type })}
            value={values?.type}
            button
            isLine
            dontSetDefaultValue
          />
          <PickerInput
            singleItem
            title="Создать карту. Условия получения"
            show={showCardReceiptConditionsPicker}
            onClose={() => setShowCardReceiptConditionsPicker(false)}
            onChange={receiptCondition =>
              setValues({ ...values, receiptConditions: receiptCondition })
            }
            value={values?.receiptConditions}
            button
            isLine
          />
          <Picker
            singleItem
            title="Создать карту. Статус карты"
            show={showCardDiscountPicker}
            onClose={() => setShowCardDiscountPicker(false)}
            data={createObjectDram(['cardDiscount'], ['0%', '5%', '10%', '20%', '30%'])}
            onChange={({ cardDiscount }) => setValues({ ...values, cardDiscount })}
            value={values?.cardDiscount}
            button
            isLine
            dontSetDefaultValue
          />
          <Picker
            singleItem
            title="Создать карту. Срок действия"
            show={showCardActiveDatePicker}
            onClose={() => setShowCardActiveDatePicker(false)}
            data={createObjectDram(
              ['activeDate'],
              ['2 года', '1 год', 'Точная дата', 'Бессрочно', '5 лет'],
            )}
            onChange={({ activeDate }) => setValues({ ...values, activeDate })}
            value={values?.activeDate}
            button
            isLine
            dontSetDefaultValue
          />
          <div
            style={{
              paddingTop: px(23),
              paddingBottom: px(15),
            }}
            className="border-b-[1px] border-solid border-black border-opacity-30"
          >
            <SelectPhotoForCard
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              image={cardImage}
              onChange={setCardImage}
            />
          </div>
          <CardInformation
            handleComponentClick={handleComponentClick}
            data={[
              {
                id: 7,
                title: { label: 'Общая информация' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                      textareaFontSize: 18,
                      textareaLineHeight: 24,
                    }}
                    objectKey="generalInformation"
                    value={values?.generalInformation}
                    setValue={(_, generalInformation) =>
                      setValues(prevValues => ({
                        ...prevValues,
                        generalInformation: capitalizeFirstLetter(generalInformation),
                      }))
                    }
                  />
                ),
              },
              {
                id: 8,
                title: { label: 'Где применяется' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                      textareaFontSize: 18,
                      textareaLineHeight: 24,
                    }}
                    objectKey="applicationScope"
                    value={values?.applicationScope}
                    setValue={(_, applicationScope) =>
                      setValues(prevValues => ({
                        ...prevValues,
                        applicationScope: capitalizeFirstLetter(applicationScope),
                      }))
                    }
                  />
                ),
              },
              {
                id: 9,
                title: { label: 'Соглашение' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                      textareaFontSize: 18,
                      textareaLineHeight: 24,
                    }}
                    objectKey="agreement"
                    value={values?.agreement}
                    setValue={(_, agreement) =>
                      setValues(prevValues => ({
                        ...prevValues,
                        agreement: capitalizeFirstLetter(agreement),
                      }))
                    }
                  />
                ),
              },
              {
                id: 10,
                title: { label: 'Регламент использования' },
                text: (
                  <ObjectDescriptionTextarea
                    objectStyles={{
                      height: 140,
                      placeholder: 'Вставьте текст или напишите…',
                      paddingTop: 4,
                      paddingLeft: 18,
                      paddingRight: 18,
                      textareaHeight: 132,
                      textareaFontSize: 18,
                      textareaLineHeight: 24,
                    }}
                    objectKey="useTerms"
                    value={values?.useTerms}
                    setValue={(_, useTerms) =>
                      setValues(prevValues => ({
                        ...prevValues,
                        useTerms: capitalizeFirstLetter(useTerms),
                      }))
                    }
                  />
                ),
              },
              {
                id: 11,
                title: { label: 'Обслуживание клиентов' },
                text: [
                  {
                    label: 'Email:',
                    value: event?.EventContact?.email,
                  },
                  {
                    value: event?.EventContact?.mainPhone,
                  },
                  {
                    value: event?.EventContact?.secondaryPhone,
                  },
                ],
              },
            ]}
            dropDown
          />
          <Button
            onClick={onClickButtonSubmit}
            className="w-full"
            height={60}
            borderRadius={0}
            marginTop={20}
            marginBottom={20}
            disabled={disabledButtonCreate}
          >
            Создать
          </Button>
        </div>
      </main>
    </div>
  );
};

export default AddLoyalCardPage;
