import * as profileAdminApi from '@/api/profile-admin';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addModal } from '@/actions/modal';

export const ADD_ADMIN = 'profile-admin/addAdmin';

const onFail = (error) => async (dispatch) => {
  if (error) {
    await dispatch(
      addModal({
        id: 6,
        data: {
          title: 'Такой пользователь не зарегистрирован либо уже добавлен. Вы не можете пригласить его к управлению',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
  }
};

const onSuccess =  (data) => async (dispatch) => {
  await dispatch(
    addModal({
      id: 9,
      data: {
        title: `Администратор ${data} успешно добавлен`,
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'account-control',
    }),
  );
};

export const addAdmin = createAsyncThunk(
  ADD_ADMIN,
  createRequest({
    type: ADD_ADMIN,
    loader: (data) => profileAdminApi.addAdmin(data),
    onFail,
    onSuccess: (data) => onSuccess(data.data.name),
  }),
);
