import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { deleteCompetitionAdmin, getCompetitionCabinet } from '../../actions/competition/thunks';
import { getEvent } from '../../actions/event';
import { deleteEventAdmin } from '../../actions/event-admin/thunks';
import { deleteGuideAdmin, getGuideCabinet } from '../../actions/guide';
import { getProfile } from '../../actions/profile';
import { deleteAdmin, getAdmins } from '../../actions/profile-admin';
import { AdminManager, CustomAlert, Main, TruncatedText } from '../../components';
import { Icon } from '../../components/Icon';
import { px } from '../../constants/Px';
import { CircleButton } from '../../new-architecture/shared';
import { getImageUrl, getPlasholderName } from '../../new-architecture/utils/avatars';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { competitionSelector } from '../../selectors/competition';
import { eventObjectSelector, nameObjectSelector } from '../../selectors/get-event';
import { guideSelector } from '../../selectors/guide';
import { profileSelector } from '../../selectors/profile';
import { adminsSelector } from '../../selectors/profile-admin';
import { TypesIcon } from '../../types/TypesIcon';

export const AccountControl = () => {
  const [showAdmins, setShowAdmins] = useState(false);
  const [adminId, setAdminId] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteReq, setDeleteReq] = useState(false);
  const profile = useSelector(profileSelector);
  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const event = useSelector(eventObjectSelector);
  const competition = useSelector(competitionSelector);
  const guide = useSelector(guideSelector);
  const adm = useSelector(adminsSelector);

  const admins =
    activeType === 'object'
      ? event?.EventAdmin
      : activeType === 'competition'
        ? competition?.admins
        : activeType === 'guide'
          ? guide?.admins
          : adm;
  const name = useSelector(nameObjectSelector);
  const linkToMenu =
    activeId === 100_000_000 || activeId === undefined ? '/profile' : '/user-object';

  const handleShowAdmins = () => {
    setShowAdmins(!showAdmins);
  };

  const addAdmin = () => {
    navigate('/add-administrator');
  };

  const removeAdmin = async () => {
    await dispatch(
      activeType === 'object'
        ? deleteEventAdmin({ profileAdminId: adminId })
        : activeType === 'competition'
          ? deleteCompetitionAdmin({ profileAdminId: adminId })
          : activeType === 'guide'
            ? deleteGuideAdmin({ profileAdminId: adminId })
            : deleteAdmin({ profileAdminId: adminId }),
    );
    if (activeType === 'object') dispatch(getEvent({ id: activeId }));
    if (activeType === 'competition') dispatch(getCompetitionCabinet({ id: activeId }));
    if (activeType === 'guide') dispatch(getGuideCabinet({ id: activeId }));
  };
  useEffect(() => {
    dispatch(getProfile());
    if (activeType === 'object') dispatch(getEvent({ id: activeId }));
    if (activeType === 'competition') dispatch(getCompetitionCabinet({ id: activeId }));
    if (activeType === 'guide') dispatch(getGuideCabinet({ id: activeId }));
  }, []);
  useEffect(() => {
    dispatch(getAdmins());
  }, []);

  return (
    <Main
      title="Управление кабинетом"
      light
      lineHeight={32}
      navigatePatch={
        activeType === 'object'
          ? '/user-object'
          : activeType === 'competition'
            ? '/user-competition'
            : activeType === 'guide'
              ? '/user-guide'
              : '/profile'
      }
      fixed
      paddingTop={85}
    >
      <div
        style={{
          paddingTop: px(5),
        }}
      >
        <TruncatedText
          data={{
            textBefore:
              'Управляйте кабинетом, обьектами или услугами вместе с сотрудниками, родственниками или друзьями... ',
            textAfter:
              'Управляйте кабинетом, обьектами или услугами вместе с сотрудниками, родственниками или друзьями. Управляющий имеет все права. Администраторы не имеют доступ к важным настройкам кабинета и разделам. Доступна история посещений и функция быстрой блокировки доступа в кабинет ',
            lines: 3,
          }}
        />
      </div>

      <p
        className="font-normal text-[#0a84fe]"
        style={{
          fontSize: px(15),
          lineHeight: px(20),
          paddingTop: px(13),
          paddingBottom: px(27),
        }}
      >
        Пригласить для общего управления
      </p>
      <div
        className="flex flex-row"
        style={{
          paddingBottom: px(15),
          gap: px(10),
        }}
      >
        <div
          style={{
            paddingTop: px(5),
          }}
        >
          {getImageUrl(activeType, event, guide, competition, profile) ? (
            <img
              className="rounded-[50%]"
              src={getImageUrl(activeType, event, guide, competition, profile)}
              alt="user"
              style={{
                height: px(60),
                width: px(60),
              }}
            />
          ) : (
            <div
              style={{ height: px(60), width: px(60) }}
              className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
            >
              <p className="font-light text-black" style={{ fontSize: px(40), lineHeight: px(43) }}>
                {getPlasholderName(activeType, event, guide, competition, profile)}
              </p>
            </div>
          )}
        </div>
        <div>
          <p
            className="... overflow-hidden text-ellipsis whitespace-nowrap font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              width: px(185),
            }}
          >
            {activeType === 'object'
              ? event?.name
              : activeType === 'competition'
                ? competition?.name
                : activeType === 'guide'
                  ? guide?.name
                  : profile?.name}
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            ID: {activeId === 100_000_000 || activeId === undefined ? profile?.id : activeId}
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            Управляющий
          </p>
        </div>
      </div>
      <div
        className="flex flex-row "
        style={{
          gap: px(10),
          marginBottom: px(28),
        }}
      >
        <CircleButton
          onClick={() => setShowAdmins(!showAdmins)}
          icon={{
            type: TypesIcon.PEOPLES,
            viewBox: '0 0 39.58 25.131',
            width: px(39.58),
            height: px(25.131),
            fill: `${showAdmins ? 'white' : '#0A84FE'}`,
          }}
          style={{ backgroundColor: `${showAdmins ? '#39CA1C' : '#ffffff'}` }}
        />
        <CircleButton
          onClick={() => {
            navigate('/add-administrator');
          }}
          icon={{
            type: TypesIcon.ADDPROFILE,
            viewBox: '0 0 40 40',
            width: px(40),
            height: px(40),
            fill: `${showAdmins ? 'white' : '#0A84FE'}`,
          }}
        />
      </div>
      {showAdmins && (
        <>
          <div
            className="flex items-center"
            style={{
              marginBottom: px(18),
              gap: px(22),
            }}
          >
            <div>
              <Icon
                type={TypesIcon.ADMINLIST}
                width={px(26.1)}
                height={px(34.8)}
                viewBox="0 0 26.1 34.8"
              />
            </div>
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Администраторы
            </p>
          </div>
          {admins
            ?.slice()
            ?.sort((a, b) => a.id - b.id)
            ?.map((item, index, array) => (
              <AdminManager
                deleteAdmin={setDeleteReq}
                getId={setAdminId}
                admin={item}
                key={item.name}
                style={{
                  borderBottom:
                    index === array.length - 1 ? 'none' : '1px solid rgba(0, 0, 0, 0.3)',
                  marginBottom: index === array.length - 1 ? px(22) : px(35),
                  paddingBottom: !(index === array.length - 1) && px(18),
                }}
              />
            ))}
        </>
      )}
      {deleteReq && (
        <CustomAlert
          onClickButtonSubmit={() => {
            removeAdmin();
            setDeleteReq(false);
          }}
          onClickButtonClose={() => setDeleteReq(false)}
          title="Вы уверены, что хотите удалить администратора"
          buttonLeftText="Отмена"
          buttonRightText="Удалить"
        />
      )}
    </Main>
  );
};
