import { request } from '@/helpers/api/request';

export const locationEvent = async (data) => {
  const response = await request({
    url: `event`,
    method: 'POST',
    data,
  });

  return response;
};
