/* eslint-disable no-shadow */
import { throttle } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { DatePicker } from '../../../../../components';
import { StyledInput } from '../../../../../components/styled-input';
import { AreaAndDistrictOptions, timeDate } from '../../../../../constants/Component';
import { TypesIcon } from '../../../../../types/TypesIcon';
import {
  BackButton,
  CircleButton,
  DynamicImageSlider,
  Icon,
  ScrollButtonsContainer,
  Textarea,
} from '../../../../shared';
import { px } from '../../../../utils';
import { useProfile } from '../../../hooks';
import { getInitialFields, mapGuideToFields } from './helpers/guide.helpers';
import { updateGuide } from './services/guide.service';

const getSrc = (fields, key) => fields?.attachments?.find?.(att => att?.key === key)?.src;

export const GuideForm = ({ guide }) => {
  const [fields, setFields] = useState();
  const [id, setId] = useState();
  const [isPeriod, setIsPeriod] = useState('');
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [schemasIndex, setSchemasIndex] = useState(0);

  const [showClearButton, setShowClearButton] = useState(false);
  const scrollValue = useRef(0);

  useLayoutEffect(() => {
    setFields(mapGuideToFields(guide));
  }, [JSON.stringify(guide)]);

  useEffect(() => {
    const scrollHandler = throttle(() => {
      setShowClearButton(
        JSON.stringify(getInitialFields(guide)) !== JSON.stringify(fields) &&
          !(window.scrollY > scrollValue.current && scrollValue.current > 500),
      );
      scrollValue.current = window.scrollY;
    }, 200);

    scrollHandler();

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [fields, guide]);

  const { updateFunction: updateProfileFunction } = useProfile();

  const navigate = useNavigate();

  const optionAreaDistrictFilter = (id, country) => {
    if (country === '') {
      return [];
    }
    return id === 2 && country === 'Россия'
      ? AreaAndDistrictOptions.russiaDistricts
      : id === 3 && country === 'Россия'
        ? AreaAndDistrictOptions.regionsAndAreas[fields?.contact?.region] || []
        : id === 3 && country === 'Беларусь'
          ? AreaAndDistrictOptions.belarusAreas
          : AreaAndDistrictOptions.kazachstanAreas;
  };

  const openPicker = (id_, label) => {
    setId(id_);
    setIsPeriod(label);
    setShow(true);
  };

  const setMainPhoneStartTime = value =>
    setFields({ ...fields, contact: { ...fields.contact, mainPhoneStartTime: value?.hour } });

  const setMainPhoneEndTime = value =>
    setFields({ ...fields, contact: { ...fields.contact, mainPhoneEndTime: value?.hour } });

  const setSecondaryPhoneStartTime = value =>
    setFields({
      ...fields,
      contact: { ...fields.contact, secondaryPhoneStartTime: value?.hour },
    });

  const setSecondaryPhoneEndTime = value =>
    setFields({ ...fields, contact: { ...fields.contact, secondaryPhoneEndTime: value?.hour } });

  return (
    <>
      <DatePicker
        singleItem
        title="Время. Период"
        setShow={setShow}
        show={show}
        data={timeDate}
        setPickerValue={
          id === 6 && isPeriod === 'start'
            ? setMainPhoneStartTime
            : id === 6 && isPeriod === 'end'
              ? setMainPhoneEndTime
              : id === 7 && isPeriod === 'start'
                ? setSecondaryPhoneStartTime
                : id === 7 && isPeriod === 'end'
                  ? setSecondaryPhoneEndTime
                  : () => {}
        }
        pickerValue={{
          hour:
            id === 6 && isPeriod === 'start'
              ? fields?.contact?.mainPhoneStartTime
              : id === 6 && isPeriod === 'end'
                ? fields?.contact?.mainPhoneEndTime
                : id === 7 && isPeriod === 'start'
                  ? fields?.contact?.secondaryPhoneStartTime
                  : id === 7 && isPeriod === 'end'
                    ? fields?.contact?.secondaryPhoneEndTime
                    : '',
        }}
        buttonTitle="Применить"
        isLine
      />
      <div className="fixed top-0 z-10 w-full bg-[white]">
        <BackButton
          onClick={async () => {
            if (!isLoading) {
              setIsLoading(true);
              await updateGuide(dispatch, guide, fields);
              await updateProfileFunction();
              setIsLoading(false);
              navigate(`/user-guide`);
            }
          }}
        />
      </div>
      <div
        style={{
          paddingBottom: px(80),
          paddingTop: px(80),
        }}
      >
        <div style={{ paddingRight: px(15), paddingLeft: px(15) }}>
          <p
            style={{
              fontSize: px(32),
              lineHeight: px(32),
            }}
            className="whitespace-pre-wrap  font-light"
          >
            Название. Фото. Описание объекта
          </p>
          <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
            Работа с сервисом будет возможна, если Вы заполните все формы
          </p>
          <Textarea
            placeholder="Введите текст"
            title="Название"
            noEnter
            style={{
              height: px(114),
              marginTop: px(20),
              marginBottom: px(10),
              paddingTop: px(11),
              paddingLeft: px(18),
              paddingRight: px(18),
            }}
            value={fields?.name}
            onChange={value => setFields({ ...fields, name: value })}
          />
          <Textarea
            placeholder="Введите текст"
            title="Описание"
            textareaHight={px(280)}
            style={{
              height: px(330),
              paddingTop: px(4),
              paddingLeft: px(18),
              paddingRight: px(14),
              paddingBottom: px(14),
            }}
            value={fields?.description}
            onChange={value => setFields({ ...fields, description: value })}
          />

          <div className="flex flex-col" style={{ marginTop: px(25), marginBottom: px(26.26) }}>
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Логотип. Открытка, …
            </p>
            <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
              Редактируйте по своему усмотрению
            </p>
          </div>

          <div
            className="flex flex-row items-center"
            style={{ gap: px(16.86), marginTop: px(33), marginBottom: px(26.26) }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 24"
              width={px(31.14)}
              height={px(27.74)}
            />

            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Логотип. Открытка, …
            </p>
          </div>
          <div
            style={{
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <DynamicImageSlider
              items={[
                {
                  label: 'Логотип',
                  key: 'logo',
                  src: getSrc(fields, 'logo'),
                  crop: {
                    width: 130,
                    height: 130,
                    style: {
                      borderRadius: px(15),
                    },
                  },
                },
                {
                  label: 'Открытка',
                  key: 'postcard',
                  src: getSrc(fields, 'postcard'),
                  crop: {
                    width: 135,
                    height: 290,
                  },
                },
                {
                  label: 'Обложка',
                  key: 'banner',
                  src: getSrc(fields, 'banner'),
                  crop: {
                    width: 320,
                    height: 183,
                  },
                },
              ]}
              onChange={files => setFields({ ...fields, attachments: files })}
            />
          </div>

          <div
            className="flex flex-row items-center"
            style={{ gap: px(16.86), marginTop: px(26), marginBottom: px(23.26) }}
          >
            <Icon
              type={TypesIcon.GALLERY}
              viewBox="0 0 26.827 24"
              width={px(31.14)}
              height={px(27.74)}
            />

            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Галерея
            </p>
          </div>

          <div
            style={{
              marginLeft: px(-15),
              marginRight: px(-15),
              marginBottom: px(40),
            }}
          >
            <DynamicImageSlider
              items={fields?.gallery?.map(({ src, first }) => ({ src, first: !!first }))}
              onChange={files => setFields({ ...fields, gallery: files })}
            />
          </div>

          <p
            style={{
              fontSize: px(32),
              lineHeight: px(43),
            }}
            className="whitespace-pre-wrap  font-light"
          >
            Карты. Схемы. …
          </p>
          <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
            Дополнительные материалы
            <br />
            улучшат привлекательность и сервис
          </p>

          <div
            style={{
              marginTop: px(20),
              marginBottom: px(40),
              marginLeft: px(-15),
              marginRight: px(-15),
            }}
          >
            <DynamicImageSlider
              items={fields?.schemas?.map(src => ({
                src,
              }))}
              onEdit={() => {
                navigate(`/schemas`, { state: { guide, fields, schemasIndex } });
              }}
              onAdd={() => {
                navigate(`/schemas`, { state: { guide, fields, schemasIndex: -1 } });
              }}
              crop={{
                width: 141,
                height: 216,
              }}
              onSwipe={setSchemasIndex}
            />
          </div>
          <div className="relative">
            <div id="contacts" className="absolute" style={{ top: px(-60) }} />
          </div>
          <p
            className="whitespace-pre-wrap font-light"
            style={{ fontSize: px(32), lineHeight: px(43) }}
          >
            Контакты
          </p>
          <p
            className="whitespace-pre-wrap font-normal"
            style={{ fontSize: px(15), lineHeight: px(20) }}
          >
            Страница станет доступна миру,
            <br />а доверие и уверенность возрастет
          </p>
          <form
            style={{
              paddingBottom: px(65),
              gap: px(10),
              marginTop: px(20),
            }}
            className="flex flex-col"
          >
            <StyledInput
              isBorder
              id={1}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.country}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: {
                    ...fields.contact,
                    country: e.target.value,
                    region: '',
                    district: '',
                  },
                })
              }
              options={['Россия', 'Беларусь', 'Казахстан']}
              title="Страна"
              textOpacity={50}
              placeholder="Выбор из списка"
              placeholderOpacity
            />
            <StyledInput
              isBorder
              id={2}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.region}
              onChange={e => {
                const newFields = {
                  ...fields,
                  contact: {
                    ...fields.contact,
                    region: e.target.value,
                    district: e.target.value ? fields.contacts.district : '',
                  },
                };
                setFields(newFields);
              }}
              options={optionAreaDistrictFilter(2, fields?.contact?.country)}
              title="Регион"
              textOpacity={50}
              placeholder="Выбор из списка"
              placeholderOpacity
              disabled={
                fields?.contact?.country === 'Беларусь' ||
                fields?.contact?.country === 'Казахстан' ||
                !fields?.contact?.country
              }
            />
            <StyledInput
              isBorder
              id={3}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.district}
              onChange={e =>
                setFields({ ...fields, contact: { ...fields.contact, district: e.target.value } })
              }
              options={optionAreaDistrictFilter(3, fields?.contact?.country)}
              title="Область"
              textOpacity={50}
              placeholder="Выбор из списка"
              placeholderOpacity
              disabled={
                !fields?.contact?.country ||
                (fields?.contact?.country === 'Россия' && !fields?.contact?.region)
              }
            />
            <StyledInput
              isBorder
              id={4}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.town}
              onChange={e =>
                setFields({ ...fields, contact: { ...fields.contact, town: e.target.value } })
              }
              title="Город. Поселок"
              textOpacity={50}
              placeholder=""
              placeholderOpacity
            />
            <StyledInput
              isBorder
              id={5}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.street}
              onChange={e =>
                setFields({ ...fields, contact: { ...fields.contact, street: e.target.value } })
              }
              title="Улица, дом, корпус, ..."
              textOpacity={50}
              placeholder="Ввод через запятую"
              placeholderOpacity
            />
            <StyledInput
              isBorder
              id={6}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.mainPhone}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, mainPhone: e.target.value },
                })
              }
              title="Телефон 1"
              textOpacity={50}
              placeholder={
                fields?.contact?.country === 'Беларусь'
                  ? '+375 (XX) XXX XX XX'
                  : '+7 (XXX) XXX XX XX'
              }
              placeholderOpacity
              phoneInput
              bigTitle
              withDatePicker
              timePhone={{ hour: fields?.contact?.mainPhoneStartTime }}
              endTimePhone={{ hour: fields?.contact?.mainPhoneEndTime }}
              openPicker={openPicker}
            />
            <StyledInput
              isBorder
              id={7}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.secondaryPhone}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, secondaryPhone: e.target.value },
                })
              }
              title="Телефон 2"
              textOpacity={50}
              placeholder={
                fields?.contact?.country === 'Беларусь'
                  ? '+375 (XX) XXX XX XX'
                  : '+7 (XXX) XXX XX XX'
              }
              placeholderOpacity
              phoneInput
              bigTitle
              withDatePicker
              timePhone={{ hour: fields?.contact?.secondaryPhoneStartTime }}
              endTimePhone={{ hour: fields?.contact?.secondaryPhoneEndTime }}
              openPicker={openPicker}
            />
            <StyledInput
              isBorder
              id={8}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.email}
              onChange={e =>
                setFields({ ...fields, contact: { ...fields.contact, email: e.target.value } })
              }
              title="Почта электронная"
              textOpacity={50}
              placeholder="XXXX@XXX..."
              placeholderOpacity
              letEmailValidation
            />
            <StyledInput
              isBorder
              id={9}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.whatsAppLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, whatsAppLink: e.target.value },
                })
              }
              title="Ссылка на чат WhatsApp"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={10}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.telegramLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, telegramLink: e.target.value },
                })
              }
              title="Ссылка на чат в Telegram"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={11}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.viberLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, viberLink: e.target.value },
                })
              }
              title="Ссылка на чат в Viber"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={12}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.coordinates}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, coordinates: e.target.value },
                })
              }
              title="Геолокация. Координаты"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={13}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.socialPageLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, socialPageLink: e.target.value },
                })
              }
              title="Страница в соц. сети"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              isBorder
              id={14}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.websiteLink}
              onChange={e =>
                setFields({
                  ...fields,
                  contact: { ...fields.contact, websiteLink: e.target.value },
                })
              }
              title="Web-страница сайта"
              textOpacity={50}
              placeholder="Прикрепить"
              placeholderOpacity
              bluePlaceholder
            />
            <StyledInput
              id={15}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.contact?.cabinetId}
              onChange={() => {}}
              title="ID кабинета"
              textOpacity={50}
              placeholder=""
              bigTitle
              placeholderOpacity
              disabled
            />
            <StyledInput
              id={16}
              country={fields?.contact?.country || 'Россия'}
              value={fields?.createdById}
              onChange={() => {}}
              title="ID кабинета Управляющего"
              textOpacity={50}
              placeholder=""
              bigTitle
              placeholderOpacity
              disabled
            />
          </form>
          <ScrollButtonsContainer>
            <CircleButton
              onClick={() => setFields(getInitialFields(guide))}
              icon={{
                type: TypesIcon.ROUNDEDRETURN,
                viewBox: '0 0 28.451 26.164',
                width: px(28.451),
                height: px(26.164),
              }}
            />
          </ScrollButtonsContainer>
        </div>
      </div>
    </>
  );
};
export default GuideForm;
