import React from 'react';

export const CottageIcon = props => (
  <svg
    viewBox="0 0 48 27"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M16.4126 26.7091L4.5415 26.7083V16.7767L0.191895 19.4389L0 15.7903L4.5415 13.0111V13.0056L4.54248 10.1752C4.54248 9.52612 5.07056 8.99805 5.71948 8.99805C6.36816 8.99805 6.896 9.52612 6.896 10.1752V11.5999L22.6558 2.18506V1.17523C22.6558 0.526123 23.1838 -0.00195312 23.833 -0.00195312C24.4822 -0.00195312 25.0103 0.526123 25.0103 1.17523V2.18594L40.7693 11.5999V10.1752C40.7693 9.52612 41.2974 8.99805 41.9463 8.99805C42.5955 8.99805 43.1235 9.52612 43.1235 10.1752V13.0849L47.5435 15.7903L47.3528 19.4389L43.1228 16.8506V26.7073L22.3479 26.7083V24.0136H40.5398V22.444V15.5374H40.447L40.4739 15.4933L23.7295 5.41605L23.6548 5.29095L23.5881 5.33145L23.5225 5.29095L23.446 5.41605L6.7041 15.4933L6.73169 15.5393L6.6377 15.5392V24.0146H8.20728L16.4126 24.0136V26.7081V26.7091ZM16.4126 22.445L8.20728 22.444V16.4204L23.5881 7.16473L38.97 16.4203V22.443L22.3479 22.444V14.8391H16.4126V22.444V22.445ZM25.9436 15.0667V20.7169H32.8491V15.0667H25.9436Z"
      fill="currentColor"
    />
  </svg>
);
