import React from "react"
import Option from "./option"

export const SearchMenuItemPreferencesOption = ({ option, onSelect }) => {
    return (
        <div
            onClick={() => {
                onSelect(option)
            }}
            className='search-menu-item-preferences-option-border'
        >
            <Option className='search-menu-item-preferences-option'>
                {option}
            </Option>
        </div>
    )
}
