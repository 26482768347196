import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const BLOCK_EVENT = 'event/blockEvent';

export const blockEvent = createAsyncThunk(
  BLOCK_EVENT,
  createRequest({
    type: BLOCK_EVENT,
    loader: (data) => authApi.blockEvent(data),
  }),
);
