/* eslint-disable import/no-unresolved */
/* eslint-disable sonarjs/prefer-immediate-return */
import { request } from '@/helpers/api/request';

export const addCompetitionAdmin = async data => {
  const response = await request({
    url: `competition-admin/invite`,
    method: 'POST',
    data,
  });

  return response;
};
