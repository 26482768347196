import React, { useMemo, useState } from 'react';

import { Button, Picker } from '../../../../../../../../shared';
import { px } from '../../../../../../../../utils';
import { useProfile } from '../../../../../../../hooks';

export const CreateSimilar = ({ onSubmit }) => {
  const [showPicker, setShowPicker] = useState(false);
  const {
    data: { locations, selectedLocationId },
  } = useProfile();

  const selectedLocation = useMemo(
    () => locations?.find(location => location.id === selectedLocationId),
    [JSON.stringify(locations), selectedLocationId],
  );

  const [value, setValue] = useState();

  return (
    <>
      <p className="font-light" style={{ fontSize: px(32), lineHeight: px(43) }}>
        Создать похожий
      </p>
      <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
        Выберите из списка уже созданных объектов похожий и примените те же условия
      </p>

      <div
        className="flex-column flex"
        style={{ marginTop: px(20), gap: px(20), marginBottom: px(40) }}
      >
        <div className="flex flex-row items-center justify-between">
          <p className="font-normal opacity-50 " style={{ fontSize: px(20), lineHeight: px(26) }}>
            Выбрать из списка
          </p>
          <p
            aria-hidden
            onClick={() => selectedLocation?.sections?.length && setShowPicker(true)}
            className={`${!value && 'text-[#FA0303]'} font-light text-[#0A84FE]`}
            style={{ fontSize: px(20), lineHeight: px(26) }}
          >
            {value?.split(':')?.[1] || '0'}
          </p>
          <Picker
            singleItem
            title="Похожие сектора"
            show={showPicker}
            onClose={() => setShowPicker(false)}
            data={{
              similarSector: selectedLocation.sections?.map?.(
                sector => `${sector?.id}:${sector?.number}`,
              ),
            }}
            onChange={({ similarSector }) => setValue(similarSector)}
            value={{
              similarSector: value || '0',
            }}
            button
            isLine
          />
        </div>
        <div className="flex flex-row items-center justify-between">
          <Button
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#000"
            bgColor="#fff"
            onClick={() => setValue()}
          >
            Очистить
          </Button>
          <Button
            style={{ width: px(141) }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#fff"
            disabled={!value}
            onClick={() =>
              onSubmit(
                selectedLocation?.sections?.find(
                  s => s?.id === Number?.parseInt(value?.split(':')?.[0], 10),
                ),
              )
            }
          >
            Применить
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateSimilar;
