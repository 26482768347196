import { getCompetitionCabinet, getCompetitionEvent } from '../../api/competition';

export const getCompetition = async ({ competitionId }) => {
  const [cabinet, competitions] = (
    await Promise.allSettled([
      getCompetitionCabinet({ id: competitionId }),
      getCompetitionEvent({ id: competitionId }),
    ])
  )
    // eslint-disable-next-line unicorn/no-await-expression-member
    .map(item => item?.value?.data); // TODO: current object

  // Inject sectors for each location with their group
  /* const injectedLocations = await Promise.all(
    locations?.map?.(location => ({
      ...location,
      sections: location?.sections?.map?.(sector => ({
        ...sector,
        // priceGroup: location?.sectionGroups?.find?.(({ id }) => id === sector?.sectionGroupId),
        accessGroup: location?.allowanceSectionGroups?.find?.(
          ({ id }) => id === sector?.sectionAllowanceGroupId,
        ),
      })),
    })),
  ); */

  return {
    cabinet,
    competitions,
  };
};
