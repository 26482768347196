import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const throttledHandleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    function handleResize() {
      throttledHandleResize();
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      throttledHandleResize.cancel();
    };
  }, []);

  return { ...windowSize };
}
