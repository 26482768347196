import React from 'react';

export const ShoreIcon = props => (
  <svg
    viewBox="0 0 31 29"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4031_1715)">
      <g opacity="0.5">
        <path d="M15.3066 17.547V13.853H9.00264V17.547H15.3066Z" fill="currentColor" />
        <path
          d="M14.3066 14.777H10.0066V16.624H14.3066V14.777ZM15.3066 13.853V17.547H9.00664V13.853H15.3066Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path d="M8.54883 17.547V13.853H-0.000171661V17.547H8.54883Z" fill="currentColor" />
        <path
          d="M7.54883 14.777H0.999828V16.624H7.54883V14.777ZM8.54883 13.853V17.547H-0.000171661V13.853H8.54883Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M18.3047 1C18.3047 0.447715 17.857 0 17.3047 0C16.7524 0 16.3047 0.447715 16.3047 1V3.618C16.3047 4.17028 16.7524 4.618 17.3047 4.618C17.857 4.618 18.3047 4.17028 18.3047 3.618V1Z"
          fill="currentColor"
        />
        <path
          d="M17.3047 -2.89576e-05C17.5597 -0.00977275 17.8082 0.0820288 17.9957 0.255244C18.1832 0.428459 18.2943 0.668947 18.3047 0.923971V3.69397C18.2943 3.94899 18.1832 4.18948 17.9957 4.3627C17.8082 4.53591 17.5597 4.62771 17.3047 4.61797C17.0496 4.62771 16.8011 4.53591 16.6137 4.3627C16.4262 4.18948 16.3151 3.94899 16.3047 3.69397V0.923971C16.3151 0.668947 16.4262 0.428459 16.6137 0.255244C16.8011 0.0820288 17.0496 -0.00977275 17.3047 -2.89576e-05Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M21.3047 1C21.3047 0.447715 20.857 0 20.3047 0C19.7524 0 19.3047 0.447715 19.3047 1V11.93C19.3047 12.4823 19.7524 12.93 20.3047 12.93C20.857 12.93 21.3047 12.4823 21.3047 11.93V1Z"
          fill="currentColor"
        />
        <path
          d="M20.3047 -2.89576e-05C20.5597 -0.00977275 20.8082 0.0820288 20.9957 0.255244C21.1832 0.428459 21.2943 0.668947 21.3047 0.923971V12.006C21.2943 12.261 21.1832 12.5015 20.9957 12.6747C20.8082 12.8479 20.5597 12.9397 20.3047 12.93C20.0496 12.9397 19.8011 12.8479 19.6137 12.6747C19.4262 12.5015 19.3151 12.261 19.3047 12.006V0.923971C19.3151 0.668947 19.4262 0.428459 19.6137 0.255244C19.8011 0.0820288 20.0496 -0.00977275 20.3047 -2.89576e-05Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M27.3047 1C27.3047 0.447715 26.857 0 26.3047 0C25.7524 0 25.3047 0.447715 25.3047 1V7.312C25.3047 7.86428 25.7524 8.312 26.3047 8.312C26.857 8.312 27.3047 7.86428 27.3047 7.312V1Z"
          fill="currentColor"
        />
        <path
          d="M26.3047 -2.89576e-05C26.5597 -0.00977275 26.8082 0.0820288 26.9957 0.255244C27.1832 0.428459 27.2943 0.668947 27.3047 0.923971V7.38797C27.2943 7.64299 27.1832 7.88348 26.9957 8.0567C26.8082 8.22991 26.5597 8.32172 26.3047 8.31197C26.0496 8.32172 25.8011 8.22991 25.6137 8.0567C25.4262 7.88348 25.3151 7.64299 25.3047 7.38797V0.923971C25.3151 0.668947 25.4262 0.428459 25.6137 0.255244C25.8011 0.0820288 26.0496 -0.00977275 26.3047 -2.89576e-05Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M18.3047 6.54102C18.3047 5.98873 17.857 5.54102 17.3047 5.54102C16.7524 5.54102 16.3047 5.98873 16.3047 6.54102V12.853C16.3047 13.4053 16.7524 13.853 17.3047 13.853C17.857 13.853 18.3047 13.4053 18.3047 12.853V6.54102Z"
          fill="currentColor"
        />
        <path
          d="M17.3047 5.54099C17.5597 5.53124 17.8082 5.62304 17.9957 5.79626C18.1832 5.96947 18.2943 6.20996 18.3047 6.46499V12.929C18.2943 13.184 18.1832 13.4245 17.9957 13.5977C17.8082 13.7709 17.5597 13.8627 17.3047 13.853C17.0496 13.8627 16.8011 13.7709 16.6137 13.5977C16.4262 13.4245 16.3151 13.184 16.3047 12.929V6.46499C16.3151 6.20996 16.4262 5.96947 16.6137 5.79626C16.8011 5.62304 17.0496 5.53124 17.3047 5.54099Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M24.3047 1C24.3047 0.447715 23.857 0 23.3047 0C22.7524 0 22.3047 0.447715 22.3047 1V10.083C22.3047 10.6353 22.7524 11.083 23.3047 11.083C23.857 11.083 24.3047 10.6353 24.3047 10.083V1Z"
          fill="currentColor"
        />
        <path
          d="M23.3047 -2.89576e-05C23.5597 -0.00977275 23.8082 0.0820288 23.9957 0.255244C24.1832 0.428459 24.2943 0.668947 24.3047 0.923971V10.16C24.2943 10.415 24.1832 10.6555 23.9957 10.8287C23.8082 11.0019 23.5597 11.0937 23.3047 11.084C23.0496 11.0937 22.8011 11.0019 22.6137 10.8287C22.4262 10.6555 22.3151 10.415 22.3047 10.16V0.923971C22.3151 0.668947 22.4262 0.428459 22.6137 0.255244C22.8011 0.0820288 23.0496 -0.00977275 23.3047 -2.89576e-05Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M27.3047 10.235C27.3047 9.6827 26.857 9.23499 26.3047 9.23499C25.7524 9.23499 25.3047 9.6827 25.3047 10.235V15.623C25.3047 16.1753 25.7524 16.623 26.3047 16.623C26.857 16.623 27.3047 16.1753 27.3047 15.623V10.235Z"
          fill="currentColor"
        />
        <path
          d="M26.3047 9.23496C26.5597 9.22521 26.8082 9.31701 26.9957 9.49023C27.1832 9.66344 27.2943 9.90393 27.3047 10.159V15.7C27.2943 15.955 27.1832 16.1955 26.9957 16.3687C26.8082 16.5419 26.5597 16.6337 26.3047 16.624C26.0496 16.6337 25.8011 16.5419 25.6137 16.3687C25.4262 16.1955 25.3151 15.955 25.3047 15.7V10.159C25.3151 9.90393 25.4262 9.66344 25.6137 9.49023C25.8011 9.31701 26.0496 9.22521 26.3047 9.23496Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M30.3047 1C30.3047 0.447715 29.857 0 29.3047 0C28.7524 0 28.3047 0.447715 28.3047 1V12.853C28.3047 13.4053 28.7524 13.853 29.3047 13.853C29.857 13.853 30.3047 13.4053 30.3047 12.853V1Z"
          fill="currentColor"
        />
        <path
          d="M29.3047 -2.89576e-05C29.5597 -0.00977275 29.8082 0.0820288 29.9957 0.255244C30.1832 0.428459 30.2943 0.668947 30.3047 0.923971V12.93C30.2943 13.185 30.1832 13.4255 29.9957 13.5987C29.8082 13.7719 29.5597 13.8637 29.3047 13.854C29.0496 13.8637 28.8011 13.7719 28.6137 13.5987C28.4262 13.4255 28.3151 13.185 28.3047 12.93V0.923971C28.3151 0.668947 28.4262 0.428459 28.6137 0.255244C28.8011 0.0820288 29.0496 -0.00977275 29.3047 -2.89576e-05Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M18.3047 15.777C18.3047 15.2247 17.857 14.777 17.3047 14.777C16.7524 14.777 16.3047 15.2247 16.3047 15.777V18.395C16.3047 18.9473 16.7524 19.395 17.3047 19.395C17.857 19.395 18.3047 18.9473 18.3047 18.395V15.777Z"
          fill="currentColor"
        />
        <path
          d="M17.3047 14.7769C17.5597 14.7672 17.8082 14.859 17.9957 15.0322C18.1832 15.2054 18.2943 15.4459 18.3047 15.7009V18.4709C18.2943 18.726 18.1832 18.9665 17.9957 19.1397C17.8082 19.3129 17.5597 19.4047 17.3047 19.3949C17.0496 19.4047 16.8011 19.3129 16.6137 19.1397C16.4262 18.9665 16.3151 18.726 16.3047 18.4709V15.7009C16.3151 15.4459 16.4262 15.2054 16.6137 15.0322C16.8011 14.859 17.0496 14.7672 17.3047 14.7769Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M21.3047 14.853C21.3047 14.3007 20.857 13.853 20.3047 13.853C19.7524 13.853 19.3047 14.3007 19.3047 14.853V20.241C19.3047 20.7933 19.7524 21.241 20.3047 21.241C20.857 21.241 21.3047 20.7933 21.3047 20.241V14.853Z"
          fill="currentColor"
        />
        <path
          d="M20.3047 13.853C20.5597 13.8433 20.8082 13.9351 20.9957 14.1083C21.1832 14.2815 21.2943 14.522 21.3047 14.777V20.318C21.2943 20.573 21.1832 20.8135 20.9957 20.9867C20.8082 21.1599 20.5597 21.2517 20.3047 21.242C20.0496 21.2517 19.8011 21.1599 19.6137 20.9867C19.4262 20.8135 19.3151 20.573 19.3047 20.318V14.777C19.3151 14.522 19.4262 14.2815 19.6137 14.1083C19.8011 13.9351 20.0496 13.8433 20.3047 13.853Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M27.3047 18.547C27.3047 17.9947 26.857 17.547 26.3047 17.547C25.7524 17.547 25.3047 17.9947 25.3047 18.547V20.241C25.3047 20.7933 25.7524 21.241 26.3047 21.241C26.857 21.241 27.3047 20.7933 27.3047 20.241V18.547Z"
          fill="currentColor"
        />
        <path
          d="M26.3047 17.547C26.5597 17.5372 26.8082 17.629 26.9957 17.8022C27.1832 17.9755 27.2943 18.2159 27.3047 18.471V20.318C27.2943 20.573 27.1832 20.8135 26.9957 20.9867C26.8082 21.1599 26.5597 21.2517 26.3047 21.242C26.0496 21.2517 25.8011 21.1599 25.6137 20.9867C25.4262 20.8135 25.3151 20.573 25.3047 20.318V18.471C25.3151 18.2159 25.4262 17.9755 25.6137 17.8022C25.8011 17.629 26.0496 17.5372 26.3047 17.547Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M30.3047 15.777C30.3047 15.2247 29.857 14.777 29.3047 14.777C28.7524 14.777 28.3047 15.2247 28.3047 15.777V22.089C28.3047 22.6413 28.7524 23.089 29.3047 23.089C29.857 23.089 30.3047 22.6413 30.3047 22.089V15.777Z"
          fill="currentColor"
        />
        <path
          d="M29.3047 14.7769C29.5597 14.7672 29.8082 14.859 29.9957 15.0322C30.1832 15.2054 30.2943 15.4459 30.3047 15.7009V22.1649C30.2943 22.42 30.1832 22.6605 29.9957 22.8337C29.8082 23.0069 29.5597 23.0987 29.3047 23.0889C29.0496 23.0987 28.8011 23.0069 28.6137 22.8337C28.4262 22.6605 28.3151 22.42 28.3047 22.1649V15.7009C28.3151 15.4459 28.4262 15.2054 28.6137 15.0322C28.8011 14.859 29.0496 14.7672 29.3047 14.7769Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M18.3047 21.318C18.3047 20.7657 17.857 20.318 17.3047 20.318C16.7524 20.318 16.3047 20.7657 16.3047 21.318V27.63C16.3047 28.1823 16.7524 28.63 17.3047 28.63C17.857 28.63 18.3047 28.1823 18.3047 27.63V21.318Z"
          fill="currentColor"
        />
        <path
          d="M17.3047 20.318C17.5597 20.3082 17.8082 20.4 17.9957 20.5732C18.1832 20.7465 18.2943 20.9869 18.3047 21.242V27.706C18.2943 27.961 18.1832 28.2015 17.9957 28.3747C17.8082 28.5479 17.5597 28.6397 17.3047 28.63C17.0496 28.6397 16.8011 28.5479 16.6137 28.3747C16.4262 28.2015 16.3151 27.961 16.3047 27.706V21.242C16.3151 20.9869 16.4262 20.7465 16.6137 20.5732C16.8011 20.4 17.0496 20.3082 17.3047 20.318Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M21.3047 23.165C21.3047 22.6128 20.857 22.165 20.3047 22.165C19.7524 22.165 19.3047 22.6128 19.3047 23.165V27.63C19.3047 28.1823 19.7524 28.63 20.3047 28.63C20.857 28.63 21.3047 28.1823 21.3047 27.63V23.165Z"
          fill="currentColor"
        />
        <path
          d="M20.3047 22.165C20.5597 22.1553 20.8082 22.2471 20.9957 22.4203C21.1832 22.5935 21.2943 22.834 21.3047 23.089V27.706C21.2943 27.961 21.1832 28.2015 20.9957 28.3747C20.8082 28.548 20.5597 28.6398 20.3047 28.63C20.0496 28.6398 19.8011 28.548 19.6137 28.3747C19.4262 28.2015 19.3151 27.961 19.3047 27.706V23.089C19.3151 22.834 19.4262 22.5935 19.6137 22.4203C19.8011 22.2471 20.0496 22.1553 20.3047 22.165Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M24.3047 13.006C24.3047 12.4537 23.857 12.006 23.3047 12.006C22.7524 12.006 22.3047 12.4537 22.3047 13.006V27.63C22.3047 28.1823 22.7524 28.63 23.3047 28.63C23.857 28.63 24.3047 28.1823 24.3047 27.63V13.006Z"
          fill="currentColor"
        />
        <path
          d="M23.3047 12.006C23.5597 11.9962 23.8082 12.088 23.9957 12.2612C24.1832 12.4344 24.2943 12.6749 24.3047 12.93V27.706C24.2943 27.961 24.1832 28.2015 23.9957 28.3747C23.8082 28.5479 23.5597 28.6397 23.3047 28.63C23.0496 28.6397 22.8011 28.5479 22.6137 28.3747C22.4262 28.2015 22.3151 27.961 22.3047 27.706V12.93C22.3151 12.6749 22.4262 12.4344 22.6137 12.2612C22.8011 12.088 23.0496 11.9962 23.3047 12.006Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M27.3047 23.165C27.3047 22.6128 26.857 22.165 26.3047 22.165C25.7524 22.165 25.3047 22.6128 25.3047 23.165V27.63C25.3047 28.1823 25.7524 28.63 26.3047 28.63C26.857 28.63 27.3047 28.1823 27.3047 27.63V23.165Z"
          fill="currentColor"
        />
        <path
          d="M26.3047 22.165C26.5597 22.1553 26.8082 22.2471 26.9957 22.4203C27.1832 22.5935 27.2943 22.834 27.3047 23.089V27.706C27.2943 27.961 27.1832 28.2015 26.9957 28.3747C26.8082 28.548 26.5597 28.6398 26.3047 28.63C26.0496 28.6398 25.8011 28.548 25.6137 28.3747C25.4262 28.2015 25.3151 27.961 25.3047 27.706V23.089C25.3151 22.834 25.4262 22.5935 25.6137 22.4203C25.8011 22.2471 26.0496 22.1553 26.3047 22.165Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M30.3047 25.012C30.3047 24.4597 29.857 24.012 29.3047 24.012C28.7524 24.012 28.3047 24.4597 28.3047 25.012V27.63C28.3047 28.1822 28.7524 28.63 29.3047 28.63C29.857 28.63 30.3047 28.1822 30.3047 27.63V25.012Z"
          fill="currentColor"
        />
        <path
          d="M29.3047 24.0119C29.5597 24.0022 29.8082 24.094 29.9957 24.2672C30.1832 24.4404 30.2943 24.6809 30.3047 24.9359V27.7059C30.2943 27.961 30.1832 28.2014 29.9957 28.3747C29.8082 28.5479 29.5597 28.6397 29.3047 28.6299C29.0496 28.6397 28.8011 28.5479 28.6137 28.3747C28.4262 28.2014 28.3151 27.961 28.3047 27.7059V24.9359C28.3151 24.6809 28.4262 24.4404 28.6137 24.2672C28.8011 24.094 29.0496 24.0022 29.3047 24.0119Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4031_1715">
        <rect width="30.305" height="28.63" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
