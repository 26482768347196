
import { createSelector } from '@reduxjs/toolkit';


export const selectRoot = ({ visit }) => visit;

export const visitSelector = createSelector(selectRoot, (visit) => visit?.visit);



