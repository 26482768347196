import React, { useContext } from 'react';

import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { ResultsListItem } from './results-list-item';
import { SearchInputResultsNoItem } from './search-input-results-no-item';
import { StateContext } from './state-provider';

export const ResultsList = ({ setResultsListOpen }) => {
  const { resultsListItems } = useContext(StateContext);
  return (
    <div className="results-list-container-overflow">
      <div className="results-list-container">
        <div className="close-popup-line" onClick={() => setResultsListOpen(false)} aria-hidden>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4">
            <line
              id="Линия_141"
              data-name="Линия 141"
              x2="30"
              transform="translate(0 2)"
              fill="none"
              stroke="#000"
              strokeWidth="4"
              opacity="0.298"
            />
          </svg>
        </div>
        <div className="results-list-content">
          {resultsListItems?.length === 0 ? (
            <SearchInputResultsNoItem />
          ) : (
            resultsListItems?.map(resultsListItem => (
              <div key={`result-${resultsListItem.id}`} style={{ marginBottom: px(17) }}>
                <ResultsListItem resultsListItem={resultsListItem} />
              </div>
            ))
          )}
          <CircleButton
            onClick={setResultsListOpen}
            icon={{
              type: TypesIcon.ROUNDEDRETURN,
              viewBox: '0 0 28.451 26.164',
              width: px(28.451),
              height: px(26.164),
              className: 'text-[#0a84fe]',
              fill: '#0a84fe',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultsList;
