/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { addModal } from '@/actions/modal';
import * as eventAdminApi from '@/api/competition';
import { createRequest } from '@/helpers/api/createRequest';

export const ADD_COMPETITION_ADMIN = 'competition/addCompetitionAdmin';

const onFail = error => async dispatch => {
  if (error) {
    await dispatch(
      addModal({
        id: 6,
        data: {
          title:
            'Такой пользователь не зарегистрирован либо уже добавлен. Вы не можете пригласить его к управлению',
          buttonLeftText: 'Ok',
          single: true,
        },
      }),
    );
  }
};

const onSuccess = data => async dispatch => {
  await dispatch(
    addModal({
      id: 9,
      data: {
        title: `Администратор ${data} успешно добавлен`,
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'account-control',
    }),
  );
};

export const addCompetitionAdmin = createAsyncThunk(
  ADD_COMPETITION_ADMIN,
  createRequest({
    type: ADD_COMPETITION_ADMIN,
    loader: data => eventAdminApi.addCompetitionAdmin(data),
    onFail,
    onSuccess: data => onSuccess(data.data.name),
  }),
);
