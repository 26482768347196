import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { AccountControl } from '../../pages/AccountControlPage';
import { UserCompetitionPage } from '../../pages/UserCompetition';
import { UserGuidePage } from '../../pages/UserGuide';
import { UserObjectPage } from '../../pages/UserObject';
import { authActiveIdSelector, authActiveType, authSelector } from '../../selectors/auth';
import { ClubCardsPage } from './club-cards';
import { ProfileProvider } from './context';
import { EditProfilePage } from './edit/edit-profile.page';
import { useProfile } from './hooks';
import { OffersEditPage, OffersPage } from './offers';
import { ProfilePage } from './profile.page';
import { getProfile } from './services/profile.service';

const ROUTES = [
  {
    path: '/profile',
    element: ProfilePage,
    private_: true,
  },
  {
    path: '/offers',
    element: OffersPage,
    private_: true,
  },
  {
    path: '/offers/edit',
    element: OffersEditPage,
    private_: true,
  },
  {
    path: '/profile/edit',
    element: EditProfilePage,
    private_: true,
  },
  {
    path: '/profile/club-cards',
    element: ClubCardsPage,
    private_: true,
  },
  {
    path: '/user-object',
    element: UserObjectPage,
    private_: true,
  },
  {
    path: '/user-competition',
    element: UserCompetitionPage,
    private_: true,
  },
  {
    path: '/user-guide',
    element: UserGuidePage,
    private_: true,
  },
  {
    path: '/account-control',
    element: AccountControl,
    private_: true,
  },
];

const Stack = () => {
  const auth = useSelector(authSelector);
  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const location = useLocation();
  const onStack = !!matchRoutes(ROUTES, location);

  const {
    data: profileData,
    setData: setProfile,
    setUpdateFunction: setUpdateProfileFunction,
  } = useProfile();

  const getData = () => getProfile({ objectId: activeId, activeType }).then(setProfile);

  useEffect(() => {
    if (onStack) {
      setUpdateProfileFunction(() => getData);
      getData();
    }
  }, [onStack]);

  return (
    <Routes>
      {ROUTES?.map(({ private_, element: Element, ...route }) => (
        <Route
          key={route.path}
          element={
            private_ ? (
              auth ? (
                <Element />
              ) : (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            ) : (
              <Element />
            )
          }
          {...route}
        />
      ))}
    </Routes>
  );
};

export const ProfileStack = () => (
  <ProfileProvider>
    <Stack />
  </ProfileProvider>
);

export default ProfileStack;
