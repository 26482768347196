import React, { useRef, useState } from 'react';

import { px } from '../constants/Px';
import { numberPx } from '../new-architecture/utils';

const scrollToTab = (ref, number) => {
  setTimeout(() => {
    const elementPosition = ref.current.getBoundingClientRect().top;
    const scrollPosition = window.pageYOffset;
    const distance = elementPosition - numberPx(number);
    window.scrollTo({
      top: scrollPosition + distance,
      behavior: 'smooth',
    });
  }, 100);
};

const SCROLL_HEIGHT = 71;

export const Tabs = ({ tabsArray, setActiveTab }) => {
  const [tab, setTab] = useState(tabsArray[0].title);
  const ref = useRef();

  const handleTabClick = tabsItem => {
    if (tabsItem.onClick) {
      scrollToTab(ref, SCROLL_HEIGHT);
      tabsItem.onClick();
    } else {
      scrollToTab(ref, SCROLL_HEIGHT);
      setTab(tabsItem?.title);
      setActiveTab(tabsItem?.title);
    }
  };

  return (
    <div>
      <div className="container relative w-full overflow-x-hidden" style={{ marginBottom: px(20) }}>
        <div
          ref={ref}
          className="scrollbar-hidden flex w-full items-center justify-start overflow-x-auto"
          style={{ gap: px(10) }}
        >
          {tabsArray?.map(
            tabsItem =>
              tabsItem && (
                <button
                  onClick={() => handleTabClick(tabsItem)}
                  key={tabsItem?.title}
                  type="button"
                  className={`${
                    tab === tabsItem?.title
                      ? 'bg-black text-white'
                      : 'text-black-500 border-[black-300]'
                  } font-normal`}
                  style={{
                    borderRadius: px(100),
                    fontSize: px(20),
                    lineHeight: px(26),
                    paddingTop: px(6),
                    paddingBottom: px(6),
                    paddingLeft: px(15),
                    paddingRight: px(15),
                    whiteSpace: 'nowrap',
                    border: '1px solid rgba(0, 0, 0, 0.30)',
                    color: `${tab === tabsItem?.title ? 'white' : 'rgba(0, 0, 0, 0.5)'}`,
                  }}
                >
                  {tabsItem?.title}
                </button>
              ),
          )}
        </div>
      </div>
      <div>
        {tabsArray?.map(
          tabsItem => (tab === tabsItem?.title || tab === tabsArray[0]?.title) && tabsItem?.body,
        )}
      </div>
    </div>
  );
};
