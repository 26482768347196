import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/profile-admin';
import { createRequest } from '../../../helpers/api/createRequest';

export const ACCEPT_ADMINS = 'profile-admin/acceptAdmins';

export const acceptAdmins = createAsyncThunk(
  ACCEPT_ADMINS,
    createRequest({
        type: ACCEPT_ADMINS,
        loader: (data) => profileAdminApi.acceptAdmins(data),
    }),
);
