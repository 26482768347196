import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';

export const PROFILE_PASSPORT = 'profile/profilePassport';

export const profilePassport = createAsyncThunk(
    PROFILE_PASSPORT,
    createRequest({
        type: PROFILE_PASSPORT,
        loader: (data) => authApi.profilePassport(data),
    }),
);