import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/section/post-section';
import { createRequest } from '../../../helpers/api/createRequest';

export const SECTION_POST = 'section/section-post';

export const sectionPost = createAsyncThunk(
  SECTION_POST,
  createRequest({
    type: SECTION_POST,
    loader: data => authApi.sectionPost(data),
  }),
);
