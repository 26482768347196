/* eslint-disable sonarjs/prefer-immediate-return */
// eslint-disable-next-line import/no-unresolved
import { request } from '@/helpers/api/request';

export const updateCompetitionsContacts = async data => {
  const response = await request({
    url: `competition-contact/${data.id}`,
    method: 'PUT',
    data: data.contactsData,
  });
  return response;
};
