/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createCompetitionEvent, updateCompetitionEvent } from '../../../../../actions/competition';
import { Button } from '../../../../../components';
import { px } from '../../../../../constants/Px';
import { authActiveIdSelector } from '../../../../../selectors/auth';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { CircleButton, PeriodSetter, ScrollButtonsContainer, Textarea } from '../../../../shared';
import { CheckboxFieldGroup } from '../../../../shared/checkbox-field-group';
import { capitalizeFirstLetter } from '../../../../utils';
import { useCompetition } from '../../../hooks';

export const InformationForm = ({ information: defaultInformation, onClose }) => {
  const dispatch = useDispatch();
  const activeId = useSelector(authActiveIdSelector);

  const { updateFunction: updateCompetition } = useCompetition();

  const {
    placeDescription,
    sponsors,
    perticipantsNumber,
    prizePool,
    contributionsAmount,
    participantsList,
    registrationProcedure,
    participationPayment,
    regulations,
    program,
    tournamentType,
    participantType,
    periodStart,
    periodEnd,
    registrationStart,
    standings,
    discipline,
    direction,
    fishType,
    rules,
  } = defaultInformation || {};

  const [calendar, setCalendar] = useState(rules ? rules[0] : '');
  const [common, setCommon] = useState(rules ? rules[1] : '');
  const [registration, setRegistration] = useState(rules ? rules[2] : '');
  const [result, setResult] = useState(rules ? rules[3] : '');
  const [rank, setRank] = useState(rules ? rules[4] : '');
  const [rule, setRule] = useState(rules ? rules[5] : '');
  const [fall, setFall] = useState(rules ? rules[6] : '');
  const [start, setStart] = useState(periodStart);
  const [end, setEnd] = useState(periodEnd);
  const [startReg, setStartReg] = useState(registrationStart);
  const [enabled, setEnabled] = useState(false);

  const [dataForRequest, setDataForRequest] = useState({
    placeDescription: '',
    sponsors: '',
    perticipantsNumber: '',
    prizePool: '',
    contributionsAmount: '',
    participantsList: '',
    registrationProcedure: '',
    participationPayment: '',
    regulations: '',
    program: '',
    periodStart: '',
    periodEnd: '',
    registrationStart: '',
    tournamentType: [],
    participantType: [],
    standings: [],
    discipline: [],
    direction: [],
    fishType: [],
    rules: ['', '', '', '', '', '', ''],
  });

  const setFields = field => {
    setDataForRequest(prevDataForRequest => ({
      ...prevDataForRequest,
      ...field,
    }));
  };

  useEffect(() => {
    setFields({ periodEnd: end });
  }, [end]);

  useEffect(() => {
    setFields({ periodStart: start });
  }, [start]);

  useEffect(() => {
    setFields({ registrationStart: startReg });
  }, [startReg]);

  useEffect(() => {
    if (defaultInformation) {
      setDataForRequest({ ...defaultInformation });
    }
  }, [defaultInformation]);

  useEffect(() => {
    if (
      dataForRequest.placeDescription === '' &&
      dataForRequest.sponsors === '' &&
      dataForRequest.perticipantsNumber === '' &&
      dataForRequest.prizePool === '' &&
      dataForRequest.contributionsAmount === '' &&
      dataForRequest.participantsList === '' &&
      dataForRequest.registrationProcedure === '' &&
      dataForRequest.participationPayment === '' &&
      dataForRequest.regulations === '' &&
      dataForRequest.program === '' &&
      JSON.stringify([calendar, common, registration, result, rank, rule, fall]) ===
        JSON.stringify(['', '', '', '', '', '', ''])
    )
      setEnabled(false);
    else setEnabled(true);
  }, [dataForRequest, calendar, common, registration, result, rank, rule, fall]);

  return (
    <div
      className="container w-full"
      style={{
        paddingBottom: px(80),
        paddingTop: px(80),
      }}
    >
      <div style={{ marginBottom: px(16) }}>
        <p style={{ fontSize: px(32), lineHeight: px(43), marginBottom: px(5) }}>Информация</p>
        <p style={{ fontSize: px(15), lineHeight: px(20) }}>
          Работа с сервисом будет еще удобнее, если Вы заполните все формы
        </p>
      </div>
      <Textarea
        textareaHight={px(90)}
        placeholder="Введите текст"
        title="Место проведения"
        style={{
          height: px(130),
          marginBottom: px(14),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.placeDescription}
        onChange={placeDescription =>
          setFields({ placeDescription: capitalizeFirstLetter(placeDescription) })
        }
      />
      <div>
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            opacity: 0.5,
            fontWeight: 400,
            paddingLeft: px(18),
          }}
        >
          Период проведения
        </p>
        <PeriodSetter start={start} end={end} onChangeStart={setStart} onChangeEnd={setEnd} />
      </div>

      <Textarea
        placeholder="Введите текст"
        title="Организаторы"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.sponsors}
        onChange={sponsors => setFields({ sponsors: capitalizeFirstLetter(sponsors) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Количество участников"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(14),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.perticipantsNumber}
        onChange={perticipantsNumber =>
          setFields({ perticipantsNumber: capitalizeFirstLetter(perticipantsNumber) })
        }
      />
      <div>
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            opacity: 0.5,
            fontWeight: 400,
            paddingLeft: px(18),
          }}
        >
          Старт регистрации
        </p>
        <PeriodSetter start={startReg} onChangeStart={setStartReg} />
      </div>
      <Textarea
        placeholder="Введите текст"
        title="Призовой фонд"
        textareaHight={px(290)}
        style={{
          height: px(330),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.prizePool}
        onChange={prizePool => setFields({ prizePool: capitalizeFirstLetter(prizePool) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Сумма взносов"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.contributionsAmount}
        onChange={contributionsAmount =>
          setFields({ contributionsAmount: capitalizeFirstLetter(contributionsAmount) })
        }
      />
      <Textarea
        placeholder="Введите текст"
        title="Список участников"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.participantsList}
        onChange={participantsList =>
          setFields({ participantsList: capitalizeFirstLetter(participantsList) })
        }
      />
      <Textarea
        placeholder="Введите текст"
        title="Порядок регистрации"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.registrationProcedure}
        onChange={registrationProcedure =>
          setFields({ registrationProcedure: capitalizeFirstLetter(registrationProcedure) })
        }
      />
      <Textarea
        placeholder="Введите текст"
        title="Оплата участия"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.participationPayment}
        onChange={participationPayment =>
          setFields({ participationPayment: capitalizeFirstLetter(participationPayment) })
        }
      />
      <Textarea
        placeholder="Введите текст"
        title="Регламент"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.regulations}
        onChange={regulations => setFields({ regulations: capitalizeFirstLetter(regulations) })}
      />
      <Textarea
        placeholder="Введите текст"
        title="Программа"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(40),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={dataForRequest.program}
        onChange={program => setFields({ program: capitalizeFirstLetter(program) })}
      />
      <div style={{ marginBottom: px(16) }}>
        <p style={{ fontSize: px(32), lineHeight: px(43) }}>Фильтры</p>
        <p style={{ fontSize: px(15), lineHeight: px(20) }}>Характеристики</p>
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Вид турнира"
          items={[
            { label: 'Коммерческий', value: 'Коммерческий' },
            { label: 'Рейтинговый', value: 'Рейтинговый' },
            { label: 'Любительский', value: 'Любительский' },
            { label: 'Профессиональный', value: 'Профессиональный' },
          ]}
          value={tournamentType}
          onChange={tournamentType => setFields({ tournamentType })}
        />
      </div>
      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Тип участников"
          items={[
            { label: 'Все желающие', value: 'Все желающие' },
            { label: 'Молодежный', value: 'Молодежный' },
            { label: 'Детский', value: 'Детский' },
            { label: 'Женский', value: 'Женский' },
            { label: 'Мужской', value: 'Мужской' },
          ]}
          value={participantType}
          onChange={participantType => setFields({ participantType })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Зачет"
          items={[
            { label: 'Личный', value: 'Личный' },
            { label: 'Командный', value: 'Командный' },
            { label: 'Лично-командный', value: 'Лично-командный' },
          ]}
          value={standings}
          onChange={standings => setFields({ standings })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Дисциплина"
          items={[
            { label: 'Карпфишинг', value: 'Карпфишинг' },
            { label: 'Ловля форели', value: 'Ловля форели' },
            { label: 'Фидер. Донка', value: 'Фидер. Донка' },
          ]}
          value={discipline}
          onChange={discipline => setFields({ discipline })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Направление"
          items={[
            { label: 'Платная', value: 'Платная' },
            { label: 'Трофейная (от 20 кг )', value: 'Трофейная (от 20 кг )' },
            { label: 'Зимняя со льда', value: 'Зимняя со льда' },
          ]}
          value={direction}
          onChange={direction => setFields({ direction })}
        />
      </div>

      <div style={{ marginBottom: px(40) }}>
        <CheckboxFieldGroup
          multiple
          title="Виды рыб"
          items={[
            { label: 'Карп', value: 'Карп' },
            { label: 'Белый амур', value: 'Белый амур' },
            { label: 'Черный амур', value: 'Черный амур' },
          ]}
          value={fishType}
          onChange={fishType => setFields({ fishType })}
        />
      </div>

      <div style={{ marginBottom: px(20) }}>
        <p style={{ fontSize: px(32), lineHeight: px(32) }}>Условия</p>
        <p style={{ fontSize: px(32), lineHeight: px(32), marginBottom: px(6) }}>и правила</p>
        <p style={{ fontSize: px(15), lineHeight: px(20) }}>
          Работа с сервисом будет еще удобнее, если Вы заполните все формы
        </p>
      </div>
      <Textarea
        placeholder="Введите текст"
        title="1. Календарь"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={calendar}
        onChange={calendar => setCalendar(capitalizeFirstLetter(calendar))}
      />
      <Textarea
        placeholder="Введите текст"
        title="2. Основные положения"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={common}
        onChange={common => setCommon(capitalizeFirstLetter(common))}
      />
      <Textarea
        placeholder="Введите текст"
        title="3. Регистрация. Взносы …"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={registration}
        onChange={registration => setRegistration(capitalizeFirstLetter(registration))}
      />
      <Textarea
        placeholder="Введите текст"
        title="4. Результаты"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={result}
        onChange={result => setResult(capitalizeFirstLetter(result))}
      />
      <Textarea
        placeholder="Введите текст"
        title="5. Рейтинг команд"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={rank}
        onChange={rank => setRank(capitalizeFirstLetter(rank))}
      />
      <Textarea
        placeholder="Введите текст"
        title="6. Основные правила"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(10),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={rule}
        onChange={rule => setRule(capitalizeFirstLetter(rule))}
      />
      <Textarea
        placeholder="Введите текст"
        title="7. Нарушения правил"
        textareaHight={px(90)}
        style={{
          height: px(130),
          marginBottom: px(40),
          paddingTop: px(4),
          paddingLeft: px(18),
          paddingRight: px(18),
        }}
        value={fall}
        onChange={fall => setFall(capitalizeFirstLetter(fall))}
      />

      <Button
        style={{ width: '100%' }}
        fontSize={20}
        lineHeight={26}
        height={60}
        borderRadius={0}
        /* disabled={loading} */
        onClick={async () => {
          // eslint-disable-next-line unicorn/prefer-ternary
          if (defaultInformation) {
            await dispatch(
              updateCompetitionEvent({
                data: {
                  ...dataForRequest,
                  rules: [calendar, common, registration, result, rank, rule, fall],
                },
                id: defaultInformation.id,
              }),
            );
          } else {
            await dispatch(
              createCompetitionEvent({
                // eslint-disable-next-line unicorn/no-useless-spread
                ...{
                  ...dataForRequest,
                  rules: [calendar, common, registration, result, rank, rule, fall],
                },
                cabinetId: activeId,
              }),
            );
          }
          updateCompetition();
          onClose();
        }}
      >
        Сохранить
      </Button>
      <ScrollButtonsContainer>
        <CircleButton
          onClick={() => {
            setFields({ placeDescription: '' });
            setFields({ perticipantsNumber: '' });
            setFields({ prizePool: '' });
            setFields({ participationPayment: '' });
            setFields({ regulations: '' });
            setFields({ program: '' });
            setFields({ registrationProcedure: '' });
            setFields({ participantsList: '' });
            setFields({ sponsors: '' });
            setFields({ contributionsAmount: '' });
            setCalendar('');
            setCommon('');
            setRegistration('');
            setResult('');
            setRank('');
            setRule('');
            setFall('');
          }}
          icon={{
            type: TypesIcon.ROUNDEDRETURN,
            viewBox: '0 0 28.451 26.164',
            width: px(28.451),
            height: px(26.164),
          }}
        />
      </ScrollButtonsContainer>
    </div>
  );
};

export default InformationForm;
