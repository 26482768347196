import { Main } from '../../components';
import { px } from '../../constants/Px';
import Switch from '../../components/switch';
import { useNavigate } from 'react-router';
import { useState } from 'react';

export const NotificationDevices = () => {
  const navigate = useNavigate();
  const [pushNotificationToggle, setPushNotificationToggle] = useState(false);
  const [notificationToggle, setNotificationToggle] = useState(false);

  const navigateTo = (link) => {
    navigate(link);
  };
  const data = [
    {id:1,title:'Push-уведомления',subTitle:'Уведомления от нас',switch:true,bottom:px(24)},
    {id:2,title:'Куда присылать push',subTitle:'Iphone',switch:false,bottom:px(24),link:'/notification-devices-list'},
    {id:3,title:'Время рассылки',subTitle:'Ежедневно с 9:00 по 23:00',switch:false,bottom:px(14),link:'/notification-sending-time'},
    {id:4,title:'Раздел уведомлений',subTitle:'Просмотр уведомлений до авторизации',switch:true,bottom:px(14)},
    {id:5,title:'Push-коды',subTitle:'Iphone',switch:false,bottom:px(0),link:'/notification-push-codes'},
  ]

  return (

      <Main title='Уведомления' light>
        <div style={{
          marginTop:px(25)
        }}>
          {data.map((item)=>(
            <div className='flex flex-row items-center justify-between' style={{
              marginBottom:item.bottom
            }}
            onClick={()=>navigateTo(item.link)}
            >
              <div>
            <p className='font-normal ' style={{
              fontSize:px(20),
              lineHeight:px(26)
            }}>{item.title}</p>
            <p style={{
              fontSize:px(15),
              lineHeight:px(20)
            }} className='font-normal '>{item.subTitle}</p>
              </div>
              {item.switch && <div><Switch toggle={item?.id===1?pushNotificationToggle:item?.id===4 && notificationToggle} setToggle={item?.id===1?setPushNotificationToggle:item?.id===4 && setNotificationToggle}  /></div>}
            </div>
          ))}
        </div>
      </Main>

  );
};
