/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { deleteGuideOffer, updateGuideOffer } from '../../../../actions/guide';
import defPlacemark from '../../../../assets/images/placemarks/default.svg';
import { CardInformation } from '../../../../components';
import { blockTheAccountPageTimeData } from '../../../../constants/Component';
import { px } from '../../../../constants/Px';
import { TypesIcon } from '../../../../types/TypesIcon';
import { BackButton, Button, CircleButton, ScrollButtonsContainer } from '../../../shared';
import { useGuide } from '../../hooks';
import { InformationForm } from '../components/information-form/information-form';

export const GuideOffersEditPage = () => {
  const [showInformationForm, setShowInformationForm] = useState(false);
  const [hide, setHide] = useState(false);
  const [information, setInformation] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: { offers },
    updateFunction: updateCompetition,
  } = useGuide();

  return (
    <>
      <div className="global-container">
        <div className="fixed top-0 z-10 w-full bg-[white]">
          <BackButton
            onClick={() =>
              showInformationForm ? setShowInformationForm(false) : navigate('/user-guide')
            }
          />
        </div>

        {!showInformationForm && (
          <div
            className="w-full"
            style={{
              paddingBottom: px(40),
              paddingTop: px(80),
              width: px(320),
            }}
          >
            <div>
              <p style={{ fontSize: px(32), lineHeight: px(43), textAlign: 'center' }}>
                Предложения
              </p>
              <p style={{ fontSize: px(15), lineHeight: px(20), textAlign: 'center' }}>
                {offers?.length === 0
                  ? 'Добавьте предложение'
                  : 'Бронируйте и наслаждайтесь рыбалкой'}
              </p>
            </div>
          </div>
        )}

        {!showInformationForm && (
          <ScrollButtonsContainer>
            {offers?.length === 0 && (
              <CircleButton
                icon={{
                  type: TypesIcon.ADDPLUS,
                  viewBox: '0 0 26.95 26.95',
                  width: px(26.95),
                  height: px(26.95),
                }}
                onClick={() => {
                  setInformation();
                  setShowInformationForm(true);
                }}
              />
            )}

            {offers?.length > 0 && (
              <CircleButton
                onClick={() => {
                  setInformation(offers[0]);
                  setShowInformationForm(true);
                }}
                icon={{
                  type: TypesIcon.PENSIL,
                  viewBox: '0 0 18.51 23.873',
                  width: px(18.51),
                  height: px(23.873),
                  fill: '#0A84FE',
                }}
              />
            )}
          </ScrollButtonsContainer>
        )}

        {showInformationForm && (
          <InformationForm
            information={information}
            onClose={() => setShowInformationForm(false)}
          />
        )}

        {!showInformationForm && offers?.length > 0 && (
          <>
            <div className="container w-full">
              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Место проведения
                </p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.location?.replace('\n', '&#10;'),
                  }}
                />
              </div>
            </div>
            <YMaps className="w-full" style={{ height: px(290) }}>
              <div style={{ height: px(290) }}>
                <Map
                  style={{ height: px(290) }}
                  defaultState={{
                    center: [
                      offers?.[0]?.geolocation?.[0]?.coordinates?.split?.(',')?.[0]?.trim?.(),
                      offers?.[0]?.geolocation?.[0]?.coordinates?.split?.(',')?.[1]?.trim?.(),
                    ],
                    zoom: 12,
                  }}
                  behaviors={{ disable: ['drag'] }}
                >
                  {offers?.[0]?.geolocation?.map(geo => (
                    <Placemark
                      key={geo?.coordinates}
                      geometry={[
                        geo?.coordinates?.split?.(',')?.[0]?.trim?.(),
                        geo?.coordinates?.split?.(',')?.[1]?.trim?.(),
                      ]}
                      options={{
                        iconLayout: 'default#image',
                        iconImageHref: defPlacemark,
                        iconImageSize: [11, 11],
                      }}
                      /* properties={{
                          hintContent: name,
                      }} */
                      /* onClick={() => setSelectedCard(cards?.find(c => c?.id === id))} */
                    />
                  ))}
                </Map>
              </div>
            </YMaps>
            <div className="container w-full" style={{ marginTop: px(20) }}>
              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Период проведения
                </p>
                <div
                  style={{
                    gap: px(14),
                  }}
                  className="flex"
                >
                  {blockTheAccountPageTimeData.map(item => (
                    <div key={item?.id} className="flex flex-col">
                      <p
                        style={{
                          fontSize: px(15),
                          lineHeight: px(20),
                          fontWeight: 400,
                        }}
                        className="font-normal opacity-50"
                      >
                        {item?.title}
                      </p>

                      <div
                        style={{
                          gap: px(10),
                        }}
                        className="flex flex-row"
                      >
                        {item?.data?.map(el => (
                          <div key={el?.id}>
                            <p
                              style={{
                                fontSize: px(15),
                                lineHeight: px(20),
                                paddingBottom: px(20),
                                fontWeight: 400,
                              }}
                              className="font-normal  opacity-50"
                            >
                              {el?.subTitle}
                            </p>
                            <p
                              style={{
                                fontSize: px(20),
                                lineHeight: px(26),
                                fontWeight: 400,
                              }}
                              className="font-normal text-black"
                            >
                              {el?.id === 1
                                ? offers[0]?.periodStart?.slice(11, 16)
                                : el?.id === 3
                                  ? offers[0]?.periodEnd?.slice(11, 16)
                                  : el?.id === 2
                                    ? `${offers[0]?.periodStart?.slice(
                                        8,
                                        10,
                                      )}.${offers[0]?.periodStart?.slice(
                                        5,
                                        7,
                                      )}.${offers[0]?.periodStart?.slice(2, 4)}`
                                    : `${offers[0]?.periodEnd?.slice(
                                        8,
                                        10,
                                      )}.${offers[0]?.periodEnd?.slice(
                                        5,
                                        7,
                                      )}.${offers[0]?.periodEnd?.slice(2, 4)}`}
                            </p>
                            <hr
                              className="text-black opacity-30"
                              style={{
                                marginTop: px(5),
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Организаторы
                </p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.organizers?.replace('\n', '<br />'),
                  }}
                />
              </div>

              <div className="flex items-center justify-between" style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Характеристики
                </p>
                <div>
                  <button
                    type="button"
                    className="font-normal"
                    onClick={() => setHide(!hide)}
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      color: '#0A84FE',
                      cursor: 'pointer',
                    }}
                  >
                    {hide ? 'скрыть' : 'показать'}
                  </button>
                </div>
              </div>

              {hide && (
                <>
                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        marginBottom: px(13),
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Вид турнира
                    </p>
                    {offers[0]?.tournamentType?.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>

                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        marginBottom: px(13),
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Тип участников
                    </p>
                    {offers[0]?.participantsType?.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>

                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Зачет
                    </p>
                    {offers[0]?.standing.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>

                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        marginBottom: px(13),
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Дисциплина
                    </p>
                    {offers[0]?.discipline?.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>

                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        marginBottom: px(13),
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Направление
                    </p>
                    {offers[0]?.direction?.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>

                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        marginBottom: px(13),
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Виды рыб
                    </p>
                    {offers[0]?.fishType?.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>

                  <div style={{ marginBottom: px(40) }}>
                    <p
                      style={{
                        marginBottom: px(13),
                        fontSize: px(20),
                        lineHeight: px(26),
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      Услуги
                    </p>
                    {offers[0]?.service?.map(item => (
                      <div
                        key={item}
                        className="flex justify-between"
                        style={{ paddingRight: px(8), marginTop: px(13) }}
                      >
                        <p
                          style={{
                            paddingTop: px(2),
                            fontSize: px(20),
                            lineHeight: px(26),
                            fontWeight: 400,
                          }}
                        >
                          {item}
                        </p>
                        <div style={{ width: px(17), height: px(17), background: '#0A84FE' }} />
                      </div>
                    ))}
                  </div>
                </>
              )}

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Количество участников
                </p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.participantsNumber?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Знание языков
                </p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.languageKnowledge?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Спортивные достижения
                </p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.sportsAchivments?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div>
                <p
                  style={{
                    fontSize: px(20),
                    lineHeight: px(26),
                    fontWeight: 400,
                    marginBottom: px(17),
                  }}
                >
                  Стоимость. Расценки
                </p>
                <div className="flex items-center justify-between" style={{ marginBottom: px(17) }}>
                  <span style={{ fontSize: px(15), lineHeight: px(20), fontWeight: 400 }}>
                    1 час
                  </span>
                  <span
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                      fontWeight: 400,
                      color: offers[0]?.pricePerHour === 0 ? 'black' : '#0A84FE',
                    }}
                  >
                    {offers[0]?.pricePerHour}
                  </span>
                </div>
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.prices?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Обучение</p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.teaching?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Лодка</p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.boat?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div style={{ marginBottom: px(10) }}>
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>
                  Оборудование
                </p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.equipment?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <div
                style={{ paddingBottom: px(26) }}
                className="border-b-[1px] border-solid border-black border-opacity-30"
              >
                <p style={{ fontSize: px(20), lineHeight: px(26), fontWeight: 400 }}>Размещение</p>
                <p
                  className="break-words"
                  style={{
                    fontSize: px(15),
                    lineHeight: px(20),
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: offers[0]?.teaching?.replace('\n', '&#10;'),
                  }}
                />
              </div>

              <CardInformation
                dropDown
                cardHolder
                data={[
                  {
                    id: 6,
                    title: { label: 'Оплата участия' },
                    text: (
                      <div>
                        <p
                          className="break-words"
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: offers[0]?.payment?.replace('\n', '&#10;'),
                          }}
                        />
                      </div>
                    ),
                  },
                ]}
              />

              <CardInformation
                dropDown
                cardHolder
                data={[
                  {
                    id: 6,
                    title: { label: 'Программа' },
                    text: (
                      <div>
                        <p
                          className="break-words"
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: offers[0]?.program?.replace('\n', '&#10;'),
                          }}
                        />
                      </div>
                    ),
                  },
                ]}
              />

              <CardInformation
                dropDown
                cardHolder
                data={[
                  {
                    id: 6,
                    title: { label: 'Условия и Правила' },
                    text: (
                      <div>
                        <p
                          className="break-words"
                          style={{
                            fontSize: px(15),
                            lineHeight: px(20),
                            fontWeight: 400,
                            whiteSpace: 'pre-wrap',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: offers[0]?.terms?.replace('\n', '&#10;'),
                          }}
                        />
                      </div>
                    ),
                  },
                ]}
              />

              <div style={{ marginBottom: px(20), paddingTop: px(20) }}>
                <Button
                  style={{ width: '100%' }}
                  fontSize={20}
                  lineHeight={26}
                  height={60}
                  borderRadius={0}
                  /* disabled={loading} */
                  onClick={async () => {
                    // eslint-disable-next-line unicorn/prefer-ternary
                    await dispatch(
                      updateGuideOffer({
                        data: {
                          isBookingOpen: !offers[0]?.isBookingOpen,
                        },
                        id: offers[0]?.id,
                      }),
                    );
                    updateCompetition();
                  }}
                >
                  {offers[0]?.isBookingOpen ? 'Остановить бронирование' : 'Запустить бронирование'}
                </Button>
              </div>
              <p
                aria-hidden
                style={{
                  marginBottom: px(120),
                  textAlign: 'center',
                  fontSize: px(15),
                  lineHeight: px(20),
                  fontWeight: 400,
                  color: '#FA0303',
                }}
                onClick={async () => {
                  await dispatch(deleteGuideOffer(offers[0]?.id));
                  updateCompetition();
                }}
              >
                Удалить форму предложения
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};
