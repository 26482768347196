import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/auth';

export const CODE_RESTORE = 'auth/codeRestore';

export const codeRestore = createAsyncThunk(
    CODE_RESTORE,
    createRequest({
        type: CODE_RESTORE,
        loader: (data) => authApi.codeRestore(data),
    }),
);