/* eslint-disable no-restricted-syntax */
/* eslint-disable unicorn/prefer-array-flat-map */
/* eslint-disable unicorn/no-null */
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { locationEvent } from '../actions/event';
import { addFavourites, deleteFavourites, getFavourites } from '../actions/favourites';
import { px } from '../constants/Px';
import { BackButton, CircleButton } from '../new-architecture/shared';
import { authSelector } from '../selectors/auth';
import { cardsSelector } from '../selectors/event';
import { favouritesSelector } from '../selectors/favourites';
import { TypesIcon } from '../types/TypesIcon';
import { Card } from './card';

const defaultMark = `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
<g id="aa" data-name="Сгруппировать 4195" transform="translate(-3000 -458)">
  <g id="Сгруппировать_4193" data-name="Сгруппировать 4193" transform="translate(60 93)">
    <g id="Сгруппировать_4172" data-name="Сгруппировать 4172" transform="translate(2796 142)">
      <g id="ellips" data-name="Эллипс 2858" transform="translate(144 223)" fill="#01acef" stroke="#01acef" stroke-width="1" opacity="0.5">
        <circle cx="25" cy="25" r="25" stroke="none"/>
        <circle id="circ" cx="25" cy="25" r="24.5" fill="none"/>
      </g>
    </g>
    <g id="fav" data-name="Сгруппировать 4175" transform="translate(2126 -1260)">
      <g id="star_2_" data-name="star (2)" transform="translate(753 1604.207)">
        <g id="Сгруппировать_299" data-name="Сгруппировать 299" transform="translate(73 33.794)">
          <path id="Контур_327" data-name="Контур 327" d="M25.734,21.129a.755.755,0,0,0-.609-.514l-7.989-1.161-3.573-7.239a.755.755,0,0,0-1.354,0L8.635,19.454.647,20.615A.755.755,0,0,0,.228,21.9l5.781,5.635L4.644,35.494a.755.755,0,0,0,1.1.8l7.145-3.757,7.145,3.757a.755.755,0,0,0,1.1-.8l-1.365-7.957L25.542,21.9A.755.755,0,0,0,25.734,21.129Zm-7.31,5.6a.755.755,0,0,0-.217.668l1.173,6.84-6.143-3.229a.755.755,0,0,0-.7,0l-6.143,3.23L7.564,27.4a.755.755,0,0,0-.217-.668L2.378,21.889l6.868-1a.755.755,0,0,0,.568-.413l3.072-6.223,3.071,6.223a.755.755,0,0,0,.568.413l6.868,1Z" transform="translate(0 -11.794)" fill="#ecf2ee" stroke="#ecf2ee" stroke-width="1"/>
        </g>
      </g>
    </g>
  </g>
  <g id="redplashka" data-name="Сгруппировать 4194" transform="translate(2777.691 -207.069)">
    <g id="Сгруппировать_2650" data-name="Сгруппировать 2650" transform="translate(925.674 429.009) rotate(90)">
      <g id="insignia" transform="translate(236.06 689.366) rotate(-90)">
        <path id="Объединение_24" data-name="Объединение 24" d="M10.255,17.454,0,23.8V0H21.078V23.8Z" transform="translate(0 0)" fill="red"/>
      </g>
    </g>
    <g id="Личная_информация" data-name="Личная информация" transform="translate(82.93 425.979)">
      <text id="percent" data-name="20" transform="translate(155 252)" fill="#fff" font-size="10" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">20</tspan></text>
      <text id="_" data-name="%" transform="translate(166 252)" fill="#fff" font-size="10" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">%</tspan></text>
    </g>
  </g>
</g>
</svg>`;

const createElementFromHTML = htmlString => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return div.firstChild;
};

const generateIcon = (favouritesCards, EventContact, id, Location) => {
  const svg = createElementFromHTML(defaultMark);
  if (!favouritesCards?.some(item => item?.eventId === id)) {
    svg.querySelector('#fav')?.setAttribute('display', 'none');
  }
  if (
    Location?.map(loc => loc?.sections)
      .flat()
      .every(item => item?.fishingType === 'sport') &&
    Location?.map(loc => loc?.sections).length > 0
  ) {
    svg.querySelector('#ellips').setAttribute('fill', '#8B00FF');
    svg.querySelector('#ellips').setAttribute('stroke', '#8B00FF');
  } else if (
    Location?.map(loc => loc?.prices)
      ?.flat()
      ?.map(sg => sg?.rates)
      ?.flat()
      ?.filter(item => item?.isCaught).length > 0
  ) {
    svg.querySelector('#ellips').setAttribute('stroke', '#8B00FF');
    svg.querySelector('#ellips').setAttribute('stroke-width', '5');
    svg.querySelector('#circ').setAttribute('r', '22.5');
  }

  const promotionArr = Location?.map(loc => loc?.promotions)
    .flat()
    ?.map(sg => sg?.rates)
    ?.flat();
  let promo = 0;

  const findMax = value => {
    if (value && value !== '0 %' && +value.slice(0, -1) > promo) promo = value.slice(0, -1);
  };

  if (promotionArr) {
    for (const pr of promotionArr) {
      findMax(pr?.caughtValue);
      findMax(pr?.withNormValue);
      findMax(pr?.withoutNormValue);
      findMax(pr?.helpValue);
      findMax(pr?.cost);
    }
  }

  if (promo === 0) svg.querySelector('#redplashka').style.display = 'none';
  else svg.querySelector('#percent').innerHTML = promo;

  const svgString = new XMLSerializer().serializeToString(svg);

  return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
};

export const YandexMap = ({ isMapOpen, className, setMapOpen, points, ...props }) => {
  const navigate = useNavigate();

  const [selectedCard, setSelectedCard] = useState(null);

  const auth = useSelector(authSelector);
  const favouritesCards = useSelector(favouritesSelector);

  const dispatch = useDispatch();
  const cards = useSelector(cardsSelector);

  useEffect(() => {
    dispatch(locationEvent({ skip: 0 }));
  }, []);

  useEffect(() => {
    if (auth) dispatch(getFavourites());
  }, []);

  const isFavorite = useMemo(
    () => favouritesCards?.some(item => item?.eventId === selectedCard?.id),
    [favouritesCards, selectedCard],
  );

  const handleNavigateLogin = () => {
    if (auth) {
      if (isFavorite)
        dispatch(
          deleteFavourites({
            id: favouritesCards.find(item => item?.eventId === selectedCard?.id)?.id,
          }),
        );
      else dispatch(addFavourites({ ...selectedCard, eventId: selectedCard?.id }));
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="map-container">
      <BackButton
        onClick={() => {
          navigate('/');
        }}
        circle
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          overflow: 'hidden',
          width: '100%',
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <YMaps version="2.1">
          <Map
            {...props}
            defaultState={{
              center: [
                (cards && cards[0]?.EventContact?.coordinates[0]) || 55.45,
                (cards && cards[0]?.EventContact?.coordinates[1]) || 37.36,
              ],
              zoom: 5,
            }}
            modules={['geoObject.addon.hint']}
            className={`h-full w-full ${className || ''}`}
            onClick={() => {
              setSelectedCard(null);
            }}
          >
            {cards
              ?.filter(
                card =>
                  !card.deletedAt &&
                  card?.EventContact?.coordinates?.[0] &&
                  card?.EventContact?.coordinates?.[1],
              )
              ?.map(({ name, EventContact, id, Location }) => (
                <Placemark
                  key={name + id}
                  geometry={[EventContact.coordinates[0], EventContact.coordinates[1]]}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: generateIcon(favouritesCards, EventContact, id, Location),
                    iconImageSize: [50, 50],
                  }}
                  properties={{
                    hintContent: name,
                  }}
                  onClick={() => setSelectedCard(cards?.find(c => c?.id === id))}
                />
              ))}
          </Map>
        </YMaps>
        {selectedCard && (
          <div className="fixed   left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform">
            <div>
              <CircleButton
                onClick={() => setSelectedCard(null)}
                icon={{
                  type: TypesIcon.CLOSE,
                  viewBox: '0 0 17 17',
                  width: px(17),
                  height: px(17),
                  fill: '#ffffff',
                }}
                className="!bg-[#FF0A0A]"
                style={{
                  zIndex: '100000000',
                  position: 'absolute',
                  right: 'calc(min(calc(-20 * -1 * 0.3125vw), calc(-20 * -1 * 2.4px)) * -1)',
                  top: 'calc(min(calc(-20 * -1 * 0.3125vw), calc(-20 * -1 * 2.4px)) * -1)',
                  width: 'min(calc(40 * 0.3125vw), calc(40 * 2.4px))',
                  height: 'min(calc(40 * 0.3125vw), calc(40 * 2.4px))',
                  border: 'min(calc(1 * 0.3125vw), calc(1 * 2.4px)) solid white',
                }}
              />
              <Card
                id={selectedCard?.id}
                item={selectedCard}
                title={selectedCard?.name}
                blocked={selectedCard.status}
                addressTitle={selectedCard?.EventContact?.district}
                address={selectedCard?.EventContact?.town}
                street={selectedCard?.EventContact?.street}
                image={
                  selectedCard?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                    ? `${process.env?.REACT_APP_STATIC_URL}${
                        selectedCard?.EventAttachment?.find?.(i => i?.type === 'POSTCARD')?.imageUrl
                      }`
                    : selectedCard?.image
                }
                className="w-full"
                outlined
              />
              <div
                className="flex w-full items-center justify-center"
                style={{ paddingTop: px(16) }}
              >
                <CircleButton
                  onClick={handleNavigateLogin}
                  icon={{
                    type: TypesIcon.STAR,
                    viewBox: '0 0 27.5 26.233',
                    width: px(27.5),
                    height: px(26.233),
                    fill: isFavorite ? 'white' : '#0a84fe',
                  }}
                  className={`${isFavorite ? '!bg-[#39CA1C]' : '!bg-[#ffffff]'}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default YandexMap;
