/* eslint-disable import/no-unresolved */
/* eslint-disable sonarjs/prefer-immediate-return */
import { request } from '@/helpers/api/request';

export const deleteCompetitionAdmin = async ({ profileAdminId }) => {
  const response = await request({
    url: `competition-admin/${profileAdminId}`,
    method: 'DELETE',
  });

  return response;
};
