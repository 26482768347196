import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/recommendation';

export const DELETE_RECOMMENDATION= 'recommendation/deleteRecommendation';

export const deleteRecommendation = createAsyncThunk(
    DELETE_RECOMMENDATION,
    createRequest({
        type: DELETE_RECOMMENDATION,
        loader: (data) => authApi.deleteRecommendation(data),
    }),
);