import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { addAttachment, updateAttachment } from '../actions/attachment';
import { getEvent } from '../actions/event';
import { getProfile, profileAvatar } from '../actions/profile';
import { styledAvatar } from '../constants/Component';
import getCroppedImg from '../constants/Images';
import { numberPx, px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';
import { StateContext } from './state-provider';

// Container
export const StyledAvatar = ({ index, data }) => {
  const { handleClose, dragControls } = useContext(StateContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cropRef = useRef(null);
  const inputRef = useRef(null);
  const [filesSrc, setFilesSrc] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState();
  const [disabled, setDisabled] = useState(false);
  const [croppedImage, setCroppedImage] = useState();
  const [slideIndex, setslideIndex] = useState(index || 0);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  // handle Change
  const handleImgChange = e => {
    if (e?.target?.files[0]) {
      if (e.target.files) {
        const { files } = e.target;
        // eslint-disable-next-line no-underscore-dangle, no-plusplus
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);

          if (file) {
            /*         if (file.size > maxFileSize) {
              setError('Твои файлы превысили разрешенный размер в 250MB');
              return;
            }
  
            if (!acceptFileType.includes(file.type.split('/')[1])) {
              setError('Твой тип файлов не может быть загружен');
              return;
            } */

            const reader = new FileReader();

            reader.addEventListener('load', ev => {
              setFilesSrc(prevState => ({
                ...prevState,
                [file.name]: String(ev.target?.result) || '',
                url: String(ev.target?.result) || '',
                file,
              }));
            });

            reader.readAsDataURL(file);
          }
        }
      }
    }
    setDisabled(true);
  };

  // eslint-disable-next-line no-shadow
  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  }, []);

  // handle save
  const handleSave = async e => {
    e.preventDefault();
    // const clearFormDateFile = new FormData();
    // const clear = await getClear(filesSrc?.url, croppedAreaPixels, 0);
    // clearFormDateFile.append('files', clear);
    // clearFormDateFile.append('files', clear);
    // dispatch(profileAvatar(clearFormDateFile));
    try {
      const formDateFile = new FormData();

      // eslint-disable-next-line no-shadow
      const croppedImage = await getCroppedImg(filesSrc?.url, croppedAreaPixels, 0);

      setCroppedImage(croppedImage);

      const id = data?.EventAttachment?.filter(item => item.type === 'LOGO')[0]?.id;

      if (index === 1) {
        if (id) {
          /* formDateFile.append('files', croppedImage); */
          const upd = async () => {
            await formDateFile.append('file', croppedImage);
            await dispatch(updateAttachment({ data: formDateFile, id }));
          };
          await upd();
          dispatch(getEvent({ id: data?.id }));
          handleClose();
        } else {
          const add = async () => {
            await formDateFile.append('file', croppedImage);
            await formDateFile.append('eventId', data?.id);
            await formDateFile.append('type', 'LOGO');
            await dispatch(addAttachment(formDateFile));
          };
          await add();
          dispatch(getEvent({ id: data?.id }));
          handleClose();
        }
      } else {
        formDateFile.append('files', croppedImage);
        formDateFile.append('files', croppedImage);
        dispatch(profileAvatar(formDateFile));
        handleClose();
      }

      setCroppedImage();
      setCrop({ x: 0, y: 0 });
      setFilesSrc();
      setCroppedImage();
    } catch (error) {
      console.error(error);
    }
    if (index === 0) {
      dispatch(getProfile());
    }

    setDisabled(false);
  };

  const handleDeletePhoto = e => {
    setCroppedImage();
    setCrop({ x: 0, y: 0 });
    setFilesSrc();
    setDisabled(false);
  };

  const handleNavigate = () => {
    navigate('/demand', { state: { prevPath: pathname } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const changeSlideIndexPlus = () => {
    if (slideIndex < 9) {
      setslideIndex(slideIndex + 1);
    }
  };

  const changeSlideIndexMinus = () => {
    if (slideIndex > 0) {
      setslideIndex(slideIndex - 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      style={{
        paddingBottom: px(80),
      }}
    >
      <div className="flex-column flex w-full items-center justify-center">
        <div
          style={{
            padding: `${px(2)} ${px(12)} `,
          }}
          className={`flex items-center ${'justify-center'}  w-full`}
        >
          <div>
            {/* {slideIndex !== 9 && slideIndex !== 0 && (
                <Icon
                  type={TypesIcon.ARROWLEFT}
                  viewBox="0 0 21 14"
                  width={px(21)}
                  height={px(14)}
                  className={`${disabled && 'opacity-30'}`}
                  onClick={disabled ? null : changeSlideIndexMinus}
                />
              )} */}
          </div>
          <div>
            <p
              className="w-full text-center font-light"
              style={{
                fontSize: px(20),
                lineHeight: px(26),
              }}
              onPointerDown={e => {
                dragControls.start(e);
              }}
            >
              {styledAvatar?.[slideIndex]?.title}
            </p>
          </div>
          {/* {slideIndex !== 9 && (
            <Icon
              type={TypesIcon.ARROWRIGHT}
              viewBox='0 0 21 14'
              width={px(21)}
              height={px(14)}
              className={`${disabled && 'opacity-30'}`}
              onClick={!disabled ? changeSlideIndexPlus : null}
            />
          )} */}
        </div>
        <div
          style={{
            width: `${numberPx(320)}px`,
            height: `${numberPx(304)}px`,
          }}
          className="relative  bg-[#0A84FE4D]  "
          key={slideIndex}
        >
          {!filesSrc && (
            <div
              style={{
                width: px(styledAvatar?.[slideIndex]?.cropWidth),
                height: px(styledAvatar?.[slideIndex]?.cropHeight),
                borderRadius: px(styledAvatar?.[slideIndex]?.borderRadius),
              }}
              className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2  transform items-center justify-center bg-[#FFFFFF] opacity-30"
            >
              <Icon type={TypesIcon.RESIZE} viewBox="0 0 70 70" width={px(70)} height={px(70)} />
            </div>
          )}

          <Cropper
            key={slideIndex}
            imageRef={cropRef}
            image={filesSrc?.url}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
            cropShape={slideIndex === 0 ? 'round' : 'rect'}
            style={{
              cropAreaStyle: {
                boxShadow: 'none',
                background: '#ffffff80',
                border: 'none',
                borderRadius: numberPx(styledAvatar?.[slideIndex]?.borderRadius),
              },
            }}
            restrictPosition={false}
            cropSize={{
              width: numberPx(styledAvatar?.[slideIndex]?.cropWidth),
              height: numberPx(styledAvatar?.[slideIndex]?.cropHeight),
            }}
            showGrid={false}
          />
        </div>
      </div>
      <div>
        {/* <Pagination size={styledAvatar?.length} activIndex={slideIndex} /> */}

        <div
          style={{
            gap: px(10),
            paddingTop: px(10),
          }}
          className="flex items-center justify-center"
        >
          <CircleButton
            onClick={handleSave}
            disabled={!filesSrc}
            icon={{
              type: TypesIcon.CONFIRM,
              viewBox: '0 0 19.59 24.806',
              width: px(19.59),
              height: px(50),
            }}
          />
          <CircleButton
            onClick={() => handleNavigate()}
            icon={{
              type: TypesIcon.INFO,
              viewBox: '0 0 17 50',
              width: px(17),
              height: px(50),
            }}
          />
          <CircleButton
            ref={inputRef}
            icon={{
              type: TypesIcon.GALLERY,
              viewBox: '0 0 26.827 23.512',
              width: px(17),
              height: px(50),
              fill: '#0A84FE',
            }}
          >
            <label
              htmlFor="upload-photo"
              style={{
                width: px(26.827),
                height: px(23.512),
              }}
              className="flex items-center justify-center"
            >
              <input
                className="leading-0 m-0 h-0 w-0  p-0 opacity-0"
                ref={inputRef}
                id="upload-photo"
                type="file"
                accept="image/*"
                multiple
                onChange={handleImgChange}
              />
            </label>
          </CircleButton>
          {slideIndex === 9 && (
            <CircleButton
              onClick={() => handleNavigate()}
              icon={{
                type: TypesIcon.FIRST,
                viewBox: '0 0 8.734 22.859',
                width: px(8.734),
                height: px(22.859),
              }}
              className="bg-[#39CA1C]"
              disabled
            />
          )}
          <CircleButton
            onClick={() => handleDeletePhoto()}
            icon={{
              type: TypesIcon.SMALLBASKET,
              viewBox: '0 0 20.5 25.231',
              width: px(20.5),
              height: px(25.231),
            }}
            disabled={!filesSrc}
          />
        </div>
      </div>
    </div>
  );
};

export default StyledAvatar;
