import React from "react"

export const ResultsListItem = ({ resultsListItem }) => {
    return (
        <div className='results-list-item'>
            <div className='results-list-item-left'>
                {resultsListItem.eventType === "guides_education" && (
                    <img
                        src={`${process.env.REACT_APP_SITE_URL}/guide.svg`}
                        alt='Education'
                    />
                )}

                {resultsListItem.eventType === "tournaments" && (
                    <img
                        src={`${process.env.REACT_APP_SITE_URL}/tournament.svg`}
                        alt='Tournaments'
                    />
                )}

                {resultsListItem.eventType === "game" && (
                    <img
                        src={`${process.env.REACT_APP_SITE_URL}/games.svg`}
                        alt='Games'
                    />
                )}
                {resultsListItem.eventType === "tour" && (
                    <img
                        src={`${process.env.REACT_APP_SITE_URL}/tour-golf.svg`}
                        alt='Games'
                    />
                )}
            </div>
            <div className='results-list-item-right'>
                <h3>{resultsListItem.name}</h3>
                <p>{`${resultsListItem.country}, ${resultsListItem.region}`}</p>
            </div>
        </div>
    )
}

export default ResultsListItem
