import { createReducer } from '@reduxjs/toolkit';

import { getSectors } from '../../actions/sector/thunks';

export const sector = createReducer({}, builder => {
  builder.addCase(getSectors.fulfilled, (state, { payload }) => ({
    ...state,
    sectors: payload?.data,
  }));
});
