import { request } from '@/helpers/api/request';

export const addDeparture = async ({data, id}) => {
  const response = await request({
    url: `departure/${id}`,
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    method: 'PUT',
    data,
  });

  return response;
};
