import { YandexMap } from "../../components";


export const MapPage = () => {

  return (
    <div className="global-container">
      <main>
        <YandexMap isMapOpen={true} />
      </main>
    </div>
  );
};
