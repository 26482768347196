import { getGuideCabinet, getGuideOffer } from '../../api/guide';

export const getGuide = async ({ competitionId }) => {
  const [cabinet, offers] = (
    await Promise.allSettled([
      getGuideCabinet({ id: competitionId }),
      getGuideOffer({ id: competitionId }),
    ])
  ).map(item => item?.value?.data);

  return {
    cabinet,
    offers,
  };
};
