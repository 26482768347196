export const INITIAL_STATE = {
  regInformation: {
    placeDescription: '',
    sponsors: '',
    perticipantsNumber: '',
    prizePool: '',
    contributionsAmount: '',
    participantsList: '',
    registrationProcedure: '',
    participationPayment: '',
    regulations: '',
    program: '',
    tournamentType: [],
    participantType: [],
    standings: [],
    discipline: [],
    direction: [],
    fishType: [],
    rules: ['', '', '', '', '', '', ''],
  },
};
