import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/profile';

export const DELETE_PROFILE = 'profile/deleteProfile';

export const deleteProfile = createAsyncThunk(
    DELETE_PROFILE,
    createRequest({
        type: DELETE_PROFILE,
        loader: (data) => authApi.deleteProfile(data),
    }),
);