import { createAction } from '@reduxjs/toolkit';


export const ADD_LOADER = 'ADD_LOADER';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';

export const addLoader = createAction(ADD_LOADER, (loader) => ({ payload: { loader } }));

export const updateLoader = createAction(UPDATE_LOADER, (loaderName, loaderState) => ({
  payload: {
    loaderName,
    loaderState
  }
}));

export const removeLoader = createAction(REMOVE_LOADER, (loader) => ({ payload: { loader } }));
