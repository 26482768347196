import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSections, sectionPost } from '../actions/section';
import { sectionUpdate } from '../api/section/update-section';
import {
  bedObjectCountData,
  capacityCountData,
  capacityObjectCountData,
  filterObjectData,
  filterPlaceData,
  fishingFilterData,
  freeZoneCountData,
  hotelCountData,
  menuObjectFiltersData,
  sleepingPlaceCountData,
} from '../constants/Component';
import { alphabet, assistantData, sectorData } from '../constants/Component/date-picker';
import { createObjectDram } from '../constants/Function';
import { px } from '../constants/Px';
import { sectionsNumbersSelector, sectionsSelector } from '../selectors/section';
import { AvatarFilter } from './avatar-filter';
import { Button } from './button';
import { CreateSimilarFilters } from './create-similar-filters';
import { DatePicker } from './date-picker';
import { FiltersFilterFishing } from './filters-filter-fishing';
import { FiltersFilterRest } from './filters-filter-rest';
import { FiltersFilterService } from './filters-filter-service';
import { Modal } from './Modal/modal';
import { PropagateFilter } from './propagate-filter';
import { StateContext } from './state-provider';

export const Filter = ({
  id,
  modalType,
  selectedOptionsObjectFilters,
  setSelectedOptionsObjectFilters,
  selectedObjectFiltersRest,
  setSelectedObjectFiltersRest,
  selectedObjectFiltersService,
  setSelectedObjectFiltersService,
  formData,
  setImgUrl,
  setSchemaUrl,
  imgUrl,
  schemaUrl,
  saveDescription,
  setSaveDescription,
  imgArr,
  tempImgArr,
  schemaArr,
  tempSchemaArr,
  date,
  setDate,
  similarSector,
  setShowFilters,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [save, setSave] = useState(false);
  const [scrollIndex, setScrollIndex] = useState([1]);
  const [isShowAllFilters, setIsShowAllFilters] = useState(0);
  const { scrollRef } = useContext(StateContext);
  const dispatch = useDispatch();
  const [saveRest, setSaveRest] = useState(false);
  const [scrollIndexRest, setScrollIndexRest] = useState([1]);
  const [scrollIndexService, setScrollIndexService] = useState([1]);
  const [show, setShow] = useState(false);
  const [dataPickerId, setDataPickerId] = useState(0);
  const sectionsNumber = useSelector(sectionsNumbersSelector);
  const sections = useSelector(sectionsSelector);
  const [currentSector, setCurrentSector] = useState();

  const [oneSelectedOption, setOneSelectedOption] = useState({
    1: [],
    5: [],
  });
  const [oneSelectedOptionRest, setOneSelectedOptionRest] = useState({
    1: [],
    5: [],
    8: [],
  });
  const [saveService, setSaveService] = useState(false);
  const [filterRadiobutton, setFilterRadiobutton] = useState([]);
  useEffect(() => {
    if (isShowAllFilters !== 0) {
      if (save && scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [save, scrollRef.current]);

  const scrollToBlock = () => {
    setSave(true);
    if (
      scrollIndex.includes(8) ||
      scrollIndex.includes(9) ||
      scrollIndex.includes(10) ||
      scrollIndex.includes(11) ||
      scrollIndex.includes(12)
    ) {
      setIsShowAllFilters(3);
    }
    if (
      scrollIndex.includes(4) ||
      scrollIndex.includes(5) ||
      scrollIndex.includes(6) ||
      scrollIndex.includes(7)
    ) {
      setIsShowAllFilters(2);
    }
    if (scrollIndex.includes(1) || scrollIndex.includes(2) || scrollIndex.includes(3)) {
      setIsShowAllFilters(1);
    }
    if (scrollIndex.length === 12) {
      setIsShowAllFilters(1);
    }
    if (scrollIndex.length === 0) {
      setIsShowAllFilters(0);
    }
  };

  const sendData = () => {
    if (modalType === 1) {
      scrollToBlock();
    }

    if (
      (save && scrollIndex.length === 0) ||
      (saveRest && scrollIndexRest.length === 0) ||
      (saveRest && scrollIndexService.length === 0)
    ) {
      dispatch(sectionPost(formData));
    }
  };

  if (modalType === 2) {
    setSaveRest(true);
  }

  if (modalType === 3) {
    setSaveService(true);
  }

  const shouldDispatchSectionPost =
    (save && scrollIndex.length === 0) ||
    (saveRest && scrollIndexRest.length === 0) ||
    (saveService && scrollIndexService.length === 0);

  if (shouldDispatchSectionPost) {
    dispatch(sectionPost(formData));
    setShowFilters(false);
  }
  // const updateData = () => {
  //   dispatch(sectionUpdate(formData));
  //   setShowFilters(false);
  // };

  const onChangeValue = value => {
    if (dataPickerId === 0) {
      setDate({
        ...date,
        freeZone: value?.freeZone,
      });
    }
    if (dataPickerId === 1) {
      setDate({
        ...date,
        capacity: value?.capacity,
      });
    }
    if (dataPickerId === 2) {
      setDate({
        ...date,
        sleepingPlace: value?.sleepingPlace,
      });
    }
    if (dataPickerId === 3) {
      setDate({
        ...date,
        hotel: value?.hotel,
      });
    }
    if (dataPickerId === 4) {
      setDate({
        ...date,
        capacityObject: value?.capacityObject,
      });
    }
    if (dataPickerId === 5) {
      setDate({
        ...date,
        bedObject: value?.bedObject,
      });
    }
    if (dataPickerId === 6) {
      setDate({
        ...date,
        intervalStartingFromNumber: value?.intervalStartingFromNumber,
      });
    }
    if (dataPickerId === 7) {
      setDate({
        ...date,
        intervalEndsFromNumber: value?.intervalEndsFromNumber,
      });
    }
    if (dataPickerId === 8) {
      setDate({
        ...date,
        sectorShoreSide: value?.sectorShoreSide,
        sectorShoreNumber: value?.sectorShoreNumber,
        sectorShoreLetter: value?.sectorShoreLetter,
      });
    }
    if (dataPickerId === 9) {
      setDate({
        ...date,
        similarSector: value?.similarSector,
      });
    }
  };
  const dataPickersFilters = [
    {
      pickerId: '0',
      title: 'FREE ZONE',
      data: freeZoneCountData,
      pickerValue: date,
    },
    {
      pickerId: '1',
      title: 'Вместимость',
      data: capacityCountData,
      pickerValue: date,
    },
    {
      pickerId: '2',
      title: 'Спальное место',
      data: sleepingPlaceCountData,
      pickerValue: date,
    },
    {
      pickerId: '3',
      title: 'Отель',
      data: hotelCountData,
      pickerValue: date,
    },
    {
      pickerId: '4',
      title: 'Вместимость',
      data: capacityObjectCountData,
      pickerValue: date,
    },
    {
      pickerId: '5',
      title: 'Спальное место',
      data: bedObjectCountData,
      pickerValue: date,
    },
    {
      pickerId: '6',
      title: 'Начало от номера',
      data: createObjectDram(
        ['intervalStartingFromNumber'],
        assistantData?.assistant
          .filter(item => Number(item) <= Number(date?.intervalEndsFromNumber))
          .map(mappedItem => mappedItem.toString()),
      ),
      pickerValue: date,
    },
    {
      pickerId: '7',
      title: 'Конец от номера',
      data: createObjectDram(
        ['intervalEndsFromNumber'],
        assistantData?.assistant
          .filter(item => Number(item) >= Number(date?.intervalStartingFromNumber))
          .map(mappedItem => mappedItem.toString()),
      ),
      pickerValue: date,
    },
    {
      pickerId: '8',
      title: 'Номер сектора',
      data: createObjectDram(
        ['sectorShoreSide', 'sectorShoreNumber', 'sectorShoreLetter'],
        sectorData?.sectorSide,
        assistantData?.assistant,
        alphabet?.letters,
      ),
      pickerValue: date,
    },
    {
      pickerId: '9',
      title: 'Похожие сектора',
      data: createObjectDram(['similarSector'], sectionsNumber),
      pickerValue: date,
    },
  ];
  const sectorValue = useMemo(() => {
    if (
      dataPickersFilters[8]?.pickerValue.sectorShoreSide === '' &&
      dataPickersFilters[8]?.pickerValue.sectorShoreNumber === '' &&
      dataPickersFilters[8]?.pickerValue.sectorShoreLetter === ''
    ) {
      return '0';
    }
    return `${dataPickersFilters[8]?.pickerValue.sectorShoreSide}${dataPickersFilters[8]?.pickerValue.sectorShoreNumber}${dataPickersFilters[8]?.pickerValue.sectorShoreLetter}`;
  }, [
    dataPickersFilters[8]?.pickerValue.sectorShoreSide,
    dataPickersFilters[8]?.pickerValue.sectorShoreNumber,
    dataPickersFilters[8]?.pickerValue.sectorShoreLetter,
  ]);

  useEffect(() => {
    dispatch(getSections({ id }));
  }, []);

  return (
    <>
      <AvatarFilter
        sectorValue={sectorValue}
        selectedOptionsObjectFilters={selectedOptionsObjectFilters}
      />
      <p className="font-light" style={{ fontSize: px(32), lineHeight: px(43) }}>
        Фильтр
      </p>
      <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
        Создайте Условия и Описание
      </p>
      {modalType === 1 && (
        <div
          className="flex-column flex"
          style={{ marginTop: px(25), gap: px(15), marginBottom: px(37.9) }}
        >
          <div className="flex flex-row items-center justify-between">
            <p className="font-normal opacity-50" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Сектор
            </p>
            {sectorValue !== '0' && (
              <p
                aria-hidden
                onClick={() => {
                  setDate({
                    ...date,
                    sectorShoreSide: '',
                    sectorShoreNumber: '',
                    sectorShoreLetter: '',
                  });
                }}
                className="cursor-pointer font-normal text-[#0A84FE]"
                style={{ fontSize: px(15), lineHeight: px(20) }}
              >
                Сбросить
              </p>
            )}
          </div>
          <div className="flex flex-row items-center">
            <p
              className="font-normal "
              style={{ fontSize: px(20), lineHeight: px(26), marginRight: px(34) }}
            >
              Номер
            </p>
            <p
              aria-hidden
              onClick={() => {
                setDataPickerId(8);
                setShow(true);
              }}
              className="cursor-pointer font-normal text-[#0A84FE]"
              style={{ fontSize: px(15), lineHeight: px(20) }}
            >
              {sectorValue}
            </p>
          </div>
        </div>
      )}
      <div style={{ paddingBottom: px(20) }}>
        {modalType === 1 ? (
          menuObjectFiltersData.map(item => (
            <FiltersFilterFishing
              imgArr={imgArr}
              tempImgArr={tempImgArr}
              schemaArr={schemaArr}
              tempSchemaArr={tempSchemaArr}
              key={item.id}
              id={item.id}
              isShowAllFilters={isShowAllFilters}
              setIsShowAllFilters={setIsShowAllFilters}
              save={save}
              setSave={setSave}
              scrollIndex={scrollIndex}
              setScrollIndex={setScrollIndex}
              item={item}
              selectedOptionsObjectFilters={selectedOptionsObjectFilters}
              setSelectedOptionsObjectFilters={setSelectedOptionsObjectFilters}
              oneSelectedOption={oneSelectedOption}
              setOneSelectedOption={setOneSelectedOption}
              filterRadiobutton={filterRadiobutton}
              setFilterRadiobutton={setFilterRadiobutton}
              imgUrl={imgUrl}
              schemaUrl={schemaUrl}
              saveDescription={saveDescription}
              setSaveDescription={setSaveDescription}
              arr={
                item.id === 1
                  ? filterPlaceData
                  : item.id === 2
                    ? filterObjectData
                    : fishingFilterData
              }
              showFilt={show}
              setShow={setShow}
              date={date}
              setDataPickerId={setDataPickerId}
              setImgUrl={setImgUrl}
              setSchemaUrl={setSchemaUrl}
              sectorValue={sectorValue}
            />
          ))
        ) : modalType === 2 ? (
          <FiltersFilterRest
            selectedObjectFiltersRest={selectedObjectFiltersRest}
            setSelectedObjectFiltersRest={setSelectedObjectFiltersRest}
            scrollIndex={scrollIndexRest}
            setScrollIndex={setScrollIndexRest}
            save={saveRest}
            setSave={setSaveRest}
            oneSelectedOptionRest={oneSelectedOptionRest}
            setOneSelectedOptionRest={setOneSelectedOptionRest}
            show={show}
            setShow={setShow}
            imgUrl={imgUrl}
            schemaUrl={schemaUrl}
            setImgUrl={setImgUrl}
            setSchemaUrl={setSchemaUrl}
            saveDescription={saveDescription}
            setSaveDescription={setSaveDescription}
            date={date}
            setDataPickerId={setDataPickerId}
            sectorValue={sectorValue}
          />
        ) : (
          <FiltersFilterService
            scrollIndex={scrollIndexService}
            setScrollIndex={setScrollIndexService}
            save={saveService}
            setSave={setSaveService}
            selectedObjectFiltersService={selectedObjectFiltersService}
            setSelectedObjectFiltersService={setSelectedObjectFiltersService}
            show={show}
            setShow={setShow}
            saveDescription={saveDescription}
            setSaveDescription={setSaveDescription}
            imgUrl={imgUrl}
            setImgUrl={setImgUrl}
            date={date}
            setDataPickerId={setDataPickerId}
            sectorValue={sectorValue}
          />
        )}
      </div>
      {modalType === 1 && (
        <>
          <CreateSimilarFilters
            setDate={setDate}
            date={date}
            setDataPickerId={setDataPickerId}
            setShow={setShow}
          />
          <PropagateFilter
            setDate={setDate}
            setDataPickerId={setDataPickerId}
            setShow={setShow}
            date={date}
          />
        </>
      )}

      <div style={{ marginTop: px(20), marginBottom: px(20) }}>
        <Button
          style={{ width: '100%' }}
          fontSize={20}
          lineHeight={26}
          height={60}
          borderRadius={0}
          // onClick={sectorId ? updateData : sendData}
          onClick={sendData}
        >
          Сохранить
        </Button>
        <p
          aria-hidden
          className="w-full text-center font-normal text-[#FA0303]"
          style={{ fontSize: px(15), lineHeight: px(20), marginTop: px(20) }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Удалить
        </p>
      </div>
      {showModal && (
        <Modal
          onClose={setShowModal}
          data={{
            title: 'Вы действительно хотите удалить',
            buttonLeftText: 'Нет',
            buttonRightText: 'Да',
          }}
        />
      )}
      <DatePicker
        singleItem
        title={dataPickersFilters?.[dataPickerId]?.title}
        show={show}
        setShow={setShow}
        data={dataPickersFilters?.[dataPickerId]?.data}
        setPickerValue={onChangeValue}
        pickerValue={dataPickersFilters?.[dataPickerId]?.pickerValue}
        buttonTitle="Применить"
        isLine
      />
    </>
  );
};

export default Filter;
