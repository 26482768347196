import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';

export const GET_PROFILE_EVENT = 'event/getProfileEvent';

export const getProfileEvent = createAsyncThunk(
    GET_PROFILE_EVENT,
    createRequest({
        type: GET_PROFILE_EVENT,
        loader: (data) => authApi.getProfileEvent(data),
    }),
);
