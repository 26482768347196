import { useContext } from 'react';

import { competitionContext } from '../context';

export const useCompetition = () => {
  const context = useContext(competitionContext);

  if (context === undefined) {
    throw new Error('competitionContext must be inside a CompetitonProvider');
  }

  return context;
};

export default useCompetition;
