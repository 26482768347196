import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addCompetitionAdmin } from '../../actions/competition/thunks/add-competition-admin';
import { addEventAdmin } from '../../actions/event-admin/thunks/invite-admin';
import { addGuideAdmin } from '../../actions/guide/thunks/add-guide-admin';
import { getProfileById } from '../../actions/profile';
import { addAdmin } from '../../actions/profile-admin';
import { Button, Main } from '../../components';
import { StyledInput } from '../../components/styled-input';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { profileSelector } from '../../selectors/profile';

export const AddAdministratorPage = () => {
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState();
  const [roomValue, setRoomValue] = useState();
  const [isValid, setIsValid] = useState(true);
  const profile = useSelector(profileSelector);
  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);

  const changeNameHandler = e => {
    setNameValue(e.target.value);
  };

  const changeRoomHandler = async e => {
    setRoomValue(e.target.value);
    const rezult = await dispatch(getProfileById({ id: e.target.value }));
    setIsValid(rezult?.payload?.status === 200);
  };

  const addAdministrator = async () => {
    dispatch(
      activeType === 'object'
        ? addEventAdmin({ name: nameValue, adminId: +roomValue, eventId: activeId })
        : activeType === 'competition'
          ? addCompetitionAdmin({ name: nameValue, adminId: +roomValue, cabinetId: activeId })
          : activeType === 'guide'
            ? addGuideAdmin({ name: nameValue, adminId: +roomValue, cabinetId: activeId })
            : addAdmin({ data: { name: nameValue, adminId: +roomValue }, id: profile?.id }),
    );
    setNameValue('');
    setRoomValue('');
  };

  return (
    <Main title="Добавить Администратора" light lineHeight={32}>
      <div>
        <div className="mb-[10px] mt-[56px]">
          <StyledInput
            onChange={e => changeNameHandler(e)}
            value={nameValue}
            placeholder="Введите имя/должность..."
            textOpacity={50}
            placeholderOpacity={50}
            isSettingsProfilePage
          />
        </div>
        <div style={{ marginBottom: px(10) }}>
          <StyledInput
            numberType
            title="ID кабинета"
            onChange={e => changeRoomHandler(e)}
            value={roomValue}
            placeholder="Введите ID кабинета Адм…"
            textOpacity={50}
            placeholderOpacity={50}
            error={!isValid}
          />
        </div>
        <Button
          height={60}
          borderRadius={0}
          className="w-full"
          onClick={addAdministrator}
          disabled={!isValid || !nameValue || !roomValue}
        >
          Добавить
        </Button>
      </div>
    </Main>
  );
};
