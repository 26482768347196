import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/event';
import { createRequest } from '../../../helpers/api/createRequest';
import { clearActiveId } from '../../auth';
import { addModal } from '../../modal/addModal';

export const DELETE_EVENT = 'event/deleteEvent';

const onFail = () => async dispatch => {
  await dispatch(
    addModal({
      id: 31,
      data: {
        title: 'Данный кабинет не может быть удален',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

const onSuccess = () => async dispatch => {
  await dispatch(clearActiveId());
};

export const deleteEvent = createAsyncThunk(
  DELETE_EVENT,
  createRequest({
    type: DELETE_EVENT,
    loader: data => authApi.deleteEvent(data),
    onFail,
    onSuccess,
  }),
);
