import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/competition';
import { createRequest } from '../../../helpers/api/createRequest';

export const BLOCK_COMPETITION_CABINET = 'competition/blockCompetitionCabinet';

export const blockCompetitionCabinet = createAsyncThunk(
  BLOCK_COMPETITION_CABINET,
  createRequest({
    type: BLOCK_COMPETITION_CABINET,
    loader: data => authApi.blockCompetitionCabinet(data),
  }),
);
