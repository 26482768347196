import { request } from '@/helpers/api/request';

export const profilePassport = async ({ data, id }) =>
  request({
    url: `profile/passport/${id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    data,
  });
