import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation} from 'react-router';

import { getObjectFeedback,getObjectFeedbackNoAuth } from '../../actions/event-review';
import { Button, FeedbackComponent } from '../../components';
import FeedbackStar from '../../components/feedback-star';
import { feedbackComponentCriterion } from '../../constants/Component';
import { px } from '../../constants/Px';
import { feedbackSelector,feedbackSelectorNoAuth } from '../../selectors/event-review';
import { authSelector } from '../../selectors/auth';
import ObjectFeedbackHeader from '../../new-architecture/object/components/object-feedback-header/object-feedback-header';

export const ObjectFeedbackPage = () => {

  const { state } = useLocation();
  const auth = useSelector(authSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [seeAllFeedback, setSeeAllFeedback] = useState(false);
  const data = auth?useSelector(feedbackSelector): useSelector(feedbackSelectorNoAuth) ;
 
  useEffect(() => {
    if(auth)
   { dispatch(getObjectFeedback({ id: state }));}
   else{
    dispatch(getObjectFeedbackNoAuth({ id:state })
    )}
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const arr = [1, 2, 3, 4, 5];
  
  return (
    <ObjectFeedbackHeader
      title="Отзывы"
      fontSize={32}
      lineHeight={43}
      light
      withId
      withBanner
      objectId={state}
      marginTop={px(20)}
    >
      <div className="flex-column flex " style={{ gap: px(10), marginTop: px(36) }}>
        {feedbackComponentCriterion.map(item => (
          <div
            key={item?.id}
            className="flex flex-row items-center justify-between"
            style={{ height: px(31) }}
          >
            <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
              {item.title}
            </p>
            <div className="flex flex-row items-center justify-center" style={{ gap: px(10) }}>
              {arr.map(e => (
                <FeedbackStar
                  key={item.objectKey}
                  starId={e}
                  objectKey={item.objectKey}
                  objectFeedback
                  feedbackEstimate={data}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      { (data && (data[0]?.negativeText || data[0]?.positiveText)) && <div style={{ marginTop: px(20) }}>
        {seeAllFeedback
          ? data?.map(item => {
              if (item) return <FeedbackComponent data={item} countFeedback={data.length} />;
            })
          : data?.slice(0, 1)?.map(item => {
              if (item) return <FeedbackComponent data={item} countFeedback={data.length} />;
            })}

        {data?.length > 1 && !seeAllFeedback && (
          <Button
            className="m-auto border-[1px] border-solid border-black "
            marginTop={12}
            fontSize={20}
            lineHeight={26}
            bgColor="transparent"
            color="#000"
            onClick={() => setSeeAllFeedback(true)}
          >
            Посмотреть все
          </Button>
        )}
      </div>}
      
    </ObjectFeedbackHeader>
  );
};
