import React, { useRef, useState } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { CustomAlert } from './custom-alert';
import { Icon } from './Icon';

export const FileUpload = ({
  title,
  uploadButton,
  uploadButtonStyle,
  selectedFiles,
  setSelectedFiles,
  single,
  styles,
  accept,
}) => {
  const inputRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [showMaxLinitAlert, setShowMaxLinitAlert] = useState(false);

  const handleFileSelect = event => {
    const files = [...event.target.files];

    const fileCount = (files?.length || 0) + (selectedFiles?.length || 0);
    if (fileCount > 3) {
      setShowMaxLinitAlert(true);
    } else {
      setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...files]);
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const handleFileRemove = file => {
    setSelectedFiles(prevSelectedFiles => prevSelectedFiles.filter(f => f !== file));
    setShowAlert(false);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  return (
    <div>
      {!(single && selectedFiles?.length) && (
        <div
          style={{
            height: title ? px(60) : 'auto',
            paddingLeft: single ? 0 : px(18),
            paddingRight: single ? 0 : px(18),
            paddingTop: title ? px(4) : px(20),
            paddingBottom: title ? px(4) : px(10),
            borderBottom: single || title ? '' : '1px solid #707070',
          }}
          className={`flex ${title && 'border-[1px] border-solid border-black border-opacity-30'}`}
        >
          <label
            className={`${
              title ? '' : `flex items-center justify-${single ? 'start' : 'center'}`
            } w-full`}
            htmlFor="upload-photo"
          >
            {title && (
              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                }}
                className="font-normal opacity-50"
              >
                {title}
              </p>
            )}
            <p
              style={
                uploadButtonStyle || {
                  fontSize: px(20),
                  lineHeight: px(26),
                }
              }
              className="font-normal text-[#0A84FE]"
            >
              {uploadButton}
            </p>
            <input
              className="leading-0 m-0 h-0 w-0  p-0 opacity-0 "
              ref={inputRef}
              id="upload-photo"
              type="file"
              accept={accept || 'image/*'}
              multiple
              onChange={handleFileSelect}
            />
          </label>
        </div>
      )}
      <div
        style={{
          gap: styles?.gap || px(27),
          marginTop: styles?.marginTop || px(13),
          marginBottom: px(20),
        }}
        className="flex flex-col"
      >
        {selectedFiles.map(file => (
          <div
            key={file.name}
            className="flex items-center"
            style={{
              gap: px(12),
            }}
          >
            <div
              style={{
                gap: px(10),
                width: px(159),
              }}
              className="flex justify-between"
            >
              <Icon
                type={TypesIcon.DOCUMENTFINANCE}
                width={px(18.018)}
                height={px(23.655)}
                viewBox="0 0 18.018 23.655"
              />

              <p
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  width: px(131),
                }}
                className="... truncate font-normal text-[#0A84FE]"
              >
                {file.name}
              </p>
            </div>
            <div className="flex flex-1 items-center  justify-between">
              <p>{`${
                (file.size / (1024 * 1024)).toFixed(1) >= 0.1
                  ? (file.size / (1024 * 1024)).toFixed(1)
                  : 0.1
              }Мб`}</p>
              <Icon
                onClick={handleShowAlert}
                type={TypesIcon.CLOSERED}
                width={px(40)}
                height={px(40)}
                viewBox="0 0 40 40"
              />
              {showAlert && (
                <CustomAlert
                  onClickButtonSubmit={() => handleFileRemove(file)}
                  onClickButtonClose={handleCloseAlert}
                  title="Удалить файл?"
                  buttonLeftText="Нет"
                  buttonRightText="Да"
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {showMaxLinitAlert && (
        <CustomAlert
          onClickButtonSubmit={() => setShowMaxLinitAlert(false)}
          title="Максимальное количество фaйлов 3"
          single
          buttonRightText="Ок"
        />
      )}
    </div>
  );
};

export default FileUpload;
