import React from 'react';
import { px } from '../constants/Px';

export const DistributionPageDatePickers = ({ id, data, setDataPickerId, setShow }) => {
  const openPicker = (value) => {
    setDataPickerId(value);
    setShow(true);
  };

  return (
    <div className='flex flex-column'>
      <p
        className='opacity-50 font-normal '
        style={{
          fontSize: px(15),
          lineHeight: px(20),
          paddingBottom: px(13),
        }}
      >
        Стартовое время
      </p>
      <p
        onClick={() => {
          openPicker(id);
        }}
        className='font-normal'
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          paddingBottom: px(5),
        }}
      >
        {data}
      </p>
      <hr
        style={{
          width: px(125),
        }}
      />
    </div>
  );
};

export default DistributionPageDatePickers;
