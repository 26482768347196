import React from 'react';

import { iconSectors } from '../constants/Component/sector';
import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Sector = ({ type, sectorNumber, price, active, onClick, discount }) => {
  const typeData = iconSectors?.find(item => item?.type === type);
  return (
    <div
      style={{
        width: px(90),
        height: px(90),
        borderRadius: px(15),
        border: active && `${px(1)} solid #0A84FE`,
      }}
      className={`relative ${active ? 'bg-[#FFFFFF]' : 'bg-[#212B35]'}`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {discount && (
        <div
          style={{
            left: px(-5),
            top: px(-5),
          }}
          className="absolute "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={px(21.622)}
            height={px(23.801)}
            viewBox="0 0 21.622 23.801"
          >
            <g id="Group_3198" data-name="Group 3198" transform="translate(-236.309 -665.069)">
              <g
                id="Group_2650"
                data-name="Group 2650"
                transform="translate(925.674 429.009) rotate(90)"
              >
                <g id="insignia" transform="translate(236.06 689.366) rotate(-90)">
                  <path
                    id="Union_24"
                    data-name="Union 24"
                    d="M10.255,17.454,0,23.8V0H21.078V23.8Z"
                    transform="translate(0 0)"
                    fill="red"
                  />
                </g>
              </g>
              <g
                id="Личная_информация"
                data-name="Личная информация"
                transform="translate(82.93 425.979)"
              >
                <text
                  id="_20"
                  data-name="20"
                  transform="translate(155 252)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="Helvetica-Bold, Helvetica"
                  fontWeight="700"
                >
                  <tspan x="0" y="0">
                    {discount}
                  </tspan>
                </text>
                <text
                  id="_"
                  data-name="%"
                  transform="translate(166 252)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="Helvetica"
                >
                  <tspan x="0" y="0">
                    %
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
      )}
      {type?.place?.freeZone === 'true' ? (
        <div
          style={{
            paddingTop: px(8),
            paddingBottom: px(4),
          }}
          className="flex items-center justify-center"
        >
          <Icon
            type={TypesIcon.FREEZONEAVATAR}
            viewBox="0 0 36 35"
            width={px(36)}
            height={px(35)}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              height: px(35),
              paddingLeft: px(5),
              paddingRight: px(sectorNumber?.toString()?.length === 1 ? 15 : 5),
            }}
            className="flex justify-between "
          >
            {/* <div className="flex  items-end">
              {type.objects.includes('house') ? (
                <Icon
                  type={TypesIcon.HOUSEAVATAR}
                  viewBox="0 0 27 30"
                  width={px(27)}
                  height={px(30)}
                  fill={`${active ? '#000000' : '#FFFFFF'}`}
                  stroke={`${active ? '#000000' : '#FFFFFF'}`}
                />
              ) : type.objects.includes('alcove') ? (
                <Icon
                  type={TypesIcon.ALCOVEAVATAR}
                  viewBox="0 0 35 27"
                  width={px(35)}
                  height={px(27)}
                  fill={`${active ? '#000000' : '#FFFFFF'}`}
                  stroke={`${active ? '#000000' : '#FFFFFF'}`}
                />
              ) : type.objects.includes('tent') ? (
                <Icon
                  type={TypesIcon.CANOPYAVATAR}
                  viewBox="0 0 35 27"
                  width={px(35)}
                  height={px(27)}
                  fill={`${active ? '#000000' : '#FFFFFF'}`}
                  stroke={`${active ? '#000000' : '#FFFFFF'}`}
                />
              ) : (
                <Icon
                  type={TypesIcon.BATHHOUSEAVATAR}
                  viewBox="0 0 36 33"
                  width={px(36)}
                  height={px(33)}
                  fill={`${active ? '#000000' : '#FFFFFF'}`}
                  stroke={`${active ? '#000000' : '#FFFFFF'}`}
                />
              )}
            </div> */}

            <p
              style={{
                fontSize: px(sectorNumber?.toString()?.length === 3 ? 20 : 32),
                lineHeight: px(sectorNumber?.toString()?.length === 3 ? 26 : 43),
                paddingTop: px(sectorNumber?.toString()?.length === 3 && 10),
              }}
              className={`${active ? 'text-[#000000]' : 'text-[#FFFFFF]'} font-normal opacity-50`}
            >
              {sectorNumber}
            </p>
          </div>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className={`${
              active ? 'text-[#000000]' : 'text-[#FFFFFF]'
            } text-center font-normal opacity-50`}
          >
            сектор
          </p>
        </>
      )}

      {price && (
        <p
          style={{
            fontSize: px(20),
            lineHeight: px(26),
          }}
          className={`text-center font-normal ${discount ? 'text-[#FF0000]' : 'text-[#0A84FE]'}`}
        >
          {price}
        </p>
      )}
    </div>
  );
};

export default Sector;
