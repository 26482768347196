import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/auth';
import { clearProfile } from '../../profile';
import { clearAuth } from '../clear-auth';

export const REFRESH = 'auth/refresh';

const onFail = () => async (dispatch) => {
  dispatch(clearProfile());
  dispatch(clearAuth());
};

export const refresh = createAsyncThunk(
  REFRESH,
  createRequest({
    type: REFRESH,
    loader: (data) => authApi.refresh(data),
    onFail,
  }),
);
