import { request } from '@/helpers/api/request';

export const addAdmin = async ({data, id}) => {
  const response = await request({
    url: `profile-admin/${id}`,
    method: 'POST',
    data
  });

  return response;
};