import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const GET_GUIDES = 'guide/getGuides';

export const getGuides = createAsyncThunk(
  GET_GUIDES,
  createRequest({
    type: GET_GUIDES,
    loader: data => authApi.getGuides(data),
  }),
);
