/* import { addCompetitionAttachment } from '../../../../../../actions/competition'; */
import {
  addCompetitionAttachment,
  deleteCompetitionAttachment,
  getCompetitionCabinet,
  updateCompetitionCabinet,
  updateCompetitionsContacts,
} from '../../../../../../api/competition';
import { urlToBlob } from '../../../../../utils/blob';
// TODO
export const removeAttachments = async (eventId, type) => {
  const {
    data: { attachments },
  } = await getCompetitionCabinet({ id: eventId });

  const filtered = attachments?.filter?.(({ type: t }) => t === type);

  if (filtered?.length) {
    await Promise.all(filtered?.map(({ id }) => deleteCompetitionAttachment({ id })));
  }
};

export const updateCompetition = async (dispatch, competition, fields) => {
  try {
    await Promise.all(
      ['LOGO', 'POSTCARD', 'BANNER', 'GALLERY'].map(type =>
        removeAttachments(competition?.id, type),
      ),
    );

    if (fields?.gallery?.length) {
      await Promise.all(
        fields?.gallery?.map(async image => {
          if (image?.src) {
            const formData = new FormData();
            formData.append('file', await urlToBlob(image?.src));
            formData.append('cabinetId', competition?.id);
            formData.append('type', 'GALLERY');
            await addCompetitionAttachment(formData);
          }
        }),
      );
    }

    if (fields?.attachments?.length) {
      await Promise.all(
        fields?.attachments.map(async image => {
          if (image?.src) {
            const formData = new FormData();
            formData.append('file', await urlToBlob(image?.src));
            formData.append('cabinetId', competition?.id);
            formData.append('type', image?.key?.toUpperCase());
            await addCompetitionAttachment(formData);
          }
        }),
      );
    }

    await Promise.all([
      updateCompetitionCabinet({
        data: {
          name: fields?.name,
          description: fields?.description,
        },
        id: competition?.id,
      }),
      updateCompetitionsContacts({
        id: competition?.contacts?.id,
        contactsData: {
          country: fields?.contacts?.country,
          region: fields?.contacts?.region,
          district: fields?.contacts?.district,
          street: fields?.contacts?.street,
          town: fields?.contacts?.town,
          mainPhone: fields?.contacts?.mainPhone,
          mainPhoneStartTime: fields?.contacts?.mainPhoneStartTime,
          mainPhoneEndTime: fields?.contacts?.mainPhoneEndTime,
          secondaryPhone: fields?.contacts?.secondaryPhone,
          secondaryPhoneStartTime: fields?.contacts?.secondaryPhoneStartTime,
          secondaryPhoneEndTime: fields?.contacts?.secondaryPhoneEndTime,
          email: fields?.contacts?.email,
          whatsAppLink: fields?.contacts?.whatsAppLink,
          telegramLink: fields?.contacts?.telegramLink,
          viberLink: fields?.contacts?.viberLink,
          socialPageLink: fields?.contacts?.socialPageLink,
          websiteLink: fields?.contacts?.websiteLink,
          secondaryWebsiteLink: fields?.contacts?.secondaryWebsiteLink,
          coordinates: fields?.contacts?.coordinates?.split(',')?.map(i => i?.trim?.()),
        },
      }),
    ]);

    await dispatch(getCompetitionCabinet({ id: competition?.id }));
  } catch {
    console.log('error');
  }
};
