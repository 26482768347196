import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { addActiveObjectId, addActiveType, logout } from '../../actions/auth';
import { getCompetitionCabinetByProfile } from '../../actions/competition';
import { cancelDeleteCabinet } from '../../actions/competition/thunks/cancel-delete-cabinet';
import { cancelDelete, getEvents } from '../../actions/event';
import { cancelDeleteGuideCabinet, getGuideCabinetByProfile } from '../../actions/guide';
import { getProfile, getProfileById } from '../../actions/profile';
import { Button, CustomAlert } from '../../components';
import { Header } from '../../components/header';
import { Icon } from '../../components/Icon';
import { px } from '../../constants/Px';
import { authActiveIdSelector, authActiveType } from '../../selectors/auth';
import { competitionsSelector } from '../../selectors/competition';
import { eventsSelector } from '../../selectors/event';
import { guidesSelector } from '../../selectors/guide';
import { profileSelector } from '../../selectors/profile';

export const SelectProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  const events = useSelector(eventsSelector);
  const competitions = useSelector(competitionsSelector);
  const guides = useSelector(guidesSelector);
  const activeId = useSelector(authActiveIdSelector);
  const activeType = useSelector(authActiveType);
  const [showFilter, setShowFilter] = useState(false);
  const [menuToggleOpen, setMenuToggleOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [active, setActive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [cabinetType, setCabinetType] = useState();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleLockClass = () => {
    setIsLocked(!isLocked);
  };

  const handleLogout = () => {
    setShowAlert(true);
  };

  function onClickButtonClose() {
    setShowAlert(false);
  }

  const PROFILE = events
    ? [{ id: 100_000_000, name: profile?.name, iconName: '' }]
        ?.concat(
          profile?.administered
            ?.filter(item => item.status === 'ACTIVE')
            ?.map(admin => ({ ...admin.profile, type: 'administeredProfile' })),
        )
        ?.concat(
          profile?.EventAdmin?.filter?.(item => item.eventAdminStatus === 'ACTIVE')
            ?.filter(item => !events.map(ev => ev.id).includes(item.eventId))
            ?.map(admin => ({ ...admin.event, type: 'administeredObject' })),
        )
        ?.concat(events?.map(event => ({ ...event, type: 'object' })))
        ?.concat(competitions?.map(competition => ({ ...competition, type: 'competition' })))
        ?.concat(
          profile?.competitionAdmim
            ?.filter(item => !competitions?.map(ev => ev.id).includes(item.cabinetId))
            ?.map(admin => ({ ...admin?.cabinet, type: 'administeredCompetition' })),
        )
        ?.concat(guides?.map(guide => ({ ...guide, type: 'guide' })))
        ?.concat(
          profile?.guideAdmin
            ?.filter(item => !guides?.map(ev => ev.id).includes(item.cabinetId))
            ?.map(admin => ({ ...admin?.cabinet, type: 'administeredGuide' })),
        )
    : [];

  const onClickButtonSubmit = async () => {
    try {
      await dispatch(logout());
    } catch {
      console.log('logout error');
    }
    navigate('/');
  };
  const onClickButtonEnter = () => {
    dispatch(addActiveObjectId({ id: active }));
    if (cabinetType === 'object') dispatch(cancelDelete({ id: active }));
    if (cabinetType === 'competition') dispatch(cancelDeleteCabinet({ id: active }));
    if (cabinetType === 'guide') dispatch(cancelDeleteGuideCabinet({ id: active }));
    dispatch(addActiveType({ activeType: cabinetType }));
    if (active === 100_000_000 || cabinetType === 'administeredProfile') navigate('/profile');
    if (cabinetType === 'object' || cabinetType === 'administeredObject') navigate(`/user-object`);
    if (cabinetType === 'competition' || cabinetType === 'administeredCompetition')
      navigate(`/user-competition`);
    if (cabinetType === 'guide' || cabinetType === 'administeredGuide') navigate(`/user-guide`);
    /* navigate(
      active === 100_000_000 || cabinetType === 'administeredProfile'
        ? '/profile'
        : cabinetType === 'object' || cabinetType === 'administeredObject'
          ? `/user-object`
          : `/user-competition`,
    ); */
  };

  useEffect(() => {
    if (profile?.id) {
      // TODO
      dispatch(getProfileById({ id: activeId }));
    } else {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getEvents({ profileId: profile?.id }));
      dispatch(getCompetitionCabinetByProfile({ id: profile?.id }));
      dispatch(getGuideCabinetByProfile({ id: profile?.id }));
    }
  }, [profile?.id]);

  return (
    <div className="global-container ">
      <Header
        menuToggleOpen={menuToggleOpen}
        setMenuToggleOpen={setMenuToggleOpen}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        toggleLockClass={toggleLockClass}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
      />

      <main>
        <div
          style={{
            padding: `${px(10)} ${px(15)} ${px(80)} ${px(15)}`,
          }}
        >
          {PROFILE.map((item, index) => (
            <div
              style={{
                height: px(60),
                padding: `${px(17)} ${px(18)}`,
                borderRadius: px(0),
                marginBottom: px(10),
              }}
              key={item?.id}
              onClick={() => {
                setActive(item?.id);
                setCabinetType(item?.type);
              }}
              onKeyDown={() => setActive(item?.id)}
              className={`relative w-full font-normal ${
                item?.id === active
                  ? 'border-[2px] border-solid border-[#0A84FE]'
                  : item?.id === activeId
                    ? 'border-[2px] border-solid border-[#04B810]'
                    : activeId === undefined && index === 0
                      ? 'border-[2px] border-solid border-[#04B810]'
                      : 'border-[2px] border-solid border-[#000000] border-opacity-30'
              }`}
            >
              {item?.iconName && (
                <div
                  className="absolute flex items-center justify-start"
                  style={{
                    top: px(-8),
                    left: px(-0.5),
                  }}
                >
                  <Icon
                    width={px(21.1)}
                    height={px(23.8)}
                    viewBox="0 0 21.1 23.8"
                    type={item?.iconName}
                  />
                </div>
              )}

              <div
                className="line-clamp-1"
                style={{
                  fontSize: px(20),
                  lineHeight: px(26),
                  fontWeight: 400,
                  wordBreak: 'break-all',
                }}
              >
                {item?.name}
              </div>
              {item?.deletedAt && (
                <div className="absolute" style={{ top: px(-7), left: px(-2) }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={px(21.078)}
                    height={px(23.801)}
                    viewBox="0 0 21.078 23.801"
                  >
                    <g
                      id="Сгруппировать_3967"
                      data-name="Сгруппировать 3967"
                      transform="translate(18879 1495.901)"
                    >
                      <g
                        id="Сгруппировать_3965"
                        data-name="Сгруппировать 3965"
                        transform="translate(-8 -72.901)"
                      >
                        <g
                          id="Сгруппировать_3961"
                          data-name="Сгруппировать 3961"
                          transform="translate(-19107.309 -2088.069)"
                        >
                          <g
                            id="Сгруппировать_2650"
                            data-name="Сгруппировать 2650"
                            transform="translate(925.674 429.009) rotate(90)"
                          >
                            <g id="insignia" transform="translate(236.06 689.366) rotate(-90)">
                              <path
                                id="Объединение_24"
                                data-name="Объединение 24"
                                d="M10.255,17.454,0,23.8V0H21.078V23.8Z"
                                transform="translate(0 0)"
                                fill="red"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        id="Сгруппировать_3966"
                        data-name="Сгруппировать 3966"
                        transform="translate(-19043 -2177)"
                      >
                        <g id="trash" transform="translate(170 684)">
                          <path
                            id="Контур_431"
                            data-name="Контур 431"
                            d="M56.138,1.385h-1.9V1.039A1.04,1.04,0,0,0,53.195,0H51.809A1.04,1.04,0,0,0,50.77,1.039v.346h-1.9A.867.867,0,0,0,48,2.251V3.463a.346.346,0,0,0,.346.346h.189l.3,6.283a1.038,1.038,0,0,0,1.038.989h5.259a1.038,1.038,0,0,0,1.038-.989l.3-6.283h.189A.346.346,0,0,0,57,3.463V2.251A.867.867,0,0,0,56.138,1.385Zm-4.675-.346a.347.347,0,0,1,.346-.346h1.385a.347.347,0,0,1,.346.346v.346H51.463Zm-2.77,1.212a.173.173,0,0,1,.173-.173h7.272a.173.173,0,0,1,.173.173v.866H48.693Zm6.785,7.808a.346.346,0,0,1-.346.33H49.872a.346.346,0,0,1-.346-.33l-.3-6.25h6.546Z"
                            transform="translate(-48)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_432"
                            data-name="Контур 432"
                            d="M240.346,213.195a.346.346,0,0,0,.346-.346v-4.5a.346.346,0,0,0-.693,0v4.5A.346.346,0,0,0,240.346,213.195Z"
                            transform="translate(-235.844 -203.498)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_433"
                            data-name="Контур 433"
                            d="M320.346,213.195a.346.346,0,0,0,.346-.346v-4.5a.346.346,0,1,0-.693,0v4.5A.346.346,0,0,0,320.346,213.195Z"
                            transform="translate(-314.113 -203.498)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_434"
                            data-name="Контур 434"
                            d="M160.346,213.195a.346.346,0,0,0,.346-.346v-4.5a.346.346,0,0,0-.693,0v4.5A.346.346,0,0,0,160.346,213.195Z"
                            transform="translate(-157.576 -203.498)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              )}
              {item?.status === 'BLOCKED' && (
                <div className="absolute" style={{ top: px(-7), left: px(-2) }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={px(21.078)}
                    height={px(23.801)}
                    viewBox="0 0 21.078 23.801"
                  >
                    <g
                      id="Сгруппировать_3964"
                      data-name="Сгруппировать 3964"
                      transform="translate(18871 1423)"
                    >
                      <g
                        id="Сгруппировать_3961"
                        data-name="Сгруппировать 3961"
                        transform="translate(-19107.309 -2088.069)"
                      >
                        <g
                          id="Сгруппировать_2650"
                          data-name="Сгруппировать 2650"
                          transform="translate(925.674 429.009) rotate(90)"
                        >
                          <g id="insignia" transform="translate(236.06 689.366) rotate(-90)">
                            <path
                              id="Объединение_24"
                              data-name="Объединение 24"
                              d="M10.255,17.454,0,23.8V0H21.078V23.8Z"
                              transform="translate(0 0)"
                              fill="red"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Сгруппировать_3963"
                        data-name="Сгруппировать 3963"
                        transform="translate(0.001)"
                      >
                        <g id="padlock" transform="translate(-18868 -1420)">
                          <path
                            id="Контур_435"
                            data-name="Контур 435"
                            d="M10.142,15.8H4.02A1.022,1.022,0,0,1,3,14.782V10.02A1.022,1.022,0,0,1,4.02,9h6.122a1.022,1.022,0,0,1,1.02,1.02v4.761A1.022,1.022,0,0,1,10.142,15.8ZM4.02,9.68a.341.341,0,0,0-.34.34v4.761a.341.341,0,0,0,.34.34h6.122a.341.341,0,0,0,.34-.34V10.02a.341.341,0,0,0-.34-.34Z"
                            transform="translate(0 -4.919)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_436"
                            data-name="Контур 436"
                            d="M11.1,4.761a.34.34,0,0,1-.34-.34v-1.7a2.041,2.041,0,1,0-4.081,0v1.7a.34.34,0,0,1-.68,0v-1.7a2.721,2.721,0,0,1,5.441,0v1.7A.34.34,0,0,1,11.1,4.761Z"
                            transform="translate(-1.64)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_437"
                            data-name="Контур 437"
                            d="M10.907,14.814a.907.907,0,1,1,.907-.907A.908.908,0,0,1,10.907,14.814Zm0-1.134a.227.227,0,1,0,.227.227A.227.227,0,0,0,10.907,13.68Z"
                            transform="translate(-3.826 -7.105)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_438"
                            data-name="Контур 438"
                            d="M11.59,17.677a.34.34,0,0,1-.34-.34V16.09a.34.34,0,0,1,.68,0v1.247A.34.34,0,0,1,11.59,17.677Z"
                            transform="translate(-4.509 -8.608)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              )}
            </div>
          ))}
          <div
            style={{
              gap: px(10),
            }}
            className="flex flex-row"
          >
            <Button
              type="submit"
              className="w-full border-[1px] border-solid border-[#dddddd] "
              fontSize={20}
              lineHeight={26}
              height={60}
              borderRadius={0}
              disabled={!active}
              onClick={onClickButtonEnter}
            >
              Войти
            </Button>
            <Button
              disabled={!active}
              className="flex  w-full cursor-pointer items-center justify-center border-[1px] border-solid border-[#dddddd]"
              bgColor="#fff"
              color="#030303"
              fontSize={20}
              lineHeight={26}
              height={60}
              borderRadius={0}
              onClick={handleLogout}
            >
              Выйти
            </Button>
          </div>
          {!activeType && (
            <Link to="/profile#registration">
              <p
                style={{
                  fontSize: px(15),
                  lineHeight: px(20),
                  marginTop: px(20),
                }}
                className="font-normal  text-[#0A84FE]"
              >
                Регистрация нового Объекта или Услуги
              </p>
            </Link>
          )}

          {showAlert && (
            <CustomAlert
              onClickButtonSubmit={onClickButtonSubmit}
              // eslint-disable-next-line react/jsx-no-bind
              onClickButtonClose={onClickButtonClose}
              title="Вы действительно хотите выйти?"
              buttonLeftText="Отмена"
              buttonRightText="Выйти"
            />
          )}
        </div>
      </main>
    </div>
  );
};
