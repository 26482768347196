/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const ADD_GUIDE_ATTACHMENT = 'guide/addAttachment';

export const addGuideAttachment = createAsyncThunk(
  ADD_GUIDE_ATTACHMENT,
  createRequest({
    type: ADD_GUIDE_ATTACHMENT,
    loader: data => authApi.addGuideAttachment(data),
  }),
);
