import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/guide';
import { createRequest } from '../../../helpers/api/createRequest';

export const CREATE_GUIDE_OFFER = 'guide/createGuideOffer';

export const createGuideOffer = createAsyncThunk(
  CREATE_GUIDE_OFFER,
  createRequest({
    type: CREATE_GUIDE_OFFER,
    loader: data => authApi.createGuideOffer(data),
  }),
);
