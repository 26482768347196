import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/subscription';

export const GET_SUBSCRIPTIONS = 'subscription/getSubscription';


export const getSubscription = createAsyncThunk(
  GET_SUBSCRIPTIONS,
    createRequest({
        type: GET_SUBSCRIPTIONS,
        loader: (data) => authApi.getSubscription(data),
    }),
);