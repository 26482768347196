import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icon } from '../../components/Icon';
import { px } from '../../constants/Px';
import { TypesIcon } from '../../types/TypesIcon';

export const SliderPage = () => {
  const navigate = useNavigate();
  const [isLandscape, setIsLandscape] = useState(false);
  const [canPan, setCanPan] = useState(false);
  const transformWrapperRef = useRef();

  const { state } = useLocation();
  const closePopup = () => {
    if (state?.isOpen) {
      navigate(-1, { state: { ...state, isOpen: true } });
      document.body.classList.remove('horizontal');
    } else {
      navigate(-1);
      document.body.classList.remove('horizontal');
    }
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsLandscape(window.innerWidth > window.innerHeight);
      document.body.style.backgroundColor = window.innerWidth > window.innerHeight ? 'black' : '';
      transformWrapperRef?.current?.resetTransform?.();
    }, 100);
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.backgroundColor = '';
    };
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main className={`${isLandscape ? '' : 'global-container'}`}>
      <div className={` ${isLandscape && 'home_page-hero-popup horizontal'}`}>
        <div
          className="absolute right-0 top-0"
          style={{
            height: !isLandscape && px(50),
            width: !isLandscape && px(50),
          }}
          aria-hidden
          onClick={closePopup}
        >
          <div
            className={`${
              isLandscape
                ? 'search-menu-item-preferences-closeHorizontal'
                : 'search-menu-item-preferences-close'
            }`}
          >
            <Icon type={TypesIcon.CROSS} width={px(17)} height={px(17)} viewBox="0 0 17 17" />
          </div>
        </div>

        <div className={`home_page-hero-popup-content ${isLandscape && 'horizontal'}`}>
          {!isLandscape && <h3>Фото. Видео</h3>}
          <TransformWrapper
            panning={{
              disabled: !canPan,
              velocityDisabled: true,
            }}
            onTransformed={({ state: { scale } }) => setCanPan(scale !== 1)}
            zoomAnimation={{
              disabled: true,
            }}
            doubleClick={{
              disabled: true,
            }}
            centerOnInit
            ref={transformWrapperRef}
          >
            <TransformComponent wrapperClass="!h-full" contentClass="!h-full">
              <div className="h-full w-[100dvw]">
                <Swiper
                  spaceBetween={0}
                  modules={[Navigation, Pagination, Zoom]}
                  pagination={{ clickable: true }}
                  navigation={{
                    prevEl: '.popup-arrow-prev',
                    nextEl: '.popup-arrow-next',
                  }}
                  className="home_page-hero horizontal bg-black"
                  noSwipingClass="swiper-no-swiping"
                  onSlideChange={() => transformWrapperRef?.current?.resetTransform?.()}
                >
                  {state?.images?.map((img, i) => (
                    <SwiperSlide
                      style={{
                        touchAction: 'none',
                      }}
                      key={img?.src}
                      className={canPan ? 'swiper-no-swiping' : ''}
                    >
                      <img src={img.src} className="h-full object-contain" alt={`slider-${i}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </TransformComponent>
          </TransformWrapper>
          <div
            className="swiper-button-prev popup-arrow-prev  "
            style={{
              marginTop: px(0),
              top: !isLandscape && '65%',
            }}
          />
          <div
            className="swiper-button-next popup-arrow-next "
            style={{
              marginTop: px(0),
              top: !isLandscape && '65%',
            }}
          />
        </div>
      </div>
    </main>
  );
};
