import * as profileAdminApi from '@/api/profile-admin';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdmins } from './get-admins';


export const DELETE_ADMIN = 'profile-admin/deleteAdmin';

const onSuccess = () => async (dispatch) => {
  dispatch(
    getAdmins()
  );
};

export const deleteAdmin = createAsyncThunk(
  DELETE_ADMIN,
  createRequest({
    type: DELETE_ADMIN,
    loader: (data) => profileAdminApi.deleteAdmin(data),
    onSuccess,
  }),
);
