/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '@/api/guide';
import { createRequest } from '@/helpers/api/createRequest';

export const TRANSFER_GUIDE = 'guide/transferGuide';

export const transferGuide = createAsyncThunk(
  TRANSFER_GUIDE,
  createRequest({
    type: TRANSFER_GUIDE,
    loader: data => authApi.transferGuide(data),
  }),
);
