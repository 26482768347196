import { request } from '../../helpers/api/request';

export const sendCards = async ({ objectId }) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const response = await request({
    url: `club-card/mail`,
    method: 'GET',
    params: { objectId },
  });
  return response;
};
