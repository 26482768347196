import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { blockCompetitionAdmin, getCompetitionCabinet } from '../actions/competition/thunks';
import { getEvent } from '../actions/event';
import { blockEventAdmin } from '../actions/event/thunks/block-event-admin';
import { blockGuideAdmin, getGuideCabinet } from '../actions/guide';
import { getProfileById } from '../actions/profile';
import { blockAdmin } from '../actions/profile-admin';
import { addLeadingZeros } from '../constants/Function';
import { px } from '../constants/Px';
import { CircleButton } from '../new-architecture/shared';
import { authActiveType } from '../selectors/auth';
import { TypesIcon } from '../types/TypesIcon';
import { Switch } from './switch';

export const AdminManager = ({ className, style, admin, deleteAdmin, getId }) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [control, setControl] = useState(false);
  const [avatar, setAvatar] = useState();
  const activeType = useSelector(authActiveType);

  const handleControl = () => {
    if (admin.status === 'ACTIVE' || admin.eventAdminStatus === 'ACTIVE') setControl(!control);
  };

  const handleLock = async status => {
    await dispatch(
      activeType === 'object' || activeType === 'administeredObject'
        ? blockEventAdmin({
            eventAdminId: admin?.id,
            data: { status, name: admin?.name },
          })
        : activeType === 'competition'
          ? blockCompetitionAdmin({
              eventAdminId: admin?.id,
              data: { status, name: admin?.name },
            })
          : activeType === 'guide'
            ? blockGuideAdmin({ eventAdminId: admin?.id, data: { status } })
            : blockAdmin({ profileAdminId: admin?.id, status }),
    );
    if (status === 'BLOCKED') {
      setControl(false);
      setToggle(false);
    }
    if (activeType === 'object') dispatch(getEvent({ id: admin?.eventId }));
    if (activeType === 'competition') dispatch(getCompetitionCabinet({ id: admin?.cabinetId }));
    if (activeType === 'guide') dispatch(getGuideCabinet({ id: admin?.cabinetId }));
  };

  useEffect(() => {
    dispatch(getProfileById({ id: admin?.adminId })).then(res =>
      setAvatar(res?.payload?.data?.avatar?.url),
    );
  }, []);

  return (
    <div className={className} style={style}>
      <div
        className="flex flex-row"
        style={{
          paddingBottom: px(12),
          gap: px(10),
        }}
      >
        <div
          style={{
            paddingTop: px(5),
          }}
        >
          {avatar ? (
            <img
              className="rounded-[50%]"
              src={`${process.env.REACT_APP_STATIC_URL}${avatar}`}
              alt="user"
              style={{
                height: px(60),
                width: px(60),
              }}
            />
          ) : (
            <div
              style={{ height: px(60), width: px(60) }}
              className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
            >
              <p className="font-light text-black" style={{ fontSize: px(40), lineHeight: px(43) }}>
                {admin?.admin?.name?.charAt(0)?.toUpperCase() || ''}
              </p>
            </div>
          )}
        </div>
        <div>
          <p
            className="... overflow-hidden text-ellipsis whitespace-nowrap font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              width: px(185),
            }}
          >
            {admin?.admin?.name}
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            ID: {addLeadingZeros(admin?.adminId)}
          </p>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            {admin?.name}
          </p>
        </div>
      </div>
      <div
        className="flex flex-row "
        style={{
          gap: px(10),
          marginBottom: px(28),
        }}
      >
        <CircleButton
          disabled
          onClick={handleControl}
          icon={{
            type: TypesIcon.ADMINCONTROL,
            viewBox: '0 0 28.47 28.47',
            width: px(28.47),
            height: px(28.47),
            fill:
              control && (admin.status === 'ACTIVE' || admin.eventAdminStatus === 'ACTIVE')
                ? 'white'
                : '#0a84fe',
          }}
          className={`${
            control && (admin.status === 'ACTIVE' || admin.eventAdminStatus === 'ACTIVE')
              ? 'white'
              : '#0a84fe'
          }`}
        />
        {admin.status === 'ACTIVE' || admin.eventAdminStatus === 'ACTIVE' ? (
          <CircleButton
            onClick={() => handleLock('BLOCKED')}
            icon={{
              type: TypesIcon.UNLOCK,
              viewBox: '0 0 21 28',
              width: px(21),
              height: px(28),
            }}
          />
        ) : (
          <CircleButton
            onClick={() => handleLock('ACTIVE')}
            icon={{
              type: TypesIcon.LOCKER,
              viewBox: '0 0 21 28',
              width: px(21),
              height: px(28),
              fill: `${
                admin.status === 'ACTIVE' || admin.eventAdminStatus === 'ACTIVE'
                  ? '#0a84fe'
                  : '#ffffff'
              }`,
            }}
            className="!bg-[#F81902]"
          />
        )}
        <CircleButton
          onClick={() => {
            deleteAdmin(true);
            getId(admin.id);
          }}
          icon={{
            type: TypesIcon.TRASH,
            viewBox: '0 0 20.5 25.2',
            width: px(20.5),
            height: px(25.2),
          }}
          className="bg-[#F81902]"
        />
      </div>
      {control && (admin.status === 'ACTIVE' || admin.eventAdminStatus === 'ACTIVE') && (
        <div>
          <p
            style={{
              paddingLeft: px(48),
              lineHeight: px(26),
              fontSize: px(20),
              fontWeight: 'normal',
            }}
          >
            Доступ к управлению
          </p>
          <p
            style={{
              marginBottom: px(24),
              paddingLeft: px(49),
              lineHeight: px(20),
              fontSize: px(15),
              fontWeight: 'normal',
            }}
          >
            Делегирование полномочий
          </p>
          <div
            className="flex items-center"
            style={{
              gap: px(17),
            }}
          >
            <div>
              <p
                style={{
                  lineHeight: px(26),
                  fontSize: px(20),
                  fontWeight: 'normal',
                }}
              >
                Все кабинеты
              </p>
              <p
                style={{
                  lineHeight: px(20),
                  fontSize: px(15),
                  fontWeight: 'normal',
                }}
              >
                Вносить изменения, управлять
              </p>
            </div>
            <div>
              <Switch toggle setToggle={setToggle} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
