import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { px } from '../../../constants/Px';
import { formatCreditCardNumber } from '../../../new-architecture/utils';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../Icon';

export const NotificationTypeInviteRequest = ({
  item,
  states: { setRegistrationCardProfileId, setRegistrationEventId, setRegistrationCardModalOpen },
}) => {
  const navigate = useNavigate();
  const notificationMessage = useMemo(
    () => ({
      cardActiveDate: item?.body?.cardActiveDate
        ? `Срок действия карты: ${item?.body?.cardActiveDate}`
        : '',
      cardName: item?.body?.cardName ? `Имя карты: ${item?.body?.cardName}` : '',
      cardStatus: item?.body?.cardStatus ? `Статус карты: ${item?.body?.cardStatus}%` : '',
      cardType: item?.body?.cardType
        ? `Вид карты: ${
            item?.body?.cardType === 'CLUB'
              ? 'Клубная'
              : item?.body?.cardType === 'DISCOUNT'
                ? 'Скидочная'
                : 'Льготная'
          }`
        : '',
      eventName: item?.body?.eventName ? `Объект: ${item?.body?.eventName}` : '',
      holderMessage: item?.body?.holderMessage ? `${item?.body?.holderMessage}` : '',
      holderName: item?.body?.holderName ? `Имя: ${item?.body?.holderName}` : '',
      holderSurname: item?.body?.holderSurname ? `Фамилия: ${item?.body?.holderSurname}` : '',
      holderPlastic: item?.body?.holderPlastic
        ? `Номер пластиковой карты:\n №${formatCreditCardNumber(item?.body?.holderPlastic)}`
        : '',
      holderProfileId: item?.body?.holderProfileId ? `ID: ${item?.body?.holderProfileId}` : '',
    }),
    [item],
  );

  return (
    <div className="flex w-full flex-col items-start justify-start" style={{ gap: px(20) }}>
      <p>ЗАПРОС КАРТЫ:</p>
      <div className="flex w-full flex-col items-start justify-start">
        <p className="w-full break-words">{notificationMessage?.eventName}</p>
        <p className="w-full break-words">{notificationMessage?.cardName}</p>
        <p className="w-full break-words">{notificationMessage?.cardType}</p>
        <p className="w-full break-words">{notificationMessage?.cardStatus}</p>
        <p className="w-full break-words">{notificationMessage?.cardActiveDate}</p>
      </div>
      <div className="flex w-full flex-col items-start justify-start">
        <h4 className="font-[400]">От кого запрос:</h4>
        <p>{notificationMessage?.holderName}</p>
        <p
          className="break-words text-[#0A84FE]"
          aria-hidden
          onClick={() => {
            setRegistrationCardProfileId(item?.body?.holderProfileId);
            setRegistrationEventId(item?.eventId);
            setRegistrationCardModalOpen(true);
          }}
        >
          {notificationMessage?.holderProfileId}
        </p>
      </div>
      <div className="flex w-full flex-col items-start justify-start">
        <h4 className="font-[400]">Основание:</h4>
        <p className="w-full break-words">{notificationMessage.holderPlastic}</p>
      </div>
      {item?.additionalLink && (
        <div className="flex w-full flex-col">
          <p className="w-full break-words" style={{ paddingBottom: px(10) }}>
            Фото
          </p>
          <div className="flex w-full flex-col items-start justify-start" style={{ gap: px(10) }}>
            {item.additionalLink
              .split(',')
              .map(link => link.trim())
              .map(link => (
                <a
                  key={link}
                  href={`${process.env.REACT_APP_STATIC_URL}${link}`}
                  target="_blank"
                  className="flex w-full items-start text-[#0A84FE]"
                  style={{ gap: px(10) }}
                  rel="noreferrer"
                >
                  <div style={{ width: px(18.018), height: px(23.655) }}>
                    <Icon
                      type={TypesIcon.DOCUMENTFINANCE}
                      width={px(18.018)}
                      height={px(23.655)}
                      viewBox="0 0 18.018 23.655"
                    />
                  </div>
                  <p
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      wordWrap: 'break-word',
                      WebkitLineClamp: 1,
                    }}
                  >
                    {link.replaceAll('/uploads/', '')}
                  </p>
                </a>
              ))}
          </div>
        </div>
      )}
      {notificationMessage?.holderMessage && (
        <div className="flex w-full flex-col items-start justify-start">
          <h4 className="font-[400]">Комментарий:</h4>
          <p className="w-full break-words">{notificationMessage?.holderMessage}</p>
        </div>
      )}
      <div className="flex w-full flex-col items-start justify-start">
        <h4 className="font-[400]">Статус запроса:</h4>
        <p
          aria-hidden
          className="cursor-pointer text-[#0A84FE] underline"
          onClick={() =>
            navigate(`/present-card`, {
              state: {
                cardId: item?.body?.cardId,
                profileId: item?.body?.holderProfileId,
                profileName: `${item?.body?.holderName || ''} ${item?.body?.holderSurname || ''}`,
                presentCardFrom: 'notifications',
              },
            })
          }
        >
          Принять запрос
        </p>
      </div>
    </div>
  );
};
export default NotificationTypeInviteRequest;
