import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSectors } from '../../actions/sector/thunks';
import { SectorModal, SortModal } from '../../components';
import { DraggableModal } from '../../components/draggable-modal';
import { Modal } from '../../components/portals';
import { sortValues } from '../../constants/Component/sector';
import { sectorModal } from '../../constants/Component/sector-modal/index';
import { px } from '../../constants/Px';
import { CircleButton, ScrollButtonsContainer } from '../../new-architecture/shared';
import { TypesIcon } from '../../types/TypesIcon';

export const SectorPage = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [showSort, setShowSort] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleClose = () => {
    show(false);
  };
  const [selectedOption, setSelectedOption] = useState('default');

  const handleOptionChange = event => setSelectedOption(event.target.value);

  const handleComponentClick = id => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  useEffect(() => {
    dispatch(getSectors({ id: 1 }));
  }, []);

  return (
    <div className="global-container ">
      <main>
        <div>
          <Modal isOpen={show} onClose={handleClose}>
            <SectorModal
              title="Пруд бобровый"
              dropdownTitle="Выберите сектор"
              selectedItems={selectedItems}
              data={sectorModal}
              handleComponentClick={handleComponentClick}
            />
            <ScrollButtonsContainer>
              <CircleButton
                icon={{
                  type: TypesIcon.ADDPLUS,
                  viewBox: '0 0 27 27',
                  width: px(27),
                  height: px(27),
                }}
              />
              {sectorModal.length > 0 && (
                <CircleButton
                  onClick={() => {
                    setShow(false);
                    setShowSort(true);
                  }}
                  icon={{
                    type: TypesIcon.FILTER,
                    viewBox: '0 0 25 24',
                    width: px(25),
                    height: px(24),
                    fill: '#0A84FE',
                  }}
                  className="text-[#0A84FE]"
                />
              )}
            </ScrollButtonsContainer>
          </Modal>
          <Modal isOpen={show} onClose={() => setShowSort(false)}>
            <SortModal
              title="Сортировка"
              data={sortValues}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          </Modal>
        </div>
      </main>
    </div>
  );
};
