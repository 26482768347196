import { TypesIcon } from '../../../types/TypesIcon';

export const sectorModal = [
  {
    id: 100,
    price: '12470',
    type: TypesIcon.SECTOR,
    sectorNumber: 1,
    discount: 40,
  },
  {
    id: 101,
    price: '12470',
    type: TypesIcon.SECTOR,
    sectorNumber: 2,
  },
  {
    id: 102,
    price: '10650',
    type: TypesIcon.BUILDING,
    sectorNumber: 3,
  },
  {
    id: 103,
    price: '12345',
    sectorNumber: 4,
    type: TypesIcon.SECTOR,
  },
  {
    id: 104,
    price: '12345',
    type: TypesIcon.BUILDING,
    sectorNumber: 5,
  },
  {
    id: 105,
    price: '12345',
    type: TypesIcon.LOGHOUSE,
    sectorNumber: 6,
  },
  {
    id: 106,
    price: '12345',
    sectorNumber: '25Л',
    type: TypesIcon.SECTOR,
  },
  {
    id: 107,
    price: '12345',
    type: TypesIcon.FREEZONE,
    sectorNumber: 12,
  },
  {
    id: 108,
    price: '12345',
    type: TypesIcon.LOGHOUSE,
    sectorNumber: 45,
    discount: 15,
  },
  {
    id: 109,
    price: '12345',
    sectorNumber: 1,
    type: TypesIcon.FREEZONE,
  },
  {
    id: 110,
    price: '12345',
    type: TypesIcon.BUILDING,
    sectorNumber: 12,
  },
  {
    id: 111,
    price: '12345',
    type: TypesIcon.SECTOR,
    sectorNumber: 45,
  },
  {
    id: 112,
    price: '12345',
    sectorNumber: 1,
    type: TypesIcon.SECTOR,
  },
  {
    id: 113,
    price: '12345',
    type: TypesIcon.BUILDING,
    sectorNumber: 12,
  },
  {
    id: 114,
    price: '12345',
    type: TypesIcon.SECTOR,
    sectorNumber: 3,
  },
  {
    id: 115,
    price: '12345',
    sectorNumber: 1,
    type: TypesIcon.SECTOR,
  },
  {
    id: 116,
    price: '12345',
    type: TypesIcon.BUILDING,
    sectorNumber: 12,
  },
  {
    id: 117,
    price: '12345',
    type: TypesIcon.SECTOR,
    sectorNumber: 3,
  },
  {
    id: 118,
    price: '12345',
    sectorNumber: 1,
    type: TypesIcon.SECTOR,
  },
  {
    id: 119,
    price: '12345',
    type: TypesIcon.BUILDING,
    sectorNumber: 12,
  },
  {
    id: 120,
    price: '12345',
    type: TypesIcon.SECTOR,
    sectorNumber: 3,
  },
  {
    id: 121,
    price: '12345',
    sectorNumber: 1,
    type: TypesIcon.SECTOR,
  },
  {
    id: 122,
    price: '12345',
    type: TypesIcon.BUILDING,
    sectorNumber: 12,
  },
  {
    id: 123,
    price: '12345',
    type: TypesIcon.SECTOR,
    sectorNumber: 3,
    discount: 13,
  },
  {
    id: 124,
    price: '12345',
    sectorNumber: 1,
    type: TypesIcon.SECTOR,
  },
];
