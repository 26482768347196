import React, { useState } from 'react';

import { sumThreeStrings } from '../constants/Function';
import { px } from '../constants/Px';

export const TariffInfo = ({
  openPicker,
  header,
  pickerDate,
  drumData,
  priceDiscountAction,
  type,
  activeIds,
}) => {
  const [checkboxes, setCheckboxes] = useState({
    free: false,
    noLimit: false,
    limit: false,
    assistant: false,
  });

  const handleInputChange = (key, value) => {
    setCheckboxes(prevInputs => ({
      ...prevInputs,
      [key]: value,
    }));
  };
  const arrayTitles = [
    {
      id: drumData?.[13]?.pickerId,
      title: drumData?.[13]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.rentPriceFirstNumber,
        pickerDate?.rentPriceSecondNumber,
        pickerDate?.rentPriceThirdNumber,
      ),

      key: drumData?.[13]?.key,
    },
    {
      id: drumData?.[2]?.pickerId,
      title: drumData?.[2]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.freeFirstNumber,
        pickerDate?.freeSecondNumber,
        pickerDate?.freeThirdNumber,
      ),

      key: drumData?.[2]?.key,
    },
    {
      id: drumData?.[3]?.pickerId,
      title: drumData?.[3]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.noLimitFirstNumber,
        pickerDate?.noLimitSecondNumber,
        pickerDate?.noLimitThirdNumber,
      ),
      key: drumData?.[3]?.key,
    },
    {
      id: drumData?.[4]?.pickerId,
      title: drumData?.[4]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.limitFirstNumber,
        pickerDate?.limitSecondNumber,
        pickerDate?.limitThirdNumber,
      ),
      key: drumData?.[4]?.key,
    },
    {
      id: drumData?.[5]?.pickerId,
      title: drumData?.[5]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.assistantFirstNumber,
        pickerDate?.assistantSecondNumber,
        pickerDate?.assistantThirdNumber,
      ),
      key: drumData?.[5]?.key,
    },
    {
      id: drumData?.[6]?.pickerId,
      title: drumData?.[6]?.title,
      stringNumber: `${pickerDate?.weekendExtraCharge}`,
      key: drumData?.[6]?.key,
    },
    {
      id: drumData?.[14]?.pickerId,
      title: drumData?.[14]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.depositFirstNumber,
        pickerDate?.depositSecondNumber,
        pickerDate?.depositThirdNumber,
      ),
      key: drumData?.[14]?.key,
    },
    {
      id: drumData?.[15]?.pickerId,
      title: drumData?.[15]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.damageFirstNumber,
        pickerDate?.damageSecondNumber,
        pickerDate?.damageThirdNumber,
      ),
      key: drumData?.[15]?.key,
    },
    {
      id: drumData?.[7]?.pickerId,
      title: drumData?.[7]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.checkoutPenaltyFirstNumber,
        pickerDate?.checkoutPenaltySecondNumber,
        pickerDate?.checkoutPenaltyThirdNumber,
      ),
      key: drumData?.[7]?.key,
    },
    {
      id: drumData?.[16]?.pickerId,
      title: drumData?.[16]?.title,
      stringNumber: `${pickerDate?.discountFree}`,
      key: drumData?.[16]?.key,
    },
    {
      id: drumData?.[17]?.pickerId,
      title: drumData?.[17]?.title,
      stringNumber: `${pickerDate?.discountNoLimit}`,
      key: drumData?.[17]?.key,
    },
    {
      id: drumData?.[18]?.pickerId,
      title: drumData?.[18]?.title,
      stringNumber: `${pickerDate?.discountLimit}`,
      key: drumData?.[18]?.key,
    },
    {
      id: drumData?.[19]?.pickerId,
      title: drumData?.[19]?.title,
      stringNumber: `${pickerDate?.discountAssistant}`,
      key: drumData?.[19]?.key,
    },
    {
      id: drumData?.[49]?.pickerId,
      title: drumData?.[49]?.title,
      stringNumber: `${pickerDate?.restDiscount}`,
      key: drumData?.[49]?.key,
    },
    {
      id: drumData?.[50]?.pickerId,
      title: drumData?.[50]?.title,
      stringNumber: `${pickerDate?.servicesDiscount}`,
      key: drumData?.[50]?.key,
    },
    {
      id: drumData?.[51]?.pickerId,
      title: drumData?.[51]?.title,
      stringNumber: `${pickerDate?.fishingActionFree}`,
      key: drumData?.[51]?.key,
    },
    {
      id: drumData?.[52]?.pickerId,
      title: drumData?.[52]?.title,
      stringNumber: `${pickerDate?.fishingActionNoLimit}`,
      key: drumData?.[52]?.key,
    },
    {
      id: drumData?.[53]?.pickerId,
      title: drumData?.[53]?.title,
      stringNumber: `${pickerDate?.fishingActionLimit}`,
      key: drumData?.[53]?.key,
    },
    {
      id: drumData?.[54]?.pickerId,
      title: drumData?.[54]?.title,
      stringNumber: `${pickerDate?.fishingActionAssistant}`,
      key: drumData?.[54]?.key,
    },
  ].filter(item => activeIds?.includes(item?.id));
  const arrayOnShore = [
    {
      id: drumData?.[9]?.pickerId,
      title: drumData?.[9]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.ashoreCostFirstNumber,
        pickerDate?.ashoreCostSecondNumber,
        pickerDate?.ashoreCostThirdNumber,
      ),
      key: drumData?.[9]?.key,
    },
    {
      id: drumData?.[10]?.pickerId,
      title: drumData?.[10]?.title,
      stringNumber: `${pickerDate?.ashoreWeekendExtraCharge}`,
      key: drumData?.[10]?.key,
    },
    {
      id: drumData?.[11]?.pickerId,
      title: drumData?.[11]?.title,
      stringNumber: sumThreeStrings(
        pickerDate?.ashoreCheckoutPenaltyFirstNumber,
        pickerDate?.ashoreCheckoutPenaltySecondNumber,
        pickerDate?.ashoreCheckoutPenaltyThirdNumber,
      ),
      key: drumData?.[11]?.key,
    },
    {
      id: drumData?.[20]?.pickerId,
      title: drumData?.[20]?.title,
      stringNumber: `${pickerDate?.discountAshore}`,
      key: drumData?.[20]?.key,
    },
    {
      id: drumData?.[55]?.pickerId,
      title: drumData?.[55]?.title,
      stringNumber: `${pickerDate?.fishingActionAshore}`,
      key: drumData?.[55]?.key,
    },
  ].filter(item => activeIds?.includes(item?.id));

  return (
    <div>
      <p
        style={{
          fontSize: px(20),
          lineHeight: px(26),
          paddingBottom: px(18),
        }}
        className="font-normal"
      >
        {header}
      </p>
      <div
        style={{
          gap: px(14),
        }}
        className="flex  flex-col justify-center"
      >
        {arrayTitles?.map(item => (
          <div key={item.id} className="flex">
            <div className="flex w-full  items-center justify-between ">
              <div
                style={{ width: px(item?.key && 169), fontSize: px(15), lineHeight: px(20) }}
                className="font-normal"
              >
                {item.title}
              </div>
              <div
                style={{
                  width: px(item?.key && 121),
                }}
                className={`flex items-center ${item?.key && 'justify-between'} `}
              >
                {item?.key && (
                  <div
                    value={checkboxes?.[item?.key]}
                    onClick={() => handleInputChange(item?.key, !checkboxes?.[item?.key])}
                    onKeyDown={() => handleInputChange(item?.key, !checkboxes?.[item?.key])}
                    className={` cursor-pointer ${
                      checkboxes?.[item?.key]
                        ? 'bg-[#0a84fe]'
                        : 'border-[1px] border-solid border-[#000] bg-white'
                    }`}
                    style={{
                      width: px(17),
                      height: px(17),
                    }}
                  />
                )}
                <div
                  // onClick={checkboxes?.[item?.key] ? openPicker(item?.id) : () => {}}
                  // onKeyDown={checkboxes?.[item?.key] ? openPicker(item?.id) : () => {}}
                  onClick={() => {
                    openPicker(item?.id);
                  }}
                  onKeyDown={() => {
                    openPicker(item?.id);
                  }}
                  style={{ fontSize: px(20), lineHeight: px(26) }}
                  className="font-normal"
                >
                  {item.stringNumber}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          style={{
            paddingTop: px(3),
          }}
        >
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal opacity-50"
          >
            Предельное время позднего выезда
          </p>
          <p
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
            className="font-normal opacity-50"
          >
            Период
          </p>
        </div>
        <div>
          <div
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingBottom: px(3),
            }}
            className="inline-block border-b-[1px] border-solid border-black border-opacity-30 font-normal"
            onClick={() => {
              openPicker(8);
            }}
            onKeyDown={() => {
              openPicker(8);
            }}
          >
            {pickerDate?.checkoutTimeLimit}
          </div>
        </div>
      </div>
      {type === 'fishing' &&
        (priceDiscountAction === 'price' || priceDiscountAction === 'discount') && (
          <>
            <p
              style={{
                fontSize: px(20),
                lineHeight: px(26),
                paddingTop: px(40),
                paddingBottom: px(17),
              }}
              className="font-normal"
            >
              {priceDiscountAction === 'price'
                ? 'Объект на берегу. Наценка'
                : 'Скидка на Объект на берегу'}
            </p>
            <div
              style={{
                gap: px(14),
              }}
              className="flex  flex-col justify-center"
            >
              {arrayOnShore?.map(item => (
                <div key={item.key} className="flex">
                  <div className="flex w-full  items-center justify-between ">
                    <div
                      style={{ width: px(item?.key && 169), fontSize: px(15), lineHeight: px(20) }}
                      className="font-normal"
                    >
                      {item.title}
                    </div>
                    <div
                      style={{
                        width: px(item?.key && 121),
                      }}
                      className={`flex items-center ${item?.key && 'justify-between'} `}
                    >
                      {item?.key && (
                        <div
                          value={checkboxes?.[item?.key]}
                          onClick={() => handleInputChange(item?.key, !checkboxes?.[item?.key])}
                          onKeyDown={() => handleInputChange(item?.key, !checkboxes?.[item?.key])}
                          className={` cursor-pointer ${
                            checkboxes?.[item?.key]
                              ? 'bg-[#0a84fe]'
                              : 'border-[1px] border-solid border-[#000] bg-white'
                          }`}
                          style={{
                            width: px(17),
                            height: px(17),
                          }}
                        />
                      )}
                      <div
                        onClick={() => {
                          openPicker(item?.id);
                        }}
                        onKeyDown={() => {
                          openPicker(item?.id);
                        }}
                        style={{ fontSize: px(20), lineHeight: px(26) }}
                        className="font-normal"
                      >
                        {item.stringNumber}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {priceDiscountAction === 'price' && (
              <>
                <div
                  style={{
                    paddingTop: px(18),
                    paddingBottom: px(13),
                  }}
                >
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                    }}
                    className="font-normal opacity-50"
                  >
                    Предельное время позднего выезда
                  </p>
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                    }}
                    className="font-normal opacity-50"
                  >
                    Период
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                      paddingBottom: px(5),
                    }}
                    className="inline-block border-b-[1px] border-solid border-black border-opacity-30 font-normal"
                    onClick={() => {
                      openPicker(12);
                    }}
                    onKeyDown={() => {
                      openPicker(12);
                    }}
                  >
                    {pickerDate?.ashoreСheckoutTimeLimit}
                  </div>
                </div>
              </>
            )}
          </>
        )}
    </div>
  );
};

export default TariffInfo;
