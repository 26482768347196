import { createSelector } from '@reduxjs/toolkit';

export const selectRoot = ({ competition }) => competition;
export const competitionsSelector = createSelector(
  selectRoot,
  competition => competition?.competitions,
);
export const competitionSelector = createSelector(
  selectRoot,
  competition => competition?.competition,
);
export const competitionLogoSelector = createSelector(selectRoot, competition => competition?.logo);
export const competitionGallerySelector = createSelector(
  selectRoot,
  competition => competition?.gallery,
);
export const listOfCompetitionsSelector = createSelector(
  selectRoot,
  competition => competition?.listOfCompetitions,
);

export const competitionEventsSelector = createSelector(
  selectRoot,
  competition => competition?.competitionEvents,
);
export const competitionSchemasSelector = createSelector(
  selectRoot,
  competition => competition?.schema,
);
export const competitionProtocolsSelector = createSelector(
  selectRoot,
  competition => competition?.protocol,
);
export const competitionNewsSelector = createSelector(selectRoot, competition => competition?.news);
