import { request } from '@/helpers/api/request';

export const updateProfile = async (data) => {

  const response = await request({
    url: `profile/${data.id}`,
    method: 'PUT',
    data
  });

  return response;
};
