/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-shadow */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useMemo, useState } from 'react';

import { ObjectDescriptionTextarea } from '../../../../../../../../../components';
import { StyledInput } from '../../../../../../../../../components/styled-input';
import {
  alphabet,
  assistantData,
  sectorData,
} from '../../../../../../../../../constants/Component/date-picker';
import { Button, DynamicImageSlider, Picker } from '../../../../../../../../shared';
import { CheckboxFieldGroup } from '../../../../../../../../shared/checkbox-field-group';
import { capitalizeFirstLetter, px } from '../../../../../../../../utils';
import { useProfile } from '../../../../../../../hooks';
import { numberStringToArray } from '../../../../../../helpers/sector.helpers';
import { Division } from '../division';

export const ServiceFields = ({ error }) => {
  const {
    data: { sectorFields },
    setData: setProfile,
  } = useProfile();

  const [showNumberPicker, setShowNumberPicker] = useState(false);

  const setSectorFields = payload =>
    setProfile({
      sectorFields: {
        ...sectorFields,
        ...payload,
      },
    });

  const { type, number, photos, objectDescription, name, services } = sectorFields || {};

  const numberArray = useMemo(() => numberStringToArray(number), [number]);

  useEffect(() => {
    if (services?.find?.(i => i?.startsWith('lure')))
      setSectorFields({ number: services?.find?.(i => i?.startsWith('lure'))?.[4] || '0' });
  }, [JSON.stringify(sectorFields)]);

  const changeHandler = e => {
    e === undefined ? setSectorFields({ services: e }) : setSectorFields({ services: [e] });
  };

  return (
    <Division type={type} open>
      <CheckboxFieldGroup
        id="services"
        title="Услуги"
        error={error === 'services'}
        items={[
          { label: 'Прокат лодки', value: 'boat' },
          { label: 'Со своей лодкой', value: 'myBoat' },
          { label: 'Аренда кораблика', value: 'dinghy' },
          { label: 'Со своим корабликом', value: 'myDinghy' },
          { label: 'Аренда мата для рыбы', value: 'fishMat' },
          { label: 'Аренда удилища', value: 'fishingRod' },
          { label: 'Аренда спиннинга', value: 'spinning' },
          { label: 'Аренда подсака', value: 'landingNet' },
          { label: 'Садок', value: 'cage' },
          { label: 'Аренда маркер. поплавка', value: 'float' },
          { label: 'Инструктор', value: 'instructor' },
          { label: 'Аренда мангала', value: 'grill' },
          { label: 'Прикормка № 1', value: 'lure1' },
          { label: 'Прикормка № 2', value: 'lure2' },
          { label: 'Прикормка № 3', value: 'lure3' },
          { label: 'Прикормка № 4', value: 'lure4' },
          { label: 'Прикормка № 5', value: 'lure5' },
          { label: 'Прикормка № 6', value: 'lure6' },
          { label: 'Прикормка № 7', value: 'lure7' },
        ]}
        value={services?.[0]}
        onChange={services => changeHandler(services)}
      />
      <div className="w-full">
        <StyledInput
          isBorder
          onChange={({ target: { value: name } }) => setSectorFields({ name })}
          title="Название услуги"
          placeholder="XXXXXXXX"
          value={name}
        />
      </div>
      <div className="flex flex-row items-center" style={{ marginTop: px(-25) }}>
        <p
          className="font-normal"
          id="number"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            marginRight: px(64),
            color: error === 'number' ? 'red' : 'inherit',
          }}
        >
          Номер
        </p>
        <p
          aria-hidden
          onClick={() => {
            setShowNumberPicker(true);
          }}
          className="flex cursor-pointer justify-center font-light text-[#0A84FE]"
          style={{ minWidth: px(44), fontSize: px(20), lineHeight: px(26) }}
        >
          {number || 0}
        </p>
        <Picker
          singleItem
          title="Номер сектора"
          show={showNumberPicker}
          onClose={() => setShowNumberPicker(false)}
          data={{
            sectorShoreSide: sectorData?.sectorSide,
            sectorShoreNumber: assistantData?.assistant,
            sectorShoreLetter: alphabet?.letters,
          }}
          onChange={v =>
            setSectorFields({
              number: [v.sectorShoreSide, v.sectorShoreNumber, v.sectorShoreLetter]
                ?.filter(item => item !== '-')
                ?.join(''),
            })
          }
          value={{
            sectorShoreSide: numberArray?.[0],
            sectorShoreNumber: numberArray?.[1],
            sectorShoreLetter: numberArray?.[2],
          }}
          button
          isLine
        />
      </div>

      <div className="w-full">
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Фото. Видео
        </p>
        <div style={{ marginRight: px(-15), marginLeft: px(-15) }}>
          <DynamicImageSlider
            items={photos}
            onChange={photos =>
              setSectorFields({ photos: photos?.map?.(i => ({ src: i?.src, first: !!i?.first })) })
            }
          />
        </div>
      </div>

      <div className="w-full">
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Описание услуги
        </p>

        <ObjectDescriptionTextarea
          objectStyles={{
            height: 194,
            placeholder: 'Описание: с заглавной,  ….',
            paddingTop: 4,
            paddingLeft: 18,
            paddingRight: 18,
            bottom: 20,
            textareaHeight: 180,
          }}
          value={objectDescription}
          setValue={(_, objectDescription) =>
            setSectorFields({ objectDescription: capitalizeFirstLetter(objectDescription) })
          }
        />
        <div className="flex flex-row items-center justify-between">
          <Button
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#000"
            bgColor="#fff"
            onClick={() => setSectorFields({ objectDescription: '' })}
          >
            Очистить
          </Button>
          <Button
            style={{ width: px(141) }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#fff"
            disabled={!objectDescription}
            onClick={
              () => {}
              // setSaveDescription({ ...saveDescription, saveFishingDescription: true })
            }
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Division>
  );
};

export default ServiceFields;
