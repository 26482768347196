import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { priceModalSectors, tarifCardValues } from '../constants/Component/price-modal';
import { getHeader } from '../constants/Function';
import { px } from '../constants/Px';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { Dropdown } from './dropdown';
import { ObjectPriceDiscountAction } from './object-price-discount-action';
import { ObjectTypes } from './object-types';
import { Period } from './period';
import { Sector } from './sector';
import { TariffCard } from './tariff-card';
import { TariffInfo } from './tariff-info';

export const ModalPrice = ({
  dropdownTitle,
  onClick,
  buttonTitle,
  openPicker,
  selectedOption,
  handleOptionChange,
  pickerDate,
  drumData,
  type,
  handleTypeChange,
  priceDiscountAction,
  handlePriceDiscountActionChange,
  groups,
  handleOpenGroup,
  handleCloseGroup,
  selectedGroupId,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div style={{ paddingBottom: px(80) }}>
      <div
        style={{
          overflowY: 'auto',
          height: '100vh',
          maxHeight: '-webkit-fill-available',
          paddingRight: px(15),
          paddingLeft: px(15),
          paddingBottom: px(80),
        }}
      >
        <div
          style={{
            marginBottom: px(20),
          }}
        >
          <ObjectTypes type={type} handleTypeChange={handleTypeChange} />
        </div>
        <div
          style={{
            marginBottom: px(20),
          }}
        >
          <ObjectPriceDiscountAction
            priceDiscountAction={priceDiscountAction}
            handlePriceDiscountActionChange={handlePriceDiscountActionChange}
          />
        </div>
        {dropdownTitle && <Dropdown title={dropdownTitle} />}
        {groups?.map(element => (
          <div key={element?.id}>
            <p
              style={{ fontSize: px(32), lineHeight: px(43), paddingTop: px(20) }}
              className="font-light"
            >
              {element?.name}
            </p>
            <div
              style={{
                gap: px(10),
                paddingTop: px(10),
                paddingBottom: px(20),
              }}
              className="flex flex-wrap"
            >
              {/* {priceModalSectors?.map(item => (
                <Sector
                  key={item?.id}
                  sectorNumber={item?.sectorNumber}
                  discount={item?.discount}
                  type={item?.type}
                  price={item?.price}
                />
              ))} */}
            </div>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-wrap"
            />
            <div className="flex items-center justify-between">
              <p style={{ fontSize: px(32), lineHeight: px(43) }} className="font-light">
                {priceDiscountAction === 'price'
                  ? `Тарифы`
                  : priceDiscountAction === 'discount'
                    ? 'Скидка'
                    : 'Акция'}
              </p>
              <div
                style={{ fontSize: px(15), lineHeight: px(20) }}
                className="font-normal text-[#0A84FE]"
                onClick={
                  element?.id === selectedGroupId
                    ? () => handleCloseGroup()
                    : () => handleOpenGroup(element?.id)
                }
                onKeyDown={
                  element?.id === selectedGroupId
                    ? () => handleCloseGroup()
                    : () => handleOpenGroup(element?.id)
                }
              >
                {element?.id === selectedGroupId ? 'Cкрыть' : 'Показать'}
              </div>
            </div>
            <p
              style={{ fontSize: px(15), lineHeight: px(20) }}
              className=" whitespace-pre-wrap font-normal"
            >
              {`Выберите периоды. Установите\nцены и условия:`}
            </p>
            {element?.id === selectedGroupId && (
              <>
                <div
                  style={{
                    paddingBottom: px(20),
                    paddingTop: px(20),
                  }}
                  className="flex items-center justify-between"
                >
                  <div />
                  <div
                    style={{
                      paddingLeft: px(100),
                      paddingRight: px(69),
                    }}
                  >
                    <TariffСard
                      price="0"
                      isSunActive={
                        tarifCardValues.find(el => el.value === selectedOption)?.isSunActive
                      }
                      time={pickerDate?.timeStart}
                      endTime={pickerDate.timeEnd}
                      typeTime={tarifCardValues.find(el => el.value === selectedOption)?.typeTime}
                    />
                  </div>
                  <Checkbox
                    margin
                    checked
                    onChange={e => handleOptionChange(e)}
                    style={{
                      margin: '0px',
                    }}
                  />
                </div>
                <div
                  className="flex flex-col"
                  style={{
                    gap: px(10),
                  }}
                >
                  <div>
                    <p style={{ fontSize: px(20), lineHeight: px(26) }} className="font-normal">
                      Период
                    </p>
                    <p style={{ fontSize: px(15), lineHeight: px(20) }} className="font-normal">
                      Указать время работы
                    </p>
                  </div>
                  <Period
                    onlyTime
                    openPicker={openPicker}
                    pickerDate={pickerDate}
                    type={type}
                    paddingBottom={13}
                    priceDiscountAction={priceDiscountAction}
                  />
                </div>
                <div
                  style={{
                    paddingTop: px(40),
                    paddingBottom: px(40),
                  }}
                >
                  <TariffInfo
                    header={getHeader(type, priceDiscountAction)?.title}
                    activeIds={getHeader(type, priceDiscountAction)?.activeIds}
                    type={type}
                    priceDiscountAction={priceDiscountAction}
                    openPicker={openPicker}
                    pickerDate={pickerDate}
                    drumData={drumData}
                  />
                </div>
                <div style={{ gap: px(20) }} className="flex flex-col">
                  {tarifCardValues?.map(item => (
                    <div key={item?.id} className="flex items-center">
                      <div />
                      <div
                        style={{
                          paddingLeft: px(100),
                          paddingRight: px(69),
                        }}
                      >
                        <TariffCard
                          price={item?.price}
                          time={item?.time}
                          endTime={item?.endTime}
                          typeTime={item?.typeTime}
                          isSunActive={item?.isSunActive}
                        />
                      </div>
                      <Checkbox
                        margin
                        checked={selectedOption === item?.value}
                        value={item?.value}
                        onChange={e => handleOptionChange(e)}
                        style={{
                          margin: '0px',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}

        {buttonTitle && (
          <div>
            <Button
              className="w-full border-[1px] border-solid border-[#dddddd]"
              marginTop={10}
              height={60}
              borderRadius={0}
              onClick={onClick}
            >
              {buttonTitle}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalPrice;
