/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

import { DatePicker } from '../../../../../../../components';
import {
  blockTheAccountPageTimeData,
  getDaysInMonth,
  selectionsDate,
  timeDate,
} from '../../../../../../../constants/Component';
import { px } from '../../../../../../utils';
import { useProfile } from '../../../../../hooks';

export const PromotionPeriod = ({ start, end, discount, object }) => {
  /* const [openToggle, setOpenToggle] = useState(false); */
  const [id, setId] = useState();
  const [show, setShow] = useState(false);

  const date = new Date();
  const hour = date?.getHours();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  const getMonth = month =>
    month === '01'
      ? 'Январь'
      : month === '02'
        ? 'Февраль'
        : month === '03'
          ? 'Март'
          : month === '04'
            ? 'Апрель'
            : month === '05'
              ? 'Май'
              : month === '06'
                ? 'Июнь'
                : month === '07'
                  ? 'Июль'
                  : month === '08'
                    ? 'Август'
                    : month === '09'
                      ? 'Сентябрь'
                      : month === '10'
                        ? 'Октябрь'
                        : month === '11'
                          ? 'Ноябрь'
                          : 'Декабрь';

  const getDay = day => (day?.length === 1 ? `0${day}` : day);

  const getMonthBack = month =>
    month === 'Январь'
      ? '01'
      : month === 'Февраль'
        ? '02'
        : month === 'Март'
          ? '03'
          : month === 'Апрель'
            ? '04'
            : month === 'Май'
              ? '05'
              : month === 'Июнь'
                ? '06'
                : month === 'Июль'
                  ? '07'
                  : month === 'Август'
                    ? '08'
                    : month === 'Сентябрь'
                      ? '09'
                      : month === 'Октябрь'
                        ? '10'
                        : month === 'Ноябрь'
                          ? '11'
                          : '12';

  const {
    data: { currentPromotionGroup, currentDiscountGroup },
    setData: setProfile,
  } = useProfile();

  const setCurrentPromotionGroup = payload => {
    setProfile({
      currentPromotionGroup: {
        ...currentPromotionGroup,
        ...payload,
      },
    });
  };

  const setCurrentDiscountGroup = payload => {
    setProfile({
      currentDiscountGroup: {
        ...currentDiscountGroup,
        ...payload,
      },
    });
  };

  /* const { periodStart, periodEnd } = currentPromotionGroup || {}; */

  const [time, setTime] = useState({ hour: start?.slice(11, 16) || `${hour}:00` });
  const [startDate, setStartDate] = useState({
    year: start?.slice(0, 4) || `${currentYear}`,
    month:
      (start && getMonth(start?.slice(5, 7))) || selectionsDate?.month[currentMonth] || 'Январь',
    day: start?.slice(8, 10) || `${currentDay}` || '1',
  });

  const [endTime, setEndTime] = useState({ hour: end?.slice(11, 16) || `${hour}:00` });
  const [endDate, setEndDate] = useState({
    year: end?.slice(0, 4) || `${currentYear}`,
    month: (end && getMonth(end?.slice(5, 7))) || selectionsDate?.month[currentMonth] || 'Январь',
    day: end?.slice(8, 10) || `${currentDay}` || '1',
  });

  const openPicker = value => {
    setId(value);
    setShow(true);
  };

  useEffect(
    () =>
      discount
        ? object
          ? setCurrentDiscountGroup({
              objectPeriodStart: `${startDate.year}-${getMonthBack(startDate.month)}-${getDay(
                startDate.day,
              )}T${time.hour}:00.085Z`,
              objectperiodEnd: `${endDate.year}-${getMonthBack(endDate.month)}-${getDay(
                endDate.day,
              )}T${endTime.hour}:00.085Z`,
            })
          : setCurrentDiscountGroup({
              periodStart: `${startDate.year}-${getMonthBack(startDate.month)}-${getDay(
                startDate.day,
              )}T${time.hour}:00.085Z`,
              periodEnd: `${endDate.year}-${getMonthBack(endDate.month)}-${getDay(endDate.day)}T${
                endTime.hour
              }:00.085Z`,
            })
        : object
          ? setCurrentPromotionGroup({
              objectPeriodStart: `${startDate.year}-${getMonthBack(startDate.month)}-${getDay(
                startDate.day,
              )}T${time.hour}:00.085Z`,
              objectperiodEnd: `${endDate.year}-${getMonthBack(endDate.month)}-${getDay(
                endDate.day,
              )}T${endTime.hour}:00.085Z`,
            })
          : setCurrentPromotionGroup({
              periodStart: `${startDate.year}-${getMonthBack(startDate.month)}-${getDay(
                startDate.day,
              )}T${time.hour}:00.085Z`,
              periodEnd: `${endDate.year}-${getMonthBack(endDate.month)}-${getDay(endDate.day)}T${
                endTime.hour
              }:00.085Z`,
            }),
    [time, startDate, endTime, endDate],
  );

  return (
    <>
      <DatePicker
        isLine
        singleItem={id === 1 || id === 3}
        title="Календарь"
        setShow={setShow}
        show={show}
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        isDate={id === 2 || (id === 4 && true)}
        data={
          id === 2 || id === 4
            ? {
                ...selectionsDate,
                day: selectionsDate?.day?.slice(
                  0,
                  1 +
                    getDaysInMonth(
                      startDate?.year,
                      selectionsDate?.month?.indexOf(startDate?.month) + 1,
                    ),
                ),
              }
            : timeDate
        }
        setPickerValue={
          id === 1
            ? setTime
            : id === 2
              ? setStartDate
              : id === 3
                ? setEndTime
                : id === 4
                  ? setEndDate
                  : () => {}
        }
        pickerValue={
          id === 1 ? time : id === 2 ? startDate : id === 3 ? endTime : id === 4 ? endDate : ''
        }
        buttonTitle="Применить"
      />

      <div
        style={{
          gap: px(14),
        }}
        className="flex"
      >
        {blockTheAccountPageTimeData.map(item => (
          <div key={item?.id} className="flex flex-col">
            <p
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="font-normal opacity-50"
            >
              {item?.title}
            </p>

            <div
              style={{
                gap: px(10),
              }}
              className="flex flex-row"
            >
              {item?.data?.map(el => (
                <div key={el?.id}>
                  <p
                    style={{
                      fontSize: px(15),
                      lineHeight: px(20),
                      paddingBottom: px(20),
                    }}
                    className="font-normal  opacity-50"
                  >
                    {el?.subTitle}
                  </p>
                  <p
                    style={{
                      fontSize: px(20),
                      lineHeight: px(26),
                    }}
                    className="font-normal text-black"
                    onClick={() => openPicker(el?.id)}
                    aria-hidden
                  >
                    {el?.id === 1
                      ? `${time?.hour}`
                      : el?.id === 3
                        ? `${endTime?.hour}`
                        : el?.id === 2
                          ? `${
                              +startDate?.day.length < 2 ? `0${startDate?.day}` : startDate?.day
                            }.${
                              selectionsDate?.month?.indexOf(startDate?.month) + 1 < 10
                                ? `0${selectionsDate?.month?.indexOf(startDate?.month) + 1}`
                                : selectionsDate?.month?.indexOf(startDate?.month) + 1
                            }.${String(startDate?.year).slice(2, 4)}`
                          : `${+endDate?.day.length < 2 ? `0${endDate?.day}` : endDate?.day}.${
                              selectionsDate?.month?.indexOf(endDate?.month) + 1 < 10
                                ? `0${selectionsDate?.month?.indexOf(endDate?.month) + 1}`
                                : selectionsDate?.month?.indexOf(endDate?.month) + 1
                            }.${String(endDate?.year).slice(2, 4)}`}
                  </p>
                  <hr
                    className="text-black opacity-30"
                    style={{
                      marginTop: px(5),
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PromotionPeriod;
