import React, { useContext, useEffect } from 'react';

import { SearchMenuFilterBlockItem } from './search-menu-filter-block-item';
import { StateContext } from './state-provider';

export const SearchMenuFilterBlockFiltersService = ({
  filter,
  selectedOptionsTitle,
  setSelectedOptionsTitle,
  selectedObjectFiltersRest,
  setSelectedObjectFiltersRest,
  save,
  setSave,
  setScrollIndex,
  scrollIndex,
  show,
  setShow,
  date,
  setDataPickerId,
}) => {
  const { selectedOptions, setSelectedOptions } = useContext(StateContext);

  const { scrollRef } = useContext(StateContext);

  const toggleOption = (filter, option, title) => {
    setSave(false);
    setSelectedOptionsTitle(prev => [...prev, title]);

    const newOptions = selectedOptions?.includes(option)
      ? selectedOptions?.filter(item => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(newOptions);

    if (selectedObjectFiltersRest[filter]?.includes(option)) {
      const newOpt = selectedObjectFiltersRest[filter].filter(item => item !== option);
      setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [filter]: newOpt });
    } else
      setSelectedObjectFiltersRest({
        ...selectedObjectFiltersRest,
        [filter]: [...selectedObjectFiltersRest[filter], option],
      });
  };

  const cleanOptions = (e, filterTitle, value) => {
    const newArr = [];
    const newArrCommon = [];
    const arr = [];
    e?.map(el => {
      arr.push(el.value);
    });
    const commonArray = [].concat(
      selectedObjectFiltersRest[1],
      selectedObjectFiltersRest[4],
      selectedObjectFiltersRest[7],
      selectedObjectFiltersRest[9],
      selectedObjectFiltersRest[10],
      selectedObjectFiltersRest[11],
    );
    selectedObjectFiltersRest[value]?.map(elem => {
      !arr.includes(elem) && newArr.push(elem);
    });

    setSelectedObjectFiltersRest({ ...selectedObjectFiltersRest, [value]: newArr });

    commonArray.map(elem => {
      !arr.includes(elem) && newArrCommon.push(elem);
    });
    setSelectedOptions(newArrCommon);

    setSelectedOptionsTitle(selectedOptionsTitle.filter(item => item !== filterTitle));
  };

  useEffect(() => {
    if (save && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [save, scrollRef.current]);

  useEffect(() => {
    const emptyArrays = Object.keys(selectedObjectFiltersRest).filter(
      key => selectedObjectFiltersRest[key].length === 0 || !selectedObjectFiltersRest[key][0],
    );

    setScrollIndex(emptyArrays.map(str => Number.parseInt(str, 10)));
  }, [selectedObjectFiltersRest]);

  return (
    <div className="search-menu-filter-block">
      <div className="search-menu-filter-block-top">
        <h4
          style={{
            color: save && selectedObjectFiltersRest[1].length === 0 && 'red',
          }}
          ref={filter.value === Math.min(...scrollIndex) ? scrollRef : null}
        >
          {filter.title}
        </h4>
        {selectedOptionsTitle?.includes(filter.title) && (
          <div
            className="search-menu-filter-block-reset"
            onClick={() => cleanOptions(filter.options, filter.title, filter.value)}
          >
            сбросить
          </div>
        )}
      </div>
      <div className="search-menu-filter-block-bottom">
        {filter.options.map((option, key) => (
          <SearchMenuFilterBlockItem
            key={`${option.label}-${key}`}
            option={option}
            isSelected={selectedOptions?.includes(option?.value)}
            toggleOption={() => toggleOption(filter?.value, option?.value, filter?.title)}
            save={save}
            setSave={setSave}
            show={show}
            setShow={setShow}
            date={date}
            setDataPickerId={setDataPickerId}
          />
        ))}
      </div>
    </div>
  );
};
