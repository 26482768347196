export const INITIAL_PROFILE_FIELDS = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  country: '',
  town: '',
  passportDetails: {
    passportNumber: '',
    issuedBy: '',
    issueDate: '',
    divisionCode: '',
    image: undefined,
  },
  documents: [],
  departures: [],
  recommends: [],
  sportAchivments: {
    team: '',
    club: '',
    image: undefined,
  },
  socials: {
    telegramLink: '',
    telegramLinkVisible: false,
    viberLink: '',
    viberLinkVisible: false,
    whatsupLink: '',
    whatsupLinkVisible: false,
  },
};
