/* eslint-disable no-shadow */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { ObjectDescriptionTextarea } from '../../../../../../../../../components';
import {
  assistantData,
  assistantDataWithZero,
} from '../../../../../../../../../constants/Component/date-picker';
import { TypesIcon } from '../../../../../../../../../types/TypesIcon';
import { useDidMountEffect } from '../../../../../../../../hooks';
import { Button, DynamicImageSlider } from '../../../../../../../../shared';
import { CheckboxFieldGroup } from '../../../../../../../../shared/checkbox-field-group';
import { capitalizeFirstLetter, px } from '../../../../../../../../utils';
import { useProfile } from '../../../../../../../hooks';
import { Division } from '../division';

export const FishingFields = ({ error, type }) => {
  const {
    data: { sectorFields },
    setData: setProfile,
    initialState: { sectorFields: defaultSectorFields },
  } = useProfile();
  const [division1, setDivision1] = useState(false);
  const [division2, setDivision2] = useState(false);
  const [division3, setDivision3] = useState(false);

  const setSectorFields = payload =>
    setProfile({
      sectorFields: {
        ...sectorFields,
        ...payload,
      },
    });

  const {
    place,
    sleepPlaces,
    objectDescription,
    landscape,
    conditions,
    watersideObject,
    objectType,
    facilities,
    fishing,
    discipline,
    direction,
    addConditions,
    fish,
    capacityNumber,
    freeZoneNumber,
    winterFromIceNumber,
    photos,
    schemas,
  } = sectorFields || {};

  useLayoutEffect(() => {
    if (place?.includes?.('free') && !place?.includes?.('winterFromIce')) {
      if (place?.length > 2)
        setSectorFields({
          place: ['free', place?.filter(p => p !== 'free')?.[1]],
        });
    } else if (place?.includes?.('winterFromIce') && !place?.includes?.('free')) {
      if (place?.length > 2)
        setSectorFields({
          place: ['winterFromIce', place?.filter(p => p !== 'winterFromIce')?.[1]],
        });
    } else if (place?.length > 1) setSectorFields({ place: [place[1]] });
  }, [place]);

  return (
    <>
      <Division
        title="Берег.Ландшафт"
        open={division1}
        onToggle={open => setDivision1(open)}
        type={type}
        icon={{
          iconType: TypesIcon.WATERSIDE,
          iconWidth: px(31),
          iconHeight: px(30),
          viewBox: '0 0 31 30',
        }}
      >
        <CheckboxFieldGroup
          multiple
          redIfZero
          title="Берег"
          id="place"
          error={error === 'place'}
          items={[
            {
              label: 'FREE ZONE',
              value: 'free',
              picker: {
                title: 'FREE ZONE',
                data: {
                  freeZoneNumber: assistantDataWithZero?.assistant.map(mappedItem =>
                    mappedItem.toString(),
                  ),
                },
                value: {
                  freeZoneNumber,
                },
                onChange: setSectorFields,
                formatValue: c => c?.freeZoneNumber,
              },
            },
            {
              label: 'ЗИМНЯЯ СО ЛЬДА',
              value: 'winterFromIce',
              picker: {
                title: 'ЗИМНЯЯ СО ЛЬДА',
                data: {
                  winterFromIceNumber: assistantDataWithZero?.assistant.map(mappedItem =>
                    mappedItem.toString(),
                  ),
                },
                value: {
                  winterFromIceNumber,
                },
                onChange: setSectorFields,
                formatValue: c => c?.winterFromIceNumber,
              },
            },

            { label: 'Мостик', value: 'bridge' },
            { label: 'Понтон', value: 'pontoon' },
            { label: 'Английский', value: 'english' },
            { label: 'Дикий берег', value: 'wild' },
          ]}
          value={place}
          onChange={place => setSectorFields({ place })}
          onClear={() => {
            setSectorFields({ freeZoneNumber: defaultSectorFields?.freeZoneNumber });
            setSectorFields({ winterFromIceNumber: defaultSectorFields?.winterFromIceNumber });
          }}
        />
        <CheckboxFieldGroup
          multiple
          title="Ландшафт"
          items={[
            { label: 'Поле, равнина', value: 'field' },
            { label: 'Редкие деревья', value: 'tree' },
            { label: 'Лесопосадка', value: 'trees' },
            { label: 'Лес', value: 'forest' },
            { label: 'Газон', value: 'lawn' },
            { label: 'Возвышенность', value: 'hill' },
            { label: 'Низменность', value: 'lowland' },
          ]}
          value={landscape}
          onChange={landscape => setSectorFields({ landscape })}
        />
        <CheckboxFieldGroup
          multiple
          title="Условия на месте"
          items={[
            { id: 1, label: 'Стоянка у места ловли', value: 'parking' },
            { id: 2, label: 'Электричество', value: 'electricity' },
            { id: 3, label: 'Wi-Fi', value: 'wifi' },
            { id: 4, label: 'Видеонаблюдение', value: 'video' },
            { id: 5, label: 'Туалет', value: 'toilet' },
            { id: 6, label: 'Уборка мусора', value: 'cleaning' },
          ]}
          value={conditions}
          onChange={conditions => setSectorFields({ conditions })}
          paddingBottom={px(20)}
        />
      </Division>
      <Division
        title="Объект"
        open={division2}
        onToggle={open => setDivision2(open)}
        type={type}
        icon={{
          iconType: TypesIcon.HOUSE,
          iconWidth: px(27),
          iconHeight: px(30),
          viewBox: '0 0 27 30',
        }}
      >
        <CheckboxFieldGroup
          multiple
          title="Объект на берегу"
          items={[
            { label: 'Навес, шатер, зонт,...', value: 'tent' },
            { label: 'Беседка', value: 'alcove' },
            { label: 'Домик', value: 'house' },
            { label: 'Эко-Домик', value: 'ecoHouse' },
            { label: 'Вагончик', value: 'trailer' },
            { label: 'Сафари - тент,лодж,...', value: 'safari' },
            { label: 'Коттедж', value: 'cottage' },
            { label: 'Баня, сауна, …', value: 'bath' },
          ]}
          value={watersideObject}
          onChange={watersideObject => setSectorFields({ watersideObject })}
        />
        <CheckboxFieldGroup
          title="Тип объекта"
          items={[
            { label: 'Открытый', value: 'opened' },
            { label: 'Закрытый', value: 'closed' },
            {
              label: 'Вместимость',
              value: 'capacity',
              noCheckbox: true,
              picker: {
                title: 'Вместимость',
                data: {
                  capacityNumber: assistantDataWithZero?.assistant.map(mappedItem =>
                    mappedItem.toString(),
                  ),
                },
                value: {
                  capacityNumber,
                },
                onChange: setSectorFields,
                formatValue: c => c?.capacityNumber,
              },
            },
          ]}
          value={objectType}
          onChange={objectType => setSectorFields({ objectType })}
          onClear={() => setSectorFields({ capacityNumber: defaultSectorFields?.capacityNumber })}
        />
        <CheckboxFieldGroup
          multiple
          title="Удобства в объекте"
          items={[
            { id: 1, label: 'С проживанием', value: 'accommodation' },
            { id: 2, label: 'Отдых с детьми', value: 'children' },
            { id: 3, label: 'С домашними животными', value: 'pets' },
            { id: 4, label: 'Электричество', value: 'electricityInObject' },
            { id: 5, label: 'Кондиционер', value: 'conditioner' },
            { id: 6, label: 'Отопление', value: 'heating' },
            {
              id: 7,
              label: 'Спальное место',
              value: 'bed',
              picker: {
                title: 'Спальное место',
                data: {
                  sleepPlaces: assistantDataWithZero?.assistant.map(mappedItem =>
                    mappedItem.toString(),
                  ),
                },
                value: {
                  sleepPlaces,
                },
                onChange: setSectorFields,
                formatValue: c => c?.sleepPlaces,
              },
            },
            { id: 8, label: 'Мебель (минимум)', value: 'furniture' },
            { id: 9, label: 'Постельное белье', value: 'bedSheets' },
            { id: 10, label: 'Телевизор', value: 'tv' },
            { id: 11, label: 'Электрическая плита', value: 'plate' },
            { id: 12, label: 'Чайник', value: 'kettle' },
            { id: 13, label: 'Туалет', value: 'toiletInObject' },
            { id: 14, label: 'Водопровод', value: 'water' },
            { id: 15, label: 'Душ', value: 'shower' },
            { id: 16, label: 'Wi-Fi', value: 'wifiInObject' },
            { id: 17, label: 'Видеонаблюдение', value: 'videoInObject' },
            { id: 18, label: 'Охрана', value: 'guard' },
            { id: 19, label: 'Уборка мусора', value: 'cleaning' },
          ]}
          value={facilities}
          onChange={facilities => setSectorFields({ facilities })}
          onClear={() => setSectorFields({ sleepPlaces: defaultSectorFields?.sleepPlaces })}
        />

        <div className="w-full">
          <p
            className="font-normal opacity-50"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingBottom: px(15),
            }}
          >
            Фото. Видео
          </p>
          <div
            style={{
              marginRight: px(-15),
              marginLeft: px(-15),
            }}
          >
            <DynamicImageSlider
              items={photos}
              onChange={photos =>
                setSectorFields({
                  photos: photos?.map?.(i => ({ src: i?.src, first: !!i?.first })),
                })
              }
            />
          </div>
        </div>
        <div className="w-full">
          <p
            className="font-normal opacity-50"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingBottom: px(15),
            }}
          >
            Схема. Размещение на карте
          </p>
          <div
            style={{
              marginRight: px(-15),
              marginLeft: px(-15),
            }}
          >
            <DynamicImageSlider
              items={schemas}
              onChange={schemas =>
                setSectorFields({
                  schemas: schemas?.map?.(i => ({ src: i?.src, first: !!i?.first })),
                })
              }
            />
          </div>
        </div>
        <div
          className="w-full"
          // ref={Math.min(...scrollIndex) === 7 && imgUrl && schemaUrl ? scrollRef : null}
        >
          <p
            className="font-normal opacity-50"
            style={{
              // color: save && scrollIndex.includes(7) && 'red',
              fontSize: px(20),
              lineHeight: px(26),
              paddingBottom: px(15),
            }}
          >
            Описание объекта
          </p>

          <ObjectDescriptionTextarea
            objectStyles={{
              height: 194,
              placeholder: 'Описание: с заглавной,  ….',
              paddingTop: 4,
              paddingLeft: 18,
              paddingRight: 18,
              bottom: 20,
              textareaHeight: 180,
            }}
            value={objectDescription}
            setValue={(_, objectDescription) =>
              setSectorFields({ objectDescription: capitalizeFirstLetter(objectDescription) })
            }
          />
          <div
            className="flex flex-row items-center justify-between"
            style={{
              paddingBottom: px(20),
            }}
          >
            <Button
              fontSize={20}
              lineHeight={26}
              height={60}
              borderRadius={0}
              color="#000"
              bgColor="#fff"
              onClick={() => setSectorFields({ objectDescription: '' })}
            >
              Очистить
            </Button>
            <Button
              style={{ width: px(141) }}
              fontSize={20}
              lineHeight={26}
              height={60}
              borderRadius={0}
              color="#fff"
              disabled={!objectDescription}
              onClick={
                () => {}
                // setSaveDescription({ ...saveDescription, saveFishingDescription: true })
              }
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Division>
      <Division
        title="Рыбалка"
        open={division3}
        onToggle={open => setDivision3(open)}
        type={type}
        icon={{
          iconType: TypesIcon.FISH,
          iconWidth: px(43),
          iconHeight: px(25),
          viewBox: '0 0 43 25',
        }}
      >
        <CheckboxFieldGroup
          title="Рыбалка"
          items={[
            { id: 1, label: 'Спортивная', value: 'sport' },
            { id: 2, label: 'Любительская', value: 'love' },
          ]}
          radio
          value={fishing}
          onChange={fishing => setSectorFields({ fishing })}
        />
        <CheckboxFieldGroup
          title="Дисциплина"
          multiple
          items={[
            { id: 1, label: 'Карпфишинг', value: 'carpfishing' },
            { id: 2, label: 'Ловля форели', value: 'troutFishing' },
            { id: 3, label: 'Фидер. Донка ', value: 'fider' },
            { id: 4, label: 'Матч', value: 'match' },
            { id: 5, label: 'Поплавок', value: 'float' },
            { id: 6, label: 'Спиннинг с берега', value: 'spinningShore' },
            { id: 7, label: 'Спиннинг с лодок', value: 'spinningBoat' },
            { id: 8, label: 'Троллинг', value: 'trolling' },
            { id: 9, label: 'Джиг', value: 'jig' },
            { id: 10, label: 'Квок', value: 'kvock' },
            { id: 11, label: 'На живца', value: 'forLiveBait' },
            { id: 12, label: 'Нахлыст', value: 'flyFishing' },
            { id: 13, label: 'Тенкара', value: 'tenkara' },
            { id: 14, label: 'Херабуна', value: 'herabuna' },
            { id: 15, label: 'Танаго', value: 'tanago' },
            { id: 16, label: 'Подводная охота', value: 'spearfishing' },
          ]}
          value={discipline}
          onChange={discipline => setSectorFields({ discipline })}
        />
        <CheckboxFieldGroup
          title="Направление"
          multiple
          items={[
            { id: 1, label: 'Платная', value: 'pay' },
            { id: 2, label: 'Трофейная (от 20 кг)', value: 'trofei' },
            { id: 3, label: 'Зимняя со льда', value: 'winter' },
            { id: 6, label: 'В городе', value: 'city' },
            { id: 6, label: 'Дикие места', value: 'wildPlace' },
            { id: 6, label: 'На реках с берега', value: 'river' },
            { id: 6, label: 'На реках с лодки', value: 'riverBoat' },
            { id: 6, label: 'На озерах с берега', value: 'lake' },
            { id: 6, label: 'На озерах с лодки', value: 'lakeBoat' },
            { id: 6, label: 'Морская с пляжа', value: 'sea' },
            { id: 6, label: 'Морская в море', value: 'seaSea' },
            { id: 6, label: 'На затонувших судах', value: 'suda' },
            { id: 6, label: 'Морская на рифах', value: 'rifs' },
          ]}
          value={direction}
          onChange={direction => setSectorFields({ direction })}
        />
        <CheckboxFieldGroup
          title="Доп. условия"
          multiple
          items={[
            { id: 1, label: 'Использовать кораблик', value: 'boat' },
            { id: 2, label: 'Со своей лодкой', value: 'yourBoat' },
            { id: 3, label: 'Со своей палаткой', value: 'yourTent' },
          ]}
          value={addConditions}
          onChange={addConditions => setSectorFields({ addConditions })}
        />
        <CheckboxFieldGroup
          title="Виды рыб"
          multiple
          items={[
            { id: 1, label: 'Карп', value: 'carp' },
            { id: 2, label: 'Сазан', value: 'sazan' },
            { id: 3, label: 'Белый амур', value: 'whiteAmur' },
            { id: 4, label: 'Черный амур', value: 'blackAmur' },
            { id: 5, label: 'Сом обыковенный', value: 'commonCatfish' },
            { id: 6, label: 'Сом канальный', value: 'channelCatfish' },
            { id: 7, label: 'Толстолобик', value: 'silverCarp' },
            { id: 8, label: 'Форель радужная', value: 'rainbowTrout' },
            { id: 9, label: 'Форель золотая', value: 'goldenTrout' },
            { id: 10, label: 'Щука', value: 'pike' },
            { id: 11, label: 'Осетр', value: 'sturgeon' },
            { id: 12, label: 'Белуга', value: 'belugaSturgeon' },
            { id: 13, label: 'Линь', value: 'tench' },
            { id: 14, label: 'Сиг', value: 'sig' },
            { id: 15, label: 'Судак', value: 'zander' },
            { id: 16, label: 'Карась', value: 'crucianCarp' },
            { id: 17, label: 'Окунь', value: 'perch' },
            { id: 18, label: 'Жерех', value: 'roach' },
            { id: 19, label: 'Лещ', value: 'bream' },
            { id: 20, label: 'Хариус европейский', value: 'EuropeanChub' },
            { id: 21, label: 'Таймень', value: 'taimen' },
            { id: 22, label: 'Налим', value: 'salmon' },
            { id: 23, label: 'Язь', value: 'grayling' },
            { id: 24, label: 'Нельма', value: 'nelma' },
            { id: 25, label: 'Плотва', value: 'plotva' },
            { id: 26, label: 'Муксун', value: 'muksun' },
            { id: 27, label: 'Пелядь', value: 'vendace' },
            { id: 28, label: 'Красноперка', value: 'ruffe' },
            { id: 29, label: 'Морской карась', value: 'seaBream' },
            { id: 30, label: 'Каранакс', value: 'crappie' },
            { id: 31, label: 'Марлин', value: 'marlin' },
            { id: 32, label: 'Желтохвост', value: 'yellowtail' },
            { id: 33, label: 'Ваху', value: 'wahoo' },
            { id: 34, label: 'Корифена (махи-махи)', value: 'dorado' },
            { id: 35, label: 'Луциан-кубера', value: 'cuberaSnapper' },
            { id: 36, label: 'Красный луциан', value: 'redSnapper' },
            { id: 37, label: 'Снук', value: 'snook' },
            { id: 38, label: 'Пермит', value: 'permit' },
            { id: 39, label: 'Тунец', value: 'tuna' },
            { id: 40, label: 'Камбала', value: 'flounder' },
            { id: 41, label: 'Кобия', value: 'cobia' },
            { id: 42, label: 'Королевская макрель', value: 'kingMackerel' },
            { id: 43, label: 'Горбыль', value: 'mullet' },
            { id: 44, label: 'Амберджек', value: 'amberjack' },
            { id: 45, label: 'Рыба-петух', value: 'triggerfish' },
            { id: 46, label: 'Желтопёрый групер', value: 'yellowfinGrouper' },
            { id: 47, label: 'Парусник', value: 'sailfish' },
            { id: 48, label: 'Пятнистая форель', value: 'speckledTrout' },
            { id: 49, label: 'Барракуда', value: 'barracuda' },
            { id: 50, label: 'Бонито', value: 'bonito' },
            { id: 51, label: 'Спинорог', value: 'spinnerShark' },
            { id: 52, label: 'Черноперая акула', value: 'blacktipShark' },
          ]}
          value={fish}
          onChange={fish => setSectorFields({ fish })}
          paddingBottom={px(20)}
        />
      </Division>
    </>
  );
};

export default FishingFields;
