import React, { useState } from 'react';

import { px } from '../constants/Px';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const FooterDropdown = ({
  options,
  label,
  fontLabelSize,
  labelLineHeight,
  optionsColorBlack,
  paddingTop,
  fill,
  color,
  fontSize,
  lineHeight,
  children,
  handleComponentClick,
  isBorderOpacity,
  disabledLinks,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    if (handleComponentClick) {
      handleComponentClick();
    }

    setIsOpen(!isOpen);
  };

  return (
    <div
      className={` border-b-solid flex w-full cursor-pointer flex-col items-start justify-start border-b-[1px] border-b-[#363d45] ${
        isBorderOpacity && 'border-opacity-30'
      } font-normal`}
      style={{
        paddingTop: px(paddingTop || 14),
        paddingBottom: px(isOpen ? 20 : 30),
      }}
    >
      <div
        className="flex w-full items-center justify-between"
        onClick={handleClose}
        onKeyDown={handleClose}
        style={{
          paddingBottom: px(isOpen ? 25 : 0),
        }}
      >
        {label && (
          <p
            style={{
              fontSize: px(fontLabelSize || 20),
              lineHeight: px(labelLineHeight || 26),
              color: color || '#ffffff',
            }}
            className="font-normal"
          >
            {label}
          </p>
        )}
        <div className={`${isOpen && 'rotate-180 transform transition-all duration-300'}`}>
          <Icon
            type={TypesIcon.DROPDOWN}
            width={px(12)}
            height={px(7)}
            viewBox="0 0 12 7"
            fill={fill || 'white'}
          />
        </div>
      </div>
      {isOpen && options?.length && (
        <ul className="dropdown-options">
          {options?.map(option => (
            <a
              className={`${option?.disabledLinks && 'pointer-events-none'}`}
              href={option?.link}
              key={option?.value}
            >
              <li
                key={option?.value}
                className={`${
                  optionsColorBlack ? 'text-[#000]' : 'text-[#9b9b9b]'
                } duration-300 hover:text-white`}
                style={{
                  fontSize: fontSize && px(fontSize),
                  lineHeight: lineHeight && px(lineHeight),
                }}
              >
                {option.label}
              </li>
            </a>
          ))}
        </ul>
      )}
      {isOpen && children}
    </div>
  );
};

export default FooterDropdown;
