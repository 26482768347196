import { createSelector } from '@reduxjs/toolkit';



export const selectRoot = ({ modals }) => modals;

export const selectModals = createSelector(
    selectRoot,
    modals => Object.values(modals),
);


