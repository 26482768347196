import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/auth';
import { createRequest } from '../../../helpers/api/createRequest';
import { addModal } from '../../modal/addModal';
// eslint-disable-next-line import/no-cycle
import { getProfile } from '../../profile';
import { clearActiveId } from '../clear-activeId';
import { clearBiometric } from '../clear-biometric';
import { clearCode } from '../clear-code';

export const VERIFY = 'auth/verify';

const onFail = error => async dispatch => {
  await dispatch(
    addModal({
      id: 4,
      data: {
        title: 'Вы ввели не верный пароль',
        buttonLeftText: 'Ok',
        single: true,
      },
    }),
  );
};

const onSuccess = () => async dispatch => {
  await dispatch(
    addModal({
      id: 37,
      data: {
        title: 'Регистрация прошла успешно.',
        buttonLeftText: 'Ok',
        single: true,
      },
      path: 'add-code',
    }),
  );
  await dispatch(clearBiometric());
  await dispatch(clearCode());
  await dispatch(getProfile());
  await dispatch(clearActiveId());
};

export const verify = createAsyncThunk(
  VERIFY,
  createRequest({
    type: VERIFY,
    loader: data => authApi.verify(data),
    onFail,
    onSuccess,
  }),
);
