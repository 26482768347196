import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { getCardHolderCards } from '../api/card-holder';
import { restoreDeleteCardHolder } from '../api/card-holder/delete-card-holder';
import qrCode from '../assets/images/qr-code.png';
import { addLeadingZeros, formattedExpirationDate } from '../constants/Function';
import { px } from '../constants/Px';
import { BackButton, CardInformation, CircleButton } from '../new-architecture/shared';
import { TypesIcon } from '../types/TypesIcon';
import { Button } from './button';

export const ClubCardViewModal = ({
  data,
  showCardTypeView,
  setShowCardTypeView,
  setCards,
  removeDeleteButton,
}) => {
  const [openDropdownIds, setOpenDropdownIds] = useState([]);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(false);
  const handleComponentClick = id => {
    if (openDropdownIds.includes(id)) {
      setOpenDropdownIds(openDropdownIds.filter(item => item !== id));
    } else {
      setOpenDropdownIds([...openDropdownIds, id]);
    }
  };
  const onClickButtonDelete = async cardId => {
    try {
      setDisabledButtonDelete(true);
      await restoreDeleteCardHolder({ cardId });
      const response = await getCardHolderCards();
      if (response?.data.length > 0) {
        setCards(response.data || []);
        setDisabledButtonDelete(false);
      }
    } catch (error) {
      console.error('Error deleting card:', error);
    } finally {
      setDisabledButtonDelete(false);
    }
  };
  const [showInfo, setShowInfo] = useState(false);
  useEffect(() => {
    if (showCardTypeView) {
      document.documentElement.classList.add('lock');
    } else {
      document.documentElement.classList.remove('lock');
    }
    return () => document.documentElement.classList.remove('lock');
  }, [showCardTypeView]);

  return (
    <div className="fixed top-0 z-[102] h-full w-full max-w-[768px] bg-white">
      <div className="scrollable-container h-full">
        <div className="global-container">
          <div className="scrollable-container max-h-full">
            <BackButton onClick={() => setShowCardTypeView(false)} />
            <div className="container" style={{ paddingTop: px(10) }}>
              <div
                className=" flex flex-col overflow-hidden bg-white"
                style={{
                  boxShadow: '0px 6px 7.5px 0 #00000066',
                  borderRadius: px(10),
                  marginBottom: px(15),
                }}
              >
                <div
                  style={{ height: px(204), width: px(290), borderRadius: px(10) }}
                  className="relative overflow-hidden"
                >
                  <div className="absolute z-[2] flex h-full w-full items-center justify-center overflow-hidden">
                    <div
                      style={{
                        width: px(65),
                        height: px(65),
                        boxShadow: '0 4px 6px 0 #00000029',
                        borderRadius: px(10),
                        overflow: 'hidden',
                      }}
                    >
                      {data?.card?.event?.EventAttachment?.find(
                        attachment => attachment.type === 'LOGO',
                      )?.imageUrl && (
                        <img
                          className="h-full w-full object-cover"
                          src={`${process.env.REACT_APP_STATIC_URL}${
                            data?.card?.event?.EventAttachment?.find(
                              attachment => attachment.type === 'LOGO',
                            )?.imageUrl
                          }`}
                          alt="avatarImage"
                        />
                      )}
                    </div>
                  </div>
                  <div className="absolute z-[2] h-full w-full">
                    <div className="relative h-full w-full">
                      <p
                        className="absolute font-[400] text-[#FA0202]"
                        style={{ fontSize: px(32), lineHeight: px(43), top: px(0), right: px(12) }}
                      >
                        {data?.card?.cardDiscount}%
                      </p>
                      <div
                        className="absolute z-[2]"
                        style={{ bottom: px(10), left: px(20), width: px(250) }}
                      >
                        <h3
                          className="font-[400] uppercase text-white"
                          style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
                        >
                          {data?.profile?.name || ''}
                        </h3>
                        <div className="flex w-full items-center justify-between">
                          <p
                            className="font-[400] text-white"
                            style={{ fontSize: px(20), lineHeight: px(26), paddingBottom: px(5) }}
                          >
                            {formattedExpirationDate(data?.openUntill) || ''}
                          </p>
                          <p
                            className="font-[400] text-white"
                            style={{ fontSize: px(20), lineHeight: px(26) }}
                          >
                            {data?.card?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data?.card?.image?.url && (
                    <img
                      className="absolute z-[1] h-full w-full object-cover"
                      src={`${process.env.REACT_APP_STATIC_URL}${data?.card?.image?.url}`}
                      alt="card-type-view"
                    />
                  )}
                </div>
                <div
                  className="relative flex flex-col items-center justify-center"
                  style={{ paddingTop: px(10), paddingBottom: px(10) }}
                >
                  <img src={qrCode} alt="qr-code" style={{ width: px(118), height: px(118) }} />
                  <p className="font-[400]" style={{ fontSize: px(15), lineHeight: px(20) }}>
                    {addLeadingZeros(data?.id.toString())}
                  </p>
                  <div className="absolute" style={{ right: px(10), bottom: px(10) }}>
                    <CircleButton
                      onClick={() => setShowInfo(!showInfo)}
                      icon={{
                        type: TypesIcon.ARROWTOP,
                        viewBox: '0 0 14 21',
                        width: px(14),
                        height: px(21),
                        className: `${showInfo ? '' : 'rotate-[180deg]'}`,
                      }}
                      className="text-[#0A84FE]"
                    />
                  </div>
                </div>
              </div>
            </div>
            {showInfo && (
              <div
                className=" footer__dropdowns "
                style={{
                  background: 'white',
                }}
              >
                <CardInformation
                  data={[
                    {
                      id: 1,
                      title: { label: 'Имя карты' },
                      value: data?.card?.name,
                    },
                    {
                      id: 2,
                      title: { label: 'Статус карты' },
                      value: `${data?.card?.cardDiscount || 0}%`,
                    },
                    {
                      id: 3,
                      title: { label: 'Срок действия' },
                      value: formattedExpirationDate(data?.openUntill),
                    },
                    data?.card?.cardPaymentType !== 'FREE' && {
                      id: 4,
                      title: { label: 'Условия получения' },
                      value: data?.card?.receiptConditions,
                    },
                  ]}
                />
                <CardInformation
                  handleComponentClick={handleComponentClick}
                  data={[
                    {
                      id: 1,
                      title: { label: 'Общая информация' },
                      text: data?.card?.generalInformation,
                      disabled: data?.card?.generalInformation === '',
                    },
                    {
                      id: 2,
                      title: { label: 'Где применяется' },
                      text: data?.card?.applicationScope,
                      disabled: data?.card?.applicationScope === '',
                    },
                    {
                      id: 3,
                      title: { label: 'Соглашение' },
                      text: data?.card?.agreement,
                      disabled: data?.card?.agreement === '',
                    },
                    {
                      id: 4,
                      title: { label: 'Регламент использования' },
                      text: data?.card?.useTerms,
                      disabled: data?.card?.useTerms === '',
                    },
                    {
                      id: 5,
                      title: { label: 'Обслуживание клиентов' },
                      disabled:
                        data?.card?.event?.EventContact?.email === '' &&
                        data?.card?.event?.EventContact?.mainPhone === '' &&
                        data?.card?.event?.EventContact?.secondaryPhone === '',
                      text: [
                        {
                          label: 'Email:',
                          value: data?.card?.event?.EventContact?.email,
                        },
                        {
                          value: data?.card?.event?.EventContact?.mainPhone,
                        },
                        {
                          value: data?.card?.event?.EventContact?.secondaryPhone,
                        },
                      ],
                    },
                  ]}
                  dropDown
                />
                {!removeDeleteButton && (
                  <Button
                    onClick={() => {
                      onClickButtonDelete(data?.id);
                    }}
                    className="w-full"
                    height={60}
                    borderRadius={0}
                    marginTop={20}
                    marginBottom={20}
                    bgColor="#FA0303"
                    color="#ffffff"
                    disabled={disabledButtonDelete}
                  >
                    Удалить карту
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubCardViewModal;
