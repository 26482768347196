import * as authApi from '@/api/attachment';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_SINGLE_ATTACHMENT = 'attachment/getSingleAttachment';


export const getSingleAttachment = createAsyncThunk(
  GET_SINGLE_ATTACHMENT,
  createRequest({
    type: GET_SINGLE_ATTACHMENT,
    loader: (data) => authApi.getSingleAttachment(data),
 
  }),
);
