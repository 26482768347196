import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HomeAddPopup } from '../../components/home-add-popup';
import { Icon } from '../../components/Icon';
import { px } from '../../constants/Px';
import { CircleButton } from '../../new-architecture/shared';
import { TypesIcon } from '../../types/TypesIcon';

export const BookingPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="global-container">
      <main>
        <HomeAddPopup book>
          <p style={{ paddingBottom: px(12) }}>
            Бронирование доступно только зарегистрированным пользователям
          </p>
          <div className="mb-[20px] flex items-center" style={{ gap: px(10), paddingLeft: px(11) }}>
            <button
              type="button"
              aria-hidden
              className="flex items-center overflow-hidden rounded-[100px] border-[1px] border-[#0A84FE] bg-white font-normal text-[#0A84FE] "
              style={{ height: px(50), fontSize: px(20) }}
              onClick={() => navigate('/registration')}
            >
              <span
                className="flex items-center rounded-l-[50%] bg-[#0A84FE]"
                style={{ paddingRight: px(12), paddingLeft: px(12), height: px(50) }}
              >
                <Icon
                  type={TypesIcon.REGISTRATION}
                  width={px(21.66)}
                  height={px(21.66)}
                  viewBox="0 0 21.66 21.66"
                />
              </span>
              <span style={{ paddingRight: px(21), paddingLeft: px(6) }}>Регистрация</span>
            </button>
            <CircleButton
              icon={{
                type: TypesIcon.LAMPBLUE,
                viewBox: '0 0 27.5 26.233',
                width: px(27.5),
                height: px(26.233),
                fill: '#0A84FE',
              }}
            />
          </div>
        </HomeAddPopup>
      </main>
    </div>
  );
};
