import { createAsyncThunk } from '@reduxjs/toolkit';

import * as authApi from '../../../api/profile';
import { createRequest } from '../../../helpers/api/createRequest';
import { clearBiometric } from '../../auth';

export const DELETE_BIOMETRIC = 'profile/createGroup';

const onSuccess = () => async dispatch => {
  await dispatch(clearBiometric());
};

export const deleteBiometric = createAsyncThunk(
  DELETE_BIOMETRIC,
  createRequest({
    type: DELETE_BIOMETRIC,
    loader: data => authApi.deleteBiometric(data),
    onSuccess,
  }),
);
