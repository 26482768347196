/* eslint-disable no-shadow */
import React, { useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { useProfile } from '../../../../../hooks';
import { PriceRate } from '../price-rate';

export const PriceSettings = ({ data, type, priceDiscountAction, firstSector }) => {
  const [priceRate, setPriceRate] = useState();

  const {
    data: {
      priceGroup,
      promotionGroup,
      discountGroup,
      currentPriceGroup,
      currentPromotionGroup,
      currentDiscountGroup,
    },
    setData: setProfile,
    updateFunction: updateProfile,
  } = useProfile();

  const setCurrentPriceGroup = fields =>
    setProfile({
      currentPriceGroup: {
        ...currentPriceGroup,
        ...fields,
      },
    });

  const setCurrentPromotionGroup = fields =>
    setProfile({
      currentPromotionGroup: {
        ...currentPromotionGroup,
        ...fields,
      },
    });

  const setCurrentDiscountGroup = fields =>
    setProfile({
      currentDiscountGroup: {
        ...currentDiscountGroup,
        ...fields,
      },
    });

  const handleChange = async time => {
    await setPriceRate(time);
    if (priceDiscountAction === 'price')
      await setCurrentPriceGroup({ ...data?.rates?.find(item => item.type === time) });
    if (priceDiscountAction === 'action')
      await setCurrentPromotionGroup({ ...data?.rates?.find(item => item.type === time) });
    if (priceDiscountAction === 'discount')
      await setCurrentDiscountGroup({ ...data?.rates?.find(item => item.type === time) });
    await updateProfile();
  };

  return (
    <div>
      {priceGroup && (
        <PriceRate
          locationType={type}
          priceDiscountAction={priceDiscountAction}
          /* priceInfo={priceGroup?.rates?.find(item => item.type === 'DAY')} */
          priceInfo={
            priceDiscountAction === 'price'
              ? data?.rates?.find(item => item.type === 'DAY')
              : firstSector?.priceGroup?.rates?.find(item => item.type === 'DAY')
          }
          discountInfo={discountGroup?.rates?.find(item => item.type === 'DAY')}
          promotionInfo={promotionGroup?.rates?.find(item => item.type === 'DAY')}
          type="ДЕНЬ"
          checked={priceRate === 'DAY'}
          onChange={async () => {
            handleChange('DAY');
          }}
        />
      )}

      {priceGroup && (
        <PriceRate
          priceDiscountAction={priceDiscountAction}
          locationType={type}
          /* priceInfo={priceGroup?.rates?.find(item => item.type === 'TWHOUR')} */
          priceInfo={
            priceDiscountAction === 'price'
              ? data?.rates?.find(item => item.type === 'TWHOUR')
              : firstSector?.priceGroup?.rates?.find(item => item.type === 'TWHOUR')
          }
          discountInfo={discountGroup?.rates?.find(item => item.type === 'TWHOUR')}
          promotionInfo={promotionGroup?.rates?.find(item => item.type === 'TWHOUR')}
          type="СУТКИ"
          checked={priceRate === 'TWHOUR'}
          onChange={async () => {
            handleChange('TWHOUR');
          }}
        />
      )}

      {priceGroup && (
        <PriceRate
          priceDiscountAction={priceDiscountAction}
          locationType={type}
          /* priceInfo={priceGroup?.rates?.find(item => item.type === 'NIGHT')} */
          priceInfo={
            priceDiscountAction === 'price'
              ? data?.rates?.find(item => item.type === 'NIGHT')
              : firstSector?.priceGroup?.rates?.find(item => item.type === 'NIGHT')
          }
          discountInfo={discountGroup?.rates?.find(item => item.type === 'NIGHT')}
          promotionInfo={promotionGroup?.rates?.find(item => item.type === 'NIGHT')}
          type="НОЧЬ"
          checked={priceRate === 'NIGHT'}
          onChange={() => handleChange('NIGHT')}
        />
      )}

      {priceGroup && (
        <PriceRate
          priceDiscountAction={priceDiscountAction}
          locationType={type}
          /* priceInfo={priceGroup?.rates?.find(item => item.type === 'HOUR')} */
          priceInfo={
            priceDiscountAction === 'price'
              ? data?.rates?.find(item => item.type === 'HOUR')
              : firstSector?.priceGroup?.rates?.find(item => item.type === 'HOUR')
          }
          discountInfo={discountGroup?.rates?.find(item => item.type === 'HOUR')}
          promotionInfo={promotionGroup?.rates?.find(item => item.type === 'HOUR')}
          type="ЧАС"
          checked={priceRate === 'HOUR'}
          onChange={() => handleChange('HOUR')}
        />
      )}
    </div>
  );
};

export default PriceSettings;
