import React from 'react';

export const BathIcon = props => (
  <svg
    viewBox="0 0 36 33"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4031_2007)">
      <g opacity="0.5">
        <path
          d="M28.5203 14.233C28.143 14.233 27.8359 13.926 27.8359 13.5486V8.19885L29.2056 8.53627V13.5486C29.2056 13.926 28.8982 14.233 28.5203 14.233Z"
          fill="currentColor"
        />
        <path
          d="M28.5203 13.7332C28.6208 13.7332 28.7056 13.6488 28.7056 13.5488V8.92822L28.3359 8.83715V13.5488C28.3359 13.6488 28.4204 13.7332 28.5203 13.7332ZM28.5203 14.7332C27.8673 14.7332 27.3359 14.2019 27.3359 13.5488V7.56091L29.7056 8.14467V13.5488C29.7056 14.2019 29.1739 14.7332 28.5203 14.7332Z"
          fill="currentColor"
        />
        <path
          d="M34.4695 14.5014L18.933 5.53144C18.1232 5.06393 17.0879 5.34117 16.6205 6.15068C16.1532 6.96019 16.4307 7.99542 17.2405 8.46293L32.777 17.4329C33.5867 17.9004 34.622 17.6232 35.0894 16.8137C35.5568 16.0042 35.2792 14.9689 34.4695 14.5014Z"
          fill="currentColor"
        />
        <path
          d="M34.2201 14.9344L18.6836 5.96442C18.113 5.63498 17.3834 5.83029 17.0541 6.40066C16.7248 6.97102 16.9205 7.70045 17.4911 8.02989L33.0276 16.9999C33.5982 17.3293 34.3277 17.134 34.657 16.5637C34.9863 15.9933 34.7907 15.2639 34.2201 14.9344Z"
          stroke="currentColor"
        />
        <path
          d="M3.15943 17.4334L18.6959 8.46344C19.5057 7.99593 19.7832 6.96071 19.3159 6.1512C18.8485 5.34169 17.8132 5.06444 17.0034 5.53195L1.46693 14.5019C0.657184 14.9695 0.379631 16.0047 0.847002 16.8142C1.31437 17.6237 2.34968 17.901 3.15943 17.4334Z"
          fill="currentColor"
        />
        <path
          d="M2.90885 17.0005L18.4453 8.03046C19.016 7.70102 19.2116 6.97159 18.8823 6.40122C18.553 5.83086 17.8235 5.63555 17.2528 5.96499L1.71635 14.935C1.14575 15.2644 0.950134 15.9939 1.27943 16.5642C1.60873 17.1346 2.33825 17.3299 2.90885 17.0005Z"
          stroke="currentColor"
        />
      </g>
      <path
        opacity="0.5"
        d="M7.66205 21.2138H3.21905V25.1448H5.50705C5.93915 25.133 6.36807 25.222 6.7597 25.405C7.15134 25.588 7.49489 25.8598 7.76305 26.1988C8.34368 27.0129 8.62972 28.0005 8.57405 28.9988C8.62576 30.006 8.33808 31.0015 7.75705 31.8258C7.49642 32.1687 7.1592 32.4458 6.77234 32.6351C6.38547 32.8244 5.9597 32.9205 5.52905 32.9158H1.99805V19.7898H7.66205V21.2138ZM3.21905 26.5688V31.4988H5.44105C5.71415 31.5105 5.98585 31.4539 6.23166 31.3344C6.47746 31.2148 6.68963 31.0359 6.84905 30.8138C7.20845 30.2702 7.38373 29.6256 7.34905 28.9748C7.38407 28.3556 7.21511 27.7419 6.86805 27.2278C6.70749 27.0164 6.4988 26.8463 6.25931 26.7317C6.01982 26.6171 5.75644 26.5612 5.49105 26.5688H3.21905ZM15.431 29.4898H11.57L10.707 32.9158H9.45005L12.969 19.7898H14.032L17.557 32.9158H16.307L15.431 29.4898ZM11.937 28.0658H15.07L13.5 21.9258L11.937 28.0658ZM26.007 32.9158H24.787V26.8488H20.142V32.9158H18.927V19.7898H20.142V25.4338H24.787V19.7898H26.007V32.9158ZM33.117 32.9158V27.8048H30.993L29.093 32.9168H27.793L29.929 27.3268C29.3238 26.9599 28.8349 26.4289 28.5189 25.7955C28.203 25.1621 28.073 24.4521 28.144 23.7478C28.0795 22.7042 28.3851 21.6713 29.007 20.8308C29.3001 20.4844 29.6691 20.2103 30.0853 20.0298C30.5016 19.8492 30.9539 19.7671 31.407 19.7898H34.337V32.9158H33.117ZM29.36 23.7478C29.3268 24.4241 29.5096 25.0934 29.882 25.6588C30.0448 25.8912 30.2626 26.0795 30.516 26.2069C30.7694 26.3343 31.0505 26.3968 31.334 26.3888H33.119V21.2138H31.391C31.1046 21.1967 30.8181 21.2482 30.5556 21.3643C30.2931 21.4803 30.0622 21.6574 29.882 21.8808C29.5036 22.427 29.3197 23.0846 29.36 23.7478Z"
        fill="currentColor"
      />
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4272 6.88699C28.0772 5.53599 28.6603 4.21099 28.8043 2.88699C28.7999 1.91617 28.5305 0.964932 28.0252 0.13595C27.8192 -0.30805 28.0862 0.501967 28.0832 0.702967C28.2012 1.89397 27.6142 2.97895 27.5832 4.15695C27.5286 4.97464 27.7228 5.78972 28.1403 6.49496C28.2253 6.62296 28.6092 7.42399 28.4272 6.88699Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4031_2007">
        <rect width="35.936" height="32.916" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
