import * as authApi from '@/api/event';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const UPDATE_EVENT = 'event/updateEvent';

export const updateEvent = createAsyncThunk(
  UPDATE_EVENT,
  createRequest({
    type: UPDATE_EVENT,
    loader: (data) => authApi.updateEvent(data),
  }),
);
