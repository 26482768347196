import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Main, Switch } from '../../components';
import { px } from '../../constants/Px';

export const StatisticPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Main title="Сбор статистики" light>
      <div>
        <p
          className="font-normal"
          style={{ marginRight: px(-6), fontSize: px(15), lineHeight: px(20) }}
        >
          Чтобы сделать сайт лучше, мы собираем статистику в обезличенном виде. Ваши данные остаются
          полностью конфиденциальны и не передаются третьим лицам.
        </p>
        <div
          className="font-normal text-[#0a84fe]"
          style={{
            marginTop: px(18),
            marginBottom: px(36),
            fontSize: px(15),
            lineHeight: px(20),
          }}
          onClick={() => navigate('/privacy')}
          onKeyDown={() => navigate('/privacy')}
        >
          Согласие на обработку данных
        </div>
        <div className="flex flex-row items-center justify-between">
          <div>
            <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
              Я разрешаю собирать
            </p>
            <p className="font-normal" style={{ fontSize: px(15), lineHeight: px(20) }}>
              Статистика по моб. версии
            </p>
          </div>
          <Switch toggle={toggle} setToggle={setToggle} />
        </div>
      </div>
    </Main>
  );
};
