import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEvent } from '../actions/event';
import { filterObjectService, textareaFiltersStyles } from '../constants/Component';
import { px } from '../constants/Px';
import { eventObjectSelector } from '../selectors/get-event';
import Button from './button';
import ObjectDescriptionTextarea from './object-description-textarea';
import { SearchMenuFilterBlockFiltersRest } from './search-menu-filter-block-filters-rest';
import { SearchMenuFilterBlockFiltersService } from './search-menu-filter-block-filters-service';
import { StateContext } from './state-provider';
import StyledGallery from './styled-gallery';
import StyledGalleryFilters from './styled-gallery-filters';
import StyledInput from './styled-input';

const capitalizeFirstLetter = str =>
  typeof str === 'string' && str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export const FiltersFilterService = ({
  scrollIndex,
  setScrollIndex,
  setSave,
  save,
  selectedObjectFiltersService,
  setSelectedObjectFiltersService,
  show,
  setShow,
  date,
  setDataPickerId,
  imgUrl,
  setImgUrl,
  sectorValue,
}) => {
  const [galleryIndexPhoto, setGalleryIndexPhoto] = useState(0);

  const [addSlidePhoto, setAddSlidePhoto] = useState(false);

  const dispatch = useDispatch();
  const { setSelectedOptionsTitle, selectedOptionsTitle } = useContext(StateContext);
  const data = useSelector(eventObjectSelector);
  useEffect(() => {
    dispatch(getEvent({ id: 12 }));
  }, []);

  const { scrollRef } = useContext(StateContext);

  const handleInputChange = (e, title) => {
    setSave(false);
    const text = capitalizeFirstLetter(e.target.value);
    setSelectedObjectFiltersService({ ...selectedObjectFiltersService, 2: [text] });
  };

  const handleChangeTextarea = (key, value) => {
    const text = capitalizeFirstLetter(value);
    setSelectedObjectFiltersService({ ...selectedObjectFiltersService, 3: [text] });
  };

  return (
    <div>
      <div
        className="search-menu-item-filter-items"
        style={{
          paddingTop: px(20),
          paddingBottom: px(20),
        }}
      >
        {filterObjectService?.map(item => (
          <SearchMenuFilterBlockFiltersService
            setScrollIndex={setScrollIndex}
            scrollIndex={scrollIndex}
            selectedOptionsTitle={selectedOptionsTitle}
            setSelectedOptionsTitle={setSelectedOptionsTitle}
            key={item?.value}
            filter={item}
            selectedObjectFiltersRest={selectedObjectFiltersService}
            setSelectedObjectFiltersRest={setSelectedObjectFiltersService}
            save={save}
            setSave={setSave}
            show={show}
            setShow={setShow}
            date={date}
            setDataPickerId={setDataPickerId}
          />
        ))}
      </div>
      <div
        ref={Math.min(...scrollIndex) === 2 ? scrollRef : null}
        style={{ paddingTop: px(20), paddingBottom: px(15) }}
      >
        <StyledInput
          isBorder
          onChange={handleInputChange}
          title="Название объекта"
          titleColor={save && scrollIndex.includes(2)}
          placeholder="XXXXXXXX"
          value={selectedObjectFiltersService[2]}
        />
      </div>
      <div className="flex flex-row" style={{ paddingBottom: px(40), gap: px(71) }}>
        <p className="font-normal" style={{ fontSize: px(20), lineHeight: px(26) }}>
          Номер
        </p>
        <p
          aria-hidden
          onClick={() => {
            setDataPickerId(8);
            setShow(true);
          }}
          className="text-[#0A84FE]"
          style={{ fontSize: px(20), lineHeight: px(26) }}
        >
          {sectorValue}
        </p>
      </div>
      <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
        <p
          className="font-normal opacity-50"
          style={{
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
            color: save && scrollIndex.includes(3) && !imgUrl && 'red',
          }}
          ref={Math.min(...scrollIndex) === 3 && !imgUrl ? scrollRef : null}
        >
          Фото. Видео
        </p>
        <div>
          <StyledGalleryFilters
            index={galleryIndexPhoto}
            data={data}
            setIndex={setGalleryIndexPhoto}
            setAddSlide={setAddSlidePhoto}
            addSlide={addSlidePhoto}
            setUrl={setImgUrl}
            imgUrl={imgUrl}
            setSave={setSave}
          />
        </div>
      </div>
      <div style={{ paddingTop: px(20), paddingBottom: px(20) }}>
        <p
          ref={Math.min(...scrollIndex) === 3 && imgUrl ? scrollRef : null}
          className="font-normal opacity-50"
          style={{
            color: save && scrollIndex.includes(3) && 'red',
            fontSize: px(20),
            lineHeight: px(26),
            paddingBottom: px(15),
          }}
        >
          Описание объекта
        </p>

        <ObjectDescriptionTextarea
          objectStyles={textareaFiltersStyles}
          value={selectedObjectFiltersService[3]}
          setValue={handleChangeTextarea}
        />
        <div className="flex flex-row items-center justify-between">
          <Button
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#000"
            bgColor="#fff"
            onClick={() =>
              selectedObjectFiltersService({ ...selectedObjectFiltersService, 11: [''] })
            }
          >
            Очистить
          </Button>
          <Button
            style={{ width: px(141) }}
            fontSize={20}
            lineHeight={26}
            height={60}
            borderRadius={0}
            color="#fff"
            onClick={() => setSaveDescription({ ...saveDescription, saveServiceDescription: true })}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FiltersFilterService;
