import { useDragControls } from 'framer-motion';
import { createContext, useContext, useRef, useState } from 'react';

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [selectedPreferences, setSelectedPreferences] = useState({
    country: 'Россия',
    currency: 'рубль',
    svgUrl: 'FLAGRUSSIAN',
    iconUrl: '/russia.png',
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const scrollRef = useRef(null);

  const [selectedOptionsTitle, setSelectedOptionsTitle] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const dragControls = useDragControls();
  const [selectedItem, setSelectedItem] = useState(null);
  const [resultsListItems, setResultsListItems] = useState([]);
  const [resultsListOpen, setResultsListOpen] = useState(false);
  const [bookingCopyPeriod, setBookingCopyPeriod] = useState({
    distribution: 'Каждые 12 часов',
  });
  const [statisticCopyPeriod, setStatisticCopyPeriod] = useState({
    distribution: 'Каждые 12 часов',
  });
  const [suggestionCopyPeriod, setSuggestionCopyPeriod] = useState({
    distribution: 'Каждые 12 часов',
  });
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <StateContext.Provider
      value={{
        selectedPreferences,
        setSelectedPreferences,
        setShowSearch,
        showSearch,
        selectedItem,
        setSelectedItem,
        resultsListItems,
        setResultsListItems,
        resultsListOpen,
        setResultsListOpen,
        dragControls,
        setSelectedOptions,
        selectedOptions,
        selectedOptionsTitle,
        setSelectedOptionsTitle,
        bookingCopyPeriod,
        setBookingCopyPeriod,
        statisticCopyPeriod,
        setStatisticCopyPeriod,
        suggestionCopyPeriod,
        setSuggestionCopyPeriod,
        handleShow,
        show,
        handleClose,
        scrollRef,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
