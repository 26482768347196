import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { emailEnd, emailStart } from '../../actions/profile';
import { Button, Input, Registration } from '../../components';
import { regexEmail } from '../../constants/Component/index';
import { px } from '../../constants/Px';

export const AddEmailPage = () => {
  const dispatch = useDispatch();
  const [isCode, setIsCode] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeEmail = event => {
    setEmail(event);

    setIsValidEmail(regexEmail.test(event));
  };

  const handleChangeCode = event => {
    const newValue = event.target.value.replaceAll(/\D/g, ''); // удаляем все, кроме цифр
    if (newValue.length <= 4) {
      setCode(newValue);
    }
  };

  const onSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (isCode) {
        const { payload } = await dispatch(emailEnd({ email, code }));
        setIsLoading(false);
      } else {
        const { payload } = await dispatch(emailStart({ email }));
        setIsLoading(false);
        /*       payload?.status === 201 && setIsCode(true); */
        setIsCode(true);
        /*      payload?.data?.verificationCode && alert(`Ваш код ${payload?.data?.verificationCode}`); */
      }
    } catch {
      console.log('error emailEnd');
    }
  };

  return (
    <Registration title="Регистрация" navigatePatch="/profile/edit">
      <form onSubmit={onSubmit}>
        <div className="reg">
          <Input
            placeholder="Электронная почта"
            className={`${!isValidEmail && 'error'} font-normal`}
            onChange={event => handleChangeEmail(event.target.value)}
            value={email}
            disabled={isCode}
            style={{
              fontSize: px(22),
              lineHeight: px(29),
            }}
          />
        </div>

        {isCode && (
          <div className="number">
            <Input
              style={{
                borderRadius: px(0),
                margin: `${px(0)} ${px(0)} ${px(10)} ${px(0)}`,
              }}
              placeholder="Ввести код…"
              onChange={event => handleChangeCode(event)}
              value={code}
              type="text"
              name="token"
              inputmode="numeric"
              pattern="\d{4}"
              autocomplete="one-time-code"
              required
            />
          </div>
        )}
        {isCode && (
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
              marginTop: px(10),
              marginBottom: px(20),
              paddingLeft: px(18),
            }}
          >
            Введите код из 4-х цифр,
            <br /> отправленный вам на почту
          </p>
        )}

        <Button
          type="submit"
          className="w-full border-[1px] border-solid border-[#dddddd] "
          borderRadius={0}
          height={60}
          fontSize={24}
          lineHeight={32}
          disabled={isCode ? code?.length < 4 : !isValidEmail || !email || isLoading}
        >
          {isCode ? 'Подтвердить' : 'Зарегистрировать'}
        </Button>
      </form>
    </Registration>
  );
};
