import { createAsyncThunk } from '@reduxjs/toolkit';

import * as profileAdminApi from '@/api/event';

import { createRequest } from '../../../helpers/api/createRequest';
/* import { getAdmins } from './get-admins'; */

export const BLOCK_EVENT_ADMIN = 'profile-admin/blockEventAdmin';

/* const onSuccess = () => async (dispatch) => {
  dispatch(
    getAdmins()
  );
}; */

export const blockEventAdmin = createAsyncThunk(
  BLOCK_EVENT_ADMIN,
  createRequest({
    type: BLOCK_EVENT_ADMIN,
    loader: data => profileAdminApi.blockEventAdmin(data),
  }),
);
