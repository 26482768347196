import * as authApi from '@/api/event-review';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_OBJECT_FEEDBACK = 'event-review/getObjectFeedback';

export const getObjectFeedback = createAsyncThunk(
  GET_OBJECT_FEEDBACK,
  createRequest({
    type: GET_OBJECT_FEEDBACK,
    loader: (data) => authApi.getObjectFeedback(data),
  }),
);
