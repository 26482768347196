import React from 'react';
import { px } from '../constants/Px';

export const ObjectDescriptionTitles = ({title,text,titleLineHeight,top}) => {
  return (
    <>
      <p className='font-light whitespace-pre-wrap' style={{ fontSize: px(32), lineHeight: px(titleLineHeight),marginTop:px(top) }}>{title}</p>
      <p className='font-normal whitespace-pre-wrap' style={{ fontSize: px(15), lineHeight: px(20) }}>
        {text}
      </p>
    </>
  );
};

export default ObjectDescriptionTitles;