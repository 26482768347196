import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BackButton } from '../../new-architecture/shared';
import { px } from '../../new-architecture/utils';

export const CookiesPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="global-container">
      <div className="scrollable-container max-h-full">
        <BackButton onClick={handleClose} marker />
      </div>
      <main>
        <div
          className="bg-white"
          style={{
            padding: `${px(20)} ${px(12)} ${px(60)} ${px(15)}`,
          }}
        >
          <h1
            className="text-start font-light"
            style={{
              fontSize: px(32),
              lineHeight: px(32),
            }}
          >
            Приватность. <br />
            Файлы cookie
          </h1>
          <h2
            className="text-start font-normal"
            style={{
              paddingBottom: px(40),
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            Последнее обновление 12/03/2024
          </h2>
          <h2
            className="text-start font-normal"
            style={{
              fontSize: px(20),
              lineHeight: px(26),
              paddingBottom: px(10),
            }}
          >
            Узнайте больше.
            <br />
            Что такое cookie:
          </h2>
          <p
            className="font-normal"
            style={{
              fontSize: px(15),
              lineHeight: px(20),
            }}
          >
            Настоящая Политика предоставлена в дополнение к Политике в отношении обработки и защиты
            персональных данных в целях разъяснения типов собираемых на сайте{'\u00A0'}
            <a
              href="/"
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="cursor-pointer font-normal text-[#0A84FE]"
            >
              https://booking.fishing
            </a>
            {'\u00A0'}и всех его поддоменах (далее – «Сайт») файлов cookie, целей их использования,
            и способов управления файлами cookie. Оператором данных является ООО «РАБОТАЕМ»,
            расположенное по адресу: 196006, г. Москва, Варшавское шоссе д. 125 корпус 3
            («Компания»).
            <br />
            При использовании данного сайта, вы подтверждаете свое согласие на использование файлов
            cookie в соответствии с настоящим уведомлением в отношении данного типа файлов. Если вы
            не согласны с тем, чтобы мы использовали данный тип файлов, то вы должны соответствующим
            образом установить настройки вашего браузера или не использовать Сайт.
            <br /> Нижеследующее соглашение касается правил Сайта относительно личной информации,
            <br />
            1. Введение Данное соглашение касается использования Сайтом информации, получаемой от
            Пользователей Сайта. В этом документе также содержится информация о файлах «cookie», об
            использовании файлов «cookie» Сайтом и третьими сторонами, а также о том, как вы можете
            отказаться от такого рода файлов.
            <br />
            2. Информация для Пользователей Во время просмотра любой страницы Сайта на ваш компьютер
            загружается сама страница, а также может загружаться небольшой текстовый файл под
            названием "cookie", позволяющий определить, был ли конкретный компьютер (и, вероятно,
            его Пользователь) на этом сайте раньше. Это происходит во время повторного посещения
            сайта посредством проверки компьютера пользователя на наличие файла "cookie",
            оставшегося с прошлого посещения. Файлы cookie широко используются владельцами сайтов
            для обеспечения работы сайтов или повышения эффективности работы, а также для получения
            аналитической информации. Файлы cookie могут размещаться на Вашем устройстве
            администрацией Сайта (эти файлы cookie называются «собственными»). Некоторые файлы
            cookie могут размещаться на Вашем устройстве другими операторами. Такие файлы cookie
            называются файлами «третьих лиц». Мы и третьи лица можем использовать файлы cookie,
            чтобы узнать, когда Вы посещаете Сайт, как взаимодействуете контентом. На основе файлов
            cookie может собираться и использоваться обобщенная и другая информация, не связанная с
            идентификацией отдельных пользователей (например, об операционной системе, версии
            браузера и URL- адресе, с которого выполнен переход на данную страницу, в том числе из
            электронного письма или рекламного объявления) — благодаря этому мы можем предоставить
            Вам более широкие возможности и проанализировать маршруты посещения сайтов, что служит
            инструментом для сбора обобщенной статистики об использовании сайта в целях
            аналитического исследования и помогает нам оптимизировать сайт. Информация, которую мы
            получаем посредством «cookie»-файлов, помогает нам предоставлять вам наши услуги в
            наиболее удобном для вас виде, а также может помочь нам составить представление о наших
            читателях. Например: если, зайдя на Сайт, вы посетили какой-либо раздел, то в следующий
            раз мы можем узнать об этом из «cookie» и выделять статьи из этого раздела во время
            ваших последующих посещений Сайта.
            <br /> 3. Информация о «cookie» Файл «cookie» представляет собой небольшое количество
            данных, среди которых часто содержится уникальный анонимный идентификатор, посылаемый
            вашему браузеру компьютером сайта и сохраняемый на жестком диске вашего компьютера.
            Каждый сайт может посылать свои файлы «cookie» на ваш компьютер, если настройки вашего
            браузера разрешают это. В то же время (чтобы сохранить конфиденциальность ваших данных)
            ваш браузер открывает сайтам доступ только к вашим собственным «cookie», но не позволяет
            им пользоваться такими же файлами «cookie», оставленными другими сайтами. В файлах
            «cookie» хранится информация о ваших предпочтениях в интернете. Пользователи могут
            настроить свои компьютеры так, чтобы они автоматически принимали все файлы «cookie»,
            либо предупреждали каждый раз, когда сайт пытается записать свой «cookie» на жесткий
            диск пользователя, либо вовсе не принимать никаких «cookie»-файлов. Последний вариант
            означает, что некоторые персональные услуги не могут быть предоставлены пользователям, а
            также что пользователи, выбравшие такие настройки, не смогут получить полный доступ ко
            всем разделам Сайта. Каждый браузер уникален, так что обратитесь к функции «Помощь»
            вашего браузера, чтобы узнать, как настроить работу с файлами «cookie». Если вы
            настроили свой компьютер на полный запрет приема «cookie» файлов, вы по- прежнему можете
            анонимно посещать Сайт до тех пор, пока вы не пожелаете воспользоваться одной из услуг
            сайта. Файлы cookie широко используются владельцами сайтов для обеспечения работы сайтов
            или повышения эффективности работы, а также для получения аналитической информации. Сайт
            и поставщики услуг могут использовать на своих интернет-ресурсах различные типы файлов
            cookie: Строго необходимые файлы cookie. Эти файлы cookie необходимы, чтобы сайт работал
            корректно, они позволят Пользователям передвигаться по Сайту и использовать его
            возможности. Эти файлы не идентифицируют Пользователей как личность. Если Пользователь
            не согласен использовать данный тип файлов, это может оказать влияние на
            производительность Сайта, или его компонентов. Файлы cookie, относящиеся к
            производительности, эффективности и аналитике. Эти файлы помогают нам понять, как
            Пользователи взаимодействуют с Сайтом, предоставляя информацию о тех областях, которые
            они посетили и количестве времени, которое они провели на сайте, так же эти файлы
            показывают проблемы в работе интернет-ресурса, например, сообщения об ошибках. Это
            помогает улучшить работу сайта. Файлы cookie, относящиеся к аналитике, также помогают
            нам измерять эффективность рекламных кампаний и оптимизировать содержание сайтов для
            тех, кого заинтересовала наша реклама. Данный тип файлов cookies не может быть
            использован для вашей идентификации. Вся информация, которая собирается и анализируется,
            полностью анонимна. Функциональные файлы cookie. Эти файлы cookie служат для того, чтобы
            опознавать Пользователей, возвращающихся на Сайт. Они позволяют индивидуально подбирать
            содержание Сайта для Пользователей. Если Пользователь блокирует этот тип файлов, то это
            может повлиять на производительность и функциональность веб-сайта и может ограничить
            доступ к контенту на сайте. Рекламные файлы cookie. В эти файлы записываются сведения о
            пользовательских действиях в Интернете, в том числе о посещении сайтов и страниц, а
            также данные о ссылках и рекламе, которые Пользователи выбирали для просмотра. Одна из
            целей — отражать на веб-сайтах тот контент, который наиболее полно ориентирован на
            Пользователя. Другая цель — обеспечить возможность предоставления рекламной или другой
            информации в более точном соответствии с интересами Пользователя.
            <br /> 4. Сбор и использование информации Файлы cookie используются в различных целях, в
            том числе, чтобы: Облегчить себе и третьим лицам получение информации о посещениях
            Пользователями Сайта. Анализировать информацию о посещении страниц Пользователями для
            совершенствования Сайта. Предоставлять рекламу, сообщения и контент, созданные нами и
            третьими лицами, на данном сайте и сайтах других лиц, учитывая интересы Пользователя.
            Помогать Пользователю в получении необходимой информации. Определять количество
            посетителей и то, как они используют наш сайт, — для повышения эффективности сайта и для
            наилучшего понимания интересов их аудитории.
            <br /> 5. Срок хранения файлов cookie Некоторые файлы cookie действуют с момента вашего
            входа на сайт до конца данной конкретной сессии работы в браузере. При закрытии браузера
            эти файлы становятся ненужными и автоматически удаляются. Такие файлы cookie называются
            «сеансовыми». Некоторые файлы cookie сохраняются на устройстве и в промежутке между
            сессиями работы в браузере — они не удаляются после закрытия браузера. Такие файлы
            cookie называются «постоянными». Срок хранения постоянных файлов cookie на устройстве
            различается для разных файлов cookie. Мы и другие компании используем постоянные файлы
            cookie в различных целях: например, чтобы определить, как часто Вы посещаете наши сайты
            или как часто Вы на них возвращаетесь, как с течением времени меняется характер
            использования Сайта, а также для оценки эффективности рекламы. Файлы cookie могут
            размещаться на Вашем устройстве администрацией Сайта. Эти файлы cookie называются
            «собственными». Некоторые файлы cookie могут размещаться на Вашем устройстве другими
            операторами. Такие файлы cookie называются файлами «третьих лиц». Мы и третьи лица можем
            использовать файлы cookie, чтобы узнать, когда Вы посещаете Сайт, как взаимодействуете
            контентом. На основе файлов cookie может собираться и использоваться обобщенная и другая
            информация, не связанная с идентификацией отдельных пользователей (например, об
            операционной системе, версии браузера и URL- адресе, с которого выполнен переход на
            данную страницу, в том числе из электронного письма или рекламного объявления) —
            благодаря этому мы можем предоставить Вам более широкие возможности и проанализировать
            маршруты посещения сайтов. Такая технология позволяет подсчитать количество
            пользователей, которые посетили конкретный раздел, перейдя по ссылке с определенного
            баннера за пределами данного сайта, по текстовой ссылке или изображениям, включенным в
            рассылку. Кроме того, она служит инструментом для сбора обобщенной статистики об
            использовании сайта в целях аналитического исследования и помогает нам оптимизировать
            наши сайты, предлагать рекламу в соответствии с Вашими интересами, как подробно описано
            ниже.
            <br /> 6. Использование cookie в интернет-рекламе и мобильной рекламе Мы вместе с
            третьими лицами, включая технологических партнеров и поставщиков услуг, участвуем в
            ориентированной на интересы пользователей рекламной деятельности, предоставляя рекламу и
            персонализированный контент, который, по нашему мнению и по мнению других
            рекламодателей, будет представлять интерес для Вас. Сторонние поставщики используют
            файлы cookie при реализации сервисов для нас или других компаний; в таких случаях мы не
            контролируем использование указанной технологии или полученной при этом информации и не
            несем ответственности за любые действия или политики третьих лиц. Реклама может
            предоставляться Вам с учетом характера Вашей деятельности в Интернете или при
            использовании мобильных устройств, а также с учетом Ваших действий при поиске, Ваших
            откликов на одно из наших рекламных объявлений или электронных писем, посещенных Вами
            страниц, Вашего географического региона или другой информации. Такие рекламные
            объявления могут появляться на нашем сайте или на сайтах третьих лиц. Технологические
            партнеры, с которыми мы сотрудничаем и которые помогают нам проводить рекламные кампании
            с учетом Ваших интересов, могут являться участниками саморегулируемых ассоциаций. На
            данном сайте Вы можете также видеть рекламу третьих лиц в зависимости от того, какие
            страницы Вы посещаете, какие действия выполняете на нашем сайте и на других сайтах.
            <br /> 7. Использование веб-трекинга и cookie-файлов Мы используем программное
            обеспечение для определения числа пользователей, посещающих наш веб-сайт, и регулярности
            посещения. Мы не используем программы для сбора персональных данных или IP-адресов
            отдельных лиц. Данные используются исключительно анонимным образом в сводной форме в
            статистических целях, а также для разработки веб-сайта. Мы не создаем индивидуальный
            профиль ваших действий в интернете. Содержимое постоянных cookie-файлов ограничивается
            идентификационным номером. Имя, адрес электронной почты, IP-адрес и т.д. не сохраняются.
            Существует исключение: Cookie-файлы Google Analytics. Cookie-файлы Google Analytics
            используют IP-адрес для распознавания пользователя, однако не проводят персональную
            идентификацию (информация собирается анонимно). Указанная информация используется для
            составления отчетов и помогают нам совершенствовать Сайт. Вы можете отказаться от
            использования cookie-файлов Google Analytics для отслеживания вашей активности на всех
            веб-сайтах, пройдя по следующей ссылке: Google Analytics Opt-out Browser Add-on{' '}
            <a
              href="http://tools.google.com/dlpage/gaoptout"
              target="_blanck"
              style={{
                fontSize: px(15),
                lineHeight: px(20),
              }}
              className="cursor-pointer font-normal text-[#0A84FE]"
            >
              (http://tools.google.com/dlpage/gaoptout)
            </a>
            .
            <br /> 8. Cookie-файлы третьих лиц Cookie-файлы третьих лиц во вставленном контенте
            Веб-маяки Мы используем аналитические веб-службы, например Яндекс.Метрика,
            GoogleAnalytics, которые помогают нам понять, как люди пользуются нашими веб-сайтами, и
            тем самым обеспечить их релевантность, удобство использования и актуальность
            информационного наполнения. Эти службы пользуются такими технологиями сбора данных, как
            веб-маяки. Веб-маяки — небольшие электронные изображения, которые размещают
            cookie-файлы, подсчитывают число посещений и оценивают показатели использования и
            эффективность использования веб-сайта. В свою очередь, эти сведения помогают нам понять,
            какая информация интересует посетителей наших сайтов, и предоставить
            индивидуализированное наполнение наших веб-сайтов. Веб- маяки анонимны, не содержат и не
            собирают идентифицирующую Вас информацию. Информация является анонимной и используется
            исключительно в статистических целях. Данные веб-аналитики и cookie-файлы невозможно
            использовать для того, чтобы установить Вашу личность, поскольку они никогда не содержат
            персональные данные, включая Ваши имя или адрес электронной почты. <br />
            9. Управление файлами cookie Нашим Сайтом можно пользоваться и без cookie-файлов. Вы
            можете отключить сохранение cookie-файлов, ограничить их создание конкретными
            веб-сайтами или установить уведомление об отправке cookie-файлов в своем браузере. Вы
            также можете в любой момент удалить cookie-файлы с жесткого диска своего ПК (файл:
            «cookies»). Обратите внимание: в таком случае отображение страниц и руководство по
            использованию сайтов будут ограниченными. Большинство браузеров позволяют в той или иной
            степени контролировать большинство cookie-файлов через настройки браузера. Не все
            посетители нашего сайта используют веб-браузеры. Например, некоторые пользователи
            получают доступ к веб-сайтам или приложениям DHL с мобильных устройств. В таком случае
            отключение cookie-файлов или изменение настроек веб- браузера, вероятно, будет
            невозможно. Большинство интернет-браузеров изначально настроены автоматически принимать
            cookie. Пользователь может изменить настройки таким образом, чтобы браузер блокировал
            cookie или предупреждал, когда файлы данного типа будут отправлены на устройство. Есть
            несколько способов управления cookie. Пожалуйста, обратитесь к инструкции браузера для
            того, чтобы узнать больше о том, как скорректировать или изменить настройки браузера.
            Если отключить cookie, это может повлиять на работу Пользователя в Интернете. Если
            Пользователь использует различные устройства для просмотра и доступа к Сайту (например,
            компьютер, смартфон, планшет и т.д.), он должны убедиться, что каждый браузер на каждом
            устройстве настроен в соответствии с предпочтениями на работу с файлами cookie.
          </p>
        </div>
      </main>
    </div>
  );
};
export default CookiesPage;
