import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { getCompetitionCabinet } from '../actions/competition';
import { getEvent } from '../actions/event';
import { getGuideCabinet } from '../actions/guide/thunks/get-guide-cabinet';
import { getNotification } from '../actions/notification/thunks';
import { getProfile, getProfileById } from '../actions/profile';
import { px } from '../constants/Px';
import useNotificationCount from '../hooks/useNotificationCount';
import { getImageUrl, getPlasholderName } from '../new-architecture/utils';
import { authActiveIdSelector, authActiveType } from '../selectors/auth';
import { competitionSelector } from '../selectors/competition';
import { eventSelector } from '../selectors/event';
import { guideSelector } from '../selectors/guide';
import { profileByIdSelector, profileSelector } from '../selectors/profile';
import { TypesIcon } from '../types/TypesIcon';
import { Icon } from './Icon';

export const HeaderWithSearch = ({ setSearchReq }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeType = useSelector(authActiveType);
  const profile = useSelector(
    activeType === 'administeredProfile' ? profileByIdSelector : profileSelector,
  );
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [trigger, setTrigger] = useState(false);
  const eventSelectorData = useSelector(eventSelector);
  const activeId = useSelector(authActiveIdSelector);
  const competition = useSelector(competitionSelector);
  const guide = useSelector(guideSelector);

  const closeSearch = () => {
    setSearchValue('');
    setSearchReq('');
    setSearch(false);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleKeyPress = event => {
    if (event.key === 'Escape') {
      setSearchValue('');
    }
    if (event.key === 'Enter') {
      setSearchReq(searchValue);
    }
    if (event.key === 'Backspace' && searchValue.length > 0) setSearchReq(searchValue.slice(0, -1));
  };

  const handleChangeSearch = () => {
    setSearch(true);
  };

  useEffect(() => {
    if (!profile?.id) {
      dispatch(getProfile());
    }
  }, [profile?.id]);

  useEffect(() => {
    if (activeType === 'administeredProfile') dispatch(getProfileById({ id: activeId }));

    if (activeType === 'object' || activeType === 'administeredObject')
      dispatch(getEvent({ id: activeId }));

    if (activeType === 'competition' || activeType === 'administeredCompetition')
      dispatch(getCompetitionCabinet({ id: activeId }));

    if (activeType === 'guide' || activeType === 'administeredGuide')
      dispatch(getGuideCabinet({ id: activeId }));
  }, [activeId, activeType]);

  useEffect(() => {
    if (searchValue?.length >= 3) setSearchReq(searchValue);
  }, [searchValue, setSearchReq]);

  const unreadNotificationsCount = useNotificationCount(trigger);

  return (
    <header className="header">
      <nav className="header__navbar navbar">
        <div className="flex items-center">
          <div onClick={handleClose} onKeyDown={handleClose} className="cursor-pointer">
            <Icon
              type={TypesIcon.COLORBACK}
              viewBox="0 0 11.61 19.762"
              width={px(11.61)}
              height={px(19.762)}
            />
          </div>
          <div
            style={{
              marginLeft: search ? px(46.39) : px(58.39),
              marginRight: !search && px(105.85),
              width: search && px(175),
              height: search && px(40),
              paddingLeft: search && px(12),
              paddingRight: search && px(11),
              borderRadius: search && px(50),
              gap: px(4.8),
            }}
            className={`${
              search &&
              'flex items-center border-[1px] border-solid  border-black border-opacity-30'
            } cursor-pointer`}
            onClick={handleChangeSearch}
            onKeyDown={handleChangeSearch}
          >
            <Icon
              type={TypesIcon.BLACKSEARCH}
              width={px(21.357)}
              height={px(21.358)}
              viewBox="0 0 21.357 21.358"
            />
            {search && (
              <input
                placeholder="Искать..."
                className="header__input w-full outline-none"
                style={{ height: px(36), fontSize: px(20), lineHeight: px(26) }}
                value={searchValue}
                onKeyDown={handleKeyPress}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
              />
            )}
          </div>
          {!search && (
            <div
              style={{
                width: px(38),
                height: px(40),
                marginRight: px(30),
              }}
              className="navbar__item navbar-item"
            >
              <Link
                className="navbar-item__link notifications"
                to="/notifications"
                onClick={() => {
                  dispatch(getNotification());
                  setTrigger(!trigger);
                }}
              >
                {unreadNotificationsCount > 0 ? (
                  <span
                    className={`${
                      unreadNotificationsCount > 9 ? '' : 'circle flex items-center justify-center'
                    }`}
                    style={{ left: unreadNotificationsCount > 9 ? px(10) : px(9) }}
                  >
                    {unreadNotificationsCount}
                  </span>
                ) : (
                  ''
                )}
                <Icon
                  type={TypesIcon.HEADERBELL}
                  width={px(19.901)}
                  height={px(22.643)}
                  viewBox="0 0 19.901 22.643"
                />
              </Link>
            </div>
          )}

          {search ? (
            <div
              style={{ height: px(38), width: px(40) }}
              className="flex cursor-pointer items-center justify-center"
              onClick={closeSearch}
              onKeyDown={closeSearch}
            >
              <Icon type={TypesIcon.CLOSE} viewBox="0 0 17 17" width={px(17)} height={px(17)} />
            </div>
          ) : (
            <Link className="navbar-item__link" to="/select-profile">
              {getImageUrl(activeType, eventSelectorData, guide, competition, profile) ? (
                <img
                  alt="avatar"
                  style={{
                    width: px(40),
                    height: px(40),
                    borderRadius: px(40),
                    border: '1px solid gray',
                  }}
                  src={getImageUrl(activeType, eventSelectorData, guide, competition, profile)}
                />
              ) : (
                <div
                  style={{ height: px(40), width: px(40) }}
                  className="flex items-center justify-center rounded-[50%] border-[1px] border-solid border-black border-opacity-50"
                >
                  <p
                    className="font-light text-black"
                    style={{ fontSize: px(32), lineHeight: px(43) }}
                  >
                    {getPlasholderName(activeType, eventSelectorData, guide, competition, profile)}
                  </p>
                </div>
              )}
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
};

export default HeaderWithSearch;
