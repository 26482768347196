import { useContext } from 'react';

import { objectContext } from '../context';

export const useObject = () => {
  const context = useContext(objectContext);

  if (context === undefined) {
    throw new Error('objectContext must be inside a ObjectProvider');
  }

  return context;
};

export default useObject;
