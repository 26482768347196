import * as authApi from '@/api/auth';
import { createRequest } from '@/helpers/api/createRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { clearProfile } from '../../profile';
import { clearAuth } from '../clear-auth';

export const LOGOUT = 'auth/logout';


const onSuccess =  () =>async  dispatch => {
    dispatch(clearProfile());
    dispatch(clearAuth());

};

const onFail = () => async (dispatch) => {
    dispatch(clearProfile());
    dispatch(clearAuth());
 
  };

export const logout = createAsyncThunk(
    LOGOUT,
    createRequest({
        type: LOGOUT,
        loader: (data) => authApi.logout(data),
        onSuccess,
        onFail
    }),
);