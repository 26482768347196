import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '@/helpers/api/createRequest';
import * as authApi from '@/api/achievement';

export const ADD_ACHIEVEMENT = 'achievement/addAchievement';

export const addAchievement= createAsyncThunk(
    ADD_ACHIEVEMENT,
    createRequest({
        type: ADD_ACHIEVEMENT,
        loader: (data) => authApi.addAchievement(data),
    }),
);