import { request } from '@/helpers/api/request';

export const updateEvent = async (data) => {
  const response = await request({
    url: `event/${data?.id}`,
    method: 'PUT',
    data:data?.dataObj,
  });

  return response;
};
